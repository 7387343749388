import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";

export const ViewContext = createContext<{
  fixedHeight: boolean | undefined;
  hideXScrollbar: boolean | undefined;
  hideYScrollbar: boolean | undefined;
  permanentSidenav: boolean | undefined;
  /**
   * Resets the context to the initial state.  Should be called when a route changes.
   */
  reset: () => void;
  /**
   * Sets the view to a fixed height.
   */
  setFixedHeight: (fixedHeight: boolean) => void;
  /**
   * Hides the X scrollbar
   */
  setHideXScrollbar: (hideXScrollbar: boolean) => void;
  /**
   * Hides the Y scrollbar
   */
  setHideYScrollbar: (hideYScrollbar: boolean) => void;
  /**
   * Sets the side nav to be permanently visible.
   */
  setPermanentSidenav: (permanentSidenav: boolean) => void;
}>(null as never);

export const ViewProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [fixedHeight, setFixedHeight] = useState<boolean>(false);
  const [hideXScrollbar, setHideXScrollbar] = useState<boolean>(false);
  const [hideYScrollbar, setHideYScrollbar] = useState<boolean>(false);
  const [permanentSidenav, setPermanentSidenav] = useState<boolean>();

  const reset = useCallback(() => {
    setFixedHeight(false);
    setHideXScrollbar(false);
    setHideYScrollbar(false);
    setPermanentSidenav(undefined);
  }, [setPermanentSidenav]);

  return (
    <ViewContext.Provider
      value={{
        fixedHeight,
        hideXScrollbar,
        hideYScrollbar,
        permanentSidenav,
        setFixedHeight,
        setHideXScrollbar,
        setHideYScrollbar,
        setPermanentSidenav,
        reset
      }}
    >
      {children}
    </ViewContext.Provider>
  );
};

type Options = {
  /**
   * Sets the view to a fixed height.
   * @default false
   */
  fixedHeight?: boolean;
  /**
   * Hides the x scrollbar
   * @default false
   */
  hideXScrollbar?: boolean;
  /**
   * Hides the y scrollbar
   * @default false
   */
  hideYScrollbar?: boolean;
  /**
   * Displays the side nav permanently on the left of the layout if set true.
   * @default false
   */
  permanentSidenav?: boolean;
};

export function useView({
  fixedHeight = false,
  hideXScrollbar = false,
  hideYScrollbar = false,
  permanentSidenav = false
}: Options = {}) {
  const context = useContext(ViewContext);
  const {
    setFixedHeight,
    setHideXScrollbar,
    setHideYScrollbar,
    setPermanentSidenav
  } = context;

  useEffect(() => {
    setFixedHeight(fixedHeight);
  }, [setFixedHeight, fixedHeight]);

  useEffect(() => {
    setHideXScrollbar(hideXScrollbar);
  }, [setHideXScrollbar, hideXScrollbar]);

  useEffect(() => {
    setHideYScrollbar(hideYScrollbar);
  }, [setHideYScrollbar, hideYScrollbar]);

  useEffect(() => {
    setPermanentSidenav(permanentSidenav);
  }, [setPermanentSidenav, permanentSidenav]);

  return context;
}
