import { AndonEvent, AndonWorkstation } from "~/features/andon/andon.slice";
import {
  SendMessageDto,
  ToteEventDto,
  SignalRPickEventDto,
  PTLEventDto,
  ToteDto,
  AutostoreEvent
} from "~/types/api";

export type MixedEventData =
  | SendMessageDto
  | ToteEventDto
  | SignalRPickEventDto
  | PTLEventDto
  | AutostoreEvent;

export function updateAwsWithPortMode(
  eventData: AutostoreEvent.PortMode,
  matchingAws: AndonWorkstation,
  portId: string,
  isParentPort: boolean
) {
  let matchingPortIndex = -1;

  const updatedAws = {
    ...matchingAws
  };

  if (!isParentPort) {
    matchingPortIndex = matchingAws.ports.findIndex(
      (port) => port.portId.toString() === portId
    );
  }

  if (eventData.event.portMode === "Open") {
    updatedAws.isOpen = true;
    if (updatedAws?.parentPort) {
      updatedAws.parentPort.isOpen = true;
    }

    if (matchingPortIndex !== -1) {
      updatedAws.ports[matchingPortIndex] = {
        ...matchingAws.ports[matchingPortIndex],
        isOpen: true
      };
    }
  } else if (eventData.event.portMode === "Closed") {
    updatedAws.isOpen = false;
    if (updatedAws?.parentPort) {
      updatedAws.parentPort.isOpen = false;
    }
    if (matchingPortIndex !== -1) {
      updatedAws.ports[matchingPortIndex] = {
        ...matchingAws.ports[matchingPortIndex],
        isOpen: false
      };
    }
  }

  return updatedAws;
}

export function updateAwsWithBinOpened(
  eventData: AutostoreEvent.BinModeChange,
  matchingAws: AndonWorkstation,
  portId: string,
  isParentPort: boolean
) {
  const updatedAws = {
    ...matchingAws
  };

  // update the aws bins
  if (updatedAws?.hasBins?.length > 0) {
    updatedAws.hasBins = [
      ...new Set([...updatedAws.hasBins, eventData.event.binId])
    ];
  } else {
    updatedAws.hasBins = [...new Set([eventData.event.binId])];
  }

  // update the parent port bins
  if (isParentPort && updatedAws.parentPort) {
    updatedAws.parentPort.hasBins = updatedAws.parentPort.hasBins
      ? [...new Set([...updatedAws.parentPort.hasBins, eventData.event.binId])]
      : [eventData.event.binId];

    // or find the matching port and update its bins
  } else {
    const matchingPortIndex = matchingAws.ports.findIndex(
      (port) => port.portId.toString() === portId
    );

    if (matchingPortIndex !== -1) {
      updatedAws.ports[matchingPortIndex].hasBins = updatedAws.ports[
        matchingPortIndex
      ].hasBins
        ? [
            ...new Set([
              ...updatedAws.ports[matchingPortIndex].hasBins,
              eventData.event.binId
            ])
          ]
        : [];
    }
  }

  return updatedAws;
}

export function updateAwsWithBinClosed(
  eventData: AutostoreEvent.BinModeChange,
  matchingAws: AndonWorkstation,
  portId: string,
  isParentPort: boolean
) {
  const updatedAws = {
    ...matchingAws
  };

  updatedAws.hasBins =
    updatedAws.hasBins?.filter((binId) => eventData.event.binId !== binId) ||
    [];

  if (isParentPort && updatedAws.parentPort) {
    updatedAws.parentPort.hasBins =
      updatedAws.parentPort.hasBins?.filter(
        (binId) => eventData.event.binId !== binId
      ) || [];
  } else {
    const matchingPortIndex = updatedAws.ports.findIndex(
      (port) => port.portId.toString() === portId
    );

    if (matchingPortIndex !== -1) {
      updatedAws.ports[matchingPortIndex].hasBins =
        updatedAws.ports[matchingPortIndex].hasBins?.filter(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          (binId) => eventData.event.binId !== binId
        ) || [];
    }
  }

  return updatedAws;
}

export function updateAwsWithHandToggled(
  eventData: AutostoreEvent.HandToggled,
  matchingAws: AndonWorkstation
) {
  const updatedAws = {
    ...matchingAws
  };

  updatedAws.handRaised = eventData.event.raiseHand;

  return updatedAws;
}

export function resetAwsStatus(
  matchingAws: AndonWorkstation
): AndonWorkstation {
  return {
    ...matchingAws,
    status: "good",
    handRaised: false,
    isOpen: false,
    hasBins: [],
    ports: matchingAws.ports.map((port) => ({
      ...port,
      isOpen: false,
      status: "good",
      hasBins: []
    })),
    parentPort: matchingAws.parentPort
      ? {
          ...matchingAws.parentPort,
          isOpen: false,
          status: "good",
          hasBins: []
        }
      : null
  };
}

export function hasEventData(
  event: AndonEvent
): event is AndonEvent & { eventData: MixedEventData } {
  if (typeof event !== "object" || event === null) return false;

  return "eventData" in event;
}

export function hasEventType(
  event: MixedEventData
): event is MixedEventData & { eventType: string } {
  if (typeof event !== "object" || event === null) return false;
  return "eventType" in event;
}

export function isAutostoreEvent(
  event: MixedEventData
): event is AutostoreEvent {
  return "case" in event;
}

export function hasTote(
  event: MixedEventData
): event is ToteEventDto & { tote: ToteDto } {
  return "tote" in event;
}

export function hasText(
  event: MixedEventData
): event is PTLEventDto & { data: { text: string } } {
  return "data" in event && "text" in event.data;
}

export function hasDeviceId(
  event: MixedEventData
): event is PTLEventDto & { data: { deviceId: string } } {
  return "deviceId" in event;
}

export function isAutostoreEventWithBinId(
  event: MixedEventData
): event is
  | AutostoreEvent.BinRequested
  | AutostoreEvent.BinRequestTimedOut
  | AutostoreEvent.BinModeChange
  | AutostoreEvent.BinFlagged {
  return (
    "case" in event &&
    (event.case === "BinRequested" ||
      event.case === "BinRequestTimedOut" ||
      event.case === "BinModeChange" ||
      event.case === "BinFlagged")
  );
}

export function isAutostoreEventWithSourceBinId(
  event: MixedEventData
): event is
  | AutostoreEvent.PTLMessageRegistered
  | AutostoreEvent.PTLMessageActivated
  | AutostoreEvent.PTLMessageCompleted {
  return (
    "case" in event &&
    (event.case === "PTLMessageRegistered" ||
      event.case === "PTLMessageActivated" ||
      event.case === "PTLMessageCompleted")
  );
}
