import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type PickingScannedBarcode = {
  scannedBarcode: string | null;
};

const initialState: PickingScannedBarcode = {
  scannedBarcode: null
};

export const pickingScannedBarcodeSlice = createSlice({
  name: "pickingScannedBarcode",
  initialState,
  reducers: {
    setScannedBarcode(state, action: PayloadAction<string>) {
      state.scannedBarcode = action.payload;
    },
    resetScannedBarcode(state) {
      state.scannedBarcode = null;
    }
  }
});

export const { setScannedBarcode, resetScannedBarcode } =
  pickingScannedBarcodeSlice.actions;
