import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function TotePrepModalInstruction() {
  const { t } = useTranslation();
  return (
    <Box style={{ padding: "0 0 0 2px" }}>
      <Typography>
        1.
        {t("print & apply label")}
      </Typography>
      <Typography>
        2.
        {t("place box in matching position")}
      </Typography>
      <Typography>
        3.
        {t("press button on position to confirm")}
      </Typography>
    </Box>
  );
}
