import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import { BinDto } from "~/types/api";

export interface BinLocationProps {
  bin: BinDto | null;
}

const LocationHeader = styled(Typography)(() => ({
  fontSize: 11
}));

const WarehouseLocation = styled(Grid)(() => ({
  textAlign: "center"
}));

/**
 * Renders a bin location with headers.
 *
 * Accepts all types of bin locations and possible coordinates.
 */
export function BinLocation(props: BinLocationProps) {
  const { bin } = props;

  const { t } = useTranslation();

  if (!bin) {
    return null;
  }

  const { aisle, palletBay, shelfBay, shelf, position, storeBinCode } = bin;
  return (
    <Grid container wrap="wrap" alignItems="center">
      {storeBinCode && (
        <WarehouseLocation item xs={3}>
          <LocationHeader variant="overline">{t("store bin")}</LocationHeader>
          <Typography variant="body1" noWrap>
            {storeBinCode}
          </Typography>
        </WarehouseLocation>
      )}
      {aisle && (
        <WarehouseLocation item xs={3}>
          <LocationHeader variant="overline">{t("aisle")}</LocationHeader>
          <Typography variant="body1" noWrap>
            {aisle}
          </Typography>
        </WarehouseLocation>
      )}
      {shelfBay && (
        <WarehouseLocation item xs={3}>
          <LocationHeader variant="overline">{t("shelf bay")}</LocationHeader>
          <Typography variant="body1" noWrap>
            {shelfBay}
          </Typography>
        </WarehouseLocation>
      )}
      {palletBay && (
        <WarehouseLocation item xs={3}>
          <LocationHeader variant="overline" noWrap>
            {t("pallet bay")}
          </LocationHeader>
          <Typography variant="body1" noWrap>
            {palletBay}
          </Typography>
        </WarehouseLocation>
      )}
      {shelf && (
        <WarehouseLocation item xs={3}>
          <LocationHeader variant="overline">{t("shelf")}</LocationHeader>
          <Typography variant="body1" noWrap>
            {shelf}
          </Typography>
        </WarehouseLocation>
      )}
      {position && (
        <WarehouseLocation item xs={3}>
          <LocationHeader variant="overline">{t("position")}</LocationHeader>
          <Typography variant="body1" noWrap>
            {position}
          </Typography>
        </WarehouseLocation>
      )}
    </Grid>
  );
}
