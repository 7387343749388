import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function ErrorPlaceholder() {
  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid item>
        <Typography
          component="h1"
          variant="h1"
          color="secondary"
          align="center"
          style={{ paddingTop: 14 }}
        >
          500
        </Typography>
      </Grid>
      <Grid item>
        <Typography component="h4" variant="h4" color="primary" align="center">
          Server Error
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          component="h6"
          variant="h6"
          color="primary"
          align="center"
          style={{ paddingTop: 14 }}
        >
          It&apos;s not you, it&apos;s me.
        </Typography>
      </Grid>
    </Grid>
  );
}
