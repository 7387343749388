import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { OrderSummaryDto } from "~/types/api";

type CreateBatchesState = {
  selectedDate: Date | null;
  createBatchesLoading: boolean;
  selectedOrderIds: Guid[];
};

const initialState: CreateBatchesState = {
  createBatchesLoading: false,
  selectedDate: null,
  selectedOrderIds: []
};

export const createBatchesSlice = createSlice({
  name: "createBatches",
  initialState,
  reducers: {
    setSelectedDate(state, { payload }: PayloadAction<Date | null>) {
      state.selectedDate = payload;
    },
    setSelectedOrders(state, { payload }: PayloadAction<OrderSummaryDto>) {
      if (state.selectedOrderIds.includes(payload.orderId)) {
        state.selectedOrderIds = state.selectedOrderIds.filter(
          (id) => id !== payload.orderId
        );
      } else if (["Locked", "Received"].includes(payload.status)) {
        state.selectedOrderIds.push(payload.orderId);
      }
    },
    clearSelectedOrders(state) {
      state.selectedOrderIds = [];
    }
  }
});

export const { setSelectedDate, clearSelectedOrders, setSelectedOrders } =
  createBatchesSlice.actions;
