import { Button, Toolbar, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import { warehouseService } from "~/api/warehouse";
import { useAppDispatch, useAppSelector } from "~/app/store";
import AutostoreLoadingIcon from "~/components/AutostoreLoadingIcon";
import { NavBarButtonGroup, UnselectButton } from "~/components/navbar/Navbar";
import { useShouldListenToGridEvents } from "~/hooks/useShouldListenToGridEvents";
import {
  binNotAtPort,
  closeBin,
  fetchPortStatus,
  getNextBinWithConfig
} from "~/redux/actions";
import {
  fetchCycleCountsUncountedBins,
  modifyShapedCycleCountInfo,
  postBinCount
} from "~/redux/actions/cycleCounts";
import {
  selectedInventoryInBin,
  selectInventoryAtPort,
  selectUncountedBinAtPort
} from "~/redux/selectors/autostoreCycleCountsSelectors";
import {
  selectSelectedPortId,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";
import { CycleCountV1Dto, MeasuredValueDto } from "~/types/api";

import {
  setBinPresent,
  setIsAdjustPanelOpen,
  setIsBinHoldModalOpen,
  setPageState,
  setPortPollingActive
} from "./autostoreCycleCounts.slice";
import { notNullOrUndefined } from "~/lib/notNullOrUndefined";

interface CycleCountInfo {
  cycleCountId: Guid;
}

type Props = {
  resetPage: () => void;
};

export function AutostoreCycleCountsToolbar({ resetPage }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const shouldListenToGridEvents = useShouldListenToGridEvents();

  const binAtPort = useAppSelector((state) => state.autostore.binAtPort);
  const binPresent = useAppSelector(
    (state) => state.autostoreCycleCounts.binPresent
  );
  const incompleteCycleCounts = useAppSelector(
    (state) => state.cycleCounts.incompleteCycleCounts
  );
  const matchingNextBinInventory = useAppSelector(selectInventoryAtPort);
  const matchingUncountedBin = useAppSelector(selectUncountedBinAtPort);
  const portOpened = useAppSelector(
    (state) => state.autostoreCycleCounts.portOpened
  );
  const portPollingActive = useAppSelector(
    (state) => state.autostoreCycleCounts.portPollingActive
  );
  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const selectedInventory = useAppSelector(selectedInventoryInBin);
  const selectedRows = useAppSelector(
    (state) => state.autostoreCycleCounts.selectedRows
  );
  const selectedPortId = useAppSelector(selectSelectedPortId);

  // to get bins, we must open "new" incomplete cycle counts to acquire a cycle count id
  // and use "open" incomplete cycle counts ids
  const handleGetBins = async () => {
    if (!!selectedAutostoreGridId && !!selectedPortId) {
      dispatch(setPageState("get bin request"));
      dispatch(setBinPresent(false));
      const selectedIncompleteCycleCounts = selectedRows.map((row) =>
        incompleteCycleCounts.find(
          (iCC) => iCC.productCycleId === row || iCC.cycleCountId === row
        )
      );

      // open any "new" incomplete cycle counts
      const cycleCountsToOpen = selectedIncompleteCycleCounts.filter(
        (iCC) => iCC && iCC.status === "new"
      );

      const responses = await Promise.all(
        cycleCountsToOpen.map((iCC) => {
          if (!iCC) return null;
          return warehouseService.post<CycleCountV1Dto>("/api/cycle-counts", {
            ProductCycleId: iCC.productCycleId
          });
        })
      );

      // get the new cycleCountIds, which only exist on open cycle counts,
      // so open the cycle counts
      const newCycleCountsInfo: CycleCountInfo[] = responses
        .filter((response) => response?.status === 200)
        .filter(notNullOrUndefined)
        .map((response): CycleCountInfo => {
          return {
            cycleCountId: response.data.cycleCountId
          };
        });

      const newCycleCountIds: string[] = newCycleCountsInfo.map(
        (info) => info.cycleCountId
      );

      const openCycleCountsInfo: CycleCountInfo[] =
        selectedIncompleteCycleCounts
          .map((iCC) => iCC?.cycleCountId)
          .filter(notNullOrUndefined)
          .map((cycleCountId): CycleCountInfo => {
            return {
              cycleCountId: cycleCountId
            };
          });

      const openCycleCountIds: string[] = openCycleCountsInfo.map(
        (info) => info.cycleCountId
      );

      dispatch(setPageState("fetch cycle counts uncounted bins"));

      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      dispatch(
        fetchCycleCountsUncountedBins(
          [...newCycleCountIds, ...openCycleCountIds],
          selectedAutostoreGridId
        )
      );
    }
  };

  const handleGetNextBin = async () => {
    const portStatus = await dispatch(fetchPortStatus());
    if (!!selectedAutostoreGridId && !!selectedPortId && portStatus) {
      const { selectedBin, selectedTask } = portStatus;

      dispatch(setPageState("close bin"));
      await dispatch(closeBin({ binId: selectedBin, taskId: selectedTask }));
      dispatch(setBinPresent(false));

      try {
        dispatch(setPageState("get next bin"));
        // get next bin
        await dispatch(getNextBinWithConfig());
      } catch {
        // error is handled upstream in the `getNextBinWithConfig`
      }

      // start polling
      dispatch(setPageState("start polling"));
      if (!shouldListenToGridEvents) {
        dispatch(setPortPollingActive(true));
      } else {
        dispatch(binNotAtPort());
      }
    }
  };

  const handleVerify = async ({
    verifiedCount,
    binId,
    inventoryId
  }: {
    verifiedCount: MeasuredValueDto;
    binId: string;
    inventoryId: string;
  }) => {
    if (!matchingUncountedBin) return;
    if (verifiedCount.value !== 0) {
      await dispatch(
        postBinCount({
          verifiedCount,
          binId,
          inventoryId,
          cycleCountId: matchingUncountedBin.cycleCountId
        })
      );
    }

    dispatch(
      modifyShapedCycleCountInfo({
        inventoryId,
        cycleCountId: matchingUncountedBin.cycleCountId,
        newUncountedBinStatus: "counted"
      })
    );

    dispatch(setIsAdjustPanelOpen(false));
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    handleGetNextBin();
  };

  return (
    <Toolbar>
      {!!selectedRows.length &&
        !matchingNextBinInventory &&
        !portPollingActive && (
          <>
            <UnselectButton onClick={() => resetPage()} />
            <NavBarButtonGroup
              variant="text"
              color="primary"
              aria-label="text primary button group"
              style={{ justifyContent: "center", width: "100%" }}
            >
              <Button
                color="secondary"
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                  handleGetBins();
                }}
              >
                <Typography variant="body2" style={{ color: "#fff" }}>
                  {`${t("get bins")} (${selectedRows.length})`}
                </Typography>
              </Button>
            </NavBarButtonGroup>
          </>
        )}
      {binPresent && !portPollingActive && (
        <>
          <UnselectButton onClick={() => resetPage()} />
          <NavBarButtonGroup>
            {selectedInventory && (
              <Button
                color="secondary"
                onClick={() => {
                  if (matchingUncountedBin?.expectedCount)
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                    handleVerify({
                      verifiedCount: matchingUncountedBin.expectedCount,
                      binId: selectedInventory.bin.binId,
                      inventoryId: selectedInventory.inventoryId
                    });
                }}
              >
                <Typography variant="body2" style={{ color: "#fff" }}>
                  {t("verify")}
                </Typography>
              </Button>
            )}
            {selectedInventory && (
              <Button
                color="secondary"
                onClick={() => dispatch(setIsAdjustPanelOpen(true))}
              >
                <Typography variant="body2" style={{ color: "#fff" }}>
                  {`${t("adjust")}`}
                </Typography>
              </Button>
            )}
            {selectedInventory && (
              <Button
                color="secondary"
                onClick={() => dispatch(setIsBinHoldModalOpen(true))}
                style={{
                  width: 175
                }}
              >
                <Typography variant="body2" style={{ color: "#fff" }}>
                  {t("nav.link.inventory holds")}
                </Typography>
              </Button>
            )}
            <Button
              color="secondary"
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                handleGetNextBin();
              }}
            >
              <Typography variant="body2" style={{ color: "#fff" }}>
                {t("next bin")}
              </Typography>
            </Button>
          </NavBarButtonGroup>
        </>
      )}
      {portOpened &&
        ((portPollingActive && !shouldListenToGridEvents) ||
          (!binAtPort && shouldListenToGridEvents)) && (
          <>
            <UnselectButton onClick={() => resetPage()} />
            <NavBarButtonGroup>
              <Button color="secondary" disabled>
                <AutostoreLoadingIcon />
              </Button>
            </NavBarButtonGroup>
          </>
        )}
    </Toolbar>
  );
}
