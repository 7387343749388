import { handleWarehouseError, warehouseService } from "~/api/warehouse";
import { AppThunk, AsyncAppThunk } from "~/app/store";
import envConstants from "~/config/envConstants";
import {
  ExpectedPTLStateResponse,
  PTLEventDto,
  StateChangeDto,
  TotePlacementDto,
  WorkstationSummaryDto
} from "~/types/api";

import { SetUserMessageAction } from "./site";

export interface FetchActualPTLStateAction {
  type: "ptl/FETCH_ACTUAL_STATE";
}

export interface FetchActualPTLStateSuccessAction {
  type: "ptl/FETCH_ACTUAL_STATE_SUCCESS";
  payload: {
    workstationId: Guid;
    ptlStates: { placement: TotePlacementDto; ptlState: PTLState }[];
  };
}

export interface FetchActualPTLStateFailureAction {
  type: "ptl/FETCH_ACTUAL_STATE_FAILURE";
  payload: {
    workstationId: Guid;
    message: string;
  };
}

export interface FetchExpectedPTLStateAction {
  type: "ptl/FETCH_EXPECTED_STATE";
}

export interface FetchExpectedPTLStateSuccessAction {
  type: "ptl/FETCH_EXPECTED_STATE_SUCCESS";
  payload: {
    workstationId: Guid;
    expectedPTLStateResponse: ExpectedPTLStateResponse;
  };
}

export interface FetchExpectedPTLStateFailureAction {
  type: "ptl/FETCH_EXPECTED_STATE_FAILURE";
  payload: {
    workstationId: Guid;
    message: string;
  };
}

export interface PTLSimulationStateChangeAction {
  type: "ptl/PTL_SIMULATION_STATE_CHANGE";
  payload: {
    stateChange: StateChangeDto;
    deviceId: string;
  };
}

export interface TogglePTLSimulationEnabledAction {
  type: "ptl/TOGGLE_SIMULATION_ENABLED";
}

export interface SimulatePressButtonAction {
  type: "ptl/SIMULATE_PRESS_BUTTON";
}

export interface SimulatePressButtonSuccessAction {
  type: "ptl/SIMULATE_PRESS_BUTTON_SUCCESS";
}

export interface SimulatePressButtonFailureAction {
  type: "ptl/SIMULATE_PRESS_BUTTON_FAILURE";
  payload: string;
}

export type PTLState = Partial<{
  originalText: string;
  state: number;
  id: string;
  toteId: string;
  pickId: string;
  pickBinId: string;
}>;

export const fetchPTLState =
  (workstation: WorkstationSummaryDto): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<FetchActualPTLStateAction>({
      type: "ptl/FETCH_ACTUAL_STATE"
    });
    try {
      const ptlStates = await Promise.all(
        workstation.totePlacements.map(async (placement) => {
          const response = await warehouseService.get<PTLState>(
            "api/ptl/get-state-by-module-id",
            {
              params: {
                gridId: workstation.autostoreGridId,
                lightModuleId: placement.putToLightModuleId
              }
            }
          );
          return { placement, ptlState: response.data };
        })
      );

      dispatch<FetchActualPTLStateSuccessAction>({
        type: "ptl/FETCH_ACTUAL_STATE_SUCCESS",
        payload: {
          workstationId: workstation.id,
          ptlStates
        }
      });
    } catch (err) {
      handleWarehouseError(err, (message) =>
        dispatch<FetchActualPTLStateFailureAction>({
          type: "ptl/FETCH_ACTUAL_STATE_FAILURE",
          payload: {
            workstationId: workstation.id,
            message
          }
        })
      );
    }
  };

export const fetchExpectedPTLState =
  (workstation: WorkstationSummaryDto): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<FetchExpectedPTLStateAction>({
      type: "ptl/FETCH_EXPECTED_STATE"
    });
    try {
      const response = await warehouseService.get<ExpectedPTLStateResponse>(
        "api/ptl/expected-state",
        {
          params: {
            workstationId: workstation.id
          }
        }
      );

      dispatch<FetchExpectedPTLStateSuccessAction>({
        type: "ptl/FETCH_EXPECTED_STATE_SUCCESS",
        payload: {
          workstationId: workstation.id,
          expectedPTLStateResponse: response.data
        }
      });
    } catch (err) {
      handleWarehouseError(err, (message) =>
        dispatch<FetchExpectedPTLStateFailureAction>({
          type: "ptl/FETCH_EXPECTED_STATE_FAILURE",
          payload: {
            workstationId: workstation.id,
            message
          }
        })
      );
    }
  };

export const togglePTLSimulationEnabled = (): AppThunk => (dispatch) => {
  dispatch<TogglePTLSimulationEnabledAction>({
    type: "ptl/TOGGLE_SIMULATION_ENABLED"
  });
};

export const simulatePressPTLButton =
  (deviceId: number, stateChange: StateChangeDto): AsyncAppThunk =>
  async (dispatch, getState) => {
    dispatch<SimulatePressButtonAction>({
      type: "ptl/SIMULATE_PRESS_BUTTON"
    });
    try {
      const state = getState();
      if (!state.workstations.siteWorkstation) {
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title:
              "No workstation set, please set this machine as a workstation and try again",
            severity: "error"
          }
        });
        return;
      }

      await warehouseService.post(
        "api/ptl/simulate-press-button",
        {
          deviceId,
          ...stateChange
        },
        {
          params: {
            gridId: state.workstations.siteWorkstation.autostoreGridId
          }
        }
      );

      dispatch<SimulatePressButtonSuccessAction>({
        type: "ptl/SIMULATE_PRESS_BUTTON_SUCCESS"
      });
    } catch (err) {
      handleWarehouseError(err, (message) =>
        dispatch<SimulatePressButtonFailureAction>({
          type: "ptl/SIMULATE_PRESS_BUTTON_FAILURE",
          payload: message
        })
      );
    }
  };

export const setPTLSimulationStateChange =
  (event: PTLEventDto): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    if (!state.workstations.siteWorkstation) return;
    if (state.workstations.siteWorkstation.autostoreGridId !== event.gridId)
      return;
    if (
      !state.workstations.siteWorkstation.totePlacements.find(
        (tp) => tp.putToLightModuleId === parseInt(event.deviceId, 10)
      )
    )
      return;

    dispatch<PTLSimulationStateChangeAction>({
      type: "ptl/PTL_SIMULATION_STATE_CHANGE",
      payload: {
        deviceId: event.deviceId,
        stateChange: event.data
      }
    });

    if (
      envConstants.ENABLE_AUTOMATED_OPERATIONS === "true" &&
      state.site.automatedOperationsEnabled
    ) {
      if (event.data.text !== "") {
        // if there is a pick id, then we are lighting up ptl for pick confirmation
        // wait 7 seconds between when the bin arrives (this event) and when the operator confirms the pick
        // or just wait 0.5 seconds since it's just a tote confirmation
        const timeout = event.data.pickId ? 7620 : 500;
        // eslint-disable-next-line no-console
        console.log(`Simulating button press in ${timeout} ms...`);
        setTimeout(() => {
          // eslint-disable-next-line no-console
          console.log(`Simulating button press now`);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          simulatePressPTLButton(parseInt(event.deviceId, 10), event.data)(
            dispatch,
            getState,
            undefined
          );
        }, timeout);
      }
    }
  };
