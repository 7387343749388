import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { NavBarButtonGroup } from "~/components/navbar/Navbar";
import { fulfillPick, outOfStockPick } from "~/redux/actions/batch";
import { selectBatch } from "~/redux/selectors/batchSelectors";

import { getAllPickModels } from "./BatchViewModel";

export function BatchToolbar() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const batchViewModel = useAppSelector(selectBatch);

  const fulfillAll = () => {
    if (!batchViewModel) return;
    getAllPickModels(batchViewModel)
      .filter((pick) => pick.status === "Unfulfilled")
      .forEach((pick) => {
        void dispatch(fulfillPick(batchViewModel, pick, true, [])); // this may need to be updated with the persist UPC changes
      });
  };

  const outOfStockAll = () => {
    if (!batchViewModel) return;
    getAllPickModels(batchViewModel)
      .filter((pick) => pick.status === "Unfulfilled")
      .forEach((pick) => {
        void dispatch(outOfStockPick(batchViewModel, pick));
      });
  };

  return (
    <Toolbar>
      <NavBarButtonGroup>
        <Button color="secondary" onClick={fulfillAll}>
          <Typography variant="body2" style={{ color: "#fff" }}>
            {t("fulfill all")}
          </Typography>
        </Button>
        <Button color="secondary" onClick={outOfStockAll}>
          <Typography variant="body2" style={{ color: "#fff" }}>
            Out of Stock all
          </Typography>
        </Button>
      </NavBarButtonGroup>
    </Toolbar>
  );
}
