import { AutostoreBinConfigurationDto } from "~/types/api";

import { binApi } from "./bin";

// enhance endpoints and export hooks individually
// At some point in the future, we want to code-gen the binApi
// from an OpenAPI spec.  The generated code will not have response transformations.
// https://redux-toolkit.js.org/rtk-query/api/created-api/code-splitting#enhanceendpoints
export const {
  useGetBinConfigurationsQuery,
  usePatchBinConfigurationMutation,
  useHoldBinMutation,
  useLazyGetBinsBySearchQuery,
  useLazyGetBinsByAutostoreBinNumberQuery
} = binApi.enhanceEndpoints({
  addTagTypes: [],
  endpoints: {
    holdBin: {
      invalidatesTags: ["inventory by autostore bin number"]
    },
    getBinConfigurations: {
      transformResponse: (response: AutostoreBinConfigurationDto[]) => {
        response.sort(
          (a, b) => a.numberOfCompartments - b.numberOfCompartments
        );

        return response;
      }
    }
  }
});
