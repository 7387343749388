import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { ErrorResponse } from "~/types/api";

// pepsi and public endpoints have a non-standard error format
export const getErrorMessage = (error: unknown) => {
  const legacyError = error as {
    data?: ErrorResponse;
  };
  if (legacyError.data?.errorCode && legacyError.data?.message) {
    return `${legacyError.data.errorCode}: ${legacyError.data.message}`;
  }
  const publicApiError = error as {
    data?: { "error Info": string[] };
  };
  if (publicApiError.data?.["error Info"].length) {
    return publicApiError.data["error Info"].join(", ");
  }
  return getMessageFromRtkError(error);
};
