import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Filter } from "./LowInventoryFilters";

type LowInventoryState = {
  filter: Filter | null;
  page: number;
};

const initialState: LowInventoryState = {
  filter: null,
  page: 1
};

export const lowInventorySlice = createSlice({
  name: "lowInventory",
  initialState,
  reducers: {
    setFilter(state, { payload }: PayloadAction<Filter | null>) {
      state.filter = payload;
    },
    setPage(state, { payload }: PayloadAction<number>) {
      state.page = payload;
    }
  }
});
export const { setFilter, setPage } = lowInventorySlice.actions;
