import DoneIcon from "@mui/icons-material/Done";
import {
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { ModalDialogTransition } from "~/components/ModalDialogTransition";
import { createNumberSequence } from "~/lib/shared";

import { setIsTransferModalOpen, setTransferQty } from "./shipOrder.slice";

export function MovePickDialog() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isTransferModalOpen = useAppSelector(
    (state) => state.shipOrder.isTransferModalOpen
  );
  const transferQty = useAppSelector((state) => state.shipOrder.transferQty);
  const selectedPick = useAppSelector((state) => state.shipOrder.selectedPick);

  if (!selectedPick) {
    return null;
  }

  return (
    <Dialog
      open={isTransferModalOpen}
      TransitionComponent={ModalDialogTransition}
      onClose={() => dispatch(setIsTransferModalOpen(false))}
    >
      <DialogContent style={{ textAlign: "center" }}>
        <DialogContentText>Quantity to Move</DialogContentText>
        <Box maxWidth="300px" display="flex" flexDirection="column">
          <Select
            value={transferQty?.value || selectedPick.quantity.value}
            onChange={(e: SelectChangeEvent<number>) =>
              dispatch(
                setTransferQty({
                  value: e.target.value as number,
                  units: selectedPick.quantity.units
                })
              )
            }
            style={{ fontSize: 40, marginBottom: 16 }}
          >
            {createNumberSequence(selectedPick.quantity.value).map((qty) => (
              <MenuItem key={qty} value={qty} style={{ fontSize: 26 }}>
                {qty}
              </MenuItem>
            ))}
          </Select>
          <Chip
            label={t("scan tote confirm")}
            icon={<DoneIcon />}
            color="primary"
            style={{ margin: 2 }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
