import { useCallback, useContext, useState } from "react";
import { Outlet } from "react-router";

import { ErrorBoundary } from "~/components/ErrorBoundary";
import { AutostoreStatusInfo } from "~/components/debug/AutostoreStatusInfo";
import EventSimulator from "~/components/debug/EventSimulator";
import { Navbar } from "~/components/navbar/Navbar";
import { Shell } from "~/components/shell/Shell";
import envConstants from "~/config/envConstants";
import { DevCheatsContext } from "~/hooks/useDevCheats";

export function Layout() {
  const { isPortPolling, showAutostoreStatus, showEventSimulator } =
    useContext(DevCheatsContext);
  const [isAutostoreStatusOpen, setIsAutostoreStatusOpen] = useState(false);
  const [isEventSimulatorOpen, setIsEventSimulatorOpen] = useState(false);

  const devCheatClickHandler = useCallback(() => {
    if (envConstants.DEV_CHEATS_UAT !== "true") {
      return;
    }
    if (showAutostoreStatus) {
      setIsAutostoreStatusOpen(!isAutostoreStatusOpen);
    }
    if (showEventSimulator) {
      setIsEventSimulatorOpen(!isEventSimulatorOpen);
    }
  }, [
    isAutostoreStatusOpen,
    isEventSimulatorOpen,
    showAutostoreStatus,
    showEventSimulator
  ]);

  return (
    <Shell>
      <Navbar onDevCheatClick={devCheatClickHandler} />
      <ErrorBoundary>
        <Outlet />
        {isAutostoreStatusOpen && (
          <AutostoreStatusInfo
            defaultAccordion="bin present"
            portPollingIsActive={isPortPolling}
            floating
          />
        )}
        {isEventSimulatorOpen && <EventSimulator floating />}
      </ErrorBoundary>
    </Shell>
  );
}
