import { Button } from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "~/app/store";

import { Center } from "~/components/Center";
import { mixpanelTrack } from "~/lib/mixpanel-tracking";

import { loginQubit } from "./login.slice";

export const Login = () => {
  const dispatch = useAppDispatch();

  const errorMessage = useAppSelector((state) => state.login.errorMessage);
  const navigate = useNavigate();
  const recentOrgLogin: string | undefined = useAppSelector(
    (state) => state.site.loginPath
  );

  useEffect(() => {
    if (recentOrgLogin) {
      navigate(recentOrgLogin);
      mixpanelTrack({
        trackedPageName: "Basic Login",
        type: "Login",
        label: ""
      });
    }
  }, [recentOrgLogin, navigate]);

  return (
    <Center height="100%">
      <form
        noValidate
        onSubmit={async () => {
          await dispatch(loginQubit());
        }}
      >
        {errorMessage && (
          <Container>
            <Typography className="login__error">{errorMessage}</Typography>
          </Container>
        )}
        <Button
          sx={{
            width: 350,
            margin: "20px auto"
          }}
          variant="contained"
          color="primary"
          type="submit"
          id="login-button"
        >
          Log In
        </Button>
      </form>
    </Center>
  );
};
