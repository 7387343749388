import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { StartPickingOnWorkstationAction } from "~/redux/actions";
import {
  FetchActualPTLStateSuccessAction,
  FetchActualPTLStateAction,
  FetchActualPTLStateFailureAction,
  PTLState,
  FetchExpectedPTLStateAction,
  FetchExpectedPTLStateSuccessAction,
  FetchExpectedPTLStateFailureAction,
  PTLSimulationStateChangeAction,
  TogglePTLSimulationEnabledAction,
  SimulatePressButtonAction,
  SimulatePressButtonFailureAction,
  SimulatePressButtonSuccessAction
} from "~/redux/actions/ptl";
import { ExpectedPTLState, TotePlacementDto } from "~/types/api";

export type PTLSimulationState = {
  text: string;
  adjustable: boolean;
  flash: boolean;
  toteId?: Guid;
  pickId?: Guid;
  pickBinId?: Guid;
};

export type ActualWorkstationPTLState = {
  ptlStates: {
    placement: TotePlacementDto;
    ptlState: PTLState;
  }[];
  errorMessage: string | undefined;
  loading: boolean;
};

export type ExpectedWorkstationPTLState = {
  ptlStates: Map<number | undefined, ExpectedPTLState>;
  statusMessage: string | undefined;
  errorMessage: string | undefined;
  loading: boolean;
};

export type ByWorkstationId<T> = {
  [workstationId: Guid]: T;
};

export type PTLReducerState = {
  actualWorkstationPTLState: ByWorkstationId<ActualWorkstationPTLState>;
  expectedWorkstationPTLState: ByWorkstationId<ExpectedWorkstationPTLState>;
  ptlSimulationStates: { [id: number]: PTLSimulationState }; // Map<number, PTLSimulationState>;
  ptlSimulationEnabled: boolean;
};

const initialState = {
  actualWorkstationPTLState: {},
  expectedWorkstationPTLState: {},
  ptlSimulationStates: {},
  ptlSimulationEnabled: false
};

type Actions =
  | TogglePTLSimulationEnabledAction
  | SimulatePressButtonAction
  | SimulatePressButtonSuccessAction
  | SimulatePressButtonFailureAction
  | PTLSimulationStateChangeAction
  | FetchActualPTLStateAction
  | FetchActualPTLStateSuccessAction
  | FetchActualPTLStateFailureAction
  | FetchExpectedPTLStateAction
  | FetchExpectedPTLStateSuccessAction
  | FetchExpectedPTLStateFailureAction
  | StartPickingOnWorkstationAction;

const ptlReducer = (
  state: PTLReducerState | undefined,
  action: Actions
): PTLReducerState => {
  if (state === undefined) {
    return initialState;
  }

  switch (action.type) {
    case "ptl/FETCH_ACTUAL_STATE":
    case "ptl/SIMULATE_PRESS_BUTTON":
    case "ptl/SIMULATE_PRESS_BUTTON_SUCCESS":
    case "ptl/SIMULATE_PRESS_BUTTON_FAILURE":
      return {
        ...state
      };
    case "ptl/FETCH_ACTUAL_STATE_SUCCESS":
      return {
        ...state,
        actualWorkstationPTLState: {
          ...state.actualWorkstationPTLState,
          [action.payload.workstationId]: {
            loading: false,
            errorMessage: undefined,
            ptlStates: action.payload.ptlStates
          }
        }
      };
    case "ptl/FETCH_ACTUAL_STATE_FAILURE":
      return {
        ...state,
        actualWorkstationPTLState: {
          ...state.actualWorkstationPTLState,
          [action.payload.workstationId]: {
            loading: false,
            errorMessage: action.payload.message,
            ptlStates: []
          }
        }
      };
    case "ptl/FETCH_EXPECTED_STATE":
      return {
        ...state
      };
    case "ptl/FETCH_EXPECTED_STATE_SUCCESS":
      return {
        ...state,
        expectedWorkstationPTLState: {
          ...state.expectedWorkstationPTLState,
          [action.payload.workstationId]: {
            loading: false,
            statusMessage:
              action.payload.expectedPTLStateResponse.statusMessage,
            errorMessage: undefined,
            ptlStates: new Map(
              action.payload.expectedPTLStateResponse.expectedPTLStates.map(
                (ptlState) => [ptlState.lightModuleId, ptlState]
              )
            )
          }
        }
      };
    case "ptl/FETCH_EXPECTED_STATE_FAILURE":
      return {
        ...state,
        expectedWorkstationPTLState: {
          ...state.expectedWorkstationPTLState,
          [action.payload.workstationId]: {
            loading: false,
            statusMessage: undefined,
            errorMessage: action.payload.message,
            ptlStates: new Map()
          }
        }
      };
    case "ptl/PTL_SIMULATION_STATE_CHANGE": {
      const ptlSimulationStates = { ...state.ptlSimulationStates };
      ptlSimulationStates[parseInt(action.payload.deviceId, 10)] = {
        text: action.payload.stateChange.text,
        adjustable: action.payload.stateChange.adjustable,
        flash: action.payload.stateChange.flash,
        toteId: action.payload.stateChange.toteId,
        pickId: action.payload.stateChange.pickId,
        pickBinId: action.payload.stateChange.pickBinId
      };
      return {
        ...state,
        ptlSimulationStates
      };
    }
    case "ptl/TOGGLE_SIMULATION_ENABLED": {
      return {
        ...state,
        ptlSimulationEnabled: !state.ptlSimulationEnabled
      };
    }
    case "autostore/START_PICKING_ON_WORKSTATION": {
      return {
        ...state,
        ptlSimulationStates: {}
      };
    }
    default:
      return state;
  }
};

const ptlPersistConfig = {
  key: "ptl",
  storage,
  whitelist: ["ptlSimulationStates", "ptlSimulationEnabled"]
};

export const ptl = persistReducer(ptlPersistConfig, ptlReducer);
