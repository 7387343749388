import { Plus24Px } from "@locaisolutions/icons";

import {
  Button,
  CircularProgress,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm
} from "react-hook-form";

import { useAppSelector } from "~/app/store";
import { useToast } from "~/hooks/useToast";

import { globalDateTimeFormat } from "~/lib/dateHelpers";
import { generateUuid, getRndInteger } from "~/lib/helpers";
import { groupBy } from "~/lib/shared";
import {
  selectOrderCreationSelectedSku,
  selectOrderCreationSimplified
} from "~/redux/selectors/orderCreationSelectors";

import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

import { useCreatePepsiOrderMutation } from "~/redux/warehouse/pepsi.hooks";
import { useCreateOrderMutation } from "~/redux/warehouse/public.hooks";
import {
  ExternalToteDto,
  FulfillmentCenterDto,
  LineItemDto,
  PickOrderRequest,
  ReceiveOrderDto
} from "~/types/api";

import { LineItem } from "./LineItem";
import { getErrorMessage } from "./shared";

const createExternalOrderId = (): string =>
  `${dayjs().format("MMDD_hh:mm:ss")}_${getRndInteger(0, 99)}`;

const customerNamePool = [
  "Leonardo DiStackrio",
  "Anne Hathawarehouse",
  "Morgan Freightman",
  "Kim Cardbatchshian",
  "Wharry Potter",
  "Shelvin Diesel",
  "Scarlet Palletson",
  "Storage Clooney",
  "Jim Carry",
  "Jennifer Anistorage",
  "Mark Warehouseberg",
  "Putawayne The Rack Johnson",
  "Harrison Forklift",
  "Samuel L. Stackson",
  "Wilson Pallet",
  "Cardi Binventory",
  "Frank S'bin-atra",
  "Gwen Stackfani",
  "Freddie Merchandisury",
  "Johnny Cashier",
  "AutosTorey Spelling",
  "Orderlando Bloom",
  "Replenishmeryl Streep",
  "Cartney Cox",
  "Cycle Countney Love",
  "Holdie Hawn",
  "Gridley Cooper",
  "Pickolas Cage",
  "Bin Affleck",
  "Bulk Hogan",
  "Gridney Houston",
  "Tote Hanks",
  "Progress Barbra Streisand",
  "Robport Pattinson",
  "Natalie Portman",
  "Sam OutOf Stockwell",
  "Qubitney Spears",
  "Gridgory Peck",
  "Binona Ryder",
  "Pick Jagger",
  "Jon Bin Jovi",
  "Print Eastwood",
  "H'user Jackman",
  "Expiry Date Blanchett",
  "FulfillmentCenterId-ris Elba",
  "PTLState Middleton",
  "Expressica Tandy",
  "Decantino Tarantino",
  "Tempzoney Depp",
  "ConfiguReese Witherspoon",
  "Cart Simpson",
  "CommittedCount Dracula",
  "Robins Hood",
  "Eric Cartman",
  "Inventory Jones",
  "Disconnected Sheeran",
  "Binnie the Pooh",
  "Aristotel",
  "Pablo Pickasso",
  "George AndonBoarwell",
  "Rene Descartes",
  "Restockrates",
  "Charles Bulkowski",
  "Binjamin Franklin",
  "Jimmy Carter",
  "Cart Twain",
  "Bincent van Gogh",
  "Immanuel DeKant",
  "Printcent Price",
  "Tempzoneey Deschanel",
  "Aisle Pacino",
  "Scandra Bullock",
  "Totey Macguire",
  "Zoney Kravitz",
  "Batch Galifianakis",
  "Picktoria Jackson",
  "Sean Pending",
  "Adjustin Bieber"
];

const initialCustomerName =
  customerNamePool[Math.floor(Math.random() * customerNamePool.length)];

const createCreatedDate = (timezone: string): Date =>
  dayjs().tz(timezone).toDate();

const createInitialLineItem = (): LineItemDto => ({
  sku: "",
  externalLineItemId: getRndInteger(1, 500).toString(),
  quantity: {
    unit: "ea",
    value: 1.0
  },
  instructions: "order instructions",
  madeToOrder: false,
  acceptableSubstitutions: []
});

const createInitialCreateOrder = (
  fulfillmentCenter?: FulfillmentCenterDto
): ReceiveOrderDto => ({
  id: generateUuid(),
  externalOrderId: createExternalOrderId(),
  fulfillmentCenterId: fulfillmentCenter?.fulfillmentCenterId || "",
  orderType: "Fresh",
  priority: "Standard",
  createdDate: createCreatedDate(fulfillmentCenter?.timeZone || "utc"),
  customerName: `${initialCustomerName} ${dayjs().format("MMM D h:mm")}`,
  pickingEndTime: dayjs()
    .tz(fulfillmentCenter?.timeZone || "utc")
    .endOf("day")
    .toDate(),
  orderWindow: [
    dayjs()
      .tz(fulfillmentCenter?.timeZone || "utc")
      .startOf("day")
      .toDate(),
    dayjs()
      .tz(fulfillmentCenter?.timeZone || "utc")
      .endOf("day")
      .toDate()
  ],
  substitutionPreference: "",
  specialRequest: "",
  lineItems: []
});

const generateRandomToteId = (existingToteId?: number) =>
  getRndInteger(100000000, 999999999, existingToteId).toString();

const generateInitialTote = (
  isExternalTotes: boolean,
  existingToteId?: number
): ExternalToteDto | undefined =>
  isExternalTotes
    ? {
        id: `${generateRandomToteId(existingToteId)}`,
        priority: undefined
      }
    : undefined;

/***
 * Translate the Order to a Pick Order, a Pepsi-specific DTO.
 */
const translateToPickOrder = (
  request: ReceiveOrderDto,
  fulfillmentCenter: FulfillmentCenterDto
): PickOrderRequest => {
  const totes = groupBy(request.lineItems, (li) => li.tote?.id || "");
  return {
    facility: fulfillmentCenter.abbreviation,
    location: "", // does this matter?
    messageId: request.id ?? generateUuid(),
    request: {
      pickOrder: {
        orderType: request.orderType,
        customerName: request.customerName,
        deliveryDate: dayjs(request.pickingEndTime).format("MM/DD/YYYY"),
        orderID: request.externalOrderId,
        priority: 0,
        numberOfCartons: [new Set(request.lineItems.map((li) => li.tote?.id))]
          .length,
        cartons: Object.entries(totes).map(([toteId, lineItems]) => ({
          cartonID: parseInt(toteId, 10),
          boxNumber: "1",
          boxSize: "S",
          numberOfItems: lineItems.length,
          items: lineItems.map((li) => ({
            layer: "",
            productCode: li.sku,
            quantity: li.quantity.value,
            uOM: li.quantity.unit === "ea" ? "E" : "C",
            uPC: li.sku
          }))
        }))
      }
    }
  };
};

export const PickTab = () => {
  const { palette } = useTheme();
  const { successToast, errorToast } = useToast();
  const { dev_useV2OrderCreation } = useAppSelector(selectClientConfig);

  const selectedSku = useAppSelector(selectOrderCreationSelectedSku);
  const simplified = useAppSelector(selectOrderCreationSimplified);
  const fulfillmentCenter =
    useAppSelector(selectUsersFulfillmentCenter) || undefined;

  const [createPickOrder, { isLoading: createPickOrderInProgress }] =
    useCreatePepsiOrderMutation();
  const [createOrder, { isLoading: createOrderInProgress }] =
    useCreateOrderMutation();

  const isExternalTotes =
    fulfillmentCenter?.toteConfiguration === "ExternalTotes";

  const createOrderContext = useForm<ReceiveOrderDto>({
    defaultValues: createInitialCreateOrder(fulfillmentCenter)
  });

  const {
    fields: lineItemFields,
    insert: insertLineItem,
    append: appendLineItem,
    remove: removeLineItem
  } = useFieldArray({
    control: createOrderContext.control,
    name: "lineItems"
  });

  const handleCreateOrder = async (data: ReceiveOrderDto) => {
    try {
      if (dev_useV2OrderCreation && fulfillmentCenter) {
        await createPickOrder(
          translateToPickOrder(data, fulfillmentCenter)
        ).unwrap();
      } else {
        await createOrder(data).unwrap();
      }
      successToast("Successfully created order");
    } catch (err) {
      errorToast(getErrorMessage(err));
    }

    createOrderContext.setValue("id", generateUuid());
    createOrderContext.setValue("externalOrderId", createExternalOrderId());
    createOrderContext.setValue(
      "createdDate",
      createCreatedDate(fulfillmentCenter?.timeZone || "utc")
    );

    if (isExternalTotes) {
      // need to ensure that totes are still grouped the same
      const newToteIds = [
        ...new Set(data.lineItems.map((li) => li.tote?.id))
      ].reduce<{ [index: string]: string }>((prev, curr) => {
        if (curr) {
          // eslint-disable-next-line no-param-reassign
          prev[curr] = generateRandomToteId(parseInt(curr, 10));
        }
        return prev;
      }, {});

      data.lineItems.forEach((li, i) => {
        if (li.tote) {
          createOrderContext.setValue(
            `lineItems.${i}.tote.id`,
            newToteIds[li.tote.id]
          );
        }
      });
    }
  };

  const handleAddLineItem = (index?: number, tote?: ExternalToteDto) => {
    const lineItem = {
      ...createInitialLineItem(),
      sku: selectedSku || "",
      tote: tote ?? generateInitialTote(isExternalTotes)
    };
    if (index !== undefined) {
      insertLineItem(index, lineItem);
    } else {
      appendLineItem(lineItem);
    }
  };

  const groupedLineItems = Object.entries(
    groupBy(
      lineItemFields.map((field, index) => ({ ...field, index })),
      (field) => field.tote?.id || ""
    )
  );

  groupedLineItems.map(([_, group]) => group.sort((a, b) => a.index - b.index));

  return (
    <>
      <Typography variant="h6" sx={{ my: 2 }}>
        Pick Order
      </Typography>

      <FormProvider {...createOrderContext}>
        <form onSubmit={createOrderContext.handleSubmit(handleCreateOrder)}>
          <Stack flexWrap="wrap" gap={3}>
            <Stack direction="row" flexWrap="wrap" gap={2}>
              <TextField
                {...createOrderContext.register("customerName")}
                inputProps={{ sx: { width: 300 } }}
                variant="standard"
                label="CustomerName"
              />
              {!simplified && (
                <>
                  <TextField
                    {...createOrderContext.register("externalOrderId")}
                    variant="standard"
                    label="External Order Id"
                  />
                  <TextField
                    {...createOrderContext.register("priority")}
                    variant="standard"
                    label="Priority"
                  />
                </>
              )}
            </Stack>
            {!simplified && (
              <Stack direction="row" flexWrap="wrap" gap={2}>
                <Controller
                  control={createOrderContext.control}
                  name="earliestPickingStartTime"
                  render={({
                    field: { onBlur, value, onChange, ...field }
                  }) => (
                    <DateTimePicker
                      {...field}
                      label="Ealiest Picking Start Time"
                      value={dayjs(value)}
                      onChange={(event) => {
                        onChange(event?.toDate() || null);
                      }}
                      format={globalDateTimeFormat}
                      slotProps={{
                        textField: {
                          variant: "standard",
                          onBlur,
                          sx: {
                            ".MuiInputBase-input": {
                              minWidth: "180px"
                            }
                          }
                        }
                      }}
                    />
                  )}
                />
                <Controller
                  control={createOrderContext.control}
                  name="pickingEndTime"
                  render={({
                    field: { onBlur, value, onChange, ...field }
                  }) => (
                    <DateTimePicker
                      {...field}
                      label="Picking End Time"
                      value={dayjs(value)}
                      onChange={(event) => {
                        onChange(event?.toDate() || null);
                      }}
                      format={globalDateTimeFormat}
                      slotProps={{
                        textField: {
                          variant: "standard",
                          onBlur,
                          sx: {
                            ".MuiInputBase-input": {
                              minWidth: "180px"
                            }
                          }
                        }
                      }}
                    />
                  )}
                />
                <Controller
                  control={createOrderContext.control}
                  name="createdDate"
                  render={({
                    field: { onBlur, value, onChange, ...field }
                  }) => (
                    <DateTimePicker
                      {...field}
                      label="Created Date"
                      value={dayjs(value)}
                      onChange={(event) => {
                        onChange(event?.toDate() || null);
                      }}
                      format={globalDateTimeFormat}
                      slotProps={{
                        textField: {
                          variant: "standard",
                          onBlur,
                          sx: {
                            ".MuiInputBase-input": {
                              minWidth: "180px"
                            }
                          }
                        }
                      }}
                    />
                  )}
                />
              </Stack>
            )}
          </Stack>

          <Stack direction={"row"} my={2} gap={3}>
            <Button
              variant={
                createOrderContext.watch("lineItems").length
                  ? "contained"
                  : "outlined"
              }
              disabled={
                !createOrderContext.watch("lineItems").length ||
                createOrderInProgress ||
                createPickOrderInProgress
              }
              sx={{ width: 150 }}
              type="submit"
              color="primary"
            >
              {createOrderInProgress ? (
                <CircularProgress size={24} />
              ) : (
                "Create Order"
              )}
            </Button>
            <Button
              variant="contained"
              disabled={!createOrderContext.watch("lineItems").length}
              onClick={() =>
                createOrderContext.reset(
                  createInitialCreateOrder(fulfillmentCenter)
                )
              }
              color="warning"
            >
              Reset
            </Button>
          </Stack>

          {groupedLineItems
            .filter((group) => group[1].length)
            .map((group) => (
              <Paper key={group[0]} sx={{ p: 2, my: 2 }}>
                {isExternalTotes && (
                  <Typography>Tote Id: {group[0]}</Typography>
                )}
                {group[1].map((field) => (
                  <LineItem
                    key={field.id}
                    index={field.index}
                    removeLineItem={removeLineItem}
                  />
                ))}

                {isExternalTotes && (
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={!selectedSku}
                    onClick={() =>
                      handleAddLineItem(
                        group[1].at(-1)?.index,
                        group[1][0].tote
                      )
                    }
                    aria-label={`Add Product to Tote ${group[1][0].tote?.id}`}
                  >
                    <Plus24Px
                      style={{ fill: palette.primary.main, marginRight: "8px" }}
                    />{" "}
                    Product
                  </Button>
                )}
              </Paper>
            ))}

          <Paper sx={{ p: 2, my: 2 }}>
            {isExternalTotes && <Typography mb={2}>New Tote</Typography>}
            <Button
              variant={
                !createOrderContext.watch("lineItems").length
                  ? "contained"
                  : "outlined"
              }
              disabled={!selectedSku}
              color="primary"
              onClick={() => handleAddLineItem()}
              aria-label="Add Product to New Tote"
            >
              <Plus24Px
                style={{
                  fill: !createOrderContext.watch("lineItems").length
                    ? palette.buttonGroup.main
                    : palette.primary.main,
                  marginRight: "8px"
                }}
              />{" "}
              Product
            </Button>
          </Paper>
        </form>
      </FormProvider>
    </>
  );
};
