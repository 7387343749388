import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type PickedQuantityModalStatus = {
  isShown: boolean;
  pickedQuantity: number | null;
  selectedBinId: number | null;
};

type ConfirmPickQuantityModalState = {
  isShown: boolean;
  pickedQuantity: number | null;
  selectedBinId: number | null;
};

const initialState: ConfirmPickQuantityModalState = {
  isShown: false,
  pickedQuantity: null,
  selectedBinId: null
};

export const confirmPickQuantityModalSlice = createSlice({
  name: "confirmPickQuantityModal",
  initialState,
  reducers: {
    setPickedQuantityModalStatus(
      state,
      action: PayloadAction<PickedQuantityModalStatus>
    ) {
      state.isShown = action.payload.isShown;
      state.pickedQuantity = action.payload.pickedQuantity;
      state.selectedBinId = action.payload.selectedBinId;
    }
  }
});

export const { setPickedQuantityModalStatus } =
  confirmPickQuantityModalSlice.actions;
