import ASButton from "@locaisolutions/button";
import {
  ArrowDoubleRight20Px,
  Bookmark20Px,
  BookmarkFill20Px,
  CheckmarkCircle20Px,
  CloseCircle20Px,
  Edit20Px
} from "@locaisolutions/icons";
import { Stack } from "@mui/material";

import AutostoreBin from "~/components/autostore/autostoreBin";
import { usePortStatusQuery } from "~/hooks/usePortStatus";

import { InventoryMovementResponse } from "~/types/api";

import { BinNotEmptyButton } from "./BinNotEmptyButton";
import { BinReconfigurationButton } from "./BinReconfigurationButton";

export const BinMaintenancePort = ({
  portId,
  movementData
}: {
  portId: number;
  movementData: InventoryMovementResponse | undefined;
}) => {
  const {
    horizontalCompartmentCount,
    verticalCompartmentCount,
    error,
    activityState,
    binState,
    ...portStatus
  } = usePortStatusQuery(portId);

  const { selectedBin: thisBinNumber } = portStatus;
  const { sourceBin, destinationBin } = movementData || {};

  return (
    <Stack width={"100%"}>
      {!error && (
        <AutostoreBin
          state={activityState}
          binId={portStatus?.selectedBin}
          pickQuantity={""}
          pickCompartment={null}
          numberOfRows={horizontalCompartmentCount ?? 1}
          numberOfColumns={verticalCompartmentCount ?? 1}
        />
      )}
      {/* Todo: move these buttons and their modals to seperate components */}
      {sourceBin?.autostoreBinId === thisBinNumber && (
        <Stack direction="column" alignItems="center" spacing={4} my={3}>
          <ASButton
            kind="accent"
            size="large"
            startIcon={<ArrowDoubleRight20Px fill="white" />}
          >
            Move inventory
          </ASButton>
          <ASButton kind="subtle" size="large" startIcon={<Edit20Px />}>
            Adjust
          </ASButton>
          <ASButton kind="subtle" size="large" startIcon={<CloseCircle20Px />}>
            Cancel Movement
          </ASButton>
        </Stack>
      )}
      {destinationBin?.autostoreBinId === thisBinNumber && (
        <Stack direction="column" alignItems="center" spacing={4} my={3}>
          <ASButton
            kind="accent"
            size="large"
            startIcon={<CheckmarkCircle20Px fill="white" />}
            disabled
          >
            Confirm Move
          </ASButton>
          <BinNotEmptyButton
            state={activityState}
            binId={portStatus?.selectedBin}
            numberOfRows={horizontalCompartmentCount ?? 1}
            numberOfColumns={verticalCompartmentCount ?? 1}
            portId={portId}
            binState={binState}
          />
          <ASButton kind="subtle" size="large" startIcon={<Bookmark20Px />}>
            Flag Bin
          </ASButton>
        </Stack>
      )}
      {!!thisBinNumber &&
        destinationBin?.autostoreBinId !== thisBinNumber &&
        sourceBin?.autostoreBinId !== thisBinNumber && (
          <Stack direction="column" alignItems="center" spacing={4} my={3}>
            <ASButton
              kind="accent"
              size="large"
              startIcon={<BookmarkFill20Px fill="white" />}
            >
              Unflag Bin
            </ASButton>
            <BinNotEmptyButton
              state={activityState}
              binId={portStatus?.selectedBin}
              numberOfRows={horizontalCompartmentCount ?? 1}
              numberOfColumns={verticalCompartmentCount ?? 1}
              portId={portId}
              binState={binState}
            />
            {/* <ASButton kind="subtle" size="large" startIcon={<Bookmark20Px />}>
              Flag Bin
            </ASButton> */}
            {/* TODO: This should actually open the flag modal, which will have the option to open bin reconfig */}
            <BinReconfigurationButton binNumber={thisBinNumber} />
          </Stack>
        )}
    </Stack>
  );
};
