import { ButtonBase, Paper, Stack } from "@mui/material";

import { useAppSelector } from "~/app/store";
import { BatchSummaryDto } from "~/types/api";

import { BatchCardHeader } from "./BatchCardHeader";
import { BatchOrderSummaries } from "./BatchOrderSummaries";
import { TemperatureZoneIndicator } from "./TemperatureZoneIndicator";
import { selectBatches } from "./batchCards.slice";

export type BatchCardProps = {
  batchId: Guid;
  onBatchSelected: (batch: BatchSummaryDto) => void;
  selectedBatchId: Guid | null;
};

export function BatchCard(props: BatchCardProps) {
  const { onBatchSelected, selectedBatchId } = props;
  const batch = useAppSelector(selectBatches).find(
    (b) => b.batchId === props.batchId
  )!;
  const { batchName, batchId } = batch;

  const isSelected = batchId === selectedBatchId;

  return (
    <ButtonBase
      aria-label={`batch ${batchName}`}
      sx={{
        "&:hover": {
          cursor: "pointer"
        },
        width: "100%"
      }}
      onMouseDown={(e) => {
        // In a scenario where the user selects some text
        // And after clicking (this should deselect text) on the same element that containts already selected text
        // 'document.getSelection()' will return previously selected value
        // To cover this case, we need to clear the selected text (if any) each time when mouse down event occur.
        // e.button === 0 means a left click occurred
        if (document.getSelection()?.toString().length && e.button === 0)
          document.getSelection()?.removeAllRanges();
      }}
      onClick={(): void => {
        // If the user just wants to copy the desired text,
        // The row containing the desired text should not be selected
        if (!document.getSelection()?.toString().length) onBatchSelected(batch);
      }}
      disableRipple={true}
    >
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          userSelect: "text",
          ...(isSelected
            ? {
                backgroundColor: "primary.light",
                border: "3px solid",
                borderColor: "darkBlue.main"
              }
            : null)
        }}
      >
        <Stack direction="row">
          <TemperatureZoneIndicator batchId={batchId} />
          <Stack padding={1} width="100%">
            <BatchCardHeader batchId={batchId} isSelected={isSelected} />
            <BatchOrderSummaries batchId={batchId} />
          </Stack>
        </Stack>
      </Paper>
    </ButtonBase>
  );
}
