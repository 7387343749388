// libs
import SearchOffIcon from "@mui/icons-material/SearchOff";
import {
  Typography,
  Box,
  Paper,
  InputLabel,
  Select,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Chip,
  Pagination
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { Variant } from "@mui/material/styles/createTypography";
import { ReactElement, isValidElement, ReactNode, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

type AutostoreTableProps<RowType> = {
  tableKey?: string;
  title?: string;
  headerColNames?: (string | ReactNode)[];
  rowId: (row: RowType, i: number) => string;
  renderColumns: (
    | ((row: RowType) => string | React.ReactElement | React.ReactElement[])
    | null
  )[];
  widthOfCols: string[];
  headerVariant?: "inherit" | Variant;
  bodyVariant?: "inherit" | Variant;
  rowData: RowType[];
  selectedRow?: RowType | null;
  // only use when you want the selected row to be expanded
  renderSelectedRowExpand?: (row: RowType) => React.ReactElement | null;
  selectedRows?: Guid[];
  // highlighted rows are outlined but not technically selected, as selected often has unique logic to the component
  highlightedRows?: Guid[];
  alertRows?: Guid[];
  selectRowCallback?: (row: RowType, i: number) => void;
  loading: boolean;
  // useful for a table like CartTable where a selected row is not the expanded row
  expandedRows?: Guid[];
  renderExpandedRowsExpand?: (row: RowType) => React.ReactElement | null;
  reverse?: boolean;
  sort?: (a: RowType, b: RowType) => number;
  setLimitCb?: (newLimit: number) => void;
  limit?: number;
  /** Show applied filters as chip elements at the top of the table */
  filters?: string[];
  removeFilterCb?: (filterString: string) => void;
  noResults?: boolean;
  paginationCount?: number;
  paginationPage?: number;
  paginationOnChange?: (event: ChangeEvent<unknown>, page: number) => void;
  rowColorHighlight?: (row: RowType) => string;
};

function isReactElementWithKey(
  item: React.ReactNode
): item is ReactElement<{ "data-key": string }> {
  return isValidElement(item) && "data-key" in item.props;
}

function AutostoreTable<RowType>(props: AutostoreTableProps<RowType>) {
  const {
    title,
    headerColNames,
    rowId,
    renderColumns,
    widthOfCols,
    headerVariant,
    bodyVariant,
    rowData,
    selectedRow,
    selectedRows,
    highlightedRows,
    alertRows,
    selectRowCallback,
    loading = false,
    renderSelectedRowExpand,
    expandedRows,
    renderExpandedRowsExpand,
    reverse,
    sort,
    setLimitCb,
    limit,
    tableKey = "",
    filters,
    removeFilterCb,
    noResults = false,
    paginationCount,
    paginationPage,
    paginationOnChange,
    rowColorHighlight
  } = props;
  const { t } = useTranslation();

  const sortedRows = sort ? rowData.slice().sort(sort) : rowData;
  const rowsToDisplay = reverse ? [...sortedRows.reverse()] : sortedRows;
  const columnsToRender = renderColumns.filter(Boolean) as ((
    row: RowType
  ) => string | React.ReactElement | React.ReactElement[])[];

  const rowsMargin = "3px";
  const rowsPadding = "5px";

  return (
    <Box
      id="autostore-table"
      key={tableKey}
      sx={{
        height: "100%",
        width: "100%"
      }}
    >
      {/* setLimitCb is only used by Ship */}
      {setLimitCb && limit && (
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10
          }}
        >
          <Grid
            style={{
              width: 100,
              display: "flex",
              flexDirection: "row"
            }}
          >
            <InputLabel shrink />

            <Select<number>
              id="limit-select"
              value={limit}
              fullWidth
              onChange={(e: SelectChangeEvent<number>) =>
                setLimitCb(e.target.value as number)
              }
            >
              {[5, 10, 25, 50, 100, 250].map((numOption) => (
                <MenuItem key={numOption} value={numOption}>
                  {`${numOption}`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Box>
      )}

      {title && (
        <Box
          sx={{
            height: "10%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center"
          }}
        >
          <Typography fontSize="20px">{title}</Typography>
        </Box>
      )}

      <Paper
        sx={{
          pb: 1,
          backgroundColor: "gray.light",
          height: "90%"
        }}
      >
        {/* table container */}
        <Box
          data-testid="table-container"
          role="table"
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "gray.light",
            width: "100%",
            height: "100%",
            overflowY: "hidden",
            padding: "6px",
            boxSizing: "border-box",
            borderRadius: "10px"
          }}
        >
          {/* header row */}
          {headerColNames && (
            <Box
              data-testid="header-row"
              sx={{
                display: "flex",
                width: "100%",
                boxSizing: "border-box",
                border: "2px solid transparent"
              }}
            >
              {headerColNames &&
                headerColNames.map((colContent, index) => {
                  let key;

                  if (typeof colContent === "string") {
                    key = `header-${colContent}`;
                  } else if (isReactElementWithKey(colContent)) {
                    key = colContent.props["data-key"];
                  }

                  return (
                    <Box
                      key={key}
                      role="columnheader"
                      sx={{
                        width: widthOfCols[index],
                        overflow: "hidden",
                        boxSizing: "border-box",
                        paddingRight: 2,
                        paddingLeft: 2,
                        paddingTop: 1,
                        paddingBottom: 1
                      }}
                    >
                      {typeof colContent === "string" ? (
                        <Typography
                          sx={{
                            overflowWrap: "break-word",
                            fontWeight: 400,
                            color: "darkGray.main",
                            width: "100%"
                          }}
                          variant={headerVariant}
                        >
                          {colContent}
                        </Typography>
                      ) : (
                        colContent
                      )}
                    </Box>
                  );
                })}
            </Box>
          )}
          {/* table rows */}
          {!!filters?.length && (
            <Box sx={{ width: "100%", mb: 1 }}>
              {filters.map((filter) => (
                <Chip
                  key={filter}
                  label={filter}
                  color="primary"
                  onDelete={
                    removeFilterCb ? () => removeFilterCb(filter) : undefined
                  }
                  sx={{ mr: 2, fontSize: 14 }}
                />
              ))}
            </Box>
          )}
          <Box
            id="table-rows"
            data-testid="table-rows"
            sx={{
              height: "100%",
              width: "100%",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                display: "none"
              },
              // This is necessary for Firefox and other browsers that don't support ::-webkit-scrollbar
              scrollbarWidth: "none", // For Firefox
              msOverflowStyle: "none" // For Internet Explorer and Edge
            }}
          >
            {rowsToDisplay.length > 0 && !loading && (
              <>
                {rowsToDisplay.map((row, index) => {
                  const isSelected =
                    (selectedRow &&
                      rowId(selectedRow, index) === rowId(row, index)) ||
                    selectedRows?.includes(rowId(row, index));
                  const isHighlighted = highlightedRows?.includes(
                    rowId(row, index)
                  );
                  const isExpanded = expandedRows?.includes(rowId(row, index));
                  const isAlerted = alertRows?.includes(rowId(row, index));

                  let backgroundColor = "white";

                  if (isSelected) {
                    backgroundColor = "primary.light";
                  }
                  if (isHighlighted) {
                    backgroundColor = "primary.light";
                  }
                  if (isAlerted) {
                    backgroundColor = "warning.light";
                  }

                  return (
                    // table row and expanded row
                    <Box
                      role="row"
                      aria-selected={isSelected}
                      key={rowId(row, index)}
                      sx={(theme) => ({
                        width: "100%",
                        border: `2px solid ${
                          isSelected
                            ? theme.palette.primary.main
                            : theme.palette.gray.light
                        }`,
                        boxSizing: "border-box",
                        borderRadius: "10px",
                        marginBottom: rowsMargin,
                        marginTop: rowsMargin
                      })}
                    >
                      {/* table row */}
                      <Box
                        id={`row-${index}`}
                        key={rowId(row, index)}
                        sx={{
                          display: "flex",
                          backgroundColor,
                          cursor: selectRowCallback ? "pointer" : undefined,
                          borderRadius: "10px",
                          boxSizing: "border-box"
                        }}
                        onClick={() => {
                          if (selectRowCallback) {
                            selectRowCallback(row, index);
                          }
                        }}
                        role="button"
                        tabIndex={0}
                        onKeyDown={(event) => {
                          if (
                            (event.key === "Enter" || event.key === " ") &&
                            selectRowCallback
                          ) {
                            event.preventDefault();
                            selectRowCallback(row, index);
                          }
                        }}
                      >
                        {/* table columns */}
                        {columnsToRender.map((renderColumn, colIndex) => {
                          const cellContent = renderColumn(row);

                          return (
                            <Box
                              key={`cell-${colIndex}`}
                              role="cell"
                              sx={{
                                width: widthOfCols[colIndex],
                                fontWeight: 400,
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                overflow: "hidden",
                                boxSizing: "border-box",
                                paddingRight: 2,
                                paddingLeft:
                                  !!rowColorHighlight && colIndex === 0
                                    ? 4.5
                                    : 2,
                                paddingTop: 1,
                                paddingBottom: 1,
                                borderRadius: "10px",
                                position: "relative"
                              }}
                            >
                              {!!rowColorHighlight && colIndex === 0 && (
                                <Box
                                  sx={{
                                    width: 28,
                                    height: "100%",
                                    backgroundColor: rowColorHighlight(row),
                                    position: "absolute",
                                    left: 0
                                  }}
                                />
                              )}
                              {typeof cellContent === "string" ? (
                                <Typography
                                  variant={bodyVariant}
                                  style={{
                                    overflowWrap: "break-word",
                                    width: "100%",
                                    fontWeight: 400
                                  }}
                                >
                                  {cellContent}
                                </Typography>
                              ) : (
                                cellContent
                              )}
                            </Box>
                          );
                        })}
                      </Box>
                      {isSelected && renderSelectedRowExpand && (
                        <Box sx={{ width: "100%" }}>
                          {renderSelectedRowExpand(row)}
                        </Box>
                      )}
                      {isExpanded && renderExpandedRowsExpand && (
                        <Box sx={{ width: "100%" }}>
                          {renderExpandedRowsExpand(row)}
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </>
            )}
            {/* loading skeleton */}
            {loading && (
              <Box
                data-testid="loading-skeleton"
                sx={{ height: "100%", width: "100%" }}
              >
                {[1, 2, 3, 4, 5].map((num) => {
                  return (
                    <Box
                      key={`skeleton-row-${num}`}
                      sx={{
                        display: "flex",
                        backgroundColor: "white",
                        mx: rowsMargin,
                        my: `calc(${rowsMargin} + 4px)`,
                        borderRadius: "10px",
                        padding: rowsPadding,
                        height: "calc(20% - 4px)",
                        boxSizing: "border-box"
                      }}
                    >
                      {widthOfCols.map((width, i) => {
                        return (
                          <Skeleton
                            key={`skeleton-column-${i}`}
                            variant="text"
                            sx={{
                              width,
                              margin: 2
                            }}
                          ></Skeleton>
                        );
                      })}
                    </Box>
                  );
                })}
              </Box>
            )}
            {/* no results box */}
            {noResults && !rowsToDisplay.length && (
              <Box data-testid="no-results">
                <Box
                  sx={{ textAlign: "center", borderBottom: "none", padding: 5 }}
                >
                  <SearchOffIcon
                    sx={{ fontSize: "120px", color: "darkGray.light", ml: 1 }}
                    color="secondary"
                  />
                  <Typography
                    color="darkGray.light"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {t("no results")}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {paginationCount &&
        paginationCount > 1 &&
        paginationPage &&
        paginationOnChange ? (
          <Pagination
            count={paginationCount}
            page={paginationPage}
            onChange={paginationOnChange}
            shape="rounded"
            sx={{ width: "fit-content", margin: "0 auto" }}
          />
        ) : null}
      </Paper>
    </Box>
  );
}

export default AutostoreTable;
