import { Box, Grid, Stack, styled, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { t } from "i18next";

import { useAppSelector } from "~/app/store";
import AutostoreBin from "~/components/autostore/autostoreBin";
import {
  selectBinIsPresent,
  selectBinIsPresentBinId,
  selectLeftOrRightPortActive,
  selectPickingState,
  selectShowTwoBinComponents
} from "~/redux/selectors/autostoreSelectors";
import { selectPickQuantityForDisplay } from "~/redux/selectors/outOfStockSelectors";
import { selectStartPickingModalIsOpen } from "~/redux/selectors/pickingSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { useGetInventoryByIdQuery } from "~/redux/warehouse/inventory.hooks";
import { PickDto } from "~/types/api";

import { selectPickCompartment } from "./autostorePicking.slice";

const TwoBinContainer = styled(Grid)`
  display: flex;
  width: 150%;
  position: relative;
  left: -25%;
  margin-bottom: 30px;
`;

type Props = {
  currentPick: PickDto | null;
};

export default function PickingBins({ currentPick }: Props) {
  const binIsPresent = useAppSelector(selectBinIsPresent);
  const binIsPresentBinId = useAppSelector(selectBinIsPresentBinId);
  const showTwoBinComponents = useAppSelector(selectShowTwoBinComponents);
  const pickingState = useAppSelector(selectPickingState);
  const clientConfig = useAppSelector(selectClientConfig);
  const batchCompletedModalOpen = useAppSelector(selectStartPickingModalIsOpen);
  const leftOrRightPortActive = useAppSelector(selectLeftOrRightPortActive);
  const pickQuantityForDisplay = useAppSelector(selectPickQuantityForDisplay);
  const pickCompartment = useAppSelector(selectPickCompartment);

  const { ap_fusionPortScreenEnabled, ap_showPickQtyWhileBinLoaderIsActive } =
    clientConfig;
  const displayTwoBinComponents =
    ap_fusionPortScreenEnabled && showTwoBinComponents;

  const { data: inventory } = useGetInventoryByIdQuery(
    currentPick ? { inventoryId: currentPick.inventoryId } : skipToken,
    { refetchOnMountOrArgChange: true }
  );
  const isInventoryOnHold = !!inventory?.holds?.length;
  const isBinFlagged = inventory?.bin?.autostoreBin?.isFlagged;

  const numberOfColumns =
    pickingState?.binConfiguration?.verticalCompartmentCount;
  const numberOfRows =
    pickingState?.binConfiguration?.horizontalCompartmentCount;

  const getBinIdForDisplay = () =>
    isBinFlagged
      ? ` ${t("bin is flagged", {
          binNumber:
            currentPick?.pickBin?.autostoreBin?.autostoreBinId ||
            binIsPresentBinId
        })}`
      : `${t("bin")} ${
          currentPick?.pickBin?.autostoreBin?.autostoreBinId ||
          binIsPresentBinId
        }`;

  return (
    <>
      {displayTwoBinComponents ? (
        <TwoBinContainer>
          {(["Left", "Right"] as const).map((side) => {
            const isActive = side === leftOrRightPortActive;
            return (
              <Stack key={side} alignItems="center" flexGrow={1}>
                <AutostoreBin
                  state={
                    batchCompletedModalOpen
                      ? "Port Closed"
                      : !binIsPresent
                        ? "Waiting for Bin"
                        : isActive
                          ? "Bin Opened"
                          : "Inactive"
                  }
                  numberOfColumns={(isActive && numberOfColumns) || 1}
                  numberOfRows={(isActive && numberOfRows) || 1}
                  isBinFlagged={isActive ? isBinFlagged : undefined}
                  isSelectedCompartmentInventoryOnHold={
                    isActive ? isInventoryOnHold : undefined
                  }
                  pickCompartment={pickCompartment}
                  pickQuantity={pickQuantityForDisplay}
                  binId={
                    currentPick?.pickBin?.autostoreBin?.autostoreBinId ||
                    binIsPresentBinId
                  }
                  showPickQtyWhileBinLoaderIsActive={
                    ap_showPickQtyWhileBinLoaderIsActive
                  }
                  hideBinId
                />
                {isActive && binIsPresent && (
                  <Box style={{ height: 25 }}>
                    <Typography
                      variant="h5"
                      textAlign="center"
                      data-testid="bin-id-text"
                    >
                      {getBinIdForDisplay()}
                    </Typography>
                  </Box>
                )}
              </Stack>
            );
          })}
        </TwoBinContainer>
      ) : (
        <Stack alignItems="center">
          <AutostoreBin
            state={
              batchCompletedModalOpen
                ? "Port Closed"
                : !binIsPresent
                  ? "Waiting for Bin"
                  : "Bin Opened"
            }
            numberOfColumns={numberOfColumns || 1}
            numberOfRows={numberOfRows || 1}
            pickCompartment={pickCompartment}
            pickQuantity={pickQuantityForDisplay}
            binId={currentPick?.pickBin?.autostoreBin?.autostoreBinId}
            showPickQtyWhileBinLoaderIsActive={
              ap_showPickQtyWhileBinLoaderIsActive
            }
            isBinFlagged={isBinFlagged}
            isSelectedCompartmentInventoryOnHold={isInventoryOnHold}
            hideBinId
          />
        </Stack>
      )}
      <Box style={{ height: 25 }}>
        {!ap_fusionPortScreenEnabled && binIsPresent && (
          <Typography variant="h5" textAlign="center">
            {getBinIdForDisplay()}
          </Typography>
        )}
      </Box>
    </>
  );
}
