import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Grid,
  Typography,
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem
} from "@mui/material";

import { useState, useRef, useEffect } from "react";

export type SplitButtonProps = {
  buttonLabel?: string;
  options: {
    label: string;
    value: string;
  }[];
  clickCb: (value: string) => void;
  switchCb?: (value: string) => void;
  selectedValue?: string;
};

export function SplitButton(props: SplitButtonProps) {
  const { buttonLabel, options, selectedValue } = props;
  const selectedOption = options.find(
    (option) => option.value === selectedValue
  );

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = () => {
    props.clickCb(options[selectedIndex].value);
  };

  const handleMenuItemClick = (
    option: {
      label: string;
      value: string;
    },
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    if (props.switchCb) props.switchCb(option.value);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose: (event: MouseEvent | TouchEvent) => void = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (selectedOption) {
      setSelectedIndex(options.indexOf(selectedOption));
    }
  }, [options, selectedOption]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ width: "unset" }}
    >
      <Grid
        style={{ flexGrow: 1, display: "flex", justifyContent: "flex-start" }}
      >
        {buttonLabel && (
          <Typography style={{ fontSize: 12 }}>{buttonLabel}</Typography>
        )}
      </Grid>

      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
        style={{ minHeight: 50 }}
      >
        <Button onClick={handleClick}>
          {options[selectedIndex]?.label || ""}
        </Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{
          zIndex: 10000
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.value}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(option, index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}
