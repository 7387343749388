import {
  Avatar,
  Box,
  Collapse,
  Grid,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { ErrorSuccessSnackbar } from "~/components/ErrorSuccessSnackbar";
import PickStatusIcon from "~/components/PickStatusIcon";
import PrintableToteLabels from "~/components/PrintableToteLabels";
import ScanningIndicator, {
  useScanIndicator
} from "~/components/ScanningIndicator";
import { TinyIconGenerator } from "~/components/TinyIconGenerator";
import AutostoreTable from "~/components/autostore/table/AutostoreTable";

import { CartDisplay } from "~/features/batch/Pick";
import { toteLabelZPL } from "~/features/batch/toteLabelZpl";

import { useNavbar } from "~/hooks/useNavbar";
import { useView } from "~/hooks/useView";
import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import { mobileWidth } from "~/lib/theme";

import {
  getPosition,
  getToteOrLoosePickId,
  getType,
  ToteOrLoosePick
} from "~/lib/toteOrLoosePick";

import {
  clearErrorMessage as clearPrinterError,
  printToDefaultPrinter
} from "~/redux/actions/printer";
import {
  clearErrorMessage,
  getBatch,
  getLoosePicksByBatch,
  getStagingAreas,
  getTotesByBatch,
  resetStageView,
  stageLoosePicks,
  stageTotes
} from "~/redux/actions/stage";
import { StoreState } from "~/redux/reducers";

import {
  batchTotesAndLoosePicks,
  getToteLabels,
  getUnstagedTotes,
  selectedTotesAndLoosePicks as getSelectedTotesAndLoosePicks,
  singleSelectedToteOrLoosePick
} from "~/redux/selectors/stageTotesSelectors";
import { ToteSummaryDto } from "~/types/api";

import { CompletionModal } from "./CompletionModal";
import { StageTotesToolbar } from "./StageTotesToolbar";
import { LoosePick } from "./loosePick.type";
import {
  clearSelectedToteIds,
  getSelectedToteIds,
  selectToteIds
} from "./stageTotes.slice";

const mapStateToProps = (state: StoreState) => ({
  batch: state.stage.batch,
  loadingBatchLoosePicks: state.stage.loadingBatchLoosePicks,
  batchTotes: state.stage.batchTotes,
  loadingBatchTotes: state.stage.loadingBatchTotes,
  errorMessage: state.stage.errorMessage,
  loadingBatch: state.stage.loadingBatch,
  batchLoosePicksFetched: state.stage.batchLoosePicksFetched,
  batchTotesFetched: state.stage.batchTotesFetched,
  loadingStagingAreas: state.stage.loadingStagingAreas,
  stagingAreas: state.stage.stagingAreas,
  fulfillmentCenter: state.store.usersFulfillmentCenter,
  defaultPrinter: state.printer.defaultPrinter,
  printerError: state.printer.error,
  auth0Profile: state.login && state.login.profile
});

const connector = connect(mapStateToProps, {
  getBatch,
  getStagingAreas,
  stageLoosePicks,
  stageTotes,
  getLoosePicksByBatch,
  getTotesByBatch,
  clearErrorMessage,
  resetStageView,
  clearPrinterError,
  printToDefaultPrinter
});
type PropsFromRedux = ConnectedProps<typeof connector>;
type StageTotesInheritedProps = { viewTitle?: string };
export type StageTotesProps = PropsFromRedux & StageTotesInheritedProps;

export function StageTotes(props: StageTotesProps) {
  const {
    batch,
    loadingBatchTotes,
    loadingBatchLoosePicks,
    batchTotes,
    errorMessage,
    batchLoosePicksFetched,
    batchTotesFetched,
    fulfillmentCenter,
    printerError,
    defaultPrinter,
    viewTitle
  } = props;

  const printerConfiguration = fulfillmentCenter?.printerConfiguration;

  const { t } = useTranslation();
  const isMobile = useMediaQuery(mobileWidth);
  const dispatch = useAppDispatch();
  useView({ permanentSidenav: true });
  const { setToolbar, setMenuItems } = useNavbar({ viewTitle });

  const toteLabels = useAppSelector(getToteLabels);
  const totesAndLoosePicks = useAppSelector(batchTotesAndLoosePicks);
  const selectedTotesAndLoosePicks = useAppSelector(
    getSelectedTotesAndLoosePicks
  );
  const selectedToteIds = useAppSelector(getSelectedToteIds);
  // show "View Totes" button only if one order is selected
  const singleSelectedTote = useAppSelector(singleSelectedToteOrLoosePick);
  const unstagedTotes = useAppSelector(getUnstagedTotes);

  const printComponentRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePagePrint = useCallback(
    useReactToPrint({
      pageStyle: `
    @page {
      margin: 0
    }
  `,
      content: () => printComponentRef.current
    }),
    [printComponentRef]
  );

  const handleBrowserPrint = useCallback(() => {
    const labelsZPL =
      toteLabels &&
      toteLabels?.map((label) => {
        const {
          zone,
          position = "",
          toteId,
          externalOrderId,
          putwallPosition,
          orderType
        } = label;
        const first = label.firstName || "";
        const last = label.lastName || "";
        return toteLabelZPL({
          zone,
          cartPosition: `${position}`,
          toteId,
          putwallPosition,
          customerName: `${first || ""} ${last || ""}`,
          orderType,
          externalOrderId
        });
      });
    if (!defaultPrinter && labelsZPL)
      dispatch(printToDefaultPrinter(labelsZPL.join()));
    if (labelsZPL && defaultPrinter) {
      defaultPrinter.send(labelsZPL.join());
    }
  }, [defaultPrinter, dispatch, toteLabels]);

  const handlePrint = useCallback(() => {
    if (printerConfiguration === "BrowserPrint") {
      handleBrowserPrint();
    } else if (printerConfiguration === "PagePrint") {
      handlePagePrint();
    }
  }, [handleBrowserPrint, handlePagePrint, printerConfiguration]);

  useEffect(() => {
    setMenuItems([
      {
        textContent: t("select all"),
        actionCb: () => {
          dispatch(
            selectToteIds(
              unstagedTotes.map((tote) => getToteOrLoosePickId(tote))
            )
          );
        }
      },
      {
        textContent: t("print labels"),
        actionCb: () => (handlePrint ? handlePrint() : null)
      }
    ]);
  }, [dispatch, handlePrint, setMenuItems, t, unstagedTotes]);

  // component did unmount
  useEffect(
    () => () => {
      props.resetStageView();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [completeModalIsOpen, setCompleteModalIsOpen] =
    useState<boolean>(false);

  const { batchName = "" } = useParams<{ batchName: string }>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    props.getStagingAreas();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    props.getBatch(batchName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchName]);

  useEffect(() => {
    if (batch) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getLoosePicksByBatch(batch.batchId);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getTotesByBatch(batch.batchId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batch]);

  useEffect(() => {
    if (
      batchTotesFetched &&
      batchLoosePicksFetched &&
      totesAndLoosePicks.filter((toteOrLoosePick) =>
        ["picked", "dropped"].includes(toteOrLoosePick.status.toLowerCase())
      ).length === 0
    ) {
      setCompleteModalIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchTotesFetched, batchLoosePicksFetched]);

  // combine tote, order, and areaIds

  const handleStageTotes = useCallback(async () => {
    if (!selectedTotesAndLoosePicks.length) return;
    const putWallIdsAndPutWallLaneIds = selectedTotesAndLoosePicks.map(
      (loosePick) => {
        return {
          putWallId: loosePick.putWallId,
          putWallLaneId: loosePick.putWallLaneId
        };
      }
    );
    const uniquePutWallIdsAndPutWallLaneIds =
      putWallIdsAndPutWallLaneIds.filter((putWallIdAndPutWallLaneId, index) => {
        return (
          index ===
          putWallIdsAndPutWallLaneIds.findIndex(
            (putWall) =>
              putWallIdAndPutWallLaneId.putWallId === putWall.putWallId &&
              putWallIdAndPutWallLaneId.putWallLaneId === putWall.putWallLaneId
          )
        );
      });

    for (const putWall of uniquePutWallIdsAndPutWallLaneIds) {
      if (putWall.putWallId && putWall.putWallLaneId) {
        const loosePickIds = selectedTotesAndLoosePicks
          .filter(
            (selectedToteAndLoosePick) =>
              selectedToteAndLoosePick.type.toLowerCase() === "loosepick" &&
              selectedToteAndLoosePick.putWallId === putWall.putWallId &&
              selectedToteAndLoosePick.putWallLaneId === putWall.putWallLaneId
          )
          .map((loosePick) => loosePick.toteOrLoosePickId);
        const toteIds = selectedTotesAndLoosePicks
          .filter(
            (selectedToteAndLoosePick) =>
              selectedToteAndLoosePick.type.toLowerCase() === "tote" &&
              selectedToteAndLoosePick.putWallId === putWall.putWallId &&
              selectedToteAndLoosePick.putWallLaneId === putWall.putWallLaneId
          )
          .map((tote) => tote.toteOrLoosePickId);
        if (loosePickIds.length)
          await dispatch(
            stageLoosePicks({
              putWallId: putWall.putWallId,
              putWallLaneId: putWall.putWallLaneId,
              loosePickIds
            })
          );
        if (toteIds.length)
          await dispatch(
            stageTotes({
              putWallId: putWall.putWallId,
              putWallLaneId: putWall.putWallLaneId,
              toteIds
            })
          );
      }
    }

    dispatch(clearSelectedToteIds());
    await dispatch(getBatch(batchName));
  }, [batchName, dispatch, selectedTotesAndLoosePicks]);

  useEffect(() => {
    if (selectedToteIds.length > 0) {
      setToolbar(<StageTotesToolbar handleStageTotes={handleStageTotes} />);
    } else {
      setToolbar(undefined);
    }
  }, [handleStageTotes, selectedToteIds.length, setToolbar]);

  const toggleToteSelected = (toteId: Guid) => {
    if (selectedToteIds.includes(toteId)) {
      dispatch(selectToteIds(selectedToteIds.filter((id) => id !== toteId)));
    } else {
      dispatch(selectToteIds([...selectedToteIds, toteId]));
    }
  };

  const isBulk = batch?.batchType === "Bulk";

  const [scanState, setScanState] = useScanIndicator();
  const [scannedBarcode, setScannedBarcode] = useState<string | null>(null);

  useKeyDownHandler();
  useBarcodeScanner<ToteOrLoosePick | false>({
    findScanMatch: (buffer: string) => {
      setScannedBarcode(buffer);
      setScanState("success");
      // if tote is selected, assume scan is for putwall
      if (singleSelectedTote) {
        const expectedPutwallBarcode = `${singleSelectedTote.putWallIdentifier}${singleSelectedTote.putWallLaneIdentifier}`;

        const returnValue =
          expectedPutwallBarcode.toLowerCase() === buffer.toLowerCase()
            ? singleSelectedTote
            : false;
        return returnValue;
      }

      // if no tote is selected, look for matching tote/loose-pick
      const matchingToteOrPick = unstagedTotes.find(
        (tote) =>
          getToteOrLoosePickId(tote).toLowerCase() === buffer.toLowerCase()
      );
      if (matchingToteOrPick) {
        return matchingToteOrPick;
      }
      return false;
    },
    processScanMatch: (match): boolean => {
      if (!match) return false;

      // putwall scan
      if (singleSelectedTote) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        handleStageTotes();
        return true;
      }

      // tote scan
      dispatch(selectToteIds([getToteOrLoosePickId(match)]));
      return true;
    },
    deps: [totesAndLoosePicks, singleSelectedTote]
  });

  const toteColumnText = isBulk ? t("pick status") : t("tote status");

  return (
    <Grid
      container
      direction="column"
      style={{
        marginTop: 20,
        flexWrap: "nowrap",
        minHeight: "100vh",
        maxWidth: "100vw"
      }}
    >
      <Grid
        container
        direction="column"
        style={{ height: "100%", flexWrap: "nowrap" }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            height: "100%"
          }}
        >
          <Box sx={{ width: isMobile ? "99%" : "90%", height: "100%" }}>
            <AutostoreTable<ToteOrLoosePick>
              widthOfCols={
                !isMobile ? ["20%", "35%", "20%", "25%"] : ["20%", "45%", "35%"]
              }
              headerColNames={[
                t("putwall"),
                t("order"),
                !isBulk ? t("position") : "",
                !isMobile ? toteColumnText : ""
              ].filter((el) => el)}
              rowId={(row: ToteOrLoosePick) => getToteOrLoosePickId(row)}
              renderColumns={[
                (row: ToteOrLoosePick) => {
                  return row.putWallIdentifier ? (
                    <Avatar
                      sx={{
                        backgroundColor: "info.main",
                        width: 50,
                        height: 50
                      }}
                    >
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "row"
                        }}
                      >
                        {row.putWallIdentifier} {row.putWallLaneIdentifier}
                      </Grid>
                    </Avatar>
                  ) : (
                    ""
                  );
                },
                (row: ToteOrLoosePick) => `${row.customerName || ""}`,
                (row: ToteOrLoosePick) => {
                  return !isBulk ? (
                    <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
                      {fulfillmentCenter &&
                        (getType(row) === "Tote" ? (
                          <CartDisplay
                            cartCapacity={fulfillmentCenter?.cartCapacity}
                            totePosition={getPosition(row) as number}
                            loading={
                              loadingBatchLoosePicks || loadingBatchTotes
                            }
                            batchTotes={batchTotes}
                            toteBoxSize={isMobile ? "1em" : "2.2em"}
                            toteBoxSpacing={isMobile ? "0 2px" : "0 4px"}
                          />
                        ) : (
                          <Typography>{getPosition(row)}</Typography>
                        ))}
                    </Box>
                  ) : (
                    ""
                  );
                },
                (row: ToteOrLoosePick) => {
                  return !isMobile ? (
                    <Box
                      style={{
                        display: "flex",
                        height: 40,
                        alignItems: "center"
                      }}
                    >
                      <Grid
                        container
                        alignItems="center"
                        direction="row"
                        wrap="nowrap"
                      >
                        <div key={getToteOrLoosePickId(row)}>
                          {TinyIconGenerator({
                            tempZone: row.temperatureZone.toLowerCase() as
                              | "ambient"
                              | "chilled"
                              | "frozen",
                            type: getType(row),
                            key: getToteOrLoosePickId(row) || "",
                            status: row.status
                          })}
                        </div>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  );
                }
              ].filter((el) => el)}
              rowData={totesAndLoosePicks
                .filter(
                  (tote) =>
                    tote.status.toLowerCase() === "picked" ||
                    tote.status.toLowerCase() === "dropped"
                )
                .sort(
                  (a, b) =>
                    ((a as ToteSummaryDto).totePosition || 0) -
                    ((b as ToteSummaryDto).totePosition || 0)
                )}
              selectedRows={selectedToteIds}
              selectRowCallback={(row: ToteOrLoosePick) => {
                const toteId = getToteOrLoosePickId(row);

                toggleToteSelected(toteId);
              }}
              renderSelectedRowExpand={(row: ToteOrLoosePick) => {
                const pickForTote = batch?.picks.find(
                  (pick) =>
                    (isBulk && pick.pickId === (row as LoosePick).pickId) ||
                    pick.assignedToteId === (row as ToteSummaryDto).toteId
                );
                const pickName = pickForTote?.name;
                const pickQty = pickForTote?.quantity.value;

                return isBulk && pickForTote && pickQty && pickName ? (
                  <Collapse in timeout="auto" unmountOnExit>
                    <Box
                      key={pickForTote.pickId}
                      display="flex"
                      justifyContent="center"
                      paddingTop="6px"
                      paddingBottom="8px"
                    >
                      <Box display="flex" alignItems="center">
                        <PickStatusIcon pick={pickForTote} />
                        <Typography variant="body2" style={{ paddingLeft: 8 }}>
                          {`${pickName} x ${pickQty}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Collapse>
                ) : null;
              }}
              loading={loadingBatchLoosePicks}
              noResults={
                totesAndLoosePicks.filter(
                  (tote) =>
                    tote.status.toLowerCase() === "picked" ||
                    tote.status.toLowerCase() === "dropped"
                ).length === 0
              }
            />
          </Box>
        </Box>
      </Grid>
      <ErrorSuccessSnackbar
        successMessage={null}
        errorMessage={errorMessage || printerError}
        clearSuccessMessage={(): void => {
          /* No success messages yet */
        }}
        clearErrorMessage={() => {
          if (errorMessage) props.clearErrorMessage();
          if (printerError) props.clearPrinterError();
        }}
      />
      <CompletionModal isOpen={completeModalIsOpen} />
      {printerConfiguration && printerConfiguration === "PagePrint" && (
        <div style={{ display: "none" }}>
          {!!toteLabels?.length && (
            <PrintableToteLabels labels={toteLabels} ref={printComponentRef} />
          )}
        </div>
      )}
      <ScanningIndicator
        scanState={scanState}
        scannedBarcode={scannedBarcode}
        placeholderText={
          singleSelectedTote ? "Scan Putwall" : "Scan Tote or Loose Pick"
        }
      />
    </Grid>
  );
}

export default connector(StageTotes);
