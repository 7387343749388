import ASButton from "@locaisolutions/button";
import {
  CheckmarkCircle20Px,
  CloseCircle20Px,
  Warning20Px
} from "@locaisolutions/icons";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from "@mui/material";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import AutostoreBin from "~/components/autostore/autostoreBin";
import { useToast } from "~/hooks/useToast";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";
import { useReconfigureBinCompartmentsMutation } from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetBinConfigurationsQuery } from "~/redux/warehouse/bin.hooks";

export function BinReconfigurationButton({ binNumber }: { binNumber: number }) {
  const {
    data: availableBinConfigurations,
    error: availableBinConfigurationsError
  } = useGetBinConfigurationsQuery();

  const { errorToast, successToast } = useToast();
  const { t } = useTranslation();

  const selectedWorkstation = useAppSelector(selectThisWorkstation);
  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const [reconfigureBinCompartments, { isLoading }] =
    useReconfigureBinCompartmentsMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedConfiguration, setSelectedConfiguration] = useState<
    null | number
  >(null);

  if (availableBinConfigurationsError)
    errorToast(getMessageFromRtkError(availableBinConfigurationsError));

  const handleReconfigureCompartments = async () => {
    if (!selectedConfiguration) return;
    const selectedBinConfiguration = availableBinConfigurations?.find(
      (bc) => bc.configurationType === selectedConfiguration
    );

    if (!selectedWorkstation || !autostoreGridId || !selectedBinConfiguration) {
      return;
    }
    try {
      await reconfigureBinCompartments({
        autostoreGridId,
        binNumber,
        configurationId: selectedBinConfiguration.configurationId,
        workstationId: selectedWorkstation.id
      }).unwrap();

      successToast(
        `Successfully reconfigured bin to have ${selectedBinConfiguration.numberOfCompartments} compartments`
      );
      setIsModalOpen(false);
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  return (
    <>
      <ASButton
        kind="subtle"
        size="large"
        startIcon={<Warning20Px />}
        onClick={() => setIsModalOpen(true)}
      >
        {t("misconfigured bin")}
      </ASButton>
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        maxWidth="xl"
      >
        <DialogTitle>{t("misconfigured bin")} </DialogTitle>
        <DialogContent>
          <Stack direction="column" gap={2} mt={2} alignItems="center">
            <Typography variant="h5" mt={2}>
              {t("select bin configuration")}
            </Typography>
            <Stack direction="row" gap={1}>
              {availableBinConfigurations?.map((conf) => (
                <Fragment key={conf.configurationId}>
                  <Box
                    aria-label={
                      selectedConfiguration === conf.configurationType
                        ? "selected-bin"
                        : "unselected-bin"
                    }
                    sx={{
                      padding: 1,
                      borderRadius: "0.5em",
                      border: `0.625em solid`,
                      borderColor:
                        selectedConfiguration === conf.configurationType
                          ? "primary.main"
                          : "white"
                    }}
                  >
                    <AutostoreBin
                      state={"Port Closed"}
                      pickQuantity={0}
                      pickCompartment={null}
                      numberOfRows={conf.horizontalCompartmentCount}
                      numberOfColumns={conf.verticalCompartmentCount}
                      hideBinId
                      onBinClick={() =>
                        setSelectedConfiguration(conf.configurationType)
                      }
                    />
                  </Box>
                </Fragment>
              ))}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mb: 2 }}>
          <Stack justifyContent="center" direction="row" width="100%" gap={1}>
            <ASButton
              kind="subtle"
              size="large"
              startIcon={<CloseCircle20Px />}
              onClick={() => setIsModalOpen(false)}
            >
              {t("cancel")}
            </ASButton>
            <ASButton
              kind="accent"
              size="large"
              startIcon={<CheckmarkCircle20Px fill="white" />}
              disabled={!selectedConfiguration || isLoading}
              onClick={handleReconfigureCompartments}
            >
              {t("confirm")}
            </ASButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
