import { TextField, Autocomplete } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

import { warehouseService } from "~/api/warehouse";
import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import { CartDto } from "~/types/api";

type CartInputProps = {
  setPreppedCartNumberCb: (preppedCartNumber: string | null) => void;
  placeholder: string;
};

export function CartSearchInput(props: CartInputProps) {
  const { placeholder } = props;

  const [selectedCart, setSelectedCart] = useState<CartDto | null>(null);
  const [options, updateOptions] = useState<CartDto[] | []>([]);
  const [carts, setCarts] = useState<CartDto[] | []>([]);
  const [inputValue, setInputValue] = useState<string | null>();

  const searchCarts = async (): Promise<void> => {
    const cartSearch = await warehouseService.get<CartDto[]>(`/api/carts`);
    if (cartSearch.data.length === 1) {
      setSelectedCart(cartSearch.data[0]);
    }

    const sortedCarts = cartSearch.data.sort((a, b) =>
      parseInt(a.cartNumber, 10) > parseInt(b.cartNumber, 10) ? 1 : -1
    );

    setCarts(sortedCarts);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    searchCarts();
  }, []);

  const autocompleteCartSearch = (input: string | null) => {
    setInputValue(input);
    if (!input) {
      props.setPreppedCartNumberCb(null);
      updateOptions(carts);
      return;
    }

    setSelectedCart(null);
    if (input?.length) {
      const autocompleteCart = carts.filter((c) =>
        c.cartNumber.includes(input)
      );
      if (autocompleteCart.length > 0) {
        updateOptions(autocompleteCart);
        props.setPreppedCartNumberCb(input);
        return;
      }
      props.setPreppedCartNumberCb(input);
      updateOptions([]);
      return;
    }
    updateOptions(carts);
  };

  useKeyDownHandler();
  useBarcodeScanner<CartDto | undefined>({
    findScanMatch: (buffer: string) => {
      const cartMatch = carts.find((cart) => cart.cartNumber === buffer);
      setInputValue(buffer);
      return cartMatch;
    },
    processScanMatch: (cartMatch) => {
      if (cartMatch) {
        props.setPreppedCartNumberCb(cartMatch.cartNumber);
        setSelectedCart(cartMatch);
      }
    },
    deps: [carts]
  });

  return (
    <Autocomplete<CartDto>
      options={options}
      id="cart-search"
      filterOptions={(ops) => ops}
      getOptionLabel={(option: CartDto): string => option.cartNumber}
      style={{
        background: "white",
        margin: "14px 3px"
      }}
      onChange={(_e: ChangeEvent<unknown>, option: CartDto | null): void => {
        autocompleteCartSearch(option ? option.cartNumber.toString() : null);
        setSelectedCart(option);
      }}
      inputValue={inputValue || ""}
      value={selectedCart}
      popupIcon={null}
      blurOnSelect
      ListboxProps={{ style: { maxHeight: "11rem" } }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e): void => {
            autocompleteCartSearch(e.target.value.trim());
          }}
          placeholder={placeholder}
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
}
