import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

type ErrorSuccessSnackbarProps = {
  successMessage?: string | null;
  errorMessage?: string | string[] | null;
  clearSuccessMessage: () => void;
  clearErrorMessage: () => void;
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function ErrorSuccessSnackbar(props: ErrorSuccessSnackbarProps) {
  const {
    successMessage,
    errorMessage,
    clearErrorMessage,
    clearSuccessMessage
  } = props;

  const formatErrorMessageToDisplay = (
    errorMessageToFormat: string | string[]
  ) => {
    if (typeof errorMessageToFormat === "string") {
      return errorMessageToFormat.split(".").map((line) => (
        <span key={line}>
          {line}
          <br />
        </span>
      ));
    }
    if (typeof errorMessageToFormat === "object") {
      return errorMessageToFormat.concat(" ").map((line) => (
        <span key={line}>
          {line}
          <br />
        </span>
      ));
    }

    return errorMessageToFormat;
  };

  return (
    <>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={clearSuccessMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        ClickAwayListenerProps={{ onClickAway: () => null }}
      >
        <div>
          <Alert onClose={clearSuccessMessage} severity="success">
            {successMessage}
          </Alert>
        </div>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={clearErrorMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        ClickAwayListenerProps={{ onClickAway: () => null }}
      >
        <div>
          <Alert onClose={clearErrorMessage} severity="error">
            {errorMessage && formatErrorMessageToDisplay(errorMessage)}
          </Alert>
        </div>
      </Snackbar>
    </>
  );
}
