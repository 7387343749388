import {
  handleWarehouseErrorAsyncThunk,
  warehouseService
} from "~/api/warehouse";
import { ProductSearchProduct } from "~/types/api";

/**
 * Call the Qubit Backend API to search for products.
 *
 * @searchText The search term(s) to find matching products for.
 */
export const searchProductsFromWarehouseApi = async (
  searchText: string
): Promise<ProductSearchProduct[] | string> => {
  try {
    const response = await warehouseService.get<ProductSearchProduct[]>(
      `/api/variants/search-products/${searchText}`
    );
    return response.data;
  } catch (err: unknown) {
    return handleWarehouseErrorAsyncThunk(err);
  }
};
