import { AlertColor } from "@mui/material";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { clearSingleUserMessage, clearUserMessage } from "~/redux/actions/site";
import { selectUserMessages } from "~/redux/selectors/siteSelectors";

import { useToast } from "./useToast";

const defaultDuration: { [Property in AlertColor]: number } = {
  error: 10000,
  warning: 10000,
  info: 3000,
  success: 3000
};

// A temporary solution as we move from the old MessagePopper component/userMessage redux state, to the useToast hook
export function useMessagePopper(): void {
  const dispatch = useAppDispatch();
  const userMessages = useAppSelector(selectUserMessages);
  // put the oldest messages at the bottom
  const messages = [...userMessages].sort(
    (a, b) => (b?.timestamp ?? 0) - (a?.timestamp ?? 0)
  );

  const { errorToast, warningToast, infoToast, successToast } = useToast();

  useEffect(() => {
    messages.map((message) => {
      const { title, description, autohideDuration, severity, id } = message;
      const duration =
        autohideDuration || (severity && defaultDuration[severity]);
      if (message.severity === "error") {
        errorToast(title || "", {
          description,
          duration
        });
      } else if (message.severity === "warning") {
        warningToast(title || "", {
          description: description,
          duration
        });
      } else if (message.severity === "info") {
        infoToast(title || "", {
          description: description,
          duration
        });
      } else if (message.severity === "success") {
        successToast(title || "", {
          description: description,
          duration
        });
      }
      // remove message from redux after its been forwarded to useToast
      dispatch(clearUserMessage(id));
    });
  }, [messages, errorToast, warningToast, infoToast, successToast, dispatch]);

  // single messages are rendered in the center of the screen with an optional greyed out background
  const singleUserMessage = useAppSelector(
    (state) => state.site.singleUserMessage
  );
  useEffect(() => {
    if (!singleUserMessage) return;
    const { title, description, autohideDuration, severity, withMask } =
      singleUserMessage;
    const duration =
      autohideDuration || (severity && defaultDuration[severity]);
    if (singleUserMessage.severity === "error") {
      errorToast(title || "", {
        description,
        duration,
        withMask,
        isSingleMessage: true
      });
    } else if (singleUserMessage.severity === "warning") {
      warningToast(title || "", {
        description: description,
        duration,
        withMask,
        isSingleMessage: true
      });
    } else if (singleUserMessage.severity === "info") {
      infoToast(title || "", {
        description: description,
        duration,
        withMask,
        isSingleMessage: true
      });
    } else if (singleUserMessage.severity === "success") {
      successToast(title || "", {
        description: description,
        duration,
        withMask,
        isSingleMessage: true
      });
    }
    // remove message from redux after its been forwarded to useToast
    dispatch(clearSingleUserMessage());
  }, [
    singleUserMessage,
    errorToast,
    warningToast,
    infoToast,
    successToast,
    dispatch
  ]);
}
