import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppSelector } from "~/app/store";
import { convertHolds, external, getCommonHolds } from "~/lib/helpers";
import { StoreState } from "~/redux/reducers";
import { InventorySummaryDto } from "~/types/api";

type InventoryHoldsState = {
  isInventoryAdjustDialogOpen: boolean;
  isBinHoldModalOpen: boolean;
  selectedRow: Guid | null;
};

const initialState: InventoryHoldsState = {
  isInventoryAdjustDialogOpen: false,
  isBinHoldModalOpen: false,
  selectedRow: null
};

export const inventoryHoldsSlice = createSlice({
  name: "inventoryHolds",
  initialState,
  reducers: {
    setIsInventoryAdjustDialogOpen(state, { payload }: PayloadAction<boolean>) {
      state.isInventoryAdjustDialogOpen = payload;
    },
    setIsBinHoldModalOpen(state, { payload }: PayloadAction<boolean>) {
      state.isBinHoldModalOpen = payload;
    },
    setSelectedRow(state, { payload }: PayloadAction<Guid | null>) {
      state.selectedRow = payload;
    }
  }
});

export const {
  setIsInventoryAdjustDialogOpen,
  setIsBinHoldModalOpen,
  setSelectedRow
} = inventoryHoldsSlice.actions;

export const selectSelectedRow = (state: StoreState) =>
  state.inventoryHolds.selectedRow;
export const selectInventorySummaries = (state: StoreState) =>
  state.inventory.inventorySummaries;

export const selectSelectedSummary: AppSelector<InventorySummaryDto | null> =
  createSelector(
    [selectSelectedRow, selectInventorySummaries],
    (selectedRow, inventorySummaries) =>
      (selectedRow &&
        inventorySummaries.find(
          (summary) => summary.inventoryId === selectedRow
        )) ||
      null
  );

export const selectCommonHoldsForSelectedSummary: AppSelector<HoldType[]> =
  createSelector([selectSelectedSummary], (selectedSummary) => {
    return selectedSummary
      ? getCommonHolds(
          [selectedSummary],
          convertHolds(selectedSummary?.holds),
          [external]
        )
      : [];
  });
