import { Tune } from "@mui/icons-material";
import { Box, Button, Divider, Menu, MenuItem } from "@mui/material";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import NavSearchInput from "~/components/navbar/NavSearchInput";

import {
  setOrderTypeFilter,
  setPutWallIdentifierFilter,
  setSearch
} from "./ship.slice";

export function ShipSearch() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const orderTypeFilter = useAppSelector((state) => state.ship.orderTypeFilter);
  const putWallIdentifierFilter = useAppSelector(
    (state) => state.ship.putWallIdentifierFilter
  );
  const search = useAppSelector((state) => state.ship.search);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const rackFilters: string[] = ["A", "B", "C", "D"];

  // TODO: this should come from an FC config
  const orderTypeFilters: string[] = ["Shop", "Fresh"];

  const handleOpenFilters = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <NavSearchInput
        textInput={search || ""}
        setTextInput={(text) => dispatch(setSearch(text))}
        isAutocomplete
        searchPlaceholder={t("search orders")}
        autocompleteSearchCb={(input) => {
          dispatch(setSearch(input));
        }}
        autocompleteOptions={[]}
        hideOptions
        clearOnBlur={false}
      />
      <Button
        aria-label="filters"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpenFilters}
        style={{
          minWidth: 40,
          paddingRight: 0
        }}
      >
        <Tune />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        {rackFilters.map((rack) => (
          <MenuItem
            key={rack}
            onClick={() => {
              dispatch(setPutWallIdentifierFilter(rack));
            }}
            sx={{
              minWidth: 150,
              backgroundColor:
                putWallIdentifierFilter === rack ? "gray.light" : undefined
            }}
          >
            {`Rack ${rack}`}
          </MenuItem>
        ))}
        <Divider style={{ marginTop: 2, marginBottom: 2, height: 3 }} />
        {orderTypeFilters.map((orderType) => (
          <MenuItem
            key={orderType}
            onClick={() => {
              dispatch(setOrderTypeFilter(orderType));
            }}
            sx={{
              minWidth: 150,
              backgroundColor:
                orderTypeFilter === orderType ? "gray.light" : undefined
            }}
          >
            {`${orderType} Orders`}
          </MenuItem>
        ))}
        <Divider style={{ marginTop: 2, marginBottom: 2, height: 3 }} />
        <MenuItem
          key="reset filters"
          onClick={() => {
            dispatch(setOrderTypeFilter(null));
            dispatch(setPutWallIdentifierFilter(null));
            handleClose();
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box>{t("reset filters")}</Box>
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
}
