import PlayIcon from "@locaisolutions/icons/dist/icons20px/Play20Px";

import { ButtonProps } from "@mui/material";
import { parse, stringify } from "qs";

import { useLocation, useNavigate } from "react-router-dom";

import { ProgressButton } from "~/components/ProgressButton";

type StartPickingNextBatchButtonProps = {
  // this function should clear the state of the previous batch and return the batch ID of the next.
  startBatchCallback: () => Promise<string | null>;
} & ButtonProps;

export const StartPickingNextBatchButton = ({
  startBatchCallback,
  children,
  ...rest
}: StartPickingNextBatchButtonProps) => {
  const navigate = useNavigate();
  // probably don't need this but keeping it to reduce the likelihood of regression
  const { search }: { search: string } = useLocation();

  return (
    <ProgressButton
      data-testid="start-new-pick-button"
      onClick={async () => {
        const batchId = await startBatchCallback();
        navigate({
          pathname: "/autostore-pick",
          search: stringify({
            ...parse(search, { ignoreQueryPrefix: true }),
            autostore: true,
            batchId: batchId
          })
        });
      }}
      sx={{ textTransform: "none" }}
      buttonSize="xLarge"
      emphasis="high"
      responsive
      variant="contained"
      color="primary"
      startIcon={<PlayIcon style={{ fill: "white" }} />}
      fullWidth
      {...rest}
    >
      {children}
    </ProgressButton>
  );
};
