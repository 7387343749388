import { StoreState } from "~/redux/reducers";

export const selectStartPickingModalIsOpen = (state: StoreState) =>
  state.startPicking.startModalIsOpen;

export const selectScanAndApplyLabelModalActiveStep = (state: StoreState) =>
  state.scanAndApplyLabel.scanAndApplyModalActiveStep;

export const selectPickingScannedBarcode = (state: StoreState) =>
  state.pickingScannedBarcode.scannedBarcode;
