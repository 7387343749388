import { Box, LinearProgress, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type BatchProgressBarProps = {
  totalBatches: number;
  completedBatches: number;
  isLoading: boolean;
};

function BatchProgressBar({
  totalBatches,
  completedBatches,
  isLoading
}: BatchProgressBarProps) {
  const { t } = useTranslation();
  const normalise = (value: number) => (value * 100) / totalBatches;
  const batchesCompletedCountText = `${completedBatches}/${totalBatches} ${totalBatches === 1 ? t("batch") : t("nav.viewname.batches")}`;

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        {isLoading ? (
          <Skeleton animation="wave" data-testid="wave-progress-bar-loader" />
        ) : (
          <LinearProgress
            variant="determinate"
            value={normalise(completedBatches)}
            sx={{ height: 10, borderRadius: 2 }}
          />
        )}
      </Box>
      <Box sx={{ minWidth: 150 }}>
        {isLoading ? (
          <Skeleton animation="wave" data-testid="batches-completed-count" />
        ) : (
          <Typography variant="h6">{batchesCompletedCountText}</Typography>
        )}
      </Box>
    </Box>
  );
}

export default BatchProgressBar;
