import { connect, ConnectedProps } from "react-redux";

import { useAppDispatch, useAppSelector } from "~/app/store";

import {
  useToteSubscription,
  usePickSubscription,
  usePTLSubscription,
  useGridV2Subscription
} from "~/lib/signalr";
import { setSiteWorkstationStatus } from "~/redux/actions/workstations";
import {
  selectSitePortIds,
  selectThisWorkstation
} from "~/redux/selectors/workstationsSelectors";
import {
  ToteEventDto,
  SignalRPickEventDto,
  PTLEventDto,
  AutostoreEvent
} from "~/types/api";

import {
  AndonStatus,
  saveEventData,
  selectAndonSaveAllEvents
} from "./andon.slice";

const connector = connect(undefined, {
  setSiteWorkstationStatus
});

type PropsFromRedux = ConnectedProps<typeof connector>;
export type EventListeningComponentProps = PropsFromRedux;

function EventListeningComponent(props: EventListeningComponentProps) {
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const siteAllPortIds = useAppSelector(selectSitePortIds);
  const saveAllEvents = useAppSelector(selectAndonSaveAllEvents);
  const dispatch = useAppDispatch();
  // ** GRID **

  const gridSubscription = (data: AutostoreEvent) => {
    switch (data.case) {
      // boolean problem with the port
      case "PortError": {
        const status = data.event.hasError ? "bad" : "good";

        if (siteAllPortIds.includes(data.event.portId)) {
          props.setSiteWorkstationStatus({
            status,
            portId: data.event.portId
          });
        }

        if (saveAllEvents) {
          dispatch(
            saveEventData({
              hub: "grid",
              portId: data.event.portId.toString(),
              event: data,
              status
            })
          );
        }
        return;
      }

      // inaccessable if andon features are not enabled
      case "HandToggled":
        dispatch(
          saveEventData({
            hub: "grid",
            workstationId: data.event.workstationId,
            event: data
          })
        );
        return; // exit the function

      case "BinModeChange":
        switch (data.event.binMode) {
          case "O":
          case "C":
            if (
              (data.event.portId &&
                siteAllPortIds.includes(data.event.portId)) ||
              !siteWorkstation
            ) {
              dispatch(
                saveEventData({
                  hub: "grid",
                  portId: `${data.event.portId || ""}`,
                  event: data
                })
              );
            }
            return;
        }
        break;
      case "SystemModeChanged":
        dispatch(
          saveEventData({
            hub: "grid",
            event: data
          })
        );
        return;
      default:
    }

    if (saveAllEvents) {
      let portId = "";
      let status: AndonStatus | undefined;

      switch (data.case) {
        case "BinRequested":
        case "BinFlagged":
          portId = `${data.event.portId || ""}`;
          break;
        case "PTLMessageRegistered":
        case "PTLMessageActivated":
        case "PTLMessageCompleted":
        case "PTLLightsOff":
          portId = `${data.event.sourcePortId || ""}`;
          break;
        case "BinModeChange":
          portId = `${data.event.portId || ""}`;
          break;
        // Open or Closed
        case "PortMode":
          portId = `${data.event.portId || ""}`;
          break;
        case "PortDefinition":
          portId = `${data.event.portId}`;
          break;
        case "ConnectionMode":
          portId = "N/A";
          break;

        default:
          portId = "event type not recognized";
      }
      dispatch(
        saveEventData({
          hub: "grid",
          portId,
          event: data,
          status,
          gridId: data.event.gridId
        })
      );
    }
  };
  useGridV2Subscription(gridSubscription);

  // ** PICK **
  const pickSubscription = (event: SignalRPickEventDto) => {
    if (saveAllEvents) {
      const { eventType } = event;

      let portId = "";

      switch (eventType) {
        case "Canceled":
        case "Completed":
        case "OutOfStock":
        case "Reverted":
        case "Added":
        case "Selected":
        case "BarcodeScanned":
          portId = event.portId?.toString() || "??";
          break;
        default:
          portId = event.portId?.toString() || "??";
      }

      dispatch(saveEventData({ hub: "pick", portId, event }));
    }
  };
  usePickSubscription(pickSubscription);

  // ** TOTE **
  // no status

  const toteSubscription = (event: ToteEventDto) => {
    if (saveAllEvents) {
      const { eventType } = event;

      let portId = "";

      switch (eventType) {
        case "On Workstation":
        case "Position Confirmed":
        case "Picking Started":
        case "Picking Reverted":
        case "Picked":
        case "Verified":
          portId = event.portId?.toString() || "--";
          break;
        default:
          portId = event.portId?.toString() || "--";
      }
      dispatch(saveEventData({ hub: "tote", portId, event }));
    }
  };
  useToteSubscription(toteSubscription);

  // ** PTL **

  // no status
  const ptlSubscription = (event: PTLEventDto) => {
    if (saveAllEvents) {
      const { deviceId } = event;
      dispatch(saveEventData({ hub: "ptl", event, ptlDeviceId: deviceId }));
    }
  };
  usePTLSubscription(ptlSubscription);

  return <></>;
}

export default connector(EventListeningComponent);
