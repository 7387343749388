/* eslint-disable react/no-array-index-key */

import { ListItem } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { Fragment } from "react";

import { getPickStatus } from "~/lib/helpers";
import { PickingStateV2TotePick } from "~/types/api";

export function ToteProgressBar(props: {
  allPicksForTote: PickingStateV2TotePick[];
  pickingIsComplete: boolean;
}) {
  const { allPicksForTote, pickingIsComplete } = props;

  return (
    <>
      {allPicksForTote.map((pickSummary, allPicksForToteArray, arr) => {
        const isLastPickInTote = allPicksForToteArray === arr.length - 1;

        const {
          pickId,
          quantity,
          lastName,
          canceledReason,
          canceledTimestamp,
          completedTimestamp
        } = pickSummary;

        const status = getPickStatus(pickSummary);

        let backgroundColor = "gray.dark";
        if (canceledTimestamp) {
          backgroundColor = "autostoreRed.main";
        } else if (completedTimestamp || pickingIsComplete) {
          backgroundColor = "info.main";
        }

        return (
          <Fragment key={pickId}>
            <Tooltip
              data-testid={`tooltip-${pickId}`}
              title={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: 100
                  }}
                >
                  <Typography noWrap sx={{ fontSize: 12 }}>
                    {status}
                  </Typography>
                  <Typography noWrap sx={{ fontSize: 12 }}>
                    {lastName}
                  </Typography>
                  {canceledReason && (
                    <Typography noWrap sx={{ fontSize: 12 }}>
                      {canceledReason}
                    </Typography>
                  )}
                  <Typography noWrap sx={{ fontSize: 12 }}>
                    {pickId}
                  </Typography>
                </Box>
              }
              arrow
              enterDelay={2000}
              slotProps={{
                tooltip: { sx: { backgroundColor: "primary.main" } }
              }}
            >
              <ul
                style={{
                  margin: 0,
                  padding: 0
                }}
              >
                {Array.from({ length: quantity }).map((_, i) => {
                  const isFirst = i === 0;
                  const isLast = i === quantity - 1;

                  return (
                    <ListItem
                      key={`segment-${pickId}-${i}`}
                      data-testid={`segment-${pickId}`}
                      data-testconst="progress-bar-segment"
                      aria-label={`segment-${pickId}-${i}`}
                      style={{ marginTop: 5, marginBottom: 5 }}
                      sx={{
                        padding: 0,
                        backgroundColor,
                        border: `1px solid`,
                        borderColor: "background.default",
                        width: "1.2vw",
                        height: "1.5vh",
                        display: "inline-block",
                        ...(isFirst && {
                          borderTopLeftRadius: "50.5px",
                          borderBottomLeftRadius: "50.5px"
                        }),
                        ...(isLast && {
                          borderTopRightRadius: "50.5px",
                          borderBottomRightRadius: "50.5px"
                        })
                      }}
                      className="tote-progress-bar-member"
                    />
                  );
                })}
              </ul>
            </Tooltip>
            {!isLastPickInTote && <Grid> - </Grid>}
          </Fragment>
        );
      })}
    </>
  );
}
