import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

import { useToast } from "~/hooks/useToast";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { usePostChangeBatchWorkstationMutation } from "~/redux/warehouse/batches.hooks";
import { WorkstationSummaryDto } from "~/types/api";

type ChangeWorkstationModalProps = {
  currentWorkstation: string;
  batchId: string;
  workstations: WorkstationSummaryDto[];
  setIsWorkstationModalOpenCallback: (value: SetStateAction<boolean>) => void;
};

export function ChangeWorkstationModal(props: ChangeWorkstationModalProps) {
  const {
    currentWorkstation,
    batchId,
    workstations,
    setIsWorkstationModalOpenCallback
  } = props;

  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();

  const [postChangeBatchWorkstationMutation, { isLoading }] =
    usePostChangeBatchWorkstationMutation();

  const [selectedWorkstation, setSelectedWorkstation] =
    useState(currentWorkstation);

  const displayWorkstations = workstations.map((workstation, index) => (
    <MenuItem
      value={workstation.deviceId}
      key={workstation.id}
      data-testid={`workstation-${index}`}
    >
      {workstation.deviceId}
    </MenuItem>
  ));

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedWorkstation(event.target.value);
  };

  const handleConfirm = async () => {
    try {
      const desiredWorkstation = workstations.find(
        (workstation) => workstation.deviceId === selectedWorkstation
      );

      if (desiredWorkstation && batchId) {
        const parentPort = desiredWorkstation.ports.find(
          (port) => !!port.parentPortId
        );

        const portId =
          parentPort?.parentPortId || desiredWorkstation.ports[0].portId;

        // We should add desiredWorkstation.id as a third parameter
        // After backend part that handles workstationId is deployed
        await postChangeBatchWorkstationMutation({
          batchId,
          portId
        }).unwrap();

        successToast("Workstation changed", {
          description: `Batch will now be assigned to workstation ${desiredWorkstation.deviceId}`
        });
      } else {
        setIsWorkstationModalOpenCallback(false);
      }
    } catch (err: unknown) {
      errorToast(getMessageFromRtkError(err));
    } finally {
      setIsWorkstationModalOpenCallback(false);
    }
  };

  return (
    <Dialog
      open
      onClose={() => setIsWorkstationModalOpenCallback(false)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle data-testid="change-workstation-modal-title">
        {t("change workstation")}
      </DialogTitle>
      <DialogContent style={{ paddingTop: "15px" }}>
        <FormControl fullWidth>
          <InputLabel id="workstations-label">{t("workstations")}</InputLabel>
          <Select
            labelId="workstations-label"
            value={selectedWorkstation}
            onChange={handleChange}
          >
            {displayWorkstations}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={async () => {
            await handleConfirm();
          }}
          // We don't want to call endpoint if same workstation is selected
          disabled={currentWorkstation === selectedWorkstation || isLoading}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
