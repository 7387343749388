import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";

export const SideNavContext = createContext<{
  /**
   * Triggers some dialogs when navigating away.
   *
   * @default false
   * */
  isAutostorePicking: boolean;
  /**
   * True if the sidenav is open.
   *
   * @default false
   */
  isSideNavOpen: boolean;
  /**
   * Resets the context to the initial state.  Should be called when a route changes.
   */
  reset: () => void;
  /**
   * Triggers some dialogs when navigating away.
   *
   * Don't use this, instead implement navigation logic inside the page.
   * @deprecated
   * */
  setIsAutostorePicking: (isAutostorePicking: boolean) => void;
  /**
   * Opens the sidenav if true
   * */
  setIsSideNavOpen: (isSidenavOpen: boolean) => void;
}>(null as never);

export const SideNavProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isAutostorePicking, setIsAutostorePicking] = useState(false);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  const reset = useCallback(() => {
    setIsAutostorePicking(false);
    setIsSideNavOpen(false);
  }, []);

  return (
    <SideNavContext.Provider
      value={{
        isAutostorePicking,
        isSideNavOpen,
        reset,
        setIsAutostorePicking,
        setIsSideNavOpen
      }}
    >
      {children}
    </SideNavContext.Provider>
  );
};

type Options = {
  /**
   * Triggers some dialogs when navigating away
   * @deprecated
   * @default false
   * */
  isAutostorePicking?: boolean;
};

/**
 * Configures the layout's nav bar.
 */
export function useSideNav({ isAutostorePicking = false }: Options = {}) {
  const context = useContext(SideNavContext);
  const { setIsAutostorePicking } = context;

  useEffect(() => {
    setIsAutostorePicking(isAutostorePicking);
  }, [setIsAutostorePicking, isAutostorePicking]);

  return context;
}
