import { warehouseService } from "~/api/warehouse";
import { AppThunk, AsyncAppThunk } from "~/app/store";
import { AndonStatus } from "~/features/andon/andon.slice";
import { WorkstationSummaryDto } from "~/types/api";

// SET THIS WORKSTATION
export interface SetThisWorkstationAction {
  type: "workstations/SET_THIS_WORKSTATION";
  payload: WorkstationSummaryDto | null;
}

export interface SetSitePortIdAction {
  type: "workstations/SET_SITE_PORT_ID";
  payload: number | null;
}

export const setThisWorkstation =
  (
    workstation: WorkstationSummaryDto | null,
    workstationChange?: boolean
  ): AsyncAppThunk =>
  async (dispatch, getState) => {
    const store = getState();
    const currentWorkstation = store.workstations.siteWorkstation;
    const { profile } = store.login;

    // in order to send a user workstation update, we need to send a logout message with the previous
    // workstation and then a log in message with the new workstation
    if (workstationChange && profile) {
      if (currentWorkstation) {
        await warehouseService.post(
          `/api/workstations/${currentWorkstation.id}/deactivate-workstation`
        );
      }
      if (workstation) {
        await warehouseService.post(
          `/api/workstations/${workstation.id}/activate-workstation`
        );
      }
    }

    dispatch<SetThisWorkstationAction>({
      type: "workstations/SET_THIS_WORKSTATION",
      payload: workstation
    });

    let portId = null;

    if (workstation) {
      const workstationWithMultiport = workstation.ports.find(
        (workstationPort) => workstationPort.parentPortId != null
      );
      const workstationWithCleanigDirection = workstation.ports.find(
        (workstationPort) =>
          workstationPort.cleaningDirection?.toLowerCase() === "outbound"
      );

      if (workstationWithMultiport && workstationWithMultiport.parentPortId) {
        portId = workstationWithMultiport.parentPortId;
      } else if (workstationWithCleanigDirection) {
        portId = workstationWithCleanigDirection.portId;
      } else {
        portId = workstation?.ports[0].portId;
      }
    }

    dispatch<SetSitePortIdAction>({
      type: "workstations/SET_SITE_PORT_ID",
      payload: portId || null
    });
  };

// SET SITE FC
export interface SetSiteFcAction {
  type: "workstations/SET_SITE_FC";
  payload: Guid | null;
}

export const setSiteFcId =
  (fcId: Guid | null): AppThunk =>
  (dispatch): void => {
    dispatch<SetSiteFcAction>({
      type: "workstations/SET_SITE_FC",
      payload: fcId
    });
  };

export const updateSelectedSiteWorkstation =
  (workstation: WorkstationSummaryDto): AppThunk =>
  (dispatch): void => {
    dispatch<SetThisWorkstationAction>({
      type: "workstations/SET_THIS_WORKSTATION",
      payload: workstation
    });
  };

// SET SITE WORKSTATION STATUS
export interface SetSiteWorkstationStatusAction {
  type: "workstations/SET_SITE_WORKSTATION_STATUS";
  payload: { status: AndonStatus; portId: number };
}

export const setSiteWorkstationStatus =
  ({ status, portId }: { status: AndonStatus; portId: number }): AppThunk =>
  (dispatch): void => {
    dispatch<SetSiteWorkstationStatusAction>({
      type: "workstations/SET_SITE_WORKSTATION_STATUS",
      payload: { status, portId }
    });
  };

// RESET SITE WORKSTATION STATUS
export interface ResetSiteWorkstationStatusAction {
  type: "workstations/RESET_SITE_WORKSTATION_STATUS";
}

export const resetSiteWorkstationStatus = (): AppThunk => (dispatch) => {
  dispatch<ResetSiteWorkstationStatusAction>({
    type: "workstations/RESET_SITE_WORKSTATION_STATUS"
  });
};
