import Error20Px from "@locaisolutions/icons/dist/icons20px/Error20Px";
import {
  Box,
  BoxProps,
  Button,
  Stack,
  SvgIcon,
  Typography
} from "@mui/material";

type Props = {
  /**
   * The error message to display.
   * Defaults to "Oops, something went wrong. Please try again."
   * */
  message?: string;
  /** If provided, renders a button to retry a failure */
  tryAgain?: {
    /** Called when a user clicks the Try Again button */
    onTryAgain: () => void;
    /** Overrides the default Try Again button text */
    text?: string;
  };
  /** Error panel variants */
  variant?: "filled" | "outline";
} & BoxProps;

export function ErrorPanel({
  message = "Oops, something went wrong. Please try again.",
  tryAgain,
  variant = "filled",
  ...rest
}: Props) {
  return (
    <Box
      minWidth="xl"
      minHeight="md"
      border="4px solid"
      borderRadius={3}
      borderColor="error.main"
      bgcolor={variant === "filled" ? "error.light" : "background.default"}
      px={6}
      py={3}
      {...rest}
    >
      <Stack alignItems="center" color="error.main" gap={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          gap={1}
        >
          <SvgIcon
            component={Error20Px}
            width={5}
            height={5}
            sx={{ alignSelf: "flex-end" }}
          />
          <Typography variant="subtitle1">Error</Typography>
        </Stack>
        <Typography variant="subtitle2" textAlign="center">
          {message}
        </Typography>
        {tryAgain && (
          <Button
            color="primary"
            onClick={tryAgain.onTryAgain}
            variant="contained"
          >
            {tryAgain.text || "Try Again"}
          </Button>
        )}
      </Stack>
    </Box>
  );
}
