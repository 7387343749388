import { Dialog, DialogContent, DialogTitle } from "@mui/material";

type ModalWrapperProps = {
  title?: string;
  setModalOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  children: React.ReactNode;
};

export const ModalWrapper = (props: ModalWrapperProps) => {
  const { title, setModalOpen, isOpen, children } = props;

  return (
    <Dialog open={isOpen} onClose={() => setModalOpen(false)} maxWidth={false}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default ModalWrapper;
