import { warehouseService, handleWarehouseError } from "~/api/warehouse";
import { AppThunk, AsyncAppThunk } from "~/app/store";
import {
  BatchViewModel,
  createBatchViewModel,
  PickModel,
  adjustWeight,
  fulfill,
  outOfStock,
  split,
  update,
  revert
} from "~/features/batch/BatchViewModel";
import { BatchStatus, BatchType, OrderType } from "~/features/batch/batch.type";
import { ActivePickCompletion } from "~/redux/reducers/batch";
import {
  CompletePickRequest,
  RevertPickRequest,
  BatchDto,
  SplitPickRequest,
  SplitPickResponse,
  WeightAdjustRequest,
  OutOfStockRequest,
  WeightAdjustmentDto,
  FulfillmentCenterDto,
  ToteSummaryDto,
  LoosePickSummaryDto,
  GetBatchResponse,
  SignalRPickDto,
  PickDto
} from "~/types/api";

import { SetUserMessageAction } from "./site";

export type TempZone = "ambient" | "chilled" | "frozen";

export interface GetCurrentBatchViewModelAction {
  type: "batch/GET_CURRENT_BATCH_VIEW_MODEL";
}

export interface GetBatchViewModelSuccessAction {
  type: "batch/GET_BATCH_VIEW_MODEL_SUCCESS";
  payload: BatchViewModel | null;
}
export interface GetBatchWithCartNumberAction {
  type: "batch/GET_BATCH_WITH_CART_NUMBER";
}

export interface GetBatchWithCartNumberSuccessAction {
  type: "batch/GET_BATCH_WITH_CART_NUMBER_SUCCESS";
  payload: {
    batch: BatchDto | null;
    cartNumber: string | null;
  };
}

export interface SetBatchWithCartNumberAction {
  type: "batch/SET_BATCH_WITH_CART_NUMBER";
  payload: {
    batch: BatchDto | null;
    cartNumber: string | null;
  } | null;
}

export interface GetBatchWithCartNumberFailureAction {
  type: "batch/GET_BATCH_WITH_CART_NUMBER_FAILURE";
  payload: string;
}

export interface UpdatePickAction {
  type: "batch/UPDATE_PICK";
  payload: BatchViewModel;
}

export interface ClearBatchViewModelAction {
  type: "batch/CLEAR_BATCH_VIEW_MODEL";
}

export interface SetConfirmablePickIdsAction {
  type: "batch/SET_CONFIRMABLE_PICK_IDS";
  payload: {
    confirmablePickIds: Guid[];
  };
}

export interface SetActivePickCompletionAction {
  type: "batch/SET_ACTIVE_PICK_COMPLETION";
  payload: ActivePickCompletion;
}

export interface SetFetchBatchFiltersAction {
  type: "batch/SET_FETCH_BATCH_FILTERS";
  payload: {
    selectedTempZones?: TempZone[];
    selectedBatchStatuses?: BatchStatus[];
    selectedBatchStatusesAdmin?: BatchStatus[];
    selectedBatchTypes?: BatchType[];
    selectedOrderType?: OrderType | null;
  };
}

export interface SetSelectedOrderTypeAction {
  type: "batch/SET_SELECTED_ORDER_TYPE";
  payload: OrderType;
}

export const clearBatchViewModel = (): AppThunk => (dispatch) => {
  dispatch<ClearBatchViewModelAction>({
    type: "batch/CLEAR_BATCH_VIEW_MODEL"
  });
};

export const setFetchBatchFilters =
  (args: {
    selectedTempZones?: TempZone[];
    selectedBatchStatuses?: BatchStatus[];
    selectedBatchStatusesAdmin?: BatchStatus[];
    selectedBatchTypes?: BatchType[];
    selectedOrderType?: OrderType | null;
  }): AppThunk =>
  (dispatch) => {
    dispatch<SetFetchBatchFiltersAction>({
      type: "batch/SET_FETCH_BATCH_FILTERS",
      payload: args
    });
  };

export const setSelectedOrderType =
  (orderType: OrderType): AppThunk =>
  (dispatch) => {
    dispatch<SetSelectedOrderTypeAction>({
      type: "batch/SET_SELECTED_ORDER_TYPE",
      payload: orderType
    });
  };

// GET BATCH VIEW MODEL
export interface GetBatchViewModelAction {
  type: "batch/GET_BATCH_VIEW_MODEL";
}

export interface GetBatchViewModelFailureAction {
  type: "batch/GET_BATCH_VIEW_MODEL_FAILURE";
}

export const getBatch =
  (
    batchId: Guid,
    fulfillmentCenter?: FulfillmentCenterDto
  ): AsyncAppThunk<BatchDto | null> =>
  async (dispatch) => {
    dispatch<GetBatchViewModelAction>({
      type: "batch/GET_BATCH_VIEW_MODEL"
    });
    try {
      const getBatchResponse = await warehouseService.get<GetBatchResponse>(
        `/api/batches/${batchId}`
      );
      dispatch<GetBatchViewModelSuccessAction>({
        type: "batch/GET_BATCH_VIEW_MODEL_SUCCESS",
        payload: createBatchViewModel(
          getBatchResponse.data.batch,
          fulfillmentCenter,
          getBatchResponse.data.cartNumber
        )
      });
      return getBatchResponse.data.batch;
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<GetBatchViewModelFailureAction>({
          type: "batch/GET_BATCH_VIEW_MODEL_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
      return null;
    }
  };

// a variation on getBatch that will re-fetch the current batch, without clearing the batch in state or triggering the loading state
export const getCurrentBatch =
  (batchId: Guid): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<GetCurrentBatchViewModelAction>({
      type: "batch/GET_CURRENT_BATCH_VIEW_MODEL"
    });
    try {
      const getBatchResponse = await warehouseService.get<{
        batch: BatchDto;
        cartNumber: number | null;
      }>(`/api/batches/${batchId}`);
      dispatch<GetBatchViewModelSuccessAction>({
        type: "batch/GET_BATCH_VIEW_MODEL_SUCCESS",
        payload: createBatchViewModel(getBatchResponse.data.batch)
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<GetBatchViewModelFailureAction>({
          type: "batch/GET_BATCH_VIEW_MODEL_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

export const fetchBatchWithCartNumber =
  (batchName: string): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<GetBatchWithCartNumberAction>({
      type: "batch/GET_BATCH_WITH_CART_NUMBER"
    });
    try {
      const getBatchResponse = await warehouseService.get<{
        batch: BatchDto;
        cartNumber: string | null;
      }>(`/api/batches/${batchName}`);

      dispatch<GetBatchWithCartNumberSuccessAction>({
        type: "batch/GET_BATCH_WITH_CART_NUMBER_SUCCESS",
        payload: {
          batch: getBatchResponse.data.batch,
          cartNumber: getBatchResponse.data.cartNumber
        }
      });
    } catch (err) {
      handleWarehouseError(err, (message) =>
        dispatch<GetBatchWithCartNumberFailureAction>({
          type: "batch/GET_BATCH_WITH_CART_NUMBER_FAILURE",
          payload: message
        })
      );
    }
  };

// FULFILL PICK
export interface FulfillAction {
  type: "batch/FULFILL";
  payload: string;
}

export interface FulfillSuccessAction {
  type: "batch/FULFILL_SUCCESS";
  payload: BatchViewModel;
}

export interface FulfillFailureAction {
  type: "batch/FULFILL_FAILURE";
}

export const fulfillPick =
  (
    batchViewModel: BatchViewModel,
    pickModel: PickModel,
    fulfilled: boolean,
    scannedUpcs?: string[]
  ): AsyncAppThunk =>
  async (dispatch) => {
    try {
      dispatch<FulfillAction>({
        type: "batch/FULFILL",
        payload: pickModel.pickId
      });

      let fulfilledViewModel = fulfill(batchViewModel, pickModel, fulfilled);

      if (fulfilled && pickModel.status === "OutOfStock") {
        const request: RevertPickRequest = {
          pickIds: [pickModel.pickDto.pickId],
          pickId: pickModel.pickDto.pickId
        };
        await warehouseService.post<null>("/api/picks/revert", request);
      }

      if (fulfilled && scannedUpcs) {
        const request: CompletePickRequest = {
          fulfilled,
          scannedPick: {
            pickId: pickModel.pickDto.pickId,
            scannedUpcs: scannedUpcs || []
          }
        };
        await warehouseService.post<null>("/api/picks/complete", request);
      } else {
        const request: RevertPickRequest = {
          pickIds: [pickModel.pickDto.pickId],
          pickId: pickModel.pickDto.pickId,
          clearWeightAdjustments: true
        };
        fulfilledViewModel = revert(batchViewModel, pickModel);
        await warehouseService.post<null>("/api/picks/revert", request);
      }
      dispatch<FulfillSuccessAction>({
        type: "batch/FULFILL_SUCCESS",
        payload: fulfilledViewModel
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<FulfillFailureAction>({
          type: "batch/FULFILL_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// OUT OF STOCK PICK

export interface OutOfStockSuccessAction {
  type: "batch/OUT_OF_STOCK_SUCCESS";
  payload: BatchViewModel;
}

export interface OutOfStockFailureAction {
  type: "batch/OUT_OF_STOCK_FAILURE";
}

export const outOfStockPick =
  (batchViewModel: BatchViewModel, pickModel: PickModel): AsyncAppThunk =>
  async (dispatch) => {
    try {
      const outOfStockViewModel = outOfStock(batchViewModel, pickModel);
      if (pickModel.status === "Fulfilled") {
        const request: RevertPickRequest = {
          pickIds: [pickModel.pickDto.pickId],
          pickId: pickModel.pickDto.pickId
        };

        await warehouseService.post<null>("/api/picks/revert", request);
      }
      const request: OutOfStockRequest = {
        pickId: pickModel.pickDto.pickId,
        pickBinId: pickModel.pickBin?.binId,
        hold: "out of stock"
      };

      // TODO: this is a crutch for backwards compatibility
      // it can be removed after batch id is no longer necessary (when https://github.com/locaisolutions/warehouse/pull/1205 is released)
      // eslint-disable-next-line
      (request as any).batchId = batchViewModel.batchId;
      await warehouseService.post<null>("/api/picks/out-of-stock", request);

      dispatch<OutOfStockSuccessAction>({
        type: "batch/OUT_OF_STOCK_SUCCESS",
        payload: outOfStockViewModel
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<OutOfStockFailureAction>({
          type: "batch/OUT_OF_STOCK_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// REVERT PICK

export interface RevertPickAction {
  type: "batch/REVERT_PICK";
  payload: Guid;
}

export interface RevertPickSuccessAction {
  type: "batch/REVERT_PICK_SUCCESS";
  payload: BatchViewModel;
}

export interface RevertPickFailureAction {
  type: "batch/REVERT_PICK_FAILURE";
}

export const revertPick =
  (batchViewModel: BatchViewModel, pickModel: PickModel): AsyncAppThunk =>
  async (dispatch) => {
    try {
      dispatch<RevertPickAction>({
        type: "batch/REVERT_PICK",
        payload: pickModel.pickId
      });

      // revert pick in batchmodel
      const revertBatchViewModel = revert(batchViewModel, pickModel);
      const request: RevertPickRequest = {
        pickIds: [pickModel.pickDto.pickId],
        pickId: pickModel.pickDto.pickId,
        clearWeightAdjustments: true
      };
      await warehouseService.post<null>("/api/picks/revert", request);
      dispatch<RevertPickSuccessAction>({
        type: "batch/REVERT_PICK_SUCCESS",
        payload: revertBatchViewModel
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<RevertPickFailureAction>({
          type: "batch/REVERT_PICK_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// SPLIT PICK

export interface SplitAction {
  type: "batch/SPLIT";
}

export interface SplitSuccessAction {
  type: "batch/SPLIT_SUCCESS";
  payload: BatchViewModel;
}

export interface SplitFailureAction {
  type: "batch/SPLIT_FAILURE";
}

export const splitPick =
  (
    batchViewModel: BatchViewModel,
    pickModel: PickModel,
    splitQuantity: {
      units: string;
      value: number;
    }
  ): AsyncAppThunk =>
  async (dispatch) => {
    try {
      const pick = pickModel.pickDto;

      const request: SplitPickRequest = {
        pickId: pick.pickId,
        batchId: batchViewModel.batchId,
        splitQuantity
      };
      const splitPickResponse = await warehouseService.post<SplitPickResponse>(
        "/api/picks/split",
        request
      );

      const splitViewModel = split(
        batchViewModel,
        pickModel,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        splitPickResponse.data.remainingPick,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        splitPickResponse.data.splitPick
      );

      dispatch<SplitSuccessAction>({
        type: "batch/SPLIT_SUCCESS",
        payload: splitViewModel
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<SplitFailureAction>({
          type: "batch/SPLIT_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// WEIGHT ADJUST PICK

export interface WeightAdjustAction {
  type: "batch/WEIGHT_ADJUST";
}

export interface WeightAdjustSuccessAction {
  type: "batch/WEIGHT_ADJUST_SUCCESS";
  payload: BatchViewModel;
}

export interface WeightAdjustFailureAction {
  type: "batch/WEIGHT_ADJUST_FAILURE";
}

export const weightAdjustPick =
  (
    batchViewModel: BatchViewModel,
    pickId: Guid,
    weight: number,
    quantity: number
  ): AsyncAppThunk<BatchViewModel | null> =>
  async (dispatch) => {
    try {
      const request: WeightAdjustRequest = {
        pickId,
        weight,
        quantity
      };
      const response = await warehouseService.post<WeightAdjustmentDto>(
        "/api/picks/weight-adjust",
        request
      );

      const weightAdjustedViewModel = adjustWeight(
        batchViewModel,
        pickId,
        response.data
      );

      dispatch<WeightAdjustSuccessAction>({
        type: "batch/WEIGHT_ADJUST_SUCCESS",
        payload: weightAdjustedViewModel
      });
      return weightAdjustedViewModel;
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<WeightAdjustFailureAction>({
          type: "batch/WEIGHT_ADJUST_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
    return null;
  };

// COMPLETE BATCH

export interface CompleteAction {
  type: "batch/COMPLETE";
}

export interface CompleteSuccessAction {
  type: "batch/COMPLETE_SUCCESS";
}

export interface CompleteFailureAction {
  type: "batch/COMPLETE_FAILURE";
}

export const completeBatch =
  (batchId: Guid): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<CompleteAction>({
      type: "batch/COMPLETE"
    });
    try {
      await warehouseService.post<null>(`/api/batches/${batchId}/complete`);

      dispatch<CompleteSuccessAction>({
        type: "batch/COMPLETE_SUCCESS"
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<CompleteFailureAction>({
          type: "batch/COMPLETE_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// DROP TOTES

export interface DropTotesAction {
  type: "batch/DROP_TOTES";
}
export interface DropTotesSuccessAction {
  type: "batch/DROP_TOTES_SUCCESS";
}
export interface DropTotesFailureAction {
  type: "batch/DROP_TOTES_FAILURE";
}

export const dropTotes =
  ({ toteIds }: { toteIds: Guid[] }): AsyncAppThunk =>
  async (dispatch) => {
    try {
      await warehouseService.post<null>(`/api/totes/drop`, toteIds);
      dispatch<DropTotesSuccessAction>({
        type: "batch/DROP_TOTES_SUCCESS"
      });
      dispatch<SetUserMessageAction>({
        type: "site/SET_USER_MESSAGE",
        payload: {
          title: "Totes Successfully Dropped",
          severity: "success",
          autohideDuration: 2000
        }
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<DropTotesFailureAction>({
          type: "batch/DROP_TOTES_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// GET LOOSE PICKS BY BATCH

export interface GetLoosePicksByBatchAction {
  type: "batch/GET_LOOSE_PICKS_BY_BATCH";
}
export interface GetLoosePicksByBatchSuccessAction {
  type: "batch/GET_LOOSE_PICKS_BY_BATCH_SUCCESS";
  payload: LoosePickSummaryDto[];
}
export interface GetLoosePicksByBatchFailureAction {
  type: "batch/GET_LOOSE_PICKS_BY_BATCH_FAILURE";
}

export const getLoosePicksByBatch =
  (batchId: Guid): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<GetLoosePicksByBatchAction>({
      type: "batch/GET_LOOSE_PICKS_BY_BATCH"
    });
    try {
      const getBatchResponse = await warehouseService.get<
        LoosePickSummaryDto[]
      >(`/api/loose-picks/`, {
        params: { batchId }
      });
      dispatch<GetLoosePicksByBatchSuccessAction>({
        type: "batch/GET_LOOSE_PICKS_BY_BATCH_SUCCESS",
        payload: getBatchResponse.data
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<GetLoosePicksByBatchFailureAction>({
          type: "batch/GET_LOOSE_PICKS_BY_BATCH_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// GET TOTES BY BATCH

export interface GetTotesByBatchAction {
  type: "batch/GET_TOTES_BY_BATCH";
}
export interface GetTotesByBatchSuccessAction {
  type: "batch/GET_TOTES_BY_BATCH_SUCCESS";
  payload: ToteSummaryDto[];
}
export interface GetTotesByBatchFailureAction {
  type: "batch/GET_TOTES_BY_BATCH_FAILURE";
}

export const getTotesByBatch =
  (batchId: Guid): AsyncAppThunk<ToteSummaryDto[] | null> =>
  async (dispatch) => {
    dispatch<GetTotesByBatchAction>({
      type: "batch/GET_TOTES_BY_BATCH"
    });
    try {
      const getBatchResponse = await warehouseService.get<ToteSummaryDto[]>(
        `/api/totes/`,
        {
          params: { batchId }
        }
      );
      dispatch<GetTotesByBatchSuccessAction>({
        type: "batch/GET_TOTES_BY_BATCH_SUCCESS",
        payload: getBatchResponse.data
      });
      return getBatchResponse.data;
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<GetTotesByBatchFailureAction>({
          type: "batch/GET_TOTES_BY_BATCH_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
      return null;
    }
  };

// RESTART TOTES BY BATCH CONTENT

export interface RestartTotesByBatchContent {
  type: "batch/RESTART_TOTES_BY_BATCH_CONTENT";
}

export const restartTotesByBatch = (): AppThunk => (dispatch) => {
  dispatch<RestartTotesByBatchContent>({
    type: "batch/RESTART_TOTES_BY_BATCH_CONTENT"
  });
};

// ADD TOTE TO BATCH

export interface AddToteAction {
  type: "batch/ADD_TOTE";
}
export interface AddToteSuccessAction {
  type: "batch/ADD_TOTE_SUCCESS";
}
export interface AddToteFailureAction {
  type: "batch/ADD_TOTE_FAILURE";
}

export const addToteToBatch =
  (currentToteId: Guid, workstationId?: Guid): AsyncAppThunk<Guid | null> =>
  async (dispatch) => {
    try {
      const response = await warehouseService.post<Guid>(
        `/api/totes/${currentToteId}/add`,
        {
          workstationId
        }
      );
      dispatch<AddToteSuccessAction>({
        type: "batch/ADD_TOTE_SUCCESS"
      });
      dispatch<SetUserMessageAction>({
        type: "site/SET_USER_MESSAGE",
        payload: {
          title: "Tote Added",
          severity: "success",
          autohideDuration: 2000
        }
      });

      return response.data;
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<AddToteFailureAction>({
          type: "batch/ADD_TOTE_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
    return null;
  };

// PREPARE CART
export interface PrepareCartAction {
  type: "batch/PREPARE_CART";
}

export interface PrepareCartSuccessAction {
  type: "batch/PREPARE_CART_SUCCESS";
  payload: BatchViewModel;
}

export interface PrepareCartFailureAction {
  type: "batch/PREPARE_CART_FAILURE";
}

export const prepareCart =
  (batchId: Guid, fulfillmentCenter: FulfillmentCenterDto | null): AppThunk =>
  async (dispatch) => {
    dispatch<PrepareCartAction>({
      type: "batch/PREPARE_CART"
    });
    try {
      const response = await warehouseService.post<BatchDto>(
        `/api/batches/${batchId}/prepare-cart`
      );
      dispatch<PrepareCartSuccessAction>({
        type: "batch/PREPARE_CART_SUCCESS",
        payload: createBatchViewModel(
          response.data,
          fulfillmentCenter || undefined
        )
      });
    } catch (err) {
      handleWarehouseError(err, (message) => {
        dispatch<PrepareCartFailureAction>({
          type: "batch/PREPARE_CART_FAILURE"
        });
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message,
            severity: "error"
          }
        });
      });
    }
  };

// SET CONFIRMABLE PICK IDS

export const setConfirmablePickIds =
  (confirmablePickIds: Guid[]): AppThunk =>
  (dispatch) => {
    dispatch<SetConfirmablePickIdsAction>({
      type: "batch/SET_CONFIRMABLE_PICK_IDS",
      payload: { confirmablePickIds }
    });
  };

// SET ACTIVE PICK COMPLETION

export const setActivePickCompletion =
  (pickCompletion: ActivePickCompletion): AppThunk =>
  (dispatch) => {
    dispatch<SetActivePickCompletionAction>({
      type: "batch/SET_ACTIVE_PICK_COMPLETION",
      payload: pickCompletion
    });
  };

// UPDATE PICK

export const updatePick =
  (batchViewModel: BatchViewModel, pick: PickDto | SignalRPickDto): AppThunk =>
  (dispatch): void => {
    const updatedViewModel = update(batchViewModel, pick);

    dispatch<UpdatePickAction>({
      type: "batch/UPDATE_PICK",
      payload: updatedViewModel
    });
  };

export type WeightAdjustParameters = {
  pick: PickDto | SignalRPickDto;
  weight: number | null;
  quantity: number | null;
};

// START WEIGHT ADJUSTMENT
export interface StartWeightAdjustment {
  type: "batch/START_WEIGHT_ADJUSTMENT";
  payload: WeightAdjustParameters;
}

export const startWeightAdjustment =
  (parameters: WeightAdjustParameters): AppThunk =>
  (dispatch) => {
    dispatch<StartWeightAdjustment>({
      type: "batch/START_WEIGHT_ADJUSTMENT",
      payload: parameters
    });
  };

// END WEIGHT ADJUSTMENT
export interface EndWeightAdjustment {
  type: "batch/END_WEIGHT_ADJUSTMENT";
}

export const endWeightAdjustment =
  (): AppThunk =>
  (dispatch): void => {
    dispatch<EndWeightAdjustment>({
      type: "batch/END_WEIGHT_ADJUSTMENT"
    });
  };
