import { Button, Toolbar, Typography } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { NavBarButtonGroup, UnselectButton } from "~/components/navbar/Navbar";
import { useToast } from "~/hooks/useToast";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useSplitMutation } from "~/redux/warehouse/totes.hooks";

import { MovePickButton } from "./MovePickButton";
import { selectPick, setOpenToteOrLoosePickId } from "./shipOrder.slice";
import { useOpenTotePicks } from "./useOpenTotePicks";

export function OrderToolbar() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { orderId = "" } = useParams<{
    orderId: Guid;
  }>();
  const { errorToast, successToast } = useToast();

  const openToteOrLoosePickId = useAppSelector(
    (state) => state.shipOrder.openToteOrLoosePickId
  );
  const selectedPick = useAppSelector((state) => state.shipOrder.selectedPick);

  const [split] = useSplitMutation();

  const openTotePicks = useOpenTotePicks(orderId);

  const canMovePick = selectedPick && selectedPick.status === "Completed";

  const handleAddTote = useCallback(
    async (currentToteId: Guid, pickId: Guid) => {
      try {
        await split({
          currentToteId,
          picksIdsToTransfer: [pickId]
        }).unwrap();
        dispatch(selectPick(null));
        successToast("New Tote Added");
      } catch (err: unknown) {
        errorToast(getMessageFromRtkError(err));
      }
    },
    [dispatch, errorToast, split, successToast]
  );

  return (
    <Toolbar>
      <UnselectButton
        onClick={() => {
          if (selectedPick) {
            dispatch(selectPick(null));
          }
          if (!selectedPick && openToteOrLoosePickId) {
            dispatch(setOpenToteOrLoosePickId(null));
          }
        }}
      />
      <NavBarButtonGroup>
        {!!openToteOrLoosePickId &&
          openTotePicks &&
          openTotePicks.length > 1 &&
          selectedPick && (
            <Button
              color="secondary"
              onClick={() =>
                handleAddTote(openToteOrLoosePickId, selectedPick.pickId)
              }
            >
              <Typography variant="body2" style={{ color: "#fff" }}>
                {t("add to new tote")}
              </Typography>
            </Button>
          )}
        {!!canMovePick && <MovePickButton />}
      </NavBarButtonGroup>
    </Toolbar>
  );
}
