import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Box, Paper, useTheme } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { InventoryAddDialog } from "~/components/InventoryAddDialog";
import { InventoryProductCard } from "~/components/InventoryProductCard";
import { ProgressButton } from "~/components/ProgressButton";

import { useNavbar } from "~/hooks/useNavbar";
import { useView } from "~/hooks/useView";
import { mobileWidth } from "~/lib/theme";
import {
  fetchCycleCountAndBins,
  fetchCycleCountBins,
  postBinCount,
  completeCycleCounts,
  removeCycleCount,
  clearCycleCountsState,
  clearCycleCountsMessage
} from "~/redux/actions/cycleCounts";
import {
  getVariantByVariantId,
  clearSelectedVariant,
  clearInventoryMessage,
  getInventoryReport,
  placeInventoryHold
} from "~/redux/actions/inventory";
import { StoreState } from "~/redux/reducers";

import CycleCountBinTable from "./CycleCountBinTable";
import { CycleCountToolbar } from "./CycleCountToolbar";
import { selectCycleCountBinId } from "./cycleCounts.slice";

const mapStateToProps = (state: StoreState) => ({
  selectedVariant: state.inventory.selectedVariant,
  cycleCount: state.cycleCounts.cycleCount,
  loadingCycleCountBins: state.cycleCounts.loadingCycleCountBins,
  cycleCountInventoryRecords: state.cycleCounts.cycleCountInventoryRecords,
  selectedPortId: state.workstations.sitePortId,
  inventoryReports: state.inventory.inventoryReport,
  usersFulfillmentCenter: state.store.usersFulfillmentCenter,
  excludeRecalledHold: state.site.clientConfig.ap_excludeRecalledHold,
  thisWorkstationId: state.workstations.siteWorkstation?.id,
  initialFrequency: state.cycleCounts.cycleCountFrequency?.frequency
});

const connector = connect(mapStateToProps, {
  getVariantByVariantId,
  fetchCycleCountAndBins,
  fetchCycleCountBins,
  postBinCount,
  completeCycleCounts,
  clearCycleCountsState,
  clearSelectedVariant,
  clearInventoryMessage,
  clearCycleCountsMessage,
  getInventoryReport,
  placeInventoryHold
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type CycleCountInheritedProps = { viewTitle?: string };
export type CycleCountProps = PropsFromRedux & CycleCountInheritedProps;

export function CycleCount(props: CycleCountProps) {
  const {
    selectedVariant,
    cycleCount,
    cycleCountInventoryRecords,
    loadingCycleCountBins,
    selectedPortId,
    inventoryReports,
    usersFulfillmentCenter,
    initialFrequency,
    viewTitle
  } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { palette } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(mobileWidth);

  useView({ permanentSidenav: true });
  const { setMenuItems, setToolbar } = useNavbar({ viewTitle });

  const cycleCountId = location.pathname.split("/")[2];

  // component did unmount
  useEffect(
    () => () => {
      props.clearSelectedVariant();
      props.clearCycleCountsState();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const selectedCycleCountBinId = useAppSelector(
    (state) => state.cycleCountsSlice.selectedCycleCountBinId
  );

  const [initialFetchComplete, setInitialFetchComplete] =
    useState<boolean>(false);
  const [isAddPanelOpen, setAddPanelOpen] = useState(false);

  // get product info for selected inventory or variant from params (if no inventory records)
  const searchVariantId: Guid | undefined = cycleCount?.variant?.variantId;

  useEffect(() => {
    if (searchVariantId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getVariantByVariantId(searchVariantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVariantId]);

  useEffect(() => {
    if (!initialFetchComplete) {
      // fetch cycle count, then fetch cycle count bins
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.fetchCycleCountAndBins(cycleCountId);
      setInitialFetchComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFetchComplete]);

  // get inventory report for selected variant. Used for total qty/total committed
  useEffect(() => {
    if (selectedVariant) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getInventoryReport(selectedVariant.variantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant]);

  useEffect(() => {
    if (selectedCycleCountBinId) {
      setToolbar(<CycleCountToolbar />);
    } else {
      setToolbar(null);
    }
  }, [selectedCycleCountBinId, setToolbar]);

  useEffect(() => {
    setMenuItems([
      {
        textContent: "Remove Cycle Count",
        actionCb: async () => {
          await dispatch(removeCycleCount(cycleCountId));
          dispatch(selectCycleCountBinId(null));
          navigate("/cycle-count");
        }
      }
    ]);
  }, [cycleCountId, dispatch, navigate, setMenuItems]);

  const allCycleCountBinsAreVerified =
    !!cycleCount &&
    cycleCountInventoryRecords.reduce((acc, cycleCountBin) => {
      if (cycleCount && cycleCountBin.count) {
        const matchingBinCount = cycleCount.binCounts.find(
          (binCount) => binCount.inventoryId === cycleCountBin.inventoryId
        );
        return (
          !!matchingBinCount &&
          matchingBinCount.count.value === cycleCountBin.count.value &&
          acc
        );
      }
      return acc;
    }, true);

  const showCompletionButton =
    !loadingCycleCountBins && allCycleCountBinsAreVerified;

  return (
    <Container maxWidth="xl" sx={{ margin: "15px 0 0 0" }}>
      <Grid container spacing={isMobile ? 0 : 5}>
        <Grid item md={4} xs={12} style={{ margin: "14px 0" }}>
          <InventoryProductCard
            variantId={selectedVariant?.variantId}
            addClickCb={() => setAddPanelOpen(true)}
            inventoryReports={inventoryReports}
            usersFulfillmentCenter={usersFulfillmentCenter}
            initialCycleCountFrequency={initialFrequency}
          />
        </Grid>

        <Grid item container xs={12} md={8} spacing={isMobile ? 0 : 2}>
          <Grid item xs={12}>
            <Box marginTop="14px" marginBottom="14px">
              <Paper>
                <CycleCountBinTable />
              </Paper>

              {showCompletionButton && (
                <Grid
                  xs={12}
                  item
                  justifyContent="center"
                  style={{ marginTop: 10 }}
                >
                  <ProgressButton
                    id="complete-button"
                    sx={{ background: palette.success.dark, color: "white" }}
                    buttonSize="medium"
                    emphasis="high"
                    responsive
                    fullWidth
                    startIcon={<DoneAllIcon />}
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                      props.completeCycleCounts([cycleCountId]).then(() => {
                        navigate("/cycle-count");
                      });
                      dispatch(selectCycleCountBinId(null));
                    }}
                  >
                    {t("complete cycle count")}
                  </ProgressButton>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>

        <InventoryAddDialog
          open={isAddPanelOpen}
          autostorePortId={selectedPortId}
          onClose={() => setAddPanelOpen(false)}
          variant={selectedVariant ?? undefined}
          refreshCb={() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
            props.fetchCycleCountAndBins(cycleCountId);
            if (selectedVariant) {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
              props.getInventoryReport(selectedVariant.variantId);
            }
          }}
        />
      </Grid>
    </Container>
  );
}

export default connector(CycleCount);
