import { Tune } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem } from "@mui/material";

import Divider from "@mui/material/Divider";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export type Filter = {
  filterText: string;
  hasPutawayTasks: boolean;
};

function LowInventoryFilters(props: {
  selectedFilter: Filter | null;
  onFilterSelect: (filter: Filter | null) => void;
  onResetFilters: () => void;
}) {
  const { selectedFilter, onFilterSelect, onResetFilters } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { t } = useTranslation();

  const filtersList: Filter[] = [
    {
      filterText: "Putaway",
      hasPutawayTasks: true
    },
    {
      filterText: "Restock",
      hasPutawayTasks: false
    }
  ];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-label="filters"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ minWidth: 40, paddingRight: 0, textTransform: "capitalize" }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Tune
              data-testid="batch-filterIcon"
              sx={{ color: "secondary.contrastText" }}
            />
          </Box>
        </Box>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        <div>
          {filtersList.map((filter) => (
            <MenuItem
              key={filter.filterText}
              onClick={() => {
                if (selectedFilter === filter) {
                  onFilterSelect(null);
                  handleClose();
                } else {
                  onFilterSelect(filter);
                  handleClose();
                }
              }}
              selected={selectedFilter?.filterText === filter.filterText}
              style={{
                minWidth: 150
              }}
            >
              {filter.filterText}
            </MenuItem>
          ))}
        </div>
        <Divider style={{ marginTop: 2, marginBottom: 2, height: 3 }} />
        <MenuItem
          key="reset filters"
          onClick={() => {
            onResetFilters();
            handleClose();
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box>{t("reset filters")}</Box>
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
}

export default LowInventoryFilters;
