import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { warehouseService } from "~/api/warehouse";
import { StoreState } from "~/redux/reducers";

type mode =
  | "induction"
  | "picking"
  | "cycle-count"
  | "problem-solving"
  | "maintenance";

/** Sends an update-mode event to warehouse as the user moves to different parts of the app */
function useModeUpdate(locationPathname: string) {
  const currentWorkstationId = useSelector(
    (state: StoreState) => state.workstations.siteWorkstation?.id
  );
  const [currentMode, setCurrentMode] = useState<mode | null>(null);
  // define newMode based on new router location
  let newMode: mode | null;
  switch (locationPathname) {
    case "/autostore-putaway":
    case "/autostore-putaway-v2":
      newMode = "induction";
      break;
    case "/autostore-pick":
      newMode = "picking";
      break;
    case "/autostore-cyclecount":
    case "/autostore-cyclecountv2":
      newMode = "cycle-count";
      break;
    // all inventory screens (v1, v2, bin, product, etc)
    case locationPathname.match(/^\/inventory/)?.input || "":
    case "/inventory/bin":
    case "/autostore-inventory-holds":
      newMode = "problem-solving";
      break;
    case "/bin-maintenance":
      newMode = "maintenance";
      break;
    default:
      newMode = null;
  }

  useEffect(() => {
    // if user/workstation switches from one mode to another, post updated mode to warehouse
    if (currentWorkstationId && newMode && newMode !== currentMode) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      warehouseService
        .post(`/api/workstations/${currentWorkstationId}/mode/${newMode}`)
        .then(() => {
          setCurrentMode(newMode);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMode]);
}

export default useModeUpdate;
