import { fulfillmentCenterApi } from "./fulfillmentCenter";

// enhance endpoints and export hooks individually
// At some point in the future, we want to code-gen the binApi
// from an OpenAPI spec.  The generated code will not have response transformations.
// https://redux-toolkit.js.org/rtk-query/api/created-api/code-splitting#enhanceendpoints
export const {
  useGetFulfillmentCenterQuery,
  useGetAllFulfillmentCentersQuery,
  useLazyGetAllFulfillmentCentersQuery
} = fulfillmentCenterApi;
