import {
  Table,
  Skeleton,
  Typography,
  styled,
  Stack,
  Pagination
} from "@mui/material";
import { PropsWithChildren } from "react";

import { ASTableV2Body } from "./ASTableV2Body";
import { ASTableV2Cell } from "./ASTableV2Cell";
import { ASTableV2Row } from "./ASTableV2Row";

const StyledASTableV2 = styled(Table, {
  shouldForwardProp: (propName) =>
    !["gridTemplateColumns", "gridTemplateRows"].includes(propName as string)
})<{
  gridTemplateColumns: string;
  gridTemplateRows?: string;
}>(({ gridTemplateColumns, gridTemplateRows, theme: { spacing } }) => ({
  flexGrow: 1,
  display: "grid",
  gridTemplateColumns,
  gridTemplateRows,
  padding: spacing(1),
  overflowY: "auto"
}));

const TableWrapper = styled(Stack)(({ theme: { palette, shadows } }) => ({
  boxShadow: shadows["3"],
  backgroundColor: palette.secondary.main,
  borderRadius: 10,
  height: "100%"
}));

type ASTableV2Props = {
  gridTemplateColumns: string;
  gridTemplateRows?: string;
  isLoading: boolean;
  isEmpty: boolean;
  isEmptyMessage?: string;
  pagination?: {
    setPage: (page: number) => void;
    page: number;
    pageCount: number;
  };
} & PropsWithChildren;

export const ASTableV2 = ({
  gridTemplateColumns,
  gridTemplateRows,
  isLoading,
  isEmpty,
  isEmptyMessage,
  pagination,
  children
}: ASTableV2Props) => {
  return (
    <TableWrapper>
      <StyledASTableV2
        gridTemplateColumns={gridTemplateColumns}
        gridTemplateRows={gridTemplateRows}
      >
        {children}
        {isLoading && (
          <ASTableV2Body>
            <ASTableV2Row>
              <ASTableV2Cell sx={{ gridColumn: "1 / -1", display: "flex" }}>
                <Skeleton
                  role="progressbar"
                  sx={{ flexGrow: 1 }}
                  variant="text"
                ></Skeleton>
              </ASTableV2Cell>
            </ASTableV2Row>
          </ASTableV2Body>
        )}
        {!isLoading && isEmpty && (
          <ASTableV2Body>
            <ASTableV2Row>
              <ASTableV2Cell sx={{ gridColumn: "1 / -1", display: "flex" }}>
                <Typography flexGrow={"1"}>{isEmptyMessage}</Typography>
              </ASTableV2Cell>
            </ASTableV2Row>
          </ASTableV2Body>
        )}
      </StyledASTableV2>
      {!!pagination && pagination.pageCount > 1 && (
        <Pagination
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 2
          }}
          count={pagination.pageCount}
          page={pagination.page}
          onChange={(_e, p) => pagination.setPage(p)}
          shape="rounded"
        />
      )}
    </TableWrapper>
  );
};
