import CheckIcon from "@mui/icons-material/Check";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { DialogTitle, SelectChangeEvent } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { ProgressButton } from "~/components/ProgressButton";
import { RoundIconButton } from "~/components/RoundIconButton";
import { adjustInventoryNet } from "~/redux/actions/inventory";
import { StoreState } from "~/redux/reducers";
import { selectPutawayReasonCodeOptions } from "~/redux/selectors/inventorySelectors";
import { PutAwayTaskSummaryDto } from "~/types/api";

const mapStateToProps = (state: StoreState) => ({
  workstationId: state.workstations.siteWorkstation?.id,
  putawayReasonCodeOptions: selectPutawayReasonCodeOptions(state)
});

const connector = connect(mapStateToProps, {
  adjustInventoryNet
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type PutawayAdjustModalProps = PropsFromRedux & {
  putawayTask: PutAwayTaskSummaryDto;
  closeModal: () => void;
  successCallback: () => void;
};

export function PutawayAdjustModal(props: PutawayAdjustModalProps) {
  const { t } = useTranslation();

  const { putawayTask, closeModal, putawayReasonCodeOptions } = props;

  const [adjustmentQuantity, setAdjustmentQuantity] = useState<number>(0);
  const [reasonCode, updateReasonCode] = useState<string | null>(null);

  // user should not be abe to remove more than the remaining qty
  const downDisabled = adjustmentQuantity === putawayTask.remaining.value * -1;

  let confirmText = `${
    adjustmentQuantity > 0 ? t("add") : t("remove")
  } ${Math.abs(adjustmentQuantity)}`;

  // require reason code and adjustment value to be >= to remaining value
  let confirmDisabled =
    !reasonCode || adjustmentQuantity < putawayTask.remaining.value * -1;

  if (adjustmentQuantity === 0) {
    confirmText = "Confirm";
    confirmDisabled = true;
  }

  const handleReasonCodeChange = (event: SelectChangeEvent<unknown>) => {
    if (typeof event?.target?.value === "string") {
      updateReasonCode(event.target.value);
    }
  };

  const handleAdjustInventory = () => {
    const { workstationId } = props;

    const { inventoryId, receivedQuantity, quantity } = putawayTask;

    const qty = receivedQuantity ?? quantity;

    const netAdjustment = { units: qty.units, value: adjustmentQuantity };
    const netCommittedAdjustment = { units: qty.units, value: 0 };

    if (inventoryId && reasonCode) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props
        .adjustInventoryNet({
          inventoryId,
          netAdjustment,
          netCommittedAdjustment,
          reasonCode,
          workstationId
        })
        .then(() => {
          props.successCallback();
          props.closeModal();
        });
    }
  };

  const quantityTextField = (
    <TextField
      id="quantity"
      label={t("adjustment")}
      type="number"
      variant="outlined"
      sx={{
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
          {
            display: "none"
          },
        width: "100%"
      }}
      value={adjustmentQuantity === null ? 0 : adjustmentQuantity}
      onChange={(e) =>
        setAdjustmentQuantity(e.target.value ? Number(e.target.value) : 0)
      }
      inputProps={{
        style: {
          fontSize: 50,
          padding: "35px 10px 35px 18px"
        }
      }}
      InputLabelProps={{
        shrink: true
      }}
    />
  );

  const quantityButtons = (
    <Grid
      container
      item
      direction="column"
      xs={6}
      spacing={1}
      wrap="nowrap"
      sx={{ position: "absolute", top: 28, right: 8 }}
    >
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <RoundIconButton
          aria-label="increase quantity"
          onClick={(): void => {
            setAdjustmentQuantity(adjustmentQuantity + 1);
          }}
          size="large"
        >
          <ExpandLessIcon
            sx={{
              fontSize: 27
            }}
          />
        </RoundIconButton>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <RoundIconButton
          aria-label="decrease quantity"
          onClick={(): void => {
            setAdjustmentQuantity(adjustmentQuantity - 1);
          }}
          disabled={downDisabled}
          size="large"
        >
          <ExpandMoreIcon
            sx={{
              fontSize: 27
            }}
          />
        </RoundIconButton>
      </Grid>
    </Grid>
  );

  const reasonCodeField = (
    <TextField
      id="reasonCode"
      label={t("reason code")}
      aria-label={t("reason code")}
      type="string"
      select
      fullWidth
      variant="outlined"
      value={reasonCode || ""}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        style: {
          fontSize: "1rem"
        }
      }}
      SelectProps={{
        onChange: handleReasonCodeChange
      }}
    >
      {putawayReasonCodeOptions.map((choiceObj, i, arr) => (
        <MenuItem
          key={`uom-option-${choiceObj.value}`}
          className={`uom-option-${i}`}
          sx={
            i === arr.length - 1
              ? { padding: "10px", fontSize: "24px" }
              : {
                  padding: "10px",
                  fontSize: "24px",
                  borderBottom: `1px solid`,
                  borderBottomColor: "gray.light"
                }
          }
          value={choiceObj.value}
        >
          {choiceObj.label}
        </MenuItem>
      ))}
    </TextField>
  );

  const AdjustInventoryButton = (
    <ProgressButton
      id="adjust-button"
      sx={{ backgroundColor: "primary.main", color: "white" }}
      buttonSize="medium"
      emphasis="high"
      responsive
      fullWidth
      startIcon={<CheckIcon />}
      onClick={() => handleAdjustInventory()}
      disabled={confirmDisabled}
    >
      {confirmText}
    </ProgressButton>
  );

  const CancelButton = (
    <ProgressButton
      id="cancel-button"
      color="secondary"
      buttonSize="medium"
      emphasis="high"
      responsive
      fullWidth
      startIcon={<HighlightOffIcon />}
      onClick={closeModal}
    >
      {t("cancel")}
    </ProgressButton>
  );

  return (
    <>
      <Grid
        className="InventoryAdjustPanel"
        container
        item
        xs={12}
        direction="column"
        wrap="nowrap"
      >
        <Grid className="InventoryAdjustPanel-card-container" item>
          <Card className="InventoryAdjustPanel-card">
            <DialogTitle id="InventoryAdjustPanel-header-text">
              {t("change quantity")}
            </DialogTitle>
            <Box className="InventoryAdjustPanel-card-box" padding="20px">
              <Grid container spacing={2} alignItems="center">
                <Grid item md={5} xs={12}>
                  <Typography variant="body1" gutterBottom align="center">
                    {t("how many need to be added or removed")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={7}
                  sx={{
                    position: "relative"
                  }}
                >
                  {quantityTextField}
                  {quantityButtons}
                </Grid>
                <Grid item xs={12} md={12}>
                  {reasonCodeField}
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item xs={6}>
                    {CancelButton}
                  </Grid>
                  <Grid item xs={6}>
                    {AdjustInventoryButton}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default connector(PutawayAdjustModal);
