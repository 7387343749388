import { useAppDispatch, useAppSelector } from "~/app/store";
import { extractReceipientId } from "~/lib/helpers";
import { useUserSubscription } from "~/lib/signalr";
import { setUserMessage } from "~/redux/actions/site";
import { MessageComponentType } from "~/redux/reducers/site";
import { selectAuth0UserId } from "~/redux/selectors/authSelectors";

import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { SendMessageDto } from "~/types/api";

export const useUserMessages = () => {
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector(selectAuth0UserId);
  const currentRecipientId = extractReceipientId(currentUserId);

  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);

  const userSubscription = (event: SendMessageDto) => {
    if (
      usersFulfillmentCenter?.fulfillmentCenterId !==
        event.fulfillmentCenterId ||
      event.userId !== currentRecipientId
    )
      return;

    dispatch(
      setUserMessage({
        componentType: "single" as MessageComponentType,
        title: event.message,
        severity: "warning",
        autohideDuration: null,
        withMask: true
      })
    );
  };

  useUserSubscription(userSubscription);
};
