import { Box, styled } from "@mui/material";

export const RobotLoader = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 20,
  "> div:nth-of-type(1)": {
    alignSelf: "end"
  }
});
