import PersonIcon from "@locaisolutions/icons/dist/icons20px/Person20Px";
import WorkstationIcon from "@locaisolutions/icons/dist/icons24px/Workstation24Px";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { WorkstationRepresentationProps } from "~/redux/reducers/workstations";

function WorkstationRepresentation(props: WorkstationRepresentationProps) {
  const {
    size,
    rows,
    columns,
    operatorCoordinate,
    portCoordinates,
    totePlacements,
    onClickCb
  } = props;
  const { palette } = useTheme();
  return (
    <div
      style={{
        height: `${size * rows.length}px`,
        width: `${size * columns.length}px`,
        backgroundColor: palette.gray.dark,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {[...rows].reverse().map((rowNum) => (
        <div
          key={`row-${rowNum}`}
          style={{
            height: `${100 / rows.length}%`,
            width: "100%",
            display: "flex",
            flexDirection: "row"
          }}
        >
          {[...columns].map((columnNum) => {
            const xPos = columnNum;
            const yPos = rowNum;
            let isOperator = false;
            let isPort = false;
            let isPortDuplicate = false;
            let isTote = false;
            let isToteDuplicate = false;
            let elBackgroundColor;

            if (
              xPos === operatorCoordinate.x &&
              yPos === operatorCoordinate.y
            ) {
              isOperator = true;
            }

            const matchingPortPositions = portCoordinates.filter(
              (port) => port.coordinate.x === xPos && port.coordinate.y === yPos
            );

            if (matchingPortPositions.length === 1) {
              isPort = true;
              elBackgroundColor = palette.primary.main;
            } else if (matchingPortPositions.length > 1) {
              isPortDuplicate = true;
              elBackgroundColor = palette.autostoreRed.main;
            }

            const matchingTotePosition = totePlacements.find(
              (totePlacement) =>
                totePlacement.coordinate.x === xPos &&
                totePlacement.coordinate.y === yPos
            );

            const matchingTotePositions = totePlacements.filter(
              (totePlacement) =>
                totePlacement.coordinate.x === xPos &&
                totePlacement.coordinate.y === yPos
            );

            if (matchingTotePositions.length === 1) {
              isTote = true;
              elBackgroundColor = palette.success.main;
            } else if (matchingTotePositions.length > 1) {
              isToteDuplicate = true;
              elBackgroundColor = palette.autostoreRed.main;
            }

            return (
              <div
                key={`column-${columnNum}`}
                style={{
                  width: `${100 / columns.length}%`,
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <div
                  style={{
                    height: "90%",
                    width: "90%",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                  }}
                  role="button"
                  tabIndex={0}
                  aria-hidden="true"
                  onClick={(): void => {
                    onClickCb({ x: xPos, y: yPos });
                  }}
                >
                  <div
                    style={{
                      height: "40%",
                      width: "80%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 5,
                      backgroundColor: elBackgroundColor,
                      boxSizing: "border-box"
                    }}
                  >
                    {isOperator && <PersonIcon />}
                    {isPort && <WorkstationIcon fill="white" />}
                    {isPortDuplicate && (
                      <div>
                        <Typography sx={{ color: "white" }}>X</Typography>
                      </div>
                    )}
                    {isTote && (
                      <div>
                        <Typography sx={{ color: "white" }}>
                          {`${matchingTotePosition?.totePosition || ""}`}
                        </Typography>
                      </div>
                    )}
                    {isToteDuplicate && (
                      <div>
                        <Typography sx={{ color: "white" }}>X</Typography>
                      </div>
                    )}
                  </div>
                  <div
                    id={`${xPos}, ${yPos}`}
                    style={{
                      justifySelf: "flex-end"
                    }}
                  >
                    <Typography
                      sx={{ color: "black" }}
                    >{`${xPos}, ${yPos}`}</Typography>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}

export default WorkstationRepresentation;
