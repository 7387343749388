import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Grid, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

interface ConfirmButtonProps {
  size: "lg" | "md";
  greenBackground?: boolean;
  color: string;
  backgroundColor: string;
  onClick: () => void;
  disabled?: boolean;
  text?: string;
  isNext?: boolean;
}

export function ConfirmButton(props: ConfirmButtonProps) {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const {
    size,
    greenBackground,
    color,
    backgroundColor,
    onClick,
    disabled,
    text,
    isNext
  } = props;
  return (
    <Button
      onClick={(): void => {
        onClick();
      }}
      id="autostoreConfirmButton"
      style={{
        border: greenBackground ? `1px solid` : "",
        borderColor: palette.info.main,
        color,
        backgroundColor: disabled ? palette.gray.dark : backgroundColor,
        height: size === "md" ? "100%" : "8vH",
        width: size === "md" ? "80%" : "12vw"
      }}
      disabled={disabled || false}
    >
      <Grid item xs={12}>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "5px"
          }}
        >
          <Box>
            {isNext ? <ArrowRightAltIcon /> : <CheckCircleOutlineIcon />}
          </Box>
          <Box
            style={{
              color,
              fontSize: "20px",
              marginTop: "-5px",
              marginLeft: "5px",
              textTransform: "capitalize"
            }}
          >
            {text || t("confirm")}
          </Box>
        </Box>
      </Grid>
    </Button>
  );
}
