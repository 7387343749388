import { Button, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { MovePickDialog } from "./MovePickDialog";
import { setIsTransferModalOpen, setTransferQty } from "./shipOrder.slice";

export function MovePickButton() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isTransferModalOpen = useAppSelector(
    (state) => state.shipOrder.isTransferModalOpen
  );
  return (
    <>
      <Button
        color="secondary"
        onClick={() => {
          dispatch(setTransferQty(null));
          dispatch(setIsTransferModalOpen(!isTransferModalOpen));
        }}
      >
        <Typography variant="body2" style={{ color: "#fff" }}>
          {t("move to existing tote")}
        </Typography>
      </Button>
      <MovePickDialog />
    </>
  );
}
