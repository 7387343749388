import { AutostoreEvent } from "~/types/api";

export const eventPortErrorSample: AutostoreEvent = {
  case: "PortError",
  event: {
    gridId: "000-000-123",
    hasError: true,
    portId: 123
  }
};
