import { styled } from "@mui/material/styles";
import { forwardRef } from "react";
import Barcode from "react-barcode";

type LabelInfo = {
  putawayTaskId: Guid;
  sku: string;
  productName: string;
  units: string;
};

type LabelProps = { labels: LabelInfo[] };

const LabelBox = styled("div")({
  pageBreakAfter: "always",
  width: "calc(100vw - 4px)",
  height: "calc(100vh - 4px)",
  border: "2px solid black"
});

function PutawayLabel({ label }: { label: LabelInfo }) {
  const { sku, productName, units } = label;
  return (
    <LabelBox>
      <div style={{ fontSize: 16, padding: "3px 0 0 3px" }}>{productName}</div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Barcode
          value={sku}
          width={1.3}
          height={60}
          format="CODE128"
          displayValue
          textMargin={0}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 3px"
        }}
      >
        <div style={{ textAlign: "center", fontSize: 24 }}>{units}</div>
      </div>
    </LabelBox>
  );
}

export const PrintablePutawayLabels = forwardRef<HTMLDivElement, LabelProps>(
  (props, ref) => {
    const { labels } = props;

    return (
      <div style={{ backgroundColor: "white", minHeight: "100vh" }} ref={ref}>
        {labels.map((label) => (
          <PutawayLabel label={label} key={label.putawayTaskId} />
        ))}
      </div>
    );
  }
);
PrintablePutawayLabels.displayName = "PrintablePutawayLabels";

export default PrintablePutawayLabels;
