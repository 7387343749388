import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

export function StatusChip({
  batchStatus
}: {
  batchStatus:
    | "Scheduled"
    | "Cart Ready"
    | "Processing"
    | "Picked"
    | "Completed"
    | "Staged"
    | "Dropped"
    | "Failed"
    | "Suspended"
    | "Waiting Dependency"
    | "Canceled"
    | "Converted";
}) {
  const { t } = useTranslation();

  let chipColor = "warning.main";
  const textColor = "#000";
  let textToDisplay = "";
  switch (batchStatus) {
    case "Processing":
      chipColor = "statusGray.main";
      textToDisplay = t("processing");
      break;
    case "Cart Ready":
      chipColor = "statusBlue.main";
      textToDisplay = t("cart ready");
      break;
    case "Completed":
      chipColor = "statusGreen.main";
      textToDisplay = t("completed");
      break;
    case "Failed":
      chipColor = "statusRed.main";
      textToDisplay = t("failed");
      break;
    case "Staged":
      chipColor = "statusGreen.main";
      textToDisplay = t("staged");
      break;
    case "Dropped":
      chipColor = "statusBlue.main";
      textToDisplay = t("dropped");
      break;
    case "Scheduled":
      chipColor = "statusGray.main";
      textToDisplay = t("scheduled");
      break;
    case "Picked":
      chipColor = "statusGreen.main";
      textToDisplay = t("picked");
      break;
    case "Suspended":
      chipColor = "statusRed.main";
      textToDisplay = t("suspended");
      break;
    case "Waiting Dependency":
      chipColor = "statusRed.main";
      textToDisplay = t("waiting dependency");
      break;
    case "Canceled":
      chipColor = "statusRed.main";
      textToDisplay = t("canceled");
      break;
    case "Converted":
      chipColor = "statusBlue.main";
      textToDisplay = t("converted");
      break;
  }
  return (
    <Chip
      sx={{ backgroundColor: chipColor, color: textColor }}
      label={textToDisplay}
      className={
        batchStatus.toLowerCase() === "scheduled" ? "scheduled-batch" : ""
      }
    />
  );
}
