import Backdrop, { BackdropProps } from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export function LoadingBackdrop(props: BackdropProps) {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: 1201 }} {...props}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
