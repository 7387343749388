import Button from "@locaisolutions/button";
import { Checkmark20Px } from "@locaisolutions/icons";

import { Refresh } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getUserClientId } from "~/api/usersTypes/auth0Profile";
import { useAppDispatch, useAppSelector } from "~/app/store";
import MultiPort from "~/components/autostore/autostoreBin/MultiPort";
import {
  ASTableV2,
  ASTableV2Body,
  ASTableV2Cell,
  ASTableV2Header,
  ASTableV2Row
} from "~/components/autostore/table";
import { PickInfoIsLoading } from "~/components/productCard/UniversalProductCard";
import FlagBinModal from "~/features/autostorePutaway/modals/FlagBinModal";
import InventoryHoldModal from "~/features/autostorePutaway/modals/InventoryHoldModal";
import { setBinsFlags } from "~/features/cycleCounts/cycleCounts.slice";
import { useDevCheats } from "~/hooks/useDevCheats";
import { useInactivityResetTimer } from "~/hooks/useInactivityResetTimer";
import { useNavbar } from "~/hooks/useNavbar";
import usePortStatus from "~/hooks/usePortStatus";
import { useShouldListenToGridEvents } from "~/hooks/useShouldListenToGridEvents";
import { useToast } from "~/hooks/useToast";
import { isExpirationValid } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import usePromiseInterval from "~/lib/usePromiseIntervalEffect";
import {
  fetchPortStatus,
  getNextBinWithConfig,
  closeBin,
  binNotAtPort,
  GetPortResponse,
  closeWorkstation,
  getNextBin,
  setPortStatus
} from "~/redux/actions/autostore";
import { clearSelectedCountingTasks } from "~/redux/actions/cycleCounts";
import { clearSelectedVariant } from "~/redux/actions/inventory";
import { setUserMessage } from "~/redux/actions/site";
import { StoreState } from "~/redux/reducers";

import { useLazyGetBinQuery } from "~/redux/warehouse/autostoreGrid.hooks";

import {
  usePostStartCycleCountingMutation,
  usePostCompleteCountingTaskMutation
} from "~/redux/warehouse/cycleCounts.hooks";
import { useGetInventorySummaryListQuery } from "~/redux/warehouse/inventory.hooks";

import { useVariantsByIdQuery } from "~/redux/warehouse/variant.hooks";
import { InventoryDto, InventorySummaryDto, HoldDto } from "~/types/api";

import { BlindCount } from "./BlindCount";
import CountingTaskInventoryCard from "./CountingTaskInventoryCard";

const mapStateToProps = (state: StoreState) => ({
  clientId: getUserClientId(state.login.profile),
  selectedAutostoreGridId: state.workstations.siteWorkstation?.autostoreGridId,
  selectedPortId: state.workstations.sitePortId,
  portState: state.autostore.portState,
  usersFulfillmentCenter: state.store.usersFulfillmentCenter,
  thisWorkstationId: state.workstations.siteWorkstation?.id,
  selectedCountingTasks: state.cycleCounts.selectedCountingTasks,
  portStateByPort: state.autostore.portStateByPort,
  /** response from nextEmptyBin call */
  nextEmptyBinByPort: state.autostore.nextEmptyBinByPort,
  siteWorkstation: state.workstations.siteWorkstation,
  siteAllPortIds: state.workstations.siteAllPortIds,
  nextBinInventoryByPort: state.autostore.nextBinInventoryByPort,
  inv_inventoryDateLabel: state.site.clientConfig.inv_inventoryDateLabel,
  inv_inventoryDateRequired: state.site.clientConfig.inv_inventoryDateRequired
});

const connector = connect(mapStateToProps, {
  getNextBinWithConfig,
  fetchPortStatus,
  binNotAtPort,
  closeBin,
  closeWorkstation,
  setUserMessage,
  getNextBin,
  setPortStatus,
  clearSelectedVariant
});

type PropsFromRedux = ConnectedProps<typeof connector>;
export type CycleCountProps = PropsFromRedux;

export const CycleCountTask = (props: CycleCountProps) => {
  const {
    selectedAutostoreGridId,
    selectedPortId,
    usersFulfillmentCenter,
    portStateByPort,
    nextEmptyBinByPort,
    siteWorkstation,
    thisWorkstationId,
    siteAllPortIds,
    nextBinInventoryByPort,
    inv_inventoryDateLabel,
    inv_inventoryDateRequired,
    selectedCountingTasks
  } = props;

  const binFlaggingEnabled = usersFulfillmentCenter?.binFlaggingEnabled;

  // hooks
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { successToast, errorToast } = useToast();
  const shouldListenToGridEvents = useShouldListenToGridEvents();
  const isInventoryDateFieldDirty = useAppSelector(
    (state) => state.cycleCountsSlice.isInventoryDateFieldDirty
  );

  // local state
  const [initialFetchComplete, setInitialFetchComplete] =
    useState<boolean>(false);

  // modal state
  const [isInventoryHoldModalOpen, setIsInventoryHoldModalOpen] =
    useState(false);
  const [isFlagBinModalOpen, setIsFlagBinModalOpen] = useState(false);

  // bin fetching state
  const [portPollingActive, setPortPollingActive] = useState<boolean>(false);
  const [binAtPortSeconds, setBinAtPortSeconds] = useState(0);

  /** specifically selected inventory after "get bins" */
  const [currentSelectedInventory, setCurrentSelectedInventory] =
    useState<InventorySummaryDto | null>(null);

  /** Bin that corresponds to selected inventory summary */
  const currentSelectedBin = Object.values(nextBinInventoryByPort).find((bin) =>
    bin.nextBinInventory.some(
      (inv) => inv.inventoryId === currentSelectedInventory?.inventoryId
    )
  );
  const currentSelectedPortId = currentSelectedBin?.portId;
  const currentSelectedBinNumber = currentSelectedInventory?.autostoreBinNumber;

  const { areAllPortsReady, areSomePortsReady, portStateByPortArray } =
    usePortStatus(portStateByPort, siteAllPortIds, currentSelectedPortId);

  const isSelectedBinReady =
    currentSelectedPortId &&
    portStateByPort[currentSelectedPortId]?.getPortResponse.isReady;

  const [selectedInventoryRecord, setSelectedInventoryRecord] =
    useState<InventoryDto | null>(null);

  // inventory adjustment state hooks
  const [changedQuantity, setChangedQuantity] = useState<number | undefined>();
  const [inventoryDate, setInventoryDate] = useState<Date | string | null>(
    null
  );

  const portStateBinIds = useMemo(
    () => portStateByPortArray.map((port) => port.getPortResponse.selectedBin),
    [portStateByPortArray]
  );

  // page vars
  // good chance this is getting removed with a new way of handling pagination.
  const [summariesPage, setSummariesPage] = useState<number>(1);
  const limit = 6;

  // query hooks
  const inventoryIds =
    selectedCountingTasks?.flatMap((cycleCount) => cycleCount.countingTasks) ??
    [];
  const [sortedSummariesToFetch, setSortedSummariesToFetch] = useState<Guid[]>(
    []
  );

  // fallback to inventoryIds if the user hasn't completed any tasks
  const {
    data,
    isLoading,
    refetch: refetchInventorySummaryList
  } = useGetInventorySummaryListQuery({
    inventoryIds: sortedSummariesToFetch.length
      ? sortedSummariesToFetch
      : inventoryIds,
    limit: 150,
    offset: 0
  });
  const { data: countingTastVariantData, refetch: refetchUserSelectedVariant } =
    useVariantsByIdQuery({
      variantIds: selectedInventoryRecord
        ? selectedInventoryRecord.variantId
        : []
    });

  const inventoryTaskRecords = data?.inventory;
  const selectedSummariesAtPort = inventoryTaskRecords
    ?.filter((sum) => portStateBinIds.includes(sum.autostoreBinNumber || 0))
    .map((sum) => sum.inventoryId);

  const [post] = usePostStartCycleCountingMutation();
  const [completeCountingTaskPost] = usePostCompleteCountingTaskMutation();
  const [fetchBinInfo] = useLazyGetBinQuery();

  /** selected summaries sorted by inventory at (or on the way to) the ports */
  const sortByPort = useCallback(
    (a: InventorySummaryDto, b: InventorySummaryDto) => {
      const aIndex = portStateBinIds.indexOf(a.autostoreBinNumber || 0);
      const bIndex = portStateBinIds.indexOf(b.autostoreBinNumber || 0);
      if (aIndex !== -1 && bIndex === -1) return -1;
      if (aIndex === -1 && bIndex !== -1) return 1;
      return aIndex - bIndex;
    },
    [portStateBinIds]
  );

  const [summariesToDisplay, setSummariesToDisplay] = useState<
    InventorySummaryDto[]
  >([]);
  const [completedCountingTasks, setCompletedCountingTasks] = useState<Guid[]>(
    []
  );
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] =
    useState<boolean>(true);

  const navigateToCycleCountPageCallback = useCallback(() => {
    dispatch(clearSelectedCountingTasks());
    navigate({
      pathname: `/autostore-cyclecountv2`
    });
  }, [navigate, dispatch]);

  // This function is in the dependency array of the 'inactivityModalCloseCallback' function
  // So we need to wrap it with useCallback to avoid endless re-renders
  const resetPage = useCallback(() => {
    setPortPollingActive(false);
    if (selectedAutostoreGridId && siteAllPortIds) {
      void dispatch(closeWorkstation());
    }
    navigateToCycleCountPageCallback();
  }, [
    selectedAutostoreGridId,
    navigateToCycleCountPageCallback,
    setPortPollingActive,
    dispatch,
    siteAllPortIds
  ]);

  useInactivityResetTimer({
    onInactivityModalClose: resetPage
  });

  // Maps product within bin to product on counting-task table
  const handleBinClickCallback = (binId?: number, inventoryId?: Guid) => {
    if (binId) {
      if (
        !(selectedInventoryRecord?.bin.autostoreBin?.autostoreBinId === binId)
      ) {
        setChangedQuantity(undefined);
        setInventoryDate(null);
      }
      // Return highlighted inventoryIds to match on
      const highlightedInventoryIds = inventoryTaskRecords
        ?.filter((sum) => portStateBinIds.includes(sum.autostoreBinNumber || 0))
        .map((sum) => sum.inventoryId);

      // Return inventory record which matches on the inventoryId within the selected binId
      const selectedInventoryByBin = Object.values(nextBinInventoryByPort)
        .flatMap((inv) => inv.nextBinInventory)
        .filter(
          (binInv) =>
            binInv.bin.autostoreBin?.autostoreBinId === binId &&
            highlightedInventoryIds?.includes(binInv.inventoryId)
        );
      // if we know the inventoryId (user clicks specific row), use it. Otherwise default to first inventoryDto
      const userSelectedInventory = inventoryId
        ? selectedInventoryByBin.find((inv) => inv.inventoryId === inventoryId)
        : selectedInventoryByBin[0];
      if (userSelectedInventory) {
        setIsConfirmButtonDisabled(false);
        setSelectedInventoryRecord(userSelectedInventory);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        refetchUserSelectedVariant();
      }
      const selectedInventoryRow = inventoryTaskRecords?.find(
        (row) => row.inventoryId === userSelectedInventory?.inventoryId
      );
      if (selectedInventoryRow)
        setCurrentSelectedInventory(selectedInventoryRow);
    }
  };

  const handleClickFlagBin = () => {
    setIsFlagBinModalOpen((prev) => !prev);

    //get all the flags for the current bins and show them above bins on the flagBinModal
    siteAllPortIds?.map((portId) => {
      const binId = portStateByPort[portId].getPortResponse.selectedBin;
      if (binId && selectedAutostoreGridId) {
        void fetchBinInfo({
          autostoreGridId: selectedAutostoreGridId,
          binNumber: binId
        })
          .unwrap()
          .then((binInfo) => {
            dispatch(setBinsFlags({ binId: binId, flags: binInfo.flags }));
          });
      }
    });
  };

  const handleFetchPortStatus = async (portId?: number) => {
    await props.fetchPortStatus({ portId });
  };

  // Port polling: if log publisher enabled, poll port and log publisher state
  const waitInterval = 7;
  usePromiseInterval(
    async () => {
      setBinAtPortSeconds((binAtPortSecondsState) => binAtPortSecondsState + 1);
      if (binAtPortSeconds > waitInterval && siteWorkstation) {
        await Promise.all(
          siteWorkstation.ports.map((port) => {
            const portStateResponse =
              portStateByPort[port.portId]?.getPortResponse;
            if (
              !portStateResponse?.isReady &&
              portStateResponse?.selectedBin !== 0
            ) {
              return handleFetchPortStatus(port.portId);
            }
            return Promise.resolve();
          })
        );
      }
    },
    1000,
    shouldListenToGridEvents && !areAllPortsReady
  );

  // Poll if signal r is diconnected
  usePromiseInterval(
    async () => {
      await Promise.all(
        (siteWorkstation?.ports ?? []).map((port) => {
          const portStateResponse =
            portStateByPort[port.portId]?.getPortResponse;
          if (
            !portStateResponse?.isReady &&
            portStateResponse?.selectedBin !== 0
          ) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
            handleFetchPortStatus(port.portId);
          }
        })
      );
    },
    500,
    !areAllPortsReady && !shouldListenToGridEvents
  );

  const onClickIncreaseQuantity = (previousQty: number) => {
    const newQuantity = (previousQty ?? 0) + 1;
    setChangedQuantity(newQuantity);
  };

  const onClickDecreaseQuantity = (previousQty: number) => {
    const newQuantity = Math.max((previousQty ?? 1) - 1, 0);
    if (newQuantity >= 0) {
      setChangedQuantity(newQuantity);
    } else {
      setUserMessage({
        title: "Quantity can't be negative",
        severity: "error"
      });
    }
  };

  const handleGetNextBin = async (portId?: number) => {
    // Get port status in order to close bin and call next bin
    await props.fetchPortStatus({ portId }).then(async (portStatus) => {
      if (portStatus) {
        const { selectedTask } = portStatus;
        if (portStatus.selectedBin) {
          await props.closeBin({
            binId: portStatus.selectedBin,
            taskId: selectedTask,
            portId: portStatus.portId
          });
        }

        await props.getNextBin({ portId, shouldSoftFail: true });
        siteWorkstation?.ports.forEach((port) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          handleFetchPortStatus(port.portId);
        });
        if (!shouldListenToGridEvents) {
          setPortPollingActive(true);
        } else {
          props.binNotAtPort();
        }
      }
    });

    // Get port status again in order to update portState's ready to be false while calling 'next bin'
    await props
      .fetchPortStatus({ portId })
      .then((data: GetPortResponse | void) => {
        if (!data) return;
        props.setPortStatus(data);
      });
  };

  const selectedCountingTask =
    currentSelectedInventory &&
    selectedCountingTasks?.find((cc) =>
      cc.countingTasks.includes(currentSelectedInventory.inventoryId)
    );

  const handleCompleteCountingTask = async () => {
    if (
      !currentSelectedInventory ||
      !currentSelectedInventory?.count?.value ||
      !selectedCountingTask ||
      !selectedAutostoreGridId ||
      !currentSelectedPortId ||
      !thisWorkstationId
    ) {
      errorToast("Can't complete task, no grid information found");
      return;
    }

    if (
      !(inventoryDate || currentSelectedInventory.expiration) &&
      countingTastVariantData?.at(0)?.isExpirationRequired
    ) {
      errorToast("Expiration Date can't be empty or invalid");
      return;
    }

    try {
      await completeCountingTaskPost({
        cycleCountId: selectedCountingTask.cycleCountId,
        inventoryId: currentSelectedInventory?.inventoryId,
        count: changedQuantity ?? currentSelectedInventory?.count?.value,
        expiration: inventoryDate ? new Date(inventoryDate) : null,
        gridId: selectedAutostoreGridId,
        portId: currentSelectedPortId,
        workstationId: thisWorkstationId
      }).unwrap();
    } catch (err: unknown) {
      errorToast(getMessageFromRtkError(err));
      return;
    }

    successToast(t("counting task completed successfully"));
    props.clearSelectedVariant();
    setCurrentSelectedInventory(null);
    setSelectedInventoryRecord(null);
    setIsConfirmButtonDisabled(true);
    setSummariesPage(1);
    setCompletedCountingTasks([
      ...completedCountingTasks,
      currentSelectedInventory.inventoryId
    ]);
    if (summariesToDisplay.length > 1 && currentSelectedInventory) {
      summariesToDisplay
        .filter(
          (summary) =>
            summary.inventoryId !== currentSelectedInventory?.inventoryId
        )
        .sort(sortByPort)
        .slice((summariesPage - 1) * limit, limit * summariesPage);
    }
    // For the final counting task, rely on nextBinWithConfig error supression
    // to ensure the bin is closed and content code is updated.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    handleGetNextBin(currentSelectedPortId);
  };

  const handleGetBinsMultiPort = async (
    cycleCountIds: Guid[]
  ): Promise<void> => {
    if (!selectedAutostoreGridId || !thisWorkstationId || !siteWorkstation)
      return;
    await post({
      cycleCountIds,
      gridId: selectedAutostoreGridId,
      workstationId: thisWorkstationId
    }).then(async () => {
      for (const portId of siteAllPortIds) {
        try {
          await props.getNextBinWithConfig({ portId });
        } catch {
          return;
        }

        await props
          .fetchPortStatus({ portId })
          .then((getPortResponse: GetPortResponse | void) => {
            if (!getPortResponse) return;
            const portIsOpen = getPortResponse.mode
              .toLowerCase()
              .includes("open");
            const portIsReady = getPortResponse.isReady;
            if (!portIsReady && shouldListenToGridEvents) {
              props.binNotAtPort();
            }
            if (portIsOpen && portIsReady) {
              try {
                if (!shouldListenToGridEvents) {
                  setPortPollingActive(true);
                } else {
                  props.binNotAtPort();
                }
              } catch {
                setBinAtPortSeconds(0);
              }
            }
          });
      }
    });
  };

  // UseEffect: 1 -- Starts our Counting Tasks
  useEffect(() => {
    if (!initialFetchComplete && inventoryTaskRecords?.length) {
      const cycleCountIds = selectedCountingTasks?.map(
        (task) => task.cycleCountId
      );

      if (cycleCountIds?.length && siteAllPortIds) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        dispatch(closeWorkstation()).then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          handleGetBinsMultiPort(cycleCountIds);
        });
      }
      // handle get bins for counting-tasks
      setInitialFetchComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFetchComplete, inventoryTaskRecords?.length]);

  // UseEffect: 2 -- Stop polling when bin arrives
  useEffect(() => {
    if (!shouldListenToGridEvents && areAllPortsReady) {
      setPortPollingActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portStateByPort]);

  // UseEffect: 3 -- Reset Page & Navigate user to Cycle Count page on Task(s) completion
  useEffect(() => {
    // We bounce user to page 1 on task completion
    if (summariesToDisplay.length === 0 && !!completedCountingTasks.length) {
      successToast(
        "All selected Counting Tasks completed. Redirecting to Cycle Counts."
      );

      setTimeout(() => {
        // Fire reset page once summariesToDisplay are 0 on page 1
        // The delay allows the incomplete counting tasks projection to be updated
        // so the user does not see the completed task on the cycle count page
        resetPage();
      }, 1200);
    }
  }, [
    completedCountingTasks.length,
    summariesToDisplay.length,
    resetPage,
    successToast
  ]);

  // UseEffect 4 -- Filter counting-task table results
  useEffect(() => {
    if (inventoryTaskRecords) {
      let inventoryRecordsToSort = [...inventoryTaskRecords];

      if (completedCountingTasks.length) {
        inventoryRecordsToSort = inventoryRecordsToSort.filter(
          (inv) => !completedCountingTasks.includes(inv.inventoryId)
        );
      }
      const sortedSummariesToDisplay = inventoryRecordsToSort.sort(sortByPort);
      setSortedSummariesToFetch(
        sortedSummariesToDisplay.map((sum) => sum.inventoryId)
      );
      setSummariesToDisplay(
        sortedSummariesToDisplay.slice(
          (summariesPage - 1) * limit,
          limit * summariesPage
        )
      );
    }
  }, [
    inventoryTaskRecords,
    completedCountingTasks,
    summariesPage,
    limit,
    sortByPort
  ]);

  // UseEffect 5: on component dismounting, close the port & cleanup the workstation
  useEffect(() => {
    return () => {
      if (selectedAutostoreGridId && siteAllPortIds) {
        // close workstation
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        dispatch(closeWorkstation());
        dispatch(binNotAtPort());
      }
    };
  }, [selectedAutostoreGridId, siteAllPortIds, dispatch]);

  useDevCheats({ isPortPolling: portPollingActive, showAutostoreStatus: true });
  const { setMenuItems } = useNavbar({
    viewTitle: t("nav.viewname.autostore cyclecounts")
  });

  useEffect(() => {
    setMenuItems(
      selectedAutostoreGridId && selectedPortId
        ? [
            {
              textContent: t("refresh"),
              actionCb: resetPage
            }
          ]
        : []
    );
  }, [resetPage, selectedAutostoreGridId, selectedPortId, setMenuItems, t]);

  const getIsInventoryDateValid = (): boolean => {
    const formattedExpDate =
      !moment.isMoment(inventoryDate) && inventoryDate !== null
        ? moment(inventoryDate)
        : inventoryDate;
    const isDateValid = !!isExpirationValid(
      inv_inventoryDateRequired ||
        (!!countingTastVariantData?.length &&
          countingTastVariantData[0]?.isExpirationRequired &&
          isInventoryDateFieldDirty),
      formattedExpDate
    );
    if (!isDateValid !== isConfirmButtonDisabled) {
      setIsConfirmButtonDisabled(!isDateValid);
    }
    return isDateValid;
  };

  const selectedCompartment =
    currentSelectedInventory?.autostoreCompartmentNumber
      ? currentSelectedInventory.autostoreCompartmentNumber - 1
      : null;

  return (
    <Container maxWidth="xl" sx={{ padding: 2 }}>
      {/* Main */}
      <Grid container spacing={3} direction="row">
        <Grid container justifyContent="flex-end">
          <Box sx={{ mt: 3 }}>
            <Button
              onClick={() => resetPage()}
              startIcon={<Refresh />}
              variant="contained"
              color="primary"
              sx={{
                fontWeight: "normal"
              }}
            >
              {t("return to cycle counts")}
            </Button>
          </Box>
        </Grid>
        <Grid container item flexWrap="nowrap">
          <Grid item xs={11} sx={{ display: "flex" }}>
            <MultiPort
              pickQuantity={0}
              nextBinInventoryByPort={nextBinInventoryByPort}
              nextEmptyBinByPort={nextEmptyBinByPort}
              portStateByPort={portStateByPort}
              workstation={siteWorkstation}
              currentSelectedBinId={currentSelectedBinNumber}
              selectedSummariesAtPort={selectedSummariesAtPort}
              selectedCompartment={selectedCompartment ?? undefined}
              showLoading={!areSomePortsReady}
              onBinClick={(_, binId) => handleBinClickCallback(binId)}
            />
            <Grid
              item
              container
              alignContent="space-evenly"
              height="100%"
              flexBasis="min-content"
            >
              {binFlaggingEnabled && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleClickFlagBin}
                  disabled={!areSomePortsReady}
                  sx={{
                    minWidth: "175px",
                    color: "primary.secondary",
                    fontWeight: "normal"
                  }}
                >
                  {t("bin problem")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7} height="665px">
          <ASTableV2
            gridTemplateColumns="repeat(4, auto)"
            gridTemplateRows="auto repeat(6, 1fr)"
            isEmpty={summariesToDisplay.length === 0}
            isLoading={isLoading || !selectedCountingTasks?.length}
            pagination={{
              pageCount: Math.ceil(
                completedCountingTasks.length
                  ? (inventoryTaskRecords ?? []).filter(
                      (inv) =>
                        !completedCountingTasks.includes(inv?.inventoryId)
                    ).length / limit
                  : (inventoryTaskRecords ?? []).length / limit
              ),
              page: summariesPage,
              setPage: setSummariesPage
            }}
          >
            <ASTableV2Header>
              <ASTableV2Row>
                <ASTableV2Cell>
                  <Typography variant="overline">{t("product")}</Typography>
                </ASTableV2Cell>
                <ASTableV2Cell>
                  <Typography variant="overline">{t("count")}</Typography>
                </ASTableV2Cell>
                <ASTableV2Cell>
                  <Typography variant="overline">{t("due by")}</Typography>
                </ASTableV2Cell>
                <ASTableV2Cell>
                  <Typography variant="overline">{t("holds")}</Typography>
                </ASTableV2Cell>
              </ASTableV2Row>
            </ASTableV2Header>
            <ASTableV2Body>
              {summariesToDisplay.map((row) => {
                const thisTask = selectedCountingTasks?.find((task) =>
                  task.countingTasks.includes(row.inventoryId)
                );
                const dueDate =
                  selectedCountingTasks?.find((task) =>
                    task.countingTasks.includes(row.inventoryId)
                  )?.dueDate || "n/a";
                return (
                  <ASTableV2Row
                    key={row.inventoryId}
                    selected={
                      currentSelectedInventory?.inventoryId === row.inventoryId
                    }
                    highlighted={selectedSummariesAtPort?.includes(
                      row.inventoryId
                    )}
                    onClick={() => {
                      // allow user to select row if it's inventory that's at the port
                      if (selectedSummariesAtPort?.includes(row.inventoryId))
                        handleBinClickCallback(
                          row.autostoreBinNumber,
                          row.inventoryId
                        );
                    }}
                  >
                    <ASTableV2Cell>
                      <Typography variant="body2">{row.productName}</Typography>
                    </ASTableV2Cell>
                    <ASTableV2Cell>
                      <BlindCount count={row.count} task={thisTask} />
                    </ASTableV2Cell>
                    <ASTableV2Cell>
                      <Typography variant="body2">{dueDate}</Typography>
                    </ASTableV2Cell>
                    <ASTableV2Cell sx={{ gap: 1 }}>
                      {(row?.holds || []).map((hold: HoldDto) => (
                        <Typography key={hold.reasonCode} display="block">
                          {`${t(hold.reasonCode)} (${t(hold.source)})`}
                        </Typography>
                      ))}
                    </ASTableV2Cell>
                  </ASTableV2Row>
                );
              })}
            </ASTableV2Body>
          </ASTableV2>
        </Grid>

        <Grid item xs={5}>
          {summariesToDisplay?.length &&
          selectedCountingTasks?.length &&
          countingTastVariantData?.length &&
          !!currentSelectedInventory ? (
            <CountingTaskInventoryCard
              selectedVariant={countingTastVariantData[0]}
              inv_inventoryDateLabel={inv_inventoryDateLabel}
              inventoryDate={inventoryDate}
              setInventoryDate={setInventoryDate}
              currentSelectedInventory={currentSelectedInventory}
              selectedInventoryRecord={selectedInventoryRecord}
              changedQuantity={changedQuantity}
              onClickIncreaseQuantity={onClickIncreaseQuantity}
              onClickDecreaseQuantity={onClickDecreaseQuantity}
              handleChangeQuantity={(count) => setChangedQuantity(count)}
              isDateValid={getIsInventoryDateValid()}
              isCycleCountBlind={selectedCountingTask?.isBlind ?? false}
            />
          ) : (
            <PickInfoIsLoading height="630px" disableGutters />
          )}
        </Grid>
        <Grid item container xs={7} justifyContent={"flex-end"}>
          <Button
            data-testid="inventory-hold-button"
            kind="subtle"
            size="large"
            startIcon={<ErrorOutlineIcon />}
            onClick={() => setIsInventoryHoldModalOpen((prev) => !prev)}
            disabled={!isSelectedBinReady}
            sx={{ px: 6, fontWeight: "normal" }}
          >
            {t("inventory hold")}
          </Button>
        </Grid>
        <Grid item xs={5}>
          <Button
            kind="accent"
            size="large"
            sx={{ width: "100%", fontWeight: "normal" }}
            startIcon={<Checkmark20Px style={{ fill: "white" }} />}
            onClick={handleCompleteCountingTask}
            disabled={
              isConfirmButtonDisabled ||
              !isSelectedBinReady ||
              isNaN(changedQuantity ?? 0) ||
              !(changedQuantity || currentSelectedInventory?.count?.value) ||
              (changedQuantity === undefined && selectedCountingTask?.isBlind) // if blind, user must enter a count before confirming
            }
          >
            {t("confirm")}
          </Button>
        </Grid>
      </Grid>
      <InventoryHoldModal
        isOpen={isInventoryHoldModalOpen}
        onClose={() => {
          setIsInventoryHoldModalOpen(false);
        }}
        onCallback={() => refetchInventorySummaryList()}
        nextEmptyBinByPort={nextEmptyBinByPort}
        nextBinInventoryByPort={nextBinInventoryByPort}
        portStateByPort={portStateByPort}
        currentSelectedBin={currentSelectedBin?.nextBinResponse ?? null}
        currentSelectedCompartment={selectedCompartment}
      />
      <FlagBinModal
        isOpen={isFlagBinModalOpen}
        onClose={() => setIsFlagBinModalOpen(false)}
        nextBinInventoryByPort={nextBinInventoryByPort}
        nextEmptyBinByPort={nextEmptyBinByPort}
        portStateByPort={portStateByPort}
        workstation={siteWorkstation}
        shouldCloseBinUponSubmit={false}
        selectedBin={currentSelectedBinNumber}
        onSuccess={() => {
          successToast(t("flag set successfully"));
          return refetchInventorySummaryList();
        }}
      />
    </Container>
  );
};

export default connector(CycleCountTask);
