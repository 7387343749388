import { createSvgIcon } from "@mui/material";

export const CornerSvg = createSvgIcon(
  <svg
    width="7"
    height="48"
    viewBox="0 0 7 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 15818">
      <path
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 38V48H-4C1.52285 48 6 43.5228 6 38Z"
        fill="currentcolor"
      />
    </g>
  </svg>,
  "LeftCorner"
);
