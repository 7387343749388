import { createSelector } from "@reduxjs/toolkit";

import { AppSelector } from "~/app/store";
import { StoreState } from "~/redux/reducers";

export const selectUsersFulfillmentCenter = (state: StoreState) => {
  return state.store?.usersFulfillmentCenter;
};

export const selectUsersFulfillmentCenterId = (state: StoreState) => {
  return state.store.usersFulfillmentCenter?.fulfillmentCenterId;
};

/** Selector to get Putaway Inventory Adjustment reason codes from the client configuration. */
export const selectPutawayAdjustmentReasonCodes: AppSelector<
  string[] | undefined
> = createSelector(
  [selectUsersFulfillmentCenter],
  (usersFulfillmentCenter) =>
    usersFulfillmentCenter?.clientConfiguration?.putawayAdjustmentReasonCodes
);

export const selectIsRecurringScheduleFc: AppSelector<boolean> = createSelector(
  [selectUsersFulfillmentCenter],
  (usersFulfillmentCenter) =>
    usersFulfillmentCenter?.batchConfiguration.toLowerCase() ===
    "recurring schedule"
);
