import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";

export const DevCheatsContext = createContext<{
  setIsPortPolling: (isPortPolling: boolean) => void;
  setShowAutostoreStatus: (showAutostoreStatus: boolean) => void;
  setShowEventSimulator: (showEventSimulator: boolean) => void;
  isPortPolling: boolean;
  showAutostoreStatus: boolean;
  showEventSimulator: boolean;
  /**
   * Resets the context to the initial state.  Should be called when a route changes.
   */
  reset: () => void;
}>(null as never);

export const DevCheatsProvider: FC<{ children: ReactNode }> = ({
  children
}) => {
  const [isPortPolling, setIsPortPolling] = useState<boolean>(false);
  const [showAutostoreStatus, setShowAutostoreStatus] =
    useState<boolean>(false);
  const [showEventSimulator, setShowEventSimulator] = useState<boolean>(false);

  const reset = useCallback(() => {
    setIsPortPolling(false);
    setShowAutostoreStatus(false);
    setShowEventSimulator(false);
  }, [setShowAutostoreStatus]);

  return (
    <DevCheatsContext.Provider
      value={{
        isPortPolling,
        showAutostoreStatus,
        showEventSimulator,
        setIsPortPolling,
        setShowAutostoreStatus,
        setShowEventSimulator,
        reset
      }}
    >
      {children}
    </DevCheatsContext.Provider>
  );
};

type Options = {
  /**
   * Displays the autostore grid status
   * @default false
   */
  isPortPolling?: boolean;
  /**
   * Displays the autostore grid status
   * @default false
   */
  showAutostoreStatus?: boolean;
  /**
   * Displays the event simulator
   * @default false
   */
  showEventSimulator?: boolean;
};

export function useDevCheats({
  isPortPolling = false,
  showAutostoreStatus = false,
  showEventSimulator = false
}: Options = {}) {
  const context = useContext(DevCheatsContext);
  const { setIsPortPolling, setShowAutostoreStatus, setShowEventSimulator } =
    context;

  useEffect(() => {
    setIsPortPolling(isPortPolling);
    setShowAutostoreStatus(showAutostoreStatus);
    setShowEventSimulator(showEventSimulator);
  }, [
    isPortPolling,
    setIsPortPolling,
    setShowAutostoreStatus,
    setShowEventSimulator,
    showAutostoreStatus,
    showEventSimulator
  ]);

  return context;
}
