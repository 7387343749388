export type WorkstationSize = {
  workstationId: Guid;
  xCoords: number[];
  yCoords: number[];
};

// Todo: Fusion and Multiport port types can be consolidated
export const portTypes = ["independent", "cleaning", "fusion", "multiport"];
export const configurationOptions = ["Whole Bin", "Half Bin", "Quarter Bin"];
export const binSizes = [0, 220, 330, 425];
export const portDirections = ["no direction", "inbound", "outbound"];

// Create an array of numbers from 0 to 4, allowing the array to grow if max(xCoords) > 4
export const possibleGridCoordinates = Array.from(
  { length: 19 },
  (_, i) => i + -9
);
