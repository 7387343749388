import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import { Box } from "@mui/material";

import { PickDto } from "~/types/api";

function PickStatusIcon({ pick }: { pick: PickDto }) {
  const isComplete = pick.status.toLowerCase() === "completed";
  const isOOS =
    isComplete &&
    !!pick.exception &&
    pick.exception.type?.toLowerCase() === "outofstock";
  const isCanceled = pick.status.toLowerCase() === "canceled" || isOOS;

  if (isCanceled) {
    return (
      <CancelOutlined
        sx={{ color: "autostoreRed.main" }}
        data-testid="oos-icon"
      />
    );
  }

  if (isComplete) {
    return (
      <CheckCircleOutline
        data-testid="complete-icon"
        sx={{ color: "success.main" }}
      />
    );
  }
  return <Box width="24px" height="24px" />;
}

export default PickStatusIcon;
