import { createSelector } from "@reduxjs/toolkit";

import { AppSelector, selectState } from "~/app/store";
import { selectDropdownWorkstationId } from "~/features/workstationConfig/workstationConfig.slice";
import { autostoreGridApi } from "~/redux/warehouse/autostoreGrid";
import { workstationApi } from "~/redux/warehouse/workstation";
import { AutostoreGridDto, WorkstationSummaryDto } from "~/types/api";

import { selectUserIsAdmin, selectUsersClientId } from "./authSelectors";
import { selectClientConfig } from "./siteSelectors";
import { selectThisWorkstation } from "./workstationsSelectors";

export const selectIsDeleteWorkstationShown: AppSelector<boolean> =
  createSelector(
    [selectUsersClientId, selectUserIsAdmin, selectClientConfig],
    (clientId, isAdmin, { ws_deleteWorkstationEnabled }) => {
      // If pepsi is the client only admin is allowed to delete ws. Other clients
      // all have this permission
      const clientCondition =
        clientId === "9e951c04-edad-42a7-af77-accaf41701a1" ? !!isAdmin : true;
      return ws_deleteWorkstationEnabled && clientCondition;
    }
  );

export const selectIsCurrentDropdownWorkstationActivated: AppSelector<boolean> =
  createSelector(
    [selectThisWorkstation, selectDropdownWorkstationId],
    (siteWorkstation, dropdownWorkstationId) => {
      return siteWorkstation?.id === dropdownWorkstationId;
    }
  );

export const selectDropdownWorkstation: AppSelector<
  WorkstationSummaryDto | undefined
> = createSelector([selectState], (state) => {
  // We are specifically searching RTK Query's getWorkstations call here as
  // state.workstations and state.workstations.siteWorkstation are not
  // guaranteed to be up to date
  const { data: workstations } =
    workstationApi.endpoints.getWorkstations.select()(state);
  const dropdownWorkstationId = selectDropdownWorkstationId(state);

  return workstations?.find((ws) => ws.id === dropdownWorkstationId);
});

export const selectIsDropdownWorkstationPickingRoleEnabled: AppSelector<
  boolean | undefined
> = createSelector([selectDropdownWorkstation], (dropdownWorkstation) => {
  return dropdownWorkstation?.roles?.some(
    (role) => role.toLowerCase() === "picking"
  );
});

export const selectDropdownWorkstationAutostoreGrid: AppSelector<
  AutostoreGridDto | undefined
> = createSelector([selectState], (state) => {
  const { data: autostoreGrids } =
    autostoreGridApi.endpoints.getAutostoreGrids.select()(state);
  const dropdownWorkstation = selectDropdownWorkstation(state);

  return autostoreGrids?.find(
    (autostoreGrid) =>
      autostoreGrid.autostoreGridId === dropdownWorkstation?.autostoreGridId
  );
});
