import { warehouseService, handleWarehouseError } from "~/api/warehouse";
import { AppThunk, AsyncAppThunk } from "~/app/store";
import {
  BatchDto,
  LoosePickSummaryDto,
  StageToteRequest,
  StagingAreaDto,
  ToteSummaryDto
} from "~/types/api";

export interface GetBatchAction {
  type: "stage/GET_BATCH";
}

export interface GetBatchSuccessAction {
  type: "stage/GET_BATCH_SUCCESS";
  payload: BatchDto | null;
}

export interface GetBatchFailureAction {
  type: "stage/GET_BATCH_FAILURE";
  payload: string;
}

export const getBatch =
  (batchId: Guid): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<GetBatchAction>({
      type: "stage/GET_BATCH"
    });
    try {
      const getBatchResponse = await warehouseService.get<{
        batch: BatchDto;
        cartNumber: number | null;
      }>(`/api/batches/${batchId}`);
      dispatch<GetBatchSuccessAction>({
        type: "stage/GET_BATCH_SUCCESS",
        payload: getBatchResponse.data.batch
      });
    } catch (err) {
      handleWarehouseError(err, (message) =>
        dispatch<GetBatchFailureAction>({
          type: "stage/GET_BATCH_FAILURE",
          payload: message
        })
      );
    }
  };

export interface ClearErrorMessageAction {
  type: "stage/CLEAR_ERROR_MESSAGE";
}

export const clearErrorMessage = (): AppThunk => (dispatch) => {
  dispatch<ClearErrorMessageAction>({
    type: "stage/CLEAR_ERROR_MESSAGE"
  });
};

export interface GetStagingAreasAction {
  type: "stage/GET_STAGING_AREAS";
}

export interface GetStagingAreasSuccessAction {
  type: "stage/GET_STAGING_AREAS_SUCCESS";
  payload: StagingAreaDto[];
}

export interface GetStagingAreasFailureAction {
  type: "stage/GET_STAGING_AREAS_FAILURE";
  payload: string;
}

export const getStagingAreas = (): AsyncAppThunk => async (dispatch) => {
  dispatch<GetStagingAreasAction>({
    type: "stage/GET_STAGING_AREAS"
  });
  try {
    const getBatchResponse =
      await warehouseService.get<StagingAreaDto[]>(`/api/staging-areas`);
    dispatch<GetStagingAreasSuccessAction>({
      type: "stage/GET_STAGING_AREAS_SUCCESS",
      payload: getBatchResponse.data
    });
  } catch (err) {
    handleWarehouseError(err, (message) =>
      dispatch<GetStagingAreasFailureAction>({
        type: "stage/GET_STAGING_AREAS_FAILURE",
        payload: message
      })
    );
  }
};

export interface StageLoosePicksFailureAction {
  type: "stage/STAGE_LOOSE_PICKS_FAILURE";
  payload: string;
}

export const stageLoosePicks =
  ({
    putWallId,
    putWallLaneId,
    loosePickIds
  }: {
    putWallId: Guid;
    putWallLaneId: Guid | null;
    loosePickIds: Guid[];
  }): AsyncAppThunk =>
  async (dispatch) => {
    if (loosePickIds.length === 0) {
      return;
    }
    try {
      const request = {
        putWallId,
        putWallLaneId,
        loosePickIds
      };
      await warehouseService.post(`/api/loose-picks/stage`, request);
    } catch (err) {
      handleWarehouseError(err, (message) =>
        dispatch<StageLoosePicksFailureAction>({
          type: "stage/STAGE_LOOSE_PICKS_FAILURE",
          payload: message
        })
      );
    }
  };

export interface StageTotesFailureAction {
  type: "stage/STAGE_TOTES_FAILURE";
  payload: string;
}

export const stageTotes =
  ({
    putWallId,
    putWallLaneId,
    toteIds
  }: {
    putWallId: Guid;
    putWallLaneId: Guid;
    toteIds: Guid[];
  }): AsyncAppThunk =>
  async (dispatch) => {
    if (toteIds.length === 0) {
      return;
    }
    try {
      const request: StageToteRequest = {
        putWallId,
        putWallLaneId,
        toteIds
      };
      await warehouseService.post(`/api/totes/stage`, request);
    } catch (err) {
      handleWarehouseError(err, (message) =>
        dispatch<StageTotesFailureAction>({
          type: "stage/STAGE_TOTES_FAILURE",
          payload: message
        })
      );
    }
  };

export interface GetLoosePicksByBatchAction {
  type: "stage/GET_LOOSE_PICKS_BY_BATCH";
}
export interface GetLoosePicksByBatchSuccessAction {
  type: "stage/GET_LOOSE_PICKS_BY_BATCH_SUCCESS";
  payload: LoosePickSummaryDto[];
}
export interface GetLoosePicksByBatchFailureAction {
  type: "stage/GET_LOOSE_PICKS_BY_BATCH_FAILURE";
  payload: string;
}

export const getLoosePicksByBatch =
  (batchId: Guid): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<GetLoosePicksByBatchAction>({
      type: "stage/GET_LOOSE_PICKS_BY_BATCH"
    });
    try {
      const getBatchResponse = await warehouseService.get<
        LoosePickSummaryDto[]
      >(`/api/loose-picks/`, {
        params: { batchId }
      });
      dispatch<GetLoosePicksByBatchSuccessAction>({
        type: "stage/GET_LOOSE_PICKS_BY_BATCH_SUCCESS",
        payload: getBatchResponse.data
      });
    } catch (err) {
      handleWarehouseError(err, (message) =>
        dispatch<GetLoosePicksByBatchFailureAction>({
          type: "stage/GET_LOOSE_PICKS_BY_BATCH_FAILURE",
          payload: message
        })
      );
    }
  };

export interface GetTotesByBatchAction {
  type: "stage/GET_TOTES_BY_BATCH";
}
export interface GetTotesByBatchSuccessAction {
  type: "stage/GET_TOTES_BY_BATCH_SUCCESS";
  payload: ToteSummaryDto[];
}
export interface GetTotesByBatchFailureAction {
  type: "stage/GET_TOTES_BY_BATCH_FAILURE";
  payload: string;
}

export const getTotesByBatch =
  (batchId: Guid): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<GetTotesByBatchAction>({
      type: "stage/GET_TOTES_BY_BATCH"
    });
    try {
      const getBatchResponse = await warehouseService.get<ToteSummaryDto[]>(
        `/api/totes/`,
        {
          params: { batchId }
        }
      );
      dispatch<GetTotesByBatchSuccessAction>({
        type: "stage/GET_TOTES_BY_BATCH_SUCCESS",
        payload: getBatchResponse.data
      });
    } catch (err) {
      handleWarehouseError(err, (message) =>
        dispatch<GetTotesByBatchFailureAction>({
          type: "stage/GET_TOTES_BY_BATCH_FAILURE",
          payload: message
        })
      );
    }
  };

export interface ResetStageViewAction {
  type: "stage/RESET_STAGE_VIEW";
}

export const resetStageView = (): AppThunk => (dispatch) => {
  dispatch<ResetStageViewAction>({
    type: "stage/RESET_STAGE_VIEW"
  });
};
