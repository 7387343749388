import {
  ClearErrorMessageAction,
  GetDefaultPrinterAction,
  GetDefaultPrinterFailureAction,
  GetDefaultPrinterSuccessAction,
  GetPrinterStatusAction,
  GetPrinterStatusFailureAction,
  GetPrinterStatusSuccessAction,
  PrintToDefaultPrinterAction,
  PrintToDefaultPrinterFailureAction,
  PrintToDefaultPrinterSuccessAction
} from "~/redux/actions/printer";

export type PrinterState = {
  defaultPrinter: Device | null;
  statusLoading: boolean;
  readyToPrint: boolean;
  error: string | null;
};

const initialState = {
  defaultPrinter: null,
  statusLoading: false,
  readyToPrint: false,
  error: null
};

type Actions =
  | GetPrinterStatusAction
  | GetPrinterStatusSuccessAction
  | GetPrinterStatusFailureAction
  | GetDefaultPrinterAction
  | GetDefaultPrinterSuccessAction
  | GetDefaultPrinterFailureAction
  | PrintToDefaultPrinterAction
  | PrintToDefaultPrinterSuccessAction
  | PrintToDefaultPrinterFailureAction
  | ClearErrorMessageAction;

export const printer = (
  state: PrinterState | undefined,
  action: Actions
): PrinterState => {
  if (state === undefined) {
    return initialState;
  }

  switch (action.type) {
    case "printer/GET_PRINTER_STATUS":
      return {
        ...state,
        statusLoading: true
      };

    case "printer/GET_PRINTER_STATUS_SUCCESS":
      return {
        ...state,
        statusLoading: false,
        readyToPrint: true
      };

    case "printer/GET_DEFAULT_PRINTER_SUCCESS":
      return {
        ...state,
        defaultPrinter: action.payload
      };

    case "printer/GET_DEFAULT_PRINTER_FAILURE":
    case "printer/GET_PRINTER_STATUS_FAILURE":
    case "printer/PRINT_TO_DEFAULT_FAILURE":
      return {
        ...state,
        readyToPrint: false,
        statusLoading: false,
        error: action.payload
      };

    case "printer/CLEAR_ERROR_MESSAGE":
    case "printer/PRINT_TO_DEFAULT_SUCCESS":
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
};
