import { FulfillmentCenterDto } from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const fulfillmentCenterApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getFulfillmentCenter: build.query<FulfillmentCenterDto, Guid>({
      query: (fulfillmentCenterId) => {
        return { url: `/api/fulfillment-centers/${fulfillmentCenterId}` };
      },
      providesTags: ["fulfillment center"]
    }),

    getAllFulfillmentCenters: build.query<FulfillmentCenterDto[], void>({
      query: () => {
        return { url: `/api/fulfillment-centers` };
      },
      providesTags: ["fulfillment center"]
    })
  }),
  overrideExisting: false
});
