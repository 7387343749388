import { SelectChangeEvent } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState, useEffect, useRef, useMemo } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { useAppDispatch, useAppSelector } from "~/app/store";
import {
  saveEventData,
  selectAndonGrids,
  selectAndonSaveAllEvents,
  selectAndonWorkstations,
  sendUserMessages,
  setHandAction
} from "~/features/andon/andon.slice";
import { MixedEventData } from "~/lib/andonHelpers";
import { extractDisplayName, extractReceipientId } from "~/lib/helpers";

import { setSiteWorkstationStatus } from "~/redux/actions/workstations";
import { StoreState } from "~/redux/reducers";
import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

import { createEventSystemModeSample } from "./eventGridSystemModeSampleCreator";
import { eventPickSample } from "./eventPickSample";
import { eventPortErrorNoErrorSample } from "./eventPortErrorNoErrorSample";
import { eventPortErrorSample } from "./eventPortErrorSample";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";

const mapStateToProps = (state: StoreState) => ({
  siteAllPortIds: state.workstations.siteAllPortIds,
  siteFulfillmentCenterId: state.workstations.siteFulfillmentCenterId,
  sitePortId: state.workstations.sitePortId,
  siteWorkstationId: state.workstations.siteWorkstation?.id
});

const connector = connect(mapStateToProps, {
  setSiteWorkstationStatus
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export type EventSimulatorProps = PropsFromRedux & {
  floating?: boolean;
};

function EventSimulator(props: EventSimulatorProps) {
  // hooks
  const { t } = useTranslation();
  const draggableRef = useRef(null);
  const dispatch = useAppDispatch();
  const saveAllEvents = useAppSelector(selectAndonSaveAllEvents);
  const andonGrids = useAppSelector(selectAndonGrids);
  const andonWorkstations = useAppSelector(selectAndonWorkstations);

  // props
  const {
    floating,
    siteAllPortIds,
    siteFulfillmentCenterId,
    sitePortId,
    siteWorkstationId
  } = props;

  const { data: workstations, error: getWorkstationsError } =
    useGetWorkstationsQuery();
  if (getWorkstationsError) {
    throw new Error(getMessageFromRtkError(getWorkstationsError));
  }

  const workstationIds = useMemo(
    () => workstations?.map((workstation) => workstation.id),
    [workstations]
  );

  const [selectedPortId, setSelectedPortId] = useState<string>("--");
  const [selectedWorkstationId, setSelectedWorkstationId] =
    useState<string>("--");

  // receiver user id will receive the message
  const [receiverUserId, setRecieverUserId] = useState<string>("--");
  const [selectedGridId, setSelectedGridId] = useState<string>("--");

  useEffect(() => {
    // select first grid
    if (Object.keys(andonGrids).length && selectedGridId === "--") {
      setSelectedGridId(Object.keys(andonGrids)[0]);
    }

    if (workstationIds?.length && selectedWorkstationId === "--") {
      setSelectedWorkstationId(siteWorkstationId || "--");
    }

    if (sitePortId && selectedPortId === "--") {
      setSelectedPortId(sitePortId?.toString() || "--");
    }

    if (
      siteWorkstationId &&
      andonWorkstations[siteWorkstationId] &&
      receiverUserId === "--"
    ) {
      setRecieverUserId(
        extractReceipientId(
          andonWorkstations[siteWorkstationId].workstation.userId
        )
      );
    }
    // I don't want this function to run every time the andon board changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitePortId, siteWorkstationId, workstationIds]);

  // functions
  const createPortErrorEvent = () => {
    if (siteAllPortIds.includes(parseInt(selectedPortId, 10))) {
      props.setSiteWorkstationStatus({
        status: "bad",
        portId: parseInt(selectedPortId, 10)
      });
    }

    if (saveAllEvents)
      dispatch(
        saveEventData({
          portId: selectedPortId,
          hub: "grid",
          event: eventPortErrorSample,
          status: "bad"
        })
      );
  };

  const createGridStatusEvent = (caseCode: string) => {
    if (saveAllEvents) {
      dispatch(
        saveEventData({
          gridId: selectedGridId,
          hub: "grid",
          event: createEventSystemModeSample({
            caseCode: caseCode,
            gridId: selectedGridId
          })
        })
      );
    }
  };

  const createPortErrorNoErrorEvent = () => {
    if (siteAllPortIds.includes(parseInt(selectedPortId, 10))) {
      props.setSiteWorkstationStatus({
        status: "good",
        portId: parseInt(selectedPortId, 10)
      });
    }

    if (saveAllEvents) {
      dispatch(
        saveEventData({
          portId: selectedPortId,
          hub: "grid",
          event: eventPortErrorNoErrorSample,
          status: "good"
        })
      );
    }
  };

  const createPickEvent = (theEvent: MixedEventData) => {
    dispatch(
      saveEventData({
        workstationId:
          selectedWorkstationId === "undefined"
            ? undefined
            : selectedWorkstationId,
        portId: selectedPortId === "undefined" ? undefined : selectedPortId,
        hub: "pick",
        event: {
          ...theEvent,
          portId:
            selectedPortId === "undefined"
              ? undefined
              : parseInt(selectedPortId, 10)
        } as MixedEventData
      })
    );
  };

  const handleWorkstationSelect = (workstationId: string) => {
    if (workstationId !== "--") setSelectedPortId("--");

    setSelectedWorkstationId(workstationId);
  };

  const handleGridSelect = (gridId: string) => {
    setSelectedGridId(gridId);
  };

  const handleReceiverUserIdSelect = (userId: string) => {
    setRecieverUserId(userId);
  };

  const handleHandEvent = (handRaised: boolean) => {
    dispatch(
      setHandAction({ workstationId: selectedWorkstationId, handRaised })
    );
  };

  const handleMessageEvent = () => {
    if (!siteFulfillmentCenterId) return;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    dispatch(
      sendUserMessages({
        fcId: siteFulfillmentCenterId,
        recipientIds: [receiverUserId],
        message: "hello to you"
      })
    );
  };

  return (
    <>
      <Draggable nodeRef={draggableRef}>
        <Box
          ref={draggableRef}
          sx={{
            backgroundColor: "gray.light",
            position: floating ? "fixed" : "inherit",
            border: "1px solid ##777777",
            padding: 5,
            borderRadius: 7,
            top: floating ? 75 : "unset",
            right: floating ? 15 : "unset",
            display: "flex",
            flexDirection: "column",
            boxShadow:
              "5px 5px 15px 5px #FF8080, -9px 5px 15px 5px #FFE488, -7px -5px 15px 5px #8CFF85, 12px -5px 15px 5px #80C7FF, 12px 10px 15px 7px #E488FF, -10px 10px 15px 7px #FF616B, -10px -7px 27px 1px #8E5CFF, 5px 5px 15px 5px rgba(0,0,0,0)",
            zIndex: 1000,
            overflowY: "scroll",
            maxHeight: "90vh",
            width: "300px"
          }}
        >
          {/* title */}
          <Box
            sx={{
              height: "40px",
              backgroundColor: "darkGray.dark",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "3px solid black",
              boxSizing: "border-box",
              boxShadow: "2px 2px"
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                letterSpacing: "2px",
                fontFamily: "monospace",
                color: "#c898e0",
                cursor: "grab"
              }}
            >
              {t("event simulator").toLocaleUpperCase()}
            </Typography>
          </Box>

          {/* User selector */}
          <Box
            sx={{
              width: "95%",
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end"
            }}
          >
            <InputLabel sx={{ fontSize: "12px" }} id="userId-select-label">
              Recepient User ID
            </InputLabel>
            <Select
              id="userId-select"
              value={receiverUserId}
              labelId="userId-select-label"
              onChange={(e: SelectChangeEvent) => {
                handleReceiverUserIdSelect(e.target.value);
              }}
              variant="standard"
              sx={{ width: "70%", marginLeft: "15px" }}
            >
              <MenuItem key="--" value="--">
                --
              </MenuItem>
              {Object.values(andonWorkstations)
                .filter(
                  (workstation) => workstation.workstation.status === "Active"
                )
                .sort((a, b) =>
                  a.workstation.userId.localeCompare(b.workstation.userId)
                )
                .map((aws) => (
                  <MenuItem
                    key={extractReceipientId(aws.workstation.userId)}
                    value={extractReceipientId(aws.workstation.userId)}
                  >
                    {extractDisplayName(aws.workstation.userId)}
                  </MenuItem>
                ))}
            </Select>
          </Box>

          {/* Event Message */}
          <Box style={{ width: "100%" }}>
            <Button
              disabled={receiverUserId === "--"}
              onClick={() => {
                handleMessageEvent();
              }}
              color="primary"
            >
              {t("send message")}
            </Button>
          </Box>

          {/* Port Selector */}
          <Box
            sx={{
              width: "95%",
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end"
            }}
          >
            <InputLabel sx={{ fontSize: "12px" }} id="port-select-label">
              Port
            </InputLabel>
            <TextField
              aria-labelledby="port-select-label"
              variant="standard"
              sx={{ width: "50%", marginLeft: "15px" }}
              value={selectedPortId === "--" ? "--" : selectedPortId}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSelectedPortId(e.target.value)
              }
            />
          </Box>

          {/* Event Port Error Button */}
          <Box style={{ width: "100%", display: "flex" }}>
            <Button
              disabled={selectedPortId === "--"}
              onClick={() => {
                createPortErrorEvent();
              }}
              color="primary"
            >
              {t("port error")}
            </Button>
          </Box>

          {/* Event No Port Error Button */}
          <Box style={{ width: "100%" }}>
            <Button
              disabled={selectedPortId === "--"}
              onClick={() => {
                createPortErrorNoErrorEvent();
              }}
              color="primary"
            >
              {t("port error no error")}
            </Button>
          </Box>

          {/* grid selector */}
          <Box
            sx={{
              width: "95%",
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end"
            }}
          >
            <InputLabel sx={{ fontSize: "12px" }} id="grid-select-label">
              Grid
            </InputLabel>
            <Select
              id="grid-select"
              value={selectedGridId}
              labelId="grid-select-label"
              onChange={(e: SelectChangeEvent) => {
                const gridId = e.target.value;
                handleGridSelect(gridId);
              }}
              variant="standard"
              sx={{ width: "80%", marginLeft: "15px" }}
            >
              <MenuItem key="--" value="--">
                --
              </MenuItem>
              {Object.values(andonGrids).map((ag) => {
                return (
                  <MenuItem key={ag.gridId} value={ag.gridId}>
                    {`${ag.gridName}`}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          {/* Grid System STOPPED Button */}
          <Box style={{ width: "100%", display: "flex" }}>
            <Button
              disabled={selectedGridId === "--"}
              onClick={() => {
                createGridStatusEvent("STP");
              }}
              color="primary"
            >
              {t("grid system status STOPPED")}
            </Button>
          </Box>

          {/* Grid System SERVICE Button */}
          <Box style={{ width: "100%", display: "flex" }}>
            <Button
              disabled={selectedGridId === "--"}
              onClick={() => {
                createGridStatusEvent("SVC");
              }}
              color="primary"
            >
              {t("grid system status SERVICE")}
            </Button>
          </Box>

          {/* Grid System RUNNING Button */}
          <Box style={{ width: "100%", display: "flex" }}>
            <Button
              disabled={selectedGridId === "--"}
              onClick={() => {
                createGridStatusEvent("RUN");
              }}
              color="primary"
            >
              {t("grid system status RUNNING")}
            </Button>
          </Box>

          {/* Workstation selector */}
          <Box
            sx={{
              width: "95%",
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end"
            }}
          >
            <InputLabel sx={{ fontSize: "12px" }} id="workstation-select-label">
              Workstation
            </InputLabel>
            <Select
              id="workstation-select"
              value={selectedWorkstationId}
              labelId="workstation-select-label"
              onChange={(e: SelectChangeEvent) => {
                handleWorkstationSelect(e.target.value);
              }}
              variant="standard"
              sx={{ width: "70%", marginLeft: "15px" }}
            >
              <MenuItem key="--" value="--">
                --
              </MenuItem>
              {workstationIds?.map((id) => {
                const matchingAws = Object.values(andonWorkstations).find(
                  (aws) => aws.id === id
                );
                return (
                  <MenuItem key={id} value={id}>
                    {`${
                      matchingAws ? matchingAws?.workstation.deviceId : ""
                    }...`}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          {/* Event Raise Hand Button */}
          <Box style={{ width: "100%" }}>
            <Button
              disabled={selectedWorkstationId === "--"}
              onClick={() => {
                handleHandEvent(true);
              }}
              color="primary"
            >
              {t("raise hand")}
            </Button>
          </Box>

          {/* Event Lower Hand Button */}
          <Box style={{ width: "100%" }}>
            <Button
              disabled={selectedWorkstationId === "--"}
              onClick={() => {
                handleHandEvent(false);
              }}
              color="primary"
            >
              {t("lower hand")}
            </Button>
          </Box>

          {/* Event Pick Event Button */}
          <Box style={{ width: "100%" }}>
            <Button
              onClick={() => {
                createPickEvent(eventPickSample);
              }}
              color="primary"
            >
              {t("pick event")}
            </Button>
          </Box>
        </Box>
      </Draggable>
    </>
  );
}

export default connector(EventSimulator);
