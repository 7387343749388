import { warehouseService, handleWarehouseError } from "~/api/warehouse";
import { AsyncAppThunk } from "~/app/store";
import { AnalyticsDashboardDto } from "~/types/api";

export interface GetDashboardAction {
  type: "analytics/GET_DASHBOARD";
}

export interface GetDashboardSuccessAction {
  type: "analytics/GET_DASHBOARD_SUCCESS";
  payload: AnalyticsDashboardDto;
}

export interface GetDashboardFailureAction {
  type: "analytics/GET_DASHBOARD_FAILURE";
  payload: string;
}

/**
 * Get the parameters for enabling a dashboard to be embedded
 */
export const getDashboard =
  (dashboardType: string): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<GetDashboardAction>({
      type: "analytics/GET_DASHBOARD"
    });
    try {
      const response = await warehouseService.get<AnalyticsDashboardDto>(
        `/api/analytics/dashboards/${dashboardType}`
      );
      dispatch<GetDashboardSuccessAction>({
        type: "analytics/GET_DASHBOARD_SUCCESS",
        payload: response.data
      });
    } catch (err) {
      handleWarehouseError(err, (message) =>
        dispatch<GetDashboardFailureAction>({
          type: "analytics/GET_DASHBOARD_FAILURE",
          payload: message
        })
      );
    }
  };
