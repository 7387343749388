import { styled } from "@mui/material";
import { forwardRef } from "react";
import Barcode from "react-barcode";

type LabelInfo = {
  loosePickId: Guid;
  productName: string;
  customerName: string;
  orderId: string;
  loosePickQty: number;
};

type LabelProps = { labels: LabelInfo[] };

const LabelBox = styled("div")({
  pageBreakAfter: "always",
  width: "calc(100vw - 4px)",
  height: "calc(100vh - 4px)",
  border: "2px solid black"
});

function BulkLabel({ label }: { label: LabelInfo }) {
  const { loosePickId, customerName, productName, loosePickQty } = label;
  return (
    <LabelBox>
      <div style={{ fontSize: 16, padding: "3px 0 0 3px" }}>{productName}</div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Barcode
          value={loosePickId}
          width={1.3}
          height={60}
          format="CODE128"
          displayValue={false}
          textMargin={0}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 3px"
        }}
      >
        <div style={{ textAlign: "center", fontSize: 24 }}>{customerName}</div>
        <div style={{ textAlign: "center", fontSize: 24 }}>
          {`Qty: ${loosePickQty}`}
        </div>
      </div>
    </LabelBox>
  );
}

export const BulkPickLabels = forwardRef<HTMLDivElement, LabelProps>(
  (props, ref) => {
    const { labels } = props;

    return (
      <div style={{ backgroundColor: "white", minHeight: "100vh" }} ref={ref}>
        {labels.map((label, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <BulkLabel label={label} key={`${label.loosePickId} - ${i}`} />
        ))}
      </div>
    );
  }
);
BulkPickLabels.displayName = "BulkPickLabels";

export default BulkPickLabels;
