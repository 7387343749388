import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { useAppSelector } from "~/app/store";
import { useNavbar } from "~/hooks/useNavbar";
import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import { selectDropdownWorkstation } from "~/redux/selectors/workstationConfigSelectors";
import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";
import { UpdateWorkstation } from "~/types/api";

import WorkstationChangesToolbar from "./WorkstationChangesToolbar";

export const useWorkstationConfigForm = ({
  viewTitle
}: {
  viewTitle: string | undefined;
}) => {
  const { setToolbar } = useNavbar({ viewTitle });
  useGetWorkstationsQuery();
  const workstation = useAppSelector(selectDropdownWorkstation);
  const isAdmin = useAppSelector(selectUserIsAdmin);

  const defaultValues: UpdateWorkstation = {
    deviceId: workstation?.deviceId || "",
    autostoreGridId: workstation?.autostoreGridId || "",
    orientation: workstation?.orientation || "",
    roles: workstation?.roles || [],
    multiPortEnabled: workstation?.multiPortEnabled || false,
    ports: workstation?.ports || [],
    totePlacements: workstation?.totePlacements || [],
    workstationId: workstation?.id || "",
    mode: workstation?.mode || "",
    pickableOrderTypes: workstation?.pickableOrderTypes || []
  };

  const form = useForm<UpdateWorkstation>({ values: defaultValues });
  const {
    formState: { isDirty },
    reset
  } = form;

  useEffect(reset, [workstation?.id, reset]);

  useEffect(() => {
    if (isDirty && isAdmin) {
      setToolbar(
        <FormProvider {...form}>
          <WorkstationChangesToolbar viewTitle={viewTitle} />
        </FormProvider>
      );
    } else {
      setToolbar(undefined);
    }
  }, [isDirty, isAdmin, reset, setToolbar, viewTitle, form]);

  return form;
};
