import ASButton from "@locaisolutions/button";
import { Alert } from "@mui/material";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { handleWarehouseError } from "~/api/warehouse";
import { useAppDispatch } from "~/app/store";
import { clearAndonWorkstationsAndGrids } from "~/features/andon/andon.slice";
import { useToast } from "~/hooks/useToast";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { updateUsersFulfillmentCenter } from "~/redux/actions/store";
import { setSiteFcId, setThisWorkstation } from "~/redux/actions/workstations";
import { StoreState } from "~/redux/reducers";
import { useGetAllFulfillmentCentersQuery } from "~/redux/warehouse/fulfillmentCenter.hooks";

import { warehouseApi } from "~/redux/warehouse/warehouseApi";

const mapStateToProps = (state: StoreState) => ({
  fulfillmentCenterId: state.workstations.siteFulfillmentCenterId
    ? state.workstations.siteFulfillmentCenterId
    : state.store.usersFulfillmentCenter
      ? state.store.usersFulfillmentCenter.fulfillmentCenterId
      : null
});

const connector = connect(mapStateToProps, {
  setSiteFcId,
  setThisWorkstation,
  updateUsersFulfillmentCenter
});
type FulfillmentCenterSelectorInheritedProps = {
  viewTitle?: string;
};
type PropsFromRedux = ConnectedProps<typeof connector>;
type FulfillmentCenterSelectorProps = PropsFromRedux &
  FulfillmentCenterSelectorInheritedProps;

function FulfillmentCenterSelector(props: FulfillmentCenterSelectorProps) {
  const {
    fulfillmentCenterId,
    setSiteFcId,
    setThisWorkstation,
    updateUsersFulfillmentCenter
  } = props;
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();
  const {
    isFetching: fulfillmentCentersFetching,
    data: fulfillmentCenters,
    error: fulfillmentCentersFetchError,
    refetch: refetchFulfillmentCenters
  } = useGetAllFulfillmentCentersQuery();
  const dispatch = useAppDispatch();

  function handleSelectionSelected(fcId: string): void {
    try {
      // redux state, probably belongs in reducer?
      setSiteFcId(fcId);
      dispatch(clearAndonWorkstationsAndGrids());
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      setThisWorkstation(null, true);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      updateUsersFulfillmentCenter(fcId);

      dispatch(warehouseApi.util.invalidateTags(["autostore grid"]));
      // toast
      const selectedFc = fulfillmentCenters
        ? fulfillmentCenters.find((fc) => fc.fulfillmentCenterId === fcId)
        : null;

      const successMessage: string = selectedFc
        ? `Your Device's Fulfillment Center has been updated to ${selectedFc.name}`
        : // odd case where we couldn't actually find the fulfillment center
          "Your Device's Fulfillment Center has been updated";

      successToast("Fulfillment Center Updated", {
        description: successMessage
      });
    } catch (err: unknown) {
      handleWarehouseError(err, (message) => errorToast(message));
    }
  }

  if (fulfillmentCentersFetching) {
    return (
      <Grid item xs={6}>
        <Skeleton variant="rectangular" />
      </Grid>
    );
  }

  if (fulfillmentCentersFetchError) {
    return (
      <Grid item xs={6}>
        <Alert
          variant="outlined"
          severity="error"
          action={
            <ASButton onClick={refetchFulfillmentCenters}>Retry</ASButton>
          }
        >
          {getMessageFromRtkError(fulfillmentCentersFetchError)}
        </Alert>
      </Grid>
    );
  }

  return (
    <Grid item xs={10}>
      <InputLabel id="fulfillment-center-label" shrink>
        {`${t("site fulfillment center")}`}
      </InputLabel>

      {fulfillmentCenters?.length && (
        <Select
          id="fulfillment-center"
          fullWidth
          value={fulfillmentCenterId ? fulfillmentCenterId : ""}
          labelId="fulfillment-center-label"
        >
          {fulfillmentCenters.map((fc) => (
            <MenuItem
              key={fc.fulfillmentCenterId}
              value={fc.fulfillmentCenterId}
              onClick={() => handleSelectionSelected(fc.fulfillmentCenterId)}
            >
              {fc.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </Grid>
  );
}

export default connector(FulfillmentCenterSelector);
