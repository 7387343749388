import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { BatchSummaryDto } from "~/types/api";

type AdminBatchesState = {
  page: number;
  searchText: string;
  selectedBatch: BatchSummaryDto | null;
  toteSearch: string;
};

const initialState: AdminBatchesState = {
  page: 1,
  searchText: "",
  selectedBatch: null,
  toteSearch: ""
};

export const adminBatchesSlice = createSlice({
  name: "adminBatches",
  initialState,
  reducers: {
    resetSelectedBatch(state) {
      state.selectedBatch = null;
    },
    selectBatch(state, { payload }: PayloadAction<BatchSummaryDto>) {
      state.selectedBatch = payload;
    },
    setPage(state, { payload }: PayloadAction<number>) {
      state.page = payload;
    },
    setSearchText(state, { payload }: PayloadAction<string>) {
      state.searchText = payload;
    },
    setToteSearch(state, { payload }: PayloadAction<string>) {
      state.toteSearch = payload;
    }
  }
});
export const {
  resetSelectedBatch,
  selectBatch,
  setPage,
  setSearchText,
  setToteSearch
} = adminBatchesSlice.actions;
