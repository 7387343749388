import { createSelector } from "@reduxjs/toolkit";

import { AppSelector } from "~/app/store";
import { LabelInfo } from "~/components/PrintableToteLabels";
import { formatDateTime } from "~/lib/dateHelpers";
import { StoreState } from "~/redux/reducers";

import { selectClientConfig } from "./siteSelectors";

import { selectUsersFulfillmentCenter } from "./storeSelectors";

export const selectBatch = (state: StoreState) => state.batch.batchViewModel;
export const selectBatchTotes = (state: StoreState) => state.batch.batchTotes;

export const selectToteLabels: AppSelector<null | LabelInfo[]> = createSelector(
  [selectBatch, selectBatchTotes, selectUsersFulfillmentCenter],
  (batch, batchTotes, usersFC) =>
    batch &&
    batchTotes
      .slice()
      .sort((a, b) => (a.totePosition || 0) - (b.totePosition || 0))
      .map((tote) => {
        const totePick = batch.picks.find(
          (pick) => pick.assignedToteId === tote.toteId
        );
        const putwallPosition = `${tote.putWallIdentifier}${tote.putWallLaneIdentifier}`;
        return {
          toteId: tote.toteId,
          externalOrderId: tote.externalOrderId || "",
          firstName: totePick?.pickDto.firstName || "",
          lastName: totePick?.pickDto.lastName || "",
          orderWindow: formatDateTime(tote.pickingEndTime, usersFC?.timeZone),
          position: tote.totePosition || 0,
          toteNumber: tote.toteNumber,
          zone: tote.temperatureZone,
          batchName: batch.batchName,
          putwallPosition,
          orderType: tote.orderType
        };
      })
);

export const selectCanUnassignCart: AppSelector<boolean> = createSelector(
  [selectBatch, selectClientConfig],
  (batch, { cartAssignmentEnabled }) => {
    const { cartNumber, status } = batch || {};

    // only allow Unassign Cart when batch is Scheduled or Cart Ready and cartNumber is assigned
    return (
      !!batch &&
      cartAssignmentEnabled &&
      !!cartNumber &&
      ["Scheduled", "Cart Ready"].includes(status || "")
    );
  }
);

export const selectBatchWithCartNumber = (state: StoreState) =>
  state.batch.batchWithCartNumber;
