import { Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { ProgressButton } from "~/components/ProgressButton";

import { getVariantDisplayNameByDtoFe } from "~/lib/helpers";

import {
  clearSlotBin,
  getSlotBinById,
  getSlottingInfo
} from "~/redux/actions/inventory";
import { setUserMessage } from "~/redux/actions/site";

import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";

import { useGetBinQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import { VariantFrontendDto } from "~/types/api";

import { CreateInventoryButton } from "./CreateInventoryButton";

import { NewBinSlotModal } from "./NewBinSlotModal";
import { BinSearchData } from "./getBinIds";
import { useViewType } from "./useViewType";

interface InventorySummaryHeader {
  selectedVariant: VariantFrontendDto | null;
  isAdjustingBins: boolean;
  handleCreateBinInventory: () => void;
  handleFetchAllInventory: () => Promise<void>;
  isAutostoreInventoryView: boolean;
  binNumberParam?: string;
  variantIdParam?: string;
  searchedBinRecord: string[] | null;
  binCreateInventoryDisabled: boolean;
  selectedAutostoreGridId?: Guid;
}

export const InventorySummaryHeader: React.FC<InventorySummaryHeader> = ({
  selectedVariant,
  isAdjustingBins,
  handleCreateBinInventory,
  handleFetchAllInventory,
  isAutostoreInventoryView,
  binNumberParam,
  variantIdParam,
  searchedBinRecord,
  binCreateInventoryDisabled,
  selectedAutostoreGridId
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isBinView, isProductView } = useViewType();
  const slotBin = useAppSelector((state) => state.inventory.slotBin);
  const usersFulfillmentCenter = useAppSelector(
    (state) => state.store.usersFulfillmentCenter
  );
  const slottingInfo = useAppSelector((state) => state.inventory.slottingInfo);
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const firstSlottingInfo = !!slottingInfo.length && slottingInfo[0];

  const [newBinSlotModalOpen, setNewBinSlotModalOpen] = useState(false);
  const [selectedBinSlot, setSelectedBinSlot] = useState<BinSearchData | null>(
    null
  );

  const [isBinOutsideOfGrid, setIsBinOutsideOfGrid] = useState(false);

  const { data: binLocationInformation } = useGetBinQuery(
    selectedAutostoreGridId && binNumberParam
      ? {
          autostoreGridId: selectedAutostoreGridId,
          binNumber: Number(binNumberParam)
        }
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
      skip:
        binNumberParam === undefined || selectedAutostoreGridId === undefined
    }
  );

  useEffect(() => {
    if (binLocationInformation?.binState) {
      const { binMode, contentCode } = binLocationInformation.binState;
      const flags = binLocationInformation?.flags;

      if (binMode === "X") {
        setIsBinOutsideOfGrid(true);
        dispatch(
          setUserMessage({
            title: t("bin is outside the grid"),
            severity: "warning",
            id: "bin-is-outside-of-grid"
          })
        );
      } else {
        setIsBinOutsideOfGrid(false);
      }

      if (contentCode === 0 || flags?.includes("bin locked")) {
        dispatch(
          setUserMessage({
            title: t("bin locked"),
            severity: "warning",
            id: "bin-is-locked"
          })
        );
      }
    }
  }, [isBinOutsideOfGrid, dispatch, binLocationInformation, t]);

  useEffect(() => {
    if (variantIdParam) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      dispatch(getSlottingInfo({ variantId: variantIdParam }));
    } else if (
      binNumberParam &&
      isAutostoreInventoryView &&
      searchedBinRecord
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      dispatch(
        getSlottingInfo({
          binId: searchedBinRecord[0]
        })
      );
    }
  }, [
    variantIdParam,
    binNumberParam,
    dispatch,
    isAutostoreInventoryView,
    searchedBinRecord
  ]);

  // get bin data for product slot to display bin location
  useEffect(() => {
    // get bin for most recent/last slot of slotting info
    if (isProductView && firstSlottingInfo) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      dispatch(getSlotBinById(firstSlottingInfo.binId));
    } else {
      dispatch(clearSlotBin());
    }
  }, [dispatch, firstSlottingInfo, isProductView]);

  if (isProductView && selectedVariant && !isAdjustingBins) {
    return (
      <>
        <Typography variant="h6" component="div">
          {getVariantDisplayNameByDtoFe(
            selectedVariant,
            usersFulfillmentCenter
          )}
        </Typography>
        <CreateInventoryButton
          handleFetchAllInventory={handleFetchAllInventory}
        />
        <NewBinSlotModal
          open={newBinSlotModalOpen}
          onClose={() => {
            setNewBinSlotModalOpen(false);
          }}
          slotBin={slotBin}
          firstSlottingInfo={firstSlottingInfo || null}
          variantIdParam={variantIdParam}
          selectedBinSlot={selectedBinSlot}
          setSelectedBinSlot={setSelectedBinSlot}
        />
      </>
    );
  } else if (isBinView && !!searchedBinRecord && binNumberParam) {
    return (
      <>
        <Typography variant="h6" component="div" marginRight={1}>
          {`Bin ${binNumberParam}`}
        </Typography>
        <ProgressButton
          variant="contained"
          color="primary"
          size="large"
          disabled={
            binCreateInventoryDisabled ||
            !siteWorkstation ||
            isBinOutsideOfGrid ||
            binLocationInformation?.flags.includes("bin locked")
          }
          onClick={() => {
            handleCreateBinInventory();
          }}
        >
          {t("create inventory")}
        </ProgressButton>
      </>
    );
  }
  return null;
};
