import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";

import {
  closeRestockPickModal,
  confirmRestock
} from "./restockPickModal.slice";

const activeButtonCss = {
  backgroundColor: "primary.main",
  color: "#fff",
  "&:hover": {
    backgroundColor: "primary.main"
  },
  height: "100%",
  width: "100%"
};
const disabledButtonCss = {
  backgroundColor: "gray.light",
  "&:hover": {
    backgroundColor: "gray.light"
  },
  color: "darkGray.dark",
  height: "100%",
  width: "100%"
};

type Props = {
  handleContinuePickingButtonClick: () => Promise<void>;
};

function RestockPickModal(props: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isConfirmRestockEnabled = useAppSelector(
    (state) => state.restockPickModal.restockComplete
  );
  const isOpen = useAppSelector((state) => state.restockPickModal.isOpen);

  const handleConfirmRestockButtonClick = () => {
    dispatch(confirmRestock());
  };

  return (
    <Dialog open={isOpen} maxWidth="md">
      <DialogTitle
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
        variant="h5"
      >
        <Grid container justifyContent="space-evenly" alignItems="center">
          <Grid item>
            <WarningAmberIcon
              fontSize="large"
              sx={{
                color: "warning.main"
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h5">
              {t("order or product cancellation")}
            </Typography>
          </Grid>
          <Grid item>
            <WarningAmberIcon
              fontSize="large"
              sx={{
                color: "warning.main"
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ marginTop: "2vH" }}>
        <Grid container direction="column">
          <Grid item>
            <Typography
              variant="h6"
              sx={{ marginBottom: "30px", maxWidth: "400px" }}
              fontWeight={700}
            >
              {t("this order or product has been canceled")}.
              <br />
              {t("please restock the inventory")}.
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              height: "60px",
              marginBottom: "10px"
            }}
          >
            <Button
              variant="contained"
              startIcon={
                <SaveAltIcon
                  sx={{
                    color: isConfirmRestockEnabled ? "darkGray.dark" : "#fff"
                  }}
                />
              }
              sx={isConfirmRestockEnabled ? disabledButtonCss : activeButtonCss}
              disabled={isConfirmRestockEnabled}
              onClick={handleConfirmRestockButtonClick}
            >
              <Typography
                style={{
                  color: isConfirmRestockEnabled ? "darkGray.dark" : "#fff"
                }}
              >
                {t("confirm restock")}
              </Typography>
            </Button>
          </Grid>
          <Grid item sx={{ height: "60px" }}>
            <Button
              variant="contained"
              startIcon={
                <PlayCircleOutlineIcon
                  sx={{
                    color: !isConfirmRestockEnabled ? "darkGray.dark" : "#fff"
                  }}
                />
              }
              sx={
                !isConfirmRestockEnabled ? disabledButtonCss : activeButtonCss
              }
              disabled={!isConfirmRestockEnabled}
              onClick={async () => {
                dispatch(closeRestockPickModal());
                await props.handleContinuePickingButtonClick();
              }}
            >
              <Typography
                sx={{
                  color: !isConfirmRestockEnabled ? "darkGray.dark" : "#fff"
                }}
              >
                {t("continue picking")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default RestockPickModal;
