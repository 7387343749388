import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";
import envConstants from "~/config/envConstants";
import { fetchPicks, reprintLabel, setPickedTote } from "~/redux/actions";
import { fetchToteById } from "~/redux/actions/home";
import { selectAutomatedOperationsEnabled } from "~/redux/selectors/siteSelectors";
import { selectSitePortId } from "~/redux/selectors/workstationsSelectors";
import { ToteDto } from "~/types/api";

export default function usePickingFuncionalities() {
  const dispatch = useAppDispatch();
  const sitePortId = useAppSelector(selectSitePortId);
  const automatedOperationsEnabled = useAppSelector(
    selectAutomatedOperationsEnabled
  );

  const handleSlamProcess = useCallback(
    async (
      toteId: Guid,
      orderId: Guid,
      toteDtoParam: ToteDto | null,
      callPrintLabel?: boolean,
      portId?: number
    ) => {
      if (portId && portId !== sitePortId) return;
      const toteDto = toteDtoParam || (await dispatch(fetchToteById(toteId)));
      if (toteDto && toteDto.status.toLowerCase() !== "picked") return;
      const allOrderPicks = await dispatch(fetchPicks(orderId));
      if (allOrderPicks) {
        const allTotePicks = allOrderPicks.filter(
          (pick) => pick.assignedToteId === toteId || pick.putToteId === toteId
        );

        const isEveryPickOOS = allTotePicks.every(
          (pick) =>
            (pick.status.toLowerCase() === "completed" &&
              pick.exception?.type.toLowerCase() === "outofstock") ||
            pick.status.toLowerCase() === "canceled"
        );

        const countNonScheduledPicks = allTotePicks.filter(
          (pick) =>
            pick.status.toLowerCase() === "canceled" ||
            pick.status.toLowerCase() === "completed"
        ).length;

        const isAtLeastOnePickScheduled = allTotePicks.some(
          (pick) => pick.status.toLowerCase() === "scheduled"
        );

        if (
          !isEveryPickOOS &&
          countNonScheduledPicks === allTotePicks.length &&
          !isAtLeastOnePickScheduled
        ) {
          // If the reprint label flow is triggered after the tote is already slammed
          // The print-label API should be called
          if (callPrintLabel) void dispatch(reprintLabel(toteId));

          // Update state that should dictate whether or not the scan modal label is displayed
          dispatch(setPickedTote(toteDto));

          if (
            envConstants.ENABLE_AUTOMATED_OPERATIONS === "true" &&
            automatedOperationsEnabled
          ) {
            setTimeout(() => {
              // eslint-disable-next-line no-console
              console.log(
                `Simulating scan of tote label ${toteDto?.externalToteId || ""}`
              );
              window.simScan(toteDto?.externalToteId || "");
            }, 200);
          }
        }
      }
    },
    [automatedOperationsEnabled, dispatch, sitePortId]
  );

  return {
    handleSlamProcess
  };
}
