import { createSelector } from "@reduxjs/toolkit";

import {
  selectedRows,
  selectedSummaries
} from "~/features/inventoryOld/inventoryOld.slice";
import { StoreState } from "~/redux/reducers";

export const selectInventoryAtPort = (state: StoreState) =>
  state.inventory.inventory;

export const selectInventorySummaryToDisplay = createSelector(
  [selectedSummaries, selectedRows],
  (selectedSummaries, selectedRows) => {
    return (
      selectedSummaries.find(
        (summary) =>
          summary?.inventoryId === selectedRows[selectedRows.length - 1]
      ) || null
    );
  }
);

export const selectSelectedInventoryAtPort = createSelector(
  [selectInventoryAtPort, selectedSummaries],
  (inventoryAtPort, selectedSummaries) => {
    return selectedSummaries.find(
      (summary) =>
        summary?.autostoreBinNumber ===
        inventoryAtPort[0]?.bin?.autostoreBin?.autostoreBinId
    );
  }
);
