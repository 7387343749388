import { TableRow, styled } from "@mui/material";

const ASTableV2RowStyled = styled(TableRow, {
  shouldForwardProp: (propName) =>
    !["expanded", "selected", "highlighted"].includes(propName as string)
})<{
  selected?: boolean;
  highlighted?: boolean;
  expanded?: boolean;
}>(
  ({
    theme: { palette, spacing },
    selected,
    highlighted,
    expanded,
    onClick
  }) => ({
    display: "contents",
    "& > td:first-of-type": {
      borderTopLeftRadius: spacing(1),
      borderBottomLeftRadius: expanded ? spacing(0) : spacing(1),
      borderLeft: "solid 3px",
      borderColor: selected ? "#2260D3" : "transparent"
    },
    "& > td:last-of-type": {
      borderTopRightRadius: spacing(1),
      borderBottomRightRadius: expanded ? spacing(0) : spacing(1),
      borderRight: "solid 3px",
      borderColor: selected ? "#2260D3" : "transparent"
    },
    "& > td": {
      cursor: onClick ? "pointer" : "default",
      border: 0,
      borderTop: "solid 3px",
      borderBottom: "solid 3px",
      borderColor: selected ? "#2260D3" : "transparent",
      marginTop: spacing(1),
      padding: spacing(2),
      backgroundColor: highlighted
        ? palette.primary.light
        : palette.background.default
    }
  })
);

export const ASTableV2Row = (
  props: Parameters<typeof ASTableV2RowStyled>[0]
) => {
  return <ASTableV2RowStyled {...props} aria-selected={props.selected} />;
};
