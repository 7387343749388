import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

interface CancelButtonProps {
  onClick?: () => void;
  color: string;
  backgroundColor: string;
}

export function CancelButton(props: CancelButtonProps) {
  const { t } = useTranslation();
  const { onClick, color, backgroundColor } = props;

  return (
    <Button
      style={{
        color,
        backgroundColor,
        height: "100%",
        width: "80%"
      }}
      onClick={onClick ? (): void => onClick() : () => null}
    >
      <Grid item xs={12}>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "5px"
          }}
        >
          <Box>
            <CancelPresentationIcon />
          </Box>
          <Box
            style={{
              fontSize: "20px",
              marginTop: "-5px",
              marginLeft: "5px",
              textTransform: "capitalize",
              color
            }}
          >
            {t("cancel")}
          </Box>
        </Box>
      </Grid>
    </Button>
  );
}
