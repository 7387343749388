import { Box, ButtonBase, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import { useToast } from "~/hooks/useToast";
import { simulatePressPTLButton } from "~/redux/actions/ptl";
import { StoreState } from "~/redux/reducers";

const mapStateToProps =
  (_: StoreState, ownProps: { position: number }) =>
  (storeState: StoreState) => {
    const totePlacement =
      storeState.workstations.siteWorkstation?.totePlacements.find(
        (tp) => tp.totePosition === ownProps.position
      );
    const state = totePlacement?.putToLightModuleId
      ? storeState.ptl.ptlSimulationStates[totePlacement.putToLightModuleId]
      : null;
    return { state, totePlacement };
  };
const connector = connect(mapStateToProps, {
  simulatePressPTLButton
});
type PropsFromRedux = ConnectedProps<typeof connector>;
type PTLProps = PropsFromRedux;

export function PTL(props: PTLProps) {
  const { state, totePlacement } = props;
  const [text, setText] = useState(state?.text);
  const { errorToast } = useToast();

  useEffect(() => {
    setText(props.state?.text);
  }, [props.state]);

  const increment = (): void => {
    if (!state?.adjustable) return;
    setText((quantity) => {
      const quantityAsNumber = parseInt(quantity || "", 10);
      if (Number.isNaN(quantityAsNumber)) return quantity;
      const maxQuantityToPick = parseInt(state?.text || "", 10);
      const quantityToSet = quantityAsNumber + 1;
      return `${Math.min(quantityToSet, maxQuantityToPick)}`;
    });
  };

  const decrement = (): void => {
    if (!state?.adjustable) return;
    setText((quantity) => {
      const quantityAsNumber = parseInt(quantity || "", 10);
      if (Number.isNaN(quantityAsNumber)) return quantity;
      const quantityToSet = quantityAsNumber - 1;
      return `${Math.max(quantityToSet, 0)}`;
    });
  };

  const buttonPress = () => {
    if (!totePlacement || !state || !text) {
      errorToast("Button was not active and thus cannot be pressed");
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    props.simulatePressPTLButton(totePlacement.putToLightModuleId, {
      ...state,
      text
    });
  };

  return (
    <>
      <Box
        sx={{
          marginTop: "10px",
          height: "80px",
          backgroundColor: "black",
          borderRadius: "60px",
          display: "flex",
          gap: "5px",
          justifyContent: "center",
          alignItems: "center",
          userSelect: "none"
        }}
      >
        {state?.adjustable && (
          <Stack direction="column">
            <ButtonBase
              onClick={increment}
              sx={{
                backgroundColor: "success.main",
                color: "#fff",
                borderRadius: "1rem 1rem 0 0",
                height: 1,
                p: 1,
                fontFamily: "Monospace",
                "&:hover": {
                  backgroundColor: "success.light"
                }
              }}
            >
              +
            </ButtonBase>
            <ButtonBase
              onClick={decrement}
              sx={{
                backgroundColor: "error.main",
                color: "#fff",
                borderRadius: "0 0 1rem 1rem",
                height: 1,
                p: 1,
                fontFamily: "Monospace",
                "&:hover": {
                  backgroundColor: "error.light"
                }
              }}
            >
              -
            </ButtonBase>
          </Stack>
        )}
        <Box
          sx={{
            height: "50px",
            width: "100px",
            backgroundColor: "#300702",
            fontSize: "40px",
            color: "#f46a6f",
            textShadow: "0 0 7px #f46a6f",
            lineHeight: "50px",
            textAlign: "right",
            paddingRight: "5px"
          }}
        >
          {text}
        </Box>
        <ButtonBase
          aria-label="simulate"
          sx={{
            height: "50px",
            width: "60px",
            backgroundColor: "#2db53f",
            borderRadius: "75px",
            backgroundImage: state?.text
              ? "radial-gradient(circle, rgba(3,255,0,1) 49%, rgba(9,121,13,1) 96%)"
              : "default",
            cursor: state?.text ? "pointer" : "default"
          }}
          onClick={buttonPress}
        />
      </Box>
    </>
  );
}

export default connector(PTL);
