import PrintIcon from "@locaisolutions/icons/dist/icons20px/Print20Px";
import ReloadIcon from "@locaisolutions/icons/dist/icons20px/Reload20Px";
import ErrorIcon from "@locaisolutions/icons/dist/icons24px/Error24Px";
import {
  Button,
  CircularProgress,
  Box,
  TextField,
  Typography,
  useTheme
} from "@mui/material";

import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { ErrorSuccessSnackbar } from "~/components/ErrorSuccessSnackbar";
import {
  getDefaultPrinter,
  getPrinterStatus,
  clearErrorMessage
} from "~/redux/actions/printer";
import { StoreState } from "~/redux/reducers";

const mapStateToProps = (
  state: StoreState
): {
  readyToPrint: boolean;
  statusLoading: boolean;
  defaultPrinter: Device | null;
  printerError: string | null;
} => ({
  readyToPrint: state.printer.readyToPrint,
  statusLoading: state.printer.statusLoading,
  defaultPrinter: state.printer.defaultPrinter,
  printerError: state.printer.error
});

const connector = connect(mapStateToProps, {
  getDefaultPrinter,
  getPrinterStatus,
  clearErrorMessage
});
type PropsFromRedux = ConnectedProps<typeof connector>;
type PrinterSettingsProps = PropsFromRedux;

function PrinterSettings(props: PrinterSettingsProps) {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const { statusLoading, readyToPrint, defaultPrinter, printerError } = props;

  const initialZpl =
    "^XA^CFA,30^FO20,50^FDZebra Test!^FS^FO20,100^FD100 Main Street^FS^BY2,2,100^FO40,200^BC^FD12345678^FS^XZ";

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [zplValue, setZplValue] = useState<string>(initialZpl);
  // const [previewUrl, setPreviewUrl] = useState<string>(
  //   `http://api.labelary.com/v1/printers/8dpmm/labels/2.5x5/0/${initialZpl}`
  // );

  // connect to default printer
  // useEffect(() => {
  //   props.getDefaultPrinter();
  // }, []);

  // get printer status
  useEffect(() => {
    if (defaultPrinter) {
      props.getPrinterStatus(defaultPrinter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPrinter]);

  const handlePrintTestLabel = () => {
    if (defaultPrinter) {
      defaultPrinter.send(zplValue);
    }
  };

  const handleZplChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZplValue(event.target.value);
  };

  // const handleGetLabelPreview = () => {
  //   setPreviewUrl(
  //     `http://api.labelary.com/v1/printers/8dpmm/labels/2.5x5/0/${zplValue}`
  //   );
  // };

  return (
    <Box sx={{ paddingBottom: "10px" }}>
      <Grid container spacing={2} direction="column" style={{ margin: 0 }}>
        <Box id="top-row" sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            {!statusLoading && defaultPrinter && (
              <Box id="printer-name">
                <Typography
                  variant="h5"
                  color={readyToPrint ? "primary" : "default"}
                >{`${defaultPrinter.name}`}</Typography>
              </Box>
            )}
            {!statusLoading && !defaultPrinter && (
              <Box
                id="printer-error"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ErrorIcon fill={palette.error.main} />
                <Typography color="error" sx={{ marginLeft: "5px" }}>
                  {t("no printer found")}
                </Typography>
              </Box>
            )}
            {statusLoading && <CircularProgress size={20} />}
          </Box>
          <Button
            sx={{
              backgroundColor: "mediumGray.main",
              color: "black",
              fontWeight: 600,
              margin: "3px",
              marginLeft: "10px",
              padding: "10px",
              textTransform: "none"
            }}
            variant="text"
            startIcon={<ReloadIcon />}
            onClick={() => props.getDefaultPrinter()}
          >
            {t("retry").charAt(0).toUpperCase() +
              t("retry").slice(1).toLowerCase()}
          </Button>
        </Box>
        <Grid item container xs={12}>
          <Grid item container xs={10} direction="column" spacing={2}>
            <Grid item>
              <TextField
                id="standard-multiline-flexible"
                label="Test ZPL"
                variant="outlined"
                multiline
                maxRows={40}
                value={zplValue || ""}
                onChange={handleZplChange}
                fullWidth
              />
            </Grid>
            <Grid item container spacing={2}>
              {/* <Grid item>
                <Button
                  onClick={handleGetLabelPreview}
                  disabled={!defaultPrinter}
                  variant="contained"
                  color="primary"
                >
                  Preview Label
                </Button>
              </Grid> */}
              <Grid item>
                <Button
                  onClick={handlePrintTestLabel}
                  disabled={!defaultPrinter}
                  variant="text"
                  sx={{
                    color: defaultPrinter
                      ? palette.secondary.contrastText
                      : palette.disabled.dark,
                    backgroundColor: palette.disabled.main,
                    textTransform: "none",
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontWeight: 600
                  }}
                  startIcon={
                    <PrintIcon
                      aria-label="print-icon"
                      style={{
                        fill: defaultPrinter
                          ? palette.secondary.contrastText
                          : palette.disabled.dark
                      }}
                    />
                  }
                >
                  {t("print test label")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={8}>
            <img
              src={previewUrl}
              alt="barcode preview"
              style={{ border: "1px solid black" }}
            />
          </Grid> */}
        </Grid>
      </Grid>
      <ErrorSuccessSnackbar
        errorMessage={printerError || error}
        clearErrorMessage={() => {
          setError(null);
          props.clearErrorMessage();
        }}
        successMessage={success}
        clearSuccessMessage={() => setSuccess(null)}
      />
    </Box>
  );
}

export default connector(PrinterSettings);
