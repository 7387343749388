import algoliasearch from "algoliasearch";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import NavSearchInput from "~/components/navbar/NavSearchInput";
import { createProductSearchOptions } from "~/config/algoliaConfig";

import { searchAlgoliaProductsIndex } from "~/lib/algoliaProductSearch";
import { getAlgoliaConfig } from "~/lib/helpers";
import { selectUsersClientId } from "~/redux/selectors/authSelectors";

import { setSearch, setSelectedSku } from "./orderCreation.slice";

type BinOrProductResult = {
  type: string;
  variantId?: Guid;
  binId?: Guid;
  sku?: string;
  displayText: string;
};

export function OrderCreationSearch() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const searchPlaceholder = t("search products");

  const clientId = useAppSelector(selectUsersClientId);
  const searchText = useAppSelector((state) => state.orderCreation.search);
  const algolia = (clientId && getAlgoliaConfig(clientId)) || null;
  const client =
    algolia && algoliasearch(algolia.application_id, algolia.api_key);
  const index = algolia && client?.initIndex(algolia.products_index);

  const [autocompleteOptions, updateAutocompleteOptions] = useState<
    BinOrProductResult[]
  >([] as BinOrProductResult[]);

  const autocompleteSearchProducts = async (
    input: string | null
  ): Promise<void> => {
    if (!input || !index) {
      return;
    }
    if (input.length) {
      const hits = await searchAlgoliaProductsIndex(index, input, false);

      const hitsAsAutocompleteRecords = createProductSearchOptions({ hits });
      updateAutocompleteOptions([...hitsAsAutocompleteRecords]);
    }
  };

  return (
    <NavSearchInput<BinOrProductResult>
      setTextInput={(text) => dispatch(setSearch(text))}
      textInput={searchText || ""}
      isAutocomplete
      searchPlaceholder={searchPlaceholder}
      autocompleteSearchCb={(input) => {
        void autocompleteSearchProducts(input);
      }}
      autocompleteOptions={autocompleteOptions}
      autocompleteLabelConstructor={(option: BinOrProductResult): string =>
        option.displayText
      }
      groupBy={(option) => t(option.type)}
      selectCb={(result: BinOrProductResult) => {
        if (result.sku) {
          dispatch(setSelectedSku(result.sku));
        }
      }}
    />
  );
}
