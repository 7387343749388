import { Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { t } from "i18next";

import { useAppSelector } from "~/app/store";
import { maybePluralize } from "~/lib/helpers";
import {
  selectWorkstationAutostoreGridId,
  selectWorkstationId
} from "~/redux/selectors/workstationsSelectors";
import {
  useGetInventoryMovementsStateQuery,
  useGetNumberOfFlaggedBinsQuery,
  usePostStartInventoryMovementsQuery
} from "~/redux/warehouse/autostoreGrid.hooks";

const MovementsHeader = () => {
  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const workstationId = useAppSelector(selectWorkstationId);

  const { data: numberOfFlaggedBins } = useGetNumberOfFlaggedBinsQuery(
    autostoreGridId ? { autostoreGridId } : skipToken
  );
  const { isLoading: isLoadingStartInventoryMovements } =
    usePostStartInventoryMovementsQuery(
      autostoreGridId && workstationId
        ? { autostoreGridId, workstationId }
        : skipToken
    );
  const { data: tasks } = useGetInventoryMovementsStateQuery(
    autostoreGridId && workstationId && !isLoadingStartInventoryMovements
      ? { autostoreGridId, workstationId }
      : skipToken
  );
  const numberUniqueBins = [...new Set(tasks?.map((task) => task.binId))]
    .length;

  return (
    <>
      <Typography sx={{ fontSize: "17px", fontWeight: 500 }}>
        {numberUniqueBins && numberOfFlaggedBins
          ? `${t(
              maybePluralize("bin", numberUniqueBins)
            )} ${numberUniqueBins} ${t("of")} ${numberOfFlaggedBins}`
          : null}
        <Typography component="span" sx={{ fontWeight: "normal" }}>
          {tasks?.length
            ? ` (${tasks.length} ${t(
                maybePluralize("movement", tasks.length)
              )})`
            : null}
        </Typography>
      </Typography>
    </>
  );
};

export default MovementsHeader;
