import {
  Box,
  Button,
  Container,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Tab,
  Tabs
} from "@mui/material";
import { parse } from "qs";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { useFilteredVariantInventorySummaries } from "~/hooks/useFilteredVariantInventorySummaries";
import { useNavbar } from "~/hooks/useNavbar";
import { useToast } from "~/hooks/useToast";
import { useView } from "~/hooks/useView";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectUsersClientId } from "~/redux/selectors/authSelectors";
import {
  selectOrderCreationSelectedAutostoreGridId,
  selectOrderCreationSelectedPage,
  selectOrderCreationOnlyAvailable,
  selectOrderCreationSimplified
} from "~/redux/selectors/orderCreationSelectors";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";
import { useGetAutostoreGridsQuery } from "~/redux/warehouse/autostoreGrid.hooks";

import { InventoryTable } from "./InventoryTable";
import { InductionTab } from "./OrderCreationInductionTab";
import { PickTab } from "./OrderCreationPickTab";
import { OrderCreationSearch } from "./OrderCreationSearch";
import {
  setOnlyAvailable,
  setSelectedAutostoreGridId,
  setSimplified
} from "./orderCreation.slice";

export type OrderCreationProps = { viewTitle?: string };

export function OrderCreation(props: OrderCreationProps) {
  const { viewTitle } = props;

  const { errorToast } = useToast();
  const [tabIndex, setTabIndex] = useState(0);

  const dispatch = useAppDispatch();
  const clientId = useAppSelector(selectUsersClientId);

  if (!clientId) {
    throw new Error("User has no client id configured");
  }

  const onlyAvailable = useAppSelector(selectOrderCreationOnlyAvailable);
  const simplified = useAppSelector(selectOrderCreationSimplified);
  const workstationGridId = useAppSelector(selectWorkstationAutostoreGridId);

  const { data: autostoreGrids } = useGetAutostoreGridsQuery();

  const selectedPage = useAppSelector(selectOrderCreationSelectedPage);
  const selectedAutostoreGridId = useAppSelector(
    selectOrderCreationSelectedAutostoreGridId
  );

  const selectedAutostoreGrid = autostoreGrids?.find(
    (g) => g.autostoreGridId === selectedAutostoreGridId
  );

  const {
    refetch: refetchVariantInventorySummaries,
    error: variantInventorySummariesError
  } = useFilteredVariantInventorySummaries(
    selectedPage,
    selectedAutostoreGridId,
    onlyAvailable
  );

  useEffect(() => {
    dispatch(setSelectedAutostoreGridId(workstationGridId ?? null));
  }, [dispatch, workstationGridId]);

  useEffect(() => {
    if (!variantInventorySummariesError) return;
    errorToast(` ${getMessageFromRtkError(variantInventorySummariesError)}`);
  }, [variantInventorySummariesError, errorToast]);

  const { search } = useLocation();

  const query = parse(search, { ignoreQueryPrefix: true });

  const { autostore } = query;

  const isAutostoreView = !!autostore;

  useView({ permanentSidenav: !isAutostoreView });
  useNavbar({
    centerComponent: useMemo(() => <OrderCreationSearch />, []),
    viewTitle
  });

  return (
    <Container maxWidth="xl" sx={{ padding: 2 }}>
      <Stack gap={3} flexWrap="wrap">
        <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
          <Tabs
            value={tabIndex}
            onChange={(_event, newValue) => setTabIndex(newValue as number)}
            aria-label="creation tabs"
          >
            <Tab label="Create Order" />
            <Tab label="Create Putaway" />
          </Tabs>
          <Stack direction="row" gap={3} flexWrap="wrap" alignItems="center">
            <InputLabel htmlFor="simplified">Simplified</InputLabel>
            <Switch
              sx={{ marginLeft: -3 }}
              id={"simplified"}
              name={"simplified"}
              checked={simplified}
              onChange={() => dispatch(setSimplified(!simplified))}
            />
            <InputLabel htmlFor="onlyAvailable">Only Available</InputLabel>
            <Switch
              sx={{ marginLeft: -3 }}
              id="onlyAvailable"
              name="onlyAvailable"
              checked={onlyAvailable}
              onChange={() => dispatch(setOnlyAvailable(!onlyAvailable))}
            />

            <Button
              variant="outlined"
              onClick={refetchVariantInventorySummaries}
              color="primary"
            >
              Fetch Inventory
            </Button>

            <Select
              id="selected-grid-for-inventory-search"
              labelId="selected-grid-for-inventory-search"
              value={`${
                selectedAutostoreGrid?.autostoreGridName || "All Grids"
              }`}
              onChange={(e: SelectChangeEvent) => {
                const selectedGrid = (autostoreGrids || []).find(
                  (grid) => grid.autostoreGridName === e.target.value
                );

                dispatch(
                  setSelectedAutostoreGridId(
                    selectedGrid?.autostoreGridId || null
                  )
                );
              }}
              variant="standard"
            >
              <MenuItem key="All Grids" value="All Grids">
                {`All Grids`}
              </MenuItem>
              {(autostoreGrids || []).map((grid) => (
                <MenuItem
                  key={grid.autostoreGridName}
                  value={grid.autostoreGridName}
                >
                  {`${grid.autostoreGridName}`}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>
        <Stack direction="row" gap={5}>
          <Box flex="0.8 1" maxHeight="calc(100vh - 200px)">
            <InventoryTable />
          </Box>
          <Box flex="1 0">
            <Box
              role="tabpanel"
              hidden={tabIndex != 0}
              aria-labelledby="tab-pick"
            >
              <PickTab />
            </Box>
            <Box
              role="tabpanel"
              hidden={tabIndex != 1}
              aria-labelledby="tab-induct"
            >
              <InductionTab />
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
}
