import { Container, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { ConfirmationModal } from "~/components/ConfirmationModal";
import envConstants from "~/config/envConstants";
import useFlag from "~/config/flags";
import { useCloseWorkstationWithErrorToast } from "~/hooks/useCloseWorkstationWithErrorToast";
import { useDevCheats } from "~/hooks/useDevCheats";
import { useNavbar } from "~/hooks/useNavbar";
import { useToast } from "~/hooks/useToast";
import { isHomePageTypeIncluded } from "~/lib/helpers";
import {
  fetchPortStatus,
  synchronizeTaskGroups
} from "~/redux/actions/autostore";
import { selectOpeningPort } from "~/redux/selectors/autostoreSelectors";

import { selectHomepages } from "~/redux/selectors/siteSelectors";

import {
  selectWorkstationAutostoreGridId,
  selectSelectedPortId
} from "~/redux/selectors/workstationsSelectors";

import { CycleCountsButton } from "./CycleCountsButton";
import { InductionButton } from "./InductionButton";
import { InventoryButton } from "./InventoryButton";
import { InventoryHoldsButton } from "./InventoryHoldsButton";
import { PickingButton } from "./PickingButton";

type Props = { viewTitle?: string };

export function AutostoreMain(props: Props) {
  const { viewTitle } = props;
  const { successToast } = useToast();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const inventoryV2Enabled = useFlag().enableInventoryV2;

  const closeWorkstation = useCloseWorkstationWithErrorToast();

  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const sitePortId = useAppSelector(selectSelectedPortId);
  const homepages = useAppSelector(selectHomepages);
  const openingPort = useAppSelector(selectOpeningPort);

  const [isNavigateAwayModalOpen, setIsNavigateAwayModalOpen] = useState(false);

  useDevCheats({ showAutostoreStatus: true });

  const { setMenuItems, setWarnings } = useNavbar({
    viewTitle
  });

  useEffect(() => {
    setWarnings([
      ...(!selectedAutostoreGridId ? [t("no autostore grid selected")] : []),
      ...(!sitePortId ? [t("no autostore port selected")] : []),
      ...(openingPort ? ["Opening port..."] : [])
    ]);
  }, [t, setWarnings, selectedAutostoreGridId, sitePortId, openingPort]);

  useEffect(() => {
    const handleCloseWorkstation = async () => {
      const data = await dispatch(fetchPortStatus());
      if (data && (data.selectedBin > 0 || data.selectedTask > 0))
        setIsNavigateAwayModalOpen(true);
      else {
        const error = await closeWorkstation();
        if (!error) successToast(t("workstation closed"));
      }
    };

    /** This is a dev cheats only operation */
    const handleSynchronizeTaskGroupsClicked = async () => {
      await dispatch(synchronizeTaskGroups());
      successToast("Task groups synchronized");
    };

    setMenuItems([
      ...(selectedAutostoreGridId && sitePortId
        ? [
            {
              textContent: t("close workstation"),
              actionCb: handleCloseWorkstation
            }
          ]
        : []),
      ...(envConstants.DEV_CHEATS_UAT === "true" && selectedAutostoreGridId
        ? [
            {
              textContent: t("sync task groups"),
              actionCb: handleSynchronizeTaskGroupsClicked
            }
          ]
        : [])
    ]);
  }, [
    t,
    setMenuItems,
    dispatch,
    successToast,
    closeWorkstation,
    selectedAutostoreGridId,
    sitePortId
  ]);

  return (
    <Container
      sx={{
        mt: 20,
        mb: 4
      }}
      id="container-id"
      maxWidth="lg"
    >
      <Box
        display="flex"
        justifyContent="space-evenly"
        flexDirection={{ xs: "column", md: "row" }}
        gap={2}
      >
        <Box flex="1 1 0px">
          <PickingButton />
        </Box>
        <Box flex="1 1 0px">
          {isHomePageTypeIncluded(homepages, "induction") && (
            <InductionButton route="/autostore-putaway" />
          )}
          {isHomePageTypeIncluded(homepages, "induction v2") && (
            <InductionButton route="/autostore-putaway-v2" />
          )}
        </Box>
        <Stack direction="column" gap={2} flex="1 1 0px">
          {isHomePageTypeIncluded(homepages, "inventory") && (
            <InventoryButton
              route={inventoryV2Enabled ? "/inventoryv2" : "/inventory/product"}
            />
          )}
          {isHomePageTypeIncluded(homepages, "inventory v2") && (
            <InventoryButton route="/inventoryv2" />
          )}
          <Stack direction="row" gap={2}>
            {isHomePageTypeIncluded(homepages, "cycle counts") && (
              <CycleCountsButton route="/autostore-cyclecount" />
            )}
            {isHomePageTypeIncluded(homepages, "cycle counts v2") && (
              <CycleCountsButton route="/autostore-cyclecountv2" />
            )}
            <InventoryHoldsButton />
          </Stack>
        </Stack>
      </Box>
      <ConfirmationModal
        isOpen={isNavigateAwayModalOpen}
        confirmCb={async () => {
          await closeWorkstation();
          setIsNavigateAwayModalOpen(false);
          successToast(t("workstation closed"));
        }}
        closeCb={() => {
          setIsNavigateAwayModalOpen(false);
        }}
        modalTitle={t("are you sure you want to close the workstation")}
        modalText=""
      />
    </Container>
  );
}
