import { createSelector } from "@reduxjs/toolkit";

import { LogPublisherGridStateResponseTransformed } from "~/redux/warehouse/autostoreGrid.hooks";
import { BinStateDto, Point2D, PortStateDto } from "~/types/api";

const emptyResponse = { ports: [], bins: [] };
const emptyBinsArray: BinStateDto[] = [];
const emptyPortsArray: PortStateDto[] = [];

export const selectDataByCell = createSelector(
  (cache?: LogPublisherGridStateResponseTransformed) => cache,
  (_res: unknown, position: Point2D | null) => position,
  (
    cache: LogPublisherGridStateResponseTransformed | undefined,
    position: Point2D | null
  ) => {
    if (!position || !cache) return emptyResponse;
    const ports =
      cache.portsByPos[`${position.x}/${position.y}`] || emptyPortsArray;
    const bins =
      cache.binsByPos[`${position.x}/${position.y}`] || emptyBinsArray;
    return { ports, bins };
  }
);

export const selectCells = createSelector(
  (data?: LogPublisherGridStateResponseTransformed) => data,
  (data?: LogPublisherGridStateResponseTransformed) => ({
    columns: data?.columns,
    cells: data?.cells
  })
);
