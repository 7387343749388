import { WorkstationSummaryDto } from "~/types/api";

export function sortWorkstations(stations: WorkstationSummaryDto[]) {
  const workStations = [...stations];
  workStations.sort(
    (a, b) =>
      a.autostoreGridName.localeCompare(b.autostoreGridName) ||
      a.deviceId.localeCompare(b.deviceId)
  );
  return workStations;
}
