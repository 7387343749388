import {
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  DialogActions,
  Button,
  Autocomplete,
  TextField
} from "@mui/material";
import { ChangeEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { useLocation } from "react-router";

import { getUserClientId } from "~/api/usersTypes/auth0Profile";
import { useAppDispatch, useAppSelector } from "~/app/store";
import { useBarcodeScanner } from "~/lib/barCodeScan";
import { isAutostoreView } from "~/lib/helpers";

import {
  createBinSlot,
  getSlottingInfo,
  updateSlottingInfo
} from "~/redux/actions/inventory";
import { BinDto, SlotDto } from "~/types/api";

import { BinSearchData, getBinIds } from "./getBinIds";

type NewBinSlotModalProps = {
  onClose: () => void;
  open: boolean;
  slotBin: BinDto | null;
  firstSlottingInfo: SlotDto | null;
  variantIdParam?: string;
  selectedBinSlot: BinSearchData | null;
  setSelectedBinSlot: (bin: BinSearchData | null) => void;
};

export function NewBinSlotModal(props: NewBinSlotModalProps) {
  const {
    onClose,
    open,
    slotBin,
    firstSlottingInfo,
    variantIdParam,
    selectedBinSlot,
    setSelectedBinSlot
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const locationInfo = useLocation();
  const { search } = locationInfo;

  const clientId = useAppSelector((state) =>
    getUserClientId(state.login.profile)
  );
  const usersFulfillmentCenter = useAppSelector(
    (state) => state.store.usersFulfillmentCenter
  );
  const selectedAutostoreGridId = useAppSelector(
    (state) => state.workstations.siteWorkstation?.autostoreGridId
  );

  const [newBinSlotInputValue, setNewBinSlotInputValue] = useState<
    string | null
  >(null);
  const [newBinSlotAutocomplete, setNewBinSlotAutoComplete] = useState<
    BinSearchData[]
  >([]);

  const searchBinsByText = useCallback(
    (searchText: string): Promise<BinSearchData[]> =>
      getBinIds({
        searchText,
        usersClientId: clientId,
        usersFulfillmentCenter,
        usersAutostoreGridId: selectedAutostoreGridId
      }),
    [selectedAutostoreGridId, usersFulfillmentCenter, clientId]
  );

  useBarcodeScanner<BinSearchData | null>({
    findScanMatch: async (buffer: string) => {
      if (open) {
        const binSearch = await searchBinsByText(buffer.replace("*", ""));
        if (binSearch.length === 1) return binSearch[0];
      }
      return null;
    },
    processScanMatch: (binSearch) => {
      if (binSearch) {
        setSelectedBinSlot(binSearch);
      }
      return null;
    },
    deps: [open]
  });

  const autocompleteBinSearch = async (input: string | null): Promise<void> => {
    if (!input) {
      return;
    }
    if (input.length) {
      const binSearch = await searchBinsByText(input.replace("*", ""));
      if (!isAutostoreView(search)) {
        if (binSearch.length === 1) {
          setNewBinSlotAutoComplete([]);
          setNewBinSlotInputValue(null);
          setSelectedBinSlot(binSearch[0]);
        } else {
          setNewBinSlotAutoComplete(binSearch);
        }
      }
    }
  };

  const confirmBinSlotUpdate = () => {
    if (
      selectedBinSlot &&
      firstSlottingInfo &&
      selectedBinSlot?.warehouse_bin_ids
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      dispatch(
        updateSlottingInfo({
          slotId: firstSlottingInfo.slotId,
          binId: selectedBinSlot.warehouse_bin_ids[0]
        })
      ).then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        dispatch(getSlottingInfo({ variantId: variantIdParam }));
        setSelectedBinSlot(null);
      });
    }
  };

  const confirmCreateBinSlot = () => {
    if (
      selectedBinSlot &&
      variantIdParam &&
      selectedBinSlot?.warehouse_bin_ids
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      dispatch(
        createBinSlot({
          binId: selectedBinSlot.warehouse_bin_ids[0],
          variantId: variantIdParam
        })
      ).then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        dispatch(getSlottingInfo({ variantId: variantIdParam }));
        setSelectedBinSlot(null);
      });
    }
  };

  return (
    <Dialog key="new-bin-dialog" open={open} onClose={onClose}>
      <DialogContent style={{ textAlign: "center" }}>
        <DialogContentText>
          {slotBin ? "Update Slot" : "Assign Slot"}
        </DialogContentText>
        <FormControl style={{ margin: 10 }}>
          <Autocomplete
            id="combo-box-demo"
            options={newBinSlotAutocomplete}
            filterOptions={(ops) => ops}
            getOptionLabel={(option) => String(option.autostore_bin_number)}
            style={{ width: 220 }}
            open={!!newBinSlotAutocomplete.length}
            onChange={(_e, option) => {
              setNewBinSlotInputValue(null);
              setSelectedBinSlot(option);
            }}
            inputValue={
              newBinSlotInputValue ||
              String(selectedBinSlot?.autostore_bin_number) ||
              ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                type="text"
                label={t("scan new bin")}
                onChange={(e: ChangeEvent<{ value: unknown }>) => {
                  setSelectedBinSlot(null);
                  setNewBinSlotInputValue(
                    (e.target.value as string).replace("*", "")
                  );
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                  autocompleteBinSearch(e.target.value as string);
                }}
              />
            )}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            setNewBinSlotAutoComplete([]);
            setNewBinSlotInputValue(null);
            onClose();
          }}
          color="primary"
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={() => {
            if (slotBin && firstSlottingInfo) {
              confirmBinSlotUpdate();
            } else {
              confirmCreateBinSlot();
            }
            setNewBinSlotAutoComplete([]);
            setNewBinSlotInputValue(null);
            onClose();
          }}
          color="primary"
          autoFocus
          disabled={!selectedBinSlot}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
