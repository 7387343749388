import { createSelector } from "@reduxjs/toolkit";

import { getSelectedToteIds } from "~/features/stage/stageTotes.slice";
import { formatDateTime } from "~/lib/dateHelpers";
import {
  combineTotesAndLoosePicks,
  getToteOrLoosePickId,
  getType,
  ToteOrLoosePickType
} from "~/lib/toteOrLoosePick";
import { StoreState } from "~/redux/reducers";

import { selectUsersFulfillmentCenter } from "./storeSelectors";

export type SelectedToteOrLoosePick = {
  toteOrLoosePickId: Guid;
  orderId: Guid;
  putWallId?: string;
  putWallLaneId?: string;
  putWallIdentifier: string;
  putWallLaneIdentifier: string;
  type: ToteOrLoosePickType;
};

export const batchLoosePicks = (state: StoreState) =>
  state.stage.batchLoosePicks;
export const batchTotes = (state: StoreState) => state.stage.batchTotes;

export const batchTotesAndLoosePicks = createSelector(
  [batchLoosePicks, batchTotes],
  (loosePicks, totes) => {
    return combineTotesAndLoosePicks(totes, loosePicks);
  }
);

export const selectedTotesAndLoosePicks = createSelector(
  [batchTotesAndLoosePicks, getSelectedToteIds],
  (totesAndLoosePicks, selectedToteIds) => {
    return totesAndLoosePicks.reduce<SelectedToteOrLoosePick[]>(
      (acc, toteOrLoosePick) => {
        if (selectedToteIds.includes(getToteOrLoosePickId(toteOrLoosePick))) {
          acc.push({
            toteOrLoosePickId: getToteOrLoosePickId(toteOrLoosePick),
            orderId: toteOrLoosePick.orderId,
            putWallId: toteOrLoosePick.putWallId,
            putWallLaneId: toteOrLoosePick.putWallLaneId,
            putWallIdentifier: toteOrLoosePick.putWallIdentifier,
            putWallLaneIdentifier: toteOrLoosePick.putWallLaneIdentifier,
            type: getType(toteOrLoosePick)
          });
        }
        return acc;
      },
      []
    );
  }
);

export const singleSelectedToteOrLoosePick = createSelector(
  [getSelectedToteIds, batchTotesAndLoosePicks],
  (selectedToteIds, totesAndLoosePicks) => {
    const singleSelectedToteId =
      selectedToteIds.length === 1 && selectedToteIds[0];
    return totesAndLoosePicks.find(
      (tote) => getToteOrLoosePickId(tote) === singleSelectedToteId
    );
  }
);

export const getUnstagedTotes = createSelector(
  [batchTotesAndLoosePicks],
  (totesAndLoosePicks) => {
    return totesAndLoosePicks.filter(
      (tote) =>
        tote.status.toLowerCase() === "picked" ||
        tote.status.toLowerCase() === "dropped"
    );
  }
);

export const getToteLabels = createSelector(
  [
    selectUsersFulfillmentCenter,
    (state: StoreState) => state.stage.batchTotes,
    (state: StoreState) => state.stage.batch
  ],
  (fulfillmentCenter, batchTotes, batch) => {
    return batchTotes
      .sort((a, b) => (a.totePosition || 0) - (b.totePosition || 0))
      .map((tote) => {
        const pickForTote = batch?.picks.find(
          (pick) => pick.orderId === tote.orderId
        );
        return {
          toteId: tote.toteId,
          externalOrderId: tote.externalOrderId || "",
          firstName: pickForTote?.firstName || "",
          lastName: pickForTote?.lastName || "",
          orderWindow: formatDateTime(
            tote.pickingEndTime,
            fulfillmentCenter?.timeZone
          ),
          position: tote.totePosition || 0,
          toteNumber: tote.toteNumber,
          zone: tote.temperatureZone,
          batchName: batch?.batchName || "",
          putwallPosition: `${tote.putWallIdentifier}${tote.putWallLaneIdentifier}`,
          orderType: tote.orderType
        };
      });
  }
);
