import { Card } from "@mui/material";
import { CSSProperties } from "react";

type ProductCardContainerProps = {
  children: React.ReactNode;
  style?: CSSProperties;
};

export function ProductCardContainer(props: ProductCardContainerProps) {
  const { children, style } = props;
  return (
    <Card
      sx={{
        maxWidth: 368,
        minWidth: 315,
        overflow: "visible",
        ...style
      }}
      elevation={2}
      data-testid="product-card-container"
    >
      {children}
    </Card>
  );
}
