import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { PutAwayTaskSummaryDto } from "~/types/api";

type Props = {
  receivingContainerCode: PutAwayTaskSummaryDto["receivingContainerCode"];
};

export function NotesRow(props: Props) {
  const { receivingContainerCode } = props;
  const { t } = useTranslation();
  const receivingContainerData: string[] = receivingContainerCode
    ? receivingContainerCode.split("|")
    : [];

  return (
    <Stack gap={0.5}>
      {!!receivingContainerData[0] && (
        <Typography variant="tableBody">
          {t("pallet")}: {receivingContainerData[0]}
        </Typography>
      )}
      {!!receivingContainerData[1] && (
        <Typography variant="tableBody">
          {t("location")}: {receivingContainerData[1]}
        </Typography>
      )}
      {!!receivingContainerData[2] && (
        <Typography variant="tableBody">
          {t("case product")}: {receivingContainerData[2]}
        </Typography>
      )}
      {!!receivingContainerData[3] && (
        <Typography variant="tableBody">
          {t("cart")}: {receivingContainerData[3]}
        </Typography>
      )}
    </Stack>
  );
}

export default NotesRow;
