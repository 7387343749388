import BackHandOutlinedIcon from "@mui/icons-material/BackHandOutlined";
import { ButtonBase } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useAppSelector } from "~/app/store";

import { figureAndonColorFromStatus } from "~/lib/helpers";

import { selectAndonGrids, selectAndonWorkstations } from "./andon.slice";

type AndonBoardElementProps = {
  workstationId: string;
  titleFontSize: number;
  elHeight: number;
  elWidth: number;
};

export function AndonBoardElement(props: AndonBoardElementProps) {
  const { workstationId, titleFontSize, elHeight, elWidth: _elWidth } = props;
  const andonGrids = useAppSelector(selectAndonGrids);
  const andonWorkstations = useAppSelector(selectAndonWorkstations);

  const aws = andonWorkstations[workstationId];

  const { workstation, handRaised } = aws;

  let boxColor = "gray.light";
  boxColor = figureAndonColorFromStatus({
    gridSystemMode:
      andonGrids[aws.workstation.autostoreGridId].systemMode || undefined,
    workstationStatus: aws.status,
    workstationActive: aws.workstation.status === "Active",
    workstationOpen: aws.isOpen,
    handRaised
  });

  return (
    <ButtonBase
      key={workstation.id}
      aria-label={workstation.deviceId}
      sx={{
        backgroundColor: boxColor,
        border: "1px solid black",
        boxSizing: "border-box",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        height: "100%",
        justifyContent: "space-between"
      }}
    >
      {/* hand raised overlay */}
      {aws.handRaised && (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            width: "100%",
            height: "100%",
            zIndex: 10
          }}
        >
          <BackHandOutlinedIcon
            role="img"
            aria-hidden={false}
            aria-label="hand raised"
            sx={{
              width: "70%",
              height: "70%",
              color: "black",
              marginBottom: "5px"
            }}
          />
        </Box>
      )}

      {/* device name */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "5px",
          boxSizing: "border-box",
          width: "100%",
          height: "33%"
        }}
      >
        <Typography
          fontSize={`${titleFontSize}px`}
          sx={{ color: "black", wrap: "nowrap", fontWeight: 600 }}
        >
          {workstation.deviceId}
        </Typography>
      </Box>

      {/* workstation mode */}
      <Box
        sx={{
          height: "33%",
          display: "flex",
          alignItems: "center"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90%",
            padding: `${elHeight * 0.1}px`,
            color: "white",
            boxSizing: "border-box",
            boxShadow: aws.isOpen
              ? "0px 6px 10px rgba(0, 0, 0, 0.5)"
              : undefined
          }}
        >
          <Typography
            fontSize={`${titleFontSize * 0.7}px`}
            sx={{
              textShadow: "2px 4px 8px rgba(0, 0, 0, 0.9)"
            }}
          >
            {aws.isOpen && aws.workstation.mode !== "Undefined"
              ? aws.workstation.mode
              : ""}
          </Typography>
        </Box>
      </Box>

      {/* has bins */}
      <Box
        sx={{
          width: "100%",
          height: "33%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden"
        }}
      >
        {aws.hasBins &&
          aws.hasBins.map((binId) => {
            return (
              <Box
                key={binId}
                sx={{
                  backgroundColor: "gray.main",
                  borderRadius: `${titleFontSize * 0.2}px`,
                  boxSizing: "border-box",
                  marginLeft: `${elHeight / 20}px`,
                  marginRight: `${elHeight / 20}px`,
                  padding: `${elHeight / 20}px`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography
                  fontWeight={600}
                  fontSize={`${titleFontSize * 0.6}px`}
                >
                  {binId}
                </Typography>
              </Box>
            );
          })}
      </Box>
    </ButtonBase>
  );
}

export default AndonBoardElement;
