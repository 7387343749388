import { useMemo } from "react";
import { useLocation } from "react-router-dom";

type ViewType = {
  isBinView: boolean;
  isProductView: boolean;
  isProductsView: boolean;
  isHoldsView: boolean;
  viewType: "bin" | "product" | "products" | "holds" | "";
};

/**
 * Hook that returns the type of inventory view, based on the path of the location.
 */
export function useViewType() {
  const { pathname } = useLocation();

  const view = useMemo(() => {
    let viewType: ViewType["viewType"] = "";
    if (pathname.includes("/bin")) {
      viewType = "bin";
    } else if (pathname.includes("/product/")) {
      viewType = "product";
    } else if (pathname.includes("/product")) {
      viewType = "products";
    } else if (pathname.includes("/holds")) {
      viewType = "holds";
    }
    const isBinView = viewType === "bin";
    const isProductView = viewType === "product";
    const isProductsView = viewType === "products";
    const isHoldsView = viewType === "holds";
    return {
      isBinView,
      isProductView,
      isProductsView,
      isHoldsView,
      viewType
    };
  }, [pathname]);

  return view;
}
