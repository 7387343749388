import { Typography, Box, Chip } from "@mui/material";
import { t } from "i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import {
  ASTableV2,
  ASTableV2Header,
  ASTableV2Row,
  ASTableV2Cell,
  ASTableV2Body
} from "~/components/autostore/table";
import { formatUtcDate } from "~/lib/dateHelpers";
import {
  inventoryDateLabel,
  temperatureZoneColors,
  formatQuantityString,
  checkIsExpiration
} from "~/lib/helpers";
import { setSelectedAutostoreBinId } from "~/redux/actions";
import { TempZone } from "~/redux/actions/batch";
import {
  clearSelectedInventoryId,
  clearSelectedVariant,
  setSelectedInventoryId
} from "~/redux/actions/inventory";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";

import { useInventorySummariesQuery } from "./useInventorySummariesQuery";

const InventoryTableRow = ({ inventoryId }: { inventoryId: Guid }) => {
  const dispatch = useAppDispatch();
  const { inv_inventoryDateLabel } = useAppSelector(selectClientConfig);
  const { data: inventorySummaries } = useInventorySummariesQuery();
  const row = inventorySummaries?.inventory.find(
    (is) => is.inventoryId === inventoryId
  );
  const selectedInventoryId = useAppSelector(
    (state) => state.inventory.selectedInventoryId
  );

  if (!row) return <></>;

  return (
    <ASTableV2Row
      key={row.inventoryId}
      selected={row.inventoryId === selectedInventoryId}
      onClick={() => {
        if (selectedInventoryId && selectedInventoryId === row.inventoryId) {
          dispatch(clearSelectedInventoryId());
          dispatch(setSelectedAutostoreBinId(null));
          dispatch(clearSelectedVariant());
        } else {
          dispatch(setSelectedInventoryId(row.inventoryId));
          if (row.binType === "autostore") {
            dispatch(setSelectedAutostoreBinId(row.autostoreBinNumber || null));
          } else {
            dispatch(setSelectedAutostoreBinId(null));
          }
        }
      }}
    >
      <ASTableV2Cell>
        <Box>
          <Typography variant="tableBody">{row.productName}</Typography>

          <Box sx={{ marginTop: 1 }}>
            {!!row.temperatureZone && (
              <Chip
                label={t(`${row.temperatureZone.toLowerCase()}`)}
                sx={{
                  bgColor:
                    temperatureZoneColors[
                      row.temperatureZone.toLowerCase() as TempZone
                    ],
                  color: "white",
                  marginRight: 1
                }}
              />
            )}
            <Chip
              label={row.binType}
              sx={{
                textTransform: "capitalize"
              }}
            />
          </Box>
        </Box>
      </ASTableV2Cell>
      <ASTableV2Cell>
        <Typography variant="tableBody">{`${row.count?.value || t("n/a")} ${row.count?.units || ""}`}</Typography>
      </ASTableV2Cell>
      <ASTableV2Cell>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {row.holds?.length
            ? row.holds.map((hold) => (
                <Typography key={hold.reasonCode} variant="tableBody">
                  {t(hold.reasonCode)}
                </Typography>
              ))
            : ""}
        </Box>
      </ASTableV2Cell>
      <ASTableV2Cell>
        <Typography variant="tableBody">
          {row.committedCount ? formatQuantityString(row.committedCount) : ""}
        </Typography>
      </ASTableV2Cell>
      <ASTableV2Cell>
        <Typography variant="tableBody">
          {checkIsExpiration(inv_inventoryDateLabel)
            ? formatUtcDate(row.expiration)
            : formatUtcDate(row.manufactureDate)}
        </Typography>
      </ASTableV2Cell>
    </ASTableV2Row>
  );
};

export const InventoryTable = () => {
  const { inv_inventoryDateLabel } = useAppSelector(selectClientConfig);

  const { data: inventorySummaries, isFetching: inventorySummariesLoading } =
    useInventorySummariesQuery();

  return (
    <ASTableV2
      gridTemplateColumns="1fr repeat(4, auto)"
      gridTemplateRows="50px repeat(7, 1fr)"
      isLoading={inventorySummariesLoading}
      isEmpty={
        !!inventorySummaries && inventorySummaries.inventory.length === 0
      }
      isEmptyMessage={t("no inventory found")}
    >
      <ASTableV2Header>
        <ASTableV2Row>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("product")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("quantity")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("hold type")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">{t("committed")}</Typography>
          </ASTableV2Cell>
          <ASTableV2Cell>
            <Typography variant="tableBody">
              {t(inventoryDateLabel(inv_inventoryDateLabel))}
            </Typography>
          </ASTableV2Cell>
        </ASTableV2Row>
      </ASTableV2Header>
      <ASTableV2Body>
        {inventorySummaries?.inventory.map((row) => (
          <InventoryTableRow
            key={row.inventoryId}
            inventoryId={row.inventoryId}
          />
        ))}
      </ASTableV2Body>
    </ASTableV2>
  );
};
