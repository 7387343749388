import { Box, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { t } from "i18next";
import { useSearchParams } from "react-router-dom";

import { useAppSelector } from "~/app/store";

import { BouncingDotsLoader } from "~/components/BouncingDotsLoader";
import { RobotLoader } from "~/components/RobotLoader";
import { AutostoreRobotSvg } from "~/icons/AutostoreRobotSvg";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useEmptyBinReportQuery } from "~/redux/warehouse/autostoreGrid.hooks";

const Loading = (
  <RobotLoader sx={{ placeSelf: "center" }}>
    <AutostoreRobotSvg sx={{ fontSize: "2rem", color: "autostoreRed.main" }} />
    <BouncingDotsLoader sx={{ div: { height: "8px", width: "8px" } }} />
  </RobotLoader>
);

export const BinReport = () => {
  const [queryParams] = useSearchParams();
  const sourceBinConfigurationId = queryParams.get("sourceBinConfigurationId");
  const targetBinConfigurationId = queryParams.get("targetBinConfigurationId");

  const workstation = useAppSelector(selectThisWorkstation);

  const { data: emptyBinReport, isLoading: emptyBinReportLoading } =
    useEmptyBinReportQuery(workstation?.autostoreGridId ?? skipToken);

  const sourceBinConfigurationReport = emptyBinReport?.find(
    (r) => r.binConfigurationId === sourceBinConfigurationId
  );
  const targetBinConfigurationReport = emptyBinReport?.find(
    (r) => r.binConfigurationId === targetBinConfigurationId
  );

  return (
    <>
      {emptyBinReportLoading ? (
        Loading
      ) : (
        <Box
          sx={{
            placeSelf: "center",
            textAlign: "right",
            color: "darkGray.main",
            mt: "-48px"
          }}
        >
          <Typography variant="body1">
            {t(
              `autostoreBin.empty ${sourceBinConfigurationReport?.numberOfCompartments} compartments`
            )}
          </Typography>
          <Typography variant="stat">
            {sourceBinConfigurationReport?.binsWithNoInventory}
          </Typography>
        </Box>
      )}
      <Box />
      {emptyBinReportLoading ? (
        Loading
      ) : (
        <Box
          sx={{
            placeSelf: "center",
            textAlign: "right",
            color: "darkGray.main"
          }}
        >
          <Typography variant="body1">
            {t(
              `autostoreBin.empty ${targetBinConfigurationReport?.numberOfCompartments} compartments`
            )}
          </Typography>
          <Typography variant="stat">
            {targetBinConfigurationReport?.binsWithNoInventory}
          </Typography>
        </Box>
      )}
      <Box />
    </>
  );
};
