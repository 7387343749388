import { Point2D, PortSide, WorkstationDto } from "~/types/api";

import { pointEquals } from "./pointEquals";

export const getPortSide = (
  workstation: WorkstationDto,
  binCoordinate: Point2D
): PortSide => {
  const port = workstation.ports.find((p) =>
    p.cells.find(pointEquals(binCoordinate))
  );

  if (!port || port.cells.length < 2) return "Unknown";

  const [minX, maxX, minY, maxY] = port.cells.reduce<number[]>((a, c) => {
    let [minX, maxX, minY, maxY] = a;
    if (!(c.x >= minX)) minX = c.x;
    if (!(c.x <= maxX)) maxX = c.x;
    if (!(c.y >= minY)) minY = c.y;
    if (!(c.y <= maxY)) maxY = c.y;
    return [minX, maxX, minY, maxY];
  }, []);

  switch (workstation.orientation) {
    case "North":
      if (minY < maxY) {
        if (maxY === binCoordinate.y) return "Left";
        if (minY === binCoordinate.y) return "Right";
      }
      break;

    case "South":
      if (minY < maxY) {
        if (maxY === binCoordinate.y) return "Right";
        if (minY === binCoordinate.y) return "Left";
      }
      break;

    case "East":
      if (minX < maxX) {
        if (maxX === binCoordinate.x) return "Left";
        if (minX === binCoordinate.x) return "Right";
      }
      break;

    case "West":
      if (minX < maxX) {
        if (maxX === binCoordinate.x) return "Right";
        if (minX === binCoordinate.x) return "Left";
      }
      break;
  }

  return "Unknown";
};
