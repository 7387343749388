import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function SmallNumberStat(args: {
  label?: string;
  number?: number;
  labelColor?: string;
  numberColor?: string;
  backroundColor?: string;
}) {
  const { label, number, labelColor, numberColor, backroundColor } = args;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        margin: 10,
        backgroundColor: backroundColor || "white"
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Typography
          data-testid="dialog-content-text"
          style={{
            color: numberColor || "black",
            fontSize: 40,
            fontWeight: 600
          }}
        >
          {number?.toString()}
        </Typography>
      </Box>
      <Box
        style={{
          width: 150,
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Typography
          data-testid="dialog-content-text"
          style={{
            color: labelColor || "black",
            fontWeight: 400,
            fontSize: "1.25em",
            textTransform: "capitalize"
          }}
        >
          {label || ""}
        </Typography>
      </Box>
    </Box>
  );
}

export default SmallNumberStat;
