import { Button, ButtonGroup, Typography } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { UnselectButton } from "~/components/navbar/Navbar";

import { useToast } from "~/hooks/useToast";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { usePostBatchesFromOrdersMutation } from "~/redux/warehouse/orders.hooks";

import { clearSelectedOrders } from "./createBatches.slice";

export const CreateBatchesToolbar = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();
  const selectedOrderIds = useAppSelector(
    (state) => state.createBatches.selectedOrderIds
  );
  const createBatchesLoading = useAppSelector(
    (state) => state.createBatches.createBatchesLoading
  );

  const [postBatchesFromOrders] = usePostBatchesFromOrdersMutation();

  return (
    <Toolbar>
      <UnselectButton onClick={() => dispatch(clearSelectedOrders())} />
      {!!selectedOrderIds.length && (
        <ButtonGroup
          variant="text"
          color="primary"
          aria-label="text primary button group"
          style={{ justifyContent: "center", width: "100%" }}
        >
          <Button
            color="secondary"
            id="clear-batch-id"
            onClick={async () => {
              if (selectedOrderIds.length > 0) {
                try {
                  const data = await postBatchesFromOrders({
                    orderIds: selectedOrderIds
                  }).unwrap();
                  dispatch(clearSelectedOrders());
                  successToast(
                    `${data.batched.length} ${t("orders")} ${t("batched")}`
                  );
                } catch (e) {
                  errorToast(getMessageFromRtkError(e));
                }
              }
            }}
            disabled={createBatchesLoading}
          >
            <Typography variant="body2" style={{ color: "#fff" }}>
              {`${t("new batch")} (${selectedOrderIds.length})`}
            </Typography>
          </Button>
        </ButtonGroup>
      )}
    </Toolbar>
    // </Navbar>
  );
};
