import { ordersApi } from "./orders";

export const {
  useGetConsolidationSummaryQuery,
  useGetOrdersQuery,
  useGetOrderSummariesQuery,
  useGetOrderTypesQuery,
  useLazyGetOrdersQuery,
  usePostUnstallOrderMutation,
  usePostBatchesFromOrdersMutation,
  useCreateOrderTypeMutation,
  useDeleteOrderTypeMutation
} = ordersApi;
