import {
  GetBatchAction,
  GetBatchSuccessAction,
  GetBatchFailureAction,
  ClearErrorMessageAction,
  GetStagingAreasAction,
  GetStagingAreasSuccessAction,
  GetStagingAreasFailureAction,
  StageLoosePicksFailureAction,
  StageTotesFailureAction,
  GetLoosePicksByBatchAction,
  GetLoosePicksByBatchFailureAction,
  GetLoosePicksByBatchSuccessAction,
  GetTotesByBatchAction,
  GetTotesByBatchFailureAction,
  GetTotesByBatchSuccessAction,
  ResetStageViewAction
} from "~/redux/actions/stage";
import {
  BatchDto,
  LoosePickSummaryDto,
  StagingAreaDto,
  ToteSummaryDto
} from "~/types/api";

export type StageState = {
  batch: BatchDto | null;
  batchLoosePicks: LoosePickSummaryDto[];
  batchTotes: ToteSummaryDto[];
  errorMessage: string | null;
  loadingBatch: boolean;
  loadingBatchLoosePicks: boolean;
  loadingBatchTotes: boolean;
  batchLoosePicksFetched: boolean;
  batchTotesFetched: boolean;
  loadingStagingAreas: boolean;
  stagingAreas: StagingAreaDto[];
  successMessage: string | null;
};

export const initialState: StageState = {
  batch: null,
  batchLoosePicks: [],
  batchTotes: [],
  errorMessage: null,
  loadingBatch: false,
  loadingBatchLoosePicks: false,
  loadingBatchTotes: false,
  batchLoosePicksFetched: false,
  batchTotesFetched: false,
  loadingStagingAreas: false,
  stagingAreas: [],
  successMessage: null
};

type StageActions =
  | GetBatchAction
  | GetBatchSuccessAction
  | GetBatchFailureAction
  | GetStagingAreasAction
  | GetStagingAreasSuccessAction
  | GetStagingAreasFailureAction
  | ClearErrorMessageAction
  | StageLoosePicksFailureAction
  | StageTotesFailureAction
  | GetLoosePicksByBatchAction
  | GetLoosePicksByBatchSuccessAction
  | GetLoosePicksByBatchFailureAction
  | GetTotesByBatchAction
  | GetTotesByBatchSuccessAction
  | GetTotesByBatchFailureAction
  | ResetStageViewAction;

export const stage = (
  state: StageState | undefined,
  action: StageActions
): StageState => {
  if (state === undefined) {
    return initialState;
  }

  switch (action.type) {
    case "stage/GET_STAGING_AREAS_FAILURE":
    case "stage/GET_BATCH_FAILURE":
    case "stage/STAGE_LOOSE_PICKS_FAILURE":
    case "stage/STAGE_TOTES_FAILURE":
    case "stage/GET_TOTES_BY_BATCH_FAILURE":
    case "stage/GET_LOOSE_PICKS_BY_BATCH_FAILURE":
      return {
        ...state,
        loadingBatch: false,
        loadingBatchLoosePicks: false,
        loadingBatchTotes: false,
        loadingStagingAreas: false,
        errorMessage: action.payload
      };
    case "stage/GET_BATCH":
      return {
        ...state,
        loadingBatch: true
      };
    case "stage/GET_BATCH_SUCCESS":
      return {
        ...state,
        batch: action.payload,
        loadingBatch: false
      };
    case "stage/GET_STAGING_AREAS":
      return {
        ...state,
        loadingStagingAreas: true
      };
    case "stage/GET_STAGING_AREAS_SUCCESS":
      return {
        ...state,
        stagingAreas: action.payload,
        loadingStagingAreas: false
      };
    case "stage/GET_LOOSE_PICKS_BY_BATCH":
      return {
        ...state,
        loadingBatchLoosePicks: true,
        batchLoosePicksFetched: false
      };
    case "stage/GET_LOOSE_PICKS_BY_BATCH_SUCCESS":
      return {
        ...state,
        batchLoosePicks: action.payload,
        loadingBatchLoosePicks: false,
        batchLoosePicksFetched: true
      };
    case "stage/GET_TOTES_BY_BATCH":
      return {
        ...state,
        loadingBatchTotes: true,
        batchTotesFetched: false
      };
    case "stage/GET_TOTES_BY_BATCH_SUCCESS":
      return {
        ...state,
        batchTotes: action.payload,
        loadingBatchTotes: false,
        batchTotesFetched: true
      };
    case "stage/CLEAR_ERROR_MESSAGE":
      return {
        ...state,
        errorMessage: null
      };
    case "stage/RESET_STAGE_VIEW":
      return initialState;

    default:
      return state;
  }
};
