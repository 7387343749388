import Button from "@locaisolutions/button";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  Stack,
  TextField
} from "@mui/material";
import { t } from "i18next";
import { useForm } from "react-hook-form";

import { ModalDialogTransition } from "~/components/ModalDialogTransition";
import { useToast } from "~/hooks/useToast";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { dayOfWeekAsString } from "~/lib/shared";
import {
  useDeleteRecurringScheduleMutation,
  usePostRecurringScheduleMutation
} from "~/redux/warehouse/recurringSchedule.hooks";
import {
  CreateRecurringScheduleRequest,
  RecurringScheduleDto
} from "~/types/api";

type Props = {
  recurringSchedule: RecurringScheduleDto;
  onClose: () => void;
};

export default function EditRecurringScheduleModal(props: Props) {
  const { errorToast, successToast } = useToast();
  const { recurringSchedule, onClose } = props;
  const {
    batchDependency,
    dayOfWeek,
    interval,
    minMaxOrdersPerWave,
    orderTypes,
    recurringScheduleId,
    scheduleWindow,
    shipmentConfig
  } = recurringSchedule;

  const startOfWindow = scheduleWindow?.[0] ?? t("N/A");
  const endOfWindow = scheduleWindow.length > 1 ? scheduleWindow[1] : t("N/A");

  const [deleteRecurringSchedule] = useDeleteRecurringScheduleMutation();
  const [postRecurringSchedule] = usePostRecurringScheduleMutation();

  const { register, getValues, formState } =
    useForm<CreateRecurringScheduleRequest>({
      defaultValues: {
        interval,
        minMaxOrdersPerWave,
        shipmentConfig,
        dayOfWeek,
        scheduleWindow,
        orderTypes,
        batchDependency
      }
    });

  const handleSave = async () => {
    const newSchedule = getValues();
    try {
      await deleteRecurringSchedule(recurringScheduleId).unwrap();
      await postRecurringSchedule(newSchedule).unwrap();
      successToast(t("batch setting updated"));
      onClose();
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  return (
    <Dialog
      open={!!recurringSchedule}
      TransitionComponent={ModalDialogTransition}
      onClose={onClose}
    >
      <DialogTitle>
        {`${dayOfWeekAsString(dayOfWeek)} ${startOfWindow} - ${endOfWindow}`}
      </DialogTitle>

      <DialogContent>
        <Stack direction="column" spacing={3} pt="20px">
          <Box>
            <InputLabel htmlFor="interval" shrink>
              {t("batch interval label")}
            </InputLabel>
            <TextField
              id="interval"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">hh:mm:ss</InputAdornment>
                )
              }}
              {...register("interval", { required: true })}
            />
          </Box>

          <Box>
            <InputLabel htmlFor="minimum" shrink>
              {t("batch minimum label")}
            </InputLabel>
            <TextField
              id="minimum"
              type="number"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("orders").toLowerCase()}
                  </InputAdornment>
                )
              }}
              {...register("minMaxOrdersPerWave.0", {
                valueAsNumber: true,
                required: true
              })}
            />
          </Box>

          <Box>
            <InputLabel htmlFor="maximum" shrink>
              {t("batch maximum label")}
            </InputLabel>
            <TextField
              id="maximum"
              type="number"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t("orders").toLowerCase()}
                  </InputAdornment>
                )
              }}
              {...register("minMaxOrdersPerWave.1", {
                valueAsNumber: true,
                required: true
              })}
            />
          </Box>

          <FormControl>
            <FormGroup row>
              {/* Shipment checkbox not functional yet */}
              <FormControlLabel
                control={<Checkbox name="shipment" checked />}
                label="Shipment"
              />
            </FormGroup>
          </FormControl>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ gap: 1 }}>
        <Button onClick={onClose} kind="subtle" color="secondary" size="large">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSave}
          disabled={!formState.isDirty || !formState.isValid}
          kind="accent"
          size="large"
          sx={{ minWidth: 88 }}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
