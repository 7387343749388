import { useAppSelector } from "~/app/store";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";

import { ReconfigurationPort } from "./ReconfigurationPort";

export const WorkstationPort = ({ portId }: { portId: number }) => {
  const workstation = useAppSelector(selectThisWorkstation);
  const targetPorts = workstation?.ports.filter(
    (port) => port.parentPortId === portId || port.portId === portId
  );
  return (
    <>
      {targetPorts?.map((p, i) => (
        <ReconfigurationPort key={p.portId} portId={portId} portSideIndex={i} />
      ))}
    </>
  );
};
