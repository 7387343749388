import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type TwoColumnTableProps = {
  tableData: {
    left: string;
    right: string;
  }[];
  firstColumnWidth: string;
  sx?: Record<string, unknown>;
};

function TwoColumnTable(props: TwoColumnTableProps) {
  const { tableData, firstColumnWidth, sx } = props;

  const secondColumnWidth = `${100 - parseInt(firstColumnWidth, 10)}%`;

  return (
    <Box sx={{ width: "100%", ...sx }}>
      {tableData.map((td) => (
        <Box key={td.left} sx={{ display: "flex" }}>
          <Box sx={{ width: firstColumnWidth }}>
            <Typography>{td.left}</Typography>
          </Box>
          <Box sx={{ width: secondColumnWidth }}>
            <Typography>{td.right}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default TwoColumnTable;
