import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { MeasuredValueDto, PickDto } from "~/types/api";

type ShipOrderState = {
  isTransferModalOpen: boolean;
  openToteOrLoosePickId: Guid | null;
  selectedPick: PickDto | null;
  transferQty: MeasuredValueDto | null;
};

const initialState: ShipOrderState = {
  isTransferModalOpen: false,
  openToteOrLoosePickId: null,
  selectedPick: null,
  transferQty: null
};

export const shipOrderSlice = createSlice({
  name: "shipOrder",
  initialState,
  reducers: {
    selectPick(state, { payload }: PayloadAction<PickDto | null>) {
      state.selectedPick = payload;
    },
    setIsTransferModalOpen(state, { payload }: PayloadAction<boolean>) {
      state.isTransferModalOpen = payload;
    },
    setOpenToteOrLoosePickId(state, { payload }: PayloadAction<Guid | null>) {
      state.openToteOrLoosePickId = payload;
    },
    setTransferQty(state, { payload }: PayloadAction<MeasuredValueDto | null>) {
      state.transferQty = payload;
    }
  }
});
export const {
  selectPick,
  setIsTransferModalOpen,
  setOpenToteOrLoosePickId,
  setTransferQty
} = shipOrderSlice.actions;
