import { AutostoreEvent, SystemMode } from "~/types/api";

export const createEventSystemModeSample = ({
  caseCode,
  gridId
}: {
  caseCode: string;
  gridId: string;
}): AutostoreEvent => {
  return {
    case: "SystemModeChanged",
    event: {
      gridId: gridId,
      systemMode: caseCode as SystemMode
    }
  };
};
