import { Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import NavSearchInput from "~/components/navbar/NavSearchInput";
import { createProductSearchOptions } from "~/config/algoliaConfig";
import { fetchAlgoliaSearch } from "~/lib/helpers";
import { selectUsersClientId } from "~/redux/selectors/authSelectors";

import { ProductResult } from "./ProductResult";
import { setPage, setSearchedVariant } from "./cycleCountsNew.slice";
import useFlag from "~/config/flags";

export function CycleCountsSearch() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const clientId = useAppSelector(selectUsersClientId);

  const [autocompleteOptions, updateAutocompleteOptions] = useState<
    ProductResult[]
  >([] as ProductResult[]);
  const [searchText, setSearchText] = useState<string | null>(null);

  const useBackendForAlgoliaSearch = useFlag().useBackendForAlgoliaSearch;

  const autocompleteProductSearch = async (
    input: string | null
  ): Promise<void> => {
    if (input === null || input === "" || !clientId) {
      updateAutocompleteOptions([]);
    } else {
      // fetch algolia variants to get variantId to index counting-task as inventory or variant data doesn't exist in the task data
      const hits = await fetchAlgoliaSearch(
        useBackendForAlgoliaSearch,
        clientId,
        input
      );

      const hitsAsAutocompleteRecords = createProductSearchOptions({ hits });

      updateAutocompleteOptions([...hitsAsAutocompleteRecords]);
    }
  };

  return (
    <Box
      id="autostorePutaway-navbar-searchComponent"
      display="flex"
      flexGrow="1"
      justifyContent="center"
    >
      <NavSearchInput<ProductResult>
        textInput={searchText || ""}
        setTextInput={(text) => setSearchText(text)}
        isAutocomplete
        searchPlaceholder={t("search cycle counts on inventory")}
        autocompleteSearchCb={(input) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          autocompleteProductSearch(input);
        }}
        autocompleteOptions={autocompleteOptions}
        autocompleteLabelConstructor={(option: ProductResult): string =>
          option.displayText
        }
        groupBy={(option) => t(option.type)}
        selectCb={(result: ProductResult) => {
          dispatch(setPage(1));
          dispatch(setSearchedVariant(result));
        }}
        clearTextOnSelect
      />
    </Box>
  );
}
