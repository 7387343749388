import { createSvgIcon } from "@mui/material";

export const RemoveIcon = createSvgIcon(
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 4C9 3.44772 9.44772 3 10 3C10.5523 3 11 3.44772 11 4H9ZM7 4C7 2.34315 8.34315 1 10 1C11.6569 1 13 2.34315 13 4H19V6H1V4H7ZM5 17V7H3V18V19H4H16H17V18V7H15V17H5ZM11 15V8H13V15H11ZM7 8V15H9V8H7Z"
      fill="currentcolor"
    />
  </svg>,
  "Remove"
);
