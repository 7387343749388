import { Button, Toolbar } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { NavBarButtonGroup, UnselectButton } from "~/components/navbar/Navbar";

import {
  selectedTotesAndLoosePicks,
  singleSelectedToteOrLoosePick
} from "~/redux/selectors/stageTotesSelectors";

import { clearSelectedToteIds } from "./stageTotes.slice";

type Props = {
  handleStageTotes: () => Promise<void>;
};

export function StageTotesToolbar({ handleStageTotes }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const batch = useAppSelector((state) => state.stage.batch);
  const totesAndLoosePicks = useAppSelector(selectedTotesAndLoosePicks);
  const selectedToteIds = useAppSelector(
    (state) => state.stageTotes.selectedToteIds
  );
  const singleSelectedTote = useAppSelector(singleSelectedToteOrLoosePick);

  const isBulk = batch?.batchType === "Bulk";

  const viewButtonText = isBulk ? t("view bulk") : t("view totes");

  return (
    <Toolbar>
      <UnselectButton onClick={() => dispatch(clearSelectedToteIds())} />
      <NavBarButtonGroup>
        {singleSelectedTote && (
          <Button
            style={{ color: "white" }}
            onClick={(): void => {
              navigate(`/ship/order/${singleSelectedTote.orderId}`);
            }}
          >
            {viewButtonText}
          </Button>
        )}
        {!!totesAndLoosePicks.length && (
          <Button
            style={{ color: "white" }}
            onClick={async () => {
              await handleStageTotes();
            }}
          >
            {`${t("stage_verb")} ${selectedToteIds.length}`}
          </Button>
        )}
      </NavBarButtonGroup>
    </Toolbar>
  );
}
