import { Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { Auth0Profile } from "~/api/usersTypes/auth0Profile";
import { LanguageCode, languageCodes } from "~/localization_i18n";
import { setNavBarPosition } from "~/redux/actions/home";
import { setLanguage } from "~/redux/actions/site";
import { StoreState } from "~/redux/reducers";
import { NavBarPosition } from "~/redux/reducers/home";

const mapStateToProps = (state: StoreState) => ({
  siteLanguageCode: state.site.languageCode,
  navBarPosition: state.home.navBarPosition,
  clientConfig: state.site.clientConfig,
  profile: state.login.profile
});

const connector = connect(mapStateToProps, {
  setNavBarPosition,
  setLanguage
});
type UserSettingsInheritedProps = { viewTitle?: string };
type PropsFromRedux = ConnectedProps<typeof connector>;
type UserSettingsProps = PropsFromRedux & UserSettingsInheritedProps;

function UserSettings(props: UserSettingsProps) {
  const { siteLanguageCode, navBarPosition, clientConfig, profile } = props;

  const { t } = useTranslation();

  const handleLanguageSelect = (langCode: LanguageCode): void => {
    props.setLanguage(langCode);
  };

  const handleNavBarChange = (position: NavBarPosition): void => {
    props.setNavBarPosition(position);
  };

  const hasRequiredRoles = (profile: Auth0Profile | null): string => {
    if (profile) {
      const requiredRoles = [
        "powerpick-picker",
        "powerpick-inventory-manager",
        "powerpick-receiver",
        "client-administrator",
        "qubit-workstation-configurer"
      ];
      return profile.roles.some((role) => requiredRoles.includes(role))
        ? ""
        : "No sufficient permissions set for this profile";
    } else {
      return "";
    }
  };

  const languageCodeMap: Partial<Record<LanguageCode, string>> = {
    ...(clientConfig.site_defaultLanguageCode === "bs"
      ? { bs: "English" }
      : { en: "English" }),
    ko: "Korean",
    es: "Spanish",
    fr: "French"
  };

  const selectedLanguage = languageCodeMap[siteLanguageCode];

  return (
    <>
      <Grid item xs={12} style={{ marginBottom: 16 }}>
        <Typography>{hasRequiredRoles(profile)}</Typography>
      </Grid>
      <Grid item xs={12} md={6} style={{ marginBottom: 16 }}>
        <InputLabel id="language-label" shrink>
          {t("language")}
        </InputLabel>
        <Select
          id="language"
          fullWidth
          value={selectedLanguage || ""}
          labelId="language-label"
        >
          {languageCodes.map((langCode) => (
            <MenuItem
              key={langCode}
              value={languageCodeMap[langCode]}
              onClick={() => {
                handleLanguageSelect(langCode);
              }}
            >
              {languageCodeMap[langCode]}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel id="nav-bar-position-label" shrink>
          {t("nav bar position")}
        </InputLabel>
        <RadioGroup
          row
          value={navBarPosition || "top"}
          onChange={(e) => handleNavBarChange(e.target.value as NavBarPosition)}
        >
          <FormControlLabel value="top" control={<Radio />} label={t("top")} />
          <FormControlLabel
            value="bottom"
            control={<Radio />}
            label={t("bottom")}
          />
        </RadioGroup>
      </Grid>
    </>
  );
}

export default connector(UserSettings);
