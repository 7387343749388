import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Grid, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { useTranslation } from "react-i18next";

type ExpressIconProps = {
  epxressIconSizePx: number;
  translateVerticallyExpressIconPercentage: number;
  expressTextVariant: Variant;
  expressTextWeight: number;
  translateVerticallyExpressTextPercentage: number;
};

export const ExpressIcon = (props: ExpressIconProps) => {
  const { t } = useTranslation();
  const {
    epxressIconSizePx,
    translateVerticallyExpressIconPercentage,
    expressTextVariant,
    expressTextWeight,
    translateVerticallyExpressTextPercentage
  } = props;

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        item
        style={{
          transform: `translateY(${translateVerticallyExpressIconPercentage}%)`
        }}
      >
        <AccessTimeIcon sx={{ fontSize: `${epxressIconSizePx}px` }} />
      </Grid>
      <Grid
        item
        style={{
          transform: `translateY(${translateVerticallyExpressTextPercentage}%)`
        }}
      >
        <Typography variant={expressTextVariant} fontWeight={expressTextWeight}>
          {t("express")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ExpressIcon;
