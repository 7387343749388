import { AppDispatch } from "~/app/store";
import { hubs } from "~/lib/signalr";
import {
  UpdatePickingStateAction,
  UpdatePickingStateFailureAction,
  UpdatePickingStateSuccessAction
} from "~/redux/actions";
import {
  AutostorePickingState,
  PickingStatePickInfoByTote,
  SignalRPickEventDto
} from "~/types/api";

import autostorePickingApi from "./autostorePicking";

export const {
  useGetFocusedPickQuery,
  useLazyGetFocusedPickQuery,
  useGetPortStateQuery,
  useGetTotesForBatchQuery,
  useRepickProductsMutation,
  useGetPickingStateV1Query,
  usePartiallyCompleteMutation,
  useOutOfStockPickMutation,
  usePickBatchMutation
} = autostorePickingApi.enhanceEndpoints({
  addTagTypes: [],
  endpoints: {
    getFocusedPick: {
      async onCacheEntryAdded(
        _args,
        { cacheDataLoaded, cacheEntryRemoved, ...rest }
      ) {
        await cacheDataLoaded;
        const subscription = hubs.pick.subscribe({
          next: (event: SignalRPickEventDto) => {
            rest.updateCachedData((draft) => {
              if (draft?.pickId === event.pick.pickId) {
                return { ...draft, status: event.pick.status };
              }
              return;
            });
          }
        });
        await cacheEntryRemoved;
        subscription.unsubscribe();
      }
    },
    getPickingStateV1: {
      transformResponse: (response: AutostorePickingState) => {
        const sortedTotes = response.totes.slice().sort((tote1, tote2) => {
          if (tote1.totePosition && tote2.totePosition) {
            return tote1.totePosition - tote2.totePosition;
          }
          return 0;
        });

        const allPicksSortedByTotesPosition = sortedTotes
          .map((tote) => {
            return response.allPicks.find(
              (picks) => picks.toteId === tote.toteId
            );
          })
          .filter((ps): ps is PickingStatePickInfoByTote => !!ps);

        return {
          ...response,
          allPicks: allPicksSortedByTotesPosition,
          totes: sortedTotes
        };
      },
      // updating the picking state in the redux store until it is removed.
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        (dispatch as AppDispatch)<UpdatePickingStateAction>({
          type: "autostore/UPDATE_PICKING_STATE"
        });
        try {
          const { data } = await queryFulfilled;
          (dispatch as AppDispatch)<UpdatePickingStateSuccessAction>({
            type: "autostore/UPDATE_PICKING_STATE_SUCCESS",
            payload: {
              portId: args.portId,
              pickingState: data,
              timestamp: new Date()
            }
          });
        } catch {
          (dispatch as AppDispatch)<UpdatePickingStateFailureAction>({
            type: "autostore/UPDATE_PICKING_STATE_FAILURE"
          });
        }
      }
    }
  }
});
