import { useAppSelector } from "~/app/store";
import { useGetPicksQuery } from "~/redux/warehouse/pick.hooks";

/**
 * Computes the picks for the currently open tote or loose pick.
 *
 * @param orderId
 */
export function useOpenTotePicks(orderId: Guid) {
  const openToteOrLoosePickId = useAppSelector(
    (state) => state.shipOrder.openToteOrLoosePickId
  );
  const { data: picks } = useGetPicksQuery({
    orderId
  });

  return (
    (!!openToteOrLoosePickId &&
      picks?.filter(
        (pick) =>
          (pick.putToteId || pick.assignedToteId) === openToteOrLoosePickId
      )) ||
    null
  );
}
