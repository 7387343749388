import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import autostorePickingApi from "~/redux/warehouse/autostorePicking";

type ProbelmSolveModalState = {
  isOpen: boolean;
  // 1-indexed compartment number.
  selectedCompartment?: number;
  problemType?: string | null;
};

const initialState: ProbelmSolveModalState = {
  isOpen: false
};

export const problemSolveModalSlice = createSlice({
  name: "problemSolveModal",
  initialState,
  reducers: {
    openHoldBinModal(state) {
      state.isOpen = true;
    },
    closeHoldBinModal(state) {
      state.isOpen = false;
      state.selectedCompartment = undefined;
      state.problemType = undefined;
    },
    selectCompartment(state, action: PayloadAction<number>) {
      state.selectedCompartment = action.payload;
    },
    selectProblemType(state, action: PayloadAction<string>) {
      state.problemType = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      autostorePickingApi.endpoints.getFocusedPick.matchFulfilled,
      (state, { payload }) => {
        if (
          state.isOpen &&
          !state.selectedCompartment &&
          payload?.compartmentNumber
        ) {
          state.selectedCompartment = payload.compartmentNumber;
        }
      }
    );
  }
});

export const {
  openHoldBinModal,
  closeHoldBinModal,
  selectCompartment,
  selectProblemType
} = problemSolveModalSlice.actions;
