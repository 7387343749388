import { Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";

const SquareAvatar = styled(Avatar)(({ theme }) => ({
  height: 40,
  width: 40,
  backgroundColor: "white",
  border: `3px solid ${theme.palette.info.main}`,
  color: `${theme.palette.info.main}`,
  fontSize: "22px",
  fontWeight: 600,
  marginRight: 20
}));

const CircleAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,

  fontSize: "24px"
}));

function QuantityCircle(props: {
  quantity: number | React.ReactElement;
  variant: "circle" | "square";
  color?: string;
  backgroundColor?: string;
  border?: string;
  height?: string | number;
  width?: string | number;
  onClickCallback?: () => void;
}) {
  const {
    quantity,
    variant,
    color,
    backgroundColor,
    border,
    height = 50,
    width = 50,
    onClickCallback
  } = props;
  return variant === "square" ? (
    <SquareAvatar
      aria-label="quantity"
      variant="rounded"
      style={{ backgroundColor }}
    >
      {quantity}
    </SquareAvatar>
  ) : (
    <Button onClick={onClickCallback} aria-label="quantity">
      <CircleAvatar style={{ color, backgroundColor, border, height, width }}>
        {quantity}
      </CircleAvatar>
    </Button>
  );
}

export default QuantityCircle;
