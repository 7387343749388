import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { InventorySummaryDto } from "~/types/api";

type InventoryOldState = {
  getBinsIndex: number;
  isAddPanelOpen: boolean;
  isAdjustPanelOpen: boolean;
  isAdjustingBins: boolean;
  isBinComponentShown: boolean;
  isBinHoldModalOpen: boolean;
  isFetchingBin: boolean;
  portOpened: boolean;
  portPollingActive: boolean;
  selectedRows: Guid[];
  selectedSummaries: (InventorySummaryDto | null)[];
};

const initialState: InventoryOldState = {
  getBinsIndex: 0,
  isAddPanelOpen: false,
  isAdjustingBins: false,
  isAdjustPanelOpen: false,
  isBinComponentShown: false,
  isBinHoldModalOpen: false,
  isFetchingBin: false,
  portOpened: false,
  portPollingActive: false,
  selectedRows: [],
  selectedSummaries: []
};

export const inventoryOldSlice = createSlice({
  name: "inventoryOld",
  initialState,
  reducers: {
    setGetBinsIndex(state, { payload }: PayloadAction<number>) {
      state.getBinsIndex = payload;
    },
    setIsAddPanelOpen(state, { payload }: PayloadAction<boolean>) {
      state.isAddPanelOpen = payload;
    },
    setIsAdjustPanelOpen(state, { payload }: PayloadAction<boolean>) {
      state.isAdjustPanelOpen = payload;
    },
    setIsAdjustingBins(state, { payload }: PayloadAction<boolean>) {
      state.isAdjustingBins = payload;
    },
    setIsBinComponentShown(state, { payload }: PayloadAction<boolean>) {
      state.isBinComponentShown = payload;
    },
    setIsBinHoldModalOpen(state, { payload }: PayloadAction<boolean>) {
      state.isBinHoldModalOpen = payload;
    },
    setIsFetchingBin(state, { payload }: PayloadAction<boolean>) {
      state.isFetchingBin = payload;
    },
    setPortOpened(state, { payload }: PayloadAction<boolean>) {
      state.portOpened = payload;
    },
    setPortPollingActive(state, { payload }: PayloadAction<boolean>) {
      state.portPollingActive = payload;
    },
    selectRows(state, { payload }: PayloadAction<Guid[]>) {
      state.selectedRows = payload;
    },
    selectSummaries(
      state,
      { payload }: PayloadAction<(InventorySummaryDto | null)[]>
    ) {
      state.selectedSummaries = payload;
    }
  },
  selectors: {
    selectedRows: (state) => state.selectedRows,
    selectedSummaries: (state) => state.selectedSummaries
  }
});
export const {
  selectRows,
  selectSummaries,
  setGetBinsIndex,
  setIsAdjustingBins,
  setIsAdjustPanelOpen,
  setIsAddPanelOpen,
  setIsBinComponentShown,
  setIsBinHoldModalOpen,
  setIsFetchingBin,
  setPortOpened,
  setPortPollingActive
} = inventoryOldSlice.actions;

export const { selectedRows, selectedSummaries } = inventoryOldSlice.selectors;
