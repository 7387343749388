import { lighten, useTheme } from "@mui/material";

import { useAppSelector, useAppDispatch } from "~/app/store";
import { setStatusHoverPosition } from "~/features/status/status.slice";
import { useLogPublisherCell } from "~/hooks/useLogPublisherCell";

import { selectStatusSelectedAutostoreGridId } from "~/redux/selectors/statusSelectors";
import { Point2D } from "~/types/api";

import { BaseCell } from "./BaseCell";

export const BinCell = ({ position }: { position: Point2D }) => {
  const selectedAutostoreGridId = useAppSelector(
    selectStatusSelectedAutostoreGridId
  );
  const { bins } = useLogPublisherCell(selectedAutostoreGridId, position);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const backgroundColor = bins?.length
    ? lighten(theme.palette.darkGray.dark, bins.length / 16)
    : lighten(theme.palette.primary.main, 0.2);

  return (
    <BaseCell
      role="gridcell"
      aria-label={`${position.x}/${position.y}`}
      sx={{ backgroundColor }}
      onMouseOver={() => dispatch(setStatusHoverPosition(position))}
    />
  );
};
