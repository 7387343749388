import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type BinReconfigurationState = {
  sourceBinConfigurationId?: Guid;
  targetBinConfigurationId?: Guid;
};

const initialState: BinReconfigurationState = {};

export const binReconfigurationSlice = createSlice({
  name: "binReconfiguration",
  initialState,
  reducers: {
    setSourceBinConfigurationId(state, { payload }: PayloadAction<Guid>) {
      state.sourceBinConfigurationId = payload;
    },
    setTargetBinConfigurationId(state, { payload }: PayloadAction<Guid>) {
      state.targetBinConfigurationId = payload;
    }
  }
});
export const { setSourceBinConfigurationId, setTargetBinConfigurationId } =
  binReconfigurationSlice.actions;
