import Box from "@mui/material/Box";
import { useFlags, camelCaseKeys } from "launchdarkly-react-client-sdk";
import ReactJson from "react-json-view";

export const LaunchDarklyFlags = () => {
  const flags = camelCaseKeys(useFlags());

  return (
    <Box
      sx={{
        backgroundColor: "gray.light",
        p: 3,
        borderRadius: 1,
        maxWidth: 600,
        m: 3
      }}
      data-testid="launchdarkly-flags-tab"
    >
      {flags && (
        <Box data-testid="launchdarkly-flags-container">
          <ReactJson
            displayDataTypes={false}
            quotesOnKeys={false}
            src={flags}
            displayObjectSize={false}
            style={{
              fontSize: 16
            }}
            name={null}
            shouldCollapse={false}
          />
        </Box>
      )}
    </Box>
  );
};
