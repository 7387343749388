import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

import { Inv_InventoryDateLabelSettings } from "~/config/clientConfig";
import { formatUtcDateDayjs, globalDateFormat } from "~/lib/dateHelpers";
import { checkIsExpiration, inventoryDateLabel } from "~/lib/helpers";

type Props = {
  inventoryDate: Dayjs | null;
  inv_inventoryDateLabel: Inv_InventoryDateLabelSettings;
  isDateValid: boolean;
  isPastDateAllowed?: boolean;
  setInventoryDate: (date: Dayjs | null) => void;
  onChangeCallback?: () => void;
};

export function InventoryDateField(props: Props) {
  const {
    inventoryDate,
    inv_inventoryDateLabel,
    isDateValid,
    isPastDateAllowed,
    setInventoryDate,
    onChangeCallback
  } = props;
  const { t } = useTranslation();

  return (
    <DesktopDatePicker
      slotProps={{
        textField: {
          InputLabelProps: {
            shrink: true
          },
          label: t(inventoryDateLabel(inv_inventoryDateLabel)),
          variant: "outlined",
          fullWidth: true,
          error: !isDateValid,
          sx: {
            ".MuiInputBase-input": {
              fontSize: "22px"
            }
          }
        }
      }}
      label={t(inventoryDateLabel(inv_inventoryDateLabel))}
      value={inventoryDate ? dayjs(formatUtcDateDayjs(inventoryDate)) : null}
      format={globalDateFormat}
      onChange={(date) => {
        if (onChangeCallback) onChangeCallback();
        setInventoryDate(date);
      }}
      minDate={
        checkIsExpiration(inv_inventoryDateLabel) && !isPastDateAllowed
          ? dayjs()
          : undefined
      }
    />
  );
}

export default InventoryDateField;
