import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  InventorySummaryDto,
  NextBinResponse,
  NextEmptyBinResponse
} from "~/types/api";

type NextBin = NextEmptyBinResponse | NextBinResponse | null;
type SelectedCompartment = { bin: NextBin; compartmentNumber: number | null };

type InventoryState = {
  binAtPortSeconds: number;
  holdTypeFilters: string[];
  isAutostorePutawayDialogOpen: boolean;
  isAdjustingBins: boolean;
  isChangeBinConfigModalOpen: boolean;
  isShowScanChoiceModalOpen: boolean;
  autostoreGridIdOverride?: Guid;
  selectedSummaries: InventorySummaryDto[];
  selectedAdjustingSummary: InventorySummaryDto | null;
  allInventoryPage: number;
  adjustingInventoryPage: number;
  isGetBinsLoading: boolean;
  selectedCompartment: SelectedCompartment;
  isCreateInventoryButtonEnabled: boolean;
  isFetchingBin: boolean;
  portPollingActive: boolean;
  isInventoryAdjustDialogOpen: boolean;
  isMovePanelOpen: boolean;
};

const initialState: InventoryState = {
  binAtPortSeconds: 0,
  holdTypeFilters: [],
  isAutostorePutawayDialogOpen: false,
  isAdjustingBins: false,
  isChangeBinConfigModalOpen: false,
  isShowScanChoiceModalOpen: false,
  selectedSummaries: [],
  selectedAdjustingSummary: null,
  allInventoryPage: 1,
  adjustingInventoryPage: 1,
  isGetBinsLoading: false,
  selectedCompartment: { bin: null, compartmentNumber: null },
  isCreateInventoryButtonEnabled: true,
  isFetchingBin: false,
  portPollingActive: false,
  isInventoryAdjustDialogOpen: false,
  isMovePanelOpen: false
};

export const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    incrementBinAtPortSeconds(state) {
      state.binAtPortSeconds = state.binAtPortSeconds + 1;
    },
    resetBinAtPortSeconds(state) {
      state.binAtPortSeconds = 0;
    },
    setHoldTypeFilters(state, { payload }: PayloadAction<string[]>) {
      state.holdTypeFilters = payload;
    },
    setIsAutostorePutawayDialogOpen(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.isAutostorePutawayDialogOpen = payload;
    },
    setIsAdjustingBins(state, { payload }: PayloadAction<boolean>) {
      state.isAdjustingBins = payload;
    },
    setIsChangeBinConfigModalOpen(state, { payload }: PayloadAction<boolean>) {
      state.isChangeBinConfigModalOpen = payload;
    },
    setIsShowScanModalOpen(state, { payload }: PayloadAction<boolean>) {
      state.isShowScanChoiceModalOpen = payload;
    },
    setAutostoreGridIdOverride(
      state,
      { payload }: PayloadAction<InventoryState["autostoreGridIdOverride"]>
    ) {
      state.autostoreGridIdOverride = payload;
    },
    setAllInventoryPage(state, { payload }: PayloadAction<number>) {
      state.allInventoryPage = payload;
    },
    setAdjustingInventoryPage(state, { payload }: PayloadAction<number>) {
      state.adjustingInventoryPage = payload;
    },
    setSelectedSummaries(
      state,
      { payload }: PayloadAction<InventorySummaryDto[]>
    ) {
      state.selectedSummaries = payload;
    },
    appendSummarySelector(
      state,
      { payload }: PayloadAction<InventorySummaryDto>
    ) {
      state.selectedSummaries = [...state.selectedSummaries, payload];
    },
    updateSelectedSummaries(
      state,
      { payload }: PayloadAction<InventorySummaryDto[]>
    ) {
      const updatedSummaries = state.selectedSummaries.map((summary) => {
        // If the summary is in the payload, use the updated payload version
        return (
          payload.find((sum) => sum.inventoryId === summary.inventoryId) ||
          summary
        );
      });
      state.selectedSummaries = updatedSummaries;
    },
    setSelectedAdjustingSummary(
      state,
      { payload }: PayloadAction<InventorySummaryDto | null>
    ) {
      state.selectedAdjustingSummary = payload;
    },
    setIsGetBinsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isGetBinsLoading = payload;
    },
    setSelectedCompartment(
      state,
      { payload }: PayloadAction<SelectedCompartment>
    ) {
      state.selectedCompartment = payload;
    },
    setSelectedCompartmentNumber(state, { payload }: PayloadAction<number>) {
      state.selectedCompartment = {
        ...state.selectedCompartment,
        compartmentNumber: payload
      };
    },
    setIsCreateInventoryButtonEnabled(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.isCreateInventoryButtonEnabled = payload;
    },
    setIsFetchingBin(state, { payload }: PayloadAction<boolean>) {
      state.isFetchingBin = payload;
    },
    setPortPollingActive(state, { payload }: PayloadAction<boolean>) {
      state.portPollingActive = payload;
    },
    setIsInventoryAdjustDialogOpen(state, { payload }: PayloadAction<boolean>) {
      state.isInventoryAdjustDialogOpen = payload;
    },
    setIsMovePanelOpen(state, { payload }: PayloadAction<boolean>) {
      state.isMovePanelOpen = payload;
    }
  }
});

export const {
  incrementBinAtPortSeconds,
  resetBinAtPortSeconds,
  setIsAutostorePutawayDialogOpen,
  setIsAdjustingBins,
  setIsChangeBinConfigModalOpen,
  setIsShowScanModalOpen,
  setHoldTypeFilters,
  setAutostoreGridIdOverride,
  setAllInventoryPage,
  setAdjustingInventoryPage,
  setSelectedAdjustingSummary,
  setSelectedSummaries,
  appendSummarySelector,
  updateSelectedSummaries,
  setIsGetBinsLoading,
  setSelectedCompartment,
  setSelectedCompartmentNumber,
  setIsCreateInventoryButtonEnabled,
  setIsFetchingBin,
  setPortPollingActive,
  setIsInventoryAdjustDialogOpen,
  setIsMovePanelOpen
} = inventorySlice.actions;
