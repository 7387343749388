import { BatchSummaryDto, FulfillmentCenterDto } from "~/types/api";

/**
 * Determines if a batch is ready to be picked based on FCs toteConfiguration,
 * and whether a cart has been assigned to the batch
 */
export const canBatchBePicked = (
  fulfillmentCenter: FulfillmentCenterDto,
  batch: BatchSummaryDto
): boolean => {
  // if fc has noTotes, we wont have a cart for the batch
  // for generatedTotes, we need a cartId assigned to the batch before the user can pick
  if (
    batch.batchType === "Bulk" ||
    fulfillmentCenter.toteConfiguration === "NoTotes" ||
    (fulfillmentCenter.toteConfiguration === "GeneratedTotes" && !!batch.cartId)
  ) {
    return true;
  }
  return false;
};
