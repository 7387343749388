import { NextBinResponse, NextEmptyBinResponse } from "~/types/api";

export const getEmptyCompartmentNumbers = (
  currentEmptyBin: NextEmptyBinResponse | NextBinResponse | null
): number[] | undefined => {
  return currentEmptyBin?.autostoreBinCompartments
    .filter(
      (b) =>
        b.isAutostoreBinCompartmentEmpty === true &&
        b.autostoreCompartmentNumber
    )
    .map((c) => c.autostoreCompartmentNumber as number);
};
