import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";

import { BatchViewModel } from "~/features/batch/BatchViewModel";
import { BatchStatus, BatchType, OrderType } from "~/features/batch/batch.type";
import {
  buildBatchStatusFilter,
  buildBatchStatusFilterAdmin,
  buildBatchTypeFilter
} from "~/lib/helpers";

import {
  GetBatchViewModelAction,
  GetBatchViewModelSuccessAction,
  GetBatchViewModelFailureAction,
  GetCurrentBatchViewModelAction,
  GetBatchWithCartNumberAction,
  GetBatchWithCartNumberSuccessAction,
  GetBatchWithCartNumberFailureAction,
  SetBatchWithCartNumberAction,
  FulfillAction,
  FulfillSuccessAction,
  FulfillFailureAction,
  OutOfStockSuccessAction,
  OutOfStockFailureAction,
  SplitSuccessAction,
  SplitFailureAction,
  WeightAdjustSuccessAction,
  WeightAdjustFailureAction,
  CompleteAction,
  CompleteSuccessAction,
  CompleteFailureAction,
  ClearBatchViewModelAction,
  GetLoosePicksByBatchAction,
  GetLoosePicksByBatchSuccessAction,
  GetLoosePicksByBatchFailureAction,
  GetTotesByBatchAction,
  GetTotesByBatchSuccessAction,
  GetTotesByBatchFailureAction,
  DropTotesFailureAction,
  DropTotesSuccessAction,
  PrepareCartAction,
  PrepareCartSuccessAction,
  PrepareCartFailureAction,
  UpdatePickAction,
  SetConfirmablePickIdsAction,
  SetActivePickCompletionAction,
  StartWeightAdjustment,
  EndWeightAdjustment,
  WeightAdjustParameters,
  AddToteFailureAction,
  AddToteSuccessAction,
  RevertPickAction,
  RevertPickSuccessAction,
  RevertPickFailureAction,
  SetFetchBatchFiltersAction,
  TempZone,
  SetSelectedOrderTypeAction,
  RestartTotesByBatchContent
} from "~/redux/actions/batch";
import {
  UpdateUsersFulfillmentCenterSuccessAction,
  FetchFulfillmentCenterSuccessAction,
  ClearFulfillmentCenterAction
} from "~/redux/actions/store";
import {
  BatchDto,
  FulfillmentCenterDto,
  LoosePickSummaryDto,
  ToteSummaryDto
} from "~/types/api";

export type ActivePickCompletion = {
  pickId: string;
  completion: number;
  buffer: number;
} | null;

export type BatchState = {
  fulfillmentCenter: FulfillmentCenterDto | null;
  batchViewModel: BatchViewModel | null;
  loadingBatchViewModel: boolean;
  batchComplete: boolean;
  batchLoosePicks: LoosePickSummaryDto[];
  batchTotes: ToteSummaryDto[];
  loadingBatchLoosePicks: boolean;
  loadingBatchTotes: boolean;
  confirmablePickIds: Guid[];
  activePickCompletion: ActivePickCompletion;
  weightAdjustParameters: WeightAdjustParameters | null;
  fulfillingPickId: string | null;
  fetchBatchFilters: {
    selectedTempZones: TempZone[];
    selectedBatchStatuses: BatchStatus[];
    selectedBatchStatusesAdmin: BatchStatus[];
    selectedBatchTypes: BatchType[];
    selectedOrderType: OrderType | null;
  };
  batchWithCartNumber: {
    batch: BatchDto | null;
    cartNumber: string | null;
  } | null;
};

export const initialState: BatchState = {
  fulfillmentCenter: null,
  batchViewModel: null,
  loadingBatchViewModel: false,
  batchComplete: false,
  batchLoosePicks: [],
  loadingBatchLoosePicks: false,
  batchTotes: [],
  loadingBatchTotes: false,
  confirmablePickIds: [],
  activePickCompletion: null,
  weightAdjustParameters: null,
  fulfillingPickId: null,
  fetchBatchFilters: {
    selectedTempZones: [],
    selectedBatchStatuses: [],
    selectedBatchStatusesAdmin: [],
    selectedBatchTypes: [],
    selectedOrderType: null
  },
  batchWithCartNumber: null
};

type BatchActions =
  | GetCurrentBatchViewModelAction
  | GetBatchViewModelAction
  | GetBatchViewModelSuccessAction
  | GetBatchViewModelFailureAction
  | GetBatchWithCartNumberAction
  | GetBatchWithCartNumberSuccessAction
  | GetBatchWithCartNumberFailureAction
  | SetBatchWithCartNumberAction
  | UpdatePickAction
  | FulfillAction
  | FulfillSuccessAction
  | FulfillFailureAction
  | OutOfStockSuccessAction
  | OutOfStockFailureAction
  | SplitSuccessAction
  | SplitFailureAction
  | WeightAdjustSuccessAction
  | WeightAdjustFailureAction
  | CompleteAction
  | CompleteSuccessAction
  | CompleteFailureAction
  | ClearBatchViewModelAction
  | GetLoosePicksByBatchAction
  | GetLoosePicksByBatchSuccessAction
  | GetLoosePicksByBatchFailureAction
  | GetTotesByBatchAction
  | GetTotesByBatchSuccessAction
  | GetTotesByBatchFailureAction
  | DropTotesFailureAction
  | DropTotesSuccessAction
  | PrepareCartAction
  | PrepareCartSuccessAction
  | PrepareCartFailureAction
  | SetConfirmablePickIdsAction
  | SetActivePickCompletionAction
  | StartWeightAdjustment
  | EndWeightAdjustment
  | AddToteFailureAction
  | AddToteSuccessAction
  | RevertPickAction
  | RevertPickSuccessAction
  | RevertPickFailureAction
  | SetFetchBatchFiltersAction
  | SetSelectedOrderTypeAction
  | UpdateUsersFulfillmentCenterSuccessAction
  | FetchFulfillmentCenterSuccessAction
  | RestartTotesByBatchContent
  | ClearFulfillmentCenterAction;

const reducer = (
  state: BatchState | undefined,
  action: BatchActions
): BatchState => {
  if (state === undefined) {
    return initialState;
  }

  switch (action.type) {
    case "batch/GET_BATCH_VIEW_MODEL_FAILURE":
    case "batch/COMPLETE_FAILURE":
    case "batch/FULFILL_FAILURE":
    case "batch/OUT_OF_STOCK_FAILURE":
    case "batch/SPLIT_FAILURE":
    case "batch/WEIGHT_ADJUST_FAILURE":
    case "batch/GET_LOOSE_PICKS_BY_BATCH_FAILURE":
    case "batch/GET_TOTES_BY_BATCH_FAILURE":
    case "batch/DROP_TOTES_FAILURE":
    case "batch/PREPARE_CART_FAILURE":
    case "batch/ADD_TOTE_FAILURE":
    case "batch/REVERT_PICK_FAILURE":
      return {
        ...state,
        loadingBatchViewModel: false,
        loadingBatchTotes: false,
        loadingBatchLoosePicks: false,
        fulfillingPickId: null
      };
    case "batch/GET_BATCH_VIEW_MODEL":
      return {
        ...state,
        batchViewModel: null,
        loadingBatchViewModel: true,
        batchComplete: false
      };
    case "batch/GET_CURRENT_BATCH_VIEW_MODEL":
      return {
        ...state,
        batchComplete: false
      };
    case "batch/COMPLETE_SUCCESS":
      return {
        ...state,
        batchComplete: true
      };
    case "batch/GET_BATCH_VIEW_MODEL_SUCCESS":
      return {
        ...state,
        batchViewModel: action.payload,
        loadingBatchViewModel: false,
        batchComplete: false
      };
    case "batch/GET_BATCH_WITH_CART_NUMBER":
      return {
        ...state,
        batchWithCartNumber: null
      };
    case "batch/GET_BATCH_WITH_CART_NUMBER_SUCCESS":
      return {
        ...state,
        batchWithCartNumber: action.payload
      };
    case "batch/GET_BATCH_WITH_CART_NUMBER_FAILURE":
      return {
        ...state,
        batchWithCartNumber: null
      };
    case "batch/SET_BATCH_WITH_CART_NUMBER":
      return {
        ...state,
        batchWithCartNumber: action.payload
      };
    case "batch/FULFILL":
      return {
        ...state,
        fulfillingPickId: action.payload
      };
    case "batch/REVERT_PICK":
      return {
        ...state,
        fulfillingPickId: action.payload
      };
    case "batch/FULFILL_SUCCESS":
      return {
        ...state,
        activePickCompletion: null,
        batchViewModel: action.payload,
        confirmablePickIds: [],
        fulfillingPickId: null
      };
    case "batch/OUT_OF_STOCK_SUCCESS":
    case "batch/SPLIT_SUCCESS":
    case "batch/WEIGHT_ADJUST_SUCCESS":
      return {
        ...state,
        batchViewModel: action.payload,
        fulfillingPickId: null
      };
    case "batch/REVERT_PICK_SUCCESS":
      return {
        ...state,
        batchViewModel: action.payload,
        activePickCompletion: null,
        confirmablePickIds: [],
        fulfillingPickId: null
      };
    case "batch/UPDATE_PICK":
      return {
        ...state,
        batchViewModel: action.payload
      };
    case "batch/CLEAR_BATCH_VIEW_MODEL":
      return {
        ...state,
        batchViewModel: null,
        batchComplete: false
      };
    case "batch/GET_LOOSE_PICKS_BY_BATCH":
      return {
        ...state,
        loadingBatchLoosePicks: true
      };
    case "batch/GET_LOOSE_PICKS_BY_BATCH_SUCCESS":
      return {
        ...state,
        batchLoosePicks: action.payload
      };
    case "batch/GET_TOTES_BY_BATCH":
      return {
        ...state,
        loadingBatchTotes: true
      };
    case "batch/GET_TOTES_BY_BATCH_SUCCESS":
      return {
        ...state,
        batchTotes: action.payload,
        loadingBatchTotes: false
      };
    case "batch/RESTART_TOTES_BY_BATCH_CONTENT":
      return {
        ...state,
        batchTotes: [],
        loadingBatchTotes: false
      };
    case "batch/PREPARE_CART_SUCCESS":
      return {
        ...state,
        batchViewModel: action.payload
      };
    case "batch/SET_CONFIRMABLE_PICK_IDS":
      return {
        ...state,
        confirmablePickIds: action.payload.confirmablePickIds
      };
    case "batch/SET_ACTIVE_PICK_COMPLETION":
      return {
        ...state,
        activePickCompletion: action.payload
      };
    case "batch/START_WEIGHT_ADJUSTMENT":
      return {
        ...state,
        weightAdjustParameters: action.payload
      };
    case "batch/END_WEIGHT_ADJUSTMENT":
      return {
        ...state,
        weightAdjustParameters: null
      };
    case "batch/SET_FETCH_BATCH_FILTERS":
      return {
        ...state,
        fetchBatchFilters: {
          selectedTempZones:
            action.payload.selectedTempZones ||
            state.fetchBatchFilters.selectedTempZones,
          selectedBatchStatuses:
            action.payload.selectedBatchStatuses ||
            state.fetchBatchFilters.selectedBatchStatuses,
          selectedBatchStatusesAdmin:
            action.payload.selectedBatchStatusesAdmin ||
            state.fetchBatchFilters.selectedBatchStatusesAdmin,
          selectedBatchTypes:
            action.payload.selectedBatchTypes ||
            state.fetchBatchFilters.selectedBatchTypes,
          selectedOrderType:
            // check if undefined rather than !! so that we pass null through
            action.payload.selectedOrderType !== undefined
              ? action.payload.selectedOrderType
              : state.fetchBatchFilters.selectedOrderType
        }
      };
    case "batch/SET_SELECTED_ORDER_TYPE":
      return {
        ...state,
        fetchBatchFilters: {
          ...state.fetchBatchFilters,
          selectedOrderType:
            state.fetchBatchFilters.selectedOrderType === action.payload
              ? null
              : action.payload
        }
      };
    case "store/FETCH_FULFILLMENT_CENTER_SUCCESS":
    case "store/UPDATE_USERS_FULFILLMENT_CENTER_SUCCESS":
      return {
        ...state,
        fetchBatchFilters: {
          ...state.fetchBatchFilters,
          selectedBatchStatuses: buildBatchStatusFilter(action.payload),
          selectedBatchStatusesAdmin: buildBatchStatusFilterAdmin(
            action.payload
          ),
          selectedBatchTypes: buildBatchTypeFilter(action.payload),
          selectedOrderType: null
        }
      };
    case "store/CLEAR_FULFILLMENT_CENTER":
      return {
        ...state,
        fetchBatchFilters: {
          ...state.fetchBatchFilters,
          selectedBatchStatuses: buildBatchStatusFilter(null),
          selectedBatchStatusesAdmin: buildBatchStatusFilterAdmin(null),
          selectedBatchTypes: buildBatchTypeFilter(null),
          selectedOrderType: null
        }
      };

    default:
      return state;
  }
};

const autostorePersistConfig = {
  key: "batch",
  storage,
  whitelist: ["fetchBatchFilters"]
};

export const batch = persistReducer(autostorePersistConfig, reducer);
