import { useLoading } from "@agney/react-loading";
import LoadingGrid from "@agney/react-loading/src/svg-loaders/grid.svg?react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Stack, styled } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { skipToken } from "@reduxjs/toolkit/query";
import { useRef, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import ReactJson from "react-json-view";
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts";

import { useAppSelector } from "~/app/store";
import { SplitButton } from "~/components/SplitButton";
import { ChangeWorkstationModal } from "~/features/batches/ChangeWorkstationModal";
import { useToast } from "~/hooks/useToast";
import { getPortSide } from "~/lib/getPortSide";
import {
  convertMillisecondsToTimeString,
  extractUserNameFromAuth0Id
} from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useGridV2Subscription } from "~/lib/signalr";

import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import {
  selectSelectedPortId,
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";
import {
  useCloseBinMutation,
  useClosePortMutation,
  useGetAutostoreTasksQuery,
  useGetBinQuery,
  useGetPortStatusQuery,
  useNextEmptyBinMutation,
  useOpenPortMutation,
  useRequestNextBinMutation
} from "~/redux/warehouse/autostoreGrid.hooks";

import { useGetPickingStateV1Query } from "~/redux/warehouse/autostorePicking.hooks";

import { useGetInventoryByAutostoreBinNumberQuery } from "~/redux/warehouse/inventory.hooks";
import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";
import { AutostoreEvent, WorkstationSummaryDto } from "~/types/api";

const BoxColumns = styled(Box)<{ cols: number }>(({ cols }) => ({
  flexGrow: 1,
  display: "grid",
  gridTemplateColumns: `repeat(${cols}, 1fr)`
}));

type BinActivity = {
  timestamp: Date;
  binNumber: number;
  secondsSinceDelta: number;
  operation: "Open" | "Close";
};

export type AutostoreStatusInfoProps = {
  portPollingIsActive?: boolean;
  floating?: boolean;
  forceWorkstation?: WorkstationSummaryDto;
  forcePortId?: number;
  forceAutostoreId?: Guid;
  forcePolling?: boolean;
  defaultAccordion?: string;
  updateColorDisabled?: boolean;
};

const dateToTimeString = (time: Date) =>
  time.toLocaleTimeString("en", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hourCycle: "h24",
    fractionalSecondDigits: 3
  });

export const AutostoreStatusInfo = (props: AutostoreStatusInfoProps) => {
  // hooks
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();
  const draggableRef = useRef(null);
  const { containerProps: containerProps1, indicatorEl: indicatorEl1 } =
    useLoading({
      loading: true,
      indicator: (
        <LoadingGrid
          color={"#000000".replace(/0/g, () =>
            // eslint-disable-next-line no-bitwise
            (~~(Math.random() * 16)).toString(16)
          )}
          height="40"
        />
      )
    });

  const { containerProps: containerProps2, indicatorEl: indicatorEl2 } =
    useLoading({
      loading: true,
      indicator: (
        <LoadingGrid
          color={"#000000".replace(/0/g, () =>
            // eslint-disable-next-line no-bitwise
            (~~(Math.random() * 16)).toString(16)
          )}
          height="40"
        />
      )
    });

  const { containerProps: containerProps3, indicatorEl: indicatorEl3 } =
    useLoading({
      loading: true,
      indicator: (
        <LoadingGrid
          color={"#000000".replace(/0/g, () =>
            // eslint-disable-next-line no-bitwise
            (~~(Math.random() * 16)).toString(16)
          )}
          height="40"
        />
      )
    });

  // props
  const {
    floating,
    forcePortId,
    forceAutostoreId,
    forcePolling,
    forceWorkstation,
    portPollingIsActive,
    defaultAccordion,
    updateColorDisabled
  } = props;

  // use state
  const [portPollingActive, setPortPollingActive] = useState<boolean>(false);
  const [isWorkstationModalOpen, setIsWorkstationModalOpen] = useState(false);
  const [binHistoryPanelOpen, setPreviousBinPanelOpen] = useState(false);
  const [thirdPanelOpen, setThirdPanelOpen] = useState(false);

  const clientConfig = useAppSelector(selectClientConfig);

  // variable definitions
  const { ap_confirmCartNumberEnabled } = clientConfig;

  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const sitePortId = useAppSelector(selectSelectedPortId);
  const shouldPollPortStatus = portPollingActive || forcePolling;
  const portIdToUse = forcePortId || sitePortId;
  const autostoreGridIdToUse = forceAutostoreId || selectedAutostoreGridId;
  const workstationToUse = forceWorkstation || siteWorkstation;

  const auth0UserId = workstationToUse?.userId;
  const username = auth0UserId
    ? extractUserNameFromAuth0Id(auth0UserId)
    : " - ";

  const [closePort] = useClosePortMutation();
  const [openPort] = useOpenPortMutation();
  const [closeBin] = useCloseBinMutation();
  const [nextEmptyBin] = useNextEmptyBinMutation();
  const [requestNextBin] = useRequestNextBinMutation();

  const { data: workstations, error: getWorkstationsError } =
    useGetWorkstationsQuery();
  if (getWorkstationsError) {
    throw new Error(getMessageFromRtkError(getWorkstationsError));
  }

  const { data: portStatus, fulfilledTimeStamp: portStatusTimestamp } =
    useGetPortStatusQuery(
      autostoreGridIdToUse && portIdToUse
        ? {
            autostoreGridId: autostoreGridIdToUse,
            portId: portIdToUse
          }
        : skipToken,
      { pollingInterval: shouldPollPortStatus ? 500 : 0 }
    );

  const { data: autostoreTasks, refetch: refetchAutostoreTasks } =
    useGetAutostoreTasksQuery(autostoreGridIdToUse || skipToken);

  const {
    data: binQuery,
    fulfilledTimeStamp: binStateTimestamp,
    isFetching: binStateLoading,
    refetch: refetchBinQuery
  } = useGetBinQuery(
    autostoreGridIdToUse && portStatus?.selectedBin
      ? {
          autostoreGridId: autostoreGridIdToUse,
          binNumber: portStatus?.selectedBin
        }
      : skipToken
  );

  const { data: inventoryForBin } = useGetInventoryByAutostoreBinNumberQuery(
    autostoreGridIdToUse && portStatus?.selectedBin
      ? {
          autostoreGridId: autostoreGridIdToUse,
          binNumber: portStatus.selectedBin
        }
      : skipToken
  );

  const taskGroupsWithMatchingTaskIds = autostoreTasks?.filter(
    (autostoreTask) =>
      autostoreTask.tasks.find(
        (task) => task.taskId === portStatus?.selectedTask
      )
  );
  const taskGroupsWithMatchingBin = autostoreTasks?.filter((autostoreTask) =>
    autostoreTask.tasks.find((task) => task.binId === portStatus?.selectedBin)
  );

  const assumedTaskGroup = taskGroupsWithMatchingTaskIds?.at(0);
  const assumedTask = assumedTaskGroup?.tasks.find(
    (task) => task.taskId === portStatus?.selectedTask
  );
  const taskGroupsWithMatchingPortIds = autostoreTasks?.filter(
    (autostoreTask) =>
      !!autostoreTask.selectedPortId &&
      !!portIdToUse &&
      autostoreTask.selectedPortId === portIdToUse
  );

  const {
    data: pickingState,
    fulfilledTimeStamp: pickingStateTimestamp,
    isFetching: pickingStateLoading,
    refetch: refetchPickingState
  } = useGetPickingStateV1Query(
    autostoreGridIdToUse &&
      portIdToUse &&
      portStatus?.selectedTask &&
      [1, 2].includes(assumedTaskGroup?.category || 0)
      ? {
          gridId: autostoreGridIdToUse,
          portId: portIdToUse,
          taskId: portStatus.selectedTask
        }
      : skipToken
  );

  const currentPick = pickingState?.currentPicks.at(0);

  const taskGroupObjToDisplay = {
    binMatches: taskGroupsWithMatchingBin,
    taskIdMatches: taskGroupsWithMatchingTaskIds,
    portIdMatches: taskGroupsWithMatchingPortIds
  };

  const binStateColor = portStatus?.isReady ? "lightgreen" : "lightblue";
  const binStateText = binQuery ? `${binQuery.binState.binId}` : "-";
  const portStateColor = portStatus?.selectedBin
    ? binStateColor
    : "darkGray.main";
  const portStateText = portStatus?.selectedBin
    ? `${portStatus.selectedBin}`
    : "-";

  // functions
  const handleCloseBin = async () => {
    if (!portStatus || !autostoreGridIdToUse || !portIdToUse) return;
    const { selectedBin, selectedTask } = portStatus;

    try {
      await closeBin({
        autostoreGridId: autostoreGridIdToUse,
        portId: portIdToUse,
        binNumber: selectedBin,
        taskId: selectedTask
      }).unwrap();
      successToast(
        `Successfully closed bin ${selectedBin} at port ${portIdToUse}`
      );
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  const handleGetNextBin = async () => {
    if (!autostoreGridIdToUse || !portIdToUse) return;
    try {
      await requestNextBin({
        autostoreGridId: autostoreGridIdToUse,
        portId: portIdToUse
      }).unwrap();
      successToast(`Successfully opened bin at port ${portIdToUse}`);
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  const handleClosePort = async () => {
    if (!autostoreGridIdToUse || !portIdToUse) return;
    try {
      await closePort({
        autostoreGridId: autostoreGridIdToUse,
        portId: portIdToUse
      }).unwrap();
      successToast(`Successfully closed port ${portIdToUse}`);
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  const handleGetEmptyBin = async () => {
    if (!autostoreGridIdToUse || !portIdToUse) return;
    try {
      await nextEmptyBin({
        autostoreGridId: autostoreGridIdToUse,
        portId: portIdToUse
      }).unwrap();
      successToast(`Successfully opened empty bin at port ${portIdToUse}`);
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  const handleOpenPort = async (categoryIdString: string) => {
    if (!autostoreGridIdToUse || !portIdToUse) return;
    const categoryId = parseInt(categoryIdString, 10);
    if (!categoryId) return;
    try {
      await openPort({
        autostoreGridId: autostoreGridIdToUse,
        portId: portIdToUse,
        categoryId
      }).unwrap();
      successToast(
        `Successfully opened port ${portIdToUse} with category ${categoryId}`
      );
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  const handleToglePortStatePolling = () => {
    setPortPollingActive(!portPollingActive);
  };

  const [allBinActivity, setAllBinActivity] = useState<BinActivity[]>([]);
  const mostRecentBinActivity = allBinActivity.at(-1);

  const gridSubscription = (event: AutostoreEvent) => {
    if (
      event.case !== "BinModeChange" ||
      event.event.gridId !== autostoreGridIdToUse ||
      event.event.portId != portIdToUse ||
      !["C", "O"].includes(event.event.binMode)
    )
      return;

    setAllBinActivity((allBinActivity) => {
      const lastBin = allBinActivity.at(-1);
      const newBinActivity: BinActivity = {
        timestamp: new Date(),
        binNumber: event.event.binId,
        secondsSinceDelta: lastBin
          ? (new Date().valueOf() - lastBin.timestamp.valueOf()) / 1000
          : 0,
        operation: event.event.binMode === "C" ? "Close" : "Open"
      };
      allBinActivity.push(newBinActivity);
      if (allBinActivity.length > 100) allBinActivity.shift();
      return allBinActivity;
    });
  };

  useGridV2Subscription(gridSubscription);

  const binOpenHistory = allBinActivity.filter((ba) => ba.operation === "Open");
  const binCloseHistory = allBinActivity.filter(
    (ba) => ba.operation === "Close"
  );

  const portSide =
    workstationToUse && binQuery
      ? getPortSide(workstationToUse, {
          x: binQuery.binState.xPosition,
          y: binQuery.binState.yPosition
        })
      : undefined;

  return (
    <>
      <Draggable nodeRef={draggableRef}>
        <Stack
          ref={draggableRef}
          sx={{
            display: "flex",
            alignItems: "stretch",
            py: 5,
            px: 2,
            backgroundColor: "gray.light",
            position: floating ? "fixed" : "inherit",
            border: "1px solid ##777777",
            borderRadius: 7,
            top: floating ? 75 : "unset",
            left: floating ? 15 : "unset",
            boxShadow:
              "5px 5px 15px 5px #FF8080, -9px 5px 15px 5px #FFE488, -7px -5px 15px 5px #8CFF85, 12px -5px 15px 5px #80C7FF, 12px 10px 15px 7px #E488FF, -10px 10px 15px 7px #FF616B, -10px -7px 27px 1px #8E5CFF, 5px 5px 15px 5px rgba(0,0,0,0)",
            zIndex: 1000,
            flexBasis: 290
          }}
        >
          <Stack
            color={
              workstationToUse?.id === siteWorkstation?.id ? "white" : undefined
            }
            textAlign={"center"}
            justifyContent={"center"}
          >
            <Typography>Workstation: {workstationToUse?.deviceId}</Typography>
            <Typography>Port: {portIdToUse}</Typography>
            <Typography variant="subtitle1">{`${t("username")}: ${username}`}</Typography>
          </Stack>
          {/* bin present */}
          {mostRecentBinActivity && (
            <Accordion defaultExpanded={defaultAccordion === "bin present"}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <BoxColumns cols={2}>
                  <Typography>Bin Present</Typography>
                  <Typography
                    textAlign={"center"}
                    sx={{ backgroundColor: portStateColor }}
                  >
                    {mostRecentBinActivity.binNumber || "-"}
                  </Typography>
                </BoxColumns>
              </AccordionSummary>
              <AccordionDetails>
                <BoxColumns cols={2}>
                  <Typography variant="body2">timestamp:</Typography>
                  {mostRecentBinActivity.timestamp && (
                    <Typography variant="body2">
                      {dateToTimeString(mostRecentBinActivity.timestamp)}
                    </Typography>
                  )}
                  <Typography variant="body2">time since switch:</Typography>
                  {mostRecentBinActivity.secondsSinceDelta && (
                    <Typography variant="body2">
                      {convertMillisecondsToTimeString(
                        mostRecentBinActivity.secondsSinceDelta * 1000
                      )}
                    </Typography>
                  )}
                </BoxColumns>
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    onClick={() => {
                      setPreviousBinPanelOpen(!binHistoryPanelOpen);
                    }}
                    color="primary"
                  >
                    Previous Presentations
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
          {/* port state */}
          <Accordion defaultExpanded={defaultAccordion === "port state"}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <BoxColumns cols={2}>
                <Typography
                  sx={{
                    color:
                      portStatus && !updateColorDisabled
                        ? "info.main"
                        : undefined
                  }}
                >
                  Port State
                </Typography>
                <Typography
                  textAlign={"center"}
                  sx={{ backgroundColor: portStateColor }}
                >
                  {portStateText}
                </Typography>
              </BoxColumns>
            </AccordionSummary>
            <AccordionDetails>
              <BoxColumns cols={2}>
                <Typography variant="body2">timestamp:</Typography>
                {portStatusTimestamp && (
                  <Typography variant="body2">
                    {dateToTimeString(new Date(portStatusTimestamp))}
                  </Typography>
                )}
                <Typography variant="body2">
                  {t("mode").toLowerCase()}:
                </Typography>
                {portStatus && (
                  <Typography variant="body2">{`${portStatus.mode}`}</Typography>
                )}
                <Typography variant="body2">{t("task")}:</Typography>
                {portStatus && (
                  <Typography variant="body2">{`${portStatus.selectedTask}`}</Typography>
                )}
                <Typography variant="body2">isReady:</Typography>
                {portStatus && (
                  <Typography variant="body2">
                    {portStatus.isReady ? t("true") : t("false")}
                  </Typography>
                )}
                {workstationToUse?.multiPortEnabled && portSide && (
                  <>
                    <Typography variant="body2">Bin Open on Side:</Typography>
                    <Typography variant="body2">{portSide}</Typography>
                  </>
                )}
                <Typography variant="body2">statusPolling:</Typography>
                <Typography variant="body2">
                  {portPollingIsActive ? t("true") : t("false")}
                </Typography>
              </BoxColumns>
              <Box
                className="loading-progress-container"
                style={{
                  height: 60,
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                {(portPollingIsActive || portPollingActive) && (
                  <>
                    <section
                      {...containerProps1}
                      style={{
                        margin: 5
                      }}
                    >
                      {indicatorEl1}
                    </section>
                    <section
                      {...containerProps2}
                      style={{
                        margin: 5
                      }}
                    >
                      {indicatorEl2}
                    </section>
                    <section
                      {...containerProps3}
                      style={{
                        margin: 5
                      }}
                    >
                      {indicatorEl3}
                    </section>
                  </>
                )}
              </Box>

              <Button
                onClick={() => {
                  handleToglePortStatePolling();
                }}
                color="primary"
              >
                {portPollingActive ? t("stop polling") : t("start polling")}
              </Button>
            </AccordionDetails>
          </Accordion>
          {/* picking state */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <BoxColumns cols={2}>
                <Typography
                  sx={{
                    color:
                      pickingStateLoading && !updateColorDisabled
                        ? "info.main"
                        : undefined
                  }}
                >
                  Picking State
                </Typography>
                <Typography
                  textAlign={"center"}
                  sx={{ backgroundColor: portStateColor }}
                >
                  {currentPick?.pickBin?.autostoreBin?.autostoreBinId || "-"}
                </Typography>
              </BoxColumns>
            </AccordionSummary>
            <AccordionDetails>
              <BoxColumns cols={2}>
                <Typography variant="body2">timestamp:</Typography>
                {pickingStateTimestamp && (
                  <Typography variant="body2">
                    {dateToTimeString(new Date(pickingStateTimestamp))}
                  </Typography>
                )}
                <Typography variant="body2">isBinOpened:</Typography>
                {pickingState && (
                  <Typography variant="body2">{`${
                    pickingState.isBinOpened ? "true" : "false"
                  }`}</Typography>
                )}
                <Typography variant="body2">batch id:</Typography>
                {pickingState && (
                  <Typography
                    variant="body2"
                    noWrap
                  >{`${pickingState.batchId}`}</Typography>
                )}
                <Typography variant="body2">batch name:</Typography>
                {pickingState && (
                  <Typography
                    variant="body2"
                    noWrap
                  >{`${pickingState.batchName}`}</Typography>
                )}
                <Typography variant="body2">task group id:</Typography>
                {pickingState && (
                  <Typography variant="body2">{`${pickingState.taskGroupId}`}</Typography>
                )}

                <Divider />
                <Typography>current pick</Typography>

                <Typography variant="body2">task id:</Typography>
                {currentPick && (
                  <Typography variant="body2">{`${currentPick.autostoreTaskId}`}</Typography>
                )}

                <Typography variant="body2">pick status:</Typography>
                {currentPick && (
                  <Typography variant="body2">{`${currentPick.status}`}</Typography>
                )}
              </BoxColumns>
              <Button onClick={refetchPickingState} color="primary">
                Fetch Picking State
              </Button>
            </AccordionDetails>
          </Accordion>
          {/* bin log publisher state */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <BoxColumns cols={2}>
                <Typography
                  sx={{
                    color: binStateLoading ? "info.main" : undefined
                  }}
                >
                  Bin State
                </Typography>
                <Typography
                  textAlign={"center"}
                  sx={{ backgroundColor: binStateColor }}
                >
                  {binStateText}
                </Typography>
              </BoxColumns>
            </AccordionSummary>
            <AccordionDetails>
              <BoxColumns cols={2}>
                <Typography variant="body2">timestamp:</Typography>
                {binStateTimestamp && (
                  <Typography variant="body2">
                    {dateToTimeString(new Date(binStateTimestamp))}
                  </Typography>
                )}
                <Typography variant="body2">bin id:</Typography>
                {binQuery && (
                  <Typography variant="body2">{`${binQuery.binState.binId}`}</Typography>
                )}
                <Typography variant="body2">port id:</Typography>
                {binQuery && (
                  <Typography variant="body2">{`${binQuery.binState.portId}`}</Typography>
                )}
                <Typography variant="body2">bin mode:</Typography>
                {binQuery && (
                  <Typography variant="body2">{`${binQuery.binState.binMode}`}</Typography>
                )}
              </BoxColumns>
              <BoxColumns cols={6}>
                <Typography variant="body2">x:</Typography>
                {binQuery && (
                  <Typography variant="body2">{`${binQuery.binState.xPosition}`}</Typography>
                )}
                <Typography variant="body2">y:</Typography>
                {binQuery && (
                  <Typography variant="body2">{`${binQuery.binState.yPosition}`}</Typography>
                )}
                <Typography variant="body2">depth:</Typography>
                {binQuery && (
                  <Typography variant="body2">{`${binQuery.binState.depth}`}</Typography>
                )}
              </BoxColumns>
              <Button onClick={refetchBinQuery} color="primary">
                Fetch Bin Lp State
              </Button>
            </AccordionDetails>
          </Accordion>
          {/* matching task group */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <BoxColumns cols={2}>
                <Typography>Task Group</Typography>
                <Typography
                  textAlign={"center"}
                  sx={{ backgroundColor: binStateColor }}
                >
                  {assumedTask?.binId || "-"}
                </Typography>
              </BoxColumns>
            </AccordionSummary>
            <AccordionDetails>
              {assumedTaskGroup && (
                <BoxColumns cols={2}>
                  <Typography variant="body2">task id:</Typography>
                  {assumedTask && (
                    <Typography variant="body2">
                      {assumedTask.taskId}
                    </Typography>
                  )}

                  <Typography variant="body2">task bin:</Typography>
                  {assumedTask && (
                    <Typography variant="body2">{assumedTask.binId}</Typography>
                  )}

                  <Typography variant="body2">task group id:</Typography>
                  {assumedTaskGroup && (
                    <Typography variant="body2">
                      {assumedTaskGroup.taskGroupId}
                    </Typography>
                  )}

                  <Typography noWrap variant="body2">
                    batch name:
                  </Typography>
                  {assumedTaskGroup && (
                    <Typography variant="body2" noWrap>
                      {assumedTaskGroup.batchName}
                    </Typography>
                  )}
                  <Typography variant="body2">status:</Typography>
                  {assumedTaskGroup && (
                    <Typography variant="body2">
                      {assumedTaskGroup.batchStatus}
                    </Typography>
                  )}
                  <Typography variant="body2">selected port id:</Typography>
                  {assumedTaskGroup && (
                    <Typography variant="body2">
                      {assumedTaskGroup.selectedPortId || ""}
                    </Typography>
                  )}
                </BoxColumns>
              )}
              <Button onClick={refetchAutostoreTasks} color="primary">
                Fetch Autostore Tasks
              </Button>

              {!!assumedTaskGroup && (
                <Box
                  style={{
                    width: "100%",
                    marginTop: 5,
                    marginBottom: 5,
                    paddingTop: 15,
                    paddingBottom: 5,
                    borderTop: "1px solid black"
                  }}
                >
                  <Typography variant="body2">task group matches:</Typography>
                </Box>
              )}

              <Box sx={{ backgroundColor: "gray.light" }}>
                {!!assumedTaskGroup && (
                  <ReactJson
                    displayDataTypes={false}
                    displayObjectSize={false}
                    quotesOnKeys={false}
                    collapsed
                    src={taskGroupObjToDisplay}
                    indentWidth={1}
                  />
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* API Calls */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>API</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack>
                {portStatus && portStatus.mode === "OPEN" && (
                  <Button
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                      handleClosePort();
                    }}
                    color="primary"
                  >
                    {t("close port")}
                  </Button>
                )}
                {portStatus && portStatus.mode === "CLOSED" && (
                  <SplitButton
                    buttonLabel={t("open port")}
                    options={[
                      ...(ap_confirmCartNumberEnabled
                        ? [
                            {
                              label: ` ${t("nav.link.picking")}, ${t(
                                "Category"
                              )} 2`,
                              value: "2"
                            }
                          ]
                        : []),
                      {
                        label: ` ${t("nav.link.picking")}, ${t("Category")} 1`,
                        value: "1"
                      },
                      {
                        label: ` ${t("cycle counts")},  ${t("Category")} ${
                          portIdToUse || ""
                        }9`,
                        value: `${portIdToUse || ""}9`
                      },
                      {
                        label: ` ${t("holds")}, ${t("Category")} ${
                          portIdToUse || ""
                        }8`,
                        value: `${portIdToUse || ""}8`
                      }
                    ]}
                    clickCb={(value: string) => {
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                      handleOpenPort(value);
                    }}
                  />
                )}

                {portStatus &&
                  !!portStatus.selectedBin &&
                  portStatus.isReady && (
                    <Button
                      onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                        handleCloseBin();
                      }}
                      color="primary"
                    >
                      {t("close_verb")} {t("bin")}
                    </Button>
                  )}
                {portStatus && !portStatus.selectedBin && (
                  <Button
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                      handleGetNextBin();
                    }}
                    color="primary"
                  >
                    {t("next bin")}
                  </Button>
                )}
                {portStatus && !portStatus.selectedBin && (
                  <Button
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                      handleGetEmptyBin();
                    }}
                    color="primary"
                  >
                    {t("next")} {t("empty bin")}
                  </Button>
                )}
                {portStatus &&
                  [1, 2].includes(assumedTaskGroup?.category || 0) && (
                    <Button
                      onClick={() => {
                        setIsWorkstationModalOpen(true);
                      }}
                      color="primary"
                    >
                      CHANGE WORKSTATION
                    </Button>
                  )}
              </Stack>
            </AccordionDetails>
          </Accordion>
          {/* next bin response */}
          {!!inventoryForBin?.length && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>BIN INVENTORY</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  sx={{
                    backgroundColor: "gray.light"
                  }}
                >
                  {inventoryForBin.map((i) => (
                    <Box key={i.inventoryId} p={2}>
                      <Typography>Inventory Id:</Typography>
                      <Typography>{i.inventoryId}</Typography>
                      <Divider />
                      <Typography>Compartment Number:</Typography>
                      <Typography>
                        {i.bin.autostoreCompartmentNumber || ""}
                      </Typography>
                    </Box>
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          )}
          {binHistoryPanelOpen && (
            <Box
              style={{
                width: 290,
                marginTop: 40,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                height: 497,
                overflowY: "auto",
                borderBottom: "1px solid black",
                marginLeft: 2
              }}
            >
              <Box style={{ alignSelf: "flex-end" }}>
                <Box>
                  {binOpenHistory.length > 5 && (
                    <Button
                      onClick={() => {
                        setThirdPanelOpen(!thirdPanelOpen);
                      }}
                      color="primary"
                    >
                      Show Chart
                    </Button>
                  )}
                  {binOpenHistory.length > 0 && (
                    <Button
                      onClick={() => {
                        setAllBinActivity([]);
                      }}
                      color="primary"
                    >
                      Clear Data
                    </Button>
                  )}
                </Box>
              </Box>
              <Box
                mx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                {allBinActivity.map((ba) => {
                  return (
                    <Card
                      key={`${ba.binNumber || ""} + ${
                        ba.secondsSinceDelta || "0"
                      }`}
                      style={{
                        display: "flex",
                        width: "100%"
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%"
                        }}
                      >
                        <Box style={{ width: "50%" }}>
                          <Typography variant="body2">bin id:</Typography>
                        </Box>
                        <Box
                          style={{
                            width: "50%"
                          }}
                        >
                          {ba.binNumber && (
                            <Typography variant="body2">
                              {ba.binNumber}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%"
                        }}
                      >
                        <Box style={{ width: "50%" }}>
                          <Typography variant="body2">
                            delta duration:
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            width: "50%"
                          }}
                        >
                          {ba.secondsSinceDelta && (
                            <Typography variant="body2">
                              {convertMillisecondsToTimeString(
                                ba.secondsSinceDelta * 1000
                              )}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Card>
                  );
                })}
              </Box>
            </Box>
          )}
          {thirdPanelOpen && allBinActivity.length > 5 && (
            <Box
              style={{
                width: 400,
                marginTop: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                height: 497,
                overflowY: "auto",
                borderBottom: "1px solid black",
                marginLeft: 2
              }}
            >
              <BarChart width={400} height={200} data={binOpenHistory}>
                <CartesianGrid strokeDasharray="3 3" fill="white" />
                <YAxis domain={[0, 60]} allowDataOverflow />
                <XAxis dataKey="binNumber" hide />
                <Tooltip />
                <Bar
                  type="monotone"
                  dataKey="secondsSinceDelta"
                  fill="lightblue"
                  stroke="black"
                />
              </BarChart>
              <Box
                style={{
                  marginBottom: 10
                }}
              >
                <Typography>Fetching Bin</Typography>
              </Box>
              <Box
                style={{
                  marginBottom: 10,
                  borderBottom: "1px solid black",
                  height: 5
                }}
              />

              <BarChart width={400} height={200} data={binCloseHistory}>
                <CartesianGrid strokeDasharray="3 3" fill="white" />
                <YAxis domain={[0, 60]} allowDataOverflow />
                <XAxis dataKey="binNumber" hide />
                <Tooltip />
                <Bar
                  type="monotone"
                  dataKey="secondsSinceDelta"
                  fill="lightgreen"
                  stroke="black"
                />
              </BarChart>
              <Box
                style={{
                  marginBottom: 10
                }}
              >
                <Typography>Bin Present</Typography>
              </Box>
            </Box>
          )}
        </Stack>
      </Draggable>
      {isWorkstationModalOpen && portIdToUse && pickingState?.batchId && (
        <ChangeWorkstationModal
          currentWorkstation=""
          batchId={pickingState.batchId}
          workstations={
            (autostoreGridIdToUse
              ? workstations?.filter(
                  (workstation) =>
                    workstation.autostoreGridId === autostoreGridIdToUse
                )
              : workstations) || []
          }
          setIsWorkstationModalOpenCallback={setIsWorkstationModalOpen}
        />
      )}
    </>
  );
};
