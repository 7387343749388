import { useEffect } from "react";

import { useAppSelector } from "~/app/store";
import envConstants from "~/config/envConstants";

import {
  selectAuth0UserId,
  selectUsersClientId,
  selectUsersProfileName,
  selectUsersRoleString
} from "~/redux/selectors/authSelectors";

export const useBeamer = () => {
  const userId = useAppSelector(selectAuth0UserId) || "";
  const clientId = useAppSelector(selectUsersClientId) || "";
  const profileName = useAppSelector(selectUsersProfileName) || "";
  const rolesString = useAppSelector(selectUsersRoleString) || "";

  useEffect(() => {
    if (!userId || !envConstants.BEAMER_PRODUCT_ID) return;

    const configScript = document.createElement("script");
    const configContent = document.createTextNode(`
        window.beamer_config = {
          product_id: "${envConstants.BEAMER_PRODUCT_ID}",
          user_firstname : "${profileName}",
          use_lastname : "",
          user_id : "${userId}",
          client_id : "${clientId}",
          roles : "${rolesString}",
          callback: function(){ document.getElementById("beamerSelector").style.display = "none"; }
          };
      `);
    configScript.appendChild(configContent);
    document.body.appendChild(configScript);

    const beamerScript = document.createElement("script");
    beamerScript.src = "https://app.getbeamer.com/js/beamer-embed.js";
    beamerScript.async = true;
    document.body.appendChild(beamerScript);
  }, [userId, clientId, profileName, rolesString]);
};

export default useBeamer;
