import Button from "@locaisolutions/button";

import { CircularProgress } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { useToast } from "~/hooks/useToast";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  selectWorkstationAutostoreGridId,
  selectWorkstationId
} from "~/redux/selectors/workstationsSelectors";
import { usePostConfirmInventoryMovementMutation } from "~/redux/warehouse/autostoreGrid.hooks";
import { fixedCacheKeys } from "~/redux/warehouse/fixedCacheKeys";
import { usePostInventoryMoveMutation } from "~/redux/warehouse/inventory.hooks";
import { InventoryMovementResponse, MeasuredValueDto } from "~/types/api";

import { setCurrentBins } from "./manageFlaggedInventory.slice";

export type Props = {
  isDisabled: boolean;
  isLastTask: boolean;
  currentBins: InventoryMovementResponse | undefined;
  quantity: MeasuredValueDto | undefined;
  handleCleanup: () => void;
};

const ConfirmMovementButton = (props: Props) => {
  const { isDisabled, isLastTask, currentBins, quantity, handleCleanup } =
    props;
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();
  const dispatch = useAppDispatch();
  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const workstationId = useAppSelector(selectWorkstationId);

  const [postInventoryMove, { isLoading: inventoryMoveIsLoading }] =
    usePostInventoryMoveMutation();
  const [confirmInventoryMovement, { isLoading: confirmIsLoading }] =
    usePostConfirmInventoryMovementMutation({
      fixedCacheKey: fixedCacheKeys.confirmMove
    });

  const handleClick = async () => {
    if (
      !autostoreGridId ||
      !workstationId ||
      !currentBins ||
      !quantity ||
      !currentBins.sourceBin?.inventoryId ||
      !currentBins.destinationBin?.warehouseBinId
    ) {
      Sentry.captureMessage(
        `Not all required data is available -> Source Inventory Id: ${currentBins?.sourceBin?.inventoryId}, Destination Inventory Id: ${currentBins?.destinationBin?.warehouseBinId}`,
        "error"
      );
      return errorToast(t("not all required data is available"));
    }
    try {
      await postInventoryMove({
        inventoryId: currentBins.sourceBin.inventoryId,
        targetBinId: currentBins.destinationBin.warehouseBinId,
        quantity
      }).unwrap();
      const nextBins = await confirmInventoryMovement({
        autostoreGridId,
        workstationId,
        sourceBinId: currentBins.sourceBin.autostoreBinId,
        destinationBinId: currentBins.destinationBin.autostoreBinId
      }).unwrap();
      dispatch(setCurrentBins(nextBins));
      successToast(t("inventory moved"));
      handleCleanup();
    } catch (error) {
      const errorMessage = getMessageFromRtkError(error);
      const lastItemErrorMessage =
        "There is nothing left to pick or no appropriate bin found.";
      // Currently the endpoint returns a 422 for the last item in the table
      // so we gracefully suppress the error until the endpoint can be changed
      if (
        isLastTask &&
        errorMessage.toLowerCase() === lastItemErrorMessage.toLowerCase()
      ) {
        successToast(t("inventory moved"));
        return handleCleanup();
      }
      errorToast(errorMessage);
    }
  };

  const loading = inventoryMoveIsLoading || confirmIsLoading;

  return (
    <Button
      onClick={handleClick}
      disabled={isDisabled || loading}
      size="large"
      sx={{
        fontWeight: "normal"
      }}
    >
      {t("confirm move")}
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            left: 16,
            color: "common.white"
          }}
        />
      )}
    </Button>
  );
};

export default ConfirmMovementButton;
