import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ConsolidationSummaryDto } from "~/types/api";

type ShipState = {
  orderTypeFilter: string | null;
  putWallIdentifierFilter: string | null;
  search: string | null;
  selectedConsolidationSummary: ConsolidationSummaryDto | null;
};

const initialState: ShipState = {
  orderTypeFilter: null,
  putWallIdentifierFilter: null,
  search: null,
  selectedConsolidationSummary: null
};

export const shipSlice = createSlice({
  name: "ship",
  initialState,
  reducers: {
    selectConsolidationSummary(
      state,
      { payload }: PayloadAction<ConsolidationSummaryDto | null>
    ) {
      state.selectedConsolidationSummary = payload;
    },
    setOrderTypeFilter(state, { payload }: PayloadAction<string | null>) {
      state.orderTypeFilter = payload;
    },
    setPutWallIdentifierFilter(
      state,
      { payload }: PayloadAction<string | null>
    ) {
      state.putWallIdentifierFilter = payload;
    },
    setSearch(state, { payload }: PayloadAction<string | null>) {
      state.search = payload;
    }
  }
});
export const {
  setSearch,
  selectConsolidationSummary,
  setOrderTypeFilter,
  setPutWallIdentifierFilter
} = shipSlice.actions;
