import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AutostorePickingState = {
  pickCompartment: number;
  currentPickQuantity: number;
};

const initialState: AutostorePickingState = {
  pickCompartment: 0,
  currentPickQuantity: 0
};

export const autostorePickingSlice = createSlice({
  name: "autostorePicking",
  initialState,
  reducers: {
    setPickCompartment(state, action: PayloadAction<number>) {
      state.pickCompartment = action.payload;
    },
    setCurrentPickQuantity(state, action: PayloadAction<number>) {
      state.currentPickQuantity = action.payload;
    }
  },
  selectors: {
    selectPickCompartment: (state: AutostorePickingState) =>
      state.pickCompartment,
    selectCurrentPickQuantity: (state: AutostorePickingState) =>
      state.currentPickQuantity
  }
});

export const { setPickCompartment, setCurrentPickQuantity } =
  autostorePickingSlice.actions;

export const { selectPickCompartment, selectCurrentPickQuantity } =
  autostorePickingSlice.selectors;
