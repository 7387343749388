import { Box, Tab, Tabs } from "@mui/material";

import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { WorkstationConfig } from "~/features/workstationConfig/WorkstationConfig";
import { useNavbar } from "~/hooks/useNavbar";
import { useTabNavLocation } from "~/hooks/useTabNavLocation";
import { useView } from "~/hooks/useView";

import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import { selectIfWorkstationsTabShouldBeEnabled } from "~/redux/selectors/workstationsSelectors";

import { BatchSettings } from "./batchSettings/BatchSettings";
import FCReference from "./FCReference";
import { GridSettings } from "./GridSettings";
import Settings from "./Settings";

type SettingsViewInheritedProps = { viewTitle?: string };
type SettingsViewProps = SettingsViewInheritedProps;

function SettingsView(props: SettingsViewProps) {
  const { viewTitle } = props;

  const { t } = useTranslation();
  const shouldEnableWorkstationTab = useAppSelector(
    selectIfWorkstationsTabShouldBeEnabled
  );
  const isAdmin = useAppSelector(selectUserIsAdmin);

  useView({ permanentSidenav: true, fixedHeight: true });
  useNavbar({ viewTitle });

  const validTabs = [
    "settings",
    ...(shouldEnableWorkstationTab ? ["workstation-config"] : []),
    ...(isAdmin ? ["grid-settings", "batch-settings"] : [])
  ];

  const { tabIndex, handleTabIndexChange } = useTabNavLocation({
    defaultTab: "settings",
    validTabs,
    key: "settings-tab"
  });

  return (
    <Box
      id="settings-view"
      sx={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        height: "99%",
        padding: "1%"
      }}
    >
      <FCReference />

      <Tabs
        value={tabIndex}
        onChange={(_event, newValue) =>
          handleTabIndexChange(newValue as string)
        }
        aria-label="settings tabs"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label={t("settings")} value="settings" />
        {shouldEnableWorkstationTab && (
          <Tab label={t("workstations")} value="workstation-config" />
        )}

        {isAdmin && (
          <Tab
            label={`${t("batch")} ${t("nav.viewname.settings")}`}
            value="batch-settings"
          />
        )}
        {isAdmin && <Tab label={t("grid settings")} value="grid-settings" />}
      </Tabs>

      <Box
        id="tab-content"
        sx={{
          height: "100%",
          width: "98%",
          overflowX: "hidden",
          overflowY: "scroll"
        }}
      >
        {tabIndex === "settings" && <Settings />}
        {tabIndex === "workstation-config" && shouldEnableWorkstationTab && (
          <WorkstationConfig />
        )}
        {isAdmin && (
          <>
            {tabIndex === "batch-settings" && <BatchSettings />}
            {tabIndex === "grid-settings" && <GridSettings viewTitle="" />}
          </>
        )}
      </Box>
    </Box>
  );
}

export default SettingsView;
