import { Container, styled } from "@mui/material";
import { useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { useAppSelector } from "~/app/store";
import { useNavbar } from "~/hooks/useNavbar";
import {
  selectThisWorkstation,
  selectWorkstationAvailablePorts
} from "~/redux/selectors/workstationsSelectors";
import { useCloseWorkstationMutation } from "~/redux/warehouse/autostoreGrid.hooks";

import { BinReport } from "./BinReport";
import { WorkstationPort } from "./WorkstationPort";

const ViewContainer = styled(Container, {
  shouldForwardProp: (propName) => !["columns"].includes(propName as string)
})<{ columns: number }>(({ columns, theme: { spacing } }) => ({
  height: "calc(100vh - 56px)",
  display: "grid",
  gridAutoFlow: "column",
  gridTemplateColumns: `auto repeat(${columns}, minmax(200px, 400px))`,
  gridTemplateRows: "repeat(4, auto)",
  gap: spacing(5),
  placeContent: "center"
}));

export const BinReconfiguration = ({ viewTitle }: { viewTitle: string }) => {
  useNavbar({ viewTitle });
  const navigate = useNavigate();

  const [queryParams] = useSearchParams();
  const sourceBinConfigurationId = queryParams.get("sourceBinConfigurationId");
  const targetBinConfigurationId = queryParams.get("targetBinConfigurationId");

  const [closeWorkstation] = useCloseWorkstationMutation();

  const workstation = useAppSelector(selectThisWorkstation);

  useEffect(() => {
    if (sourceBinConfigurationId && targetBinConfigurationId) return;

    if (workstation) {
      void closeWorkstation({
        autostoreGridId: workstation.autostoreGridId,
        workstationId: workstation.id
      }).catch();
      navigate({ pathname: "/start-bin-reconfiguration" });
    }
  }, [
    closeWorkstation,
    navigate,
    sourceBinConfigurationId,
    targetBinConfigurationId,
    workstation
  ]);

  const workstationAvailablePorts = useAppSelector(
    selectWorkstationAvailablePorts,
    { equalityFn: shallowEqual }
  );

  const columns = workstation?.multiPortEnabled
    ? workstationAvailablePorts.length + 1
    : workstationAvailablePorts.length;

  return (
    <ViewContainer maxWidth="xl" columns={columns}>
      <BinReport />

      {workstationAvailablePorts.map((portId) => (
        <WorkstationPort key={portId} portId={portId} />
      ))}
    </ViewContainer>
  );
};
