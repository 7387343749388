import { SetStateAction, useEffect, useState } from "react";

const isPromise = <U>(obj: U | PromiseLike<U>): obj is PromiseLike<U> =>
  obj && !!(obj as Promise<U>).then;

// https://gist.github.com/ca0v/73a31f57b397606c9813472f7493a940?permalink_comment_id=3792845#gistcomment-3792845
export function debounce<T extends unknown[], U>(
  callback: (...args: T) => PromiseLike<U> | U,
  wait: number
) {
  let timer: number;
  return (...args: T): Promise<U> => {
    clearTimeout(timer);
    return new Promise((resolve) => {
      timer = window.setTimeout(() => {
        const result = callback(...args);
        // wait for result to finish before resolving to allow chaining
        if (isPromise(result)) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          result.then(resolve);
        } else {
          resolve(result);
        }
      }, wait);
    });
  };
}

// from https://usehooks.com/useDebounce/
export function useDebounce<T>(
  value: SetStateAction<T | undefined>,
  delay: number
): T | undefined;
export function useDebounce<T>(
  value: SetStateAction<T>,
  delay: number,
  initialValue: T
): T;
export function useDebounce<T>(
  value: SetStateAction<T | undefined>,
  delay: number,
  initialValue?: T
): T | undefined {
  const [debouncedValue, setDebouncedValue] = useState<T | undefined>(
    initialValue
  );
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

/**
 * Debounce a search text.
 *
 * @searchText The text to search.
 * @delay Number of milliseconds in between key presses before the search is committed. Defaults to 300.
 */
export const useDebounceSearch = (
  searchText: string | null,
  delay = 300
): string => useDebounce(searchText || "", delay, "");
