import { skipToken } from "@reduxjs/toolkit/query";

import { selectDataByCell } from "~/redux/selectors/logPublisherSelectors";
import { useGetLogPublisherStateQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import { Point2D } from "~/types/api";

export const useLogPublisherCell = (
  autostoreGridId: Guid | undefined,
  position: Point2D | null
) => {
  return useGetLogPublisherStateQuery(autostoreGridId || skipToken, {
    selectFromResult: ({ data, isLoading, error }) => ({
      isLoading,
      error,
      ...selectDataByCell(data, position)
    })
  });
};
