import { PropsWithChildren, useContext, useEffect } from "react";

import { DevCheatsContext } from "~/hooks/useDevCheats";
import { NavbarContext } from "~/hooks/useNavbar";
import { ViewContext } from "~/hooks/useView";

/**
 * Resets view, navbar and dev cheats contexts when unmounted.
 *
 * This ensures the contexts are cleared from the previous page's mutations to contexts.
 *
 * Ensure the key of this is set to the path of the route, so that it unmounts on path change.
 */
export function ContextReset(props: PropsWithChildren) {
  const { reset: resetView } = useContext(ViewContext);
  const { reset: resetNavbar } = useContext(NavbarContext);
  const { reset: resetDevCheats } = useContext(DevCheatsContext);

  useEffect(() => {
    // on unmount, reset the contexts
    return () => {
      resetView();
      resetNavbar();
      resetDevCheats();
    };
  }, [resetView, resetNavbar, resetDevCheats]);

  return props.children;
}
