import { useEffect, useRef } from "react";

/// Runs a function, waits for a response,
/// then waits for `delay` milliseconds before requesting again.
/// This prevents an issue where we continually send requests
/// without waiting for a response, which can flood the server.
export default function usePromiseInterval(
  callback: () => Promise<unknown>,
  delay: number,
  enabled: boolean
): void {
  const savedTimeout = useRef<number | undefined>();
  const savedCallback = useRef<() => Promise<unknown>>();
  const savedEnabled = useRef(enabled);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // The primitive can be cached in the tick
  // Causing this to run infinitely
  useEffect(() => {
    savedEnabled.current = enabled;
  }, [enabled]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current && savedEnabled.current) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        savedCallback.current().then(() => {
          savedTimeout.current = window.setTimeout(tick, delay);
        });
      }
    }
    savedTimeout.current = window.setTimeout(tick, delay);
    return () => {
      savedEnabled.current = false;
      clearTimeout(savedTimeout.current);
    };
  }, [delay, enabled]);
}
