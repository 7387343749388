import { formatTitleCase } from "~/lib/helpers";
import {
  BinStateDto,
  LogPublisherGridStateResponse,
  Point2D,
  PortStateDto,
  SystemStatusResponse
} from "~/types/api";

import { SystemStatus } from "./autostoreGrid";
import { LogPublisherGridStateResponseTransformed } from "./autostoreGrid.hooks";

type BinStateDtoWithPosition = BinStateDto & { xpos: number; ypos: number };

export const transformGetAutostoreGridStatus = (
  response: SystemStatusResponse,
  _: unknown,
  autostoreGridId: Guid
): SystemStatus => {
  if (response.systemStatus) {
    return {
      gridId: autostoreGridId,
      kind: "connected",
      mode: formatTitleCase(response.systemStatus.modeText),
      charge: `${response.systemStatus.chargePercent}%`,
      stopCode:
        response.systemStatus.stopcode === 0
          ? null
          : response.systemStatus.stopcodeText
    };
  }
  return {
    gridId: autostoreGridId,
    kind: "error",
    message: response.connection.match(/(?:.+: )?(.*)/)?.[1] ?? "None"
  };
};

const hasPosition = (bin: BinStateDto): bin is BinStateDtoWithPosition =>
  !!bin.ypos && !!bin.xpos && bin.depth !== undefined;

const groupBy = <T, K extends string>(
  arr: T[],
  key: (i: T) => K
): Record<K, T[]> => {
  const empty = {} as Record<K, T[]>;
  return arr.reduce<Record<K, T[]>>((groups, item) => {
    // eslint-disable-next-line no-param-reassign
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, empty);
};

export const minBy = <T>(arr: T[], fn: (el: T) => number) =>
  Math.min(...arr.map(fn));

export const maxBy = <T>(arr: T[], fn: (el: T) => number) =>
  Math.max(...arr.map(fn));

export const transformGetLogPublisherState = (
  response: LogPublisherGridStateResponse
): LogPublisherGridStateResponseTransformed => {
  const binsByPos = groupBy<BinStateDto, string>(response?.binStates, (b) =>
    hasPosition(b) ? `${b.xpos}/${b.ypos}` : "unknown"
  );
  const portsByPos = groupBy<Point2D & PortStateDto, string>(
    (response?.portStates || []).flatMap((p) =>
      p.portCells.map<Point2D & PortStateDto>((pc) => ({ ...pc, ...p }))
    ),
    (p) => `${p.x}/${p.y}`
  );

  const binsInsideGrid = response?.binStates.filter(hasPosition) || [];

  const portCells = (response?.portStates || []).flatMap((p) => p.portCells);

  const minXPortPos = minBy(portCells, (c) => c.x);
  const maxXPortPos = maxBy(portCells, (c) => c.x);
  const minYPortPos = minBy(portCells, (c) => c.y);
  const maxYPortPos = maxBy(portCells, (c) => c.y);

  const minXBinPos = minBy(binsInsideGrid, (b) => b.xpos);
  const maxXBinPos = maxBy(binsInsideGrid, (b) => b.xpos);
  const minYBinPos = minBy(binsInsideGrid, (b) => b.ypos);
  const maxYBinPos = maxBy(binsInsideGrid, (b) => b.ypos);

  const minXPos = Math.min(minXBinPos, minXPortPos);
  const maxXPos = Math.max(maxXBinPos, maxXPortPos);
  const minYPos = Math.min(minYBinPos, minYPortPos);
  const maxYPos = Math.max(maxYBinPos, maxYPortPos);

  const rows = maxXPos - minXPos + 1;
  const columns = maxYPos - minYPos + 1;

  const totalCells =
    columns === -Infinity || rows === -Infinity ? 0 : columns * rows;

  const cells = new Array(totalCells)
    .fill(0)
    .map((_, i): Point2D & { isPort: boolean } => {
      const currRow = Math.floor(i / columns);
      const x = currRow + minXPos;
      const y = i - currRow * columns + minYPos;
      return { x, y, isPort: !!portsByPos[`${x}/${y}`] };
    });

  return {
    cells,
    rows,
    columns,
    binsByPos,
    portsByPos
  };
};
