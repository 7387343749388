import AutomaticIcon from "@locaisolutions/icons/dist/icons20px/Automatic20Px";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { ConfirmationModal } from "~/components/ConfirmationModal";
import envConstants from "~/config/envConstants";
import { useToast } from "~/hooks/useToast";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";
import { useLazySyncGridStateQuery } from "~/redux/warehouse/autostoreGrid.hooks";

function SyncGridStateButton() {
  const { t } = useTranslation();
  const { errorToast } = useToast();
  const [syncGridState] = useLazySyncGridStateQuery();

  const [isConfirmationModalOpen, setConfirmationModalVisibilityStatus] =
    useState(false);

  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const isAdmin = useAppSelector(selectUserIsAdmin);

  const [loader, setLoader] = useState(false);

  const [syncGridStateText, setSyncGridStateText] = useState("");

  const handleSyncGridState = async () => {
    if (!selectedAutostoreGridId) {
      return;
    }
    setConfirmationModalVisibilityStatus(true);
    setLoader(true);
    setSyncGridStateText("Syncing grid state in progress");
    try {
      const response = await syncGridState({
        gridId: selectedAutostoreGridId
      }).unwrap();
      setLoader(false);
      setSyncGridStateText(response);
    } catch (err) {
      setLoader(false);
      setSyncGridStateText("Error. Could not fetch data.");
      errorToast(getMessageFromRtkError(err));
    }
  };

  return (
    <>
      {isAdmin && envConstants.SHOW_SYNC_GRID_STATE_BUTTON === "true" && (
        <Box data-testid="sync-grid-button-spacer" sx={{ height: 60 }}>
          {selectedAutostoreGridId && (
            <Box data-testid="button-container">
              <Button
                sx={{
                  backgroundColor: "mediumGray.main",
                  color: "black",
                  fontWeight: 600,
                  margin: "3px",
                  padding: "10px"
                }}
                variant="text"
                startIcon={<AutomaticIcon />}
                onClick={handleSyncGridState}
              >
                {t("sync grid state")}
              </Button>
            </Box>
          )}
        </Box>
      )}
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        confirmCb={() => {
          setConfirmationModalVisibilityStatus(false);
        }}
        closeCb={() => {
          setConfirmationModalVisibilityStatus(false);
        }}
        modalTitle="Sync Grid State"
        modalText={syncGridStateText}
        noCancelButton
        loader={loader}
        disableConfirmButton={loader}
        confirmText={t("close")}
      />
    </>
  );
}

export default SyncGridStateButton;
