import { LocalMall, LocalMallOutlined } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CheckIcon from "@mui/icons-material/Check";
import DomainIcon from "@mui/icons-material/Domain";
import DoneIcon from "@mui/icons-material/Done";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PanToolIcon from "@mui/icons-material/PanTool";
import PauseIcon from "@mui/icons-material/Pause";
import PinDropIcon from "@mui/icons-material/PinDrop";
import RoomIcon from "@mui/icons-material/Room";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StoreIcon from "@mui/icons-material/Store";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import WorkIcon from "@mui/icons-material/Work";
import { Box, Tooltip } from "@mui/material";
import { CSSProperties } from "react";

import { temperatureZoneColors } from "~/lib/helpers";

// used by tote type icon and loose pick type icon
export const toteStatusIcon = (
  status: string,
  color: string,
  toteStatusIconStyle: CSSProperties = {
    fontSize: 18,
    color: "white",
    position: "relative",
    bottom: 2,
    right: 1
  }
) => {
  switch (status) {
    case "Assigned":
      return null;

    case "On Cart":
      return <AddShoppingCartIcon sx={{ color }} style={{ fontSize: 16 }} />;

    case "Picked":
      return <ShoppingCartIcon style={toteStatusIconStyle} />;

    case "Dropped":
      return <LocationOnOutlinedIcon style={toteStatusIconStyle} />;

    case "Staged":
      return <PinDropIcon style={toteStatusIconStyle} />;

    case "Verified":
      return <CheckIcon style={toteStatusIconStyle} />;

    case "Loaded":
      return <LocalShippingIcon style={toteStatusIconStyle} />;

    default:
      return null;
  }
};

export const batchFilterIcon = (
  status: string,
  color: string,
  batchStatusIconStyle: React.CSSProperties = {
    fontSize: 18,
    position: "relative",
    bottom: 2,
    right: 1
  }
) => {
  switch (status) {
    case "Bulk":
      return <WorkIcon sx={{ color }} style={{ ...batchStatusIconStyle }} />;

    case "Store":
      return <StoreIcon sx={{ color }} style={{ ...batchStatusIconStyle }} />;

    case "Warehouse":
      return <DomainIcon sx={{ color }} style={{ ...batchStatusIconStyle }} />;

    case "Autostore":
      return (
        <ViewModuleIcon sx={{ color }} style={{ ...batchStatusIconStyle }} />
      );

    case "Scheduled":
      return (
        <AccessTimeIcon sx={{ color }} style={{ ...batchStatusIconStyle }} />
      );

    case "Cart Ready":
      return (
        <AddShoppingCartIcon
          sx={{ color }}
          style={{ ...batchStatusIconStyle }}
        />
      );

    case "Processing":
      return (
        <HourglassEmptyIcon
          sx={{ color }}
          style={{ ...batchStatusIconStyle }}
        />
      );

    case "Waiting Dependency":
      return <PanToolIcon sx={{ color }} style={{ ...batchStatusIconStyle }} />;

    case "Picked":
      return <DoneIcon sx={{ color }} style={{ ...batchStatusIconStyle }} />;

    case "Dropped":
      return <RoomIcon sx={{ color }} style={{ ...batchStatusIconStyle }} />;

    case "Staged":
      return <PinDropIcon sx={{ color }} style={{ ...batchStatusIconStyle }} />;

    case "Failed":
      return (
        <ErrorOutlineIcon sx={{ color }} style={{ ...batchStatusIconStyle }} />
      );

    case "Suspended":
      return <PauseIcon sx={{ color }} style={{ ...batchStatusIconStyle }} />;

    default:
      return null;
  }
};

function LoosePickType(
  key: string,
  fillToteBackground: boolean,
  color: string,
  status: string | null
) {
  const loosePickStatusStyle = {
    fontSize: 17,
    color: "white",
    position: "absolute" as const,
    bottom: 7,
    right: 4
  };
  return (
    // Tighten up the margins so when displayed in a row with tote boxes, the spacing looks good
    <Box marginTop="0.125em" key={key} position="relative">
      {fillToteBackground ? (
        <>
          <LocalMall sx={{ color }} style={{ fontSize: 26 }} />
          {/* cover inner part of icon to create space for status icons */}
          <Box
            position="absolute"
            top="8px"
            left="6px"
            height="10px"
            width="14px"
            style={{
              backgroundColor: fillToteBackground ? color : "transparent"
            }}
          />
        </>
      ) : (
        <LocalMallOutlined sx={{ color }} style={{ fontSize: 26 }} />
      )}
      {status ? toteStatusIcon(status, color, loosePickStatusStyle) : null}
    </Box>
  );
}

function ToteType(
  key: string,
  fillToteBackground: boolean,
  color: string,
  status: string | null
) {
  const toteIconStyle = {
    fontSize: 18,
    color: "white",
    position: "relative" as const,
    bottom: status === "Loaded" ? 1 : 2,
    right: 1
  };
  return (
    <Box key={key}>
      <Box
        key={key}
        width="15px"
        height="15px"
        borderRadius="2px"
        marginTop="1px"
        bgcolor={fillToteBackground ? color : "background.main"}
        border="2px solid"
        sx={{ borderColor: color }}
      >
        {status ? toteStatusIcon(status, color, toteIconStyle) : null}
      </Box>
    </Box>
  );
}

function BatchStatusType(
  key: string,
  color: string | undefined,
  bgColor: string | undefined,
  status: string
) {
  const statusIconStyle = {
    fontSize: 18,
    position: "relative" as const
  };
  return (
    <Box key={key}>
      <Box
        key={key}
        width="15px"
        height="15px"
        marginTop="1px"
        bgcolor={bgColor || "background.main"}
      >
        {batchFilterIcon(status, color || "black", statusIconStyle)}
      </Box>
    </Box>
  );
}

export function TinyIconGenerator({
  tempZone,
  type,
  key,
  complete,
  status = null,
  iColor,
  iBgColor
}: {
  tempZone?: "ambient" | "chilled" | "frozen";
  type: "Tote" | "LoosePick" | "Batch Filter";
  key: string;
  complete?: boolean;
  status?: string | null;
  iColor?: string;
  iBgColor?: string;
}) {
  let icon;

  switch (type) {
    case "Batch Filter":
      if (!key) return null;
      icon = BatchStatusType(key, iColor, iBgColor, key);
      break;
    case "Tote":
      {
        const color = tempZone
          ? temperatureZoneColors[tempZone]
          : "primary.main";

        const fillToteBackground =
          complete || !["Assigned", "On Cart"].includes(status || "");
        icon = ToteType(key, fillToteBackground, color, status);
      }
      break;
    case "LoosePick":
    default:
      {
        const color = tempZone
          ? temperatureZoneColors[tempZone]
          : "primary.main";

        const fillToteBackground =
          complete || !["Assigned", "On Cart"].includes(status || "");
        icon = LoosePickType(key, fillToteBackground, color, status);
      }
      break;
  }

  return status ? (
    <Tooltip
      title={status || false}
      aria-label={status || undefined}
      enterTouchDelay={100}
    >
      {icon}
    </Tooltip>
  ) : (
    icon
  );
}
