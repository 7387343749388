import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useLazyGetBinQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import { AutostoreBinState } from "~/types/api";

import { useToast } from "./useToast";

export const useBinStatusCheck = () => {
  const { t } = useTranslation();
  const { infoToast, errorToast } = useToast();
  const sitePortId = useAppSelector((state) => state.workstations.sitePortId);

  const autostoreGridId = useAppSelector(
    (state) => state.workstations.siteWorkstation?.autostoreGridId
  );

  const [getBinQuery] = useLazyGetBinQuery();

  const createBinStatusReadableMessage = useCallback(
    (binStatus: AutostoreBinState) => {
      switch (binStatus.binMode) {
        case "G":
          return t("bin is in the grid");
        case "T":
          return t("bin is prepared but still in the grid");
        case "R":
          return t("bin is being returned to the grid");
        case "O":
          if (binStatus.portId === sitePortId)
            return t("bin is opened at the port");
          return `${t("bin is opened at different port")}: ${binStatus.portId}`;
        case "P":
          if (binStatus.portId === sitePortId) return t("bin is at the port");
          return `${t("bin is at different port")}: ${binStatus.portId}`;
        case "C":
          if (binStatus.portId === sitePortId) return t("bin is closed");
          return `${t("bin is closed at different port")}: ${binStatus.portId}`;
        default:
          return t("unknown bin status");
      }
    },
    [sitePortId, t]
  );

  return useCallback(
    (binNumber: number | undefined) => {
      if (!binNumber || !autostoreGridId) return;
      getBinQuery({ autostoreGridId, binNumber })
        .unwrap()
        .then((binStatus) =>
          infoToast(createBinStatusReadableMessage(binStatus.binState))
        )
        .catch((err) => errorToast(getMessageFromRtkError(err)));
    },
    [
      autostoreGridId,
      createBinStatusReadableMessage,
      errorToast,
      getBinQuery,
      infoToast
    ]
  );
};
