import {
  SuggestBinConfigurationResponse,
  SuggestBinConfigurationRequest
} from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const cubingApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    suggestBinConfiguration: build.query<
      SuggestBinConfigurationResponse,
      SuggestBinConfigurationRequest
    >({
      query: (data) => {
        return {
          url: "/api/cubing/induction/suggest-bin-configuration",
          method: "POST",
          data
        };
      },
      providesTags: ["bin cubing suggestion"]
    })
  }),
  overrideExisting: false
});
