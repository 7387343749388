import { FilterList, Tune } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import Divider from "@mui/material/Divider";
import { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { BatchStatus, BatchType, OrderType } from "~/features/batch/batch.type";
import { TempZone } from "~/redux/actions/batch";
import { FulfillmentCenterDto } from "~/types/api";

import { TinyIconGenerator } from "./TinyIconGenerator";

function FetchBatchFilters(props: {
  fulfillmentCenter: FulfillmentCenterDto | null;
  selectedOrderType: OrderType | null;
  selectedTempZones: TempZone[];
  selectedBatchStatuses: BatchStatus[];
  availableBatchStatuses: BatchStatus[];
  selectedBatchTypes: BatchType[];
  availableBatchTypes: BatchType[];
  onSelectOrderType: (orderType: OrderType) => void;
  onToggleZone: (zone: TempZone) => void;
  onToggleStatus: (status: BatchStatus) => void;
  onToggleBatchType: (type: BatchType) => void;
  onResetFilters: () => void;
  iconColor?: string;
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showOrderTypeFilters =
    !!props.fulfillmentCenter?.clientConfiguration?.showOrderTypeFilters;

  const allStatusesSelected =
    props.selectedBatchStatuses.length >= props.availableBatchStatuses.length;

  const allTypesSelected =
    props.selectedBatchTypes.length >= props.availableBatchTypes.length;

  const filtersTempZone = [
    {
      filter: "ambient",
      translation: t("ambient")
    },
    {
      filter: "chilled",
      translation: t("chilled")
    },
    {
      filter: "frozen",
      translation: t("frozen")
    }
  ];

  // TODO: this will eventual come from and FC config
  const orderTypes = [
    { filter: "Fresh", translation: t("fresh") },
    { filter: "Shop", translation: t("shop") }
  ];

  type FiltersBatchStatuses = { filter: BatchStatus; translation: string };
  const [filtersAvailableBatchStatuses, setFiltersAvailableBatchStatuses] =
    useState<FiltersBatchStatuses[]>([]);

  type FiltersBatchTypes = { filter: BatchType; translation: string };
  const [filtersAvailableBatchTypes, setFiltersAvailableBatchTypes] = useState<
    FiltersBatchTypes[]
  >([]);

  useEffect(() => {
    setFiltersAvailableBatchStatuses([]);
    props.availableBatchStatuses?.map((b) =>
      setFiltersAvailableBatchStatuses((current) => [
        ...current,
        { filter: b, translation: t(`${b.toLowerCase()}`) }
      ])
    );

    setFiltersAvailableBatchTypes([]);
    props.availableBatchTypes?.map((b) =>
      setFiltersAvailableBatchTypes((current) => [
        ...current,
        { filter: b, translation: t(`${b.toLowerCase()}`) }
      ])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Button
        aria-label={"filters"}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ minWidth: 40, paddingRight: 0, textTransform: "capitalize" }}
      >
        {props.selectedTempZones.length === 1 ? (
          <Box>
            {TinyIconGenerator({
              tempZone: props.selectedTempZones[0],
              type: "Tote",
              key: "zone-indicator"
            })}
          </Box>
        ) : (
          <Tune
            data-testid="batch-filterIcon"
            sx={{ color: props.iconColor || "secondary.contrastText" }}
          />
        )}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        {filtersTempZone.map((zone) => (
          <MenuItem
            key={zone.filter}
            onClick={() => {
              props.onToggleZone(zone.filter as TempZone);
            }}
            sx={{
              minWidth: 150,
              backgroundColor: props.selectedTempZones.includes(
                zone.filter as TempZone
              )
                ? "gray.light"
                : undefined
            }}
          >
            <Box display="flex" justifyContent="space-between">
              {TinyIconGenerator({
                tempZone: zone.filter as "ambient" | "chilled" | "frozen",
                type: "Tote",
                key: `${zone.filter}-option`,
                iBgColor: props.selectedTempZones.includes(
                  zone.filter as TempZone
                )
                  ? "gray.light"
                  : "transparent"
              })}
              <Box style={{ marginLeft: 10 }}>
                {zone.translation.replace(/^\w/, (c) => c.toUpperCase())}
              </Box>
            </Box>
          </MenuItem>
        ))}
        <Divider style={{ marginTop: 2, marginBottom: 2, height: 3 }} />
        {filtersAvailableBatchStatuses?.map((status) => (
          <MenuItem
            key={status.filter}
            onClick={() => {
              props.onToggleStatus(status.filter);
            }}
            sx={{
              backgroundColor:
                !allStatusesSelected &&
                props.selectedBatchStatuses.includes(status.filter)
                  ? "gray.light"
                  : undefined
            }}
          >
            <Box display="flex" justifyContent="space-between">
              <Box>
                {TinyIconGenerator({
                  type: "Batch Filter",
                  key: status.filter,
                  iBgColor:
                    !allStatusesSelected &&
                    props.selectedBatchStatuses.includes(status.filter)
                      ? "gray.light"
                      : "transparent"
                })}
              </Box>
              <Box style={{ marginLeft: 10 }}>
                {status.translation.replace(/^\w/, (c) => c.toUpperCase())}
              </Box>
            </Box>
          </MenuItem>
        ))}
        {!!props.availableBatchTypes.length && (
          <Divider style={{ marginTop: 2, marginBottom: 2, height: 3 }} />
        )}
        {filtersAvailableBatchTypes.map((type) => (
          <MenuItem
            key={type.filter}
            onClick={() => {
              props.onToggleBatchType(type.filter);
            }}
            sx={{
              backgroundColor:
                !allTypesSelected &&
                props.selectedBatchTypes.includes(type.filter)
                  ? "gray.light"
                  : undefined
            }}
          >
            <Box display="flex" justifyContent="space-between">
              <Box>
                {TinyIconGenerator({
                  type: "Batch Filter",
                  key: type.filter,
                  iBgColor:
                    !allTypesSelected &&
                    props.selectedBatchTypes.includes(type.filter)
                      ? "gray.light"
                      : "transparent"
                })}
              </Box>
              <Box style={{ marginLeft: 10 }}>
                {type.translation.replace(/^\w/, (c) => c.toUpperCase())}
              </Box>
            </Box>
          </MenuItem>
        ))}
        <Divider style={{ marginTop: 2, marginBottom: 2, height: 3 }} />
        {showOrderTypeFilters &&
          orderTypes.map((type) => (
            <Box key={type.filter}>
              <MenuItem
                onClick={() => {
                  props.onSelectOrderType(type.filter as OrderType);
                }}
                sx={{
                  backgroundColor:
                    props.selectedOrderType === type.filter
                      ? "gray.light"
                      : undefined
                }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <FilterList
                      sx={{
                        fontSize: 18,
                        color: "black",
                        position: "relative",
                        bottom: 2,
                        right: 1
                      }}
                    />
                  </Box>
                  <Box style={{ marginLeft: 10 }}>
                    {type.translation.replace(/^\w/, (c) => c.toUpperCase())}
                  </Box>
                </Box>
              </MenuItem>
              <Divider style={{ marginTop: 2, marginBottom: 2, height: 3 }} />
            </Box>
          ))}
        <MenuItem
          key="reset filters"
          onClick={() => {
            props.onResetFilters();
            handleClose();
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box>{t("reset filters")}</Box>
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
}

export default FetchBatchFilters;
