const envs = {
  VERSION_TAG: import.meta.env.VITE_VERSION_TAG,
  RUNTIME_ENVIRONMENT: import.meta.env.RUNTIME_ENVIRONMENT,
  DEV: import.meta.env.DEV,
  // prettier-ignore
  LAUNCH_DARKLY_CLIENT_SIDE_ID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  DEV_CHEATS_UAT: import.meta.env.VITE_DEV_CHEATS_UAT,
  SUGGEST_API: import.meta.env.VITE_SUGGEST_API,
  PEPSI_WAREHOUSE_API: import.meta.env.VITE_PEPSI_WAREHOUSE_API,
  AS_WAREHOUSE_API: import.meta.env.VITE_AS_WAREHOUSE_API,
  WAREHOUSE_API: import.meta.env.VITE_WAREHOUSE_API,
  MODE: import.meta.env.MODE,
  VERSION: import.meta.env.VITE_VERSION,
  APP_OTLP_URL: import.meta.env.VITE_APP_OTLP_URL,
  APP_OTLP_API_KEY: import.meta.env.VITE_APP_OTLP_API_KEY,
  SHOW_SYNC_GRID_STATE_BUTTON: import.meta.env.VITE_SHOW_SYNC_GRID_STATE_BUTTON,
  // prettier-ignore
  SHOW_SKIP_SCAN_BUTTON_ON_SLAM_MODAL: import.meta.env.VITE_SHOW_SKIP_SCAN_BUTTON_ON_SLAM_MODAL,
  SHOW_SCANNABLE_BAR_CODES: import.meta.env.VITE_SHOW_SCANNABLE_BAR_CODES,
  DEV_CHEATS_STAGING: import.meta.env.VITE_DEV_CHEATS_STAGING,
  ENABLE_PTL_SIMULATION: import.meta.env.VITE_ENABLE_PTL_SIMULATION,
  ENABLE_AUTOMATED_OPERATIONS: import.meta.env.VITE_ENABLE_AUTOMATED_OPERATIONS,
  AUTH0_CONFIG_DOMAIN: import.meta.env.VITE_AUTH0_CONFIG_DOMAIN,
  AUTH0_CONFIG_CLIENT_ID: import.meta.env.VITE_AUTH0_CONFIG_CLIENT_ID,
  AUTH0_CONFIG_AUDIENCE: import.meta.env.VITE_AUTH0_CONFIG_AUDIENCE,
  // prettier-ignore
  AUTH0_CONFIG_ANDON_CLIENT_ID: import.meta.env.VITE_AUTH0_CONFIG_ANDON_CLIENT_ID,
  AUTH0_CONFIG_NAMESPACE: import.meta.env.VITE_AUTH0_CONFIG_NAMESPACE,
  // prettier-ignore
  ENABLE_CONVEYANCE_SIMULATION: import.meta.env.VITE_ENABLE_CONVEYANCE_SIMULATION,
  BEAMER_PRODUCT_ID: import.meta.env.VITE_BEAMER_PRODUCT_ID
};

export default envs;
