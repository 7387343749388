import { Typography, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";

import { tabletWidth } from "~/lib/theme";
import { PickDto, SignalRPickDto } from "~/types/api";

type DialogState = "AskWeight" | "AskQuantity";

function AskWeight(props: {
  totalQty: number;
  price: number;
  unitFormatted: string;
  weightAdjustment: number;
  confirm: (weight: number) => void;
  cancel: () => void;
}) {
  const { totalQty, unitFormatted, price, weightAdjustment, confirm, cancel } =
    props;

  const isTablet = useMediaQuery(tabletWidth);
  const [weight, setWeight] = useState<number | undefined>(weightAdjustment);
  const newPrice = weight && (weight / totalQty) * price;
  const displayPrice =
    newPrice && newPrice > 0 ? newPrice.toFixed(2) : price.toFixed(2);

  const upperLimit = totalQty * 1.5;
  const lowerLimit = totalQty * 0.5;

  let warning = null;
  if (weight && weight > upperLimit) {
    warning = "Weight is more than 50% higher";
  }

  if (weight && weight < lowerLimit) {
    warning = "Weight is less than 50% lower";
  }

  const handleConfirm = (): void => {
    if (weight) {
      confirm(weight);
    }
  };

  const handleCancel = (): void => {
    cancel();
    setWeight(totalQty);
  };

  const handleWeightChange = (inputValue: number | undefined): void => {
    setWeight(inputValue);
  };

  return (
    <>
      <DialogContent>
        <List style={{ paddingBottom: 28 }}>
          <ListItem>
            <ListItemText
              primary="Total Qty"
              secondary={`${totalQty} ${unitFormatted}`}
              primaryTypographyProps={{ style: { fontSize: 24 } }}
              secondaryTypographyProps={{ style: { fontSize: 20 } }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Total Price"
              secondary={`$${price.toFixed(2)}`}
              primaryTypographyProps={{ style: { fontSize: 24 } }}
              secondaryTypographyProps={{ style: { fontSize: 20 } }}
            />
          </ListItem>
        </List>
        <TextField
          label="Adjusted Weight"
          type="number"
          value={weight || ""}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: isTablet ? 20 : 30, top: isTablet ? 0 : -5 }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography style={{ fontSize: 20, color: "gray" }}>
                  {unitFormatted}
                </Typography>
              </InputAdornment>
            ),
            style: { fontSize: 30, padding: 8 }
          }}
          variant="outlined"
          helperText={warning || `New Price: $${displayPrice}`}
          FormHelperTextProps={{ style: { fontSize: 20 } }}
          onChange={(e): void => handleWeightChange(parseFloat(e.target.value))}
          error={!!warning}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancel} color="primary" style={{ fontSize: 20 }}>
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          style={{ fontSize: 20 }}
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
}

function AskQuantity(props: {
  pickQuantity: number;
  confirmedWeight: number | null;
  unitFormatted: string;
  confirm: (qty: number) => void;
  cancel: () => void;
}) {
  const { pickQuantity, confirmedWeight, unitFormatted, confirm, cancel } =
    props;

  const isTablet = useMediaQuery(tabletWidth);
  const [quantity, setQuantity] = useState<number | undefined>(pickQuantity);

  let error: string | null = null;
  if (!quantity || quantity <= 0) {
    error = `Must be greater than 0`;
  }
  if (quantity && quantity > pickQuantity) {
    error = `Must be less than ${pickQuantity}`;
  }

  const handleConfirm = (): void => {
    if (quantity && error === null) {
      confirm(quantity);
    }
  };

  const handleCancel = (): void => {
    cancel();
    setQuantity(pickQuantity);
  };

  const handleQuantityChange = (inputValue: number | undefined): void => {
    setQuantity(inputValue);
  };

  return (
    <>
      <DialogContent>
        <List style={{ paddingBottom: 28 }}>
          <ListItem>
            <ListItemText
              primary="Weight"
              secondary={`${confirmedWeight || "N/A"} ${unitFormatted}`}
              primaryTypographyProps={{ style: { fontSize: 24 } }}
              secondaryTypographyProps={{ style: { fontSize: 20 } }}
            />
          </ListItem>
        </List>
        <TextField
          label="How many are you picking?"
          type="number"
          value={quantity || ""}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: isTablet ? 20 : 30, top: isTablet ? 0 : -5 }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography style={{ fontSize: 20, color: "gray" }}>
                  units
                </Typography>
              </InputAdornment>
            ),
            style: { fontSize: 30, padding: 8 }
          }}
          variant="outlined"
          helperText={error}
          FormHelperTextProps={{ style: { fontSize: 20 } }}
          onChange={(e): void =>
            handleQuantityChange(parseFloat(e.target.value))
          }
          error={!!error}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary" style={{ fontSize: 20 }}>
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          style={{ fontSize: 20 }}
        >
          Adjust
        </Button>
      </DialogActions>
    </>
  );
}

/**
 * A dialog that prompts a user to set a weight, set a quantity, and then
 * warn the picker if the adjustment will result in a large difference
 * in price from the order placed.
 */
export default function WeightAdjust(props: {
  open: boolean;
  pick: SignalRPickDto | PickDto;
  scannedWeight: number | null;
  closePanel: () => void;
  adjustWeight: (pickId: Guid, weight: number, quantity: number) => void;
}) {
  const { open, pick, closePanel, adjustWeight, scannedWeight } = props;
  const { quantity, unitAmount = 1, unitFormatted, price, pickId } = pick;

  const remainingQuantity =
    quantity.value -
    (pick.exception?.weightAdjustments || []).reduce(
      (acc: number, wa) => acc + wa.quantity,
      0
    );
  const weightAdjustment = scannedWeight || remainingQuantity * unitAmount;

  // used for displaying the correct weight adjustment question to the user
  const [dialogState, setDialogState] = useState<DialogState>(
    scannedWeight !== null ? "AskQuantity" : "AskWeight"
  );

  const [confirmedWeight, setConfirmedWeight] = useState<number | null>(null);

  useEffect(() => {
    if (open) {
      setDialogState("AskWeight");
    }
  }, [open]);

  const confirmWeight = (weight: number): void => {
    setConfirmedWeight(weight);
    setDialogState("AskQuantity");
  };

  const cancelWeight = (): void => {
    closePanel();
  };

  const confirmQuantity = (qty: number): void => {
    if (!confirmedWeight) {
      throw new Error("Expected confirmedWeight to be set");
    }
    adjustWeight(pickId, confirmedWeight, qty);
    closePanel();
  };

  const cancelQuantity = (): void => {
    closePanel();
  };

  return (
    <Dialog open={open} aria-labelledby="weight-adjustment-dialog-title">
      <DialogTitle id="weight-adjustment-dialog-title">
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: 600,
            textAlign: "center"
          }}
        >
          Weighted Item
        </Typography>
      </DialogTitle>
      {dialogState === "AskWeight" ? (
        <AskWeight
          price={price}
          totalQty={remainingQuantity}
          unitFormatted={unitFormatted}
          weightAdjustment={weightAdjustment}
          confirm={confirmWeight}
          cancel={cancelWeight}
        />
      ) : (
        <AskQuantity
          pickQuantity={remainingQuantity}
          confirmedWeight={confirmedWeight}
          unitFormatted={unitFormatted}
          confirm={confirmQuantity}
          cancel={cancelQuantity}
        />
      )}
    </Dialog>
  );
}
