import { createSlice } from "@reduxjs/toolkit";

export interface AddToteModalState {
  isOpen: boolean;
}

const initialState = {
  isOpen: false
} as AddToteModalState;

export const addToteModalSlice = createSlice({
  name: "addToteModal",
  initialState,
  reducers: {
    openAddToteModal(state) {
      state.isOpen = true;
    },
    closeAddToteModal(state) {
      state.isOpen = false;
    }
  }
});

export const { openAddToteModal, closeAddToteModal } =
  addToteModalSlice.actions;
