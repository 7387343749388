import TempChilled from "@locaisolutions/icons/dist/icons20px/TempChilled20Px";
import TempFrozen from "@locaisolutions/icons/dist/icons20px/TempFrozen20Px";
import { Box, Stack } from "@mui/material";

import { useAppSelector } from "~/app/store";
import { tempZoneBasedColor } from "~/lib/helpers";

import { selectBatches } from "./batchCards.slice";

type TempZonesChipProps = {
  batchId: Guid;
};
export const TemperatureZoneIndicator = ({ batchId }: TempZonesChipProps) => {
  const batch = useAppSelector(selectBatches).find(
    (b) => b.batchId === batchId
  );
  const temperatureZones = batch?.temperatureZones || [];
  return (
    <Stack width={36}>
      {temperatureZones.map((tempZone, i) => (
        <Box
          key={`${batchId}-${i}`}
          justifyContent="flex-end"
          alignItems="center"
          flexGrow={1}
          py={1}
          width={36}
          bgcolor={tempZoneBasedColor(tempZone)}
          aria-label={`${batchId}-${i}-temp-zone-color`}
        >
          {tempZone.toLowerCase() === "chilled" && (
            <TempChilled
              aria-label={`${batchId}-${i}-chilled-temp-zone-icon`}
            />
          )}
          {tempZone.toLowerCase() === "frozen" && (
            <TempFrozen aria-label={`${batchId}-${i}-frozen-temp-zone-icon`} />
          )}
        </Box>
      ))}
    </Stack>
  );
};
