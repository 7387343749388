import { useEffect, useRef } from "react";

// from https://usehooks.com/usePrevious/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function usePrevious<Type>(value: Type): Type | undefined {
  const ref = useRef<Type>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
