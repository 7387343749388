import { Alert } from "@mui/material";
import { t } from "i18next";
import { useSearchParams } from "react-router-dom";

import { useAppSelector } from "~/app/store";
import { ProgressButton } from "~/components/ProgressButton";
import { usePortStatusQuery } from "~/hooks/usePortStatus";
import { useToast } from "~/hooks/useToast";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useNextEmptyBinMutation } from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetBinConfigurationsQuery } from "~/redux/warehouse/bin.hooks";

import { useConfirmBinReconfigurationMutation } from "~/redux/warehouse/workstation.hooks";

export const ConfirmBinReconfigurationButton = ({
  portId,
  portSideIndex
}: {
  portId: number;
  portSideIndex: number;
}) => {
  const { successToast } = useToast();
  const [queryParams] = useSearchParams();
  const sourceBinConfigurationId = queryParams.get("sourceBinConfigurationId");
  const targetBinConfigurationId = queryParams.get("targetBinConfigurationId");

  const [
    nextEmptyBin,
    { error: nextEmptyBinError, isLoading: nextEmptyBinLoading }
  ] = useNextEmptyBinMutation();
  const [
    confirmBinReconfiguration,
    {
      reset: confirmBinReconfigurationReset,
      isLoading: confirmBinReconfigurationLoading,
      error: confirmBinReconfigurationError
    }
  ] = useConfirmBinReconfigurationMutation();
  const workstation = useAppSelector(selectThisWorkstation);

  const { data: binConfigurations } = useGetBinConfigurationsQuery();

  const targetBinConfiguration = binConfigurations?.find(
    (bc) => bc.configurationId === targetBinConfigurationId
  );

  const { selectedBin, activityState } = usePortStatusQuery(
    portId,
    portSideIndex
  );

  const handleConfirm = async () => {
    if (!workstation || !sourceBinConfigurationId || !targetBinConfigurationId)
      return;

    confirmBinReconfigurationReset();
    try {
      if (activityState === "Bin Opened" && selectedBin) {
        await confirmBinReconfiguration({
          workstationId: workstation.id,
          sourceBinConfigurationId,
          targetBinConfigurationId,
          binNumber: selectedBin
        }).unwrap();

        successToast(
          `Reconfigured bin to have ${targetBinConfiguration?.numberOfCompartments} compartments`
        );
      } else if (selectedBin === 0) {
        await nextEmptyBin({
          autostoreGridId: workstation.autostoreGridId,
          portId
        }).unwrap();
      }
    } catch {
      // error is handled by alert
    }
  };

  if (!!confirmBinReconfigurationError || !!nextEmptyBinError) {
    return (
      <Alert
        variant="outlined"
        severity="error"
        action={<ProgressButton onClick={handleConfirm}>Retry</ProgressButton>}
      >
        {getMessageFromRtkError(
          confirmBinReconfigurationError ?? nextEmptyBinError
        )}
      </Alert>
    );
  }

  return (
    <ProgressButton
      sx={{ boxSizing: "border-box", p: 0 }}
      onClick={handleConfirm}
      buttonSize="large"
      disabled={
        activityState !== "Bin Opened" ||
        confirmBinReconfigurationLoading ||
        nextEmptyBinLoading
      }
      fullWidth
    >
      {t("confirm configuration")}
    </ProgressButton>
  );
};
