import { Grid, Typography } from "@mui/material";
import { t } from "i18next";

import { useAppSelector } from "~/app/store";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

export function BatchSettingsHeader() {
  const usersFc = useAppSelector(selectUsersFulfillmentCenter);
  return (
    <Grid item container>
      <Grid item container spacing={1} alignItems="center">
        <Grid item xs={3}>
          <Typography
            variant="subtitle1"
            component="span"
            color="textSecondary"
          >
            {t("fulfillment center")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" component="span">
            {usersFc?.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1} alignItems="center">
        <Grid item xs={3}>
          <Typography
            variant="subtitle1"
            component="span"
            color="textSecondary"
          >
            {t("batch configuration")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" component="span">
            {usersFc?.batchConfiguration}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1} alignItems="center">
        <Grid item xs={3}>
          <Typography
            variant="subtitle1"
            component="span"
            color="textSecondary"
          >
            {t("time zone")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" component="span">
            {usersFc?.timeZone}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
