import { createSelector } from "@reduxjs/toolkit";
import { useMemo } from "react";

import { useGetVariantInventorySummariesQuery } from "~/redux/warehouse/inventory.hooks";
import {
  VariantInventorySummaryRecord,
  VariantInventorySummaryResponse
} from "~/types/api";

export const useFilteredVariantInventorySummaries = (
  page: number,
  autostoreGridId: Guid | null,
  onlyAvailable: boolean
) => {
  const selectFilteredSummaries = useMemo(() => {
    const emptyArray: VariantInventorySummaryRecord[] = [];
    return createSelector(
      (data?: VariantInventorySummaryResponse) =>
        data?.variantInventorySummaries || emptyArray,
      (_res: unknown, page: number) => page,
      (_res: unknown, _page: number, onlyAvailable: boolean) => onlyAvailable,
      (
        _res: unknown,
        _page: unknown,
        _onlyAvailable: boolean,
        autostoreGridId: Guid | null
      ) => autostoreGridId,
      (
        variantInventorySummaries: VariantInventorySummaryRecord[],
        page: number,
        onlyAvailable: boolean,
        autostoreGridId: Guid | null
      ) => {
        const filteredSummaries = variantInventorySummaries
          .slice()
          .filter(
            (ia) =>
              (!autostoreGridId ||
                ia.autostoreGridIds.includes(autostoreGridId)) &&
              (!onlyAvailable || !!ia.totalAvailable)
          );

        const sortedAndFilteredSummaries = filteredSummaries
          ?.sort((a, b) => a.productName.localeCompare(b.productName))
          .slice((page - 1) * 8, page * 8);
        return {
          variantInventorySummaries: sortedAndFilteredSummaries,
          pageCount: Math.ceil(filteredSummaries.length / 8)
        };
      }
    );
  }, []);

  return useGetVariantInventorySummariesQuery(undefined, {
    selectFromResult: ({ data, isLoading, error }) => ({
      isLoading,
      error,
      ...selectFilteredSummaries(data, page, onlyAvailable, autostoreGridId)
    })
  });
};
