import { Product } from "~/lib/algoliaProduct.type";
import { algoliaVariantToDisplayName } from "~/lib/helpers";

import envConstants from "./envConstants";

export type AlgoliaConfig = {
  // eslint-disable-next-line camelcase
  application_id: string;
  // eslint-disable-next-line camelcase
  api_key: string;
  // eslint-disable-next-line camelcase
  products_index: string;
};

/** Type we're expecting to get back from the API. */
export type ApiAlgoliaConfig = {
  clientId: string;
  clientKey: string;
  apiKey: string;
  applicationId: string;
};

declare type AlgoliaConfigLookup = {
  [clientId: string]: AlgoliaConfig;
};

/** Cached copy of the list of client Algolia configs. */
let algoliaConfigLookup: AlgoliaConfigLookup = {};

/** Get the Algolia index prefix. */
function getAlgoliaEnvironment(environment: string) {
  switch (environment) {
    case "production":
      return "prod";
    case "staging":
    case "development":
    default:
      return "stg";
  }
}

/**
 * Map a single client record to a Client Config lookup index.
 *
 * @clientRecord One client config.
 * @environment The environment. Likely either `stg` or `prod`.
 */
function mapToClientLookup(
  clientRecord: ApiAlgoliaConfig,
  environment: string
): AlgoliaConfigLookup {
  const algoliaIndex = `${environment}_${clientRecord.clientKey}_products`;

  // Maybe eventually we'll want to just use the global config everywhere that these two fields are referenced,
  // but this provides us more flexibility in case we need to revert back to a per-client config.
  const client: AlgoliaConfig = {
    application_id: clientRecord.applicationId,
    api_key: clientRecord.apiKey,
    products_index: algoliaIndex
  };

  return { [clientRecord.clientId]: client };
}

/**
 * Map all client records to a Client Config lookup index.
 *
 * @environment The Algolia environment
 * @clientRecords All the client records.
 */
export function mapToAllClientLookup(
  environment: string,
  clientRecords: ApiAlgoliaConfig[]
): AlgoliaConfigLookup {
  const allClients = clientRecords
    .map((c) => mapToClientLookup(c, environment))
    .reduce(
      (previousValue, currentValue) => ({ ...previousValue, ...currentValue }),
      algoliaConfigLookup // Start with the existing one (empty or not)
    );

  return allClients;
}

/**
 * Populate the list of Algolia configurations with the given list.
 *
 * @clientsFromApi Clients we got from a call to the Products service.
 */
export function loadAlgoliaConfig(clientsFromApi: ApiAlgoliaConfig[]) {
  const environment = getAlgoliaEnvironment(envConstants.RUNTIME_ENVIRONMENT);

  algoliaConfigLookup = mapToAllClientLookup(environment, clientsFromApi);
}

/**
 * Get the cached Algolia config for this client.
 *
 * @clientId The client.
 */
export const getCachedAlgoliaConfig = (clientId: string) =>
  algoliaConfigLookup[clientId];

/**
 * Create abbreviated results records for the given Algolia hits.
 *
 * @exactUpcMatchFilter Optianal UPC to filter results by.
 */
export const createProductSearchOptions = (args: {
  hits: Product[];
  exactUpcMatchFilter?: string;
}): {
  type: string;
  variantId: string;
  displayText: string;
}[] => {
  const { hits, exactUpcMatchFilter } = args;
  return hits
    .filter((hit) => {
      if (
        exactUpcMatchFilter &&
        ![...(hit.AlternateLookups || []), hit.Upc].includes(
          exactUpcMatchFilter
        )
      )
        return false;
      return true;
    })
    .flatMap((hit) =>
      hit.Variants.map((variant) => ({
        type: "product",
        variantId: variant.ProductVariantId,
        sku: variant.Sku,
        displayText: algoliaVariantToDisplayName(variant, hit.Name)
      }))
    );
};
