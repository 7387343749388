import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import AutostoreTable from "~/components/autostore/table/AutostoreTable";

import { ShapedTasksByBinIds } from "./TaskAssessment";

interface NewTaskGroups {
  taskGroupId: number;
  batchName?: string;
  batchStatus?: string;
  selectedPortId?: number;
  tasks: number[];
}

type TaskGroupTableByBinProps = {
  shapedTasksByBinIds: ShapedTasksByBinIds[];
};

export const TaskGroupTableByBin = (props: TaskGroupTableByBinProps) => {
  const { shapedTasksByBinIds } = props;
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<Guid[]>([]);

  return (
    <AutostoreTable<ShapedTasksByBinIds>
      headerColNames={[t("BinId"), t("TaskIds"), t("Tasks")]}
      rowId={(row: ShapedTasksByBinIds) => `${row.binId}`}
      renderColumns={[
        (row: ShapedTasksByBinIds) => `${row.binId}`,
        (row: ShapedTasksByBinIds) => `${row.newTaskGroups.length}`,
        (row: ShapedTasksByBinIds) => `${row.tasks.length}`
      ]}
      widthOfCols={["20%", "20%", "60%"]}
      headerVariant="h6"
      bodyVariant="h6"
      rowData={shapedTasksByBinIds}
      selectedRows={selectedRows}
      selectRowCallback={(row: ShapedTasksByBinIds) => {
        if (selectedRows.length && selectedRows.includes(`${row.binId}`)) {
          setSelectedRows([]);
        } else {
          setSelectedRows([`${row.binId}`]);
        }
      }}
      renderSelectedRowExpand={(row: ShapedTasksByBinIds) => (
        <Collapse in timeout="auto" unmountOnExit>
          <Box style={{ width: "100%", border: "1px solid black" }}>
            <AutostoreTable<NewTaskGroups>
              headerColNames={[
                "Task Group Id",
                "Port",
                "Task Ids",
                "Batch Name",
                "Batch Status"
              ]}
              rowId={(innerRow: NewTaskGroups) => `${innerRow.taskGroupId}`}
              renderColumns={[
                (innerRow: NewTaskGroups) => (
                  <Typography>{`${innerRow.taskGroupId}`}</Typography>
                ),
                (innerRow: NewTaskGroups) => (
                  <Typography>{`${innerRow.selectedPortId || ""}`}</Typography>
                ),
                (innerRow: NewTaskGroups) => (
                  <Typography>{`[ ${innerRow.tasks.join(" , ")} ]`}</Typography>
                ),
                (innerRow: NewTaskGroups) => (
                  <Typography>{`${innerRow.batchName || ""}`}</Typography>
                ),
                (innerRow: NewTaskGroups) => (
                  <Typography>{`${innerRow.batchStatus || ""}`}</Typography>
                )
              ]}
              widthOfCols={["20%", "30%", "30%", "20%"]}
              headerVariant="h5"
              bodyVariant="h6"
              rowData={row.newTaskGroups}
              loading={false}
            />
          </Box>
        </Collapse>
      )}
      loading={false}
    />
  );
};
