import { skipToken } from "@reduxjs/toolkit/query";
import { useCallback } from "react";

import { useLocation } from "react-router-dom";

import { warehouseService } from "~/api/warehouse";
import { useAppSelector } from "~/app/store";
import { isAutostoreView } from "~/lib/helpers";

import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";
import { useGetAutostoreGridQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import { SearchBinRecord } from "~/types/api";

export function useSearchBinsByText() {
  const locationInfo = useLocation();
  const { search } = locationInfo;
  const isAutoStoreView = isAutostoreView(search);
  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const { data: selectedAutostoreGrid } = useGetAutostoreGridQuery(
    selectedAutostoreGridId ?? skipToken
  );

  return useCallback(
    async (searchQuery: string) => {
      const bins = await warehouseService.get<SearchBinRecord[]>(
        `/api/bins/search/${searchQuery}`
      );
      if (isAutoStoreView && selectedAutostoreGrid?.temperatureZone) {
        return bins.data.filter(
          (bin) =>
            bin.temperature_zone.toLowerCase() ===
            selectedAutostoreGrid.temperatureZone.toLowerCase()
        );
      }
      return bins.data;
    },
    [selectedAutostoreGrid?.temperatureZone, isAutoStoreView]
  );
}
