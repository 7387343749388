import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type OrderCreationState = {
  search: string | null;
  selectedSku: Guid | null;
  selectedPage: number;
  selectedAutostoreGridId: Guid | null;
  onlyAvailable: boolean;
  simplified: boolean;
};

const initialState: OrderCreationState = {
  search: null,
  selectedSku: null,
  selectedPage: 1,
  selectedAutostoreGridId: null,
  onlyAvailable: true,
  simplified: true
};

export const orderCreationSlice = createSlice({
  name: "orderCreation",
  initialState,
  reducers: {
    setSearch(state, { payload }: PayloadAction<string | null>) {
      state.search = payload;
    },
    setSelectedSku(state, { payload }: PayloadAction<string | null>) {
      state.selectedSku = payload;
    },
    setSelectedPage(state, { payload }: PayloadAction<number>) {
      state.selectedPage = payload;
    },
    setOnlyAvailable(state, { payload }: PayloadAction<boolean>) {
      state.onlyAvailable = payload;
    },
    setSelectedAutostoreGridId(state, { payload }: PayloadAction<Guid | null>) {
      state.selectedAutostoreGridId = payload;
    },
    setSimplified(state, { payload }: PayloadAction<boolean>) {
      state.simplified = payload;
    }
  }
});

export const {
  setSearch,
  setSelectedSku,
  setSelectedPage,
  setSelectedAutostoreGridId,
  setOnlyAvailable,
  setSimplified
} = orderCreationSlice.actions;
