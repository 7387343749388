import { CircularProgress, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { StartPickingModal } from "~/components/autostore/modals/StartPickingModal";
import {
  closeStartPickingModal,
  openStartPickingModal
} from "~/components/autostore/modals/startPicking.slice";

import useFlag from "~/config/flags";
import {
  getCompartmentId,
  parentPortIdFoundInWorkstation
} from "~/lib/helpers";

import { mixpanelTrackError } from "~/lib/mixpanel-tracking";

import {
  noBatchesLeftMessage,
  setCartNumberConfirmed,
  setShowTwoBinComponents,
  showPickToLight,
  startPickingOnWorkstation
} from "~/redux/actions";
import { clearUserMessage } from "~/redux/actions/site";
import { sendUserTaskSelectedEvent } from "~/redux/actions/userEvents";
import { selectUserCanPick } from "~/redux/selectors/authSelectors";
import { selectShowTwoBinComponents } from "~/redux/selectors/autostoreSelectors";
import { selectStartPickingModalIsOpen } from "~/redux/selectors/pickingSelectors";
import {
  selectFusionPortEnabled,
  selectPickToLightEnabled,
  selectUserMessages
} from "~/redux/selectors/siteSelectors";
import {
  selectWorkstationAutostoreGridId,
  selectSelectedPortId,
  selectThisWorkstation,
  selectWorkstationHasPickingRole
} from "~/redux/selectors/workstationsSelectors";

import { MainButton } from "./MainButton";

export function PickingButton() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const canUserPick = useAppSelector(selectUserCanPick);
  const fusionPortScreenEnabled = useAppSelector(selectFusionPortEnabled);
  const isPickingRoleFound = useAppSelector(selectWorkstationHasPickingRole);
  const pickToLightEnabled = useAppSelector(selectPickToLightEnabled);
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const showTwoBinComponents = useAppSelector(selectShowTwoBinComponents);
  const sitePortId = useAppSelector(selectSelectedPortId);
  const userMessages = useAppSelector(selectUserMessages);
  const startPickingModalIsOpen = useAppSelector(selectStartPickingModalIsOpen);

  const isStartPickingOnWorkstationEndpointEnabled =
    useFlag().useStartPickingOnWorkstationEndpoint;

  const [startPickingLoading, setStartPickingLoading] =
    useState<boolean>(false);

  const disablePicking = !canUserPick || !isPickingRoleFound;
  const handlePickingButtonClick = () => {
    if (!!selectedAutostoreGridId && !!sitePortId && isPickingRoleFound) {
      dispatch(openStartPickingModal());
    }
  };

  // On the Autostore Picking page, we have a part of code which redirects user
  // To the Autostore Main page if 'No batches are available to pick' error exists.
  // With new stack messages feature, we're saving all messages (including above mentioned error) and when we click
  // On the pick new batch button on the Start Picking Modal, Qubit will redirect immediately to the Autostore main page
  // Even if we have batch ready. So we need to erase this message when user tries to start picking process
  const noBatchesfoundWarningMessage = userMessages?.find(
    (message) => message.title === noBatchesLeftMessage
  );

  const handlePickClick = async () => {
    dispatch(setCartNumberConfirmed(false));
    setStartPickingLoading(true);
    try {
      let batchId: string | null = null;

      if (fusionPortScreenEnabled) {
        const enableMultiPort = parentPortIdFoundInWorkstation(
          siteWorkstation?.ports
        );

        const pickingStateLocal = await dispatch(
          startPickingOnWorkstation({
            enableMultiPort: enableMultiPort || undefined,
            isStartPickingOnWorkstationEndpointEnabledInfo:
              isStartPickingOnWorkstationEndpointEnabled
          })
        );

        if (
          pickingStateLocal &&
          pickToLightEnabled &&
          selectedAutostoreGridId &&
          sitePortId
        ) {
          const compartmentId = getCompartmentId(
            pickingStateLocal.binConfiguration?.configurationType || 1,
            pickingStateLocal.currentPicks[0]?.pickBin
              ?.autostoreCompartmentNumber || 1
          );
          await dispatch(
            showPickToLight(
              selectedAutostoreGridId,
              sitePortId,
              pickingStateLocal.currentPicks[0]?.pickBin?.autostoreBin
                ?.autostoreBinId || 1,
              compartmentId,
              pickingStateLocal.currentPicks.length
                ? pickingStateLocal.currentPicks[0].quantity.value
                : 1
            )
          );
          batchId = pickingStateLocal.batchId;
        }

        if (showTwoBinComponents !== enableMultiPort) {
          dispatch(setShowTwoBinComponents(enableMultiPort));
        }
      } else {
        const pickingStateLocal = await dispatch(
          startPickingOnWorkstation({
            isStartPickingOnWorkstationEndpointEnabledInfo:
              isStartPickingOnWorkstationEndpointEnabled
          })
        );

        batchId = pickingStateLocal?.batchId ?? null;
      }

      if (noBatchesfoundWarningMessage) {
        mixpanelTrackError("Start Pick", "no batches are available to pick"); // not the right place for this
        dispatch(clearUserMessage(noBatchesfoundWarningMessage.id));
      }

      await dispatch(sendUserTaskSelectedEvent("PICKING"));
      dispatch(closeStartPickingModal());

      return batchId;
    } finally {
      setStartPickingLoading(false);
    }
  };

  return (
    <>
      <MainButton
        alert={(!isPickingRoleFound && t("no picking role found")) || undefined}
        disabled={disablePicking}
        onClick={handlePickingButtonClick}
      >
        {t("nav.link.picking")}
        <Typography>
          {startPickingLoading && <CircularProgress size={24} />}
        </Typography>
      </MainButton>

      <StartPickingModal
        open={startPickingModalIsOpen}
        onHomeButtonClickCallback={() => {
          dispatch(closeStartPickingModal());
        }}
        onNextPickButtonClickCallback={handlePickClick}
        maxWidth="sm"
        title={`${t("start picking")}?`}
        pickButtonText={t("start")}
        closeEnabled
      />
    </>
  );
}
