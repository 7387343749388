import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export function NoOrders() {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <SentimentDissatisfiedIcon />
      </Grid>
      <Grid item>
        <Typography>{t("no orders")}</Typography>
      </Grid>
    </Grid>
  );
}
