export type PickingConfigurationOption = "Autostore" | "Manual";

export type PickingConfigurations = PickingConfigurationOption[];

export type InventoryDateConfiguration = "ExpirationDate" | "ManufactureDate";

export type ReplenishmentConfiguration = "Automatic" | "Disabled";

export type WaveCompletionConfiguration = "TotesVerified" | "BatchPicked";

export type ToteConfiguration = "GeneratedTotes" | "ExternalTotes" | "NoTotes";

export type PickScanProductConfiguration = "OncePerPick" | "OncePerQuantity";

export type PickScanConfirmableToteConfigutation = "Single" | "Multiple";

export type PickScanToteConfiguration = "OncePerPick" | "OncePerQuantity";

export type PrinterConfiguration = "DownloadZPL" | "BrowserPrint" | "PagePrint";

const pickMenuOptions = [
  "Split",
  "AdjustWeight",
  "OutOfStock",
  "AddTote",
  "PrintToteLabel",
  "PrintBulkLabel",
  "Revert"
] as const;

export type PickMenuOption = (typeof pickMenuOptions)[number];

export const parsePickMenuOption = (str: string): PickMenuOption | null =>
  pickMenuOptions.find((opt) => opt.toLowerCase() === str.toLowerCase()) ||
  null;
