import * as Sentry from "@sentry/react";
import axios from "axios";
import moment from "moment";

import { handleWarehouseError, warehouseService } from "~/api/warehouse";
import { AsyncAppThunk } from "~/app/store";

import envConstants from "~/config/envConstants";
import { debounce } from "~/hooks/useDebounce";
export interface Version {
  tag: string;
  hash: string;
}

export interface VersionCheck {
  type: "version/CHECK";
}

export interface VersionIsCurrent {
  type: "version/IS_CURRENT";
}

export interface VersionOutOfDate {
  type: "version/OUT_OF_DATE";
}

export interface GetServerVersionAction {
  type: "version/GET_SERVER_VERSION";
}

export interface GetServerVersionSuccessAction {
  type: "version/GET_SERVER_VERSION_SUCCESS";
  payload: Version;
}

export interface GetServerVersionFailureAction {
  type: "version/GET_SERVER_VERSION_FAILURE";
  payload: string;
}

// Debounce so that we wait until we are idle before reloading the page.
const debounceWindowReload = debounce(() => {
  window.location.reload();
}, 2000);

export const checkVersion =
  (lastCheckedTime: number): AsyncAppThunk =>
  async (dispatch, getState) => {
    try {
      // If we are local, don't check.
      if (!envConstants.VERSION) {
        return;
      }

      const nextVersionCheck = moment.unix(lastCheckedTime).add(15, "minutes");
      // If we haven't checked the version in 5 minutes, then check the server version.
      if (moment() < nextVersionCheck) {
        return;
      }
      dispatch<VersionCheck>({
        type: "version/CHECK"
      });

      const state = getState();
      // So that we don't cause a continual chain of requests to version.json
      // Debounce if in progress to refresh the timer since we received an action.
      if (state.version.debounceInProgress) {
        void debounceWindowReload();
        return;
      }

      const file = await axios.get<string>(`/version.json?v=${Date.now()}`, {
        headers: { "Cache-Control": "no-cache" }
      });
      const serverVersion = `${file.data}`;
      if (serverVersion.trim() === envConstants.VERSION) {
        dispatch<VersionIsCurrent>({
          type: "version/IS_CURRENT"
        });
      } else {
        try {
          const replay = Sentry.getReplay();
          replay?.start();
        } catch {
          // error if replay already started
        }
        Sentry.captureMessage(
          `Reloading because the version is out of date, local version: ${
            envConstants.VERSION
          }, server version: ${serverVersion}`,
          "debug"
        );
        void debounceWindowReload();
        dispatch<VersionOutOfDate>({
          type: "version/OUT_OF_DATE"
        });
      }
    } catch {
      // Not a big deal if this errors.
    }
  };

export const getServerVersion = (): AsyncAppThunk => async (dispatch) => {
  dispatch<GetServerVersionAction>({
    type: "version/GET_SERVER_VERSION"
  });
  try {
    const response = await warehouseService.get<Version>(
      `/version.json?v=${Date.now()}`,
      {
        headers: { "Cache-Control": "no-cache" }
      }
    );
    dispatch<GetServerVersionSuccessAction>({
      type: "version/GET_SERVER_VERSION_SUCCESS",
      payload: response.data
    });
  } catch (err) {
    handleWarehouseError(err, (message) =>
      dispatch<GetServerVersionFailureAction>({
        type: "version/GET_SERVER_VERSION_FAILURE",
        payload: message
      })
    );
  }
};
