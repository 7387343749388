import { ProductSearchProduct, ProductSearchVariant } from "~/types/api";

export type Variant = {
  IsMfcEnabled: boolean;
  Sku?: string;
  ExternalVariantId?: string;
  Name: string | null;
  PackageUnit: string | null;
  ProductVariantId: string;
};

/** Data returned from the Algolia product index */
export interface Product {
  Name: string;
  Variants: Variant[];
  Upc: string;
  AlternateLookups: string[];
}

function fromBackendVariant(variant: ProductSearchVariant): Variant {
  return {
    IsMfcEnabled: variant.isMfcEnabled,
    Sku: variant.sku,
    ExternalVariantId: variant.sku,
    Name: variant.name,
    PackageUnit: variant.packageUnit,
    ProductVariantId: variant.productVariantId
  };
}

export function fromBackendProduct(product: ProductSearchProduct): Product {
  const variants = product.variants.map(fromBackendVariant);

  const p: Product = {
    Name: product.name,
    Variants: variants,
    Upc: product.upc,
    AlternateLookups: product.alternateLookups
  };

  return p;
}
