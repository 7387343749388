import { styled } from "@mui/material/styles";
import { forwardRef } from "react";
import Barcode from "react-barcode";

export type LabelInfo = {
  toteId: Guid;
  externalOrderId: string;
  firstName: string;
  lastName?: string;
  orderWindow: string;
  position?: number;
  toteNumber: number;
  zone: string;
  batchName: string;
  putwallPosition: string;
  orderType: string;
};

type LabelProps = { labels: LabelInfo[] };

const LabelBox = styled("div")({
  pageBreakAfter: "always",
  width: "calc(100vw - 4px)",
  height: "calc(100vh - 4px)",
  border: "2px solid black",
  position: "relative"
});

function ToteLabel({ label }: { label: LabelInfo }) {
  const {
    toteId,
    firstName,
    lastName,
    externalOrderId,
    orderWindow,
    position = "",
    toteNumber,
    zone,
    batchName
  } = label;

  const externalIdString = externalOrderId?.length
    ? `(${externalOrderId})`
    : "";
  return (
    <LabelBox key={toteId}>
      <div style={{ fontSize: 10, padding: "3px 0 0 3px" }}>
        {`${firstName} ${lastName || ""} ${externalIdString} - ${batchName}`}
      </div>
      <div style={{ fontSize: 10, paddingLeft: 3 }}>{orderWindow}</div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Barcode
          value={toteId}
          width={1.3}
          height={100}
          format="CODE128"
          displayValue={false}
          textMargin={0}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 3px",
          position: "absolute",
          bottom: 24
        }}
      >
        <div style={{ textAlign: "center", fontSize: 18, paddingRight: 4 }}>
          {`Position ${position} - ${zone}`}
        </div>
        <div style={{ textAlign: "right", fontSize: 18, marginRight: 12 }}>
          {`${toteNumber} of ___`}
        </div>
      </div>
    </LabelBox>
  );
}

export const PrintableToteLabels = forwardRef<HTMLDivElement, LabelProps>(
  (props, ref) => {
    const { labels } = props;

    return (
      <div style={{ backgroundColor: "white", minHeight: "100vh" }} ref={ref}>
        {labels.map((label) => (
          <ToteLabel key={label.toteId} label={label} />
        ))}
      </div>
    );
  }
);
PrintableToteLabels.displayName = "PrintableToteLabels";

export default PrintableToteLabels;
