export function getViewType(pathname: string) {
  let viewType: "bin" | "product" | "products" = "product";
  if (pathname.includes("/bin")) {
    viewType = "bin";
  } else if (pathname.includes("/product/")) {
    viewType = "product";
  } else if (pathname.includes("/product")) {
    viewType = "products";
  }
  return viewType;
}
