import { createSelector } from "@reduxjs/toolkit";

import { AppSelector } from "~/app/store";
import { selectCurrentPickQuantity } from "~/features/autostorePicking/autostorePicking.slice";
import { StoreState } from "~/redux/reducers";

export const selectAbleQtyToPick = (state: StoreState) =>
  state.outOfStockDialog.ableToPickQty;
export const selectOutOfStockModalStatus = (state: StoreState) =>
  state.outOfStockDialog.isOpen;
export const selectOutOfStockReasonCode = (state: StoreState) =>
  state.outOfStockDialog.outOfStockReasonCode;

export const selectPickQuantityForDisplay: AppSelector<string> = createSelector(
  [selectAbleQtyToPick, selectCurrentPickQuantity],
  (ableToPickQty, currentPickQuantity) => {
    // ableToPickQty will only be truthy after out of stock "able to pick" confirmation
    const binLabel = ableToPickQty
      ? `${ableToPickQty}/${currentPickQuantity}`
      : // if ableToPickQty is null, just display the current pick quantity
        `${currentPickQuantity}`;
    return currentPickQuantity !== 0 ? binLabel : "";
  }
);
