import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { BatchSummaryDto } from "~/types/api";

type CartsState = {
  scannedBarcode: string | null;
  selectedBatch: BatchSummaryDto | null;
  selectedDate: Date;
};

const initialState: CartsState = {
  scannedBarcode: null,
  selectedBatch: null,
  selectedDate: new Date()
};

export const cartsSlice = createSlice({
  name: "carts",
  initialState,
  reducers: {
    scanBarcode(state, { payload }: PayloadAction<string | null>) {
      state.scannedBarcode = payload;
    },
    selectBatch(state, { payload }: PayloadAction<BatchSummaryDto | null>) {
      state.selectedBatch = payload;
    },
    selectDate(state, { payload }: PayloadAction<Date>) {
      state.selectedDate = payload;
      state.scannedBarcode = null;
    }
  }
});
export const { scanBarcode, selectBatch, selectDate } = cartsSlice.actions;
