import { NextBinResponse, NextEmptyBinResponse } from "~/types/api";

export const getCompartmentNumbersWithStatus = (
  currentBin: NextEmptyBinResponse | NextBinResponse | null
): {
  autostoreCompartmentNumber: number;
  isAutostoreBinCompartmentEmpty: boolean;
}[] =>
  currentBin?.autostoreBinCompartments
    .filter((b) => b.autostoreCompartmentNumber)
    .map((c) => ({
      autostoreCompartmentNumber: c.autostoreCompartmentNumber as number,
      isAutostoreBinCompartmentEmpty: c.isAutostoreBinCompartmentEmpty ?? false
    })) ?? [];
