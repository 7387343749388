import { AppDispatch } from "~/app/store";
import { workstationApi } from "./workstation";
import {
  SetSitePortIdAction,
  SetThisWorkstationAction
} from "~/redux/actions/workstations";
import { StoreState } from "~/redux/reducers";

export const {
  useGetWorkstationsQuery,
  useLazyGetWorkstationsQuery,
  useDeleteWorkstationMutation,
  usePutWorkstationMutation,
  usePostDeactivateWorkstationMutation,
  usePostActivateWorkstationMutation,
  usePostCreateWorkstationMutation,
  useGetMultiportsQuery,
  useGetUnassignedPortsQuery,
  useLazyGetUnassignedPortsQuery,
  useStartInductionMutation,
  useStartBinReconfigurationMutation,
  useConfirmBinReconfigurationMutation
} = workstationApi.enhanceEndpoints({
  endpoints: {
    getWorkstations: {
      onQueryStarted: async (_, { dispatch, queryFulfilled, ...rest }) => {
        const { data } = await queryFulfilled;
        const state = rest.getState() as StoreState;

        if (state.workstations.siteWorkstation) {
          const selectedWs = data.find(
            (w) => w.id === state.workstations.siteWorkstation?.id
          );
          // in case the row is deleted from the database, set thisWs and selectedPortId to null
          (dispatch as AppDispatch)<SetThisWorkstationAction>({
            type: "workstations/SET_THIS_WORKSTATION",
            payload: selectedWs || null
          });

          let portId = null;
          if (selectedWs) {
            const selectedWsWithMultiport = selectedWs.ports.find(
              (workstationPort) => workstationPort.parentPortId != null
            );
            if (
              selectedWsWithMultiport &&
              selectedWsWithMultiport.parentPortId
            ) {
              portId = selectedWsWithMultiport.parentPortId;
            } else {
              portId = selectedWs?.ports[0].portId;
            }
          }
          (dispatch as AppDispatch)<SetSitePortIdAction>({
            type: "workstations/SET_SITE_PORT_ID",
            payload: portId || null
          });
        }
      }
    }
  }
});
