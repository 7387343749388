import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type StageTotesState = {
  selectedToteIds: Guid[];
};

const initialState: StageTotesState = {
  selectedToteIds: []
};

export const stageTotesSlice = createSlice({
  name: "stageTotes",
  initialState,
  reducers: {
    clearSelectedToteIds(state) {
      state.selectedToteIds = [];
    },
    selectToteIds(state, { payload }: PayloadAction<Guid[]>) {
      state.selectedToteIds = payload;
    }
  },
  selectors: {
    getSelectedToteIds: (slice) => slice.selectedToteIds
  }
});

export const { clearSelectedToteIds, selectToteIds } = stageTotesSlice.actions;
export const { getSelectedToteIds } = stageTotesSlice.selectors;
