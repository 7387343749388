import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText
} from "@mui/material";

import { ModalDialogTransition } from "./ModalDialogTransition";

type BigChoiceModalProps = {
  isOpen: boolean;
  choiceTitle?: string;
  choices: {
    label: string;
    value: string;
  }[];
  selectCb: (selectedValue: string) => void;
  closeCb: () => void;
};

export function BigChoiceModal(props: BigChoiceModalProps): React.ReactElement {
  const { isOpen, choices, selectCb, closeCb, choiceTitle } = props;

  const handleSelect = (selectedValue: string): void => {
    selectCb(selectedValue);
  };

  const handleClose = (): void => {
    closeCb();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      keepMounted
      TransitionComponent={ModalDialogTransition}
    >
      {choiceTitle && <DialogTitle>{choiceTitle}</DialogTitle>}
      <List>
        {choices.map((choice) => (
          <ListItem
            button
            onClick={() => handleSelect(choice.value)}
            key={choice.value}
          >
            <ListItemText primary={choice.label} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
