import { createSelector } from "@reduxjs/toolkit";

import {
  selectAndonGrids,
  selectAndonWorkstations
} from "~/features/andon/andon.slice";
import { StoreState } from "~/redux/reducers";

import { selectThisWorkstation } from "./workstationsSelectors";

export const selectSiteAndonWorkstationStatus = (state: StoreState) =>
  state.workstations.siteWorkstationStatus;

export const selectSiteAndonWorkstation = createSelector(
  selectAndonWorkstations,
  selectThisWorkstation,
  (andonWorkstations, siteWorkstation) =>
    siteWorkstation ? andonWorkstations[siteWorkstation.id] : null
);

export const selectSiteAndonGridSystemMode = createSelector(
  selectAndonGrids,
  selectThisWorkstation,
  (andonGrids, siteWorkstation) =>
    siteWorkstation
      ? andonGrids[siteWorkstation.autostoreGridId]?.systemMode
      : null
);
