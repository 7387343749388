import ErrorIcon from "@mui/icons-material/Error";
import { Grid } from "@mui/material";

import { Heading, Placeholder } from "./tableStyles";

export interface TableInfoRowProps {
  textContent: string;
  error: boolean | null;
}

export function TableInfoRow({ textContent, error }: TableInfoRowProps) {
  return (
    <Placeholder
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      {error && (
        <Grid item>
          <ErrorIcon color="error" />
        </Grid>
      )}

      <Grid item>
        <Heading color={error ? "error" : "inherit"}>{textContent}</Heading>
      </Grid>
    </Placeholder>
  );
}
