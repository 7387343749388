import { useLocation, useSearchParams } from "react-router-dom";

type QueryParams = {
  [key: string]: string | undefined;
};

// Function to parse query string into an object
const parseQueryString = (search: string): QueryParams => {
  const params = new URLSearchParams(search);
  const obj: QueryParams = {};
  params.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
};

// Custom hook to manage tab index and synchronize it with URL query parameters
export const useTabNavLocation = (args: {
  defaultTab: string;
  validTabs: string[];
  key: string;
  subTabs?: {
    subTab: string;
    subTabKey: string;
    subTabDefault: string;
  }[];
}) => {
  const { defaultTab, validTabs, key, subTabs } = args;
  const location = useLocation();
  const [_searchParams, setSearchParams] = useSearchParams();
  const parsedSearch = parseQueryString(location.search);
  const initialTab = parsedSearch[key];
  const tabIndex =
    initialTab && validTabs.includes(initialTab) ? initialTab : defaultTab;

  const handleTabIndexChange = (newIndex: string) => {
    if (validTabs.includes(newIndex) && newIndex !== tabIndex) {
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.set(key, newIndex);
      if (subTabs) {
        subTabs.forEach((subTab) => {
          newSearchParams.delete(subTab.subTabKey);
        });
      }
      setSearchParams(newSearchParams, { replace: true });
    }
  };

  return { tabIndex, handleTabIndexChange };
};
