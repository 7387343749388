import { useState } from "react";

export const usePagination = <R>({
  rows,
  rowsPerPage
}: {
  rows: R[] | undefined;
  rowsPerPage: number;
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = rows ? Math.ceil(rows.length / rowsPerPage) : 1;
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentPageRows = rows?.slice(startIndex, endIndex) || [];

  return { currentPage, totalPages, currentPageRows, setCurrentPage };
};
