import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import {
  Stack,
  Box,
  InputLabel,
  Tooltip,
  Typography,
  FormControlLabel,
  Checkbox,
  ClickAwayListener
} from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { UpdateWorkstation } from "~/types/api";

export function WorkstationMultiportCheckbox() {
  const { t } = useTranslation();
  const [showMultiportInfo, setShowMultiportInfo] = useState(false);
  const { register, watch } = useFormContext<UpdateWorkstation>();

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        borderBottom: "1px solid black"
      }}
    >
      <Box width="100%">
        <Stack direction="row" justifyContent="space-between">
          <InputLabel shrink>{t("multiport")}</InputLabel>
          <ClickAwayListener onClickAway={() => setShowMultiportInfo(false)}>
            <Tooltip
              title={
                <Typography variant="body2">
                  {t("workstation multiport tooltip")}
                </Typography>
              }
              open={showMultiportInfo}
              onOpen={() => setShowMultiportInfo(true)}
              onClose={() => setShowMultiportInfo(false)}
              disableHoverListener
              placement="left"
            >
              <HelpOutlineIcon
                sx={{
                  cursor: "pointer",
                  color: "gray"
                }}
                onClick={() => setShowMultiportInfo(true)}
              />
            </Tooltip>
          </ClickAwayListener>
        </Stack>

        {/* This checkbox is not meant to be manually changed */}
        <FormControlLabel
          label={t("multiport configuration enabled")}
          control={
            <Checkbox
              inputProps={{ "aria-label": "multiPortEnabled" }}
              checked={watch("multiPortEnabled")}
              {...register("multiPortEnabled")}
              disabled
            />
          }
        />
      </Box>
    </Stack>
  );
}
