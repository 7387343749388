import { createSlice } from "@reduxjs/toolkit";

type StartPickingModalState = {
  startModalIsOpen: boolean;
};

const initialState: StartPickingModalState = {
  startModalIsOpen: false
};

export const startPickingSlice = createSlice({
  name: "startPicking",
  initialState,
  reducers: {
    openStartPickingModal(state) {
      state.startModalIsOpen = true;
    },
    closeStartPickingModal(state) {
      state.startModalIsOpen = false;
    }
  }
});

export const { openStartPickingModal, closeStartPickingModal } =
  startPickingSlice.actions;
