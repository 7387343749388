import { Slice, createSlice } from "@reduxjs/toolkit";
import { WebStorage, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export type ConveyanceState = {
  conveyanceSimEnabled: boolean;
};

type PersistConfigOf<T extends Slice> = {
  key: T["name"];
  storage: WebStorage;
  whitelist: (keyof ReturnType<T["getInitialState"]>)[];
};

const initialState: ConveyanceState = {
  conveyanceSimEnabled: false
};

export const conveyanceSlice = createSlice({
  name: "conveyance",
  initialState,
  reducers: {
    toggleConveyanceSimEnabled(state) {
      state.conveyanceSimEnabled = !state.conveyanceSimEnabled;
    }
  }
});

export const { toggleConveyanceSimEnabled } = conveyanceSlice.actions;

const conveyancePersistConfig: PersistConfigOf<typeof conveyanceSlice> = {
  key: "conveyance",
  storage,
  whitelist: ["conveyanceSimEnabled"]
};

export const conveyance = persistReducer(
  conveyancePersistConfig,
  conveyanceSlice.reducer
);
