import { AppBar, Box, Toolbar } from "@mui/material";
import { Outlet } from "react-router";

import autostoreDots from "~/assets/img/autostore-dots.png";
import { ErrorBoundary } from "~/components/ErrorBoundary";
import { navBarHeight } from "~/components/navbar/Navbar";

const toolbarStyle = {
  height: navBarHeight
};

export function LoginLayout() {
  return (
    <Box height="100vh">
      <AppBar sx={toolbarStyle}>
        <Toolbar>
          <Box
            style={{
              zIndex: 2,
              position: "absolute",
              right: 10,
              top: 8
            }}
          >
            <img
              src={autostoreDots}
              alt="small logo"
              style={{
                height: 30,
                background: "transparent",
                padding: "4px"
              }}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar sx={toolbarStyle} />
      <Box height={`calc(100vh - ${navBarHeight})`}>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Box>
    </Box>
  );
}
