import { batchesApi } from "./batches";

export const {
  useGetBatchesQuery,
  useGetBatchQuery,
  useLazyGetBatchQuery,
  useGetStatusCountQuery,
  usePostChangeBatchWorkstationMutation,
  usePostForcePrepareCartMutation,
  usePostAssignCartMutation,
  usePostUnassignCartMutation,
  usePostUnsuspendBatchMutation,
  useGetWaveProgressQuery
} = batchesApi;
