import { Container, styled } from "@mui/material";

import { navBarHeight } from "./navbar/Navbar";

export const ViewContainer = styled(Container)({
  // 64 for the margin
  maxHeight: `calc(100vh - ${navBarHeight} - 64px)`,
  my: 4,
  display: "grid"
});
