import moment from "moment";

import {
  GetServerVersionAction,
  GetServerVersionFailureAction,
  GetServerVersionSuccessAction,
  Version,
  VersionCheck,
  VersionIsCurrent,
  VersionOutOfDate
} from "~/redux/actions/version";

type Actions =
  | VersionCheck
  | VersionIsCurrent
  | VersionOutOfDate
  | GetServerVersionAction
  | GetServerVersionFailureAction
  | GetServerVersionSuccessAction;

export type VersionState = {
  lastCheckedTime: number;
  debounceInProgress: boolean;
  serverVersion: Version;
};

export const initialState = {
  lastCheckedTime: moment().unix(),
  debounceInProgress: false,
  serverVersion: { tag: "", hash: "" }
};

const reducer = (
  state: VersionState | undefined,
  action: Actions
): VersionState => {
  if (state === undefined) {
    return initialState;
  }
  switch (action.type) {
    case "version/IS_CURRENT":
      return {
        ...state,
        lastCheckedTime: moment().unix()
      };
    case "version/OUT_OF_DATE":
      return {
        ...state,
        debounceInProgress: true
      };
    case "version/GET_SERVER_VERSION_SUCCESS":
      return {
        ...state,
        serverVersion: action.payload
      };
    case "version/GET_SERVER_VERSION_FAILURE":
      return {
        ...state,
        serverVersion: { tag: "", hash: "" }
      };
    case "version/GET_SERVER_VERSION":
    case "version/CHECK":
    default:
      return state;
  }
};

export const version = reducer;
