import { TableHead, styled } from "@mui/material";

export const ASTableV2Header = styled(TableHead)(({ theme }) => ({
  display: "contents",
  "& th": {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    border: 0,
    position: "sticky",
    top: theme.spacing(-2),
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(-1)
  }
}));
