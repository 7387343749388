import { PortOrientation } from "~/api/portOrientation/portOrientation";

const rotateRight = <T>(array: T[][]) => {
  const result: T[][] = [];
  array.forEach((a, i) => {
    a.forEach((b, j, bb) => {
      result[bb.length - j - 1] = result[bb.length - j - 1] || [];
      result[bb.length - j - 1][i] = b;
    });
  });
  return result;
};

const rotateLeft = <T>(array: T[][]) => {
  const result: T[][] = [];
  array.forEach(function (a, i, aa) {
    a.forEach(function (b, j, _) {
      result[j] = result[j] || [];
      result[j][aa.length - i - 1] = b;
    });
  });
  return result;
};

const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  const chunkedArray: T[][] = [];
  let index = 0;

  while (index < array.length) {
    chunkedArray.push(array.slice(index, index + chunkSize));
    index += chunkSize;
  }

  return chunkedArray;
};

const compareIgnoreCase = (a?: string, b?: string) =>
  (a === undefined && b === undefined) ||
  (a !== undefined &&
    b !== undefined &&
    a.localeCompare(b, undefined, { sensitivity: "base" })) === 0;

export const orientCompartments = (
  compartments: number[],
  verticalCompartmentCount: number,
  orientation: string
) => {
  let result = compartments.toSorted((a, b) => a - b);

  if (compareIgnoreCase(PortOrientation.NORTH, orientation))
    return result.reverse();
  else {
    const compartments2D = chunkArray(compartments, verticalCompartmentCount);
    if (compareIgnoreCase(PortOrientation.EAST, orientation))
      result = rotateRight(compartments2D).flat();
    if (compareIgnoreCase(PortOrientation.WEST, orientation))
      result = rotateLeft(compartments2D).flat();
  }
  return result;
};
