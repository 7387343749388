import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { formatCustomerName } from "~/lib/helpers";

type ToteBoxProps = {
  matchingPickFirstName: string;
  matchingPickLastName: string;
  externalToteId: string | undefined;
  toteSize: string | undefined;
  showCustomerName: boolean;
};

function ToteBox(props: ToteBoxProps) {
  const { t } = useTranslation();
  const {
    matchingPickFirstName,
    matchingPickLastName,
    externalToteId,
    toteSize,
    showCustomerName
  } = props;

  return (
    <Box
      style={{
        marginTop:
          `${matchingPickFirstName} ${matchingPickLastName}`.length < 21
            ? "25px"
            : undefined
      }}
    >
      {showCustomerName && (
        <Box sx={{ fontSize: "0.7em" }}>
          <Typography sx={{ fontSize: "1.4em" }}>
            {matchingPickFirstName && matchingPickLastName
              ? formatCustomerName(
                  `${matchingPickFirstName} ${matchingPickLastName}`
                )
              : t("customer name")}
          </Typography>
        </Box>
      )}

      {(externalToteId || toteSize) && (
        <Box
          sx={{
            marginTop:
              matchingPickFirstName &&
              matchingPickLastName &&
              `${matchingPickFirstName} ${matchingPickLastName}`.length < 25
                ? "null"
                : "1vH"
          }}
        >
          <Grid container justifyContent="space-between">
            {externalToteId && (
              <Grid item sx={{ fontSize: "0.825em", fontWeight: 600 }}>
                <Typography sx={{ fontSize: "1.4em" }}>
                  {externalToteId}
                </Typography>
              </Grid>
            )}
            {toteSize && (
              <Grid item sx={{ fontSize: "0.825em", fontWeight: 600 }}>
                <Typography sx={{ fontSize: "1.4em" }}>{toteSize}</Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default ToteBox;
