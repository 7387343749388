import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import * as Sentry from "@sentry/react";

const isFetchBaseQueryError = (
  error: unknown
): error is FetchBaseQueryError => {
  return typeof error === "object" && error != null && "status" in error;
};

const isStringArray = (xs: unknown): xs is string[] =>
  Array.isArray(xs) && xs.every((v) => typeof v === "string");

const isString = (v: unknown): v is string => typeof v === "string";

const deduplicateErrorMessages = (errors: string[]): string =>
  [...new Set(errors)].join(", ");

/**
 * Returns an error message from an RTK mutation.
 */
export const getMessageFromRtkError = (error: unknown): string => {
  const defaultErrorMessage =
    "Something went wrong. Please try again later or contact support.";
  if (isFetchBaseQueryError(error)) {
    if (isStringArray(error.data)) {
      return deduplicateErrorMessages(error.data);
    } else if (isString(error.data)) {
      return error.data;
    } else {
      Sentry.captureMessage(
        `Error was not an array: ${JSON.stringify(error)}`,
        "error"
      );
      return defaultErrorMessage;
    }
  } else {
    Sentry.captureMessage(
      `Error was not known: ${JSON.stringify(error)}`,
      "warning"
    );
    return defaultErrorMessage;
  }
};
