import { handleWarehouseError, warehouseService } from "~/api/warehouse";
import { AsyncAppThunk } from "~/app/store";

type LabelFileSpec = {
  format: string;
  mimeType: string;
};

const AvailableLabelSpecs = {
  ChicagoGrocerLabels: {
    format: "CAPUTOSZEBRA6X4",
    mimeType: "application/zpl"
  }
};

export interface GetBatchLabelsAction {
  type: "batch/GET_LABELS";
}

export interface GetBatchLabelsFailureAction {
  type: "batch/GET_LABELS_FAILURE";
  payload: string;
}

export const getBatchLabels =
  (batchId: Guid): AsyncAppThunk<Blob | null> =>
  async (dispatch) => {
    dispatch<GetBatchLabelsAction>({
      type: "batch/GET_LABELS"
    });

    try {
      // TODO: Select different label specs as needed.
      const fileSpec: LabelFileSpec = AvailableLabelSpecs.ChicagoGrocerLabels;

      const response = await warehouseService.get<string>(
        `/api/labels/batch/${batchId}?format=${fileSpec.format}`
      );

      return new Blob([response.data], {
        type: fileSpec.mimeType
      });
    } catch (err) {
      handleWarehouseError(err, (message) =>
        dispatch<GetBatchLabelsFailureAction>({
          type: "batch/GET_LABELS_FAILURE",
          payload: `Error getting labels: ${message}`
        })
      );

      return null;
    }
  };
