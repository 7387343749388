import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography
} from "@mui/material";
import moment, { Duration } from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ErrorPanel } from "~/components/ErrorPanel";
import { maybePluralize } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { groupBy, dayOfWeekAsString } from "~/lib/shared";
import { useGetRecurringSchedulesQuery } from "~/redux/warehouse/recurringSchedule.hooks";
import { useGetStagingAreasQuery } from "~/redux/warehouse/stagingArea.hooks";
import { RecurringScheduleDto } from "~/types/api";

import EditRecurringScheduleModal from "./EditRecurringScheduleModal";

function startOfWindowDuration(
  recurringSchedule: RecurringScheduleDto
): Duration {
  return moment.duration(recurringSchedule.scheduleWindow?.[0] ?? 0);
}

function endOfWindowDuration(
  recurringSchedule: RecurringScheduleDto
): Duration {
  return moment.duration(recurringSchedule.scheduleWindow?.[1] ?? 0);
}

function RecurringSchedulesLoading() {
  return (
    <Stack direction="column" spacing={3}>
      {Array.from({ length: 7 }, (_, i) => (
        <Skeleton key={i} variant="rectangular" />
      ))}
    </Stack>
  );
}

export function RecurringSchedules() {
  const { t } = useTranslation();

  const {
    data: recurringSchedules,
    isFetching: isRecurringSchedulesFetching,
    error: recurringSchedulesError
  } = useGetRecurringSchedulesQuery();
  const {
    data: stagingAreas,
    isFetching: isStagingAreasFetching,
    error: stagingAreaError
  } = useGetStagingAreasQuery();

  const [recurringScheduleToEdit, setRecurringScheduleToEdit] =
    useState<RecurringScheduleDto | null>(null);

  if (stagingAreaError || recurringSchedulesError) {
    return (
      <ErrorPanel
        message={getMessageFromRtkError(
          stagingAreaError || recurringSchedulesError
        )}
      />
    );
  }

  if (
    isRecurringSchedulesFetching ||
    isStagingAreasFetching ||
    !recurringSchedules ||
    !stagingAreas
  ) {
    return <RecurringSchedulesLoading />;
  }

  function lookupStagingArea(recurringSchedule: RecurringScheduleDto) {
    const stagingArea = stagingAreas?.find(
      (sa) => sa.stagingAreaId === recurringSchedule.stagingAreaId
    );
    return stagingArea ? stagingArea.stagingAreaName : "N/A";
  }

  const batchesByDay = groupBy(
    recurringSchedules,
    (recurringWave) => dayOfWeekAsString(recurringWave.dayOfWeek) as string
  );

  const getOrderCountForDisplay = (recurringSchedule: RecurringScheduleDto) => {
    const { ordersPerWave, orderTypes, interval } = recurringSchedule;
    const numMinutes = moment.duration(interval).asMinutes();
    return `${ordersPerWave} ${orderTypes.join(" & ")} ${t(
      "orders batched every"
    )} ${numMinutes} ${t(maybePluralize("minute", +numMinutes))}`;
  };

  return (
    <Box pb="20px">
      {Object.keys(batchesByDay).map((dayOfWeek) => (
        <Accordion key={dayOfWeek}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t(dayOfWeek)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Timeline position="alternate">
              {batchesByDay[dayOfWeek]
                .sort(
                  (a, b) =>
                    startOfWindowDuration(a).milliseconds() -
                    startOfWindowDuration(b).milliseconds()
                )
                .map((recurringSchedule, i) => (
                  <TimelineItem key={recurringSchedule.recurringScheduleId}>
                    <TimelineOppositeContent>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="span"
                      >
                        {moment
                          .utc(
                            startOfWindowDuration(
                              recurringSchedule
                            ).asMilliseconds()
                          )
                          .format("hh:mm A")}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="primary">
                        <IconButton
                          aria-label={`edit-${dayOfWeek}-${i}`}
                          onClick={() => {
                            setRecurringScheduleToEdit(recurringSchedule);
                          }}
                          sx={{ width: "16px", height: "16px" }}
                        >
                          <EditIcon
                            style={{
                              color: "white",
                              width: "16px",
                              height: "16px"
                            }}
                          />
                        </IconButton>
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={3}>
                        <Box paddingX={3} paddingY={1}>
                          <Typography>
                            {getOrderCountForDisplay(recurringSchedule)}
                          </Typography>
                        </Box>
                        {recurringSchedule.stagingAreaId && (
                          <Box paddingX={3} paddingY={1}>
                            <Typography>
                              {`Assigned to staging area
                              ${lookupStagingArea(recurringSchedule)}`}
                            </Typography>
                          </Box>
                        )}
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              <TimelineItem>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    {moment
                      .utc(
                        endOfWindowDuration(
                          batchesByDay[dayOfWeek][
                            batchesByDay[dayOfWeek].length - 1
                          ]
                        ).asMilliseconds()
                      )
                      .format("hh:mm A")}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                </TimelineSeparator>
                <TimelineContent />
              </TimelineItem>
            </Timeline>
          </AccordionDetails>
        </Accordion>
      ))}

      {recurringScheduleToEdit && (
        <EditRecurringScheduleModal
          recurringSchedule={recurringScheduleToEdit}
          onClose={() => setRecurringScheduleToEdit(null)}
        />
      )}
    </Box>
  );
}
