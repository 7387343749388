import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { InventoryDto } from "~/types/api";

type BinsFlags = {
  binId: number;
  flags: string[];
};

type CycleCountsState = {
  selectedCycleCountBinId: string | null;
  selectedInventory: InventoryDto | null;
  binsFlags: BinsFlags[] | null;
  isInventoryDateFieldDirty: boolean;
};

const initialState: CycleCountsState = {
  selectedCycleCountBinId: null,
  selectedInventory: null,
  binsFlags: null,
  isInventoryDateFieldDirty: false
};

export const cycleCountsSlice = createSlice({
  name: "cycleCounts",
  initialState,
  reducers: {
    selectCycleCountBinId(state, { payload }: PayloadAction<string | null>) {
      state.selectedCycleCountBinId = payload;
      if (payload === null) {
        state.selectedInventory = null;
      }
    },
    selectInventory(state, { payload }: PayloadAction<InventoryDto | null>) {
      state.selectedInventory = payload;
    },
    setBinsFlags(state, { payload }: PayloadAction<BinsFlags | null>) {
      if (payload === null) state.binsFlags = payload;
      else {
        if (state.binsFlags === null) state.binsFlags = [];

        state.binsFlags?.push(payload);
      }
    },
    setIsInventoryDateFieldDirty(state, { payload }: PayloadAction<boolean>) {
      state.isInventoryDateFieldDirty = payload;
    }
  }
});

export const {
  selectCycleCountBinId,
  selectInventory,
  setBinsFlags,
  setIsInventoryDateFieldDirty
} = cycleCountsSlice.actions;
