import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { skipToken } from "@reduxjs/toolkit/query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import AutostoreTable from "~/components/autostore/table/AutostoreTable";
import { buildBatchStatusFilterAdmin } from "~/lib/helpers";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { useGetBatchesQuery } from "~/redux/warehouse/batches.hooks";
import { BatchSummaryDto, BatchOrderSummaryDto } from "~/types/api";

export const TaskGroupTableByBatch = () => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<Guid[]>([]);

  const selectedFulfillmentCenter = useAppSelector(
    selectUsersFulfillmentCenter
  );

  const { data: batchesResponse } = useGetBatchesQuery(
    (selectedFulfillmentCenter?.pickingConfigurations || []).includes(
      "Autostore"
    )
      ? {
          limit: 1000,
          status: buildBatchStatusFilterAdmin(selectedFulfillmentCenter)
        }
      : skipToken
  );

  return (
    <AutostoreTable<BatchSummaryDto>
      headerColNames={[t("Batch Name"), t("Status"), t("TaskGroupId")]}
      rowId={(batch: BatchSummaryDto) => `${batch.batchId}`}
      renderColumns={[
        (batch: BatchSummaryDto) => `${batch.batchName}`,
        (batch: BatchSummaryDto) => `${batch.status}`,
        (batch: BatchSummaryDto) => `${batch.taskGroupId || ""}`
      ]}
      widthOfCols={["50%", "25", "25%"]}
      headerVariant="h6"
      bodyVariant="h6"
      rowData={
        batchesResponse?.batches.filter(
          (batch) => batch.batchType === "Autostore"
        ) || []
      }
      selectedRows={selectedRows}
      selectRowCallback={(row: BatchSummaryDto) => {
        if (selectedRows.length && selectedRows.includes(`${row.batchId}`)) {
          setSelectedRows([]);
        } else {
          setSelectedRows([`${row.batchId}`]);
        }
      }}
      renderSelectedRowExpand={(row: BatchSummaryDto) => (
        <Collapse in timeout="auto" unmountOnExit>
          <Box style={{ width: "100%", border: "1px solid black" }}>
            <AutostoreTable<BatchOrderSummaryDto>
              headerColNames={["Order Id", "Customer"]}
              rowId={(innerRow: BatchOrderSummaryDto) => `${innerRow.orderId}`}
              renderColumns={[
                (innerRow: BatchOrderSummaryDto) => (
                  <Typography>{`${innerRow.orderId}`}</Typography>
                ),
                (innerRow: BatchOrderSummaryDto) => (
                  <Typography>{`${innerRow.firstName} ${innerRow.lastName}`}</Typography>
                )
              ]}
              widthOfCols={["50%", "50%"]}
              headerVariant="h5"
              bodyVariant="h6"
              rowData={row.orderSummaries}
              loading={false}
            />
          </Box>
        </Collapse>
      )}
      sort={(a: BatchSummaryDto, b: BatchSummaryDto) => {
        if (!b.taskGroupId) {
          return 1;
        }
        // if (!b.taskGroupId) return 0;
        return (a.taskGroupId || 0) - (b.taskGroupId || 0);
      }}
      reverse
      loading={false}
    />
  );
};
