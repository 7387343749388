import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CleaningBinState = {
  binFilter: string;
};

const initialState: CleaningBinState = {
  binFilter: ""
};

export const cleaningBinSlice = createSlice({
  name: "cleaningBin",
  initialState,
  reducers: {
    setBinFilter(state, { payload }: PayloadAction<string>) {
      state.binFilter = payload;
    }
  }
});
export const { setBinFilter } = cleaningBinSlice.actions;
