import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PutawayState = {
  page: number;
  search: string | null;
  selectedPutawayTasks: Guid[];
};

const initialState: PutawayState = {
  page: 1,
  search: null,
  selectedPutawayTasks: []
};

export const putawaySlice = createSlice({
  name: "putaway",
  initialState,
  reducers: {
    clearSelectedPutawayTasks(state) {
      state.selectedPutawayTasks = [];
    },
    selectPutawayTasks(state, { payload }: PayloadAction<Guid[]>) {
      state.selectedPutawayTasks = payload;
    },
    setPage(state, { payload }: PayloadAction<number>) {
      state.page = payload;
    },
    setSearch(state, { payload }: PayloadAction<string | null>) {
      state.search = payload;
    }
  }
});
export const {
  clearSelectedPutawayTasks,
  selectPutawayTasks,
  setPage,
  setSearch
} = putawaySlice.actions;
