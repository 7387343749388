import { Stack, StackProps } from "@mui/material";

export function Center(props: StackProps) {
  return (
    <Stack
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...props}
    />
  );
}
