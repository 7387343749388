type toteLabelProps = {
  zone: string;
  cartPosition: string;
  toteId: string;
  putwallPosition: string;
  customerName: string;
  orderType: string;
  externalOrderId: string;
};

type bulkLabelProps = {
  zone: string;
  loosePickId: Guid;
  customerName: string;
  productName: string;
  externalOrderId: string;
  loosePickQty: number;
  putwallPosition?: string;
};

const totePrintSettings = {
  width: 5,
  height: 2.5,
  rotate: true,
  dpi: 206
};

const bulkPrintSettings = {
  width: 3,
  height: 2,
  rotate: true,
  dpi: 206
};

const toteLabelwidthInDots =
  totePrintSettings.dpi *
  (totePrintSettings.rotate
    ? totePrintSettings.height
    : totePrintSettings.width);

const bulkLabelWidthInDots =
  bulkPrintSettings.dpi *
  (bulkPrintSettings.rotate
    ? bulkPrintSettings.height
    : bulkPrintSettings.width);

// const horizontallyCenteredZplBox = (
//   y: number,
//   width: number,
//   height: number,
//   totalWidth: number
// ): string => {
//   const x = (totalWidth - width) / 2;
//   return `^FO${x},${y}^GB${width},${height},3^FS`;
// };

export const toteLabelZPL = ({
  zone,
  cartPosition,
  toteId,
  putwallPosition,
  customerName,
  orderType,
  externalOrderId
}: toteLabelProps): string => `^XA

^FW${totePrintSettings.rotate ? "B" : "N"}

^CF0,40^FO70,0^FB${toteLabelwidthInDots},1,0,C
^FD${zone}^FS

^CF0,40^FO120,0^FB${toteLabelwidthInDots},1,0,C
^FDCart Position^FS

^FX Cart Position Box
^FO160,198^GB120,120,3^FS
^CF0,50^FO190,0^FB${toteLabelwidthInDots},1,0,C
^FD${cartPosition}^FS

^FX QR Code - Tote Id
^FO300,175
^BY4,2.0,25^BQN,2,4^FDQA,${toteId}^FS

^CF0,40^FO430,0^FB${toteLabelwidthInDots},1,0,C
^FDPutwall Position^FS

^FX Putwall Position Box
^FO470,198^GB120,120,3^FS
^CF0,50^FO505,0^FB${toteLabelwidthInDots},1,0,C
^FD${putwallPosition}^FS

^CF0,40^FO620,0^FB${toteLabelwidthInDots},1,0,C
^FD${customerName}^FS

^CF0,40^FO740,0^FB${toteLabelwidthInDots},1,0,C
^FD${orderType}^FS

^FX Barcode - external order Id
^BY2,1,100
^FO800,50^BCB,100,N^FD${externalOrderId}^FS

^CF0,40^FO920,0^FB${toteLabelwidthInDots},1,0,C
^FD${externalOrderId}^FS

^XZ`;

export const bulkLabelZPL = ({
  loosePickId,
  productName,
  customerName,
  externalOrderId,
  loosePickQty,
  putwallPosition = ""
}: bulkLabelProps): string => {
  // remove korean characters that printer cannot handle
  // eslint-disable-next-line no-control-regex
  const printableProductName = productName.split(/[^\x00-\x7F]+/)[0];

  return `^XA

^FWB

    ^CF0,30^FO20,0^FB${bulkLabelWidthInDots},1,0,C
    ^FDBulk^FS

    ^CF0,30^FO50,0^FB${bulkLabelWidthInDots},4,0,C
    ^FD${printableProductName}^FS

    ^CF0,40^FO150,0^FB${bulkLabelWidthInDots},1,0,C
    ^FD${loosePickQty}^FS

    ^FX QR Code - Tote Id
    ^FO215,30
    ^BY4,2.0,25^BQN,2,4^FDQA,${loosePickId}^FS

    ^CF0,20^FO200,240
    ^FDPutwall Position^FS

    ^FX Putwall Position Box
    ^FO220,240^GB120,120,3^FS
    ^CF0,50^FO260,275
    ^FD${putwallPosition}^FS

    ^CF0,40^FO380,0^FB${bulkLabelWidthInDots},1,0,C
    ^FD${customerName}^FS

    ^FX Barcode - external order Id
    ^BY1,1,10
    ^FO430,130^BCR,100,N^FD${externalOrderId}^FS

    ^CF0,40^FO540,0^FB${bulkLabelWidthInDots},1,0,C
    ^FD${externalOrderId}^FS



    ^XZ`;
};
