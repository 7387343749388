/* Passing the `fixedCacheKey` option to a useMutation hook will allow accessing
 * the result from another component that passes the same `fixedCacheKey`. This
 * can be useful because by default if you call a useMutation hook in two
 * different components, the results won't be shared.
 */
export const fixedCacheKeys = {
  confirmInductionButtonDecantingRate:
    "confirm-induction-button-decanting-rate",
  confirmInductionButtonPutItemAway: "confirm-induction-button-put-item-away",
  confirmMove: "CONFIRM_MOVE"
} as const;
