import { Box } from "@mui/material";

import { useAppDispatch, useAppSelector } from "~/app/store";

import LowInventoryFilters, { Filter } from "./LowInventoryFilters";
import { setFilter, setPage } from "./lowInventory.slice";

export function LowInventoryFilter() {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.lowInventory.filter);
  return (
    <Box
      id="low-inventory-nav"
      display="flex"
      flexGrow="1"
      justifyContent="center"
    >
      <LowInventoryFilters
        selectedFilter={filter}
        onFilterSelect={(filter: Filter | null) => {
          dispatch(setFilter(filter));
          dispatch(setPage(1));
        }}
        onResetFilters={() => {
          dispatch(setFilter(null));
          dispatch(setPage(1));
        }}
      />
    </Box>
  );
}
