import { PortSide } from "~/types/api";

export const parsePortSide: (_?: string | null) => PortSide = (s) => {
  switch (s?.toLowerCase()) {
    case "left":
      return "Left";
    case "right":
      return "Right";
    default:
      return "Unknown";
  }
};
