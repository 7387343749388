import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  GetDashboardAction,
  GetDashboardSuccessAction,
  GetDashboardFailureAction
} from "~/redux/actions/analytics";
import { AnalyticsDashboardDto } from "~/types/api";

export type AnalyticsState = {
  dashboard: AnalyticsDashboardDto | null;
  loading: boolean;
  error: string | null;
};

const initialState = {
  dashboard: null,
  loading: true,
  error: null
};

type Actions =
  | GetDashboardAction
  | GetDashboardSuccessAction
  | GetDashboardFailureAction;

const reducer = (
  state: AnalyticsState | undefined,
  action: Actions
): AnalyticsState => {
  if (state === undefined) {
    return initialState;
  }

  switch (action.type) {
    case "analytics/GET_DASHBOARD_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case "analytics/GET_DASHBOARD_SUCCESS":
      return {
        ...state,
        dashboard: action.payload,
        error: null,
        loading: false
      };
    default:
      return state;
  }
};

const analyticsPersistConfig = {
  key: "analytics",
  storage
};

export const analytics = persistReducer(analyticsPersistConfig, reducer);
