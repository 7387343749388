import { StoreState } from "~/redux/reducers";

export const selectOrderCreationSelectedSku = (state: StoreState) =>
  state.orderCreation.selectedSku;

export const selectOrderCreationSelectedAutostoreGridId = (state: StoreState) =>
  state.orderCreation.selectedAutostoreGridId;

export const selectOrderCreationSelectedPage = (state: StoreState) =>
  state.orderCreation.selectedPage;

export const selectOrderCreationOnlyAvailable = (state: StoreState) =>
  state.orderCreation.onlyAvailable;

export const selectOrderCreationSimplified = (state: StoreState) =>
  state.orderCreation.simplified;
