import { Paper, Stack, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { t } from "i18next";
import { Fragment } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { ProgressButton } from "~/components/ProgressButton";
import {
  ASTableV2,
  ASTableV2Body,
  ASTableV2Cell,
  ASTableV2Header,
  ASTableV2Row
} from "~/components/autostore/table";

import { ASTableV2RowCollapse } from "~/components/autostore/table/ASTableV2RowCollapse";
import { groupBy } from "~/lib/shared";
import { selectStatusSelectedAutostoreGridId } from "~/redux/selectors/statusSelectors";
import {
  useDeleteTaskGroupMutation,
  useDeleteTaskMutation,
  useGetAutostoreTasksQuery
} from "~/redux/warehouse/autostoreGrid.hooks";
import { AutostoreTaskGroupInfoExtended } from "~/types/api";

import { setSelectedTaskGroupId } from "./status.slice";

const Row = ({ row }: { row: AutostoreTaskGroupInfoExtended }) => {
  const selectedGridId = useAppSelector(selectStatusSelectedAutostoreGridId);
  const selected = useAppSelector(
    (state) => state.status.selectedTaskGroupId === row.taskGroupId
  );
  const dispatch = useAppDispatch();
  const [cancelTaskGroup] = useDeleteTaskGroupMutation();
  const [cancelTask] = useDeleteTaskMutation();

  return (
    <Fragment key={row.taskGroupId}>
      <ASTableV2Row
        expanded={selected}
        onClick={() =>
          dispatch(
            setSelectedTaskGroupId(selected ? undefined : row.taskGroupId)
          )
        }
      >
        <ASTableV2Cell>{row.taskGroupId || ""}</ASTableV2Cell>
        <ASTableV2Cell>{row.selectedPortId || ""}</ASTableV2Cell>
        <ASTableV2Cell>{row.category || ""}</ASTableV2Cell>
        <ASTableV2Cell>{row.shipment || ""}</ASTableV2Cell>
        <ASTableV2Cell>{row.batchName || ""}</ASTableV2Cell>
        <ASTableV2Cell>{row.batchStatus || ""}</ASTableV2Cell>
        <ASTableV2Cell>{row.tasks.length || ""}</ASTableV2Cell>
        <ASTableV2Cell>
          <ProgressButton
            buttonSize="small"
            emphasis="low"
            responsive
            variant="outlined"
            color="primary"
            aria-label={`cancel-task-group-id-${row.taskGroupId}`}
            onClick={async (): Promise<void> => {
              if (!selectedGridId) return;

              await cancelTaskGroup({
                taskGroupId: row.taskGroupId,
                autostoreGridId: selectedGridId
              });
            }}
            style={{
              marginRight: 10
            }}
          >
            {t("cancel task group")}
          </ProgressButton>
        </ASTableV2Cell>
      </ASTableV2Row>
      <ASTableV2RowCollapse expanded={selected}>
        <Stack direction="row" flexWrap={"wrap"} gap={2}>
          {Object.entries(groupBy(row.tasks, (t) => `${t.binId}`)).map(
            ([binId, tasks]) => (
              <Paper key={binId} sx={{ p: 2 }}>
                <Typography>Bin Id: {binId}</Typography>
                <Stack direction="row" my={1} flexGrow={1} alignItems="center">
                  {tasks.map((task) => (
                    <Fragment key={task.taskId}>
                      <Typography>Task Id: {task.taskId}</Typography>
                      <ProgressButton
                        style={{ marginLeft: 10 }}
                        buttonSize="small"
                        emphasis="high"
                        responsive
                        variant="outlined"
                        color="primary"
                        aria-label={`cancel-task-id-${task.taskId}`}
                        onClick={async (): Promise<void> => {
                          if (!selectedGridId) return;

                          await cancelTask({
                            taskId: task.taskId,
                            autostoreGridId: selectedGridId
                          });
                        }}
                      >
                        {t("cancel task")}
                      </ProgressButton>
                    </Fragment>
                  ))}
                </Stack>
              </Paper>
            )
          )}
        </Stack>
      </ASTableV2RowCollapse>
    </Fragment>
  );
};

export const TaskGroupTableByTaskGroup = () => {
  const selectedGridId = useAppSelector(selectStatusSelectedAutostoreGridId);

  const { data: autostoreTasks, isLoading: autostoreTasksIsLoading } =
    useGetAutostoreTasksQuery(selectedGridId || skipToken);

  return (
    <ASTableV2
      gridTemplateColumns="repeat(8, 1fr)"
      isLoading={autostoreTasksIsLoading}
      isEmpty={autostoreTasks?.length === 0}
    >
      <ASTableV2Header>
        <ASTableV2Row>
          <ASTableV2Cell>{t("TaskGroupId")}</ASTableV2Cell>
          <ASTableV2Cell>{t("Port")}</ASTableV2Cell>
          <ASTableV2Cell>{t("Category")}</ASTableV2Cell>
          <ASTableV2Cell>{t("Shipment")}</ASTableV2Cell>
          <ASTableV2Cell>{t("Batch Name")}</ASTableV2Cell>
          <ASTableV2Cell>{t("Batch Status")}</ASTableV2Cell>
          <ASTableV2Cell>{t("Tasks")}</ASTableV2Cell>
          <ASTableV2Cell></ASTableV2Cell>
        </ASTableV2Row>
      </ASTableV2Header>
      <ASTableV2Body>
        {(autostoreTasks || []).map((row) => (
          <Row key={row.taskGroupId} row={row} />
        ))}
      </ASTableV2Body>
    </ASTableV2>
  );
};
