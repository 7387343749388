import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { useShouldListenToGridEvents } from "~/hooks/useShouldListenToGridEvents";
import { binNotAtPort, nextEmptyBin } from "~/redux/actions/autostore";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";

import {
  setIsCreateInventoryButtonEnabled,
  setIsFetchingBin,
  setPortPollingActive
} from "./inventory.slice";

/**
 * Requests the next empty bin from the grid.
 * Starts port polling.
 */
export function useNextEmptyBin() {
  const dispatch = useAppDispatch();

  const shouldListenToGridEvents = useShouldListenToGridEvents();
  const isFetchingBin = useAppSelector(
    (state) => state.inventoryNew.isFetchingBin
  );
  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );

  return useCallback(
    async (portId?: number) => {
      if (!selectedAutostoreGridId) return;
      try {
        await dispatch(
          nextEmptyBin({
            ...(portId && { portId })
          })
        );
        dispatch(setIsCreateInventoryButtonEnabled(false));
        if (!isFetchingBin) {
          dispatch(setIsFetchingBin(true));
        }
        if (!shouldListenToGridEvents) {
          dispatch(setPortPollingActive(true));
        } else {
          dispatch(binNotAtPort());
        }
      } catch {
        if (!shouldListenToGridEvents) {
          dispatch(setIsCreateInventoryButtonEnabled(true));
          dispatch(setPortPollingActive(false));
        }
      }
    },
    [dispatch, isFetchingBin, selectedAutostoreGridId, shouldListenToGridEvents]
  );
}
