import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  fontWeight: "bolder"
}));

export const Placeholder = styled(Grid)(() => ({
  padding: "16px",
  textAlign: "center",
  "& svg": { margin: "0 4px 0 0" }
}));
