import { Box, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const AutostoreBinNumberStyled = styled(Box)(({ theme: { palette } }) => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  textAlign: "center",
  color: palette.common.white,
  background: "rgba(0, 0, 0, 0.52)"
}));

export const AutostoreBinNumber = ({ binNumber }: { binNumber: number }) => {
  const { t } = useTranslation();
  return (
    <AutostoreBinNumberStyled zIndex={10}>
      <Typography variant="h5">
        {t("bin")} {binNumber}
      </Typography>
    </AutostoreBinNumberStyled>
  );
};
