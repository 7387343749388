import Button from "@locaisolutions/button";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import FlagBinModal from "~/features/autostorePutaway/modals/FlagBinModal";
import { selectPortStateByPort } from "~/redux/selectors/autostoreSelectors";
import {
  selectNextEmptyBinByPort,
  selectThisWorkstation
} from "~/redux/selectors/workstationsSelectors";
import { BinMovementAssignment, InventoryMovementResponse } from "~/types/api";

export function FlagBinButton({
  currentBins,
  selectedRow,
  disabled,
  handleCleanup
}: {
  currentBins?: InventoryMovementResponse;
  selectedRow?: BinMovementAssignment;
  disabled?: boolean;
  handleCleanup?: () => void;
}) {
  const { t } = useTranslation();

  const [flagBinModalOpen, setFlagBinModalOpen] = useState(false);
  const workstation = useAppSelector(selectThisWorkstation);
  const nextEmptyBinByPort = useAppSelector(selectNextEmptyBinByPort);
  const portStateByPort = useAppSelector(selectPortStateByPort);

  return (
    <>
      <Button
        data-testid="flag-bin-button"
        size="large"
        onClick={() => setFlagBinModalOpen(true)}
        disabled={disabled}
        sx={{ fontWeight: "normal" }}
      >
        {t("flag bin")}
      </Button>

      <FlagBinModal
        isOpen={flagBinModalOpen}
        onClose={() => setFlagBinModalOpen(false)}
        nextEmptyBinByPort={nextEmptyBinByPort}
        portStateByPort={portStateByPort}
        workstation={workstation}
        shouldCloseBinUponSubmit
        destinationBin={currentBins?.destinationBin}
        currentBins={currentBins}
        selectedRow={selectedRow}
        handleCleanup={handleCleanup}
      />
    </>
  );
}
