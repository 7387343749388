import { Box } from "@mui/material";

import ReactJson from "react-json-view";

import { useAppSelector } from "~/app/store";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";

const ClientConfigurations = () => {
  const clientConfig = useAppSelector(selectClientConfig);

  return (
    <Box
      sx={{
        backgroundColor: "gray.light",
        p: 3,
        borderRadius: 1,
        maxWidth: 600,
        m: 3
      }}
      data-testid="client-config-list"
    >
      <ReactJson
        displayDataTypes={false}
        quotesOnKeys={false}
        src={clientConfig}
        indentWidth={4}
        style={{
          fontSize: 16
        }}
        enableClipboard
        name={null}
        shouldCollapse={(field) => !!field.name}
      />
    </Box>
  );
};

export default ClientConfigurations;
