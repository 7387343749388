import { AppThunk } from "~/app/store";

export interface GetDefaultPrinterAction {
  type: "printer/GET_DEFAULT_PRINTER";
}

export interface GetDefaultPrinterSuccessAction {
  type: "printer/GET_DEFAULT_PRINTER_SUCCESS";
  payload: Device;
}

export interface GetDefaultPrinterFailureAction {
  type: "printer/GET_DEFAULT_PRINTER_FAILURE";
  payload: string;
}

export interface GetPrinterStatusAction {
  type: "printer/GET_PRINTER_STATUS";
}

export interface GetPrinterStatusSuccessAction {
  type: "printer/GET_PRINTER_STATUS_SUCCESS";
}

export interface GetPrinterStatusFailureAction {
  type: "printer/GET_PRINTER_STATUS_FAILURE";
  payload: string;
}

export interface PrintToDefaultPrinterAction {
  type: "printer/PRINT_TO_DEFAULT";
}

export interface PrintToDefaultPrinterSuccessAction {
  type: "printer/PRINT_TO_DEFAULT_SUCCESS";
}

export interface PrintToDefaultPrinterFailureAction {
  type: "printer/PRINT_TO_DEFAULT_FAILURE";
  payload: string;
}

export interface ClearErrorMessageAction {
  type: "printer/CLEAR_ERROR_MESSAGE";
}

export const getDefaultPrinter = (): AppThunk => (dispatch) => {
  dispatch<GetDefaultPrinterAction>({
    type: "printer/GET_DEFAULT_PRINTER"
  });
  BrowserPrint.getDefaultDevice(
    "printer",
    (printer) => {
      dispatch<GetDefaultPrinterSuccessAction>({
        type: "printer/GET_DEFAULT_PRINTER_SUCCESS",
        payload: printer
      });
    },
    () => {
      dispatch<GetDefaultPrinterFailureAction>({
        type: "printer/GET_DEFAULT_PRINTER_FAILURE",
        payload: "No default device found."
      });
    }
  );
};

export const getPrinterStatus =
  (printer: Device): AppThunk =>
  (dispatch) => {
    dispatch<GetPrinterStatusAction>({
      type: "printer/GET_PRINTER_STATUS"
    });

    // const zPrinter = new Zebra.Printer(printer);
    // zPrinter.getStatus((status) => console.log({ status }));
    // zPrinter.isPrinterReady(
    //   (status) => console.log({ status }),
    //   (e) => console.log({ e })
    // );
    // zPrinter.getConfiguration(
    //   (status) => console.log({ status }),
    //   (e) => console.log({ e })
    // );

    printer.sendThenReadAllAvailable(
      "~HQES",
      (text: string) => {
        let error;
        const isError = text.charAt(70) !== "0";
        const media = text.charAt(88);
        const head = text.charAt(87);
        const pause = text.charAt(84);
        if (!isError) {
          dispatch<GetPrinterStatusSuccessAction>({
            type: "printer/GET_PRINTER_STATUS_SUCCESS"
          });
        }
        if (media === "1") error = "Paper out";
        if (media === "2") error = "Ribbon Out";
        if (media === "4") error = "Media Door Open";
        if (media === "8") error = "Cutter Fault";
        if (head === "1") error = "Printhead Overheating";
        if (head === "2") error = "Motor Overheating";
        if (head === "4") error = "Printhead Fault";
        if (head === "8") error = "Incorrect Printhead";
        if (pause === "1") error = "Printer Paused";
        if (isError) {
          dispatch<GetPrinterStatusFailureAction>({
            type: "printer/GET_PRINTER_STATUS_FAILURE",
            payload: error || "Error: Unknown Error"
          });
        }
      },
      () =>
        dispatch<GetPrinterStatusFailureAction>({
          type: "printer/GET_PRINTER_STATUS_FAILURE",
          payload: "Failed To Connect To Printer"
        })
    );
  };

export const printToDefaultPrinter =
  (zpl: string, printer?: Device): AppThunk =>
  (dispatch) => {
    dispatch<PrintToDefaultPrinterAction>({
      type: "printer/PRINT_TO_DEFAULT"
    });
    if (printer) {
      printer.send(
        zpl,
        () => {
          dispatch<PrintToDefaultPrinterSuccessAction>({
            type: "printer/PRINT_TO_DEFAULT_SUCCESS"
          });
        },
        () => {
          dispatch<PrintToDefaultPrinterFailureAction>({
            type: "printer/PRINT_TO_DEFAULT_FAILURE",
            payload: "Failed to print."
          });
        }
      );
    } else {
      BrowserPrint.getDefaultDevice(
        "printer",
        (defaultPrinter) => {
          defaultPrinter.send(
            zpl,
            () => {
              dispatch<PrintToDefaultPrinterSuccessAction>({
                type: "printer/PRINT_TO_DEFAULT_SUCCESS"
              });
            },
            () => {
              dispatch<PrintToDefaultPrinterFailureAction>({
                type: "printer/PRINT_TO_DEFAULT_FAILURE",
                payload: "Failed to find printer."
              });
            }
          );
        },
        () => {
          dispatch<PrintToDefaultPrinterFailureAction>({
            type: "printer/PRINT_TO_DEFAULT_FAILURE",
            payload: "No default device found."
          });
        }
      );
    }
  };

export const clearErrorMessage = (): AppThunk => (dispatch) => {
  dispatch<ClearErrorMessageAction>({
    type: "printer/CLEAR_ERROR_MESSAGE"
  });
};
