import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Box, ButtonBase, TextField } from "@mui/material";
import Autocomplete, {
  AutocompleteRenderOptionState
} from "@mui/material/Autocomplete";
import { styled, alpha } from "@mui/material/styles";
import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useDebounceSearch } from "~/hooks/useDebounce";

export const SearchIconBox = styled(Box)(({ theme }) => ({
  py: 0,
  px: theme.spacing(2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.secondary.contrastText
}));

export const Search = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  maxWidth: "800px",
  flexGrow: 1
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  "& input[type='text']": {
    padding: "8px 8px 8px 0",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}) !important `,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: theme.palette.secondary.contrastText
  }
}));

const SearchNav = styled(Search)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto"
  }
}));

export type SearchCallback = (searchText: string | null) => void;
export type AutocompleteLabelConstructor<TSearchEntity> = (
  searchResult: TSearchEntity
) => string;
export type AutocompleteMatcher<TSearchEntity> = (
  option: TSearchEntity,
  value: TSearchEntity
) => boolean;
export type Select<TSearchEntity> = (selectedOption: TSearchEntity) => void;

export type NavBarProps<TSearchEntity> = {
  textInput: string;
  setTextInput: (text: string) => void;
  searchPlaceholder?: string;
  isAutocomplete?: boolean;
  autocompleteSearchCb?: SearchCallback;
  autocompleteOptions?: TSearchEntity[];
  autocompleteLabelConstructor?: AutocompleteLabelConstructor<TSearchEntity>;
  autocompleteMatcher?: AutocompleteMatcher<TSearchEntity>;
  selectCb?: Select<TSearchEntity>;
  groupBy?: (option: TSearchEntity) => string;
  hideOptions?: boolean;
  inputChangeCb?: SearchCallback;
  submitCb?: SearchCallback;
  onLeaveInputTextShown?: boolean;
  isSearchRefreshed?: boolean;
  isSearchRefreshedCallback?: (isSearchRefreshed: boolean) => void;
  /** If false, search will not clear when autocomplete input loses focus */
  clearOnBlur?: boolean;
  clearText?: boolean;
  clearTextCb?: (s: boolean) => void;
  clearCb?: () => void;
  clearTextOnSelect?: boolean;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: TSearchEntity,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  autofocus?: boolean;
};

function NavSearchInput<TSearchEntity>(props: NavBarProps<TSearchEntity>) {
  const { t } = useTranslation();

  const {
    textInput,
    setTextInput,
    isAutocomplete,
    searchPlaceholder,
    autocompleteSearchCb,
    autocompleteOptions,
    autocompleteLabelConstructor,
    autocompleteMatcher,
    selectCb,
    groupBy,
    hideOptions = false,
    inputChangeCb,
    submitCb,
    onLeaveInputTextShown,
    isSearchRefreshed,
    isSearchRefreshedCallback,
    clearOnBlur,
    clearText,
    clearTextCb,
    clearCb,
    clearTextOnSelect = false,
    renderOption,
    autofocus = true
  } = props;
  const debouncedInput = useDebounceSearch(textInput, 500);

  useEffect(() => {
    if (clearText && clearTextCb) {
      setTextInput("");
      clearTextCb(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearText]);

  useEffect(() => {
    if (autocompleteSearchCb && debouncedInput) {
      autocompleteSearchCb(debouncedInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput]);

  // If user clicks 'Refresh' option from navbar options
  // Search input field should be erased
  useEffect(() => {
    if (isSearchRefreshed && isSearchRefreshedCallback) {
      setTextInput("");
      isSearchRefreshedCallback(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchRefreshed]);

  const handleClearButton = () => {
    setTextInput("");
    if (clearCb) clearCb();
  };

  return (
    <SearchNav
      id="navbar-search-input-container"
      sx={{
        backgroundColor: "white"
      }}
    >
      <Box
        sx={{
          py: 0,
          px: 2,
          height: "100%",
          position: "absolute",
          pointerEvents: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
          color: "darkGray.dark"
        }}
      >
        <SearchIcon />
      </Box>
      <Autocomplete<TSearchEntity>
        id="nav-search-input"
        options={autocompleteOptions || []}
        getOptionLabel={autocompleteLabelConstructor}
        fullWidth
        sx={{
          minWidth: 240,
          "& .MuiAutocomplete-inputRoot": {
            paddingRight: "0 !important"
          }
        }}
        size="small"
        autoHighlight
        clearOnBlur={clearOnBlur}
        isOptionEqualToValue={autocompleteMatcher}
        PopperComponent={!isAutocomplete ? () => null : undefined}
        onChange={(
          e: ChangeEvent<unknown>,
          option: string | TSearchEntity | null
        ): void => {
          if (e && option && selectCb && typeof option !== "string") {
            selectCb(option);
            if (clearTextOnSelect) {
              setTextInput("");
            } else if (autocompleteLabelConstructor) {
              setTextInput(autocompleteLabelConstructor(option));
            }
          }
        }}
        onInputChange={(_event, newInputValue, reason) => {
          // reasons: input, reset, clear
          if (reason === "clear" || newInputValue === "") {
            if (autocompleteSearchCb) {
              autocompleteSearchCb(null);
            }

            if (!onLeaveInputTextShown) {
              setTextInput("");
            }
          }
          if (inputChangeCb && reason !== "reset") {
            inputChangeCb(newInputValue);
          }
        }}
        inputValue={textInput}
        noOptionsText={debouncedInput?.length ? t("no results") : t("loading")}
        loadingText={t("loading")}
        groupBy={groupBy}
        popupIcon={null}
        open={hideOptions ? false : undefined}
        filterOptions={(ops) => ops} // this essentially disables the material-ui autocomplete filtering, in favor of our own api search or algolia query
        renderInput={(params) => (
          <StyledInput
            {...params}
            onChange={(e) => {
              // remove check value from zebra scans ("*")
              setTextInput(e.target.value.replace("*", ""));
            }}
            onKeyPress={(ev) => {
              if (ev.key === "Enter" && submitCb) {
                submitCb(textInput);
                ev.preventDefault();
              }
            }}
            placeholder={searchPlaceholder}
            fullWidth
            variant="outlined"
            autoFocus={autofocus}
          />
        )}
        renderOption={renderOption || undefined}
      />
      {clearCb && textInput && (
        <Box
          position="absolute"
          p={1}
          right={0}
          top={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ButtonBase
            aria-label="clear search"
            sx={{ color: "darkGray.dark" }}
            onClick={() => handleClearButton()}
          >
            <CloseIcon />
          </ButtonBase>
        </Box>
      )}
    </SearchNav>
  );
}

export default NavSearchInput;
