import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { InventoryMovementResponse } from "~/types/api";

type ManageFlaggedInventoryState = {
  currentBins: InventoryMovementResponse | undefined;
};

const initialState: ManageFlaggedInventoryState = {
  currentBins: undefined
};

export const manageFlaggedInventorySlice = createSlice({
  name: "manageFlaggedInventory",
  initialState,
  reducers: {
    setCurrentBins(
      state,
      action: PayloadAction<InventoryMovementResponse | undefined>
    ) {
      state.currentBins = action.payload;
    }
  }
});

export const { setCurrentBins } = manageFlaggedInventorySlice.actions;
