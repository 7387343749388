import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { OrderTypeAutostoreCategoryDto } from "~/types/api";

type State = {
  isDialogOpened: boolean;
  orderTypeToDelete: OrderTypeAutostoreCategoryDto | null;
};

const initialState: State = {
  isDialogOpened: false,
  orderTypeToDelete: null
};

export const workstationOrderTypesSlice = createSlice({
  name: "workstationOrderTypes",
  initialState,
  reducers: {
    openWorkstationOrderTypesDialog(state) {
      state.isDialogOpened = true;
    },
    closeWorkstationOrderTypesDialog(state) {
      state.isDialogOpened = false;
    },
    setOrderTypeToDelete(
      state,
      { payload }: PayloadAction<OrderTypeAutostoreCategoryDto>
    ) {
      state.orderTypeToDelete = payload;
    },
    clearOrderTypeToDelete(state) {
      state.orderTypeToDelete = null;
    }
  },
  selectors: {
    selectIsWorkstationOrderTypesDialogOpen: (state: State) =>
      state.isDialogOpened,
    selectWorkstationOrderTypeToDelete: (state: State) =>
      state.orderTypeToDelete
  }
});

export const {
  openWorkstationOrderTypesDialog,
  closeWorkstationOrderTypesDialog,
  setOrderTypeToDelete,
  clearOrderTypeToDelete
} = workstationOrderTypesSlice.actions;

export const {
  selectIsWorkstationOrderTypesDialogOpen,
  selectWorkstationOrderTypeToDelete
} = workstationOrderTypesSlice.selectors;
