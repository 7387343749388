import {
  Card,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ProgressButton } from "~/components/ProgressButton";

import { Root } from "./Root";

type SelectorModalProps = {
  open: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg";
  closeModalCallback: (isClosedModal: boolean) => void;
  options: HoldType[];
  confirmCb: (
    holdType: HoldType,
    selectedCompartment?: string
  ) => Promise<void> | void;
  confirmButtonText: string;
  currentHold?: string;
};

/** Modal to add or remove Inventory Holds */
export function SelectorModal(props: SelectorModalProps) {
  const {
    open,
    maxWidth,
    closeModalCallback,
    options,
    confirmCb,
    confirmButtonText,
    currentHold
  } = props;
  const { t } = useTranslation();

  const [holdText, setHoldText] = useState<string | null>(null);
  const [holdReasonCode, setHoldReasonCode] = useState<string | null>(null);

  const [isSetHoldButtonDisabled, setIsSetHoldButtonDisabled] = useState(false);

  useEffect(() => {
    if (!holdText || !holdReasonCode) {
      setIsSetHoldButtonDisabled(true);
    } else {
      setIsSetHoldButtonDisabled(false);
    }
  }, [holdText, holdReasonCode, setIsSetHoldButtonDisabled]);

  useEffect(() => {
    // reset hold type when modal is closed
    if (!open) {
      setHoldText(null);
      setHoldReasonCode(null);
    }
  }, [open]);

  const getDropdownValue = () => {
    // Only return a value if there are options to avoid a MUI warning
    if (options?.length) {
      return holdText || currentHold || "";
    } else {
      return "";
    }
  };

  return (
    <Root open={open} onClose={closeModalCallback} maxWidth={maxWidth}>
      <Card>
        <DialogTitle sx={{ mb: 3 }}>
          {t("bin and inventory problems")}
        </DialogTitle>
        <DialogContent sx={{ overflowY: "hidden" }}>
          <Grid container direction="column">
            <Grid container item justifyContent="center">
              <TextField
                data-testid="hold-bin-dropdown"
                value={getDropdownValue()}
                onChange={(e) => setHoldText(e.target.value)}
                select
                label={t("hold type")}
                variant="outlined"
                style={{ width: "70%" }}
                inputProps={{
                  style: { fontSize: 30 }
                }}
              >
                {options.map((option, index) => (
                  <MenuItem
                    onClick={(e) => {
                      const liElement = (e.target as Element).closest("li");
                      const reasonCode =
                        liElement?.getAttribute("data-reasoncode");

                      setHoldReasonCode(reasonCode || holdText);
                    }}
                    data-testid={`hold-bin-dropdown-${index}`}
                    data-reasoncode={option.reasonCode}
                    key={option.text.toLocaleLowerCase()}
                    value={option.text.toLocaleLowerCase()}
                    style={{ padding: "1.5em 0 1.5em 0.5em" }}
                  >
                    <Typography>{t(option.reasonCode)}</Typography>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              container
              style={{
                marginTop: 32
              }}
              justifyContent="flex-end"
            >
              <Grid item>
                <ProgressButton
                  data-testid="set-hold-button"
                  buttonSize="large"
                  emphasis="high"
                  responsive
                  variant="contained"
                  color="primary"
                  onClick={(): void => {
                    if (holdText && holdReasonCode)
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                      confirmCb({
                        text: holdText,
                        reasonCode: holdReasonCode,
                        source: "internal"
                      });
                  }}
                  fullWidth
                  disabled={isSetHoldButtonDisabled}
                >
                  {confirmButtonText}
                </ProgressButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Card>
    </Root>
  );
}
