export type Auth0Profile = {
  sub: string;
  // eslint-disable-next-line camelcase
  client_access?: {
    [clientId: string]: {
      clientName: string;
    };
  };
  name: string;
  userId: Guid;
  roles: string[];
  "/client_ids": Guid[];
  // eslint-disable-next-line camelcase
  login_path: string;
  // eslint-disable-next-line camelcase
  custom_login_path?: string;
};

export function isClientAdmin(profile: Auth0Profile): boolean {
  return profile.roles.some((role) => role === "client-administrator");
}

export const canPick = (profile: Auth0Profile): boolean =>
  profile.roles.some(
    (role) =>
      role === "powerpick-picker" ||
      role === "admin" ||
      role === "client-administrator"
  );
export const canDecant = (profile: Auth0Profile): boolean =>
  profile.roles.some(
    (role) =>
      role === "powerpick-receiver" ||
      role === "admin" ||
      role === "client-administrator"
  );
export const canCycleCount = (profile: Auth0Profile): boolean =>
  profile.roles.some(
    (role) =>
      role === "powerpick-inventory-manager" ||
      role === "admin" ||
      role === "client-administrator"
  );

export function getUserClientId(profile: Auth0Profile | null): string | null {
  if (
    profile &&
    profile["/client_ids"] &&
    Array.isArray(profile["/client_ids"]) &&
    profile["/client_ids"].length
  ) {
    return profile["/client_ids"][0];
  }

  return null;
}
