// private route wrapper for redirects
import { PropsWithChildren, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { clearEventData } from "~/features/andon/andon.slice";
import { logout } from "~/features/login/login.slice";
import useCloseWorkstationOnLocationChange from "~/hooks/useCloseWorkstationOnLocationChange";
import useModeUpdate from "~/hooks/useModeUpdate";
import { mixpanelLogout } from "~/lib/mixpanel-tracking";
import { selectUsersClientId } from "~/redux/selectors/authSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

import { useAppDispatch, useAppSelector } from "./store";

export function PrivateRoute({ children }: PropsWithChildren) {
  // hooks
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loginPath = useAppSelector((state) => state.site.loginPath);
  const customLoginPath = useAppSelector((state) => state.site.customLoginPath);

  const isUserLoggedIn = useAppSelector((state) => state.login.isUserLoggedIn);
  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const clientId = useAppSelector(selectUsersClientId);

  // post a mode string to warehouse based on path
  useModeUpdate(location.pathname);
  useCloseWorkstationOnLocationChange();

  const userHasAnFc = !!usersFulfillmentCenter;
  const loginPathToUse: string = loginPath || "/login";
  const customLogoutUrl: string | null | undefined = customLoginPath;

  // log out: this is where 0auth logout takes place
  // 1) when the user clicks "log out" in Navbar
  //    and user state is cleared by "handleLogout" in Navbar
  // 2) when the token expires and user state is cleared
  //    by axios interceptor "applyRedirectOn401Response" in shared.ts
  useEffect(() => {
    void (async () => {
      // when the user logs out willingly, isAuthenticated will still be true, so we depend upon clearing the client ID
      // to trigger the IdP logout
      if (!isUserLoggedIn || !clientId) {
        mixpanelLogout();
        dispatch(clearEventData());
        if (customLogoutUrl) {
          await dispatch(
            logout({
              // If this value exists then we can count on it populating
              // all necessary data from Auth0 Action.  That's why Client Id is
              // not included in this param.
              logoutParams: {
                returnTo: `${customLogoutUrl}`
              }
            })
          );
        } else {
          await dispatch(
            logout({
              logoutParams: {
                returnTo: `${window.location.origin}${loginPathToUse}`,
                federated: true
              }
            })
          );
        }
        // If neither of those redirected properly, go ahead and redirect back to the login path
        navigate(loginPathToUse);
      }
    })();
  }, [
    isUserLoggedIn,
    customLogoutUrl,
    loginPathToUse,
    navigate,
    clientId,
    dispatch
  ]);

  if (
    !userHasAnFc &&
    location.pathname.toLowerCase() !== "/settings" &&
    location.pathname.toLowerCase() !== "/logout"
  ) {
    return <Navigate to="/settings" replace />;
  }

  return <>{children}</>;
}
