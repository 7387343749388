import Box from "@mui/material/Box";
import ReactJson from "react-json-view";

import { useAppSelector } from "~/app/store";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

export const FcConfigList = () => {
  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);

  return (
    <Box
      sx={{
        backgroundColor: "gray.light",
        p: 3,
        borderRadius: 1,
        maxWidth: 600,
        m: 3
      }}
      data-testid="fc-config-tab"
    >
      {usersFulfillmentCenter && (
        <Box data-testid="fc-config-container">
          <ReactJson
            displayDataTypes={false}
            quotesOnKeys={false}
            src={usersFulfillmentCenter}
            indentWidth={4}
            style={{
              fontSize: 16
            }}
            enableClipboard
            name={null}
            shouldCollapse={(field) => !!field.name}
          />
        </Box>
      )}
    </Box>
  );
};
