import { parse, stringify } from "qs";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppSelector } from "~/app/store";

import {
  selectThisWorkstation,
  selectWorkstationHasInventoryRole
} from "~/redux/selectors/workstationsSelectors";

import { MainButton } from "./MainButton";

export type Props = {
  route: string;
};
export function InventoryButton({ route }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search }: { search: string } = useLocation();

  const isInventoryRoleFound = useAppSelector(
    selectWorkstationHasInventoryRole
  );

  const hasWorkstation = !!useAppSelector(selectThisWorkstation);

  const onInventoryButtonClicked = () => {
    if (isInventoryRoleFound || !hasWorkstation) {
      navigate({
        pathname: route,
        search: stringify({
          ...parse(search, { ignoreQueryPrefix: true }),
          autostore: true
        })
      });
    }
  };

  return (
    <MainButton
      alert={
        (!isInventoryRoleFound && t("no inventory role found")) || undefined
      }
      onClick={onInventoryButtonClicked}
      variant="md"
      disabled={!isInventoryRoleFound && hasWorkstation}
    >
      {t("nav.link.inventory")}
    </MainButton>
  );
}
