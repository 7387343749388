import { skipToken } from "@reduxjs/toolkit/query";

import { useAppSelector } from "~/app/store";
import { useGetInventorySummariesQuery } from "~/redux/warehouse/inventory.hooks";

export const useInventorySummariesQuery = () => {
  const autostorePreviousBin = useAppSelector(
    (state) => state.autostore.previousBin
  );

  return useGetInventorySummariesQuery(
    autostorePreviousBin?.autostoreBinGuid
      ? {
          binId: [autostorePreviousBin.autostoreBinGuid]
        }
      : skipToken,
    { refetchOnMountOrArgChange: true }
  );
};
