import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  UpdateUsersFulfillmentCenterAction,
  UpdateUsersFulfillmentCenterSuccessAction,
  UpdateUsersFulfillmentCenterFailureAction,
  FetchFulfillmentCenterAction,
  FetchFulfillmentCenterSuccessAction,
  FetchFulfillmentCenterFailureAction,
  ClearFulfillmentCenterAction
} from "~/redux/actions/store";
import { FulfillmentCenterDto } from "~/types/api";

export type StoreState = {
  usersFulfillmentCenter: FulfillmentCenterDto | null;
};

const initialState = {
  usersFulfillmentCenter: null
};

type Actions =
  | FetchFulfillmentCenterAction
  | FetchFulfillmentCenterSuccessAction
  | FetchFulfillmentCenterFailureAction
  | ClearFulfillmentCenterAction
  | UpdateUsersFulfillmentCenterAction
  | UpdateUsersFulfillmentCenterSuccessAction
  | UpdateUsersFulfillmentCenterFailureAction;

const reducer = (
  state: StoreState | undefined,
  action: Actions
): StoreState => {
  if (state === undefined) {
    return initialState;
  }

  switch (action.type) {
    case "store/FETCH_FULFILLMENT_CENTER_SUCCESS":
    case "store/UPDATE_USERS_FULFILLMENT_CENTER_SUCCESS":
      return { ...state, usersFulfillmentCenter: action.payload };
    case "store/CLEAR_FULFILLMENT_CENTER":
      return { ...state, usersFulfillmentCenter: null };

    default:
      return state;
  }
};

const storePersistConfig = {
  key: "store",
  storage,
  whitelist: ["usersFulfillmentCenter"]
};

export const store = persistReducer(storePersistConfig, reducer);
