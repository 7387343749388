import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { BatchStatus, BatchType, OrderType } from "~/features/batch/batch.type";
import { TempZone } from "~/redux/actions/batch";
import { StoreState } from "~/redux/reducers";
import { BatchSummaryDto } from "~/types/api";

type PickBatchesState = {
  availableBatchStatuses: BatchStatus[];
  availableBatchTypes: BatchType[];
  batchStatuses: BatchStatus[];
  batchTypes: BatchType[];
  orderType: OrderType | null;
  page: number;
  search: string | null;
  selectedBatch: BatchSummaryDto | null;
  tempZones: TempZone[];
  toteId: Guid | null;
};

const initialState: PickBatchesState = {
  availableBatchStatuses: [],
  availableBatchTypes: [],
  batchStatuses: [],
  batchTypes: [],
  orderType: null,
  page: 1,
  search: null,
  selectedBatch: null,
  tempZones: [],
  toteId: null
};

export const pickBatchesSlice = createSlice({
  name: "pickBatches",
  initialState,
  reducers: {
    resetFilters(
      state,
      {
        payload
      }: PayloadAction<{
        batchStatuses: BatchStatus[];
        batchTypes: BatchType[];
      }>
    ) {
      state.batchStatuses = payload.batchStatuses;
      state.batchTypes = payload.batchTypes;
      state.orderType = null;
      state.tempZones = [];
    },
    resetSelectedBatch(state) {
      state.selectedBatch = null;
    },
    selectBatch(state, { payload }: PayloadAction<BatchSummaryDto>) {
      state.selectedBatch = payload;
    },
    setAvailableFilters(
      state,
      {
        payload
      }: PayloadAction<{
        batchStatuses: BatchStatus[];
        batchTypes: BatchType[];
      }>
    ) {
      state.availableBatchStatuses = payload.batchStatuses;
      state.availableBatchTypes = payload.batchTypes;
    },
    setBatchStatuses(state, { payload }: PayloadAction<BatchStatus[]>) {
      state.batchStatuses = payload;
      state.toteId = null;
    },
    setBatchTypes(state, { payload }: PayloadAction<BatchType[]>) {
      state.batchTypes = payload;
      state.toteId = null;
    },
    setOrderType(state, { payload }: PayloadAction<OrderType | null>) {
      state.orderType = payload;
      state.toteId = null;
    },
    setPage(state, { payload }: PayloadAction<number>) {
      state.page = payload;
      state.toteId = null;
    },
    setSearch(state, { payload }: PayloadAction<string | null>) {
      state.search = payload;
      state.toteId = null;
    },
    setTempZones(state, { payload }: PayloadAction<TempZone[]>) {
      state.tempZones = payload;
      state.toteId = null;
    },
    setToteId(state, { payload }: PayloadAction<Guid | null>) {
      state.toteId = payload;
    }
  }
});
export const {
  resetFilters,
  resetSelectedBatch,
  selectBatch,
  setAvailableFilters,
  setBatchStatuses,
  setBatchTypes,
  setOrderType,
  setPage,
  setSearch,
  setTempZones,
  setToteId
} = pickBatchesSlice.actions;

export function selectHasFilters(state: StoreState): boolean {
  return (
    !!state.pickBatches.tempZones.length ||
    (!!state.pickBatches.batchTypes.length &&
      state.pickBatches.batchTypes.length !==
        state.pickBatches.availableBatchTypes.length) ||
    (!!state.pickBatches.batchStatuses.length &&
      state.pickBatches.batchStatuses.length !==
        state.pickBatches.availableBatchStatuses.length)
  );
}
