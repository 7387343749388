import {
  ClearErrorMessageAction,
  GetPutawayByVariantAction,
  GetPutawayByVariantSuccessAction,
  GetPutawayByVariantFailureAction,
  ClearSuccessMessageAction,
  PutAwayItemAction,
  PutAwayItemSuccessAction,
  PutAwayItemFailureAction
} from "~/redux/actions/putaway";
import { PutAwayTaskSummaryDto } from "~/types/api";

export type PutawayState = {
  putawayInventoryByVariant: PutAwayTaskSummaryDto[];
  loadingPutawayByVariant: boolean;
  successMessage: string | null;
  error: string | null;
};

const initialState = {
  putawayInventoryByVariant: [],
  loadingPutawayByVariant: false,
  successMessage: null,
  error: null
};

type Actions =
  | GetPutawayByVariantAction
  | GetPutawayByVariantSuccessAction
  | GetPutawayByVariantFailureAction
  | ClearSuccessMessageAction
  | ClearErrorMessageAction
  | PutAwayItemAction
  | PutAwayItemSuccessAction
  | PutAwayItemFailureAction;

export default (
  state: PutawayState | undefined,
  action: Actions
): PutawayState => {
  if (state === undefined) {
    return initialState;
  }

  switch (action.type) {
    case "putaway/GET_PUTAWAY_BY_VARIANT":
      return {
        ...state,
        loadingPutawayByVariant: true,
        error: null
      };
    case "putaway/GET_PUTAWAY_BY_VARIANT_SUCCESS":
      return {
        ...state,
        loadingPutawayByVariant: false,
        putawayInventoryByVariant: action.payload,
        successMessage: !action.payload.length ? "Putaway Task Complete" : null
      };
    case "putaway/PUT_AWAY_ITEM":
      return {
        ...state,
        successMessage: null,
        error: null
      };
    case "putaway/PUT_AWAY_ITEM_SUCCESS":
      return {
        ...state,
        successMessage: "Putaway Complete"
      };
    case "putaway/GET_PUTAWAY_BY_VARIANT_FAILURE":
    case "putaway/PUT_AWAY_ITEM_FAILURE":
      return {
        ...state,
        successMessage: null,
        error: action.payload
      };
    case "putaway/CLEAR_ERROR_MESSAGE":
      return {
        ...state,
        error: null
      };
    case "putaway/CLEAR_SUCCESS_MESSAGE":
      return {
        ...state,
        successMessage: null
      };

    default:
      return state;
  }
};
