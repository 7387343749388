import { Layout } from "react-grid-layout";

import envConstants from "./envConstants";

export type BoardLayout = {
  name: string;
  dimensions: {
    rows: number;
    columns: number;
  };
  layout: Layout[];
};

type LayoutDefaults = Record<string, BoardLayout[]>;

const stagingLayouts: LayoutDefaults = {
  "a8a502f4-3540-4e50-a046-6c2aff6573ab": [
    {
      name: "UNY4",
      dimensions: {
        rows: 9,
        columns: 9
      },
      layout: [
        {
          w: 1,
          h: 1,
          x: 1,
          y: 2,
          i: "0b140aca-9865-459b-acc6-bd56c76364ea",
          maxH: 7,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 3,
          i: "6ce066d7-4bf1-4054-a07e-52521c55ac7b",
          maxH: 6,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 7,
          i: "6ef9168a-a814-497a-8ae0-2763fce876c1",
          maxH: 2,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 6,
          i: "71841421-437e-4ebc-b690-1d4fd3974e87",
          maxH: 3,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 0,
          i: "733d7716-53e5-49da-be8c-f817a1a36817",
          maxH: 9,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 2,
          i: "7bddb58d-c741-4543-9c27-e30bfa95f864",
          maxH: 7,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 7,
          i: "84676e71-b943-4ef0-bf45-b9df57e48541",
          maxH: 2,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 1,
          i: "8ac877f8-bb44-449e-87a3-c1c79107e6ed",
          maxH: 8,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 8,
          i: "906f7ef4-8266-4b28-8fe1-cf95e1c6d231",
          maxH: 1,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 3,
          i: "9e4278bb-ed4e-4074-b944-7c209489b997",
          maxH: 6,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 0,
          i: "b7746eaa-17a4-41d7-9a62-886117d2180d",
          maxH: 9,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 8,
          i: "bc308d08-3c57-4341-9916-130fba4db2d4",
          maxH: 1,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 4,
          i: "bf8a08f2-659e-426f-9f47-4b6de9eb1008",
          maxH: 5,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 6,
          i: "c89e7ca7-01ad-4bb7-b4a5-974be1631c4b",
          maxH: 3,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 2,
          y: 0,
          i: "ccfa19eb-ed13-4417-b1b9-0d9e03dbbd65",
          maxH: 9,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 5,
          i: "d2d21988-152a-43bc-97ce-f232ab4ec00c",
          maxH: 4,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 4,
          i: "d8312ba2-93d3-498c-9298-78de34377a06",
          maxH: 5,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 1,
          i: "df320b84-ae65-4f0b-80af-cbcd0f3c2a8c",
          maxH: 8,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 5,
          i: "f3893403-a110-4b87-842b-e799cba6168b",
          maxH: 4,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 5,
          y: 5,
          i: "10f7a790-42ec-4a3f-b1b1-3c177438849c",
          maxH: 4,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 7,
          y: 5,
          i: "151435a6-f229-4ee2-8835-823578fb6926",
          maxH: 4,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 7,
          y: 3,
          i: "23844c55-acde-4d1c-89f1-03eeecdc0413",
          maxH: 6,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 7,
          y: 6,
          i: "3635a6b7-c145-4056-8ab8-09d0b7896424",
          maxH: 3,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 5,
          y: 7,
          i: "3abbb659-c9bf-4ce2-85f4-f1f62f3ac1b1",
          maxH: 2,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 5,
          y: 1,
          i: "481cea49-791a-4447-8a4e-d43c97d74818",
          maxH: 8,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 5,
          y: 2,
          i: "51e86dc5-f8c4-48dd-9e3c-d88044f87bf4",
          maxH: 7,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 7,
          y: 2,
          i: "54c80916-0418-48f1-b5ea-c331529bd608",
          maxH: 7,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 5,
          y: 3,
          i: "77adbe6e-48ee-42d2-9724-a81ac99fec1a",
          maxH: 6,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 7,
          y: 4,
          i: "92a3627c-a2d2-48bf-85d3-36a0ecf00b7d",
          maxH: 5,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 7,
          y: 1,
          i: "b6d7f4fc-f24b-4aa6-b501-22b691ba4ad7",
          maxH: 8,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 5,
          y: 4,
          i: "d5652f86-cdf9-45c2-825c-227e83aa77a7",
          maxH: 5,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 6,
          y: 1,
          i: "d8b55608-72f6-47ec-b128-5b9b03450671",
          maxH: 8,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 5,
          y: 6,
          i: "db12abc3-0233-4e26-b6bc-f0502f8c42ef",
          maxH: 3,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 7,
          y: 7,
          i: "f5d26d97-8786-4adc-922b-0fdc83737052",
          maxH: 2,
          moved: false,
          static: false
        }
      ]
    }
  ],
  "5e971ce5-67c3-40ef-a0e1-55a76b55bbc7": [
    {
      name: "Site 1",
      dimensions: {
        rows: 5,
        columns: 9
      },
      layout: [
        {
          w: 1,
          h: 1,
          x: 0,
          y: 4,
          i: "7c4a22f3-19cb-42af-944e-6c23cbac1e44",
          maxH: 2,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 4,
          i: "4d629497-80ee-41c3-b9c6-d0d8ac8dcdcb",
          maxH: 2,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 2,
          y: 2,
          i: "45c6d659-372b-4830-a729-ea07914e2181",
          maxH: 4,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 2,
          i: "43faa114-0105-4194-b3ef-8cbd61a8c5b8",
          maxH: 4,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 4,
          y: 2,
          i: "7e440f40-dd72-4897-a0fe-ba1abf8c4618",
          maxH: 4,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 5,
          y: 2,
          i: "3ad858c3-e699-4bcc-a41f-3058158db4d5",
          maxH: 4,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 6,
          y: 2,
          i: "24448279-8a25-4daa-8796-290f83323012",
          maxH: 4,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 7,
          y: 2,
          i: "4ce518b5-f7b0-4989-a3ff-1b305838d480",
          maxH: 4,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 8,
          y: 2,
          i: "92dd4aae-4263-4253-8364-8d2144c4529b",
          maxH: 4,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 0,
          y: 0,
          i: "e04d0f04-bde7-4742-9cac-89a34c3d9e47",
          maxH: 6,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 0,
          i: "07f08ba8-f5d3-4f3c-8f87-34a8f4e8d3db",
          maxH: 6,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 2,
          y: 0,
          i: "cadc94fa-b834-4fb2-8621-a1bcbc80714a",
          maxH: 6,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 0,
          i: "b0347821-f6bf-402d-bc5f-39ad2e04e13b",
          maxH: 6,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 4,
          y: 0,
          i: "cbfb147f-cebe-45cf-834d-36500fa875b8",
          maxH: 6,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 5,
          y: 0,
          i: "7da06320-b73a-4cfa-8b73-8e6afa3e251f",
          maxH: 6,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 6,
          y: 0,
          i: "26ed3769-6e4e-4b0a-b774-9423f1804b70",
          maxH: 6,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 7,
          y: 0,
          i: "8a415734-c742-4a30-89e5-2552e8366609",
          maxH: 6,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 8,
          y: 0,
          i: "3f08b481-e24b-425c-9e5d-a87457a2285d",
          maxH: 6,
          moved: false,
          static: false
        }
      ]
    }
  ]
};

const productionLayouts: LayoutDefaults = {
  "a8a502f4-3540-4e50-a046-6c2aff6573ab": [
    {
      name: "UNY4",
      dimensions: {
        rows: 9,
        columns: 9
      },
      layout: [
        {
          w: 1,
          h: 1,
          x: 1,
          y: 2,
          i: "0b140aca-9865-459b-acc6-bd56c76364ea",
          maxH: 7,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 3,
          i: "6ce066d7-4bf1-4054-a07e-52521c55ac7b",
          maxH: 6,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 7,
          i: "6ef9168a-a814-497a-8ae0-2763fce876c1",
          maxH: 2,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 6,
          i: "71841421-437e-4ebc-b690-1d4fd3974e87",
          maxH: 3,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 0,
          i: "733d7716-53e5-49da-be8c-f817a1a36817",
          maxH: 9,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 2,
          i: "7bddb58d-c741-4543-9c27-e30bfa95f864",
          maxH: 7,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 7,
          i: "84676e71-b943-4ef0-bf45-b9df57e48541",
          maxH: 2,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 1,
          i: "8ac877f8-bb44-449e-87a3-c1c79107e6ed",
          maxH: 8,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 8,
          i: "906f7ef4-8266-4b28-8fe1-cf95e1c6d231",
          maxH: 1,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 3,
          i: "9e4278bb-ed4e-4074-b944-7c209489b997",
          maxH: 6,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 0,
          i: "b7746eaa-17a4-41d7-9a62-886117d2180d",
          maxH: 9,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 8,
          i: "bc308d08-3c57-4341-9916-130fba4db2d4",
          maxH: 1,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 4,
          i: "bf8a08f2-659e-426f-9f47-4b6de9eb1008",
          maxH: 5,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 6,
          i: "c89e7ca7-01ad-4bb7-b4a5-974be1631c4b",
          maxH: 3,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 2,
          y: 0,
          i: "ccfa19eb-ed13-4417-b1b9-0d9e03dbbd65",
          maxH: 9,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 5,
          i: "d2d21988-152a-43bc-97ce-f232ab4ec00c",
          maxH: 4,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 4,
          i: "d8312ba2-93d3-498c-9298-78de34377a06",
          maxH: 5,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 1,
          y: 1,
          i: "df320b84-ae65-4f0b-80af-cbcd0f3c2a8c",
          maxH: 8,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 3,
          y: 5,
          i: "f3893403-a110-4b87-842b-e799cba6168b",
          maxH: 4,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 5,
          y: 5,
          i: "10f7a790-42ec-4a3f-b1b1-3c177438849c",
          maxH: 4,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 7,
          y: 5,
          i: "151435a6-f229-4ee2-8835-823578fb6926",
          maxH: 4,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 7,
          y: 3,
          i: "23844c55-acde-4d1c-89f1-03eeecdc0413",
          maxH: 6,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 7,
          y: 6,
          i: "3635a6b7-c145-4056-8ab8-09d0b7896424",
          maxH: 3,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 5,
          y: 7,
          i: "3abbb659-c9bf-4ce2-85f4-f1f62f3ac1b1",
          maxH: 2,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 5,
          y: 1,
          i: "481cea49-791a-4447-8a4e-d43c97d74818",
          maxH: 8,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 5,
          y: 2,
          i: "51e86dc5-f8c4-48dd-9e3c-d88044f87bf4",
          maxH: 7,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 7,
          y: 2,
          i: "54c80916-0418-48f1-b5ea-c331529bd608",
          maxH: 7,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 5,
          y: 3,
          i: "77adbe6e-48ee-42d2-9724-a81ac99fec1a",
          maxH: 6,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 7,
          y: 4,
          i: "92a3627c-a2d2-48bf-85d3-36a0ecf00b7d",
          maxH: 5,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 7,
          y: 1,
          i: "b6d7f4fc-f24b-4aa6-b501-22b691ba4ad7",
          maxH: 8,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 5,
          y: 4,
          i: "d5652f86-cdf9-45c2-825c-227e83aa77a7",
          maxH: 5,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 6,
          y: 1,
          i: "d8b55608-72f6-47ec-b128-5b9b03450671",
          maxH: 8,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 5,
          y: 6,
          i: "db12abc3-0233-4e26-b6bc-f0502f8c42ef",
          maxH: 3,
          moved: false,
          static: false
        },
        {
          w: 1,
          h: 1,
          x: 7,
          y: 7,
          i: "f5d26d97-8786-4adc-922b-0fdc83737052",
          maxH: 2,
          moved: false,
          static: false
        }
      ]
    }
  ]
};

let layoutsToExport: LayoutDefaults;

switch (envConstants.RUNTIME_ENVIRONMENT) {
  case "production":
    layoutsToExport = productionLayouts;
    break;

  case "staging":
  default:
    layoutsToExport = stagingLayouts;
}

export default layoutsToExport;
