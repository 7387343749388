import { Hit } from "@algolia/client-search";
import { SearchIndex } from "algoliasearch";

import { Product } from "./algoliaProduct.type";
import { isDefined } from "./isDefined";

/**
 * Make a search call to Algolia for the given client and inputs.
 *
 * @algolia Config items containing which Algolia index to connect to.
 * @client Initialized connection to Algolia.
 * @input What to actually search.
 * @allowNonMfc Whether to allow non-MFC enabled records to show up in search results.
 * @hitsPerPage How many results per page to return.
 * @restrictSearchableAttributes Attributes we want to only search by. Defaults to none, meaning we search by all searchable attributes.
 */
export const searchAlgoliaProductsIndex = async (
  searchIndex: SearchIndex,
  input: string | string[],
  allowNonMfc = false,
  hitsPerPage = 5,
  restrictSearchableAttributes: string[] = []
): Promise<Hit<Product>[]> => {
  const filters = allowNonMfc ? "" : "Variants.IsMfcEnabled:true";

  const inputs = typeof input === "string" ? [input] : input;

  const searchResult = await Promise.all(
    inputs.map((input) =>
      searchIndex.search<Product>(input, {
        filters,
        attributesToRetrieve: ["*"],
        page: 0,
        hitsPerPage,
        restrictSearchableAttributes
      })
    )
  );

  return searchResult.flatMap((searchResponse) => {
    return searchResponse.hits
      .map((hit) => {
        const variants = hit.Variants.filter((variant) => {
          if (variant.IsMfcEnabled || allowNonMfc) {
            return true;
          }

          return false;
        });

        // If there are no valid variants left, don't return anything.
        if (!variants || variants.length === 0) {
          return undefined;
        }

        // If any variants have an exact match on SKU or Variant ID, just return those.
        const matchingVariants = variants.filter((variant) =>
          inputs.every(
            (input) =>
              input === variant.Sku ||
              input === variant.ExternalVariantId ||
              input === variant.ProductVariantId
          )
        );

        return {
          ...hit,
          Variants: matchingVariants.length > 0 ? matchingVariants : variants
        };
      })
      .filter(isDefined);
  });
};
