import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ProductResult } from "./ProductResult";

type CycleCountsNewState = {
  page: number;
  searchedVariant: ProductResult | null;
};

const initialState: CycleCountsNewState = {
  page: 1,
  searchedVariant: null
};

export const cycleCountsNewSlice = createSlice({
  name: "cycleCountsNew",
  initialState,
  reducers: {
    setPage(state, { payload }: PayloadAction<number>) {
      state.page = payload;
    },
    setSearchedVariant(
      state,
      { payload }: PayloadAction<ProductResult | null>
    ) {
      state.searchedVariant = payload;
    }
  }
});
export const { setPage, setSearchedVariant } = cycleCountsNewSlice.actions;
