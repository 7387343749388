import {
  Chip,
  Container,
  Grid,
  Pagination,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { ProgressButton } from "~/components/ProgressButton";
import AutostoreTable from "~/components/autostore/table/AutostoreTable";

import { useNavbar } from "~/hooks/useNavbar";
import { useView } from "~/hooks/useView";
import { dedupeBrand, getDisplayNameByDto } from "~/lib/helpers";
import { mobileWidth, tabletPortrait } from "~/lib/theme";

import {
  getLowInventory,
  clearInventoryMessage,
  findPutawayByVariant
} from "~/redux/actions/inventory";
import { StoreState } from "~/redux/reducers";
import { LowInventoryAndVariantRecord } from "~/types/api";

import { LowInventoryFilter } from "./LowInventoryFilter";
import { setPage } from "./lowInventory.slice";

const mapStateToProps = (state: StoreState) => ({
  lowInventory: state.inventory.lowInventory,
  lowInventoryRecordCount: state.inventory.lowInventoryRecordCount,
  lowInventoryLoading: state.inventory.lowInventoryLoading,
  inventoryAndPutawayTask: state.inventory.inventoryAndPutawayTask,
  inventoryAndPutawayLoading: state.inventory.inventoryAndPutawayLoading,
  usersFulfillmentCenter: state.store.usersFulfillmentCenter
});

const connector = connect(mapStateToProps, {
  getLowInventory,
  clearInventoryMessage,
  findPutawayByVariant
});
type LowInventoryInheritedProps = { viewTitle?: string };
type PropsFromRedux = ConnectedProps<typeof connector>;
type LowInventoryProps = PropsFromRedux & LowInventoryInheritedProps;

export function LowInventory(props: LowInventoryProps) {
  const {
    lowInventory,
    lowInventoryLoading,
    lowInventoryRecordCount,
    inventoryAndPutawayLoading,
    inventoryAndPutawayTask,
    usersFulfillmentCenter,
    viewTitle
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(mobileWidth);
  const isMediumSized = useMediaQuery("(max-width:1000px)");
  const isTabletPortrait = useMediaQuery(tabletPortrait);

  useView({ permanentSidenav: true });
  useNavbar({
    rightComponent: useMemo(() => <LowInventoryFilter />, []),
    viewTitle
  });

  const filter = useAppSelector((state) => state.lowInventory.filter);
  const page = useAppSelector((state) => state.lowInventory.page);

  const [selectedRow, setSelectedRow] =
    useState<LowInventoryAndVariantRecord | null>(null);

  const limit = isMobile ? 10 : 25;
  const offset = (page - 1) * limit;
  const totalPageCount = lowInventoryRecordCount
    ? Math.ceil(lowInventoryRecordCount / limit)
    : 0;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    props.getLowInventory(limit, offset, filter?.hasPutawayTasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter]);

  useEffect(() => {
    if (lowInventory?.length) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.findPutawayByVariant(
        lowInventory.map((inv) => inv.variant?.variantId || "")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lowInventory]);

  const rows = [
    (row: LowInventoryAndVariantRecord) => {
      const brandName = row.variant?.brandName || "";
      const productName =
        getDisplayNameByDto(row.variant, usersFulfillmentCenter) ?? "";
      const dedupedProduct = dedupeBrand(productName, brandName);
      const brandAndProduct = `${brandName} ${dedupedProduct}`.trim();

      return (
        <Stack direction="column" spacing={isMediumSized ? 0.5 : 1}>
          <Typography>{brandAndProduct}</Typography>
          <Stack
            direction={isMediumSized ? "column" : "row"}
            justifyContent="center"
            spacing={isMediumSized ? 0.5 : 1}
          >
            {!!row.variant?.sku && (
              <Chip
                sx={{ fontSize: 12 }}
                variant="outlined"
                label={`SKU: ${row.variant.sku}`}
              />
            )}
            {!!row.variant?.allUpcs.length && (
              <Chip
                variant="outlined"
                label={`UPC: ${row.variant.allUpcs[0]}`}
                sx={{ fontSize: 12 }}
              />
            )}
          </Stack>
        </Stack>
      );
    },
    (row: LowInventoryAndVariantRecord) => (
      <Typography>{`${row.lowInventorySummary.totalCommittedCount}`}</Typography>
    ),
    (row: LowInventoryAndVariantRecord) => (
      <Typography>{`${row.lowInventorySummary.restockCount}`}</Typography>
    ),
    (row: LowInventoryAndVariantRecord) => {
      if (
        !inventoryAndPutawayLoading &&
        inventoryAndPutawayTask.some(
          (task) => task.variantId === row.variant?.variantId
        )
      ) {
        // if inventory record has a putaway task - show link to putaway
        return (
          <ProgressButton
            color="success"
            onClick={() =>
              row.variant?.variantId
                ? navigate(`/putaway/${row.variant?.variantId}`)
                : null
            }
          >
            {t("show putaway")}
          </ProgressButton>
        );
      }
      // if inventory record does not have a putaway task - show link to inventory
      return (
        <ProgressButton
          color="primary"
          onClick={() =>
            row.variant?.variantId
              ? navigate(`/inventory/product/${row.variant?.variantId}`)
              : null
          }
        >
          {t("show inventory")}
        </ProgressButton>
      );
    }
  ];

  return (
    <Container style={{ marginTop: 24 }}>
      <Grid
        container
        direction="column"
        style={{ height: "100%", flexWrap: "nowrap" }}
        alignItems="center"
        spacing={4}
      >
        <Grid item xs={12} style={{ width: isTabletPortrait ? "100%" : "95%" }}>
          <AutostoreTable<LowInventoryAndVariantRecord>
            tableKey="main"
            widthOfCols={isMobile ? ["80", "20"] : ["40%", "20%", "20%", "20"]}
            headerVariant="h5"
            bodyVariant="h6"
            rowData={lowInventory || []}
            selectRowCallback={(row) => {
              if (
                selectedRow?.lowInventorySummary.variantId ===
                row.lowInventorySummary.variantId
              ) {
                setSelectedRow(null);
              } else {
                setSelectedRow(row);
              }
            }}
            selectedRow={selectedRow}
            // for mobile, only show the product name and the button
            renderColumns={isMobile ? [rows[0], rows[3]] : rows}
            loading={lowInventoryLoading}
            headerColNames={
              isMobile
                ? [t("product"), ""]
                : [t("product"), t("committed count"), t("restock count"), ""]
            }
            rowId={(inventory) => inventory.lowInventorySummary.variantId}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: "16px 0 48px" }}>
          {totalPageCount > 1 && (
            <Pagination
              count={totalPageCount}
              page={page}
              onChange={(_e, p) => {
                dispatch(setPage(p));
              }}
              shape="rounded"
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default connector(LowInventory);
