import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type RestockPickModalState = {
  isOpen: boolean;
  outOfStockReasonCode: string;
  isOosModalOpenedAfterQtyModal: boolean;
  ableToPickQty: number | null;
};

const initialState: RestockPickModalState = {
  isOpen: false,
  outOfStockReasonCode: "out of stock",
  isOosModalOpenedAfterQtyModal: false,
  ableToPickQty: null
};

export const outOfStockDialogSlice = createSlice({
  name: "outOfStockDialog",
  initialState,
  reducers: {
    setOutOfStockDialogStatus(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
    setOutOfStockReasonCode(state, action: PayloadAction<string>) {
      state.outOfStockReasonCode = action.payload;
    },
    setIsOosModalOpenedAfterQtyModal(state, action: PayloadAction<boolean>) {
      state.isOosModalOpenedAfterQtyModal = action.payload;
    },
    setAbleToPickQty(state, action: PayloadAction<number | null>) {
      state.ableToPickQty = action.payload;
    }
  }
});

export const {
  setOutOfStockDialogStatus,
  setOutOfStockReasonCode,
  setIsOosModalOpenedAfterQtyModal,
  setAbleToPickQty
} = outOfStockDialogSlice.actions;
