import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useEffect, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { useNavbar } from "~/hooks/useNavbar";
import { useTabNavLocation } from "~/hooks/useTabNavLocation";
import { useView } from "~/hooks/useView";
import { selectStatusSelectedAutostoreGridId } from "~/redux/selectors/statusSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";
import { useGetAutostoreGridsQuery } from "~/redux/warehouse/autostoreGrid.hooks";

import PTLStatus from "./PTLStatus";
import { PortStatus } from "./PortStatus";

import { setSelectedAutostoreGridId } from "./status.slice";

type StatusProps = { viewTitle?: string };

const StatusTab = ({
  selectedTab,
  tabIndex,
  children
}: { selectedTab: string; tabIndex: string } & PropsWithChildren) => {
  return (
    <Box
      role="tabpanel"
      hidden={tabIndex !== selectedTab}
      id={`tabpanel-${tabIndex}`}
      aria-labelledby={`tab-${tabIndex}`}
    >
      {tabIndex === selectedTab && children}
    </Box>
  );
};

export const Status = (props: StatusProps) => {
  const { viewTitle } = props;

  const { t } = useTranslation();

  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const selectedAutostoreGridId = useAppSelector(
    selectStatusSelectedAutostoreGridId
  );
  const workstationAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const { data: autostoreGrids } = useGetAutostoreGridsQuery();
  const dispatch = useAppDispatch();

  useView({ fixedHeight: true, permanentSidenav: true });
  useNavbar({ viewTitle });

  const validTabs = ["ports-status", "ptl-status"];
  const { tabIndex, handleTabIndexChange } = useTabNavLocation({
    defaultTab: "ports-status",
    validTabs,
    key: "status-tab"
  });

  useEffect(() => {
    if (!selectedAutostoreGridId && autostoreGrids?.length) {
      dispatch(
        setSelectedAutostoreGridId(
          workstationAutostoreGridId || autostoreGrids[0].autostoreGridId
        )
      );
    }
  }, [
    autostoreGrids,
    workstationAutostoreGridId,
    selectedAutostoreGridId,
    dispatch
  ]);

  const a11yProps = (index: string) => {
    return {
      id: `tab-${index}`,
      value: index,
      "aria-controls": `tabpanel-${index}`
    };
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Tabs
        value={tabIndex}
        onChange={(_event, newValue) => {
          handleTabIndexChange(newValue as string);
        }}
        aria-label="status tabs"
        sx={{
          ml: 2
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        {usersFulfillmentCenter?.pickingConfigurations.includes(
          "Autostore"
        ) && (
          <Tab
            label={`${t("port")} ${t("status")}`}
            {...a11yProps("ports-status")}
          />
        )}
        {usersFulfillmentCenter?.pickingConfigurations.includes(
          "Autostore"
        ) && <Tab label={`PTL ${t("status")}`} {...a11yProps("ptl-status")} />}
      </Tabs>
      <Box
        sx={{
          height: `calc(100% - 48px)`,
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none"
          },
          scrollbarWidth: "none",
          msOverflowStyle: "none"
        }}
      >
        <StatusTab selectedTab={tabIndex} tabIndex="ports-status">
          <PortStatus />
        </StatusTab>
        <StatusTab selectedTab={tabIndex} tabIndex="ptl-status">
          <PTLStatus />
        </StatusTab>
      </Box>
    </Box>
  );
};
