import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  ThemeOptions
} from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypeBackground {
    lightBlue: string;
    gray: string;
  }
  interface TypographyVariants {
    tableBody: React.CSSProperties;
    stat: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    tableBody?: React.CSSProperties;
    stat: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    tableBody: true;
    stat: true;
  }
}

/**
 * There are a few ways to reference theme colors:
 *
 * Using the sx prop on MUI elements provides access to the theme (colors, spacing, etc)
 * @example
 * <Box
 *     sx={{
 *       width: 300,
 *       height: 300,
 *       backgroundColor: 'primary.dark',
 *       '&:hover': {
 *         backgroundColor: 'primary.main',
 *         opacity: [0.9, 0.8, 0.7],
 *       },
 *     }}
 *   />
 *
 * For inline element styling, use the theme hook
 * @example
 * import { useTheme } from "@mui/material/styles";
 * const { palette } = useTheme();
 *
 * <Element style={{ color: palette.success.dark }} />
 *
 * When using the `styled` api,
 * we have access to the autostoreTheme defined below via theme.palette[option][variant]
 * @example
 * import { styled } from "@mui/material/styles";
 *
 * const CustomizedSlider = styled(Slider)(
 *  ({ theme }) => `
 *    color: ${theme.palette.primary.main};
 *
 *    :hover {
 *      color: ${darken(theme.palette.primary.main, 0.2)};
 *    }
 * );
 */

export const phoneWidth = 600;
export const tabletPortraitWidth = 768;

export const andonColors: { [key: string]: string } = {
  openGreen: "#6dae45",
  handRaisedYellow: "#fdc101",
  portFaultAmber: "#f07b35",
  gridDownPurple: "#9382FF",
  safetyStopRed: "#bf0001",
  workstationActiveTeal: "#7e7e7e"
};
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    tablet: true; // adds the `tablet` breakpoint
  }
  interface Palette {
    gray: PaletteColor;
    darkGray: PaletteColor;
    statusGray: PaletteColor;
    statusBlue: PaletteColor;
    statusYellow: PaletteColor;
    statusGreen: PaletteColor;
    statusRed: PaletteColor;
    autostoreRed: PaletteColor;
    frozen: PaletteColor;
    chilled: PaletteColor;
    ambient: PaletteColor;
    buttonGroup: PaletteColor;
    warning: PaletteColor;
    darkBlue: PaletteColor;
    pickProgressBarGreen: PaletteColor;
    disabled: PaletteColor;
    icon: PaletteColor;
  }

  interface PaletteOptions {
    gray: PaletteColorOptions;
    mediumGray: PaletteColorOptions;
    darkGray: PaletteColorOptions;
    statusGray: PaletteColorOptions;
    statusBlue: PaletteColorOptions;
    statusYellow: PaletteColorOptions;
    statusGreen: PaletteColorOptions;
    statusRed: PaletteColorOptions;
    autostoreRed: PaletteColorOptions;
    frozen: PaletteColorOptions;
    chilled: PaletteColorOptions;
    ambient: PaletteColorOptions;
    buttonGroup: PaletteColorOptions;
    darkBlue: PaletteColorOptions;
    pickProgressBarGreen: PaletteColorOptions;
    disabled: PaletteColorOptions;
    icon: PaletteColorOptions;
  }
}

const autostoreThemeObj: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: phoneWidth,
      tablet: tabletPortraitWidth,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    mode: "light",
    primary: {
      main: "#276FEC",
      dark: "#185BD1",
      light: "#EFF3FE"
    },
    secondary: {
      main: "#f0f0f0",
      dark: "#e8e8e8",
      contrastText: "#000"
    },
    icon: {
      main: "#0000008A",
      dark: "#000000DE"
    },
    text: {
      primary: "#212121",
      secondary: "#666666",
      disabled: "rgba(0,0,0,0.3)"
    },
    error: {
      main: "#E11B22",
      dark: "#C91218",
      light: "#FFEFED"
    },
    info: {
      main: "#15844b",
      dark: "#0e733f",
      light: "#e6f2ed"
    },
    warning: {
      main: "#ffc043",
      dark: "#f0b135",
      light: "#ffefed"
    },
    success: {
      main: "#15844B",
      dark: "#0E733F",
      light: "#E6F2ED"
    },
    background: {
      default: "#fff",
      paper: "#fff",
      lightBlue: "#EFF3FE",
      gray: "#F5F5F5"
    },
    divider: "rgba(0,0,0,0.2)",
    // custom colors:
    autostoreRed: {
      main: "#e11b22",
      contrastText: "#fff"
    },
    gray: {
      light: "#f0f0f0",
      main: "#f2f2f2",
      dark: "#e8e8e8"
    },
    mediumGray: {
      main: "#ebebeb"
    },
    darkGray: {
      dark: "#333",
      main: "#757575",
      light: "rgb(202, 202, 202)"
    },
    frozen: { main: "#E9BDFF", contrastText: "#000" },
    chilled: { main: "#BDD3FF", contrastText: "#000" },
    ambient: { main: "#FFE3AC", contrastText: "#000" },
    statusGray: { main: "#F0F0F0", contrastText: "#000" },
    statusBlue: { main: "#BDD3FF", contrastText: "#000" },
    statusRed: { main: "#FFD3BD", contrastText: "#000" },
    statusYellow: { main: "#FFE3AC", contrastText: "#000" },
    statusGreen: { main: "#DAF5A5", contrastText: "#000" },
    buttonGroup: {
      light: "#fff",
      main: "#fff",
      dark: "#fff",
      contrastText: "#fff"
    },
    darkBlue: { main: "#2260D3" },
    pickProgressBarGreen: { main: "#189A57" },
    disabled: {
      main: "#00000014",
      dark: "#0000004D"
    }
  },
  shape: {
    borderRadius: 10
  },
  typography: {
    fontFamily: "CircularXX, Roboto",
    tableBody: {
      fontSize: "1.25rem"
    },
    stat: {
      fontSize: "32px",
      fontFamily: "CircularXX, Roboto"
    },
    button: {
      textTransform: "none"
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          fontWeight: 400,
          borderRadius: "6px",
          "&.MuiButton-containedPrimary.Mui-disabled": {
            background: "#BDD3FF",
            color: "white"
          }
        }
      }
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
          backgroundColor: "#f0f0f0",
          color: "#00000099",
          borderBottom: "1px solid rgba(0,0,0,0.2)",
          height: "48px"
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 3px 1px"
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.primary.light,
          display: "flex",
          justifyContent: "center",
          color: theme.palette.primary.dark,
          fontSize: "24px",
          borderBottom: `1px solid ${theme.palette.darkGray.light}`
        })
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.error && {
            backgroundColor: theme.palette.error.light
          })
        })
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...(ownerState.selected && { color: theme.palette.primary.dark })
        })
      }
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: "2rem",
          lineHeight: "2rem",
          margin: 0
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: "1.5rem",
          lineHeight: "1.5rem",
          alignItems: "center",
          gap: "16px"
        },
        message: {
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          padding: "16px 0"
        },
        icon: {
          fontSize: "2rem"
        },
        action: {
          padding: 0
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderSpacing: "0 6px",
          borderCollapse: "separate",
          padding: "0 6px",
          background: "#f0f0f0",
          borderRadius: "10px"
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "& > td": {
            borderColor: "transparent",
            borderTopWidth: "3px",
            borderBottomWidth: "3px",
            borderStyle: "solid"
          },

          "&.Mui-selected > td": {
            background: "#EFF3FE",
            borderColor: "#2260D3"
          },
          "& > td:first-of-type": {
            borderLeftWidth: "3px"
          },
          "& > td:last-of-type": {
            borderRightWidth: "3px"
          }
        },
        head: {
          "& > th": { border: 0 }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          lineHeight: "1.25rem",
          fontSize: "1.25rem",
          fontWeight: 400
        },
        body: {
          background: "white",
          border: 0,
          "&:first-of-type": {
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px"
          },
          "&:last-of-type": {
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px"
          }
        },
        head: {
          backgroundColor: "#f0f0f0",
          padding: "0 16px",
          color: "rgba(0,0,0,0.65)"
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          height: "36px"
        }
      }
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: "4px",
          height: "48px",
          minHeight: "32px",
          "@media (min-width: 600px)": {
            minHeight: "48px",
            paddingLeft: "4px"
          }
        }
      }
    }
  }
};

export const tabletWidth = "(max-width: 1200px)";
export const tabletPortrait = `(max-width: ${tabletPortraitWidth}px)`;
export const mobileWidth = `(max-width: ${phoneWidth}px)`;

export const autostoreTheme = createTheme(autostoreThemeObj);
