import { useAppSelector, useAppDispatch } from "~/app/store";
import { setStatusHoverPosition } from "~/features/status/status.slice";
import { useLogPublisherCell } from "~/hooks/useLogPublisherCell";

import { selectStatusSelectedAutostoreGridId } from "~/redux/selectors/statusSelectors";
import { Point2D } from "~/types/api";

import { BaseCell } from "./BaseCell";

export const PortCell = ({ position }: { position: Point2D }) => {
  const selectedAutostoreGridId = useAppSelector(
    selectStatusSelectedAutostoreGridId
  );
  const { ports } = useLogPublisherCell(selectedAutostoreGridId, position);
  const dispatch = useAppDispatch();

  const backgroundColor = ports[0]?.portMode === "Open" ? "green" : "red";
  return (
    <BaseCell
      role="gridcell"
      aria-label={`${position.x}/${position.y}`}
      variant="body2"
      sx={{ backgroundColor }}
      onMouseOver={() => dispatch(setStatusHoverPosition(position))}
    >
      {ports[0]?.portId}
    </BaseCell>
  );
};
