import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
  LogLevel
} from "@microsoft/signalr";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useRef, MutableRefObject } from "react";
import { Subject, Subscription } from "rxjs";

import { getWarehouseServiceUrl } from "~/api/warehouse";
import { store, useAppSelector } from "~/app/store";
import envConstants from "~/config/envConstants";
import useFlag from "~/config/flags";
import { setSignalRState } from "~/redux/actions";
import {
  AutostoreEvent,
  ConveyanceEvent,
  PTLEventDto,
  SendMessageDto,
  SignalRPickEventDto,
  ToteEventDto
} from "~/types/api";

// Constants for signalR connection retry delay logic
const MAX_ELAPSED_MILLISECONDS = 10000; // 10 seconds
const MAX_RETRY_DELAY = 5000; // 5 seconds
const RETRY_DELAY_MULTIPLIER = 1000; // 1 second

const signalRUrlPath = "warehouse";

// 1
/*
    Create hubs/subjects for different types of warehouse events

    Subject is a traditional class object with objects and class methods
    such as next, error, complete, subscribe, unsubscribe...

    const toteSubject = new Subject();
    const hubs = {};
    const hubs.tote = toteSubject;

    Subject comes from the extensive RxJS library, which is an abstract framework for reactive programming,
    which is a programming paradigm about asynchronous data flows and the propagation of change,
    a system where changes in one part of a system propagate to other parts of the system
    through a flow of data between interconnected components

    A Subject is a special type of Observable that allows values to be multicast to many Observers.
    An Observer is a class object that subscribes to a Subject via the Subject's subscribe method.
    When an Observer subscribes, the Subject adds the Observer to its registry of Observers.
    The observer reacts by executing their next method when the Subject emits a new value via its next method.

    hubs.tote.subscribe(observer object);

    hubs.tote.subscribe({
      next: (x) => console.log(`Observer 1: ${x.tote.toteId}`),
      error: (err) => console.error(`Observer 1: ${err}`),
      complete: () => console.log("Observer 1: complete")
    });

    // hubs.tote.next
    used to emit the next value to all subscribing Observers of the Subject.
    The Subject loops through its registry of Observers and calls the next method of each Observer
    passing along the value that was emitted.
    subject.next(123); // Observer 1: 123

    // hubs.tote.error
    used to emit an error to all subscribers of the subject.
    subject.error("error message"); ## Observer 1: error message

    // hubs.tote.complete
    used to emit the completion notification to all subscribers of the subject.
    subject.complete(); ## Observer 1: complete

    the Observer is in control of how it reacts to the values emitted by the Subject.
*/

// imported by tests, picking.hooks.ts, activityIndicator.ts and grid.streaming.ts (?)
export const hubs = {
  tote: new Subject<ToteEventDto>(),
  pick: new Subject<SignalRPickEventDto>(),
  gridV2: new Subject<AutostoreEvent>(),
  ptl: new Subject<PTLEventDto>(),
  userMessage: new Subject<SendMessageDto>(),
  conveyance: new Subject<ConveyanceEvent>()
};

// 2
// Function to respond to signalr connection failure by logging the error and changing redux state.
// Dependencies: Sentry, store, setSignalRState
const respondToConnectionError = ({ errorStr }: { errorStr: string }) => {
  const errorMessage = `Client could not connect to SignalR and has disconnected with the message: ${errorStr}`;
  Sentry.captureMessage(errorMessage, "debug");

  store.dispatch(setSignalRState({ state: "Disconnected" }));
};

// 3
/**
 * Hook to manage a SignalR hub connection with automatic reconnect and event subscription.
 * Purpose: Manages the SignalR connection lifecycle using RxJS Subjects to publish events,
 *          allowing subscriptions to be set up independently of the connection state.
 * Explanation: This hook handles establishing the SignalR connection, automatic reconnection logic,
 *              and setting up event subscriptions. By using RxJS Subjects, event handling is decoupled
 *              from the connection state, so we can subscribe to events even if the connection is not yet established.
 * Called: Only used once in this file's useInitializeSignalRHubs, but fires multiple times.
 * Dependencies: useFlag, useAppSelector, Sentry, setSignalRState, store, getWarehouseServiceUrl.
 */
function useManageHubConnectionLifeCycle(args: {
  accessTokenFactory: () => Promise<string>;
  onConnectionHandlersMappedToHubPublish: (connection: HubConnection) => void;
  usersFulfillmentCenterId?: string;
}) {
  const {
    accessTokenFactory,
    onConnectionHandlersMappedToHubPublish,
    usersFulfillmentCenterId
  } = args;

  const isUserLoggedIn = useAppSelector((state) => state.login.isUserLoggedIn);

  // set up a ref to hold the connection object. a ref will not cause re-renders when it changes
  const connection = useRef<HubConnection | null>(null);

  const longPollingEnabled = useFlag().qubitSignalrEnableLongpolling;
  const serverSentEventsEnabled = useFlag().qubitSignalrEnableServerSentEvents;

  // only fires once due to useEffect
  useEffect((): (() => void) => {
    const connect = async () => {
      // establish transport types
      let transportTypes: HttpTransportType;

      if (longPollingEnabled) {
        // client sends HTTP request, connection remains open, server sends response when event occurs, repeat
        transportTypes = HttpTransportType.LongPolling;
      } else if (serverSentEventsEnabled) {
        // unilateral, text-based, server-to-client, automatic reconnection, persistent connection
        transportTypes = HttpTransportType.ServerSentEvents;
      } else {
        // signalr will negotiate the best transport type
        transportTypes = HttpTransportType.None;
      }

      const connectionOptions: IHttpConnectionOptions = {
        accessTokenFactory: accessTokenFactory,
        transport: transportTypes,
        // set credentials to false to allow wildcard origins `Access-Control-Allow-Origin: *` for testing
        withCredentials: envConstants.RUNTIME_ENVIRONMENT !== "e2e"
      };

      // 3-A
      // set up the connection using connectionOptions, respond to connection errors, and create custom retry policy
      connection.current = new HubConnectionBuilder()
        // Specify the SignalR URL and connection options.
        .withUrl(
          `${getWarehouseServiceUrl()}/signalr/${signalRUrlPath}`,
          connectionOptions
        )
        .configureLogging(LogLevel.Information) // Trace, Debug, Information, Warning, Error, Critical, None
        // Configures the HubConnection to automatically attempt to reconnect if the connection is lost.
        .withAutomaticReconnect({
          // by including a custom retry policy, the reconnect attempt will not quit after n attempts
          // The custom retry policy should return the amount of time in milliseconds to wait before the next retry
          nextRetryDelayInMilliseconds: (retryContext) => {
            // Check if the elapsed time since the initial connection loss exceeds the maximum allowed.
            // this means wait 10 seconds of retrying before logging the error every time after and updating the state.
            if (retryContext.elapsedMilliseconds >= MAX_ELAPSED_MILLISECONDS) {
              // Respond to the connection error by logging the error message and updating the state.
              respondToConnectionError({
                errorStr: retryContext.retryReason.message
              });
            }

            // Calculate and return the next retry delay. Retry in increasingly larger intervals,
            // but do not exceed the maximum retry delay, i.e. 1 second, 2 seconds, 4 seconds, 5 seconds, 5 seconds...
            return Math.min(
              retryContext.previousRetryCount * RETRY_DELAY_MULTIPLIER,
              MAX_RETRY_DELAY
            );
          }
        })
        .build();

      // 3-B
      // add event handlers to the connection
      onConnectionHandlersMappedToHubPublish(connection.current);

      // 3-C
      // potential for tweaking: connection timeouts

      // how often the client sends a keep-alive ping message to the server to ensure the connection is still active
      // connection.current.keepAliveIntervalInMilliseconds = 15000; // default 15000 (15 seconds)

      // how long the client waits for a response from the server before considering the connection to be timed out
      // connection.current.serverTimeoutInMilliseconds = 30000; // default 30000 (30 seconds)

      // 3-D
      // Register a handler to be called when the connection is re-established after being lost.
      connection.current.onreconnected(() => {
        // invoke a server-side function to initiate listening for events
        // passing the FC as an argument/filter
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
        connection.current?.invoke("listen", usersFulfillmentCenterId);

        const message = `Reconnected to the backend server.`;

        store.dispatch(setSignalRState({ state: "Connected" }));
        Sentry.captureMessage(message, "debug");
      });

      // 3-E
      // a function for when the page loads and an initial connection is attempted
      const attemptConnection = async (
        connection: MutableRefObject<HubConnection | null>
      ) => {
        let connectAttempt = 0;
        if (!connection.current) return;

        // blocks the function from resolving until the connection is established
        while (connection.current.state !== HubConnectionState.Connected) {
          try {
            await connection.current.start();
            // invoke a server-side function to initiate listening for events
            await connection.current.invoke("listen", usersFulfillmentCenterId);
            store.dispatch(setSignalRState({ state: "Connected" }));
          } catch {
            // Only send this alert the first time it cannot connect
            if (connectAttempt === 0) {
              respondToConnectionError({
                errorStr:
                  "Initial attempt to connect to signalR failed. Retrying..."
              });
            }
            // Wait a 5 seconds before attempting to reconnect
            await new Promise((r) => setTimeout(r, 5000));
            connectAttempt += 1;
          }
        }
      };

      // initial connection start up
      // this promise function will not resolve until the while loop is complete
      await attemptConnection(connection);
    };

    // Clean up function to stop the connection when component unmounts or dependencies change
    const disconnect = (): void => {
      // console.log("Disconnecting from SignalR...");
      if (connection.current) {
        void connection.current.stop();
      }
    };

    if (isUserLoggedIn) {
      void connect();
    }

    return disconnect;
  }, [
    accessTokenFactory,
    longPollingEnabled,
    serverSentEventsEnabled,
    onConnectionHandlersMappedToHubPublish,
    isUserLoggedIn,
    usersFulfillmentCenterId
  ]);
}

// 4
/**
 * Function: useInitializeSignalRHubs
 * Called: src/app/App.tsx, several times
 * Purpose: Initializes SignalR connections and sets up event listeners, mapping event types to hub subjects' .next publish
 * Dependencies: useManageHubConnectionLifeCycle, hubs
 */

export function useInitializeSignalRHubs(
  accessTokenFactory: () => Promise<string>,
  usersFulfillmentCenterId: string | undefined
): void {
  /**
   * Variable: onConnectionHandlersMappedToHubPublish
   * Type: Function: (connection: HubConnection) => void
   * Purpose: Maps incoming SignalR event types to their corresponding RxJS subjects (hubs).
   * Explanation: This function is memoized using useCallback to ensure that the same function instance is used
   *              across re-renders to avoid unnecessary re-registrations of event listeners.
   * Note: Although onConnectionHandlersMappedToHubPublish is defined each time useInitializeSignalRHubs is called,
   *       useCallback ensures that the same function reference is returned unless its dependencies change.
   */
  const onConnectionHandlersMappedToHubPublish: (
    connection: HubConnection
  ) => void = useCallback((connection) => {
    connection.on("tote-event", (event: ToteEventDto) => {
      // See hubs.tote.next(event) above for more information on how events are published to the RxJS subjects.
      // console.log("tote-event", event);
      hubs.tote.next(event);
    });
    connection.on("pick-event", (event: SignalRPickEventDto) => {
      // console.log("pick-event", event);
      hubs.pick.next(event);
    });
    // the most active event feed
    connection.on("autostore-grid-event-v2", (event: AutostoreEvent) => {
      // console.log("autostore-grid-event-v2", event);
      hubs.gridV2.next(event);
    });
    connection.on("user-event", (event: SendMessageDto) => {
      // console.log("user-event", event);
      hubs.userMessage.next(event);
    });
    connection.on("conveyance-event", (event: ConveyanceEvent) => {
      // console.log("conveyance-event", event);
      hubs.conveyance.next(event);
    });
    if (envConstants.ENABLE_PTL_SIMULATION === "true") {
      connection.on("ptl-event", (event: PTLEventDto) => {
        // console.log("ptl-event", event);
        hubs.ptl.next(event);
      });
    }
  }, []);

  // fires several times due to App re-renders
  useManageHubConnectionLifeCycle({
    accessTokenFactory,
    onConnectionHandlersMappedToHubPublish,
    usersFulfillmentCenterId
  });
}

// 5
/**
 * Function: useEventSubscription
 * Called: Used in components that require real-time event handling.
 * Purpose: Subscribes to an event hub and handles events, errors, and completion notifications using the provided callbacks.
 * Dependencies: Subject from RxJS for creating the hub, useEffect and useRef from React for managing the lifecycle.
 **/
function useCreateObserverMapEventHandler<T>(
  onEvent: (event: T) => void | Promise<void>,
  hub: Subject<T>,
  isEnabled = true
): void {
  const subscription = useRef<Subscription>();

  useEffect((): (() => void) => {
    if (!isEnabled) {
      return () => undefined;
    }

    /**
     * Variable: observer
     * Type: Object with methods { next, error, completer }
     * Purpose: Handles the incoming events, errors, and completes notifications from the event hub
     * Explanation: next is triggered on new events, error on any error in the stream, and completer when the stream completes.
     * Note: Errors and completions are logged but do not alter the state of the application.
     */
    const observer = {
      next: onEvent,
      // use this to debug the event stream
      // next: (data: T) => {
      //   console.log({ data });
      //   return onEvent(data);
      // },
      error: (err: unknown) =>
        // eslint-disable-next-line no-console
        console.log({ message: "Observer got an error", err }),
      completer: () =>
        // eslint-disable-next-line no-console
        console.log({ message: "Observer got a complete notification" })
    };

    // create a subscription to the hub
    // AND store it in a ref so that the subscription's unsubscribe method can be called when the component unmounts
    subscription.current = hub.subscribe(observer);

    // return the cleanup function for when the component unmounts or dependencies change
    const unsubscribe = () => {
      // console.log("unsubscribed"); // DISCOVERY: is induction view sometimes unsubscribing on repeat?
      if (subscription.current) {
        subscription.current.unsubscribe();
      }
    };
    return unsubscribe;
  }, [onEvent, isEnabled, hub]);
}

// 6-A
/**
 * Function: useToteSubscription
 * Called: In components as hooks
 * Purpose: Sets up a subscription to the subject/hub.
 * Dependencies: useEventHubSubscription, hub
 *
 * Note: To use, import into a component and pass in a onEvent function to handle data
 *
 *       useGridV2Subscription(gridSub);
 *
 *       Multiple subscriptions within different components will not duplicate the event stream.
 *       Each subscription adds an observer/event-hander to the subscription which
 *       unsubscribes upon dismount

 **/
export function useToteSubscription(
  onEvent: (event: ToteEventDto) => void | Promise<void>
): void {
  useCreateObserverMapEventHandler(onEvent, hubs.tote);
}

// 6-B
export function usePickSubscription(
  onEvent: (event: SignalRPickEventDto) => void | Promise<void>
): void {
  useCreateObserverMapEventHandler(onEvent, hubs.pick);
}

// 6-C
export function useGridV2Subscription(
  onEvent: (event: AutostoreEvent) => void | Promise<void>
): void {
  useCreateObserverMapEventHandler(onEvent, hubs.gridV2);
}

// 6-D
export function usePTLSubscription(
  onEvent: (event: PTLEventDto) => void,
  isEnabled = true
): void {
  useCreateObserverMapEventHandler(onEvent, hubs.ptl, isEnabled);
}

// 6-E
export function useUserSubscription(
  onEvent: (event: SendMessageDto) => void
): void {
  useCreateObserverMapEventHandler(onEvent, hubs.userMessage);
}

// 6-F
export function useConveyanceSubscription(
  onEvent: (event: ConveyanceEvent) => void
): void {
  useCreateObserverMapEventHandler(onEvent, hubs.conveyance);
}
