import { skipToken } from "@reduxjs/toolkit/query";

import { useAppSelector } from "~/app/store";
import { selectShouldListenToGridEvents } from "~/redux/selectors/autostoreSelectors";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";
import {
  useGetAutostoreGridConnectionModeQuery,
  useGetAutostoreGridQuery
} from "~/redux/warehouse/autostoreGrid.hooks";

export const useShouldListenToGridEvents = () => {
  const workstationAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const { data: workstationAutostoreGrid } = useGetAutostoreGridQuery(
    workstationAutostoreGridId || skipToken
  );
  const { data: connectionMode } = useGetAutostoreGridConnectionModeQuery(
    workstationAutostoreGridId || skipToken
  );
  return (
    useAppSelector(
      selectShouldListenToGridEvents(connectionMode, workstationAutostoreGrid)
    ) || false
  );
};
