import {
  AutostorePickingState,
  PickDto,
  SignalRPickEventDto
} from "~/types/api";

const sandwichPick = (): PickDto => ({
  workstationId: "123",
  autostoreBinPulledBy: "",
  pickId: "grilled cheese id",
  aisleName: "Deli",
  brandName: "In House",
  firstName: "Christopher",
  lastName: "Walken",
  imageFilename: "/staging/products/caputos/00022753700000.jpg",
  orderId: "sandwich order",
  inventoryId: "some inventory id",
  lineItemId: "sandwich",
  name: "sandwich",
  quantity: { units: "ea", value: 1 },
  price: 7.5,
  sku: "SANDO12",
  status: "Scheduled",
  unitAmount: 1,
  unitFormatted: "kg",
  unitIsApproximate: true,
  upc: "SANDO120",
  requestedUpc: "SANDO120",
  allUpcs: ["SANDO120"],
  variantId: "~sando~123",
  assignedToteId: "B",
  putToteId: undefined,
  exception: undefined,
  fulfilled: false,
  isSplit: false,
  scannedUpcs: [],
  modifiers: [
    {
      name: "Rye Bread",
      quantity: 1,
      modifierId: "rye123",
      variantId: "rye321"
    },
    {
      name: "Pickles",
      quantity: 1,
      modifierId: "pickles123",
      variantId: "pickles321"
    },
    {
      name: "Cheddar Cheese",
      quantity: 2,
      modifierId: "cheese123",
      variantId: "cheese321"
    },
    {
      name: "Mustard",
      quantity: 1,
      modifierId: "mustard123",
      variantId: "mustard321"
    }
  ],
  completedBy: "George Harrison",
  canceledReason: "",
  rescheduled: false
});

const autostorePickingStateSample: AutostorePickingState = {
  allPicks: [],
  batchId: "123",
  batchName: "abc",
  currentPicks: [],
  isBinOpened: false,
  orderPriorities: [],
  taskGroupId: 123,
  totes: [],
  binId: 12000
};

export const eventPickSample: SignalRPickEventDto = {
  batchId: "1234",
  eventType: "pick",
  pick: sandwichPick(),
  pickingState: autostorePickingStateSample
};
