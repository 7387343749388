import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { Card } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { TableInfoRow } from "~/components/TableInfoRow";
import { BatchCards } from "~/components/batches/BatchCards";

import { selectBatches } from "~/components/batches/batchCards.slice";
import { BatchSummaryDto } from "~/types/api";

type AdminBatchTableProps = {
  hasFilters: boolean;
  batchesLoading: boolean;
  batchesError?: string;
  selectedBatch: BatchSummaryDto | null;
  unselectBatch: () => void;
  selectBatch: (batch: BatchSummaryDto) => void;
};

export default function AdminBatchTable(props: AdminBatchTableProps) {
  const { batchesLoading, batchesError, selectedBatch, hasFilters } = props;
  const { t } = useTranslation();

  const batches = useAppSelector(selectBatches);

  const noBatchesText = hasFilters
    ? t("no batches found try removing filters")
    : t("no batches in progress");

  return (
    <Box
      padding="2px"
      marginTop="15px"
      overflow="hidden"
      className="admin-batches"
    >
      {!batchesLoading && !batches?.length ? (
        <Card>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <SentimentDissatisfiedIcon />
            </Grid>
            <Grid item>
              <TableInfoRow
                textContent={
                  batchesError ? t("error loading batches") : noBatchesText
                }
                error={!!batchesError}
              />
            </Grid>
          </Grid>
        </Card>
      ) : (
        <Grid container>
          <BatchCards
            selectedBatch={selectedBatch}
            onBatchSelected={(clickedBatch) => {
              if (
                selectedBatch &&
                selectedBatch.batchId === clickedBatch.batchId
              ) {
                props.unselectBatch();
              } else {
                props.selectBatch(clickedBatch);
              }
            }}
            batchesLoading={batchesLoading || !batches}
          />
        </Grid>
      )}
    </Box>
  );
}
