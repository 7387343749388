import { AlertColor, Box, SxProps } from "@mui/material";
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useState,
  Fragment
} from "react";

import { Toast, ToastStyle } from "~/components/Toast";

export interface ToastMessage {
  title: string;
  description?: string;
  severity: AlertColor;
  key: number;
  duration?: number;
  isSingleMessage?: boolean;
  withMask?: boolean;
}

const toastContainerStyle: SxProps = {
  pointerEvents: "none",
  "&>*": { pointerEvents: "auto" }
};

const singleMessageStyle: SxProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10000
};

export const ToastContext = createContext<{
  addMessage: (message: ToastMessage) => void;
}>(null as never);

export const ToastProvider: FC<{ children: ReactNode } & ToastStyle> = ({
  children,
  ...props
}) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  // setMessages will be stable, memoize the context object so useToast will stabilize
  const context = useMemo(() => {
    return {
      addMessage: (message: ToastMessage) => {
        setMessages((arr) => {
          // prevent duplicate messages
          if (arr.some((m) => m.title === message.title)) {
            return arr;
          } else {
            // only keep the last 5 messages
            return [...arr, message].slice(-5);
          }
        });
      }
    };
  }, [setMessages]);

  const removeMessage = (key: number) =>
    setMessages((arr) => arr.filter((m) => m.key !== key));

  return (
    <ToastContext.Provider value={context}>
      {children}
      <Box
        position="absolute"
        bottom={2}
        display="flex"
        flexDirection="column"
        width="100%"
        gap={1}
        sx={toastContainerStyle}
      >
        {messages.map((m) => (
          <Fragment key={m.key}>
            <Toast
              message={m}
              onExited={() => removeMessage(m.key)}
              {...props}
              sx={m.isSingleMessage ? singleMessageStyle : {}}
            />
            {m.withMask && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  zIndex: 1300
                }}
              />
            )}
          </Fragment>
        ))}
      </Box>
    </ToastContext.Provider>
  );
};

type Options = {
  /** A detailed description of the message */
  description?: string;
  /** Explicitly set the duration of the toast */
  duration?: number;
  /**
   * Darkens the rest of the screen
   * @deprecated Utilize MUI Dialog if a modal style alert is needed
   */
  withMask?: boolean;
  /**
   * shows message in the center of the screen
   * @deprecated
   */
  isSingleMessage?: boolean;
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw Error(
      "useToast: Context was not found. Seems you forgot to wrap your app in `<ToastProvider />`"
    );
  }

  const { addMessage } = context;

  // since the context is now stable, memoize the return so we don't re-run effects
  return useMemo(() => {
    const show = (
      title: string,
      options: {
        severity: AlertColor;
        withMask?: boolean;
        singleMessage?: boolean;
      },
      description?: string
    ) => {
      addMessage({ title, description, ...options, key: new Date().getTime() });
    };

    return {
      infoToast: (title: string, options?: Options) => {
        show(title, { severity: "info", ...options });
      },
      successToast: (title: string, options?: Options) => {
        show(title, { severity: "success", ...options });
      },
      warningToast: (title: string, options?: Options) => {
        show(title, { severity: "warning", ...options });
      },
      errorToast: (title: string, options?: Options) => {
        show(title, { severity: "error", ...options });
      }
    };
  }, [addMessage]);
};
