import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { LoadingBackdrop } from "~/components/LoadingBackDrop";
import useFlag from "~/config/flags";
import { useToast } from "~/hooks/useToast";
import { mixpanelTrack } from "~/lib/mixpanel-tracking";
import {
  clearFulfillmentCenter,
  fetchFulfillmentCenter
} from "~/redux/actions/store";
import { sendUserEventAction } from "~/redux/actions/userEvents";

import { useLazyGetAllFulfillmentCentersQuery } from "~/redux/warehouse/fulfillmentCenter.hooks";

import { handleAuthCallback } from "./login.slice";
import mixpanel from "mixpanel-browser";
import { selectAuth0UserId } from "~/redux/selectors/authSelectors";

const connector = connect(null, {
  fetchFulfillmentCenter,
  clearFulfillmentCenter,
  sendUserEventAction
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type AuthCallBackProps = PropsFromRedux;

function AuthCallback(props: AuthCallBackProps) {
  const {
    fetchFulfillmentCenter,
    clearFulfillmentCenter,
    sendUserEventAction
  } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();

  const [getAllFulfillmentCenters] = useLazyGetAllFulfillmentCentersQuery();

  const siteFulfillmentCenterId = useAppSelector(
    (state) => state.workstations.siteFulfillmentCenterId
  );
  const loginPath = useAppSelector((state) => state.site.loginPath);
  const loginPathToUse: string = loginPath || "/login";

  const isBevClientLogoutDecouplingEnabled =
    useFlag().bevClientLogoutFlowDecoupling;

  const currentUserId = useAppSelector(selectAuth0UserId);
  useEffect(() => {
    if (currentUserId) mixpanel.identify(currentUserId);
  }, [currentUserId]);

  useEffect(() => {
    const handleSuccess = async () => {
      mixpanelTrack({
        trackedPageName: "Login",
        type: "Success",
        label: "Login Success"
      });

      try {
        await sendUserEventAction(
          "UserLoggedIn",
          isBevClientLogoutDecouplingEnabled
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_err) {
        errorToast("Failed to send user event action");
      }

      // siteFulfillmentCenter is set when user has selected one in a previous sesssion
      // validate the fc id and refetch the fc, in case it has changed
      try {
        const clientFcs = await getAllFulfillmentCenters().unwrap();
        const clientFcIds = clientFcs.map((fc) => fc.fulfillmentCenterId) || [];

        if (
          siteFulfillmentCenterId &&
          clientFcIds.includes(siteFulfillmentCenterId)
        ) {
          // hits `/api/fulfillment-centers/${fulfillmentCenterId}`
          // puts the user's fc in the store
          // puts the appropriate batch filters into the batch store, used throughout the app
          await fetchFulfillmentCenter(siteFulfillmentCenterId);
        } else {
          // clears the fc center and batch filters
          clearFulfillmentCenter();
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_err) {
        clearFulfillmentCenter();
      }

      navigate("/");
    };

    const handleError = () => {
      mixpanelTrack({
        trackedPageName: "Login",
        type: "Error",
        label: "Login Failed"
      });
      navigate(loginPathToUse);
    };

    const performAuthCallback = async () => {
      const resultAction = await dispatch(handleAuthCallback());

      if (handleAuthCallback.fulfilled.match(resultAction)) {
        await handleSuccess();
      } else {
        handleError();
      }
    };

    void performAuthCallback();
  }, [
    clearFulfillmentCenter,
    dispatch,
    fetchFulfillmentCenter,
    siteFulfillmentCenterId,
    getAllFulfillmentCenters,
    errorToast,
    isBevClientLogoutDecouplingEnabled,
    navigate,
    sendUserEventAction,
    loginPathToUse
  ]);

  return <LoadingBackdrop open />;
}

export default connector(AuthCallback);
