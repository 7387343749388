/*  prevent the browser tab from sleeping

    Browsers use various heuristics to decide whether to put a tab to sleep, including:

      * Holding a web lock

    Assuming this is true, we create a lock that never resolves or rejects to keep the tab awake.

    This method is recommended by Microsoft for SignalR connection docs:
    https://docs.microsoft.com/en-ca/aspnet/core/signalr/javascript-client?view=aspnetcore-6.0&tabs=visual-studio#bsleep

    Web locks were not created to be used for keeping a tab awake, but we are using it as a working hack.
    Web locks are intended to briefly block other tabs from accessing a resource while the lock is active.
    There are other ways to keep the tab awake, such as using a setTimeout.

    Query connections with await navigator.locks.query()
*/

import { useEffect } from "react";

// Typescript doesn't recognize locks as valid.
export type LockMode = "exclusive" | "shared";
export interface ILocks {
  request: (
    name: string,
    options: { mode: LockMode },
    callback: () => Promise<unknown>
  ) => void;
}

export interface INavigatorLocks {
  locks?: ILocks;
}

export const useWebLock = () => {
  useEffect(() => {
    const navigator = window.navigator as INavigatorLocks;
    if (navigator && navigator.locks && navigator.locks.request) {
      navigator.locks.request(
        "qubit_signalr_lock",
        { mode: "shared" },
        // create a promise that never resolves or rejects.
        // This keeps the lock active indefinitely instead of resolving the lock.
        () => new Promise(() => {})
      );
    }
  }, []);
};

export default useWebLock;
