import { Fab, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useCallback, useEffect, useState } from "react";

import { mobileWidth } from "~/lib/theme";

import { AnimatedCheckMark, AnimatedX } from "./AnimatedCheckMark";
import BarcodeIcon from "./BarcodeIcon";

export const useScanIndicator = () => {
  const [scanState, setScanState] = useState<"ready" | "success" | "error">(
    "ready"
  );

  const handleSetScanState = useCallback(
    (success: "ready" | "success" | "error") => setScanState(success),
    []
  );

  useEffect(() => {
    if (scanState !== "ready") {
      window.setTimeout(() => setScanState("ready"), 1000);
    }
  }, [scanState]);
  return [scanState, handleSetScanState] as const;
};

function ScanningIndicator({
  scanState,
  scannedBarcode,
  placeholderText,
  position
}: {
  scanState: "error" | "success" | "ready";
  scannedBarcode: string | null;
  placeholderText: string;
  position?: "left" | "right";
}) {
  const { palette } = useTheme();
  const isMobile = useMediaQuery(mobileWidth);
  const [buttonExpanded, setButtonExpanded] = useState(false);

  return (
    <Fab
      aria-label="scan"
      onClick={() => setButtonExpanded(!buttonExpanded)}
      style={{
        backgroundColor: "white",
        zIndex: 1202,
        position: "fixed",
        bottom: isMobile ? 40 : 16,
        right: position === "right" || !position ? 16 : undefined,
        left: position === "left" ? 16 : undefined,
        borderRadius: 28,
        minWidth: 56,
        width: "fit-content",
        maxWidth: buttonExpanded ? "80%" : 56,
        transition: "max-width .3s 0.1s ease-in-out",
        overflow: "hidden",
        textOverflow: "clip",
        whiteSpace: "nowrap"
      }}
    >
      {!buttonExpanded && (
        <>
          <BarcodeIcon height={50} hide={scanState !== "ready"} />
          {scanState === "success" && (
            <AnimatedCheckMark checkColor={palette.success.dark} size={40} />
          )}
          {scanState === "error" && (
            <AnimatedX color={palette.autostoreRed.main} size={40} />
          )}
        </>
      )}

      {buttonExpanded && (
        <Typography
          variant="subtitle1"
          style={{ marginLeft: 16, marginRight: 16, textTransform: "none" }}
        >
          {scannedBarcode ? `Last Scan: ${scannedBarcode}` : placeholderText}
        </Typography>
      )}
    </Fab>
  );
}

export default ScanningIndicator;
