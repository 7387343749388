import * as Sentry from "@sentry/react";
import { ErrorBoundaryProps } from "@sentry/react";
import { AxiosError } from "axios";

import { ErrorPage } from "./ErrorPage";

export function ErrorBoundary(props: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        const axiosError = error as AxiosError;
        const code = axiosError?.response?.status;
        const message = axiosError?.message;

        return (
          <ErrorPage
            errorMessage={message}
            errorCode={code}
            goBackLocation="/"
          />
        );
      }}
      {...props}
    />
  );
}
