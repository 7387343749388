import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type State = {
  dropdownWorkstationId: string;
};

const initialState: State = {
  dropdownWorkstationId: ""
};

export const workstationConfigSlice = createSlice({
  name: "workstationConfig",
  initialState,
  reducers: {
    setDropdownWorkstationId(state, { payload }: PayloadAction<string>) {
      state.dropdownWorkstationId = payload;
    }
  },
  selectors: {
    selectDropdownWorkstationId: (state: State) => state.dropdownWorkstationId
  }
});

export const { setDropdownWorkstationId } = workstationConfigSlice.actions;

export const { selectDropdownWorkstationId } = workstationConfigSlice.selectors;
