// start-cycle-counting
import {
  IncompleteCycleCountsResponse,
  SetCycleCountFrequencyRequest,
  StartCycleCountingRequestDto
} from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const cycleCountApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getIncompleteCycleCounts: build.query<
      IncompleteCycleCountsResponse,
      {
        limit?: number;
        offset?: number;
        gridId?: Guid;
        AutoStoreBins: boolean;
        variantId?: Guid;
      }
    >({
      query: ({ limit, offset, gridId, AutoStoreBins, variantId }) => {
        return {
          url: "/api/cycle-counts-overhaul/incomplete-cycle-counts",
          params: { limit, offset, gridId, AutoStoreBins, variantId }
        };
      },
      providesTags: ["cycle count data"]
    }),
    postStartCycleCounting: build.mutation<
      void,
      {
        cycleCountIds: Guid[] | undefined;
        gridId: Guid | undefined;
        workstationId: Guid | undefined;
      }
    >({
      query: ({ cycleCountIds, gridId, workstationId }) => {
        const request: StartCycleCountingRequestDto = {
          cycleCountIds: cycleCountIds ?? []
        };

        return {
          url: `/api/autostore-grid/${gridId}/workstation/${workstationId}/start-cycle-counting`,
          method: "POST",
          data: request
        };
      }
    }),
    postCompleteCountingTask: build.mutation<
      void,
      {
        cycleCountId: Guid;
        inventoryId: Guid;
        count: number;
        expiration: Date | null;
        gridId: Guid;
        portId: number;
        workstationId: Guid;
      }
    >({
      query: ({
        cycleCountId,
        inventoryId,
        count,
        expiration,
        gridId,
        portId,
        workstationId
      }) => {
        return {
          url: `/api/cycle-counts-overhaul/complete-counting-task`,
          method: "POST",
          data: {
            CycleCountId: cycleCountId,
            InventoryId: inventoryId,
            ActualCount: { Value: count, Units: "ea" },
            ActualExpiration: expiration,
            AutostoreGridId: gridId,
            AutostorePortId: portId,
            WorkstationId: workstationId
          }
        };
      }
    }),
    putCycleCountFrequency: build.mutation<
      void,
      {
        variantId: Guid;
        frequency: number;
        workstationId?: Guid;
      }
    >({
      query: ({ variantId, frequency, workstationId }) => {
        const args: SetCycleCountFrequencyRequest = {
          variantId,
          frequency,
          ...(workstationId && { workstationId })
        };
        return {
          url: `/api/cycle-counts-overhaul/set-frequency`,
          method: "PUT",
          data: args
        };
      }
    })
  }),
  overrideExisting: false
});
