import { createSlice } from "@reduxjs/toolkit";

export interface BatchState {
  isAssignCartModalOpen: boolean;
  isUnassignCartModalOpen: boolean;
}

const initialState: BatchState = {
  isAssignCartModalOpen: false,
  isUnassignCartModalOpen: false
};

export const batchSlice = createSlice({
  name: "batch",
  initialState,
  reducers: {
    closeAssignCartModal(state) {
      state.isAssignCartModalOpen = false;
    },
    closeUnassignCartModal(state) {
      state.isUnassignCartModalOpen = false;
    },
    openAssignCartModal(state) {
      state.isAssignCartModalOpen = true;
    },
    openUnassignCartModal(state) {
      state.isUnassignCartModalOpen = true;
    }
  }
});

export const {
  closeAssignCartModal,
  closeUnassignCartModal,
  openAssignCartModal,
  openUnassignCartModal
} = batchSlice.actions;
