import Button from "@locaisolutions/button";

import { Dialog } from "@mui/material";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { BinNotEmptyModal } from "~/features/autostorePutaway/modals/BinNotEmptyModal";

import { useToast } from "~/hooks/useToast";
import { useKeyDownHandler } from "~/lib/barCodeScan";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";

import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";
import { usePostBinNotEmptyInventoryMovementMutation } from "~/redux/warehouse/autostoreGrid.hooks";
import { InventoryMovementResponse } from "~/types/api";

import { setCurrentBins } from "./manageFlaggedInventory.slice";

export function BinNotEmptyButton({
  inventoryActionsEnabled,
  currentBins,
  handleCleanup
}: {
  inventoryActionsEnabled: boolean;
  currentBins?: InventoryMovementResponse;
  handleCleanup: () => void;
}) {
  useKeyDownHandler();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { successToast, errorToast } = useToast();

  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const workstation = useAppSelector(selectThisWorkstation);

  const [isScanModalOpen, setIsScanModalOpen] = useState(false);

  const [setBinNotEmpty] = usePostBinNotEmptyInventoryMovementMutation();

  const handleSetBinNotEmpty = async (productIdentifier: string) => {
    if (autostoreGridId && workstation?.id && currentBins?.destinationBin) {
      try {
        const nextBins = await setBinNotEmpty({
          gridId: autostoreGridId,
          workstationId: workstation.id,
          productIdentifier: productIdentifier,
          compartment: currentBins?.destinationBin.compartmentNumber
        }).unwrap();
        dispatch(setCurrentBins(nextBins));
        successToast(t("hold placed on UPC") + " " + productIdentifier);
      } catch (err) {
        errorToast(getMessageFromRtkError(err));
      }
    } else {
      errorToast(
        "Can't set 'Bin Not Empty' hold. Invalid workstation or grid data."
      );
    }
    handleCleanup();
  };

  return (
    <>
      <Button
        size="large"
        onClick={() => setIsScanModalOpen(true)}
        disabled={!inventoryActionsEnabled}
        sx={{ fontWeight: "normal" }}
      >
        {t("bin not empty")}
      </Button>
      <Dialog
        onClose={() => {
          setIsScanModalOpen(false);
        }}
        open={isScanModalOpen}
        maxWidth="tablet"
      >
        {isScanModalOpen && (
          <BinNotEmptyModal
            binId={currentBins?.destinationBin.warehouseBinId}
            setIsBinNotEmptyPanelOpen={setIsScanModalOpen}
            handleSetBinNotEmpty={handleSetBinNotEmpty}
          />
        )}
      </Dialog>
    </>
  );
}
