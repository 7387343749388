import { LDClient, LDContext } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";

export const useLaunchDarklySetClient = (args: {
  clientId: string | null;
  userId: string | null;
  ldClient: LDClient | undefined;
  fcId?: Guid;
}) => {
  const { clientId, userId, ldClient, fcId } = args;
  const [isClientReady, setIsClientReady] = useState(false);

  useEffect(() => {
    const identifyUser = async () => {
      const key =
        clientId || userId || fcId
          ? `${clientId || ""}-${userId || ""}`
          : "unknown";
      const userContext = {
        kind: "user",
        key
      };
      const fcContext = {
        kind: "fulfillment-center",
        key: fcId
      };
      const clientContext = {
        kind: "client",
        key: clientId
      };

      const context: LDContext = {
        kind: "multi",
        user: userContext,
        ...(clientId && { client: clientContext }),
        ...(fcId && {
          "fulfillment-center": fcContext
        })
      };
      if (ldClient) {
        try {
          // 5 seconds is recommended by LaunchDarkly
          await ldClient.waitForInitialization(5);
          await ldClient.identify(context);
          setIsClientReady(true);
        } catch (error) {
          console.error("Error identifying user with LaunchDarkly:", error);
        }
      }
    };

    void identifyUser();
  }, [userId, clientId, ldClient, fcId]);

  // the hook will return false until the useEffect's async thread's ldClient is ready to use.
  // when the thread is complete, the hook will return true causing the component to re-render.
  return isClientReady;
};

export default useLaunchDarklySetClient;
