import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import CallSplitRoundedIcon from "@mui/icons-material/CallSplitRounded";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayForWorkIcon from "@mui/icons-material/PlayForWork";
import PrintIcon from "@mui/icons-material/Print";
import UndoIcon from "@mui/icons-material/Undo";
import { Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardActionArea from "@mui/material/CardActionArea";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import QRCode from "qrcode.react";
import { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useReactToPrint } from "react-to-print";

import { getUserClientId } from "~/api/usersTypes/auth0Profile";
import {
  parsePickMenuOption,
  PickMenuOption,
  PickScanToteConfiguration
} from "~/api/warehouseTypes/fulfillmentCenter";
import { ErrorSuccessSnackbar } from "~/components/ErrorSuccessSnackbar";
import PrintableToteLabels, {
  LabelInfo
} from "~/components/PrintableToteLabels";
import { BinLocation } from "~/components/productCard/BinLocation";
import { CardAvatar } from "~/components/productCard/CardAvatar";
import { ProductCardContainer } from "~/components/productCard/ProductCardContainer";
import { ProductImage } from "~/components/productCard/ProductImage";
import envConstants from "~/config/envConstants";
import { formatTime } from "~/lib/helpers";
import { createNumberSequence } from "~/lib/shared";

import {
  fulfillPick,
  outOfStockPick,
  splitPick,
  startWeightAdjustment
} from "~/redux/actions/batch";
import {
  printToDefaultPrinter,
  clearErrorMessage
} from "~/redux/actions/printer";
import { StoreState } from "~/redux/reducers";
import { ActivePickCompletion } from "~/redux/reducers/batch";
import { ToteSummaryDto } from "~/types/api";

import { PickModel } from "./BatchViewModel";
import BulkPickLabels from "./BulkPickLabels";
import { PickMenu, PickMenuItemProps } from "./PickMenu";
import Split from "./Split";
import { toteLabelZPL, bulkLabelZPL } from "./toteLabelZpl";

// inject CSS to:
// 1) remove animation from progress bar
const sheet = document.createElement("style");
document.body.appendChild(sheet);
sheet.innerHTML = `

  .MuiLinearProgress-dashed {
    animation: none;
  }

  .MuiLinearProgress-dashedColorPrimary {
    background-image: none;
  }
`;

const snPickScanToteConfig: PickScanToteConfiguration = "OncePerPick";
const pickScanToteConfiguration = snPickScanToteConfig;

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: 6
}));

type ExtraToteProps = {
  top: number;
  left: string;
  zIndex: number;
  // prefixing with a dollar sign ($) prevents this prop from being forwarded.
  $topRow: boolean;
};

const ExtraTote = styled(Box)`
  z-index: ${(props: ExtraToteProps) => props.zIndex || 0};
  position: absolute;
  top: ${(props: ExtraToteProps) => props.top || 0};
  left: ${(props: ExtraToteProps) => props.left || 0};
  box-shadow: ${(props: ExtraToteProps) =>
    props.$topRow
      ? "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
      : "0px -3px 1px -2px rgb(0 0 0 / 20%), 0px -2px 2px 0px rgb(0 0 0 / 14%), 0px -1px 5px 0px rgb(0 0 0 / 12%)"};
`;

const mapStateToProps = (state: StoreState) => ({
  fulfillmentCenter: state.store.usersFulfillmentCenter,
  clientId: getUserClientId(state.login.profile),
  batchViewModel: state.batch.batchViewModel,
  batchTotes: state.batch.batchTotes,
  batchLoosePicks: state.batch.batchLoosePicks,
  loadingBatchTotes: state.batch.loadingBatchTotes,
  defaultPrinter: state.printer.defaultPrinter,
  printerError: state.printer.error
});

const connector = connect(mapStateToProps, {
  fulfillPick,
  outOfStockPick,
  splitPick,
  startWeightAdjustment,
  printToDefaultPrinter,
  clearErrorMessage
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export type PickProps = PropsFromRedux & {
  pickModel: PickModel;
  isFulfilling: boolean;
  activePickCompletion?: ActivePickCompletion;
  addToteToBatch?: (currentToteId: Guid) => void;
  revertPick?: (pick: PickModel) => void;
};

type CartDisplayProps = {
  cartCapacity: number;
  totePosition?: number;
  toteBoxSize?: string;
  toteBoxSpacing?: string;
  batchTotes: ToteSummaryDto[];
  loading: boolean;
};

export function CartDisplay(props: CartDisplayProps) {
  const {
    cartCapacity,
    loading,
    totePosition,
    batchTotes,
    toteBoxSize,
    toteBoxSpacing
  } = props;

  // TODO: one day this might be configured by FC
  const rows = 2;
  const totesPerRow = cartCapacity / rows;

  const cartTotePositions = createNumberSequence(cartCapacity);
  const cartRows = createNumberSequence(rows);

  return (
    <Grid container direction="column" spacing={1}>
      {cartRows.map((row) => {
        const firstPositionForRow = (row - 1) * totesPerRow;
        const lastPositionForRow = totesPerRow * row - 1;
        const isTopRow = row === 1;
        const totePositionsForRow = cartTotePositions.slice(
          firstPositionForRow,
          firstPositionForRow + totesPerRow
        );

        return (
          <Grid
            key={row}
            item
            container
            direction="row"
            justifyContent="center"
            sx={{ display: "flex", flexWrap: "nowrap" }}
          >
            {totePositionsForRow.map((cartPosition) => {
              const isTargetTote =
                totePosition && cartPosition === totePosition;

              const isFirstToteInRow = cartPosition - 1 === firstPositionForRow;
              const isLastToteInRow = cartPosition - 1 === lastPositionForRow;

              const totesInCartPosition = batchTotes.filter(
                (tote) => tote.totePosition === cartPosition
              );

              const extraTotes = totesInCartPosition.slice(1, 3);

              return (
                <Grid
                  item
                  key={`${row} - ${cartPosition}`}
                  style={{
                    position: "relative",
                    padding: toteBoxSpacing || "0 4px"
                  }}
                >
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={toteBoxSize || "2.2em"}
                      height={toteBoxSize || "2.2em"}
                      component="div"
                    />
                  ) : (
                    <>
                      {/* first tote element */}
                      <Box
                        width={toteBoxSize || "2.2em"}
                        height={toteBoxSize || "2.2em"}
                        borderRadius="1px"
                        bgcolor={
                          isTargetTote && totesInCartPosition.length === 1
                            ? "info.dark"
                            : "darkGray.light"
                        }
                        sx={{ zIndex: 1, position: "relative" }}
                      >
                        {isTargetTote && totesInCartPosition.length === 1 && (
                          <PlayForWorkIcon
                            htmlColor="#fff"
                            fontSize="large"
                            style={{
                              width: "100%",
                              height: "100%"
                            }}
                          />
                        )}
                      </Box>
                      {/* extra tote element. Dont show the first tote */}
                      {extraTotes.map((tote, i) => {
                        const extraToteNumber = i + 1;
                        const isToteHighlighted =
                          isTargetTote && i + 1 === extraTotes.length;
                        const topOffset = isTopRow
                          ? 4 - 4 * extraToteNumber
                          : 6 + 3 * extraToteNumber;
                        let leftOffset = "unset";
                        if (isFirstToteInRow)
                          leftOffset = `${4 - 4 * extraToteNumber}px`;
                        if (isLastToteInRow)
                          leftOffset = `${5 + 3 * extraToteNumber}px`;

                        return (
                          <ExtraTote
                            key={tote.toteId}
                            id={tote.toteId}
                            width="2.2em"
                            height="2.2em"
                            borderRadius="1px"
                            bgcolor={
                              isToteHighlighted ? "info.dark" : "gray.main"
                            }
                            style={{ zIndex: 1, position: "relative" }}
                            top={topOffset}
                            left={leftOffset}
                            zIndex={i + 2}
                            $topRow={isTopRow}
                          >
                            {isToteHighlighted && (
                              <PlayForWorkIcon
                                htmlColor="#fff"
                                fontSize="large"
                              />
                            )}
                          </ExtraTote>
                        );
                      })}
                    </>
                  )}
                </Grid>
              );
            })}
          </Grid>
        );
      })}
    </Grid>
  );
}

export function PickLoading() {
  return (
    <ProductCardContainer>
      <CardHeader
        avatar={<Skeleton variant="circular" width={40} height={40} />}
        title={
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginBottom: 6 }}
          />
        }
        subheader={<Skeleton animation="wave" height={10} width="40%" />}
      />
      <CardActionArea disabled style={{ height: 220 }}>
        <Skeleton width={368} height={220} />
      </CardActionArea>
      <Box margin={2} data-testid="loading-skeleton">
        <Skeleton width="100%" height={100} />
      </Box>
    </ProductCardContainer>
  );
}

export function Pick(props: PickProps) {
  const {
    pickModel,
    batchViewModel,
    batchTotes,
    batchLoosePicks,
    fulfillmentCenter,
    activePickCompletion,
    loadingBatchTotes,
    defaultPrinter,
    printerError,
    isFulfilling
  } = props;
  const {
    imageFilename,
    upc,
    sku,
    pickBin,
    quantity,
    exceptionType,
    status,
    name,
    brandName,
    assignedToteId
  } = pickModel;

  const assignedTote = batchTotes.find(
    (tote) => tote.toteId === assignedToteId
  );

  const { pickDto } = pickModel;
  const { firstName, lastName, modifiers } = pickDto;

  const printerConfiguration = fulfillmentCenter?.printerConfiguration;

  const [splitOpen, toggleSplit] = useState(false);
  const [isFulfillingPick, setIsFulfillingPick] = useState(false);
  const [cardMenuAnchorEl, setCardMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const [modifiersAnchorEl, setModifiersAnchorEl] =
    useState<null | HTMLElement>(null);

  const printBulkComponentRef = useRef<HTMLDivElement>(null);
  const handleBulkPagePrint = useReactToPrint({
    pageStyle: `
      @page {
        margin: 0
      }
    `,
    content: () => printBulkComponentRef.current,
    onAfterPrint: () => window.focus()
  });

  const printToteComponentRef = useRef<HTMLDivElement>(null);
  const handlePagePrint = useReactToPrint({
    pageStyle: `
    @page {
      margin: 0
    }
  `,
    content: () => printToteComponentRef.current,
    onAfterPrint: () => window.focus()
  });

  const loosePick = batchLoosePicks.find(
    (lp) => lp.pickId === pickModel.pickDto.pickId
  );

  const bulkLabels = [
    {
      loosePickId: loosePick?.loosePickId || "",
      zone: loosePick?.temperatureZone || "",
      productName: pickModel.pickDto.name,
      customerName: [
        pickModel.pickDto.firstName,
        pickModel.pickDto.lastName
      ].join(" "),
      orderId: loosePick?.orderId || "",
      loosePickQty: pickModel.pickDto.quantity.value,
      externalOrderId: loosePick?.externalOrderId || "",
      putwallPosition: `${loosePick?.putWallIdentifier || ""}${
        loosePick?.putWallLaneIdentifier || ""
      }`
    }
  ];

  const handleBulkBrowserPrint = () => {
    const bulkZPL = bulkLabels.map((label) => bulkLabelZPL(label));
    if (!defaultPrinter && bulkZPL) props.printToDefaultPrinter(bulkZPL.join());
    if (bulkZPL && defaultPrinter) {
      defaultPrinter.send(bulkZPL.join());
    }
  };

  const toteLabels: undefined | LabelInfo[] = assignedTote && [
    {
      toteId: assignedTote.toteId,
      externalOrderId: assignedTote.externalOrderId || "",
      firstName,
      lastName,
      orderWindow: new Date(assignedTote.pickingEndTime).toLocaleString(
        "en-US",
        {
          timeZone: fulfillmentCenter?.timeZone || "America/New_York"
        }
      ),
      position: assignedTote.totePosition,
      toteNumber: assignedTote.toteNumber,
      zone: assignedTote.temperatureZone,
      batchName: batchViewModel?.batchName || "",
      putwallPosition: `${assignedTote.putWallIdentifier}${assignedTote.putWallLaneIdentifier}`,
      orderType: assignedTote.orderType
    }
  ];

  const handleBrowserPrint = () => {
    const labelsZPL = toteLabels?.map((label) => {
      const {
        zone,
        position = "",
        toteId,
        externalOrderId,
        putwallPosition,
        orderType
      } = label;
      const first = label.firstName || "";
      const last = label.lastName || "";
      return toteLabelZPL({
        zone,
        cartPosition: `${position}`,
        toteId,
        putwallPosition,
        customerName: `${first || ""} ${last || ""}`,
        orderType,
        externalOrderId
      });
    });
    if (!defaultPrinter && labelsZPL)
      props.printToDefaultPrinter(labelsZPL.join());
    if (labelsZPL && defaultPrinter) {
      defaultPrinter.send(labelsZPL.join());
    }
  };

  const handlePrint = () => {
    if (printerConfiguration === "BrowserPrint") {
      handleBrowserPrint();
    } else if (printerConfiguration === "PagePrint") {
      handlePagePrint();
    }
  };

  const handleBulkPrint = () => {
    if (printerConfiguration === "BrowserPrint") {
      handleBulkBrowserPrint();
    } else if (printerConfiguration === "PagePrint") {
      handleBulkPagePrint();
    }
  };

  const fulfilled = !!status && status === "Fulfilled";
  const outOfStock = exceptionType === "OutOfStock";
  const unfulfilled = status === "Unfulfilled";
  const isBulk = pickBin?.binType.toLowerCase() === "bulk";

  const prevIsFulfilledRef = useRef<boolean>(fulfilled);
  useEffect(() => {
    prevIsFulfilledRef.current = fulfilled;
  });
  const prevIsFulfilled = prevIsFulfilledRef.current;

  // print bulk labels when a bulk item is fulfilled (clicked or scanned)
  if (isBulk && fulfilled && !prevIsFulfilled) {
    if (printerConfiguration === "PagePrint") handleBulkPagePrint();
    if (printerConfiguration === "BrowserPrint") handleBulkBrowserPrint();
  }

  const closeCardHeaderMenu = (): void => {
    setCardMenuAnchorEl(null);
  };

  const handleWeightAdjSelect = (): void => {
    closeCardHeaderMenu();
    props.startWeightAdjustment({
      pick: pickModel.pickDto,
      weight: null,
      quantity: null
    });
  };

  const handleSplitSelect = (): void => {
    closeCardHeaderMenu();
    toggleSplit(!splitOpen);
  };

  const handleRevert = (): void => {
    closeCardHeaderMenu();
    if (batchViewModel && props.revertPick) props.revertPick(pickModel);
  };

  const handleOutOfStock = (): void => {
    closeCardHeaderMenu();
    if (batchViewModel && !isFulfillingPick) {
      setIsFulfillingPick(true);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props
        .outOfStockPick(batchViewModel, pickModel)
        .finally(() => setIsFulfillingPick(false));
    }
  };

  const handleAddTote = (): void => {
    if (assignedToteId && props.addToteToBatch)
      props.addToteToBatch(assignedToteId);
    closeCardHeaderMenu();
  };

  // revert is enabled when pick is fulfilled or when pick is OOS
  const isRevertDisabled = unfulfilled && exceptionType !== "OutOfStock";

  // are any of the picks completed and have the putId of this picks assignedToteId
  const isToteNonEmpty = batchViewModel?.picks.some(
    (pick) =>
      pick.pickDto.status === "Completed" &&
      pick.pickDto.putToteId === assignedToteId
  );
  // are there unfulfilled picks left for the same tote
  const unfulfilledPicksLeft = batchViewModel?.picks.some(
    (pick) =>
      pick.pickDto.status !== "Completed" &&
      pick.pickDto.assignedToteId === assignedToteId
  );
  // add tote disabled when pick is unfulfilled and in progress OR when its tote is empty OR when there are no picks left for that tote
  const isAddDisabled =
    (unfulfilled && !!activePickCompletion?.buffer) ||
    !isToteNonEmpty ||
    !unfulfilledPicksLeft;

  const clientConfiguration = fulfillmentCenter?.clientConfiguration;
  const pickMenuHiddenOptions: Set<PickMenuOption> = new Set(
    clientConfiguration?.pickMenuHiddenOptions
      ?.map(parsePickMenuOption)
      .filter((opt) => opt !== null) as PickMenuOption[]
  );

  const pickMenuItemProps: PickMenuItemProps[] = [
    {
      option: "Split",
      show: pickModel.quantity.value > 1,
      disabled: !unfulfilled,
      onClick: handleSplitSelect,
      children: [
        <StyledListItemIcon key="icon">
          <CallSplitRoundedIcon fontSize="large" />
        </StyledListItemIcon>,
        <ListItemText primary="Split" key="text" />
      ]
    },
    {
      option: "AdjustWeight",
      disabled: !unfulfilled,
      onClick: handleWeightAdjSelect,
      children: [
        <StyledListItemIcon key="icon">
          <FitnessCenterRoundedIcon fontSize="large" />
        </StyledListItemIcon>,
        <ListItemText primary="Adjust Weight" key="text" />
      ]
    },
    {
      option: "OutOfStock",
      disabled: !unfulfilled,
      onClick: handleOutOfStock,
      children: [
        <StyledListItemIcon key="icon">
          <CancelPresentationIcon fontSize="large" />
        </StyledListItemIcon>,
        <ListItemText primary="Out Of Stock" key="text" />
      ]
    },
    {
      option: "AddTote",
      show: Boolean(props.addToteToBatch && !isBulk),
      disabled: isAddDisabled,
      onClick: handleAddTote,
      className: "add-tote-menu-item",
      children: [
        <StyledListItemIcon key="icon">
          <AddBoxOutlinedIcon fontSize="large" />
        </StyledListItemIcon>,
        <ListItemText primary="Add Tote" key="text" />
      ]
    },
    {
      option: "PrintToteLabel",
      show: !isBulk,
      onClick: handlePrint,
      children: [
        <StyledListItemIcon key="icon">
          <PrintIcon fontSize="large" />
        </StyledListItemIcon>,
        <ListItemText primary="Print Tote Label" key="text" />
      ]
    },
    {
      option: "PrintBulkLabel",
      show: isBulk,
      onClick: handleBulkPrint,
      children: [
        <StyledListItemIcon key="icon">
          <PrintIcon fontSize="large" />
        </StyledListItemIcon>,
        <ListItemText primary="Print Bulk Label" key="text" />
      ]
    },
    {
      option: "Revert",
      show: Boolean(props.revertPick),
      disabled: isRevertDisabled,
      onClick: handleRevert,
      children: [
        <StyledListItemIcon key="icon">
          <UndoIcon fontSize="large" />
        </StyledListItemIcon>,
        <ListItemText primary="Revert" key="text" />
      ]
    }
  ];

  const cardHeaderMenu = (
    <PickMenu
      hiddenOptions={pickMenuHiddenOptions}
      menuItemProps={pickMenuItemProps}
      id="card-menu"
      anchorEl={cardMenuAnchorEl}
      keepMounted
      open={Boolean(cardMenuAnchorEl)}
      onClose={closeCardHeaderMenu}
    />
  );

  let percentageComplete: number | undefined;

  if (
    pickScanToteConfiguration === "OncePerPick" &&
    activePickCompletion &&
    props.pickModel.pickId === activePickCompletion.pickId &&
    props.pickModel.status === "Unfulfilled"
  ) {
    percentageComplete = activePickCompletion.completion;
  }

  let modalStatus:
    | "checked"
    | "error"
    | "loading"
    | "flagged"
    | "notice"
    | "none" = "none";

  if (isFulfillingPick) {
    modalStatus = "loading";
  } else if (fulfilled) {
    modalStatus = "checked";
  } else if (outOfStock) {
    modalStatus = "error";
    // } else if (itemIsFlagged) {
    //   modalStatus = "flagged";
  } else if (percentageComplete) {
    modalStatus = "notice";
  }
  if (isFulfilling) {
    modalStatus = "loading";
  }

  // TODO: disable click to fulfill once warehouse supports reverting partial weight adjusts/subs that arent fulfilled
  // this way users can't get stuck in partially weight adjusted picks

  // if pick is in progress (percentageComplete is non-zero)
  // and not all products have been scanned or weight-adjusted (percentageComplete is < 100),
  // disable click to fulfill
  // const clickToFulfillDisabled =
  //   !!percentageComplete && percentageComplete < 100;

  const pickingEndTime =
    assignedTote?.pickingEndTime || loosePick?.pickingEndTime;

  return (
    <>
      <ProductCardContainer>
        <CardHeader
          avatar={
            <CardAvatar
              quantity={quantity.value}
              quantityIconVariant="filled"
              unitOfMeasure={quantity.units}
            />
          }
          action={
            <IconButton
              aria-label="settings"
              onClick={(event) => {
                setCardMenuAnchorEl(event.currentTarget);
              }}
              className="more-options"
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          }
          title={name}
          subheader={brandName}
        />
        <BinLocation bin={pickBin || null} />
        <CardActionArea
          data-testid="card-action"
          onClick={(): void => {
            if (props.batchViewModel && !isFulfillingPick) {
              const pickQty =
                pickModel.quantity.units.toLowerCase() === "lb"
                  ? 1
                  : pickModel.quantity.value;
              const pickUpc = pickModel.upc;

              const generateBarcodes = createNumberSequence(pickQty).map(
                () => pickUpc
              );
              setIsFulfillingPick(true);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
              props
                .fulfillPick(
                  props.batchViewModel,
                  pickModel,
                  !fulfilled,
                  generateBarcodes
                )
                .finally(() => setIsFulfillingPick(false));
            }
          }}
          style={{ height: 220 }}
        >
          {envConstants.SHOW_SCANNABLE_BAR_CODES === "true" &&
            (modalStatus === "none" || modalStatus === "notice") && (
              <Box position="absolute" right={0} top="32px">
                <QRCode
                  id={pickModel.upc}
                  value={pickModel.upc}
                  size={48}
                  includeMargin={false}
                />
              </Box>
            )}
          <ProductImage
            imageFilename={imageFilename}
            modalStatus={modalStatus}
            modalStatusText={null}
            completionPercentage={percentageComplete}
            upc={upc}
            sku={sku}
          />
        </CardActionArea>
        <Grid container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {!!modifiers.length && (
              <Grid
                item
                container
                alignContent="center"
                justifyContent="flex-end"
                xs={4}
              >
                <Button
                  aria-controls="modifiers-list"
                  aria-haspopup="true"
                  size="small"
                  color="secondary"
                  className="modifiersButton"
                  onClick={(e): void => setModifiersAnchorEl(e.currentTarget)}
                >
                  Modifiers
                </Button>
                <Menu
                  open={!!modifiersAnchorEl}
                  id="modifier-list"
                  anchorEl={modifiersAnchorEl}
                  onClose={() => setModifiersAnchorEl(null)}
                >
                  <List dense onClick={() => setModifiersAnchorEl(null)}>
                    {modifiers.map((modifier) => (
                      <ListItem key={modifier.modifierId}>
                        <ListItemText
                          className="modifierList"
                          primary={`${modifier.quantity} ${modifier.name}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Menu>
              </Grid>
            )}
          </Grid>
          {pickDto.exception &&
            pickDto.exception.weightAdjustments.length > 0 && (
              <Grid item>
                <List dense subheader={<ListSubheader>Weights</ListSubheader>}>
                  {pickDto.exception.weightAdjustments.map(
                    (weightAdjustment) => (
                      <ListItem key={weightAdjustment.weightAdjustmentId}>
                        <ListItemText
                          primary={
                            <>
                              <Typography component="span" variant="body2">
                                {weightAdjustment.quantity}
                              </Typography>
                              <Typography
                                component="span"
                                variant="body2"
                                color="textSecondary"
                              >
                                {" @ "}
                              </Typography>
                              <Typography component="span" variant="body2">
                                {`${weightAdjustment.weight} ${pickDto.unitFormatted}`}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                    )
                  )}
                </List>
              </Grid>
            )}
        </Grid>

        <Grid container>
          <Grid
            container
            item
            xs={7}
            justifyContent="center"
            alignItems="center"
          >
            <Box marginTop={2} marginBottom={2}>
              {fulfillmentCenter && !isBulk && (
                <CartDisplay
                  cartCapacity={fulfillmentCenter?.cartCapacity}
                  totePosition={assignedTote?.totePosition}
                  loading={loadingBatchTotes}
                  batchTotes={batchTotes}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box marginTop={2} marginBottom={2}>
              <Typography variant="caption" display="block">
                {`${firstName} ${lastName}`}
              </Typography>
              <Typography
                variant="caption"
                display="block"
                color="textSecondary"
              >
                {fulfillmentCenter &&
                  pickingEndTime &&
                  formatTime(pickingEndTime, fulfillmentCenter.timeZone)}
              </Typography>
              {envConstants.SHOW_SCANNABLE_BAR_CODES === "true" &&
                !fulfilled &&
                !outOfStock &&
                assignedToteId && (
                  <QRCode
                    value={assignedToteId || ""}
                    size={48}
                    includeMargin={false}
                    id={assignedToteId || ""}
                  />
                )}
            </Box>
          </Grid>
          {pickScanToteConfiguration ===
            ("OncePerQuantity" as PickScanToteConfiguration) &&
            activePickCompletion &&
            props.pickModel.pickId === activePickCompletion.pickId &&
            props.pickModel.status === "Unfulfilled" && (
              <Grid item xs={12}>
                <LinearProgress
                  variant="buffer"
                  style={{ height: 20 }}
                  value={activePickCompletion.completion}
                  valueBuffer={activePickCompletion.buffer}
                />
              </Grid>
            )}
        </Grid>
      </ProductCardContainer>

      {/* card header menu */}
      {cardHeaderMenu}

      {/* split modal */}

      <Split
        open={splitOpen}
        pickDto={pickDto}
        closePanel={(): void => toggleSplit(false)}
        splitPick={(_pickId, splitQty): void => {
          if (batchViewModel) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
            props.splitPick(batchViewModel, pickModel, {
              units: pickModel.quantity.units,
              value: splitQty
            });
          }
        }}
      />
      <ErrorSuccessSnackbar
        errorMessage={printerError}
        clearErrorMessage={props.clearErrorMessage}
        successMessage={null}
        clearSuccessMessage={() => null}
      />
      {printerConfiguration === "PagePrint" && (
        <>
          <div style={{ display: "none" }}>
            {!loadingBatchTotes && !!toteLabels?.length && (
              <PrintableToteLabels
                labels={toteLabels}
                ref={printToteComponentRef}
              />
            )}
          </div>
          <div style={{ display: "none" }}>
            {isBulk && (
              <BulkPickLabels labels={bulkLabels} ref={printBulkComponentRef} />
            )}
          </div>
        </>
      )}
    </>
  );
}

export default connector(Pick);
