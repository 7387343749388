import LogoutIcon from "@locaisolutions/icons/dist/icons24px/Export24Px";

import { Box, ListItemButton, ListItemText } from "@mui/material";

import { t } from "i18next";
import { useNavigate } from "react-router-dom";

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/logout");
  };
  return (
    <ListItemButton key="Logout" onClick={handleLogout}>
      <Box
        sx={{
          width: "40px",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <LogoutIcon aria-label="logout icon" />
      </Box>
      <ListItemText
        primary={t("nav.link.logout")}
        sx={{
          fontSize: 16,
          padding: "0 14px",
          color: "text.primary"
        }}
      />
    </ListItemButton>
  );
};
export default LogoutButton;
