const supportsAudioContext =
  "AudioContext" in window || "webkitAudioContext" in window;
const supportsVibrate = "vibrate" in window.navigator;

export const makeSound = (
  waveType: OscillatorType,
  frequency: number,
  duration: number
): void => {
  if (supportsAudioContext) {
    const WindowAudioContext =
      // eslint-disable-next-line
      window.AudioContext || (window as any).webkitAudioContext;
    const context = new WindowAudioContext();
    const o = context.createOscillator();
    const g = context.createGain();
    o.connect(g);
    o.type = waveType;
    o.frequency.value = frequency;
    g.connect(context.destination);
    o.start(0);
    g.gain.exponentialRampToValueAtTime(
      0.00001,
      context.currentTime + duration
    );
  }
};

export const vibrate = (pattern: number | number[]): void => {
  if (supportsVibrate) window.navigator.vibrate(pattern);
};
