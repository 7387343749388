import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { t } from "i18next";

import { useGetAutostoreGridsQuery } from "~/redux/warehouse/autostoreGrid.hooks";

export function AutostoreGridSelector({
  selectedGrid,
  onSelect
}: {
  selectedGrid: string | undefined;
  onSelect: (value: string | undefined) => void;
}) {
  const { data: autostoreGrids } = useGetAutostoreGridsQuery();

  const handleChange = (event: SelectChangeEvent) => {
    onSelect(event.target.value);
  };

  return (
    <>
      <InputLabel shrink>{t("autostore grid")}</InputLabel>
      <Select
        aria-label="autostore grid selector"
        fullWidth
        value={selectedGrid || ""}
        onChange={handleChange}
        variant="standard"
      >
        {autostoreGrids?.map((grid) => (
          <MenuItem key={grid.autostoreGridId} value={grid.autostoreGridId}>
            {grid.autostoreGridName}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
