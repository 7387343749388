import { handleWarehouseError, warehouseService } from "~/api/warehouse";
import { AppThunk, AsyncAppThunk } from "~/app/store";
import {
  PutAwayItemRequest,
  PutAwayTaskSummaryDto,
  GridTargetPort,
  MeasuredValueDto
} from "~/types/api";

export interface GetPutawayByVariantAction {
  type: "putaway/GET_PUTAWAY_BY_VARIANT";
}

export interface GetPutawayByVariantSuccessAction {
  type: "putaway/GET_PUTAWAY_BY_VARIANT_SUCCESS";
  payload: PutAwayTaskSummaryDto[];
}

export interface GetPutawayByVariantFailureAction {
  type: "putaway/GET_PUTAWAY_BY_VARIANT_FAILURE";
  payload: string;
}

export interface PutAwayItemAction {
  type: "putaway/PUT_AWAY_ITEM";
}

export interface PutAwayItemSuccessAction {
  type: "putaway/PUT_AWAY_ITEM_SUCCESS";
}

export interface PutAwayItemFailureAction {
  type: "putaway/PUT_AWAY_ITEM_FAILURE";
  payload: string;
}

export interface ClearSuccessMessageAction {
  type: "putaway/CLEAR_SUCCESS_MESSAGE";
}

export interface ClearErrorMessageAction {
  type: "putaway/CLEAR_ERROR_MESSAGE";
}

export const getPutawayInventoryByVariant =
  (variantId?: Guid): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<GetPutawayByVariantAction>({
      type: "putaway/GET_PUTAWAY_BY_VARIANT"
    });
    try {
      const response = await warehouseService.get<PutAwayTaskSummaryDto[]>(
        `/api/put-away-tasks`,
        { params: { variantId, status: "scheduled" } }
      );
      dispatch<GetPutawayByVariantSuccessAction>({
        type: "putaway/GET_PUTAWAY_BY_VARIANT_SUCCESS",
        payload: response.data
      });
    } catch (err) {
      handleWarehouseError(err, (message) =>
        dispatch<GetPutawayByVariantFailureAction>({
          type: "putaway/GET_PUTAWAY_BY_VARIANT_FAILURE",
          payload: message
        })
      );
    }
  };

export const putAwayItem =
  (args: {
    putAwayTaskId: Guid;
    quantity: MeasuredValueDto;
    targetBinId: Guid;
    targetPort?: GridTargetPort;
    expiration?: Date | undefined;
    manufactureDate?: string | undefined;
    workstationId: Guid | undefined;
    decantingRate?: MeasuredValueDto;
    shouldForceUpdateDecantingRate?: boolean;
  }): AsyncAppThunk =>
  async (dispatch): Promise<void> => {
    dispatch<PutAwayItemAction>({
      type: "putaway/PUT_AWAY_ITEM"
    });
    try {
      const {
        putAwayTaskId,
        quantity,
        targetBinId,
        targetPort,
        workstationId,
        decantingRate,
        shouldForceUpdateDecantingRate
      } = args;
      const request: PutAwayItemRequest = {
        quantity,
        targetBinId,
        targetPort,
        targetExpiration: args.expiration,
        targetManufactureDate: args.manufactureDate,
        workstationId
      };

      if (
        (decantingRate && decantingRate?.value !== quantity.value) ||
        shouldForceUpdateDecantingRate
      ) {
        await warehouseService.patch<null>(
          `/api/put-away-tasks/${putAwayTaskId}/decanting-rate`,
          {
            quantity
          }
        );
      }

      await warehouseService.post<null>(
        `/api/put-away-tasks/${putAwayTaskId}/put-item-away`,
        request
      );

      dispatch<PutAwayItemSuccessAction>({
        type: "putaway/PUT_AWAY_ITEM_SUCCESS"
      });
    } catch (err) {
      handleWarehouseError(err, (message) =>
        dispatch<PutAwayItemFailureAction>({
          type: "putaway/PUT_AWAY_ITEM_FAILURE",
          payload: message
        })
      );
    }
  };

export const clearErrorMessage = (): AppThunk => (dispatch) => {
  dispatch<ClearErrorMessageAction>({
    type: "putaway/CLEAR_ERROR_MESSAGE"
  });
};

export const clearSuccessMessage = (): AppThunk => (dispatch) => {
  dispatch<ClearSuccessMessageAction>({
    type: "putaway/CLEAR_SUCCESS_MESSAGE"
  });
};
