import { sortWorkstations } from "~/features/workstationConfig/sortWorkstations";
import {
  AutostorePortDto,
  CreateWorkstation,
  UpdateWorkstation,
  WorkstationSummaryDto
} from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const workstationApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getWorkstations: build.query<WorkstationSummaryDto[], void>({
      query: () => ({
        url: `api/workstations`,
        method: "GET"
      }),
      transformResponse: (response: WorkstationSummaryDto[]) => {
        return sortWorkstations(response);
      },
      providesTags: ["workstation"]
    }),
    deleteWorkstation: build.mutation<
      void,
      {
        workstationId: Guid;
      }
    >({
      query: ({ workstationId }) => ({
        url: `api/workstations/${workstationId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["workstation"]
    }),
    putWorkstation: build.mutation<void, UpdateWorkstation>({
      query: (workstation) => ({
        url: `api/workstations/V2`,
        method: "PUT",
        data: workstation
      }),
      invalidatesTags: ["workstation"]
    }),
    postDeactivateWorkstation: build.mutation<void, string>({
      query: (workstationId) => ({
        url: `/api/workstations/${workstationId}/deactivate-workstation`,
        method: "POST"
      }),
      invalidatesTags: ["workstation"]
    }),
    postActivateWorkstation: build.mutation<void, string>({
      query: (workstationId) => ({
        url: `api/workstations/${workstationId}/activate-workstation`,
        method: "POST"
      }),
      invalidatesTags: ["workstation"]
    }),
    postCreateWorkstation: build.mutation<
      WorkstationSummaryDto,
      CreateWorkstation
    >({
      query: (workstation) => ({
        url: `api/workstations/V2`,
        method: "POST",
        data: workstation
      }),
      invalidatesTags: ["workstation"]
    }),
    getMultiports: build.query<AutostorePortDto[], string>({
      query: (autostoreGridId) => ({
        url: `api/autostore-grid/${autostoreGridId}/get-multi-ports`,
        method: "GET"
      }),
      providesTags: ["port"]
    }),
    getUnassignedPorts: build.query<number[], string>({
      query: (autostoreGridId) => ({
        url: `api/autostore-grid/${autostoreGridId}/get-unassigned-ports`,
        method: "GET"
      }),
      providesTags: ["workstation", "autostore grid"]
    }),
    startInduction: build.mutation<
      void,
      { autostoreGridId: Guid; workstationId: Guid }
    >({
      query: ({ autostoreGridId, workstationId }) => ({
        url: `/api/workstations/grid/${autostoreGridId}/workstation/${workstationId}/start-induction`,
        method: "POST"
      })
    }),
    startBinReconfiguration: build.mutation<
      void,
      { workstationId: Guid; binConfigurationId: Guid }
    >({
      query: ({ workstationId, binConfigurationId }) => ({
        url: `/api/workstations/${workstationId}/start-bin-reconfiguration`,
        method: "POST",
        data: { binConfigurationId }
      })
    }),
    confirmBinReconfiguration: build.mutation<
      void,
      {
        workstationId: Guid;
        sourceBinConfigurationId: Guid;
        targetBinConfigurationId: Guid;
        binNumber: number;
      }
    >({
      query: ({ workstationId, ...rest }) => ({
        url: `/api/workstations/${workstationId}/confirm-bin-reconfiguration`,
        method: "POST",
        data: rest
      }),
      invalidatesTags: ["empty bin report", "autostore bin", "port status"]
    })
  })
});
