import SvgDoorOpen20Px from "@locaisolutions/icons/dist/icons20px/DoorOpen20Px";
import { Box, Stack, SvgIcon, Typography } from "@mui/material";
import { t } from "i18next";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "~/app/store";

import { Center } from "~/components/Center";
import { useFlag } from "~/config/flags";
import { useCloseWorkstationWithErrorToast } from "~/hooks/useCloseWorkstationWithErrorToast";

import { sendUserEventAction } from "~/redux/actions/userEvents";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

import { clearLoginState } from "./login.slice";

type LogoutProps = {
  // TODO: remove when vitest mock timers works for react rendering (vitest v1?)
  time?: number;
};

export const Logout = ({ time = 2000 }: LogoutProps) => {
  const dispatch = useAppDispatch();
  const closeWorkstation = useCloseWorkstationWithErrorToast();
  const usersFulfillmentCenter = useSelector(selectUsersFulfillmentCenter);

  const isBevClientLogoutDecouplingEnabled =
    useFlag().bevClientLogoutFlowDecoupling;

  // functions
  const handleLogout = useCallback(async () => {
    // make sure the current port is closed
    if (usersFulfillmentCenter) {
      await closeWorkstation();
    }

    await dispatch(
      sendUserEventAction("UserLoggedOut", isBevClientLogoutDecouplingEnabled)
    );

    // after clearing the login state here,
    // a useEffect in the PrivateRoute component in App.tsx will
    // intercede and complete the auth0 logout.
    // TO FIX: the above steps (close ports, send user event) are skipped when the user times out
    // instead of using this logout button
    dispatch(clearLoginState());
  }, [
    dispatch,
    closeWorkstation,
    isBevClientLogoutDecouplingEnabled,
    usersFulfillmentCenter
  ]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      handleLogout();
    }, time); // 2000 milliseconds = 2 seconds

    // Cleanup function to clear the timeout if the component unmounts before the timeout finishes
    return () => clearTimeout(timeoutId);
  });

  return (
    <Center>
      <Stack spacing={8} marginTop="25vh">
        <Stack direction="row" justifyContent="start" alignItems="center">
          <SvgIcon
            component={SvgDoorOpen20Px}
            sx={{
              fontSize: 64,
              color: "primary",
              marginLeft: 2
            }}
          />
        </Stack>
        <Box>
          <Center>
            <Typography variant="h3">{t("Logging Off")}</Typography>
          </Center>
        </Box>
      </Stack>
    </Center>
  );
};
