import { Box } from "@mui/material";

export type LEDIndicatorProps = {
  status: "good" | "warning" | "error" | "calm" | "alert" | "inactive";
};

export function LEDIndicator(props: LEDIndicatorProps) {
  const { status } = props;

  let backgroundColor = "";
  let boxShadow = "";
  let transition = "none";

  switch (status) {
    case "good":
      backgroundColor = "#80FF00";
      boxShadow =
        "#000 0 -1px 6px 1px, inset #460 0 -1px 8px, #80FF00 0 3px 11px";
      break;
    case "warning":
      backgroundColor = "#FF0";
      boxShadow = "#000 0 -1px 6px 1px, inset #660 0 -1px 8px, #FF0 0 3px 11px";
      break;
    case "error":
      backgroundColor = "#F00";
      boxShadow = "#000 0 -1px 6px 1px, inset #600 0 -1px 8px, #F00 0 3px 11px";
      break;
    case "calm":
      backgroundColor = "#06F";
      boxShadow = "#000 0 -1px 6px 1px, inset #006 0 -1px 8px, #06F 0 3px 11px";
      break;
    case "alert":
      backgroundColor = "#FF7000";
      boxShadow =
        "#000 0 -1px 6px 1px, inset #630 0 -1px 8px, #FF7000 0 3px 11px";
      break;
    case "inactive":
    default:
      backgroundColor = "rgba(255, 255, 255, 0.25)";
      boxShadow = "#000 0 -1px 6px 1px";
      transition = "background-color .5s; box-shadow .5s;";
  }

  return (
    <Box
      sx={{
        backgroundColor: "#222",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Box
        role="status"
        sx={{
          m: 3,
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor,
          boxShadow,
          transition
        }}
      />
    </Box>
  );
}

export default LEDIndicator;
