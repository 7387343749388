import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Layout } from "react-grid-layout";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { handleWarehouseError, warehouseService } from "~/api/warehouse";
import { AsyncAppThunk } from "~/app/store";

import {
  resetAwsStatus,
  updateAwsWithBinClosed,
  updateAwsWithBinOpened,
  updateAwsWithHandToggled,
  updateAwsWithPortMode,
  isAutostoreEvent,
  MixedEventData
} from "~/lib/andonHelpers";
import { SetUserMessageAction } from "~/redux/actions/site";

import { workstationApi } from "~/redux/warehouse/workstation";
import {
  SystemMode,
  WorkstationPortDto,
  WorkstationSummaryDto
} from "~/types/api";

export type AndonViewOption =
  | "compact fullscreen"
  | "board fullscreen"
  | "default";

export type AndonStatus = "good" | "bad";

export type AndonEvent = {
  status?: AndonStatus;
  hub: string;
  timestamp: Date;
  eventData: MixedEventData;
};

export type AndonPort = {
  portId: number;
  isOpen: boolean;
  hasBins: number[];
  port: WorkstationPortDto;
  status: AndonStatus;
  events: AndonEvent[];
};

export type AndonParentPort = {
  portId: number;
  isOpen: boolean;
  hasBins: number[];
  status: AndonStatus;
  events: AndonEvent[];
};

export type AndonWorkstation = {
  id: string;
  workstation: WorkstationSummaryDto;
  status: AndonStatus;
  isOpen: boolean;
  handRaised: boolean;
  ports: AndonPort[];
  parentPort: AndonParentPort | null;
  events: AndonEvent[];
  hasBins: number[];
};

export type AndonWorkstations = {
  [workstationId: string]: AndonWorkstation;
};

type AndonGrid = {
  gridId: string;
  gridName: string;
  systemMode: SystemMode | null;
};

export type AndonGrids = {
  [gridId: string]: AndonGrid;
};

export const sendUserMessages =
  (args: {
    fcId: Guid;
    recipientIds: string[];
    message: string;
  }): AsyncAppThunk =>
  async (dispatch) => {
    const { fcId, recipientIds, message } = args;

    try {
      for (const recipientId of recipientIds) {
        await warehouseService.post(`/public-api/v1/users/message`, {
          FulfillmentCenterId: fcId,
          UserId: recipientId,
          Message: message
        });
      }
    } catch (err) {
      handleWarehouseError(err, (message2) => {
        dispatch<SetUserMessageAction>({
          type: "site/SET_USER_MESSAGE",
          payload: {
            title: message2,
            severity: "error"
          }
        });
      });
    }
  };

export interface AndonState {
  currentView: AndonViewOption;
  compactViewIds: string[];
  boardViewIds: string[];
  adminSummaryIds: string[];
  eventExplorerIds: string[];
  boardLayout: Layout[];
  boardDimensions: {
    columns: number;
    rows: number;
  };
  boardLocked: boolean;
  andonWorkstations: AndonWorkstations;
  andonGrids: AndonGrids;
  looseThreadEvents: AndonEvent[];
  eventSaveLimit: number;
  saveAllEvents: boolean;
  fetchWorkstationsInterval: number;
}

export const initialState = {
  currentView: "default",
  compactViewIds: [],
  boardViewIds: [],
  boardLayout: [],
  adminSummaryIds: [],
  eventExplorerIds: [],
  boardDimensions: {
    columns: 5,
    rows: 3
  },
  boardLocked: false,
  andonWorkstations: {},
  andonGrids: {},
  looseThreadEvents: [],
  eventSaveLimit: 1,
  saveAllEvents: false,
  fetchWorkstationsInterval: 0
} as AndonState;

export const andonSlice = createSlice({
  name: "andon",
  initialState,
  reducers: {
    setAndonViewOption(
      state: AndonState,
      action: PayloadAction<AndonViewOption>
    ) {
      state.currentView = action.payload;
    },
    setCompactViewIds(state: AndonState, action: PayloadAction<string[]>) {
      state.compactViewIds = action.payload;
    },
    setBoardViewIds(state: AndonState, action: PayloadAction<string[]>) {
      state.boardViewIds = action.payload;
    },
    setAdminSummaryIds(state: AndonState, action: PayloadAction<string[]>) {
      state.adminSummaryIds = action.payload;
    },
    setEventExplorerIds(state: AndonState, action: PayloadAction<string[]>) {
      state.eventExplorerIds = action.payload;
    },
    setBoardLayout(state: AndonState, action: PayloadAction<Layout[]>) {
      state.boardLayout = action.payload;
    },
    setBoardDimensions(
      state: AndonState,
      action: PayloadAction<{ columns: number; rows: number }>
    ) {
      state.boardDimensions = action.payload;
    },
    setBoardLocked(state: AndonState, action: PayloadAction<boolean>) {
      state.boardLocked = action.payload;
    },
    clearAndonWorkstationsAndGrids(state: AndonState) {
      state.andonGrids = {};
      state.andonWorkstations = {};
    },
    saveEventLimitData(state: AndonState, action: PayloadAction<number>) {
      state.eventSaveLimit = action.payload;
    },
    setEventSavingEnabled(state: AndonState, action: PayloadAction<boolean>) {
      state.saveAllEvents = action.payload;
    },
    setFetchWorkstationsInterval(
      state: AndonState,
      action: PayloadAction<number>
    ) {
      state.fetchWorkstationsInterval = action.payload;
    },
    setHandAction(
      state: AndonState,
      action: PayloadAction<{ workstationId: string; handRaised: boolean }>
    ) {
      const { workstationId, handRaised } = action.payload;
      const newAwss = {
        ...state.andonWorkstations,
        [workstationId]: {
          ...state.andonWorkstations[workstationId],
          handRaised
        }
      };
      state.andonWorkstations = newAwss;
    },
    setAndonLoginDefaults(state: AndonState) {
      const resetAndonWorkstations: AndonWorkstations = Object.fromEntries(
        Object.entries(state.andonWorkstations).map(([id, workstation]) => [
          id,
          resetAwsStatus(workstation)
        ])
      );
      state.andonWorkstations = resetAndonWorkstations;
    },
    clearEventData(state: AndonState) {
      const clearEventData: { [workstationId: string]: AndonWorkstation } =
        Object.fromEntries(
          Object.entries(state.andonWorkstations).map(
            ([id, workstation]: [string, AndonWorkstation]) => [
              id,
              {
                ...workstation,
                events: [],
                ports: workstation.ports.map((port: AndonPort) => ({
                  ...port,
                  isOpen: false,
                  status: "good",
                  events: [],
                  hasBins: []
                })),
                parentPort: workstation.parentPort
                  ? {
                      ...workstation.parentPort,
                      isOpen: false,
                      status: "good",
                      hasBins: [],
                      events: []
                    }
                  : null,
                isOpen: false,
                status: "good",
                hasBins: [],
                handRaised: false
              }
            ]
          )
        );

      let newAndonGrids: AndonGrids = {};

      for (const aws of Object.values(state.andonWorkstations)) {
        const gridId = aws.workstation.autostoreGridId;

        if (!Object.keys(newAndonGrids).includes(gridId)) {
          newAndonGrids = {
            ...newAndonGrids,

            [gridId]: {
              gridId,
              gridName: aws.workstation.autostoreGridName,
              systemMode: null
            }
          };
        }
      }

      state.andonWorkstations = clearEventData;
      state.looseThreadEvents = [];
      state.andonGrids = newAndonGrids;
    },
    saveEventData(
      state: AndonState,
      action: PayloadAction<{
        workstationId?: string;
        portId?: string;
        ptlDeviceId?: string;
        status?: AndonStatus;
        gridId?: string;
        hub: string;
        event: MixedEventData;
      }>
    ) {
      const { workstationId, portId, status, hub, event, ptlDeviceId } =
        action.payload;

      let workstationIdToUse = workstationId;

      // find workstation id from port id
      if (!workstationId && portId) {
        const matchingWorkstationId = Object.keys(state.andonWorkstations).find(
          (id) =>
            !!state.andonWorkstations[id].ports
              .map((awp) => awp.portId.toString())
              .includes(portId) ||
            // or parent port id
            state.andonWorkstations[id].parentPort?.portId.toString() === portId
        );

        workstationIdToUse = matchingWorkstationId;
      } else if (!workstationId && ptlDeviceId) {
        // find workstation from device id
        const matchingWorkstationId = Object.keys(state.andonWorkstations).find(
          (id) =>
            !!state.andonWorkstations[id].workstation.totePlacements
              .map((tp) => tp.putToLightModuleId.toString())
              .includes(ptlDeviceId)
        );

        workstationIdToUse = matchingWorkstationId;
      }

      let matchingAws: AndonWorkstation | undefined;

      const shapedAndonEvent: AndonEvent = {
        status,
        hub,
        timestamp: new Date(),
        eventData: event
      };

      let newLooseThreadEvents = state.looseThreadEvents.slice();
      let newAwss = { ...state.andonWorkstations };
      let newAndonGrids = { ...state.andonGrids };

      // .slice is used below to make the array stay at a certain length.
      // It keeps the most recently added elements.
      // [1,2,3,4,5,6,7,8,9,10].slice(-5) = [6, 7, 8, 9, 10]
      // [5, 6, 7, 8, 9, 10, 11].slice(-5) = [7, 8, 9, 10, 11]
      if (workstationIdToUse) {
        matchingAws = state.andonWorkstations[workstationIdToUse];

        if (matchingAws) {
          const isParentPort = !!(
            matchingAws.parentPort &&
            portId &&
            matchingAws.parentPort.portId.toString() === portId
          );

          // event stream v2
          if (isAutostoreEvent(event)) {
            switch (event.case) {
              // PortMode (grid hub)
              // port open and close
              case "PortMode": {
                matchingAws = updateAwsWithPortMode(
                  event,
                  matchingAws,
                  portId || "",
                  isParentPort
                );
                break;
              }

              case "BinModeChange": {
                matchingAws =
                  event.event.binMode === "O"
                    ? updateAwsWithBinOpened(
                        event,
                        matchingAws,
                        portId || "",
                        isParentPort
                      )
                    : event.event.binMode === "C"
                      ? updateAwsWithBinClosed(
                          event,
                          matchingAws,
                          portId || "",
                          isParentPort
                        )
                      : matchingAws;
                break;
              }
              case "HandToggled": {
                matchingAws = updateAwsWithHandToggled(event, matchingAws);
                break;
              }
              default:
            }
          }

          // general recording of events into andon workstations,
          // as well as adding matchingAws to new andon workstations
          newAwss = {
            ...state.andonWorkstations,
            [workstationIdToUse]: {
              ...matchingAws,
              status: status || matchingAws.status,
              events: [...matchingAws.events, shapedAndonEvent].slice(
                -state.eventSaveLimit
              ),
              ports: matchingAws.ports.map((ap) => {
                if (!portId) return ap;

                const isMatch = ap.portId.toString() === portId;

                return {
                  ...ap,
                  status: isMatch && status ? status : ap.status,
                  events: isMatch
                    ? [...ap.events, shapedAndonEvent].slice(
                        -state.eventSaveLimit
                      )
                    : ap.events
                };
              }),
              parentPort:
                isParentPort && matchingAws.parentPort
                  ? {
                      ...matchingAws.parentPort,
                      status: status || matchingAws.parentPort.status,
                      events: [
                        ...matchingAws.parentPort.events,
                        shapedAndonEvent
                      ].slice(-state.eventSaveLimit)
                    }
                  : matchingAws.parentPort || null
            }
          };
        }
      }

      if (!workstationIdToUse) {
        // event stream v2
        if (isAutostoreEvent(event)) {
          switch (event.case) {
            // SystemModeChanged (grid hub)
            // port open and close
            case "SystemModeChanged": {
              const caseCode = event.event.systemMode;
              const gridId = event.event.gridId;
              newAndonGrids = {
                ...state.andonGrids,
                [gridId]: {
                  ...state.andonGrids[gridId],
                  systemMode: caseCode
                }
              };
              break;
            }
            default:
          }
        }

        newLooseThreadEvents = [...state.looseThreadEvents, shapedAndonEvent];
      }

      state.looseThreadEvents = newLooseThreadEvents.slice(
        -state.eventSaveLimit
      );
      state.andonGrids = newAndonGrids;
      state.andonWorkstations = newAwss;
    }
  },
  selectors: {
    selectAndonWorkstations: (state: AndonState) => state.andonWorkstations,
    selectAndonGrids: (state: AndonState) => state.andonGrids,
    selectAdminSummaryIds: (state: AndonState) => state.adminSummaryIds,
    selectAndonSaveAllEvents: (state: AndonState) => state.saveAllEvents,
    selectAndonEventSaveLimit: (state: AndonState) => state.eventSaveLimit,
    selectEventExplorerIds: (state: AndonState) => state.eventExplorerIds,
    selectAndonBoardViewsIds: (state: AndonState) => state.boardViewIds,
    selectAndonCompactViewsIds: (state: AndonState) => state.compactViewIds,
    selectAndonBoardLayout: (state: AndonState) => state.boardLayout,
    selectAndonBoardDimensions: (state: AndonState) => state.boardDimensions,
    selectCurrentAndonBoardView: (state: AndonState) => state.currentView,
    selectAndonBoardLocked: (state: AndonState) => state.boardLocked,
    selectFetchWorkstationsInterval: (state: AndonState) =>
      state.fetchWorkstationsInterval,
    selectAndonLooseThreadEvents: (state: AndonState) =>
      state.looseThreadEvents,
    selectIsAndonBoardFullScreen: (state: AndonState) =>
      ["compact fullscreen", "board fullscreen"].includes(state.currentView)
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      workstationApi.endpoints.getWorkstations.matchFulfilled,
      (state, { payload }) => {
        const newStations: AndonWorkstations = {};
        let newAndonGrids: AndonGrids = { ...state.andonGrids };

        for (const fws of payload) {
          const gridId = fws.autostoreGridId;

          if (!Object.keys(newAndonGrids).includes(gridId)) {
            newAndonGrids = {
              ...newAndonGrids,

              [gridId]: {
                gridId,
                gridName: fws.autostoreGridName,
                systemMode: null
              }
            };
          }

          const existingAndonWorkstation = state.andonWorkstations[fws.id];

          if (!existingAndonWorkstation) {
            const parentPortId = fws.ports?.[0]?.parentPortId || null;

            newStations[fws.id] = {
              id: fws.id,
              workstation: fws,
              status: "good",
              handRaised: false,
              isOpen: false,
              hasBins: [],
              ports: fws.ports?.map((port) => ({
                portId: port.portId,
                isOpen: false,
                port,
                status: "good",
                events: [],
                hasBins: []
              })),
              parentPort: parentPortId
                ? {
                    portId: parentPortId,
                    isOpen: false,
                    status: "good",
                    events: [],
                    hasBins: []
                  }
                : null,
              events: []
            };
          } else {
            newStations[fws.id] = {
              ...existingAndonWorkstation,
              workstation: fws
            };
          }
        }

        state.andonWorkstations = {
          ...state.andonWorkstations,
          ...newStations
        };
        state.andonGrids = newAndonGrids;
      }
    );
  }
});

export const {
  setAndonViewOption,
  setCompactViewIds,
  setBoardViewIds,
  setEventExplorerIds,
  setAdminSummaryIds,
  setBoardLayout,
  setBoardDimensions,
  setBoardLocked,
  clearAndonWorkstationsAndGrids,
  saveEventLimitData,
  setEventSavingEnabled,
  setFetchWorkstationsInterval,
  setHandAction,
  setAndonLoginDefaults,
  clearEventData,
  saveEventData
} = andonSlice.actions;

export const {
  selectAndonWorkstations,
  selectAndonGrids,
  selectAdminSummaryIds,
  selectAndonSaveAllEvents,
  selectAndonEventSaveLimit,
  selectEventExplorerIds,
  selectAndonBoardViewsIds,
  selectAndonCompactViewsIds,
  selectAndonBoardLayout,
  selectAndonBoardDimensions,
  selectCurrentAndonBoardView,
  selectAndonBoardLocked,
  selectFetchWorkstationsInterval,
  selectAndonLooseThreadEvents,
  selectIsAndonBoardFullScreen
} = andonSlice.selectors;

const andonPersistConfig = {
  key: "andon",
  storage,
  whitelist: [
    "currentView",
    "compactViewIds",
    "boardViewIds",
    "adminSummaryIds",
    "eventExplorerIds",
    "boardLayout",
    "boardDimensions",
    "boardLocked",
    "andonWorkstations",
    "andonGrids",
    "eventSaveLimit",
    "looseThreadEvents",
    "saveAllEvents",
    "fetchWorkstationsInterval"
  ]
};

export const andonReducer = persistReducer(
  andonPersistConfig,
  andonSlice.reducer
);
