import {
  MigrationManifest,
  PersistState,
  createMigrate,
  persistReducer
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import { AndonStatus } from "~/features/andon/andon.slice";
import {
  ResetSiteWorkstationStatusAction,
  SetSiteFcAction,
  SetSitePortIdAction,
  SetSiteWorkstationStatusAction,
  SetThisWorkstationAction
} from "~/redux/actions/workstations";
import { WorkstationPortDto, WorkstationSummaryDto } from "~/types/api";

export interface CoordinateDto {
  x: number;
  y: number;
}

export interface TotePlacementDto {
  coordinate: CoordinateDto;
  putToLightModuleId?: number;
  totePosition: number;
}

export type WorkstationRepresentationProps = {
  size: number;
  rows: number[];
  columns: number[];
  operatorCoordinate: {
    x: number;
    y: number;
  };
  portCoordinates: WorkstationPortDto[];
  totePlacements: TotePlacementDto[];
  onClickCb: (args: { x: number; y: number }) => void;
};

export type WorkstationsState = {
  siteWorkstation: WorkstationSummaryDto | null;
  siteAllPortIds: number[];
  siteFulfillmentCenterId: Guid | null;
  sitePortId: number | null;
  siteWorkstationStatus: AndonStatus;
  sitePortStatuses: {
    portId: number;
    status: AndonStatus;
  }[];
};

export const initialState: WorkstationsState = {
  siteWorkstation: null,
  siteAllPortIds: [],
  siteFulfillmentCenterId: null,
  sitePortId: null,
  siteWorkstationStatus: "good",
  sitePortStatuses: []
};

type Actions =
  | SetThisWorkstationAction
  | SetSiteFcAction
  | SetSitePortIdAction
  | SetSiteWorkstationStatusAction
  | ResetSiteWorkstationStatusAction;

export const workstationsReducer = (
  state: WorkstationsState | undefined,
  action: Actions
): WorkstationsState => {
  if (state === undefined) {
    return initialState;
  }

  switch (action.type) {
    case "workstations/SET_THIS_WORKSTATION": {
      const ports = [...(action.payload?.ports || [])];

      const parentPortId = ports.find((p) => p.parentPortId)?.parentPortId;

      const siteAllPortIds = [
        ...ports.map((p) => p.portId),
        ...(parentPortId ? [parentPortId] : [])
      ];

      const sitePortStatuses = [
        ...ports.map((p) => ({
          status: "good" as AndonStatus,
          portId: p.portId
        })),
        ...(parentPortId
          ? [{ status: "good" as AndonStatus, portId: parentPortId }]
          : [])
      ];

      const siteWorkstation = action.payload
        ? { ...action.payload, ports }
        : null;

      return {
        ...state,
        siteWorkstation,
        siteAllPortIds,
        sitePortStatuses
      };
    }
    case "workstations/SET_SITE_FC":
      return {
        ...state,
        siteFulfillmentCenterId: action.payload
      };
    case "workstations/SET_SITE_PORT_ID":
      return {
        ...state,
        sitePortId: action.payload
      };
    case "workstations/SET_SITE_WORKSTATION_STATUS": {
      const sitePortStatuses = state.sitePortStatuses.map((ps) =>
        ps.portId === action.payload.portId
          ? { ...ps, status: action.payload.status }
          : ps
      );

      const siteWorkstationStatus: AndonStatus =
        sitePortStatuses.reduce<AndonStatus>(
          (acc, curr) =>
            acc === "good" && curr.status === "good" ? "good" : "bad",
          "good"
        );

      return {
        ...state,
        siteWorkstationStatus,
        sitePortStatuses
      };
    }
    case "workstations/RESET_SITE_WORKSTATION_STATUS": {
      const sitePortStatuses = state.sitePortStatuses.map((ps) => {
        return {
          ...ps,
          status: "good" as AndonStatus
        };
      });

      return {
        ...state,
        siteWorkstationStatus: "good" as AndonStatus,
        sitePortStatuses
      };
    }
    default:
      return state;
  }
};

type PersistWorkstationsStateV1 = Pick<
  WorkstationsState,
  | "siteWorkstation"
  | "siteAllPortIds"
  | "siteFulfillmentCenterId"
  | "sitePortId"
> & {
  _persist: PersistState;
};

type PersistWorkstationsStateV0 = Omit<
  PersistWorkstationsStateV1,
  | "siteWorkstation"
  | "siteAllPortIds"
  | "siteFulfillmentCenterId"
  | "sitePortId"
> & {
  thisWorkstation: WorkstationsState["siteWorkstation"];
  thisWorkstationAllPortIds: WorkstationsState["siteAllPortIds"];
  thisFcId: WorkstationsState["siteFulfillmentCenterId"];
  selectedPortId: WorkstationsState["sitePortId"];
};

const migrations: MigrationManifest = {
  1: (state): PersistWorkstationsStateV1 | undefined => {
    if (!state) {
      return state;
    }
    const {
      thisWorkstation,
      thisWorkstationAllPortIds,
      thisFcId,
      selectedPortId,
      ...otherState
    } = state as PersistWorkstationsStateV0;
    return {
      ...otherState,
      siteWorkstation: thisWorkstation,
      siteAllPortIds: thisWorkstationAllPortIds,
      siteFulfillmentCenterId: thisFcId,
      sitePortId: selectedPortId
    };
  }
};

const workstationsPersistConfig = {
  key: "workstations",
  version: 2,
  storage,
  debug: false,
  whitelist: [
    "siteWorkstation",
    "siteFulfillmentCenterId",
    "sitePortId",
    "siteAllPortIds",
    "siteWorkstationStatus",
    "sitePortStatuses"
  ],
  migrate: createMigrate(migrations, { debug: false })
};

export const workstations = persistReducer(
  workstationsPersistConfig,
  workstationsReducer
);
