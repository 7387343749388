import { BinSearchData, getBinIds } from "~/features/inventory/getBinIds";
import { FulfillmentCenterDto } from "~/types/api";

export const fetchWarehouseBinIds = async ({
  selectedAutostoreGridId,
  clientId,
  usersFulfillmentCenter,
  binNumberParam
}: {
  selectedAutostoreGridId: string;
  clientId: string | null;
  usersFulfillmentCenter?: FulfillmentCenterDto | null;
  binNumberParam: string;
}) => {
  const binData = await getBinIds({
    usersAutostoreGridId: selectedAutostoreGridId,
    usersClientId: clientId,
    usersFulfillmentCenter: usersFulfillmentCenter,
    searchText: binNumberParam
  });

  const foundBinObj = binData.find(
    (binObj: BinSearchData) =>
      String(binObj.autostore_bin_number) === binNumberParam
  );

  return foundBinObj ? foundBinObj.warehouse_bin_ids : null;
};
