import ASButton from "@locaisolutions/button";
import { Bin20Px, BookmarkFill20Px, Upload20Px } from "@locaisolutions/icons";

import { Grid, SvgIcon } from "@mui/material";
import { t } from "i18next";

import { useCallback, useState } from "react";
import { useLocation } from "react-router";

import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import FlagBinModal from "~/features/autostorePutaway/modals/FlagBinModal";

import usePortStatus from "~/hooks/usePortStatus";
import { useToast } from "~/hooks/useToast";
import { isAutostoreView } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  clearSelectedInventoryId,
  clearSelectedVariant,
  getInventoryReport
} from "~/redux/actions/inventory";
import { selectSelectedInventoryBin } from "~/redux/selectors/autostoreSelectors";
import { selectSelectedInventoryRows } from "~/redux/selectors/inventorySelectors";

import { useLazyGetInventorySummaryListQuery } from "~/redux/warehouse/inventory.hooks";
import { InventorySummaryDto } from "~/types/api";

import { MoveInventoryButton } from "./MoveInventoryButton";

import {
  setIsAdjustingBins,
  setIsInventoryAdjustDialogOpen,
  setIsGetBinsLoading,
  updateSelectedSummaries
} from "./inventory.slice";
import { useViewType } from "./useViewType";
import { mixpanelTrack } from "~/lib/mixpanel-tracking";

interface BottomActionsProps {
  isAdjustingBins: boolean;
  isGetBinsDisabled: boolean;
  selectedSummaries: InventorySummaryDto[];
  inventorySummaryToDisplay: InventorySummaryDto | null;
  selectedInventoryAtPort?: InventorySummaryDto;
  handleGetBinsMultiPort: (specificBinIds?: number[]) => Promise<void>;
  handleGetInventorySummaries: (args: {
    variantId?: Guid;
    searchedBinIds?: Guid | Guid[];
    offsetZero?: boolean;
    holds?: string[];
  }) => Promise<void>;
  searchedBinIds: Guid[] | null;
}

export const BottomActionButtons: React.FC<BottomActionsProps> = (props) => {
  const {
    isAdjustingBins,
    isGetBinsDisabled,
    selectedSummaries,
    inventorySummaryToDisplay,
    selectedInventoryAtPort,
    handleGetBinsMultiPort,
    handleGetInventorySummaries,
    searchedBinIds
  } = props;
  const locationInfo = useLocation();
  const dispatch = useAppDispatch();
  const { search } = locationInfo;
  const isAutostoreInventoryView = isAutostoreView(search);
  const { isBinView, isProductView, isProductsView } = useViewType();

  const { variantId: variantIdParam } = useParams<{
    variantId?: string;
  }>();
  const { successToast, errorToast } = useToast();
  const selectedRows = useAppSelector(selectSelectedInventoryRows);
  const currentSelectedBin = useAppSelector(selectSelectedInventoryBin);

  const selectedVariant = useAppSelector(
    (state) => state.inventory.selectedVariant
  );
  const pickingConfigurations = useAppSelector(
    (state) => state.store.usersFulfillmentCenter?.pickingConfigurations
  );
  const binFlaggingEnabled = useAppSelector(
    (state) => state.store.usersFulfillmentCenter?.binFlaggingEnabled
  );
  const portStateByPort = useAppSelector(
    (state) => state.autostore.portStateByPort
  );
  const siteWorkstation = useAppSelector(
    (state) => state.workstations.siteWorkstation
  );
  const portState = useAppSelector((state) => state.autostore.portState);
  const nextBinInventoryByPort = useAppSelector(
    (state) => state.autostore.nextBinInventoryByPort
  );
  const nextEmptyBinByPort = useAppSelector(
    (state) => state.autostore.nextEmptyBinByPort
  );
  const siteAllPortIds = useAppSelector(
    (state) => state.workstations.siteAllPortIds
  );

  const { areSomePortsReady, isSelectedBinReady } = usePortStatus(
    portStateByPort,
    siteAllPortIds,
    currentSelectedBin?.portId
  );
  const [isFlagBinModalOpen, setIsFlagBinModalOpen] = useState(false);
  const [getUpdatedInventorySummary] = useLazyGetInventorySummaryListQuery();
  const isSingleSelect: boolean = selectedRows.length === 1;
  const singleSelectSummary: InventorySummaryDto | null =
    (isSingleSelect && inventorySummaryToDisplay) || null;
  const includesManualOperation = pickingConfigurations?.includes("Manual");
  // if moving from an Autostore bin, require bin to be at port before enabling "Move" button
  const canMoveInventory =
    singleSelectSummary?.binType === "autostore"
      ? includesManualOperation &&
        portState?.isReady &&
        portState?.selectedBin === selectedInventoryAtPort?.autostoreBinNumber
      : includesManualOperation;

  const invSumToModify = isAdjustingBins
    ? selectedInventoryAtPort
    : inventorySummaryToDisplay;

  const refresh = useCallback(
    async ({ inventoryWasEmptied }: { inventoryWasEmptied: boolean }) => {
      if (isProductView) {
        await handleGetInventorySummaries({
          variantId: variantIdParam
        });
        dispatch(clearSelectedInventoryId());
      }
      if (isBinView && !!searchedBinIds?.length) {
        await handleGetInventorySummaries({
          searchedBinIds
        });
      }

      if (isProductsView) {
        await handleGetInventorySummaries({});
      }

      if (inventoryWasEmptied && isBinView) dispatch(clearSelectedVariant());

      if (selectedVariant) {
        await dispatch(getInventoryReport(selectedVariant.variantId));
      }
    },
    [
      dispatch,
      handleGetInventorySummaries,
      isBinView,
      isProductView,
      isProductsView,
      selectedVariant,
      variantIdParam,
      searchedBinIds
    ]
  );

  return (
    <>
      {isAutostoreInventoryView && (
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          {!isAdjustingBins && !isSelectedBinReady ? (
            <ASButton
              style={{ minWidth: "200px", fontWeight: "normal" }}
              variant="contained"
              color="primary"
              disabled={isGetBinsDisabled}
              startIcon={<Bin20Px fill="white" />}
              onClick={async () => {
                mixpanelTrack({
                  trackedPageName: "Inventory",
                  type: "Button Click",
                  label: "Get Bin"
                });
                dispatch(setIsAdjustingBins(true));
                try {
                  await handleGetBinsMultiPort();
                  dispatch(setIsGetBinsLoading(false));
                } catch (error) {
                  errorToast(getMessageFromRtkError(error));
                }
              }}
            >
              {isSingleSelect || selectedRows.length === 0
                ? t("get bin")
                : `${t("get bins")} (${selectedRows.length})`}
            </ASButton>
          ) : (
            <>
              <ASButton
                id="adjust-button"
                style={{ minWidth: "200px", fontWeight: "normal" }}
                size="small"
                onClick={() => {
                  dispatch(setIsInventoryAdjustDialogOpen(true));
                  mixpanelTrack({
                    trackedPageName: "Inventory",
                    type: "Button Click",
                    label: "Adjust",
                    eventProperties: currentSelectedBin
                  });
                }}
                disabled={!isSelectedBinReady}
                startIcon={<Upload20Px fill="white" />}
                kind="accent"
              >
                {t("adjust")}
              </ASButton>
              {binFlaggingEnabled && (
                <ASButton
                  id="flag-button"
                  style={{ minWidth: "200px", fontWeight: "normal" }}
                  size="small"
                  kind="subtle"
                  onClick={() => {
                    setIsFlagBinModalOpen(true);
                    mixpanelTrack({
                      trackedPageName: "Inventory",
                      type: "Button Click",
                      label: "Flag Bin",
                      eventProperties: currentSelectedBin
                    });
                  }}
                  disabled={!areSomePortsReady}
                  startIcon={
                    <SvgIcon sx={{ mt: 0.5 }} component={BookmarkFill20Px} />
                  }
                >
                  {t("flag bin")}
                </ASButton>
              )}
            </>
          )}
          {isSingleSelect && canMoveInventory && invSumToModify && (
            <MoveInventoryButton
              invToModify={invSumToModify}
              refreshCb={refresh}
            />
          )}
        </Grid>
      )}
      <FlagBinModal
        isOpen={isFlagBinModalOpen}
        onClose={() => setIsFlagBinModalOpen(false)}
        nextBinInventoryByPort={nextBinInventoryByPort}
        nextEmptyBinByPort={nextEmptyBinByPort}
        portStateByPort={portStateByPort}
        workstation={siteWorkstation}
        shouldCloseBinUponSubmit={false}
        onSuccess={async (binNumber) => {
          try {
            const inventoryToUpdate = selectedSummaries?.filter(
              (inv) => inv.autostoreBinNumber === binNumber
            );
            const query = getUpdatedInventorySummary({
              inventoryIds: [
                ...inventoryToUpdate.map((inv) => inv.inventoryId)
              ],
              limit: inventoryToUpdate.length,
              offset: 0
            });
            const queryResult = await query.unwrap();
            if (queryResult?.inventory.length > 0) {
              dispatch(updateSelectedSummaries(queryResult.inventory));
            }
            successToast(t("flag set successfully"));
          } catch (error) {
            errorToast(getMessageFromRtkError(error));
          }
        }}
      />
    </>
  );
};
