import { createSelector } from "@reduxjs/toolkit";

import { StoreState } from "~/redux/reducers";
import { AutostoreGridDto, GridConnectionModeResponse } from "~/types/api";

export const selectShouldListenToGridEvents =
  (
    connectionMode?: GridConnectionModeResponse,
    autostoreGrid?: AutostoreGridDto
  ) =>
  (state: StoreState) =>
    // State of the log publisher connection from the Backend to the grid
    connectionMode?.connectionMode === "Active" &&
    // Flag that determines if we want to listen to events even if the Backend is connected
    autostoreGrid?.enableBinOpenedEventListening &&
    // State of the connection from the Frontend to the Backend
    state.autostore.signalr.state === "Connected";

export const selectShowTwoBinComponents = (state: StoreState) =>
  state.autostore.showTwoBinComponents;
export const selectPortStateByPort = (state: StoreState) =>
  state.autostore.portStateByPort;
export const selectOpeningPort = (state: StoreState) =>
  state.autostore.openingPort;
export const selectIsOosModalOpenedAfterQtyModal = (state: StoreState) =>
  state.outOfStockDialog.isOosModalOpenedAfterQtyModal;
export const selectIsPickQuantityConfirmed = (state: StoreState) =>
  state.autostore.isPickQuantityConfirmed;
export const selectBinIsPresent = (state: StoreState) =>
  state.autostore.binIsPresent.status;
export const selectBinIsPresentBinId = (state: StoreState) =>
  state.autostore.binIsPresent.binId;
export const selectPickingState = (state: StoreState) =>
  state.autostore.pickingState;
export const selectPickedTote = (state: StoreState) =>
  state.autostore.pickedTote;
export const selectNextBinInventoryByPort = (state: StoreState) =>
  state.autostore.nextBinInventoryByPort;
export const selectNextPickingStateLoading = (state: StoreState) =>
  state.autostore.nextPickingStateLoading;
export const selectCartNumberConfirmed = (state: StoreState) =>
  state.autostore.cartNumberConfirmed;
export const selectLeftOrRightPortActive = (state: StoreState) =>
  state.autostore.leftOrRightPortActive;

export const selectBinsFlags = (state: StoreState) =>
  state.cycleCountsSlice.binsFlags;
export const selectSignalR = (state: StoreState) => state.autostore.signalr;
const selectSelectedAdjustingInventory = (state: StoreState) =>
  state.inventoryNew.selectedAdjustingSummary;

export const selectSelectedInventoryBin = createSelector(
  [selectNextBinInventoryByPort, selectSelectedAdjustingInventory],
  (nextBinInventoryByPort, inventorySummary) => {
    return Object.values(nextBinInventoryByPort).find((bin) =>
      bin.nextBinInventory.some(
        (inv) => inv.inventoryId === inventorySummary?.inventoryId
      )
    );
  }
);
