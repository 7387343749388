import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { ZoneContextManager } from "@opentelemetry/context-zone";

import {
  CompositePropagator,
  W3CBaggagePropagator,
  W3CTraceContextPropagator
} from "@opentelemetry/core";

import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { Resource } from "@opentelemetry/resources";

//exporters
import { BatchSpanProcessor } from "@opentelemetry/sdk-trace-base";

import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import {
  SEMRESATTRS_SERVICE_NAME,
  SEMRESATTRS_SERVICE_VERSION
} from "@opentelemetry/semantic-conventions";

import envConstants from "~/config/envConstants";

// The SemanticResourceAttributes is an enum that provides a set of predefined attribute keys for commonly used attributes in OpenTelemetry to maintain consistency across different OpenTelemetry implementations
const isDevelopment = envConstants.DEV;
const resourceSettings = new Resource({
  [SEMRESATTRS_SERVICE_NAME]: `Qubit.FE-${
    isDevelopment ? "local" : envConstants.RUNTIME_ENVIRONMENT
  }`,
  [SEMRESATTRS_SERVICE_VERSION]: `${envConstants.VERSION_TAG}`
});

// The `newRelicExporter` is an instance of OTLPTraceExporter configured to send traces to New Relic's OTPL-compatible backend.
// Make sure you have added your New Relic Ingest License to VITE_APP_OTEL_API_KEY env-var of your React App
const newRelicExporter = new OTLPTraceExporter({
  url: `${envConstants.APP_OTLP_URL}/v1/traces`,
  headers: {
    "api-key": `${envConstants.APP_OTLP_API_KEY}`
  }
});

const provider = new WebTracerProvider({ resource: resourceSettings });

//Uncomment this to enable debugging using consoleExporter
//provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));

// The BatchSpanProcessor is responsible for batching and exporting spans to the configured exporter (newRelicExporter in this case).
provider.addSpanProcessor(
  new BatchSpanProcessor(
    newRelicExporter,
    //Optional BatchSpanProcessor Configurations
    {
      // The maximum queue size. After the size is reached spans are dropped.
      maxQueueSize: 100,
      // The maximum batch size of every export. It must be smaller or equal to maxQueueSize.
      maxExportBatchSize: 50,
      // The interval between two consecutive exports
      scheduledDelayMillis: 500,
      // How long the export can run before it is cancelled
      exportTimeoutMillis: 30000
    }
  )
);

// ZoneContextManager is a context manager implementation based on the Zone.js library. It enables context propagation within the application using zones.
provider.register({
  contextManager: new ZoneContextManager(),
  // Configure the propagator to enable context propagation between services using the W3C Trace Headers
  propagator: new CompositePropagator({
    propagators: [new W3CBaggagePropagator(), new W3CTraceContextPropagator()]
  })
});

const startOtelInstrumentation = () => {
  if (!envConstants.APP_OTLP_URL || !envConstants.APP_OTLP_API_KEY) return;

  // Registering instrumentations
  registerInstrumentations({
    instrumentations: [
      getWebAutoInstrumentations({
        "@opentelemetry/instrumentation-xml-http-request": {
          enabled: true,
          ignoreUrls: [/events-.*\.qubit.autostoresystem.com\/*/i],
          clearTimingResources: true,
          propagateTraceHeaderCorsUrls: [/https:\/\/warehouse.+/g]
        },
        "@opentelemetry/instrumentation-document-load": {
          enabled: true
        },
        "@opentelemetry/instrumentation-user-interaction": {
          enabled: true
        }
      })
    ]
  });
};

export { startOtelInstrumentation };
