import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import { AxiosError, AxiosHeaders, AxiosRequestConfig } from "axios";

import { warehouseService } from "~/api/warehouse";

/** Add a tag to your query using 'providesTags' to take advantage of
 *  caching/invalidation. Tags must also be added here. For example, useful when
 *  you make a POST call and want the related GET call to update by itself.
 */
const tagTypes = [
  "autostore bin",
  "autostore grid",
  "autostore grid connection mode",
  "autostore grid status",
  "autostore task group",
  "batches",
  "bin cleaning report",
  "bin configuration",
  "bin cubing suggestion",
  "bin location information",
  "bin maintenance",
  "bin status",
  "bins outside grid",
  "cycle count data",
  "empty bin report",
  "focused pick",
  "fulfillment center",
  "flagged bins",
  "inventory",
  "inventory by autostore bin number",
  "inventory movements",
  "inventory summary list",
  "loose pick",
  "number flagged bins",
  "order",
  "order types",
  "organization",
  "pick",
  "picking port state",
  "put away task",
  "port",
  "port status",
  "recurring schedule",
  "staging area",
  "start bin cleaning",
  "start inventory movements",
  "tote",
  "variant inventory summaries",
  "variant",
  "wave progress",
  "workstation"
] as const;

/**
 * Patch axios in as the fetch framework.
 *
 * https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#axios-basequery
 */
const axiosBaseQuery =
  (): BaseQueryFn<{
    url: string;
    method?: AxiosRequestConfig["method"];
    data?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
    headers?: AxiosHeaders;
  }> =>
  async ({ url, method = "GET", data, params, headers }, { signal }) => {
    try {
      const result = await warehouseService({
        url,
        method,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        params,
        headers,
        signal
      });
      return { data: result.data };
    } catch (axiosError: unknown) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          data: err.response?.data || err.message
        }
      };
    }
  };

export const warehouseApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  tagTypes
});
