import { Alert, ButtonBase, ButtonBaseProps, Typography } from "@mui/material";

interface Props extends ButtonBaseProps {
  alert?: string;
  variant?: "sm" | "md" | "lg";
}

const variantProps = {
  sm: {
    height: "calc((100vh - 120px)/2)",
    maxHeight: "200px"
  },
  md: {
    height: "calc((100vh - 120px)/2)",
    maxHeight: "200px"
  },
  lg: {
    height: "calc((100vh - 120px))",
    maxHeight: "420px"
  }
};

export function MainButton(props: Props) {
  const { alert, disabled, sx, variant = "lg", children, ...rest } = props;
  return (
    <ButtonBase
      disabled={disabled}
      sx={{
        alignItems: "center",
        backgroundColor: "primary.main",
        borderRadius: 1,
        color: "primary.contrastText",
        display: "flex",
        fontSize: "1.5rem",
        justifyContent: "center",
        opacity: disabled ? 0.5 : 1,
        position: "relative",
        textAlign: "center",
        width: "100%",
        ...variantProps[variant],
        ...sx
      }}
      {...rest}
    >
      {children}
      {alert && (
        <Alert
          severity="info"
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0
          }}
        >
          <Typography variant="body2">{alert}</Typography>
        </Alert>
      )}
    </ButtonBase>
  );
}
