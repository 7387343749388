import { parse, stringify } from "qs";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { sendUserTaskSelectedEvent } from "~/redux/actions/userEvents";
import { selectUserCanCycleCount } from "~/redux/selectors/authSelectors";
import { selectWorkstationHasInventoryRole } from "~/redux/selectors/workstationsSelectors";

import { MainButton } from "./MainButton";

export type Props = {
  route: string;
};

export function CycleCountsButton({ route }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search }: { search: string } = useLocation();

  const userCanCycleCount = useAppSelector(selectUserCanCycleCount);
  const isInventoryRoleFound = useAppSelector(
    selectWorkstationHasInventoryRole
  );

  const onCycleCountButtonClicked = async () => {
    if (userCanCycleCount && isInventoryRoleFound) {
      await dispatch(sendUserTaskSelectedEvent("CYCLECOUNT"));

      navigate({
        pathname: route,
        search: stringify({
          ...parse(search, { ignoreQueryPrefix: true }),
          autostore: true
        })
      });
    }
  };

  return (
    <MainButton
      alert={
        (!isInventoryRoleFound && t("no inventory role found")) || undefined
      }
      disabled={!userCanCycleCount || !isInventoryRoleFound}
      onClick={onCycleCountButtonClicked}
      variant="md"
    >
      {t("nav.viewname.cycle counts")}
    </MainButton>
  );
}
