import SearchIcon from "@mui/icons-material/Search";
import { Box, InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import FetchBatchFilters from "~/components/FetchBatchFilters";
import { Search, SearchIconBox } from "~/components/navbar/NavSearchInput";
import { OrderType } from "~/features/batch/batch.type";
import {
  buildBatchStatusFilterAdmin,
  buildBatchTypeFilter
} from "~/lib/helpers";
import {
  setFetchBatchFilters,
  setSelectedOrderType
} from "~/redux/actions/batch";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

import { setPage, setSearchText, setToteSearch } from "./adminBatches.slice";

const StyledInput = styled(InputBase)(({ theme }) => ({
  "& input[type='text']": {
    padding: "8px 8px 8px 0",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}) !important `,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: theme.palette.secondary.contrastText
  }
}));

export function AdminBatchesSearch() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const fetchBatchFilters = useAppSelector(
    (state) => state.batch.fetchBatchFilters
  );
  const searchText = useAppSelector((state) => state.adminBatches.searchText);
  const selectedFulfillmentCenter = useAppSelector(
    selectUsersFulfillmentCenter
  );
  const toteSearch = useAppSelector((state) => state.adminBatches.toteSearch);

  const availableBatchStatuses = buildBatchStatusFilterAdmin(
    selectedFulfillmentCenter
  );
  const availableBatchTypes = buildBatchTypeFilter(selectedFulfillmentCenter);

  return (
    <Box
      id="adminbatches-navbar-searchComponent"
      display="flex"
      flexGrow="1"
      justifyContent="center"
    >
      <Search
        sx={{
          backgroundColor: "white"
        }}
      >
        <SearchIconBox style={{ padding: "0 12px", zIndex: 1 }}>
          <SearchIcon />
        </SearchIconBox>
        <StyledInput
          data-testid="batches-page-search-input"
          placeholder={t("admin batches search placeholder")}
          value={searchText}
          onChange={(e) => {
            if (toteSearch) {
              dispatch(setToteSearch(""));
            }
            dispatch(setSearchText(e.target.value));
          }}
          fullWidth
          autoFocus
        />
      </Search>
      <FetchBatchFilters
        fulfillmentCenter={selectedFulfillmentCenter}
        selectedTempZones={fetchBatchFilters.selectedTempZones}
        selectedBatchStatuses={fetchBatchFilters.selectedBatchStatusesAdmin}
        availableBatchStatuses={availableBatchStatuses}
        selectedBatchTypes={fetchBatchFilters.selectedBatchTypes}
        selectedOrderType={fetchBatchFilters.selectedOrderType}
        availableBatchTypes={availableBatchTypes}
        onSelectOrderType={(orderType: OrderType) =>
          dispatch(setSelectedOrderType(orderType))
        }
        onToggleZone={(zone) => {
          // it would be nice to be able to filter by multiple zones
          // const newZones = selectedTempZones.includes(zone)
          //   ? selectedTempZones.filter((i) => i !== zone)
          //   : [...selectedTempZones, zone];

          const newZones = fetchBatchFilters.selectedTempZones.includes(zone)
            ? []
            : [zone];

          dispatch(setFetchBatchFilters({ selectedTempZones: newZones }));
          dispatch(setPage(1));
        }}
        onToggleStatus={(status) => {
          let newStatuses;

          if (
            availableBatchStatuses.length ===
            fetchBatchFilters.selectedBatchStatusesAdmin.length
          ) {
            newStatuses = [status];
          } else {
            newStatuses = fetchBatchFilters.selectedBatchStatusesAdmin.includes(
              status
            )
              ? fetchBatchFilters.selectedBatchStatusesAdmin.filter(
                  (el) => el !== status
                )
              : [...fetchBatchFilters.selectedBatchStatusesAdmin, status];
          }

          dispatch(
            setFetchBatchFilters({
              selectedBatchStatusesAdmin: newStatuses
            })
          );
          dispatch(setPage(1));
        }}
        onToggleBatchType={(type) => {
          let newTypes;

          if (
            availableBatchTypes.length ===
            fetchBatchFilters.selectedBatchTypes.length
          ) {
            newTypes = [type];
          } else {
            newTypes = fetchBatchFilters.selectedBatchTypes.includes(type)
              ? fetchBatchFilters.selectedBatchTypes.filter((el) => el !== type)
              : [...fetchBatchFilters.selectedBatchTypes, type];
          }
          dispatch(
            setFetchBatchFilters({
              selectedBatchTypes: newTypes
            })
          );
          dispatch(setPage(1));
        }}
        onResetFilters={() => {
          dispatch(
            setFetchBatchFilters({
              selectedTempZones: [],
              selectedBatchStatusesAdmin: buildBatchStatusFilterAdmin(
                selectedFulfillmentCenter
              ),
              selectedBatchTypes: buildBatchTypeFilter(
                selectedFulfillmentCenter
              ),
              selectedOrderType: null
            })
          );
          dispatch(setPage(1));
        }}
      />
    </Box>
  );
}
