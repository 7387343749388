import { warehouseService, handleWarehouseError } from "~/api/warehouse";
import { AppThunk, AsyncAppThunk } from "~/app/store";
import { FulfillmentCenterDto } from "~/types/api";

/**
 * Fetch FC
 */
export interface FetchFulfillmentCenterAction {
  type: "store/FETCH_FULFILLMENT_CENTER";
}

export interface FetchFulfillmentCenterSuccessAction {
  type: "store/FETCH_FULFILLMENT_CENTER_SUCCESS";
  payload: FulfillmentCenterDto | null;
}

export interface FetchFulfillmentCenterFailureAction {
  type: "store/FETCH_FULFILLMENT_CENTER_FAILURE";
  payload: string;
}

export interface ClearFulfillmentCenterAction {
  type: "store/CLEAR_FULFILLMENT_CENTER";
}

export const fetchFulfillmentCenter =
  (fulfillmentCenterId: Guid): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<FetchFulfillmentCenterAction>({
      type: "store/FETCH_FULFILLMENT_CENTER"
    });
    try {
      const response = await warehouseService.get<FulfillmentCenterDto>(
        `/api/fulfillment-centers/${fulfillmentCenterId}`
      );
      dispatch<FetchFulfillmentCenterSuccessAction>({
        type: "store/FETCH_FULFILLMENT_CENTER_SUCCESS",
        payload: response.data
      });
    } catch (err) {
      handleWarehouseError(err, (message) =>
        dispatch<FetchFulfillmentCenterFailureAction>({
          type: "store/FETCH_FULFILLMENT_CENTER_FAILURE",
          payload: message
        })
      );
    }
  };

export const clearFulfillmentCenter = (): AppThunk => (dispatch) => {
  dispatch<ClearFulfillmentCenterAction>({
    type: "store/CLEAR_FULFILLMENT_CENTER"
  });
};

/**
 * Updates the user's FC in the redux store and updates
 * Auth0 with the chosen FC.
 */
export interface UpdateUsersFulfillmentCenterAction {
  type: "store/UPDATE_USERS_FULFILLMENT_CENTER";
}

export interface UpdateUsersFulfillmentCenterSuccessAction {
  type: "store/UPDATE_USERS_FULFILLMENT_CENTER_SUCCESS";
  payload: FulfillmentCenterDto;
}

export interface UpdateUsersFulfillmentCenterFailureAction {
  type: "store/UPDATE_USERS_FULFILLMENT_CENTER_FAILURE";
  payload: string;
}

export const updateUsersFulfillmentCenter =
  (fulfillmentCenterId: Guid): AsyncAppThunk =>
  async (dispatch) => {
    dispatch<UpdateUsersFulfillmentCenterAction>({
      type: "store/UPDATE_USERS_FULFILLMENT_CENTER"
    });
    try {
      if (!fulfillmentCenterId) {
        return;
      }
      const response = await warehouseService.get<FulfillmentCenterDto>(
        `/api/fulfillment-centers/${fulfillmentCenterId}`
      );
      dispatch<UpdateUsersFulfillmentCenterSuccessAction>({
        type: "store/UPDATE_USERS_FULFILLMENT_CENTER_SUCCESS",
        payload: response.data
      });
    } catch (err) {
      handleWarehouseError(err, (message) =>
        dispatch<UpdateUsersFulfillmentCenterFailureAction>({
          type: "store/UPDATE_USERS_FULFILLMENT_CENTER_FAILURE",
          payload: message
        })
      );
    }
  };
