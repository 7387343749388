import { VisibilityOff } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { CycleCountTaskDto } from "~/redux/actions/cycleCounts";
import { MeasuredValueDto } from "~/types/api";

export const BlindCount: React.FC<{
  task?: CycleCountTaskDto;
  count?: MeasuredValueDto;
}> = ({ task, count }) => {
  const { t } = useTranslation();
  const isTaskBlind = !!task?.isBlind;

  return (
    <div>
      {isTaskBlind ? (
        <VisibilityOff sx={{ color: "text.disabled" }} />
      ) : (
        <Typography>
          {count?.value || t("n/a")} {count?.units}
        </Typography>
      )}
    </div>
  );
};
