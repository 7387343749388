import * as Sentry from "@sentry/react";
import { useEffect } from "react";

type SentryTagsType = {
  [key in string]: string | number | boolean | undefined | null;
};

const useSetSentryTags = (tags: SentryTagsType) => {
  useEffect(() => {
    if (tags && typeof tags === "object") {
      Object.keys(tags).forEach((tagName: string) => {
        const tagValue = tags[tagName];
        Sentry.setTag(tagName, tagValue);
      });
    }
  }, [tags]);
};

export default useSetSentryTags;
