import { createSelector, createSlice } from "@reduxjs/toolkit";

import { StoreState } from "~/redux/reducers";
import { batchesApi } from "~/redux/warehouse/batches";
import { BatchSummaryDto } from "~/types/api";

type BatchCardState = {
  batches: BatchSummaryDto[];
};

const initialState: BatchCardState = {
  batches: []
};

/**
 * Acts as a sort of global cache of the most recent getBatches query.
 *
 * Useful to be able to access query data directly in the different batch
 * card components and subcomponents
 */
export const batchCardsSlice = createSlice({
  name: "batchCards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      batchesApi.endpoints.getBatches.matchFulfilled,
      (state, { payload: { batches } }) => {
        state.batches = batches;
      }
    );
    builder.addMatcher(
      batchesApi.endpoints.getBatches.matchPending,
      (state, _) => {
        state.batches = [];
      }
    );
  }
});

export const selectBatches = (state: StoreState) => state.batchCards.batches;
export const selectBatchIds = createSelector([selectBatches], (state) =>
  state.map((b) => b.batchId)
);
