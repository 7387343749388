import { createSlice } from "@reduxjs/toolkit";

export interface RestockPickModalState {
  isOpen: boolean;
  restockComplete: boolean;
}

const initialState = {
  isOpen: false,
  restockComplete: false
} as RestockPickModalState;

export const restockPickModalSlice = createSlice({
  name: "replacePickModal",
  initialState,
  reducers: {
    openRestockPickModal(state) {
      state.isOpen = true;
      state.restockComplete = false;
    },
    confirmRestock(state) {
      state.restockComplete = true;
    },
    closeRestockPickModal(state) {
      state.isOpen = false;
      state.restockComplete = false;
    }
  }
});

export const { openRestockPickModal, closeRestockPickModal, confirmRestock } =
  restockPickModalSlice.actions;
