import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { skipToken } from "@reduxjs/toolkit/query";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import i18n from "i18next";
import { useLDClient } from "launchdarkly-react-client-sdk";
import moment from "moment";
import { useCallback, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouterProvider } from "react-router-dom";

import { getUserClientId } from "~/api/usersTypes/auth0Profile";
import {
  getClientsFromWarehouseApi,
  setupAuthInterceptor
} from "~/api/warehouse";
import { ErrorBoundary } from "~/components/ErrorBoundary";
import { MessagePopper } from "~/components/MessagePopper";
import { loadAlgoliaConfig } from "~/config/algoliaConfig";
import envConstants from "~/config/envConstants";
import EventListeningComponent from "~/features/andon/EventListeningComponent";
import { getAccessToken } from "~/features/login/login.slice";
import { useBeamer } from "~/hooks/useBeamer";
import { useLaunchDarklySetClient } from "~/hooks/useLaunchDarklySetClient";
import useSetSentryTags from "~/hooks/useSetSentryTags";
import { ToastProvider } from "~/hooks/useToast";
import { useUserMessages } from "~/hooks/useUserMessages";
import { useWebLock } from "~/hooks/useWebLock";

import { usePTLSubscription, useInitializeSignalRHubs } from "~/lib/signalr";
import { autostoreTheme } from "~/lib/theme";
import { setPTLSimulationStateChange } from "~/redux/actions/ptl";
import { refreshClientConfig } from "~/redux/actions/site";
import { StoreState } from "~/redux/reducers";
import { selectIsUserLoggedIn } from "~/redux/selectors/authSelectors";
import { selectLocale } from "~/redux/selectors/siteSelectors";
import { useGetAutostoreGridQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import "~/lib/dayjs";

import { router } from "./Root";
import { useAppDispatch, useAppSelector } from "./store";

const mapStateToProps = (state: StoreState) => ({
  clientId: state.login.profile ? getUserClientId(state.login.profile) : null,
  usersPort: state.workstations.siteWorkstation?.ports?.[0]?.portId ?? null,
  userId: state.login.profile?.userId || null,
  usersFulfillmentCenter: state.store.usersFulfillmentCenter,
  siteLanguageCode: state.site.languageCode,
  ptlSimulationEnabled: state.ptl.ptlSimulationEnabled,
  selectedAutostoreGridId: state.workstations.siteWorkstation?.autostoreGridId
});

const connector = connect(mapStateToProps, {
  refreshClientConfig,
  setPTLSimulationStateChange
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type AppProps = PropsFromRedux;

function App(props: AppProps) {
  const {
    clientId,
    usersPort,
    usersFulfillmentCenter,
    userId,
    siteLanguageCode,
    selectedAutostoreGridId,
    refreshClientConfig
  } = props;

  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const locale = useAppSelector(selectLocale);
  const localeText = {
    cancelButtonLabel: "Cancel",
    okButtonLabel: "Confirm"
  };

  const dispatch = useAppDispatch();
  const ldClient = useLDClient();
  const sentryTags = {
    client: clientId,
    port: usersPort,
    fulfillmentCenter: usersFulfillmentCenter?.fulfillmentCenterId || null,
    userId: userId,
    clientRelease: envConstants.VERSION_TAG || envConstants.VERSION
  };

  useUserMessages();
  useWebLock();
  useLaunchDarklySetClient({
    clientId,
    userId,
    ldClient,
    fcId: usersFulfillmentCenter?.fulfillmentCenterId
  });
  useBeamer();

  const accessTokenFactory = useCallback(
    async () => dispatch(getAccessToken()),
    [dispatch]
  );

  setupAuthInterceptor(accessTokenFactory);

  useGetAutostoreGridQuery(selectedAutostoreGridId ?? skipToken, {
    skip: !isUserLoggedIn
  });

  // algolia set up
  useEffect(() => {
    if (isUserLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      getClientsFromWarehouseApi().then((algoliaConfig) => {
        if (Array.isArray(algoliaConfig)) {
          loadAlgoliaConfig(algoliaConfig);
        } else {
          // how to display this error without redux actions?
          Sentry.captureMessage(algoliaConfig, "error");
        }
      });
    }
  }, [isUserLoggedIn]);

  useInitializeSignalRHubs(
    accessTokenFactory,
    usersFulfillmentCenter?.fulfillmentCenterId
  );

  usePTLSubscription(
    props.setPTLSimulationStateChange,
    envConstants.ENABLE_PTL_SIMULATION === "true" && props.ptlSimulationEnabled
  );

  useSetSentryTags(sentryTags);

  useEffect(() => {
    if (clientId) refreshClientConfig(clientId);
  }, [clientId, refreshClientConfig]);

  useEffect(() => {
    void i18n.changeLanguage(siteLanguageCode);
    moment.locale(siteLanguageCode);
    dayjs.locale(locale);
  }, [siteLanguageCode, locale]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={autostoreTheme}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={locale}
          localeText={localeText}
        >
          <ToastProvider>
            <ErrorBoundary>
              <div
                id="App"
                style={{
                  background: autostoreTheme.palette.background.default,
                  minHeight: "100vh",
                  overflow: "auto"
                }}
              >
                <RouterProvider router={router} />
                <MessagePopper />
                <EventListeningComponent />
              </div>
            </ErrorBoundary>
          </ToastProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default connector(App);
