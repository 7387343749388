import { Box, Typography, Pagination } from "@mui/material";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";

import { warehouseService } from "~/api/warehouse";
import ErrorPlaceholder from "~/components/ErrorPlaceholder";
import AutostoreTable from "~/components/autostore/table/AutostoreTable";
import { useNavbar } from "~/hooks/useNavbar";
import { useView } from "~/hooks/useView";
import { formatDate } from "~/lib/dateHelpers";
import { dedupeBrand, hasUserOriginSpecificMessage } from "~/lib/helpers";
import { mobileWidth } from "~/lib/theme";
import { fetchIncompleteCycleCounts } from "~/redux/actions/cycleCounts";
import { StoreState } from "~/redux/reducers";
import { UserMessage } from "~/redux/reducers/site";
import { IncompleteCycleCountDto, CycleCountV1Dto } from "~/types/api";

const mapStateToProps = (
  state: StoreState
): {
  loadingIncompleteCycleCounts: boolean;
  incompleteCycleCounts: IncompleteCycleCountDto[];
  incompleteCycleCountsCount: number | null;
  errors: UserMessage[] | [];
} => ({
  loadingIncompleteCycleCounts: state.cycleCounts.loadingIncompleteCycleCounts,
  incompleteCycleCounts: state.cycleCounts.incompleteCycleCounts,
  incompleteCycleCountsCount: state.cycleCounts.incompleteCycleCountsCount,
  errors: state.site.userMessages
});

const connector = connect(mapStateToProps, {
  fetchIncompleteCycleCounts
});
type CycleCountsInheritedProps = { viewTitle?: string };
type PropsFromRedux = ConnectedProps<typeof connector>;
export type CycleCountProps = PropsFromRedux & CycleCountsInheritedProps;

export function CycleCountsComponent(props: CycleCountProps) {
  const {
    loadingIncompleteCycleCounts,
    incompleteCycleCounts,
    incompleteCycleCountsCount,
    viewTitle,
    errors
  } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(mobileWidth);

  useView({ permanentSidenav: true, fixedHeight: true });
  useNavbar({ viewTitle });

  const [initialFetchComplete, setInitialFetchComplete] =
    useState<boolean>(false);

  const [page, setPage] = useState<number>(1);

  const limit = 10;
  const offset = (page - 1) * limit;

  const totalPageCount = incompleteCycleCountsCount
    ? Math.ceil(incompleteCycleCountsCount / limit)
    : 0;

  useEffect(() => {
    if (!initialFetchComplete) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.fetchIncompleteCycleCounts({ offset, limit });
      setInitialFetchComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFetchComplete]);

  // pagination
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    props.fetchIncompleteCycleCounts({
      offset,
      limit
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  const redirectToCycleCount = (cycleCountId: Guid) => {
    navigate(`/cycle-count/${cycleCountId}`);
  };

  const createCycleCount = async (productCycleId: Guid) => {
    const response = await warehouseService.post<CycleCountV1Dto>(
      "/api/cycle-counts",
      {
        ProductCycleId: productCycleId
      }
    );

    if (response.status === 200) {
      const { cycleCountId } = response.data;
      redirectToCycleCount(cycleCountId);
    } else {
      // handle error
    }
  };

  const overDueCycleCountIds = incompleteCycleCounts
    .filter((iCycleCount) => {
      if (!iCycleCount.cycleCountId || !iCycleCount.cycleEndsOn) return false;

      return !!moment(iCycleCount.cycleEndsOn).isBefore(moment());
    })
    .map((iCycleCount) => iCycleCount.cycleCountId || "");

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      {hasUserOriginSpecificMessage(
        errors,
        "FETCH_INCOMPLETE_CYCLE_COUNTS_FAILURE",
        "error"
      ) ? (
        <ErrorPlaceholder />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifiyContent: "center",
            alignItems: "center"
          }}
        >
          <Box sx={{ marginTop: "20px", width: "80%" }}>
            <AutostoreTable<IncompleteCycleCountDto>
              widthOfCols={
                !isMobile
                  ? ["15%", "45%", "10%", "10%", "10%"]
                  : ["50%", "20%", "30%"]
              }
              headerColNames={[
                !isMobile ? t("Brand") : null,
                t("Product"),
                !isMobile ? t("sku") : null,
                t("status"),
                t("due by")
              ].filter((el) => el)}
              rowId={(row: IncompleteCycleCountDto) => `${row.cycleCountId}`}
              renderColumns={[
                !isMobile
                  ? (row: IncompleteCycleCountDto) => {
                      const { variant } = row;

                      const { brandName } = variant;

                      return brandName;
                    }
                  : null,
                (row: IncompleteCycleCountDto) => {
                  const { variant } = row;

                  const { brandName, productName } = variant;

                  // for mobile, combine brand and product into one cell
                  return isMobile ? (
                    <div>
                      <div style={{ fontWeight: 600 }}>{brandName}</div>
                      <Box
                        sx={{
                          color: "text.secondary",
                          fontWeight: 200
                        }}
                      >
                        <Typography>
                          {dedupeBrand(productName, brandName)}
                        </Typography>
                      </Box>
                    </div>
                  ) : (
                    <Box>
                      <Typography>{`${productName}`}</Typography>
                    </Box>
                  );
                },
                !isMobile
                  ? (row: IncompleteCycleCountDto) => {
                      const { variant } = row;

                      const { sku } = variant;

                      return (
                        <Box>
                          <Typography>{sku}</Typography>
                        </Box>
                      );
                    }
                  : null,
                (row: IncompleteCycleCountDto) => {
                  const { status } = row;

                  return (
                    <Box>
                      <Typography>{`${t(status)}`}</Typography>
                    </Box>
                  );
                },
                (row: IncompleteCycleCountDto) => {
                  const { cycleEndsOn } = row;

                  const dueBy = formatDate(cycleEndsOn);

                  return (
                    <Typography
                      sx={{ whiteSpace: "nowrap" }}
                    >{`${dueBy}`}</Typography>
                  );
                }
              ]}
              rowData={incompleteCycleCounts}
              alertRows={overDueCycleCountIds}
              selectRowCallback={(row: IncompleteCycleCountDto) => {
                if (row.status === "new" && row.productCycleId) {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
                  createCycleCount(row.productCycleId);
                } else if (row.status === "open" && row.cycleCountId) {
                  redirectToCycleCount(row.cycleCountId);
                }
              }}
              sort={(a, b) => {
                if (a.cycleEndsOn > b.cycleEndsOn) return 1;
                if (a.cycleEndsOn < b.cycleEndsOn) return -1;

                const statusRanks = ["open", "new"];

                if (
                  statusRanks.indexOf(a.status) > statusRanks.indexOf(b.status)
                )
                  return 1;
                if (
                  statusRanks.indexOf(a.status) < statusRanks.indexOf(b.status)
                )
                  return -1;

                return 0;
              }}
              loading={loadingIncompleteCycleCounts}
              noResults={
                !loadingIncompleteCycleCounts &&
                incompleteCycleCounts &&
                incompleteCycleCounts.length === 0
              }
            />
            <Grid
              item
              xs={12}
              style={{
                padding: "16px 0 48px",
                display: "flex",
                justifyContent: "center"
              }}
            >
              {totalPageCount > 1 && (
                <Pagination
                  count={totalPageCount}
                  page={page}
                  onChange={(_e, p) => {
                    setPage(p);
                    if (window.scrollTo) window.scrollTo(0, 0);
                  }}
                  shape="rounded"
                />
              )}
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default connector(CycleCountsComponent);
