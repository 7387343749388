import CancelIcon from "@mui/icons-material/Cancel";
import { Box, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { ProgressButton } from "~/components/ProgressButton";
import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import {
  selectDropdownWorkstationAutostoreGrid,
  selectDropdownWorkstation
} from "~/redux/selectors/workstationConfigSelectors";
import { useGetAutostoreGridsQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetMultiportsQuery } from "~/redux/warehouse/workstation.hooks";
import { UpdateWorkstation, WorkstationPortDto } from "~/types/api";

import {
  portTypes,
  portDirections,
  binSizes,
  configurationOptions,
  possibleGridCoordinates
} from "./workstationConstants";

export function PortSetup() {
  const { t } = useTranslation();
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const clientConfig = useAppSelector(selectClientConfig);
  const workstation = useAppSelector(selectDropdownWorkstation);
  const workstationAutostoreGrid = useAppSelector(
    selectDropdownWorkstationAutostoreGrid
  );

  const { data: autostoreGrids } = useGetAutostoreGridsQuery();
  const { data: multiports } = useGetMultiportsQuery(
    workstation?.autostoreGridId || skipToken
  );

  const { ws_addRemovePortsEnabled, ws_modifyPortSetupEnabled } = clientConfig;

  const shouldShowParentPortId =
    workstation?.ports[0].configuration.toLowerCase() === "multiport";

  const { control, formState, register, watch, setValue, setError } =
    useFormContext<UpdateWorkstation>();
  const { errors, isDirty } = formState;
  const {
    fields: ports,
    append: addPort,
    remove: removePort
  } = useFieldArray({
    control,
    name: "ports"
  });

  useEffect(() => {
    if (ports?.length === 0 && isDirty) {
      // Manually setting an error as the normal validation rules did not work
      // well with field arrays
      setError("ports", { type: "required" });
    }
  }, [ports?.length, isDirty, setError]);

  const createNewPortTemplate = (): WorkstationPortDto => ({
    portId: workstationAutostoreGrid?.autostorePorts[0].portId || 0,
    configuration: portTypes[0],
    binSize: binSizes[0],
    coordinate: {
      x: 0,
      y: 0
    },
    inductionBinConfiguration: ["Whole Bin"],
    cleaningDirection: "No Direction",
    cells: [{ x: 0, y: 0 }]
  });

  return (
    <Box>
      <InputLabel
        shrink
        style={{
          fontWeight: 600,
          marginBottom: 20,
          color: "#000",
          fontSize: 18
        }}
      >
        {t("port setup")}
      </InputLabel>

      {ports?.map((port, index) => (
        <Box
          key={port.id}
          style={{
            display: "flex",
            marginBottom: "20px"
          }}
          role="listitem"
        >
          <Box
            style={{
              width: 52,
              marginRight: 20
            }}
          >
            <InputLabel id="ps-port-id" shrink>
              {t("port id")}
            </InputLabel>
            {autostoreGrids?.length && (
              <Select
                labelId="ps-port-id"
                fullWidth
                variant="standard"
                disabled={!isAdmin || !!workstation?.ports[0].parentPortId}
                {...register(`ports.${index}.portId`)}
                value={watch(`ports.${index}.portId`, 0)}
              >
                {workstationAutostoreGrid?.autostorePorts.map((p) => (
                  <MenuItem key={p.portId} value={p.portId}>
                    {`${p.portId}`}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>

          <Box
            style={{
              width: 120,
              marginRight: 20
            }}
          >
            <InputLabel id="ps-port-type" shrink>
              {t("port type")}
            </InputLabel>
            <Select
              disabled={
                !ws_modifyPortSetupEnabled ||
                !isAdmin ||
                !!workstation?.ports[0].parentPortId
              }
              labelId="ps-port-type"
              variant="standard"
              fullWidth
              {...register(`ports.${index}.configuration`)}
              value={watch(`ports.${index}.configuration`, "")}
            >
              {portTypes.map((numOption) => (
                <MenuItem
                  key={numOption}
                  value={numOption}
                  disabled={
                    (workstation?.ports.length === 1 &&
                      (numOption?.toLowerCase() === "cleaning" ||
                        numOption?.toLowerCase() === "fusion")) ||
                    (port?.configuration?.toLowerCase() === "cleaning" &&
                      numOption?.toLowerCase() !== "cleaning")
                  }
                >
                  {`${numOption}`}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {workstation?.ports.length &&
            workstation.ports[0].configuration === "cleaning" && (
              <Box
                style={{
                  width: 120,
                  marginRight: 20
                }}
              >
                <InputLabel id="ps-port-direction" shrink>
                  {t("port direction")}
                </InputLabel>
                <Select
                  labelId="ps-port-direction"
                  variant="standard"
                  fullWidth
                  {...register(`ports.${index}.cleaningDirection`)}
                  value={watch(
                    `ports.${index}.cleaningDirection`,
                    "No Direction"
                  )?.toLowerCase()}
                >
                  {portDirections.map((direction) => (
                    <MenuItem
                      key={direction}
                      value={direction}
                      disabled={
                        workstation?.ports[0].configuration === "cleaning" &&
                        direction === "no direction"
                      }
                    >
                      {direction}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}

          {shouldShowParentPortId && (
            <Box
              style={{
                width: 60,
                marginRight: 20
              }}
            >
              <InputLabel id="ps-parent-port-id" shrink>
                {t("parent port id")}
              </InputLabel>
              <Select
                disabled={!ws_modifyPortSetupEnabled || !isAdmin}
                labelId="ps-parent-port-id"
                variant="standard"
                fullWidth
                value={watch("ports.0.parentPortId", 0)}
                onChange={(e) => {
                  const value =
                    e.target.value === "none"
                      ? undefined
                      : Number(e.target.value);
                  ports.forEach((_, index) => {
                    setValue(`ports.${index}.parentPortId`, value, {
                      shouldDirty: true
                    });
                  });
                }}
              >
                <MenuItem key={`none-${index}`} value="none">
                  {t("none")}
                </MenuItem>
                {multiports?.map((multiport) => (
                  <MenuItem key={multiport.portId} value={multiport.portId}>
                    {multiport.portId}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}

          {workstation?.ports?.length &&
            workstation?.ports[0].configuration !== "cleaning" && (
              <Box style={{ marginRight: 20 }}>
                <InputLabel id="ps-bin-configuration" shrink>
                  {t("bin configuration")}
                </InputLabel>
                <Select
                  labelId="ps-bin-configuration"
                  fullWidth
                  variant="standard"
                  {...register(`ports.${index}.inductionBinConfiguration`)}
                  value={
                    watch(`ports.${index}.inductionBinConfiguration`)?.length
                      ? watch(`ports.${index}.inductionBinConfiguration`)
                      : ""
                  }
                  onChange={(e) => {
                    // Since inductionBinConfiguration is a string[] type, we
                    // need to pass an array
                    const values = [e.target.value as string];
                    setValue(
                      `ports.${index}.inductionBinConfiguration`,
                      values,
                      { shouldDirty: true }
                    );
                  }}
                >
                  {configurationOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}

          <Box
            style={{
              width: 50,
              marginRight: 20
            }}
          >
            <InputLabel id="ps-x-coordinate" shrink>
              X
            </InputLabel>
            <Select
              labelId="ps-x-coordinate"
              fullWidth
              variant="standard"
              disabled={!isAdmin || !!workstation?.ports[0].parentPortId}
              {...register(`ports.${index}.coordinate.x`)}
              value={watch(`ports.${index}.coordinate.x`, 0)}
            >
              {possibleGridCoordinates.map((numOption) => (
                <MenuItem key={numOption} value={numOption}>
                  {`${numOption}`}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box
            style={{
              width: 50,
              marginRight: 20
            }}
          >
            <InputLabel id="ps-y-coordinate" shrink>
              Y
            </InputLabel>
            <Select
              labelId="ps-y-coordinate"
              fullWidth
              variant="standard"
              disabled={!isAdmin || !!workstation?.ports[0].parentPortId}
              {...register(`ports.${index}.coordinate.y`)}
              value={watch(`ports.${index}.coordinate.y`, 0)}
            >
              {possibleGridCoordinates.map((numOption) => (
                <MenuItem key={numOption} value={numOption}>
                  {`${numOption}`}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {ws_addRemovePortsEnabled && isAdmin && (
            <Box
              style={{
                width: 50,
                marginRight: 20,
                marginTop: 12
              }}
            >
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <CancelIcon
                  aria-label="remove-port"
                  onClick={() => removePort(index)}
                  style={{
                    cursor: "pointer"
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      ))}

      {errors.ports?.type === "required" && (
        <Typography color="error.main" my={2} fontWeight={700}>
          {t("at least one port is required")}
        </Typography>
      )}

      {ws_addRemovePortsEnabled && isAdmin && (
        <Box
          style={{
            width: 50,
            marginRight: 20,
            marginTop: 12
          }}
        >
          <ProgressButton
            emphasis="medium"
            buttonSize="small"
            responsive
            style={{
              width: 100
            }}
            color="primary"
            onClick={() => addPort(createNewPortTemplate())}
          >
            <Typography style={{ fontSize: 12 }}>{t("add port")}</Typography>
          </ProgressButton>
        </Box>
      )}
    </Box>
  );
}
