import { VisibilityOff } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, IconButton, TextField, styled } from "@mui/material";
import dayjs from "dayjs";

import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import UniversalProductCard from "~/components/productCard/UniversalProductCard";
import { Inv_InventoryDateLabelSettings } from "~/config/clientConfig";
import InventoryDateField from "~/features/autostorePutaway/InventoryDateField";
import { setIsInventoryDateFieldDirty } from "~/features/cycleCounts/cycleCounts.slice";
import {
  InventoryDto,
  InventorySummaryDto,
  VariantFrontendDto
} from "~/types/api";

const PickInfoFieldsContainer = styled(Box)`
  display: grid;
  grid-template-columns: 210px 1fr;
  gap: 16px;
  align-items: center;
  margin-top: 20px;
`;

const QuantityTextField = styled(TextField)`
  & input::-webkit-clear-button,
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    display: none;
  }
  width: 100%;
`;

const AdjustQuantityButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  backgroundColor: theme.palette.gray.dark,
  borderRadius: "10px",
  ":hover": {
    backgroundColor: theme.palette.darkGray.light
  }
}));

function CountingTaskInventoryCard(props: {
  selectedVariant: VariantFrontendDto;
  inv_inventoryDateLabel: Inv_InventoryDateLabelSettings;
  inventoryDate: Date | string | null;
  setInventoryDate: Dispatch<SetStateAction<string | Date | null>>;
  currentSelectedInventory: InventorySummaryDto;
  selectedInventoryRecord: InventoryDto | null;
  changedQuantity: number | undefined;
  onClickIncreaseQuantity: (qty: number) => void;
  onClickDecreaseQuantity: (qty: number) => void;
  handleChangeQuantity: (qty: number) => void;
  isDateValid: boolean;
  isCycleCountBlind?: boolean;
}) {
  const {
    selectedVariant,
    inv_inventoryDateLabel,
    inventoryDate,
    setInventoryDate,
    currentSelectedInventory,
    selectedInventoryRecord,
    changedQuantity,
    // form functions
    onClickIncreaseQuantity,
    onClickDecreaseQuantity,
    handleChangeQuantity,
    isDateValid,
    isCycleCountBlind = false
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showBlindIcon, setShowBlindIcon] = useState<boolean>(true);
  const isInventoryDateFieldDirty = useAppSelector(
    (state) => state.cycleCountsSlice.isInventoryDateFieldDirty
  );
  const hasDateBeenRemoved = isInventoryDateFieldDirty && !inventoryDate;

  useEffect(() => {
    dispatch(setIsInventoryDateFieldDirty(false));
    setShowBlindIcon(true);
  }, [currentSelectedInventory.inventoryId, dispatch]);

  const handleQuantityChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const inputValue = parseInt(e.target.value, 10);
    if (inputValue < 0) return;
    handleChangeQuantity(inputValue);
  };
  const initialQty = isCycleCountBlind
    ? 0
    : selectedInventoryRecord?.count?.value;

  const displayQty = changedQuantity ?? initialQty;

  return (
    <UniversalProductCard
      productName={selectedVariant.productName}
      imageFileName={selectedVariant.imageFilename}
      sku={selectedVariant.sku}
      upc=""
      bottomSection={
        <PickInfoFieldsContainer>
          <InventoryDateField
            inventoryDate={
              isInventoryDateFieldDirty
                ? dayjs(inventoryDate)
                : dayjs(currentSelectedInventory?.expiration) || null
            }
            inv_inventoryDateLabel={inv_inventoryDateLabel}
            isPastDateAllowed
            isDateValid={!hasDateBeenRemoved && isDateValid}
            setInventoryDate={(date) => {
              setInventoryDate(date?.toDate() || null);
            }}
            onChangeCallback={() => {
              dispatch(setIsInventoryDateFieldDirty(true));
            }}
          />

          <Box sx={{ display: "flex", gap: "8px" }}>
            <Box sx={{ position: "relative" }}>
              <QuantityTextField
                data-testid="quantity-input"
                label={t("quantity")}
                variant="outlined"
                inputProps={{
                  style: {
                    fontSize: "48px",
                    textAlign: "center"
                  },
                  type: "number", // Set input type to number
                  pattern: "[0-9]*", // Add pattern to accept only numbers
                  inputMode: "numeric" // Set input mode to numeric for better mobile support
                }}
                InputLabelProps={{
                  sx: {
                    color: "gray.main.dark",
                    fontSize: 18
                  },
                  shrink: true
                }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderWidth: "2px"
                  }
                }}
                onChange={(e) => handleQuantityChange(e)}
                value={displayQty}
                error={
                  (!displayQty && displayQty !== 0) ||
                  (changedQuantity && changedQuantity < 0) ||
                  !selectedInventoryRecord?.count?.value
                }
              />
              {isCycleCountBlind &&
                !changedQuantity &&
                changedQuantity !== 0 &&
                showBlindIcon && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "0",
                      bottom: "0",
                      margin: "auto",
                      left: "0",
                      right: "0",
                      width: "fit-content",
                      height: "fit-content",
                      padding: "15px",
                      background: "white"
                    }}
                  >
                    <IconButton onClick={() => setShowBlindIcon(false)}>
                      <VisibilityOff fontSize="large" />
                    </IconButton>
                  </Box>
                )}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "6px"
              }}
            >
              <AdjustQuantityButton
                data-testid="quantity-input-increase"
                size="large"
                aria-label="increase quantity"
                onClick={() => onClickIncreaseQuantity(displayQty ?? 0)}
              >
                <KeyboardArrowUpIcon />
              </AdjustQuantityButton>
              <AdjustQuantityButton
                data-testid="quantity-input-decrease"
                size="large"
                aria-label="decrease quantity"
                onClick={() => onClickDecreaseQuantity(displayQty ?? 0)}
                disabled={!selectedInventoryRecord?.count?.value}
              >
                <KeyboardArrowDownIcon />
              </AdjustQuantityButton>
            </Box>
          </Box>
        </PickInfoFieldsContainer>
      }
      disableGutters
      hideProductCount
      allUpcs={selectedVariant.allUpcs}
    />
  );
}

export default CountingTaskInventoryCard;
