import { SxProps, Theme } from "@mui/material";

export const menuStyle: SxProps<Theme> = {
  minWidth: "40px",
  width: "40px",
  minHeight: "40px",
  height: "40px",
  border: "4px solid",
  "&.Mui-disabled": {
    border: "4px solid"
  }
};

// We use here numbers (px's) instead of predifined breakpoints (xs, sm, md etc.)
export const typographyStyle: SxProps<Theme> = (theme) => ({
  [theme.breakpoints.up(1600)]: {
    typography: "h5"
  },
  [theme.breakpoints.between(600, 1600)]: {
    typography: "body1"
  },
  [theme.breakpoints.down(600)]: {
    typography: "caption"
  }
});

export const menuItemStyle: SxProps<Theme> = {
  paddingTop: "20px",
  paddingBottom: "20px"
};
