import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

export type PickProgressBarProps = {
  totalPicks: number;
  completedPicks: number;
  labelOutside?: boolean;
};

export default function PickProgressBar(props: PickProgressBarProps) {
  const { totalPicks, completedPicks, labelOutside = false } = props;

  const percentage = Math.ceil((completedPicks / totalPicks) * 100);

  return (
    <Box
      sx={{ flexGrow: 1 }}
      position="relative"
      width={labelOutside ? "100%" : "90px"}
      margin="auto"
      display="flex"
      alignItems="center"
    >
      {percentage !== undefined && (
        <LinearProgress
          sx={{
            margin: 0.5,
            width: "100%",
            height: 12,
            backgroundColor: "info.light",
            borderRadius: 5,
            "& .MuiLinearProgress-bar": {
              borderRadius: 20,
              backgroundColor: "info.main"
            }
          }}
          variant="determinate"
          color="secondary"
          value={percentage}
        />
      )}
      {!labelOutside && (
        <Box
          data-testid="inside-label"
          position="absolute"
          top="1px"
          left="0"
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <Typography variant="caption">
            {`${completedPicks}/${totalPicks}`}
          </Typography>
        </Box>
      )}
      {labelOutside && (
        <Box minWidth={50} data-testid="outside-label">
          <Typography variant="caption">
            {`${completedPicks}/${totalPicks}`}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
