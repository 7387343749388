import { Box } from "@mui/material";

import { temperatureZoneColors } from "~/lib/helpers";

export type IconBoxTempZone = "ambient" | "chilled" | "frozen" | "none";

function IconBulkBox(tempZone: IconBoxTempZone, key: string) {
  const tempColors = {
    ...temperatureZoneColors,
    none: "#000"
  };

  const color = tempColors[tempZone] || "primary.main";

  return (
    <Box
      key={key}
      width="2em"
      height="2em"
      border="2px solid"
      margin="0.1em"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        borderColor: color
      }}
    >
      B
    </Box>
  );
}

export default IconBulkBox;
