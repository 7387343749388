import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { InventorySummaryDto } from "~/types/api";

type AutostoreInventoryHoldsState = {
  isAdjustPanelOpen: boolean;
  isBinHoldModalOpen: boolean;
  portOpened: boolean;
  portPollingActive: boolean;
  selectedSummaries: InventorySummaryDto[];
};

const initialState: AutostoreInventoryHoldsState = {
  isAdjustPanelOpen: false,
  isBinHoldModalOpen: false,
  portOpened: false,
  portPollingActive: false,
  selectedSummaries: []
};

export const autostoreInventoryHoldsSlice = createSlice({
  name: "autostoreInventoryHolds",
  initialState,
  reducers: {
    resetSelectedSummaries(state) {
      state.selectedSummaries = [];
    },
    setIsAdjustPanelOpen(state, action: PayloadAction<boolean>) {
      state.isAdjustPanelOpen = action.payload;
    },
    setIsBinHoldModalOpen(state, action: PayloadAction<boolean>) {
      state.isBinHoldModalOpen = action.payload;
    },
    setPortOpened(state, action: PayloadAction<boolean>) {
      state.portOpened = action.payload;
    },
    setPortPollingActive(state, action: PayloadAction<boolean>) {
      state.portPollingActive = action.payload;
    },
    setSelectedSummaries(state, action: PayloadAction<InventorySummaryDto[]>) {
      state.selectedSummaries = action.payload;
    }
  }
});

export const {
  resetSelectedSummaries,
  setIsAdjustPanelOpen,
  setIsBinHoldModalOpen,
  setPortOpened,
  setPortPollingActive,
  setSelectedSummaries
} = autostoreInventoryHoldsSlice.actions;
