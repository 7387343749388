import axios from "axios";
import { stringify } from "qs";

import { ApiAlgoliaConfig } from "~/config/algoliaConfig";
import envConstants from "~/config/envConstants";
import { processErrorArray } from "~/lib/helpers";

import { isAxiosError, applyRedirectOn401Response } from "./shared";

export const getWarehouseServiceUrl = (): string => {
  const isLocalHost =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1";

  if (window.location.hostname.indexOf("pepsico") > -1) {
    return envConstants.PEPSI_WAREHOUSE_API;
  }
  if (window.location.hostname.indexOf("autostoresystem") > -1 || isLocalHost) {
    return envConstants.AS_WAREHOUSE_API;
  }
  return envConstants.WAREHOUSE_API;
};

export const warehouseService = axios.create({
  baseURL: getWarehouseServiceUrl(),
  headers: {},
  params: {},
  paramsSerializer: (params) => stringify(params, { arrayFormat: "repeat" })
});

export function setupAuthInterceptor(
  accessTokenFactory: () => Promise<string | null>
) {
  if (envConstants.MODE !== "test") {
    warehouseService.interceptors.request.use(
      async (config) => {
        try {
          const token = await accessTokenFactory();
          if (token) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        } catch {
          return config;
        }
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
  }
}

applyRedirectOn401Response(warehouseService);

export function handleWarehouseError(
  err: unknown,
  dispatch: (message: string) => void
): void {
  if (isAxiosError<string | string[]>(err)) {
    const { message = "Something Went Wrong!" } = err;
    const responseMessage = err?.response?.data;
    const stringMessage = Array.isArray(responseMessage)
      ? processErrorArray(responseMessage)
      : responseMessage;
    dispatch(stringMessage || message);
  } else if (err instanceof Error) {
    const { message } = err;
    dispatch(message);
  }

  throw err;
}

export function handleWarehouseErrorAsyncThunk(err: unknown): string {
  if (isAxiosError<string | string[]>(err)) {
    const { message = "Something Went Wrong!" } = err;
    const responseMessage = err?.response?.data;
    const stringMessage = Array.isArray(responseMessage)
      ? processErrorArray(responseMessage)
      : responseMessage;
    return stringMessage || message;
  }
  if (err instanceof Error) {
    const { message } = err;
    return message;
  }

  throw err;
}

/**
 * Call the Warehouse API for the Algolia config for the client of the logged in user
 */
export const getClientsFromWarehouseApi = async (): Promise<
  ApiAlgoliaConfig[] | string
> => {
  try {
    const response =
      await warehouseService.get<ApiAlgoliaConfig[]>(`/api/clients`);
    return response.data;
  } catch (err: unknown) {
    return handleWarehouseErrorAsyncThunk(err);
  }
};
