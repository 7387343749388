import BackHandIcon from "@mui/icons-material/BackHand";
import BackHandOutlinedIcon from "@mui/icons-material/BackHandOutlined";
import { Box, ButtonBase, Typography, useMediaQuery } from "@mui/material";

import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { useToast } from "~/hooks/useToast";
import { figureAndonColorFromStatus } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { andonColors, mobileWidth } from "~/lib/theme";
import {
  selectSiteAndonGridSystemMode,
  selectSiteAndonWorkstation,
  selectSiteAndonWorkstationStatus
} from "~/redux/selectors/andonSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useToggleHandRaiseMutation } from "~/redux/warehouse/andon.hooks";

export const WorkstationInformation = () => {
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();
  const isMobile = useMediaQuery(mobileWidth);
  const { an_andonFeaturesEnabled } = useAppSelector(selectClientConfig);
  const siteAndonWorkstation = useAppSelector(selectSiteAndonWorkstation);
  const siteAndonGridSystemMode = useAppSelector(selectSiteAndonGridSystemMode);
  const siteAndonWorkstationStatus = useAppSelector(
    selectSiteAndonWorkstationStatus
  );
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const selectedAutostoreGridId = siteWorkstation?.autostoreGridId;

  const [toggleHandRaise] = useToggleHandRaiseMutation();

  let workstationNameBackground;
  let workstationTextColor: string | undefined = "darkGray.dark";

  if (an_andonFeaturesEnabled && siteAndonWorkstation) {
    workstationNameBackground = figureAndonColorFromStatus({
      handRaised: siteAndonWorkstation.handRaised,
      workstationOpen: siteAndonWorkstation.isOpen,
      workstationStatus: siteAndonWorkstationStatus,
      workstationActive: siteAndonWorkstation.workstation.status === "Active",
      gridSystemMode: siteAndonGridSystemMode ?? undefined,
      defaultColor: andonColors.workstationActiveTeal
    });
    workstationTextColor = siteAndonWorkstation.handRaised ? "black" : "white";
  }

  const handleRaiseHandClick = async () => {
    if (!selectedAutostoreGridId || !siteWorkstation || !siteAndonWorkstation) {
      errorToast("Missing workstation information");
      return;
    }

    try {
      await toggleHandRaise({
        autostoreGridId: selectedAutostoreGridId,
        workstationId: siteWorkstation.id,
        raiseHand: !siteAndonWorkstation.handRaised
      });
      successToast(
        !siteAndonWorkstation.handRaised ? "Raised hand" : "Lowered hand"
      );
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  return (
    <>
      {an_andonFeaturesEnabled && !isMobile && siteAndonWorkstation && (
        <ButtonBase
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
          onClick={handleRaiseHandClick}
        >
          {siteAndonWorkstation.handRaised ? (
            <BackHandIcon
              sx={{
                width: "70%",
                height: "70%",
                color: "warning.dark"
              }}
            />
          ) : (
            <BackHandOutlinedIcon
              sx={{
                width: "70%",
                height: "70%",
                color: "warning.dark"
              }}
            />
          )}
        </ButtonBase>
      )}
      {!isMobile && siteWorkstation && (
        <Box
          id="workstation-info"
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: workstationNameBackground,
            marginRight: "10px",
            marginLeft: "10px",
            borderRadius: 1
          }}
        >
          <Typography
            sx={{
              margin: "5px",
              color: workstationTextColor
            }}
          >
            {t("nav.viewname.workstation")}: {siteWorkstation.deviceId}
          </Typography>
        </Box>
      )}
    </>
  );
};
