import ASButton from "@locaisolutions/button";
import { Undo20Px } from "@locaisolutions/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useParams } from "react-router";

import { useAppSelector } from "~/app/store";
import { ConfirmationModal } from "~/components/ConfirmationModal";

import ManageFlaggedInventoryButton from "./ManageFlaggedInventoryButton";
import { mixpanelTrack } from "~/lib/mixpanel-tracking";

interface TopActionButtonsProps {
  isAdjustingBins: boolean;
  isGetBinsLoading: boolean;
  handleFetchAllInventory: () => void;
}

export const TopActionButtons: React.FC<TopActionButtonsProps> = ({
  isAdjustingBins,
  isGetBinsLoading,
  handleFetchAllInventory
}) => {
  const binFlaggingEnabled = useAppSelector(
    (state) => state.store.usersFulfillmentCenter?.binFlaggingEnabled
  );
  const { t } = useTranslation();
  const { variantId: variantIdParam, binNumber: binNumberParam } = useParams<{
    variantId?: string;
    binNumber?: string;
  }>();

  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState(false);

  return (
    <>
      {binFlaggingEnabled && !isAdjustingBins && (
        <ManageFlaggedInventoryButton />
      )}
      {(isAdjustingBins || variantIdParam || binNumberParam) && (
        <ASButton
          sx={{ alignSelf: "flex-start" }}
          onClick={() => {
            if (isAdjustingBins || isGetBinsLoading) {
              setIsRefreshModalOpen(true);
            } else {
              handleFetchAllInventory();
            }
            mixpanelTrack({
              trackedPageName: "Inventory",
              type: "Button Click",
              label: "Return to all inventory"
            });
          }}
          startIcon={<Undo20Px fill="black" />}
          variant="contained"
          color="primary"
          kind="subtle"
          style={{ fontWeight: "normal" }}
        >
          {t("return to all inventory")}
        </ASButton>
      )}
      <ConfirmationModal
        isOpen={isRefreshModalOpen}
        confirmCb={() => {
          handleFetchAllInventory();
          setIsRefreshModalOpen(false);
          mixpanelTrack({
            trackedPageName: "Inventory",
            type: "Button Click",
            label: "Confirm Return to all inventory"
          });
        }}
        closeCb={() => {
          setIsRefreshModalOpen(false);
          mixpanelTrack({
            trackedPageName: "Inventory",
            type: "Button Click",
            label: "Cancel Return to all inventory"
          });
        }}
        modalTitle={t("return to all inventory")}
        modalText={t(
          "are you sure you want to close all current bins and return to the inventory main page?"
        )}
      />
    </>
  );
};
