import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Stack,
  Box,
  InputLabel,
  Tooltip,
  Typography,
  ClickAwayListener,
  Select,
  MenuItem
} from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PortOrientation } from "~/api/portOrientation/portOrientation";
import { useAppSelector } from "~/app/store";
import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import { UpdateWorkstation } from "~/types/api";

export function WorkstationOrientation() {
  const { t } = useTranslation();
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const [showOrientationInfo, setShowOrientationInfo] = useState(false);
  const { register, watch } = useFormContext<UpdateWorkstation>();

  return (
    <Stack direction="column" spacing={2}>
      <Box width="100%">
        <Stack direction="row" justifyContent="space-between">
          <InputLabel id="orientation" shrink>
            {t("orientation")}
          </InputLabel>
          <ClickAwayListener onClickAway={() => setShowOrientationInfo(false)}>
            <Tooltip
              title={
                <Typography variant="body2">
                  {t("workstation port orientation tooltip")}
                </Typography>
              }
              open={showOrientationInfo}
              onOpen={() => setShowOrientationInfo(true)}
              onClose={() => setShowOrientationInfo(false)}
              disableHoverListener
              placement="right"
            >
              <HelpOutlineIcon
                sx={{
                  cursor: "pointer",
                  color: "gray"
                }}
                onClick={() => setShowOrientationInfo(!showOrientationInfo)}
              />
            </Tooltip>
          </ClickAwayListener>
        </Stack>

        <Select
          labelId="orientation"
          variant="standard"
          fullWidth
          disabled={!isAdmin}
          {...register("orientation")}
          value={watch("orientation")}
        >
          {Object.values(PortOrientation).map((orientation) => (
            <MenuItem key={orientation} value={orientation}>
              {orientation}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Stack>
  );
}
