import { Avatar, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface CardAvatarProps {
  quantity: number;
  quantityIconVariant: "outline" | "filled";
  unitOfMeasure: string | null;
}

/**
 * A component that displays a pick quantity as a card's avatar.
 */
export function CardAvatar(props: CardAvatarProps) {
  const { quantity, quantityIconVariant, unitOfMeasure } = props;

  const { t } = useTranslation();

  return (
    <Box className="CardHeader-avatar-box">
      <Avatar
        aria-label="quantity"
        sx={{
          backgroundColor:
            quantityIconVariant === "outline" ? "transparent" : "primary.main",
          border: `1px solid`,
          borderColor: "primary.main",
          color: quantityIconVariant === "outline" ? "primary.main" : "white"
        }}
      >
        {quantity || t("n/a")}
      </Avatar>
      <Box
        className="unit-of-measure-box"
        sx={{
          justifyContent: "center",
          display: unitOfMeasure ? "flex" : "none"
        }}
      >
        <Typography
          className="unitOfMeasureText"
          sx={{ fontSize: "0.775rem", color: "primary.main" }}
        >
          {unitOfMeasure || ""}
        </Typography>
      </Box>
    </Box>
  );
}
