import { BatchStatus } from "~/features/batch/batch.type";
import {
  GetBatchResponse,
  GetBatchSummariesResponse,
  GetStatusCountsResponse,
  WaveProgressDto
} from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const batchesApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getBatches: build.query<
      GetBatchSummariesResponse,
      {
        status: BatchStatus[];
        batchType?: string[];
        temperatureZone?: string;
        search?: string;
        limit?: number;
        offset?: number;
        startTimeStamp?: Date;
        endTimeStamp?: Date;
        isPrioritizeProcessing?: boolean; // Whether to prioritize batches that are Processing when listing batches.
        includeBatchesWithoutTaskGroup?: boolean;
        gridId?: Guid;
        toteId?: Guid;
        searchByCartIdIfToteIdProvided?: boolean;
      }
    >({
      query: ({
        status,
        batchType,
        temperatureZone,
        search,
        limit = 10,
        offset = 0,
        startTimeStamp,
        endTimeStamp,
        isPrioritizeProcessing,
        includeBatchesWithoutTaskGroup,
        gridId,
        toteId,
        searchByCartIdIfToteIdProvided
      }) => ({
        url: `/api/batches`,
        params: {
          status,
          batchType,
          temperatureZone,
          search,
          limit,
          offset,
          startTimeStamp,
          endTimeStamp,
          isPrioritizeProcessing,
          includeBatchesWithoutTaskGroup,
          gridId,
          toteId,
          searchByCartIdIfToteIdProvided
        }
      }),

      providesTags: ["batches"]
    }),
    getBatch: build.query<GetBatchResponse | null, { batchId: string }>({
      query: ({ batchId }) => ({
        url: `/api/batches/${batchId}`
      }),
      providesTags: ["batches"]
    }),
    getStatusCount: build.query<
      GetStatusCountsResponse,
      { gridId: string; portId: number | null }
    >({
      query: ({ gridId, portId }) => ({
        url: `/api/batches/status-counts`,
        params: {
          gridId,
          portId
        }
      }),
      providesTags: ["batches"]
    }),
    postChangeBatchWorkstation: build.mutation<
      void,
      { batchId: string; portId: number; workstationId?: Guid }
    >({
      query: ({ batchId, portId, workstationId }) => ({
        url: `/api/batches/${batchId}/change-workstation`,
        method: "PATCH",
        data: {
          WorkstationPortId: portId,
          WorkstationId: workstationId
        }
      }),
      invalidatesTags: ["batches"]
    }),
    getWaveProgress: build.query<WaveProgressDto, { batchId: string }>({
      query: ({ batchId }) => ({
        url: `/api/batches/${batchId}/wave-progress`
      }),
      providesTags: ["wave progress"]
    }),
    postUnsuspendBatch: build.mutation<
      void,
      { batchId: Guid; autostoreGridId?: Guid }
    >({
      query: ({ batchId, autostoreGridId }) => ({
        url: `/api/batches/${batchId}/unsuspend${
          autostoreGridId ? `?autostoreGridId=${autostoreGridId}` : ""
        }`,
        method: "POST"
      }),
      invalidatesTags: ["batches"]
    }),
    postForcePrepareCart: build.mutation<
      void,
      { batchId: Guid; cartNumber: string }
    >({
      query: ({ batchId, cartNumber }) => ({
        url: `/api/batches/${batchId}/force-prepare-cart`,
        method: "POST",
        data: {
          CartNumber: cartNumber
        }
      }),
      invalidatesTags: ["batches"]
    }),
    postAssignCart: build.mutation<void, { batchId: Guid; cartNumber: string }>(
      {
        query: ({ batchId, cartNumber }) => ({
          url: `/api/batches/${batchId}/carts/assign`,
          method: "POST",
          data: {
            CartNumber: cartNumber
          }
        }),
        invalidatesTags: ["batches"]
      }
    ),
    postUnassignCart: build.mutation<void, { batchId: Guid }>({
      query: ({ batchId }) => ({
        url: `/api/batches/${batchId}/carts/unassign`,
        method: "POST"
      }),
      invalidatesTags: ["batches"]
    })
  }),
  overrideExisting: false
});
