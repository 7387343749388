import { createSelector } from "@reduxjs/toolkit";

import { AppSelector } from "~/app/store";
import { selectedBinRow } from "~/features/autostoreCycleCounts/autostoreCycleCounts.slice";
import { StoreState } from "~/redux/reducers";
import { InventoryDto, UncountedBinDto } from "~/types/api";

export const selectUncountedBins = (state: StoreState) =>
  state.cycleCounts.cycleCountsUncountedBins;

export const selectNextBinInventoryResponse = (state: StoreState) =>
  state.autostore.nextBinInventoryResponse;

export const selectUncountedBinAtPort: AppSelector<
  UncountedBinDto | undefined
> = createSelector(
  [selectUncountedBins, selectNextBinInventoryResponse],
  (uncountedBins, nextBinInventoryResponse) => {
    return uncountedBins.find((uncountedBin) => {
      const nextBinResponseInvIds = nextBinInventoryResponse.map(
        (nextBinInventory) => nextBinInventory.inventoryId
      );

      return nextBinResponseInvIds.includes(uncountedBin.inventoryId);
    });
  }
);

export const selectInventoryAtPort: AppSelector<InventoryDto | undefined> =
  createSelector(
    [selectUncountedBins, selectNextBinInventoryResponse],
    (uncountedBins, nextBinInventoryResponse) => {
      return nextBinInventoryResponse.find((nextBinInv) => {
        const uncountedInventoryIds = uncountedBins.map(
          (uncountedBins) => uncountedBins.inventoryId
        );
        return uncountedInventoryIds.includes(nextBinInv.inventoryId);
      });
    }
  );

export const selectedInventoryInBin: AppSelector<InventoryDto | undefined> =
  createSelector(
    [selectNextBinInventoryResponse, selectedBinRow],
    (nextBinInventoryResponse, selectedBinRow) => {
      return nextBinInventoryResponse.find(
        (inv) => inv.inventoryId === selectedBinRow
      );
    }
  );
