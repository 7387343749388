import {
  Container,
  Box,
  Paper,
  Stack,
  styled,
  Typography
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import BinCleaning from "~/components/settings/BinCleaning";
import BinFillCapacity from "~/components/settings/BinFillCapacity";
import BinMovements from "~/components/settings/BinMovements";
import BinRemoved from "~/components/settings/BinRemoved";
import CleaningBinSchedule from "~/features/cleaningBin/CleaningBinSchedule";
import { useGridSelector } from "~/features/inventory/useGridSelector";
import { useNavbar } from "~/hooks/useNavbar";
import { useView } from "~/hooks/useView";
import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";

type Props = {
  viewTitle: string;
};

const StyledPaper = styled(Paper)({
  border: "1px solid black",
  borderRadius: "10",
  marginBottom: "20px"
});
const GridSettingsContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "2fr 1fr",
  gap: theme.spacing(2),
  alignItems: "stretch",
  justifyItems: "stretch",
  "@media (max-width: 700px)": {
    gridTemplateColumns: "1fr",
    gap: theme.spacing(1)
  }
}));

export function GridSettings(props: Props) {
  const { viewTitle } = props;
  const { t } = useTranslation();
  const clientConfig = useAppSelector(selectClientConfig);
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const binFlaggingEnabled = useAppSelector(
    (state) => state.store.usersFulfillmentCenter?.binFlaggingEnabled
  );
  const { ap_binRemovedFlow, binCleaningEnabled } = clientConfig;

  useView({ permanentSidenav: true });
  useNavbar({ viewTitle });
  const { gridSelectorButton } = useGridSelector();

  const isBinCleaningTabEnabled =
    binCleaningEnabled && isAdmin && binFlaggingEnabled;

  return (
    <Container>
      <Stack direction="row" justifyContent="space-between" mb={1}>
        <Typography variant="h6" margin="20px 0">
          {t("grid settings")}
        </Typography>
        {gridSelectorButton}
      </Stack>
      <GridSettingsContainer>
        {isBinCleaningTabEnabled && <CleaningBinSchedule />}
        <StyledPaper
          sx={{
            margin: "0px"
          }}
        >
          <BinFillCapacity />
        </StyledPaper>
        {binFlaggingEnabled && (
          <StyledPaper sx={{ margin: "0px" }}>
            <BinMovements />
          </StyledPaper>
        )}
        {binFlaggingEnabled && (
          <StyledPaper sx={{ margin: "0px" }}>
            <BinCleaning />
          </StyledPaper>
        )}
        {ap_binRemovedFlow && (
          <StyledPaper sx={{ margin: "0px" }}>
            <BinRemoved />
          </StyledPaper>
        )}
      </GridSettingsContainer>
    </Container>
  );
}
