import { Menu, MenuItem, MenuItemProps, MenuProps } from "@mui/material";

import { PickMenuOption } from "~/api/warehouseTypes/fulfillmentCenter";

export type PickMenuItemProps = MenuItemProps & {
  option: PickMenuOption;
  show?: boolean;
};

export type PickMenuProps = MenuProps & {
  hiddenOptions: Set<PickMenuOption>;
  menuItemProps: PickMenuItemProps[];
};

export function PickMenu(props: PickMenuProps) {
  const { hiddenOptions, menuItemProps, ...menuProps } = props;
  const menuItems = menuItemProps
    .filter(
      (innerProps) =>
        !(innerProps.show === false || hiddenOptions.has(innerProps.option))
    )
    .map((innerProps) => {
      const { option, show: _show, ...itemProps } = innerProps;
      return (
        <MenuItem {...itemProps} key={option}>
          {itemProps.children}
        </MenuItem>
      );
    });

  return <Menu {...menuProps}>{menuItems}</Menu>;
}
