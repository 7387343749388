import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Point2D } from "~/types/api";

type StatusState = {
  hoverPosition: Point2D | null;
  selectedAutostoreGridId?: Guid;
  selectedTaskGroupId?: number;
};

const initialState: StatusState = {
  hoverPosition: null,
  selectedAutostoreGridId: undefined,
  selectedTaskGroupId: undefined
};

export const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setStatusHoverPosition(state, { payload }: PayloadAction<Point2D | null>) {
      state.hoverPosition = payload;
    },
    setSelectedTaskGroupId(
      state,
      { payload }: PayloadAction<number | undefined>
    ) {
      state.selectedTaskGroupId = payload;
    },
    setSelectedAutostoreGridId(
      state,
      { payload }: PayloadAction<Guid | undefined>
    ) {
      state.selectedAutostoreGridId = payload;
    }
  }
});

export const {
  setSelectedAutostoreGridId,
  setStatusHoverPosition,
  setSelectedTaskGroupId
} = statusSlice.actions;
