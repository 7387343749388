import { createSlice } from "@reduxjs/toolkit";

type ScanAndApplyLabelModalState = {
  scanAndApplyModalActiveStep: number;
};

const initialState: ScanAndApplyLabelModalState = {
  scanAndApplyModalActiveStep: 0
};

export const scanAndApplyLabelSlice = createSlice({
  name: "scanAndApplyLabel",
  initialState,
  reducers: {
    incrementScanAndApplyModalActiveStep(state) {
      state.scanAndApplyModalActiveStep += 1;
    },
    resetScanAndApplyModalActiveStep(state) {
      state.scanAndApplyModalActiveStep = 0;
    }
  }
});

export const {
  incrementScanAndApplyModalActiveStep,
  resetScanAndApplyModalActiveStep
} = scanAndApplyLabelSlice.actions;
