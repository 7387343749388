import { createSlice } from "@reduxjs/toolkit";

type AutostoreBinConfigModalState = {
  isOpen: boolean;
};

const initialState: AutostoreBinConfigModalState = {
  isOpen: false
};

export const autostoreBinConfigSlice = createSlice({
  name: "autostoreBinConfigModal",
  initialState,
  reducers: {
    openBinConfigModal(state) {
      state.isOpen = true;
    },
    closeBinConfigModal(state) {
      state.isOpen = false;
    }
  }
});

export const { openBinConfigModal, closeBinConfigModal } =
  autostoreBinConfigSlice.actions;
