import { Dispatch, Middleware, MiddlewareAPI, isAction } from "redux";

import { checkVersion } from "~/redux/actions/version";
import { StoreState } from "~/redux/reducers";

export const versionMiddleware: Middleware =
  (api: MiddlewareAPI<Dispatch, StoreState>) =>
  (next: (action: unknown) => unknown) =>
  (action: unknown) => {
    const result = next(action);
    const state = api.getState();

    if (isAction(action)) {
      switch (action.type) {
        case "version/CHECK":
        case "version/IS_CURRENT":
        case "version/OUT_OF_DATE":
          return result;
        default:
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          checkVersion(state.version.lastCheckedTime)(
            api.dispatch,
            () => api.getState(),
            undefined
          );
          return result;
      }
    }
    return result;
  };
