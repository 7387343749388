import { InductionOrderCreateRequest, PickOrderRequest } from "~/types/api";

import { warehouseApi } from "./warehouseApi";

const baseUrl = "/pepsi/api";

export const pepsiApi = warehouseApi.injectEndpoints({
  endpoints: (builder) => ({
    createPepsiOrder: builder.mutation<void, PickOrderRequest>({
      query: (order) => ({
        url: `${baseUrl}/v1/pickorder`,
        method: "POST",
        data: order
      }),
      invalidatesTags: ["order"]
    }),
    createPepsiInductionOrder: builder.mutation<
      void,
      InductionOrderCreateRequest
    >({
      query: (order) => ({
        url: `${baseUrl}/v1/inductionorder`,
        method: "POST",
        data: order
      }),
      invalidatesTags: ["put away task"]
    })
  }),
  overrideExisting: false
});
