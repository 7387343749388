import { AxiosError } from "axios";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { useShouldListenToGridEvents } from "~/hooks/useShouldListenToGridEvents";
import { useToast } from "~/hooks/useToast";
import { getAxiosErrorMessage } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { nextEmptyBin } from "~/redux/actions";
import {
  selectSelectedPortId,
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";

import {
  useLazyGetWorkstationsQuery,
  useStartInductionMutation
} from "~/redux/warehouse/workstation.hooks";

import {
  setPortPollingActive,
  setClosePortBtnDisabled
} from "./autostorePutaway.slice";

export function useStartInduction() {
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();
  const [startInduction] = useStartInductionMutation();
  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const selectedPortId = useAppSelector(selectSelectedPortId);
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const shouldListenToGridEvents = useShouldListenToGridEvents();

  const [getWorkstations, { error: getWorkstationsError }] =
    useLazyGetWorkstationsQuery();
  if (getWorkstationsError) {
    throw new Error(getMessageFromRtkError(getWorkstationsError));
  }

  const nextEmptyBinFunc = (portId?: number) => {
    if (!selectedAutostoreGridId || !selectedPortId) return;
    dispatch(
      nextEmptyBin({
        ...(portId && { portId })
      })
    )
      .then(() => {
        dispatch(setClosePortBtnDisabled(false));
      })
      .catch((err: AxiosError) => {
        errorToast(
          getAxiosErrorMessage(err) ||
            `Next empty bin failed. Grid id: ${selectedAutostoreGridId}. Port id: ${selectedPortId}`
        );
        if (!shouldListenToGridEvents) {
          dispatch(setPortPollingActive(false));
        }
      });
    if (!shouldListenToGridEvents) {
      dispatch(setPortPollingActive(true));
    }
  };

  // Call start-induction and next-empty-bin
  useEffect(() => {
    if (!siteWorkstation) {
      return;
    }
    void (async () => {
      try {
        await startInduction({
          autostoreGridId: siteWorkstation.autostoreGridId,
          workstationId: siteWorkstation.id
        });
        await getWorkstations().unwrap();
      } catch (error) {
        errorToast("Start induction failed", {
          description: getMessageFromRtkError(error)
        });
      }

      siteWorkstation.ports.forEach((port) => nextEmptyBinFunc(port.portId));
    })();
    // we want to only call this on mount, or if somehow the workstation chages
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteWorkstation?.id]);
}
