import { Chip } from "@mui/material";

import { mapEventColorAndLetters } from "~/lib/helpers";

import { AndonEvent } from "./andon.slice";

export function EventChip({ event }: { event: AndonEvent }) {
  const { color, letters } = mapEventColorAndLetters(event);
  return (
    <Chip
      sx={{
        backgroundColor: color
      }}
      size="small"
      label={letters || "✉"}
    />
  );
}
