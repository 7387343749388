import { handleWarehouseError, warehouseService } from "~/api/warehouse";
import { AppThunk, AsyncAppThunk } from "~/app/store";
import { NavBarPosition } from "~/redux/reducers/home";
import { BatchOrdersResponse, OrderSummaryDto, ToteDto } from "~/types/api";

export interface FetchOrderSummariesFilterLockedAction {
  type: "home/FETCH_ORDER_SUMMARIES_FILTER_LOCKED";
}

export interface FetchOrderSummariesFilterLockedSuccessAction {
  type: "home/FETCH_ORDER_SUMMARIES_FILTER_LOCKED_SUCCESS";
  payload: OrderSummaryDto[];
}

export interface FetchOrderSummariesFilterLockedFailureAction {
  type: "home/FETCH_ORDER_SUMMARIES_FILTER_LOCKED_FAILURE";
  payload: string;
}

export interface ClearErrorMessageAction {
  type: "home/CLEAR_ERROR_MESSAGE";
}

export interface SetNavBarPositionAction {
  type: "home/SET_NAV_BAR_POSITION";
  position: NavBarPosition;
}

export interface FetchToteAction {
  type: "home/FETCH_TOTE";
}

export interface FetchToteFailureAction {
  type: "home/FETCH_TOTE_FAILURE";
  payload: string;
}

export const fetchToteById =
  (toteId: Guid): AsyncAppThunk<ToteDto | null> =>
  async (dispatch) => {
    try {
      const response = await warehouseService.get<ToteDto>(
        `api/totes/${toteId}`
      );
      return response.data;
    } catch (err) {
      handleWarehouseError(err, (message) =>
        dispatch<FetchToteFailureAction>({
          type: "home/FETCH_TOTE_FAILURE",
          payload: message
        })
      );
      return null;
    }
  };

// CREATE BATCHES FROM ORDERS

export interface CreateBatchesFromOrdersAction {
  type: "home/CREATE_BATCHES_FROM_ORDERS";
}

export interface CreateBatchesFromOrdersSuccessAction {
  type: "home/CREATE_BATCHES_FROM_ORDERS_SUCCESS";
  payload: BatchOrdersResponse;
}

export interface CreateBatchesFromOrdersFailureAction {
  type: "home/CREATE_BATCHES_FROM_ORDERS_FAILURE";
}

export const clearErrorMessage = (): AppThunk => (dispatch) => {
  dispatch<ClearErrorMessageAction>({
    type: "home/CLEAR_ERROR_MESSAGE"
  });
};

export const setNavBarPosition =
  (position: NavBarPosition): AppThunk =>
  (dispatch) => {
    dispatch<SetNavBarPositionAction>({
      type: "home/SET_NAV_BAR_POSITION",
      position
    });
  };
