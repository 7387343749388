import { Box } from "@mui/material";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import NavSearchInput from "~/components/navbar/NavSearchInput";

import {
  selectRow,
  setIsPutawayTaskTableRefreshed,
  setSearchData
} from "./autostorePutaway.slice";

export function AutostorePutawaySearch() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isPutawayTaskTableRefreshed = useAppSelector(
    (state) => state.autostorePutaway.isPutawayTaskTableRefreshed
  );
  const onLeaveInputTextShown = useAppSelector(
    (state) => state.autostorePutaway.onLeaveInputTextShown
  );
  const searchData = useAppSelector(
    (state) => state.autostorePutaway.searchData
  );

  const setTextInput = (text: string) => {
    dispatch(selectRow(null));
    dispatch(
      setSearchData({
        offset: 1,
        scannedBarcodes: [],
        searchInputValue: text
      })
    );
  };

  return (
    <Box
      id="autostorePutaway-navbar-searchComponent"
      display="flex"
      flexGrow="1"
      justifyContent="center"
    >
      <NavSearchInput
        textInput={searchData.searchInputValue || ""}
        setTextInput={setTextInput}
        isAutocomplete
        searchPlaceholder={t("search placeholder")}
        autocompleteSearchCb={() => "autocomplete"}
        autocompleteOptions={[]}
        hideOptions
        onLeaveInputTextShown={onLeaveInputTextShown}
        isSearchRefreshed={isPutawayTaskTableRefreshed}
        isSearchRefreshedCallback={(isSearchRefreshed) =>
          dispatch(setIsPutawayTaskTableRefreshed(isSearchRefreshed))
        }
        autofocus={false}
      />
    </Box>
  );
}
