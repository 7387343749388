import { PortResponse } from "~/types/api";

/*
  Waiting: Waiting for a bin
  Active: Bin is present
  Inactive: Bin is not present
  Closed: Port is closed
 */
export type ActivityState =
  | "Waiting for Bin"
  | "Bin Opened"
  | "Inactive"
  | "Port Closed";

export const activityStateFromPortResponse = (
  portState?: PortResponse,
  isThisFusionSideOpen?: boolean
): ActivityState => {
  if (!portState) return "Port Closed";
  if (portState.mode === "CLOSED") return "Port Closed";
  if (
    portState.isReady &&
    isThisFusionSideOpen !== undefined &&
    !isThisFusionSideOpen
  )
    return "Inactive";
  if (portState.isReady) return "Bin Opened";
  return "Waiting for Bin";
};
