import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Box,
  DialogContent,
  Paper,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

import { ChangeEvent, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { ProgressButton } from "~/components/ProgressButton";
import { RoundIconButton } from "~/components/RoundIconButton";
import {
  setIsOosModalOpenedAfterQtyModal,
  setOutOfStockDialogStatus
} from "~/components/autostore/outOfStockDialog/outOfStockDialog.slice";
import envConstants from "~/config/envConstants";

import { displayPickData } from "~/lib/helpers";
import { setIsPickQuantityConfirmed } from "~/redux/actions";
import { clearUserMessage, setUserMessage } from "~/redux/actions/site";
import { selectAbleQtyToPick } from "~/redux/selectors/outOfStockSelectors";
import {
  selectSitePortId,
  selectWorkstationAutostoreGridId,
  selectWorkstationId
} from "~/redux/selectors/workstationsSelectors";
import { useGetFocusedPickQuery } from "~/redux/warehouse/autostorePicking.hooks";

import { Root } from "./Root";
import { setPickedQuantityModalStatus } from "./confirmPickQuantityModal.slice";

type ConfirmPickQuantityModalProps = {
  open: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg";
};

export function ConfirmPickQuantityModal(props: ConfirmPickQuantityModalProps) {
  const { open, maxWidth } = props;
  const { t } = useTranslation();
  const [enteredQuantity, setEnteredQuantity] = useState<number | string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const workstationId = useAppSelector(selectWorkstationId);
  const sitePortId = useAppSelector(selectSitePortId);
  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const userMessages = useAppSelector((state) => state.site.userMessages);
  const binId = useAppSelector(
    (state) => state.confirmPickQuantityModalSlice.selectedBinId
  );
  const ableToPickQuantity = useAppSelector(selectAbleQtyToPick);

  const { data: focusedPick } = useGetFocusedPickQuery(
    workstationId ? { workstationId: workstationId } : skipToken,
    {
      refetchOnMountOrArgChange: true
    }
  );

  const pickedQuantity = ableToPickQuantity || focusedPick?.quantity.value;

  const inputMustBePositiveMessageId = userMessages?.length
    ? userMessages.find(
        (message) =>
          message.title?.toLowerCase().trim() ===
          "your input must be a positive number"
      )?.id
    : null;

  const unexpectedQuantityMessageId = userMessages?.length
    ? userMessages.find((messageObj) =>
        messageObj.title?.includes("Quantity doesn't match")
      )?.id
    : null;

  const onConfirmCallback = () => {
    dispatch(setIsPickQuantityConfirmed(true));
    if (focusedPick && sitePortId) {
      void displayPickData({
        portId: sitePortId,
        pickId: focusedPick.pickId,
        batchId: focusedPick.batchId,
        gridId: selectedAutostoreGridId,
        quantity: ableToPickQuantity || undefined
      });
    }
  };

  return (
    <Root open={open} maxWidth={maxWidth}>
      <Paper sx={{ p: 4 }}>
        <DialogContent
          sx={{
            "@media (min-width: 1000px)": {
              minWidth: "500px"
            }
          }}
        >
          <Stack gap={4}>
            <Typography variant="h4">{t("please enter quantity")}</Typography>
            <Box position="relative">
              <TextField
                variant="outlined"
                value={enteredQuantity}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const parseInput = parseInt(e.target.value, 10);
                  if (parseInput > 0) {
                    if (inputMustBePositiveMessageId) {
                      dispatch(clearUserMessage(inputMustBePositiveMessageId));
                    }
                    setEnteredQuantity(parseInput);
                  } else if (parseInput < 1) {
                    dispatch(
                      setUserMessage({
                        title: "Your input must be a positive number",
                        severity: "error"
                      })
                    );
                  }
                  if (Number.isNaN(parseInput)) {
                    setEnteredQuantity("");
                  }
                }}
                fullWidth
                placeholder={t("enter quantity")}
                type="number"
                autoFocus
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  sx: {
                    fontSize: "20px",
                    padding: "35px 10px 35px 18px",
                    "&[type=number]": {
                      MozAppearance: "textfield"
                    },
                    "&::-webkit-outer-spin-button": {
                      WebkitAppearance: "none",
                      margin: 0
                    },
                    "&::-webkit-inner-spin-button": {
                      WebkitAppearance: "none",
                      margin: 0
                    }
                  }
                }}
                inputRef={inputRef}
              />

              <Stack
                gap={1}
                sx={{ position: "absolute", top: "3px", right: "8px" }}
              >
                <RoundIconButton
                  aria-label="increase quantity"
                  onClick={(): void => {
                    if (typeof enteredQuantity === "number") {
                      setEnteredQuantity(enteredQuantity + 1);
                    } else if (
                      typeof enteredQuantity === "string" &&
                      enteredQuantity.length === 0
                    ) {
                      setEnteredQuantity(1);
                    }
                  }}
                >
                  <ExpandLessIcon
                    style={{
                      fontSize: 22
                    }}
                  />
                </RoundIconButton>
                <RoundIconButton
                  aria-label="decrease quantity"
                  onClick={(): void => {
                    if (
                      typeof enteredQuantity === "number" &&
                      enteredQuantity > 1
                    ) {
                      setEnteredQuantity((prevEnteredQuantity) => {
                        if (typeof prevEnteredQuantity === "number") {
                          return prevEnteredQuantity - 1;
                        }
                        return enteredQuantity - 1;
                      });
                    } else if (
                      (typeof enteredQuantity === "number" &&
                        enteredQuantity <= 1) ||
                      (typeof enteredQuantity === "string" &&
                        enteredQuantity.length === 0)
                    ) {
                      dispatch(
                        setUserMessage({
                          title: "Your input must be a positive number",
                          severity: "error"
                        })
                      );
                    }
                  }}
                >
                  <ExpandMoreIcon
                    style={{
                      fontSize: 22
                    }}
                  />
                </RoundIconButton>
              </Stack>
            </Box>

            <Stack flexDirection={"row"} justifyContent={"center"} gap={4}>
              {[2, 3, 4, 5].map((num) => (
                <ProgressButton
                  key={`quick-confirm-quantity-${num}`}
                  id={`quick-confirm-quantity-${num}`}
                  data-testid={`quick-confirm-quantity-${num}`}
                  emphasis="low"
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor:
                      envConstants.DEV_CHEATS_STAGING === "true" &&
                      num !== pickedQuantity
                        ? "gray"
                        : null,
                    width: "75px",
                    height: "75px"
                  }}
                  onClick={() => setEnteredQuantity(num)}
                >
                  <Typography>{num}</Typography>
                </ProgressButton>
              ))}
            </Stack>
            <Stack flexDirection="row" justifyContent="center" gap={10}>
              <ProgressButton
                id="out-of-stock-button"
                buttonSize="medium"
                emphasis="high"
                responsive
                variant="contained"
                color="error"
                fullWidth
                onClick={(): void => {
                  setEnteredQuantity("");
                  dispatch(setIsOosModalOpenedAfterQtyModal(true));
                  dispatch(setOutOfStockDialogStatus(true));
                  if (pickedQuantity) {
                    dispatch(
                      setPickedQuantityModalStatus({
                        isShown: false,
                        pickedQuantity: pickedQuantity,
                        selectedBinId: binId
                      })
                    );
                  }
                }}
                sx={{
                  height: "50px",
                  width: "150px",
                  "@media (min-width: 600px)": {
                    height: "70px",
                    width: "200px"
                  }
                }}
              >
                <Typography>{t("out of stock")}</Typography>
              </ProgressButton>
              <ProgressButton
                data-testid="confirm-picked-quantity-button"
                onClick={() => {
                  if (pickedQuantity && enteredQuantity !== pickedQuantity) {
                    dispatch(
                      setUserMessage({
                        title: `Quantity doesn't match, expected quantity: ${pickedQuantity}`,
                        severity: "error"
                      })
                    );
                    inputRef.current?.focus();
                  } else {
                    if (unexpectedQuantityMessageId) {
                      dispatch(clearUserMessage(unexpectedQuantityMessageId));
                    }
                    onConfirmCallback();
                    setEnteredQuantity("");
                    dispatch(
                      setPickedQuantityModalStatus({
                        isShown: false,
                        pickedQuantity: null,
                        selectedBinId: null
                      })
                    );
                  }
                }}
                buttonSize="medium"
                emphasis="high"
                responsive
                variant="contained"
                color="primary"
                sx={{
                  height: "50px",
                  width: "150px",
                  "@media (min-width: 600px)": {
                    height: "70px",
                    width: "200px"
                  }
                }}
              >
                <Typography>{t("confirm")}</Typography>
              </ProgressButton>
            </Stack>
          </Stack>
        </DialogContent>
      </Paper>
    </Root>
  );
}
