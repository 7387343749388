import { createSelector } from "@reduxjs/toolkit";
import { t } from "i18next";

import { AppSelector } from "~/app/store";
import { limit } from "~/features/inventory/InventoryTables";
import { StoreState } from "~/redux/reducers";
import { InventorySummaryDto } from "~/types/api";

import { selectPortStateByPort } from "./autostoreSelectors";

import { selectLanguageCode } from "./siteSelectors";
import { selectPutawayAdjustmentReasonCodes } from "./storeSelectors";

/** Default Putaway Inventory Adjustment reason codes if no client-specific ones exist. */
const defaultPutawayReasonCodes = [
  "Quality",
  "Over Received",
  "Received Missing",
  "Received Expired"
];

/** Putaway Inventory Adjustment Reason Code to display. */
type PutawayReasonCodeForDisplay = {
  /** The actual reason code value. */
  value: string;
  /** The display text for the value. */
  label: string;
};

/** Default Putaway Inventory Adjustment reason codes if no client-specific ones exist.
 * Utlizes 'languageCode' state in order to recalculate codes if language changes.
 */
export const selectPutawayReasonCodeOptions: AppSelector<
  PutawayReasonCodeForDisplay[]
> = createSelector(
  [selectPutawayAdjustmentReasonCodes, selectLanguageCode],
  (clientSpecificCodes, _) => {
    const reasonCodesToMap = clientSpecificCodes?.length
      ? clientSpecificCodes
      : defaultPutawayReasonCodes;

    return reasonCodesToMap.map((code) => ({
      value: code,
      label: t(code.toLowerCase())
    }));
  }
);

export const selectSelectedSummaries = (state: StoreState) =>
  state.inventoryNew.selectedSummaries;

export const selectIsAdjustingBins = (state: StoreState) =>
  state.inventoryNew?.isAdjustingBins;

export const selectSelectedInventoryRows = createSelector(
  [selectSelectedSummaries],
  (selectedSummaries) => selectedSummaries.map((summary) => summary.inventoryId)
);

export const selectHoldTypeFilters = (state: StoreState) =>
  state.inventoryNew.holdTypeFilters;

export const selectAutostoreGridIdOverride = (state: StoreState) =>
  state.inventoryNew.autostoreGridIdOverride;

export const selectMultiportSelectedCompartment = (state: StoreState) => {
  const selectedAdjustingInventory =
    state.inventoryNew.selectedAdjustingSummary;
  if (
    selectedAdjustingInventory?.autostoreCompartmentNumber === undefined ||
    selectedAdjustingInventory?.autostoreCompartmentNumber === null
  ) {
    return undefined;
  }
  // 'autostoreCompartmentNumber' is 1-4 based while the Bin component uses 0-3
  return selectedAdjustingInventory.autostoreCompartmentNumber - 1;
};

export const selectAdjustingInventoryPage = (state: StoreState) =>
  state.inventoryNew.adjustingInventoryPage;

export const selectInventorySummariesToDisplay = createSelector(
  [
    selectPortStateByPort,
    selectSelectedSummaries,
    selectAdjustingInventoryPage
  ],
  (portStateByPort, selectedSummaries, adjustingInventoryPage) => {
    const portStateByPortArray = Object.values(portStateByPort);

    const portStateBinIds = portStateByPortArray.map(
      (port) => port.getPortResponse.selectedBin
    );

    /** selected summaries sorted by inventory at (or on the way to) the ports */
    const sortByPort = (a: InventorySummaryDto, b: InventorySummaryDto) => {
      const aIndex = portStateBinIds.indexOf(a.autostoreBinNumber || 0);
      const bIndex = portStateBinIds.indexOf(b.autostoreBinNumber || 0);
      if (aIndex !== -1 && bIndex === -1) return -1;
      if (aIndex === -1 && bIndex !== -1) return 1;
      return aIndex - bIndex;
    };

    const sortedSelectedSummaries = selectedSummaries.toSorted(sortByPort);
    return sortedSelectedSummaries.slice(
      (adjustingInventoryPage - 1) * limit,
      limit * adjustingInventoryPage
    );
  }
);

export const selectAdjustingInventory = (state: StoreState) =>
  state.inventoryNew.selectedAdjustingSummary;

export const selectInventoryAtPort = (state: StoreState) =>
  state.inventory.inventory;

export const selectSelectedInventoryAtPort = createSelector(
  [selectInventoryAtPort, selectAdjustingInventory, selectSelectedSummaries],
  (inventoryAtPort, selectedAdjustingInventory, selectedSummaries) => {
    return (
      selectedAdjustingInventory ||
      selectedSummaries.find(
        (summary) =>
          summary?.autostoreBinNumber ===
          inventoryAtPort[0]?.bin?.autostoreBin?.autostoreBinId
      )
    );
  }
);

export const selectInventorySummaryToDisplay = createSelector(
  [
    selectAdjustingInventory,
    selectSelectedSummaries,
    selectSelectedInventoryRows
  ],
  (selectedAdjustingInventory, selectedSummaries, selectedRows) => {
    return (
      selectedAdjustingInventory ||
      selectedSummaries.find(
        (summary) =>
          summary?.inventoryId === selectedRows[selectedRows.length - 1]
      ) ||
      null
    );
  }
);
