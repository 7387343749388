import Button from "@locaisolutions/button";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationModal } from "~/components/ConfirmationModal";

export function SkipMovementButton({
  skipMove,
  inventoryActionsEnabled
}: {
  skipMove: () => void;
  inventoryActionsEnabled: boolean;
}) {
  const { t } = useTranslation();

  const [isSkipMoveModalOpen, setIsSkipMoveModalOpen] = useState(false);

  return (
    <>
      <Button
        size="large"
        onClick={() => setIsSkipMoveModalOpen(true)}
        disabled={!inventoryActionsEnabled}
        sx={{ fontWeight: "normal" }}
      >
        {t("skip move")}
      </Button>

      <ConfirmationModal
        isOpen={isSkipMoveModalOpen}
        confirmCb={() => {
          skipMove();
          setIsSkipMoveModalOpen(false);
        }}
        closeCb={() => setIsSkipMoveModalOpen(false)}
        modalTitle={t("are you sure you want to skip move")}
      />
    </>
  );
}
