import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import FetchOptionsFilters from "~/components/sort/FetchOptionsFilters";

import { fullHoldTypeOptions } from "~/lib/helpers";

import {
  selectIsAdjustingBins,
  selectHoldTypeFilters,
  selectSelectedInventoryRows
} from "~/redux/selectors/inventorySelectors";

import { ProductBinSearchInput } from "./ProductBinSearchInput";
import { setHoldTypeFilters } from "./inventory.slice";

/**
 * Inventory search components rendered in the navbar.
 *
 * Returns undefined if the user is adjusting bins.
 */
export function InventoryNavbarSearch() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isAdjustingBins = useAppSelector(selectIsAdjustingBins);
  const selectedRows = useAppSelector(selectSelectedInventoryRows);
  const selectedHoldTypeFilters = useAppSelector(selectHoldTypeFilters);

  if (isAdjustingBins && selectedRows.length) {
    return undefined;
  }

  return (
    <Box
      id="inventoryLookup-navbar-searchComponent"
      display="flex"
      flexGrow="1"
      justifyContent="center"
    >
      <ProductBinSearchInput />
      <FetchOptionsFilters
        menuItemList={fullHoldTypeOptions.map((option) => ({
          translation: t(option.translation),
          filter: option.filter
        }))}
        selectedMenuItems={selectedHoldTypeFilters}
        menuItemsTitle={t("hold type")}
        onFilterData={(filters) => {
          dispatch(setHoldTypeFilters(filters));
        }}
        onResetFilters={() => {
          dispatch(setHoldTypeFilters([]));
        }}
      />
    </Box>
  );
}
