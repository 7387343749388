import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type OrdersState = {
  orderFilter: string;
  selectedDate: Date | null;
  selectedOrderIds: Guid[];
  orderStatusFilter: string[];
};

const initialState: OrdersState = {
  orderFilter: "",
  selectedDate: null,
  selectedOrderIds: [],
  orderStatusFilter: []
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    clearSelectedOrders(state) {
      state.selectedOrderIds = [];
    },

    setOrderFilter(state, { payload }: PayloadAction<string>) {
      state.orderFilter = payload;
    },

    setOrderStatusFilter(state, { payload }: PayloadAction<string[]>) {
      state.orderStatusFilter = payload;
    },

    setSelectedDate(state, { payload }: PayloadAction<Date | null>) {
      state.selectedDate = payload;
      state.selectedOrderIds = [];
    },

    selectOrder(state, { payload: orderId }: PayloadAction<Guid>) {
      const orderIdIndex = state.selectedOrderIds.indexOf(orderId);
      if (orderIdIndex === -1) {
        state.selectedOrderIds.push(orderId);
      } else {
        state.selectedOrderIds.splice(orderIdIndex, 1);
      }
    }
  }
});
export const {
  clearSelectedOrders,
  selectOrder,
  setOrderFilter,
  setOrderStatusFilter,
  setSelectedDate
} = ordersSlice.actions;
