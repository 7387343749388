import { Box } from "@mui/material";

export const AutostoreBinBorder = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        py: 0.5,
        px: 2
      }}
    >
      <Box
        sx={{
          backgroundColor: "common.white",
          px: 1.5,
          py: 0.8
        }}
      />
      <Box
        sx={{
          backgroundColor: "common.white",
          px: 1.5,
          py: 0.8
        }}
      />
    </Box>
  );
};
