import { BulkReceivingDto, ReceiveOrderDto } from "~/types/api";

import { warehouseApi } from "./warehouseApi";

const baseUrl = "/public-api";

export const publicApi = warehouseApi.injectEndpoints({
  endpoints: (builder) => ({
    createOrder: builder.mutation<void, ReceiveOrderDto>({
      query: (order) => ({
        url: `${baseUrl}/v1/orders/create`,
        method: "POST",
        data: order
      }),
      invalidatesTags: ["order"]
    }),
    receiveInventory: builder.mutation<void, BulkReceivingDto>({
      query: (order) => ({
        url: `${baseUrl}/v1/inventory/receive`,
        method: "POST",
        data: order
      }),
      invalidatesTags: ["put away task"]
    })
  }),
  overrideExisting: false
});
