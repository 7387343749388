import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import FetchBatchFilters from "~/components/FetchBatchFilters";
import { Search } from "~/components/navbar/NavSearchInput";

import { OrderType } from "~/features/batch/batch.type";
import { buildBatchStatusFilter, buildBatchTypeFilter } from "~/lib/helpers";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

import {
  resetFilters,
  setBatchStatuses,
  setBatchTypes,
  setOrderType,
  setPage,
  setSearch,
  setTempZones
} from "./pickBatches.slice";

export function PickBatchesSearch() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const selectedFulfillmentCenter = useAppSelector(
    selectUsersFulfillmentCenter
  );
  const availableBatchStatuses = useAppSelector(
    (state) => state.pickBatches.availableBatchStatuses
  );
  const availableBatchTypes = useAppSelector(
    (state) => state.pickBatches.availableBatchTypes
  );
  const batchStatuses = useAppSelector(
    (state) => state.pickBatches.batchStatuses
  );
  const batchTypes = useAppSelector((state) => state.pickBatches.batchTypes);
  const orderType = useAppSelector((state) => state.pickBatches.orderType);
  const search = useAppSelector((state) => state.pickBatches.search);
  const tempZones = useAppSelector((state) => state.pickBatches.tempZones);

  return (
    <Box
      id="pickBatches-navbar-searchComponent"
      style={{
        display: "flex",
        flexGrow: 1,
        justifyContent: "center"
      }}
    >
      <Search
        style={{
          width: "100%",
          maxWidth: 800
        }}
      >
        <TextField
          placeholder={t("admin batches search placeholder")}
          size="small"
          InputProps={{ style: { background: "white" } }}
          onKeyPress={(e) => {
            if (e.key.toLowerCase() === "enter") {
              e.preventDefault();
              dispatch(setSearch((e.target as HTMLInputElement).value));
            }
          }}
          defaultValue={search || ""}
          variant="outlined"
          fullWidth
          autoFocus
        />
      </Search>
      <FetchBatchFilters
        fulfillmentCenter={selectedFulfillmentCenter}
        selectedTempZones={tempZones}
        selectedBatchStatuses={batchStatuses}
        availableBatchStatuses={availableBatchStatuses}
        selectedBatchTypes={batchTypes}
        selectedOrderType={orderType}
        availableBatchTypes={availableBatchTypes}
        onSelectOrderType={(orderType: OrderType) =>
          dispatch(setOrderType(orderType))
        }
        onToggleZone={(zone) => {
          // it would be nice to be able to filter by multiple zones
          // const newZones = selectedTempZones.includes(zone)
          //   ? selectedTempZones.filter((i) => i !== zone)
          //   : [...selectedTempZones, zone];

          const newZones = tempZones.includes(zone) ? [] : [zone];

          dispatch(setTempZones(newZones));
          dispatch(setPage(1));
        }}
        onToggleStatus={(status) => {
          let newStatuses;

          if (availableBatchStatuses.length === batchStatuses.length) {
            newStatuses = [status];
          } else {
            newStatuses = batchStatuses.includes(status)
              ? batchStatuses.filter((el) => el !== status)
              : [...batchStatuses, status];
          }

          dispatch(setBatchStatuses(newStatuses));
          dispatch(setPage(1));
        }}
        onToggleBatchType={(type) => {
          let newTypes;

          if (availableBatchTypes.length === batchTypes.length) {
            newTypes = [type];
          } else {
            newTypes = batchTypes.includes(type)
              ? batchTypes.filter((el) => el !== type)
              : [...batchTypes, type];
          }

          dispatch(setBatchTypes(newTypes));
          dispatch(setPage(1));
        }}
        onResetFilters={() => {
          dispatch(
            resetFilters({
              batchStatuses: buildBatchStatusFilter(selectedFulfillmentCenter),
              batchTypes: buildBatchTypeFilter(selectedFulfillmentCenter)
            })
          );
          dispatch(setPage(1));
        }}
      />
    </Box>
  );
}
