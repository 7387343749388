import { Box, Card, Stack, Typography } from "@mui/material";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Heading } from "~/components/tableStyles";
import { useToast } from "~/hooks/useToast";

import { workstationRoles } from "~/lib/helpers";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";

import { useGetAutostoreGridsQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import {
  useGetWorkstationsQuery,
  usePostCreateWorkstationMutation
} from "~/redux/warehouse/workstation.hooks";
import {
  AutostoreGridDto,
  CreateWorkstation,
  WorkstationPortDto
} from "~/types/api";

import { AddWorkstationButton } from "./AddWorkstationButton";
import { AutostoreGridSelector } from "./AutostoreGridSelector";
import { AutostorePortSelector } from "./AutostorePortSelector";

export function AddWorkstation() {
  const { t } = useTranslation();
  const [createWorkstationAutostoreGrid, setCreateWorkstationAutostoreGrid] =
    useState<AutostoreGridDto | null>(null);
  const [createWorkstationPortId, setCreateWorkstationPortId] = useState<
    number[]
  >([]);
  const [unusedPorts, setUnusedPorts] = useState<number[]>([]);
  const { data: workstations, refetch: refetchWorkstations } =
    useGetWorkstationsQuery();

  const { data: autostoreGrids } = useGetAutostoreGridsQuery();
  const [postCreateWorkstation, { isLoading: isCreatingWorkstation }] =
    usePostCreateWorkstationMutation();
  const { errorToast, successToast } = useToast();

  const addPort = () => {
    const newPortId =
      unusedPorts.find((port) => !createWorkstationPortId?.includes(port)) ||
      unusedPorts[0];
    if (newPortId && createWorkstationPortId) {
      setCreateWorkstationPortId([...createWorkstationPortId, newPortId]);
    }
  };

  const updatePort = (index: number, newPortId: number) => {
    if (createWorkstationPortId) {
      const updatedPorts = [...createWorkstationPortId];
      updatedPorts[index] = newPortId;
      setCreateWorkstationPortId(updatedPorts);
    }
  };

  const removePort = (index: number) => {
    if (createWorkstationPortId) {
      const updatedPorts = createWorkstationPortId.filter(
        (_, portIndex) => index !== portIndex
      );
      setCreateWorkstationPortId(updatedPorts);
    }
  };

  const handleSelectAutostoreGrid = (selectedGridId: string | undefined) => {
    const selectedGrid =
      autostoreGrids?.find((grid) => grid.autostoreGridId === selectedGridId) ||
      undefined;
    if (selectedGrid) {
      setCreateWorkstationAutostoreGrid(selectedGrid);
      setCreateWorkstationPortId([0]);
    }
  };

  const handleAddWorkstation = async () => {
    if (!createWorkstationAutostoreGrid || !createWorkstationPortId.length)
      return;

    const ports: WorkstationPortDto[] = createWorkstationPortId.map(
      (portId, index) => ({
        binSize: 330,
        configuration: "independent",
        portId: portId,
        coordinate: { x: 0, y: index },
        inductionBinConfiguration: ["whole bin"],
        cleaningDirection: "No Direction",
        cells: [{ x: 0, y: 0 }]
      })
    );

    const newWorkstation: CreateWorkstation = {
      autostoreGridId: createWorkstationAutostoreGrid.autostoreGridId,
      deviceId: `new id ${workstations?.length}`,
      ports,
      totePlacements: [],
      mode: "",
      multiPortEnabled: false,
      orientation: "North",
      roles: workstationRoles,
      pickableOrderTypes: []
    };

    try {
      await postCreateWorkstation(newWorkstation).unwrap();
      await refetchWorkstations();
      successToast("Workstation added");
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  useEffect(() => {
    if (autostoreGrids?.length) {
      setCreateWorkstationAutostoreGrid(autostoreGrids[0]);
      const initialPortId = autostoreGrids[0].autostorePorts[0]?.portId;
      setCreateWorkstationPortId(initialPortId ? [initialPortId] : []);
    }
  }, [autostoreGrids]);

  useEffect(() => {
    if (!createWorkstationAutostoreGrid || !autostoreGrids || !workstations)
      return;

    const portIsUsedByAnyWorkstation = (portId: number) => {
      return !!workstations.find(
        (workstation) =>
          workstation.ports.length > 0 &&
          workstation.autostoreGridId ===
            createWorkstationAutostoreGrid.autostoreGridId &&
          workstation.ports.some(
            (port) => port.portId === portId || port.parentPortId === portId
          )
      );
    };

    const currentGridPorts = createWorkstationAutostoreGrid.autostorePorts.map(
      (port) => port.portId
    );
    const unassignedPorts = currentGridPorts.filter(
      (portId) => !portIsUsedByAnyWorkstation(portId)
    );

    setUnusedPorts(unassignedPorts);

    setCreateWorkstationPortId(
      unassignedPorts.length > 0 ? [unassignedPorts[0]] : []
    );
  }, [createWorkstationAutostoreGrid, autostoreGrids, workstations]);

  const selectedGridHasUnusedPorts = unusedPorts.length > 0;

  return (
    <Card>
      <Stack spacing={4} m={2}>
        <Heading>{t("create workstation")}</Heading>
        <Stack direction="row" spacing={2} m={2}>
          <Box sx={{ width: 200 }}>
            <AutostoreGridSelector
              selectedGrid={createWorkstationAutostoreGrid?.autostoreGridId}
              onSelect={handleSelectAutostoreGrid}
            />
          </Box>
          {selectedGridHasUnusedPorts && (
            <>
              <Box sx={{ width: 200 }}>
                <AutostorePortSelector
                  ports={createWorkstationPortId}
                  unusedPorts={unusedPorts}
                  addPort={addPort}
                  updatePort={updatePort}
                  removePort={removePort}
                />
              </Box>
              <Box>
                <AddWorkstationButton
                  addWorkstation={handleAddWorkstation}
                  isCreating={isCreatingWorkstation}
                />
              </Box>
            </>
          )}
          {!selectedGridHasUnusedPorts && (
            <Typography color="text.secondary" alignSelf="flex-end">
              {t(
                "all ports for the selected grid are assigned to workstations"
              )}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
