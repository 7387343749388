import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";

import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "~/app/store";
import { useToast } from "~/hooks/useToast";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useCreateOrderTypeMutation } from "~/redux/warehouse/orders.hooks";

import {
  clearOrderTypeToDelete,
  closeWorkstationOrderTypesDialog
} from "./workstationOrderTypes.slice";

export function WorkstationOrderTypesDialog() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { successToast, errorToast } = useToast();
  const [newOrderType, setNewOrderType] = useState("");
  const [createOrderType] = useCreateOrderTypeMutation();

  const handleClose = () => {
    dispatch(clearOrderTypeToDelete());
    dispatch(closeWorkstationOrderTypesDialog());
  };

  const handleNewOrderTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewOrderType(event.target.value);
  };

  const handleConfirmButtonClick = async (orderType: string) => {
    try {
      await createOrderType({ orderType }).unwrap();
      successToast("A new order type has been successfully created");
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle textAlign="center" variant="h6">
        {t("add new order type")}
      </DialogTitle>
      <DialogContent sx={{ mt: 2, display: "flex", gap: 1 }}>
        <TextField
          onChange={handleNewOrderTypeChange}
          inputProps={{
            "aria-label": "new-order-type-textfield"
          }}
        />
        <Button
          onClick={() => {
            void handleConfirmButtonClick(newOrderType);
          }}
          disabled={!newOrderType.length}
          variant="contained"
        >
          {t("confirm")}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
