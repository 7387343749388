import Error24Px from "@locaisolutions/icons/dist/icons24px/Error24Px";
import { Alert, Collapse, SvgIcon, SxProps, styled } from "@mui/material";

type AlertBannerProps = {
  message?: string;
  sx?: SxProps;
};

const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.error.light,
  border: "none",
  borderRadius: "10px",
  boxShadow: "none",
  fontSize: "14px",
  paddingLeft: "48px",
  paddingRight: "48px",
  display: "inline-flex",
  alignItems: "center",
  gap: "12px"
}));

const ErrorIcon = <SvgIcon component={Error24Px} width={6} height={6} />;

export const AlertBanner = ({ message, sx }: AlertBannerProps) => {
  return (
    <Collapse in={!!message}>
      <StyledAlert severity="error" icon={ErrorIcon} sx={sx}>
        {message}
      </StyledAlert>
    </Collapse>
  );
};
