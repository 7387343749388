import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

import { useMemo } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { selectAutostoreGridIdOverride } from "~/redux/selectors/inventorySelectors";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useGetAutostoreGridsQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import { AutostoreGridDto } from "~/types/api";

import { setAutostoreGridIdOverride } from "./inventory.slice";

/** Allows users without a workstation to select an Autostore Grid */
export function useGridSelector() {
  const workstation = useAppSelector(selectThisWorkstation);
  const autostoreGridOverride = useAppSelector(selectAutostoreGridIdOverride);
  const { data: autostoreGrids } = useGetAutostoreGridsQuery(
    workstation ? skipToken : undefined
  );
  const dispatch = useAppDispatch();

  const gridSelectorButton = useMemo(
    () =>
      workstation ? undefined : (
        <Select
          value={autostoreGridOverride || ""}
          onChange={(e: SelectChangeEvent) => {
            const selectedGrid = e.target.value;
            dispatch(setAutostoreGridIdOverride(selectedGrid || undefined));
          }}
          variant="outlined"
          size="small"
          displayEmpty
          sx={{
            minWidth: 300,
            ...(autostoreGridOverride
              ? {}
              : {
                  backgroundColor: "primary.main",
                  color: "primary.contrastText",
                  fontWeight: "700",
                  "& svg": {
                    color: "primary.contrastText"
                  }
                })
          }}
        >
          <MenuItem key={"all-grids"} value="">
            No Grid Selected
          </MenuItem>
          {(autostoreGrids || []).map(
            ({ autostoreGridName, autostoreGridId }: AutostoreGridDto) => (
              <MenuItem key={autostoreGridId} value={autostoreGridId}>
                {autostoreGridName}
              </MenuItem>
            )
          )}
        </Select>
      ),
    [autostoreGridOverride, autostoreGrids, dispatch, workstation]
  );

  return { autostoreGridOverride, gridSelectorButton };
}
